.col-to-right {
  position: relative;
  .col-item-to-right {
    position: absolute;
    right: 0;
  }
}

.cancel {
  color: $color-dark;
}

.plus-btn {
  background-color: $color-dark;
  border-radius: 0.4rem;
  display: inline-flex;

  justify-content: center;
  align-items: center;
  width: 1.625rem;
  height: 1.625rem;
}
.desktop-plus-btn {
  display: none;
}

.mobile-plus-btn {
  display: block;
}

@include media-breakpoint-up(lg) {
  .desktop-plus-btn {
    display: block;
  }

  .mobile-plus-btn {
    display: none;
  }
}

.btn-secondary-cancel {
  color: $color-text-normal;
}

.btn-secondary-cancel {
  color: $color-dark;
}
.btn-save {
  color: $color-dark;
}
