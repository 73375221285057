@import '../../../../../styles/mixins.scss';

.rpm-signup-mobile-carousel {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;

  .rpm-signup-mobile-carousel-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    z-index: 1;

    .rpm-signup-mobile-carousel-background-image {
      object-fit: cover;
      width: 100%;
      height: 60vh;
    }
  }

  .rpm-signup-mobile-carousel-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 55vh;
    padding: 1rem;
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: $color-white;
    z-index: 2;
    border-top-left-radius: 2rem;
    border-top-right-radius: 2rem;

    .rpm-signup-mobile-carousel-content-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      height: 100%;
      flex-wrap: nowrap;
      overflow: hidden;
      position: relative;
      .rpm-signup-mobile-carousel-content-item {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 1rem;
        gap: 1rem;
        img {
          height: 27px;
          object-fit: contain;
        }
        h2 {
          font-size: 1.5rem;
          font-weight: 700;
          color: $color-dark;
          font-family: $serif-font-family;
        }
        .description {
          font-size: 14px;
          font-weight: 300;
          color: $color-dark;
        }
        .reminder {
          font-size: 14px;
          font-weight: 300;
          color: #908e87;
          font-style: italic;
        }
      }
    }
  }
  .rpm-signup-mobile-carousel-footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 79px;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 3;
    background-color: $white;
    border-top-left-radius: 2rem;
    border-top-right-radius: 2rem;
    padding: 0 2rem;
    .rpm-signup-mobile-carousel-footer-lines {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 3px;
      width: max-content;
      .rpm-signup-mobile-carousel-footer-line {
        width: 14px;
        height: 3px;
        border-radius: 2px;
      }
    }
    .rpm-signup-mobile-carousel-footer-button {
      height: 3.3rem;
      width: 3.3rem;
      border-radius: 50%;
      background-color: $color-dark;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        margin-right: -0.2rem;
      }
    }
  }
}
