@import '../../../../../styles/mixins.scss';

.rpm-connect-your-device-desktop {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: $color-dark;

  .rpm-connect-card {
    width: 619px;
    height: 90vh;
    background-color: $color-dark;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    overflow: hidden;
    .rpm-connect-card-header {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 1rem;
      width: 100%;
      min-height: 40%;
      height: 100%;
      position: relative;
      .background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        overflow: hidden;
        background-color: $color-dark;
      }
      .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.8);
      }
      .user-photo {
        width: 5.56rem;
        height: 5.56rem;
        border-radius: 10px 10px 0px 10px;
        border: 2px solid rgba(255, 255, 255, 0.5);
        box-sizing: border-box;
        z-index: 2;
      }
      h1 {
        font-size: 1.5rem;
        font-weight: 700;
        color: $white;
        text-align: center;
        width: 70%;
        z-index: 2;
      }
      a {
        font-size: 0.8rem;
        font-weight: 400;
        color: $color-white;
        text-decoration: underline;
        z-index: 2;
      }
      .rpm-progress-bar {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 6px;
        background-color: #595959;
        z-index: 1;
        .rpm-progress-bar-fill {
          height: 100%;
          background-color: #e8744f;
          width: 20%;
          border-radius: 0px 10px 10px 0px;
        }
      }
    }
    .rpm-connect-card-body {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      background-color: $color-white;
      padding: 2.5rem 2rem;
      gap: 1rem;
      .watch-options {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        gap: 1rem;
        .watch-option {
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          height: 5.0625rem;
          border-radius: 10px;
          background-color: $white;
          box-shadow: 0px -4px 20px 1px rgba(0, 0, 0, 0.05);
          cursor: pointer;
          outline: none;
          &:hover {
            transform: scale(1.01);
            transition: all 0.2s ease-in-out;
          }
          img {
            height: 1.6rem;
            object-fit: contain;
          }
        }
        .footer-text {
          font-size: 14px;
          color: $color-gray-lightest;
          font-weight: 400;
        }
        .no-device {
          position: absolute;
          bottom: 2rem;
          left: 0;
          width: 100%;
          text-align: center;
          text-decoration: underline;
          font-size: $font-size-normal;
          outline: none;
        }
      }
    }
  }
}
