@import '../../styles/mixins';
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;1,300;1,500;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@400;700&display=swap');

$dark: #343434;
$background-sidebar: #f0ece8;
$background: $color-white;
$green: #88a088;

p {
  font-family: $sans-serif-font-family;
  color: $dark;
}
.signup-stripe {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  background-color: $background;
  width: 100%;
  overflow: hidden;

  .stripe-sidebar {
    background-color: $color-dark;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;
    height: 100%;
    padding: 1rem 2.31rem 1rem 2.31rem;
    .logo-noble {
      width: 5rem;
      margin-top: 1rem;
    }
    .stripe-logo {
      margin: 0 auto;
      padding-bottom: 2rem;
      width: 30%;
    }
    .login_form {
      height: 100%;
      display: grid;
      align-items: center;

      .form_c {
        display: grid;
        justify-content: start;
        width: 100%;

        .stripe_button {
          margin: 0 auto;
          margin-top: 1.9rem;

          p {
            margin-bottom: 1rem;
          }
        }
        .links {
          margin-top: 2rem;
          margin-bottom: 2rem;
          p {
            text-decoration: underline;
            font-weight: 500;

            &:hover {
              color: $color-dark;
            }
          }
        }
        .dark_button {
          background-color: $dark;
          color: white;
          margin-top: 1rem;
          border-radius: 0.5rem;
          font-family: 'Poppins';
          font-weight: 500;
          min-width: 100%;
          padding: 1rem 2rem 1rem 2rem;
          text-align: center;
        }

        h3 {
          font-weight: 500;
          font-size: $font-size-larger;
          margin-bottom: 1rem;
          text-align: center;
          font-family: $sans-serif-font-family;
          color: $white;
        }
        p {
          font-size: $font-size-small;
          font-weight: 300;
          color: $white;
          text-align: center;
        }
      }

      .ca {
        font-weight: 600;
        font-size: 1.1rem;
        margin-top: 1rem;
      }
      .form-group {
        svg {
          position: absolute;
          margin-top: 0.7rem;
          margin-left: 0.7rem;
          color: $primary;
        }
      }

      .form-control {
        padding-left: 2.5rem;
      }
      form {
        input {
          width: 100%;
          height: 2.7rem;
          margin-top: 1rem;
          border: 1px solid $color-gray-lighter;
          border-radius: 0.5rem;
        }
        ::placeholder {
          font-weight: 300;
          font-size: 1rem;
          color: #6d786e;
        }
        svg {
          float: left;
          margin-left: 1rem;
          margin-top: -1.85rem;
          position: relative;
          z-index: 2;
          color: red;
        }
      }
      .aha {
        text-align: center;
        text-decoration: underline;
        font-weight: 500;
        margin-top: 1rem;
        color: $dark;
      }
    }

    button {
      background-color: $white;
      color: $dark;
      display: inline-flex;
      align-items: center;
      border-radius: 0.8rem;
      gap: 1rem;
      height: 3.5rem;
      padding-right: 2.5rem;
      max-width: max-content;
      font-size: 0.9rem;
      font-weight: 500;
    }

    .button-icon {
      height: 100%;
      border-color: white;
      display: inline-flex;
      align-items: center;
      border-right: 1px solid #595959;
      padding: 0 1rem;
    }
  }

  .content {
    width: 67%;
    background-color: $color-white;
    padding: 2rem;
    border-radius: 0.8rem;
    min-height: 100vh;
    margin-left: 33%;
    display: grid;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    display: none;
    .login_content {
      width: 100%;
      display: grid;
      justify-items: center;
      gap: 1rem;
      .woman {
        width: 24rem;
      }
      h1 {
        text-align: center;
        margin: 0;
        font-weight: 500;
        font-size: 2rem;
      }
      p {
        text-align: center;
        max-width: 26rem;
        font-weight: 300;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .signup-stripe {
    .stripe-sidebar {
      background-color: $color-dark;
      position: fixed;
      top: 0;
      left: 0;
      min-width: 33%;
      width: 33%;
      z-index: 1;
      height: 100%;
      padding: 1rem 2.31rem 1rem 2.31rem;
    }
    .content {
      display: grid;
    }
  }
}
