.caregiver-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  .modal-content {
    background-color: #f8f5f5;
    padding: 1rem 0;
  }
  .title {
    text-align: center;
    font-size: 2rem;
    font-weight: 700;
    padding-bottom: 0.5rem;
  }
  .description {
    text-align: center;
    font-size: 0.875rem;
    padding-bottom: 0.5rem;
    font-weight: 300;
    margin: 0 auto;
  }
}

@include media-breakpoint-down(sm) {
  .caregiver-modal {
    .title {
      font-size: 1.5rem;
    }
    .description {
      font-size: 0.75rem;
    }
  }
}
