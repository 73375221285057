.dashboard-coach-add-calls {
  .d-contents {
    display: contents;
    .col {
      padding: 0px;
    }
  }

  .no-data {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0.5rem;
    border: none;
    padding-bottom: 0;
    .phone-container {
      width: 3rem;
      height: 3rem;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #f0ece8;
    }
    p {
      color: $color-gray-lightest;
      font-size: $font-size-normal;
      font-weight: 300;
    }
    @include media-breakpoint-down(md) {
      background-color: $color-dark;
      padding-bottom: 1rem;
      .phone-container {
        background-color: #4a4949;
      }
    }
  }

  .coach-add-calls-btn .card {
    background-color: $color-dark;
    color: $white;
    border-radius: 0.5rem;
    padding: 0;
    width: 100%;

    .card-body {
      width: 1.625rem;
      height: 1.625rem;
      padding: 0;
      font-size: 0.875rem;

      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .card-title {
    font-size: 1rem;
    font-weight: 300;
    color: $white;
  }
}

.fa-icon {
  color: $white;
}

.coach-upcomingcalls-card {
  min-width: 100%;
  background-color: $color-dark;
}

.upcoming-calls-title {
  color: $color-text-normal;
  font-size: 1rem;
  font-weight: 200;
  margin: 0;
  span {
    font-weight: bolder;
    color: $color-dark;
  }
}
