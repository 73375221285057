.subscriptions-internal-subscriptions-modal {
  .modal-header {
    align-items: baseline;
  }
  .modal-body {
    @extend .mrm-px-1_5;
    @extend .mrm-pb-2;

    form {
      display: flex;
      flex-direction: column;

      label {
        @extend .mrm-mt-1;
        font-size: 0.875rem;
        color: var(--primary-gray);
        padding-bottom: 0.5rem;
      }

      input {
        padding-left: 1rem;
      }
    }
    .error-message {
      background-color: var(--primary-red);
      color: white;
      padding: 0.5rem;
      border-radius: 5px;
    }
  }
}
