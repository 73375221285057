@import '../../styles/mixins';

.dashboard-connect-to-get-paid {
  background-color: $color-white;
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 999999;

  .header {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: 100%;
    align-items: center;
    justify-items: center;
    img {
      width: 10rem;
    }
    svg {
      margin-top: -2.5rem;
      margin-left: -2.5rem;
    }
  }

  .content {
    padding: 0 1rem;
    bottom: 0;
    margin-top: 30%;
    p {
      font-size: $font-size-smallest;
      color: #828181;
    }
    h1 {
      font-family: $serif-font-family;
      padding-bottom: 1.2rem;
      font-size: 2rem;
    }
    .description {
      color: $color-dark;
      font-weight: 300;
      font-size: $font-size-normal;
    }
    .button-container {
      margin-top: 1.5rem;
      p {
        color: $color-dark;
        font-size: $font-size-normal;
      }
      .stripe_button {
        min-width: 100%;
        display: flex;
      }

      button {
        background-color: $color-dark;
        color: $white;
        display: inline-flex;
        align-items: center;
        border-radius: 0.7rem;
        gap: 1rem;
        height: 4.5rem;
        min-width: 100%;
        font-size: 1rem;
        font-weight: 500;
        padding-right: 16rem;
        white-space: nowrap;
        margin-top: 1rem;
      }

      .button-icon {
        height: 100%;
        border-color: white;
        display: inline-flex;
        align-items: center;
        border-right: 1px solid #595959;
        padding: 0 2rem;
      }
    }
    .learn {
      text-decoration: underline;
      font-weight: 500;
      margin-top: 2rem;
    }
  }
}
