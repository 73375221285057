@import '../../styles/mixins';

.auth-signup {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  @include media-breakpoint-down(sm) {
    height: 100%;
  }
  margin: 0 auto;
  overflow-y: auto;
  align-items: center;
  .auth-signup-navbar {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $white;
    padding: 1.25rem 7.5%;
    z-index: 1;
    img {
      width: 7.125rem;
      object-fit: contain;
    }

    @include media-breakpoint-down(md) {
      display: grid;
      grid-template-columns: 1fr;
      padding: 0;
      place-content: center;
    }

    &__logo-container {
      display: flex;
      align-items: center;
      justify-content: center;
      @include media-breakpoint-down(md) {
        height: 65px;
        box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
        width: 100%;
      }
    }
  }
  .content-container {
    position: relative;
    object-fit: cover;
    background-repeat: no-repeat;
    width: 100%;
    display: grid;
    place-items: start;
    padding: 2rem 0;
    min-height: 100vh;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include media-breakpoint-up(md) {
      padding: 0;
      max-height: 100vh;
    }
    justify-content: center;
    align-items: center;

    video,
    .background-image {
      position: absolute;
      top: 0;
      z-index: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      background-position: contain;
      transform: scaleX(-1);
      @include media-breakpoint-down(sm) {
        filter: blur(6px);
      }
    }
    .background-image {
      transform: scaleX(1);
    }
    .signup-container {
      z-index: 1;
      background-color: $white;
      width: 90vw;
      padding: 2rem;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      border-radius: 1.2rem;

      h1 {
        font-size: $font-size-larger;
        font-weight: 700;
        text-align: center;
      }
      p {
        font-size: $font-size-smaller;
        font-weight: 300;
        text-align: center;
      }
      form {
        display: grid;
        grid-template-columns: 1fr 1fr;
        @include media-breakpoint-down(sm) {
          display: flex;
          flex-direction: column;
        }
        gap: 0.75rem;
        margin-top: 1rem;
        width: 100%;
        .form-group {
          display: flex;
          flex-direction: column;
          margin-bottom: 0;
          label {
            font-size: $font-size-smallest;
          }
          .password-container {
            position: relative;
            button {
              position: absolute;
              top: 50%;
              right: 0.5rem;
              transform: translateY(-50%);
              cursor: pointer;
              outline: none;
            }
          }
          input {
            padding: 0.6rem;
            padding-left: 1rem;
            background-color: #f8f8f8;
            margin: 0;
            width: 100%;
            border: none;

            &::placeholder {
              font-weight: 400;
              color: $color-dark;
              opacity: 0.7;
              font-size: 14px;
              font-family: $sans-serif-font-family;
            }
            @include media-breakpoint-down(sm) {
              padding: 0.45rem 0.6rem;
              font-size: 16px;
              &::placeholder {
                font-size: 12px;
              }
            }
            &:after {
              content: '*';
            }
          }
          #phone_number {
            padding-left: 3rem;
            border-radius: 0.625rem;
          }
          .react-tel-input {
            .flag-dropdown {
              background: #f8f8f8;
              border: none;
              border-radius: 0.525rem;
              .selected-flag {
                background: transparent;
                padding-left: 1rem;
              }
            }
            .country-list {
              box-shadow: 0px 4px 20px 1px rgba(0, 0, 0, 0.1);
              border-radius: 0.525rem;
              li {
                font-weight: 300;
              }
            }
          }
        }

        .password-requirements {
          display: grid;
          grid-template-columns: 1fr 1fr;
          @include media-breakpoint-down(sm) {
            grid-template-columns: 1fr;
          }
          grid-template-rows: auto;
          grid-column: span 2 / auto;
          gap: 0.25rem;
          width: 100%;
          margin: 0;
          margin-bottom: 2rem;
          .title {
            @include media-breakpoint-up(md) {
              grid-column: span 2 / auto;
            }
            font-size: 0.75rem;
            font-weight: 600;
            margin-bottom: 0.25rem;
            color: $color-dark;
            font-family: $sans-serif-font-family;
            padding: 0;
            text-align: left;
          }
          &__item {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 0.5rem;
            width: 100%;
            &__icon {
              object-fit: contain;
              font-size: 0.75rem;
              color: #e8744f;
            }
            &__text {
              font-size: 0.75rem;
              font-weight: 400;
              color: $color-dark;
              white-space: nowrap;
            }
          }
        }
        .terms-check {
          display: flex;
          gap: 0.5rem;
          grid-column: span 2 / auto;
          margin-top: 0.5rem;
          .terms-container {
            .agree {
              font-weight: 600;
              cursor: pointer;
              user-select: none;
            }
            p {
              font-size: $font-size-smallest;
            }
            a {
              font-size: $font-size-smallest;
              color: $color-dark;
              text-decoration: underline;
              font-weight: 300;
              cursor: pointer;
            }
          }
        }
        .error-message {
          color: $color-red;
          font-size: $font-size-smallest;
          margin: 0.5rem 0;
          font-weight: 700;
          text-align: start;
        }
        .signup-btn {
          margin-top: 1.5rem;
          width: 100%;

          color: $white;
          padding: 0.5rem 0;
          margin: 0 auto;
          max-height: max-content;
          border-radius: 0.4rem;
          grid-column: span 2 / auto;
          font-weight: 700;
        }
      }
      @include media-breakpoint-up(md) {
        min-height: 593px;
        height: max-content;
        width: 37.12rem;
        padding: 3.5rem;
        h1 {
          font-size: 2rem;
        }
        p {
          font-size: $font-size-normal;
        }
      }
    }
  }
}
