.rpm-client-profile-right-header {
    background-color: $color-dark;
    width: 100%;
    height: 10.25rem;
    min-height: 10.25rem;
    border-radius: 0.625rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 2rem;

    grid-area: header;

    .wheel-container {
        display: flex;
        gap: 1.5rem;
    }

    &__left {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 0.5rem;
        &__status {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 0.5rem;
            border-radius: 7.8125rem;
            padding: 0.5rem;
            border: 1px solid #718371;
            margin-bottom: 0.5rem;
            .dot {
                height: 0.3125rem;
                width: 0.3125rem;
                aspect-ratio: 1/1;
                border-radius: 50%;
                background-color: #718371;
            }
            .text {
                font-size: 0.625rem;
                color: #718371;
                font-weight: 500;
            }
        }

        .eligible {
            border: 1px solid #718371;
            .dot {
                background-color: #718371;
            }
            .text {
                color: #718371;
            }
        }

        .ineligible {
            border: 1px solid #e8744f;
            .dot {
                background-color: #e8744f;
            }
            .text {
                color: #e8744f;
            }
        }

        h3 {
            font-size: 1rem;
            color: $white;
            font-weight: 600;
        }

        &__text {
            font-size: 0.75rem;
            font-weight: 600;
            color: #a4a2a1;
        }
    }

    &__right {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 0.5rem;
        &__title {
            font-size: 0.625rem;
            font-weight: 600;
            color: #a4a2a1;
        }
        &__text {
            font-size: 0.75rem;
            font-weight: 400;
            color: $white;
            text-align: center;
        }
    }
}

@include media-breakpoint-down(sm) {
    .rpm-client-profile-right-header {
        flex-direction: column;
        align-items: center;
        height: auto;
        min-height: auto;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        max-width: 100%;
        gap: 2rem;
        .wheel-container {
            justify-content: space-between;
            width: 100%;
        }
        &__right {
            flex-direction: row;
            gap: 2rem;
            align-items: center;
            &__text {
                font-size: 0.635rem;
            }
        }
    }
}
