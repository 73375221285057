@import '../../../../../styles/mixins';

.support-radio-container {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  .radio-icon {
    cursor: pointer;
  }
  p {
    font-size: $font-size-small;
    font-weight: 500;
    min-width: 100%;
  }
}
