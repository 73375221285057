@import '../../styles/mixins';

.dashboard-dashboard-page {
  min-height: 100vh;

  .tab-selector-container {
    height: 2rem;
  }
  .dashboard-tab-selector {
    background-color: $color-background-light;
    @extend .mrm-px-1;
    position: fixed;
    left: 0;
    right: 0;

    .nav {
      @extend .mrm-py-0_5;
    }
    .nav-link {
      color: $color-dark-text-light;
      font-size: $font-size-smaller;
      font-weight: normal;
      &.active {
        color: $color-text-black;
      }
      &:hover {
        color: initial;
      }
    }
  }
  background-color: $color-bg-white;
  .dashboard-tab-selector {
    background-color: $color-bg-white;
  }
}
