.progress-bar-container {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: -1rem;
  .progress-bar {
    width: 100%;
    position: relative;
    border-radius: 5px;
    background-color: transparent;
    .progress-wrapper {
      overflow: hidden;
      width: 100%;
      height: 5px;
      @include media-breakpoint-up(md) {
        height: 8px;
      }
      font-size: 12px;
      display: flex;
      background-color: #e5e5e5;
      border-radius: 5px;
      .progress {
        position: absolute;
        height: 100%;
        display: flex;
        flex-direction: column;
        text-align: center;
        white-space: nowrap;
        color: #88a088;
        background-color: #88a088;
        justify-content: center;
      }
    }
  }
  .text {
    font-size: 10px;
    @include media-breakpoint-up(md) {
      font-size: 12px;
    }
    font-weight: 600;
    color: #555555;
  }
}
