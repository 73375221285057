.upcomingcalls-card {
  background-color: $color-background-dark;
  color: $white;
  box-shadow: 0px 4px 20px 1px rgba(0, 0, 0, 0.1) !important;

  .card-body {
    padding-left: 70px;
  }

  .date-text {
    font-weight: 200;
    font-size: $font-size-smallest;
    color: $color-dark-text-normal;
    padding-left: 0px;
  }
  .upcomingcalls-icon {
    height: 43px;
    width: 43px;
    position: absolute;
    left: 15px;
    top: 20px;
    background-color: #4a4949;
    border-radius: 50%;
    margin-right: 5px;

    svg {
      margin-top: 10px;
      margin-left: 14px;
      color: lighten($color-text-black, 20%);
    }
  }
  .edit-call-btn {
    position: absolute;
    right: 0.7em;
    top: 1.7em;
    color: lighten($color-text-black, 20%);
  }
}

.coach-upcomingcalls-card {
  @include media-breakpoint-up(lg) {
    @include mrm-white-on-white-box-shadow;
  }
}
