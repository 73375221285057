.client-message-prompt-card {
  background-color: $color-white;
  box-shadow: none;
  width: 100%;
  align-items: center;
  display: grid;
  padding: 1rem;
  font-weight: 200;

  h1 {
    font-family: $serif-font-family;
    font-size: 1.3rem;
    font-weight: bold;
    text-align: left;
    span {
      font-family: $serif-font-family;
      text-transform: capitalize;
    }
  }
  button {
    background-color: $color-dark;
    border: none;
    border-radius: 0.6rem;
    color: $color-white;
    font-weight: 500;
  }
}

@include media-breakpoint-up(lg) {
  .client-message-prompt-card {
    position: relative;
    padding: 2rem;
    height: min-content;
    top: 0;
    /*     max-height: 19rem; // this seems to fix the issue with the card being too tall on safari
 */
  }
}
