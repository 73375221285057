.chat-page {
    .chat-page-wrapper {
        .messages-container {
            .sender {
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 1rem;
                background-color: $white;
                border-radius: 1.8rem 1.8rem 0px 0px;
                gap: 1rem;

                .new-message {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    width: 100%;
                    height: 100%;
                    .message-header {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: space-between;
                        width: 100%;
                        padding: 1rem;
                        padding-bottom: 0;
                        p {
                            font-weight: 300;
                        }
                    }

                    .input-container {
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        margin-top: 2rem;
                        align-items: flex-start;
                        padding: 0 1rem;
                        p {
                            font-weight: 500;
                        }
                        input {
                            width: 100%;
                            border: none;
                            outline: none;
                            padding: 0;
                            margin-top: -1rem;
                            margin-bottom: 1rem;
                            border-bottom: 1px solid #e6e6e6;
                            border-radius: 0;
                            padding-bottom: 0.5rem;
                            &::placeholder {
                                color: rgba(0, 0, 0, 0.25);
                                font-weight: 300;
                            }
                        }
                    }
                    .recipients {
                        height: 100%;
                        overflow-y: auto;
                        max-height: 100%;
                        height: 100%;
                        align-items: flex-start;
                        padding: 0 1rem;
                        width: 100%;

                        .recipient {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            gap: 0.5rem;
                            padding: 0.5rem 0;
                            img {
                                width: 2rem;
                                height: 2rem;
                                object-fit: contain;
                                border-radius: 0.5rem;
                            }
                            .name, .email {
                                font-weight: 500;
                                font-size: 0.75rem;
                            }
                            
                        }
                    }

                    .name-group-container {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-end;
                        width: 100%;
                        gap: 1rem;

                        input {
                            width: 90%;
                            margin: 0 auto;
                            margin-top: 5vh;
                            border: none;
                            border-bottom: 1px solid #e6e6e6;
                            outline: none;
                            padding: 0;
                            border-radius: 0;
                        }

                        button {
                            width: max-content;
                            padding: 0.5rem 1rem;
                            display: flex;
                            align-items: center;
                            justify-items: center;
                            color: $white;
                            background-color: $color-dark;
                            border-radius: 0.4rem;
                            margin-right: 5%;
                        }
                    }
                }

                .record {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: 0.5rem;
                    .microphone {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 3.07rem;
                        width: 3.07rem;
                        background-color: $color-dark;
                        border-radius: 50%;
                        cursor: pointer;
                    }
                    .label {
                        font-size: 0.6rem; //
                        opacity: 0.4;
                    }
                }

                .input-container {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    gap: 1rem;
                    .input-wrapper {
                        display: flex;
                        position: relative;
                        align-items: center;
                        width: 100%;
                        textarea {
                            width: 100%;
                            padding-top: 0.75rem;
                            padding-left: 1rem;
                            padding-right: 12%;
                            max-height: 10rem;
                            &::placeholder {
                                color: rgba(0, 0, 0, 0.25);
                            }
                        }
                        button {
                            position: absolute;
                            right: 1rem;
                        }
                    }
                }

                .mic-recording {
                    background-size: cover;
                    background-repeat: no-repeat;
                    background-position: center;
                    height: 6rem;
                    width: 6rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                }
            }
        }
    }
}
