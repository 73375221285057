.rpm-dashboard {
  .mobile-records {
    grid-area: mobile-records;
    .mobile-records-header {
      width: 100%;
      display: flex;
      flex-direction: column;
      background-color: $color-dark;
      border-radius: 0 0 35px 35px;
      padding: 1.5rem;
    }
  }
}
