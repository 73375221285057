@import '../../../styles/mixins';

.user-components-user-avatar-editor {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  width: 100%;

  max-width: fit-content;
  margin: 0 auto;
  .upload-photo {
    @extend .mrm-my-0_5;
    text-align: start;

    .avatar-info {
      display: grid;
      margin-left: 0.375rem;
      p {
        margin: 0;
        font-weight: 500;
        margin-bottom: -0.35rem;
        margin-left: 0.1rem;
      }
      button {
        font-size: $font-size-small;
        color: $color-dark;
        padding: 0;
        min-height: 0;
        font-weight: 300;
        width: max-content;
      }
    }
  }
}

.photo-edit-body {
  .scale-slider {
    @extend .mrm-m-1;
  }

  .edit-actions {
    display: flex;
    align-items: center;
    justify-content: space-around;
    @extend .mrm-mb-1;
  }
}
