.rpm-client-profile {
  &__container {
    &__right-body {
      &__header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        position: relative;
        &__options-wrapper {
          display: flex;
          flex-direction: column;
          width: 100%;
          &__options {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            gap: 0.5rem;
            max-width: 36rem;

            &__option {
              padding: 0.875rem 0;
              width: 6.875rem;
              max-width: 6.875rem;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              cursor: pointer;

              &__text {
                font-size: 0.875rem;
                font-weight: 400;
                color: #686868;
                white-space: nowrap;
              }
            }

            @include media-breakpoint-down(sm) {
              max-width: 100vw;
              &__option {
                width: 3.375rem;
                max-width: 3.375rem;
                &__text {
                  @include media-breakpoint-down(sm) {
                    font-size: 0.75rem;
                  }
                }
              }
            }
          }
          &__selected-bar {
            width: 100%;
            position: relative;
            height: 1px;
            background-color: #d3d0cc;
            display: flex;
            flex-direction: row;
            &__bar {
              position: absolute;
              width: 6.875rem;
              @include media-breakpoint-down(sm) {
                width: 3.375rem;
              }
              height: 2px;
              background-color: #e8744f;
              bottom: 0;
              left: 0;
              transition: all 0.3s ease-in-out;
            }
          }
        }
        &__add {
          background-color: #343434;
          height: 1.25rem;
          width: 1.25rem;
          aspect-ratio: 1/1;
          border-radius: 0.3125rem;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          position: absolute;
          right: 0;
          outline: none;
          position: absolute;
          cursor: pointer;
          &__options {
            position: absolute;
            top: 1.75rem;
            right: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 9.3125rem;
            height: max-content;
            background-color: $white;
            border: 1px solid #f8f5f5;
            box-shadow: 1px 1px 20px 1px rgba(0, 0, 0, 0.05);
            border-radius: 4px;
            z-index: 1;
            &__option {
              height: 2.625rem;
              width: 100%;
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;
              cursor: pointer;
              min-height: 43px;
              border-bottom: 1px solid rgba(196, 196, 196, 0.3);

              &:last-child {
                border-bottom: none;
              }
              &__text {
                font-size: 0.75rem;
                font-weight: 600;
              }
            }
          }
        }
      }
    }
  }
}
