.answer-question-modal {
  .modal-content {
    padding: 2rem 2rem;

    @include media-breakpoint-down(sm) {
      padding: 2rem 1rem;
      position: fixed;
      bottom: 0;
    }
  }
  .answer-modal-header {
    .btn-save-question {
      @include media-breakpoint-down(sm) {
        color: $color-orange;
      }
    }
  }
  .answer-modal-width {
    width: 40%;
    @include media-breakpoint-down(sm) {
      width: 100%;
      margin: 0 auto;
    }
    max-width: none !important;
  }
}

h1 {
  font-size: $font-size-largest;
  font-weight: 700;
  font-family: $serif-font-family;
  text-align: center;
}

.modal-header {
  display: flex;
  align-items: flex-start;

  .cancel-btn {
    font-weight: 500;
  }

  h1 {
    font-size: $font-size-small;
    font-family: $sans-serif-font-family;
    font-weight: 300;
    margin-top: 0.2rem;
    line-height: 1.1rem;
    max-width: 50%;
  }
}

.modal-body {
  .form-control {
    border: none;
    box-shadow: 0px -4px 20px 1px rgba(0, 0, 0, 0.05);
    border-radius: 0.6rem;
    padding: 1rem;
    font-weight: 300;
  }
}
