.rpm-dashboard {
  .dashboard-records-footer {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
    margin-top: 1rem;
    .last-synced {
      font-size: $font-size-smaller;
      color: #908e87;
      font-weight: 500;
    }
    .sync-now {
      p {
        text-decoration: underline;
        font-size: $font-size-smaller;
        color: #908e87;
        font-weight: 500;
      }
      display: flex;
      align-items: center;
      gap: 0.5rem;
      cursor: pointer;
    }

    @include media-breakpoint-down(md) {
      width: calc(100% - 18px);
      margin: 0 auto;
      margin-top: 1rem;
      .last-synced {
        color: $white;
        font-size: $font-size-smallest;
      }
      .sync-now {
        p {
          color: $white;
          font-size: $font-size-smallest;
        }
      }
    }
  }
}
