@import '../../styles/mixins';
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;1,300;1,500;1,600;1,700&display=swap');

// colors
$background: #f0ece8;
$dark: #343434;

// default for all text
p {
  font-family: $sans-serif-font-family;
  color: $dark;
  p {
    margin: 0;
  }
}

.dashboard-clients {
  background-color: $background;
  width: 100%;
  height: 100%;
  overflow: hidden;

  .header {
    margin-bottom: 2.5rem;
    font-weight: normal;
  }

  .main-content {
    background-color: transparent;
    padding: 2rem;
    border-radius: 0.8rem;
    .main-header {
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: center;
      flex-shrink: 0;
      p {
        margin: 0;
      }
      .add-sub {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.6rem;
        gap: 0.5rem;
        background-color: $color-dark;
        color: $white;
        font-size: $font-size-smallest;
        border-radius: 0.4rem;
        font-weight: 500;
      }
    }

    .subscriptions-table {
      width: 100%;
      background-color: transparent;
      border-radius: 0.6rem;
      margin-top: 2rem;
      overflow: scroll;
      display: none;
      td {
        vertical-align: center;
        padding: 0.8rem 0rem 0.8rem 0rem;
      }
      th {
        padding: 1rem 0rem 1rem 0rem;
        font-weight: 300;
        font-size: 0.8rem;
        color: rgba(52, 52, 52, 0.5);
      }

      tr {
        border-bottom: 1px solid rgba(52, 52, 52, 0.1);
      }
      p {
        font-size: 0.8rem;
        font-weight: 300;
      }
      .first {
        padding: 0rem 1rem 0rem 1rem;
        max-width: min-content;
        width: 2rem;
        margin: 0;
      }

      .user {
        display: inline-flex;
        align-items: center;
        gap: 0.5rem;

        .title {
          h3 {
            margin: 0;
            font-weight: 300;
            font-size: 0.9rem;
          }
          p {
            font-size: 0.6rem;
            font-weight: 600;
            color: #88a088;
          }
        }
      }
      .tag {
        padding: 0.4rem;
        border-radius: 16px;
        max-width: min-content;
        background: $color-gray-lightest;
        font-size: 0.625rem;
      }
      .active {
        background: rgba(136, 160, 136, 0.4);
        color: $color-green;
      }
      .canceled {
        background: rgba(196, 114, 88, 0.3);
        color: $color-red;
      }
      .subscribed {
        background: rgba(136, 160, 136, 0.4);
      }
      .unsubscribed {
        background: $color-gray-lightest;
      }
      .pending {
        background: #f2f2f2;
        color: #a1a1a1;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .dashboard-clients {
    background-color: $background;
    width: 69rem;
    margin: 0 auto;
    overflow: hidden;
    display: flex;
    gap: 5rem;
    max-height: 100vh;
    overflow-y: scroll;
    align-items: flex-start;
    height: 90vh;

    .header {
      margin-bottom: 2.5rem;
      font-weight: normal;
    }

    .main-content {
      width: 100%;
      background-color: $color-white;
      padding: 2rem;
      border-radius: 0.8rem;
      margin-top: 5vh;
      overflow-y: auto;
      height: fit-content;
      // margin-left: 25%;
      // margin-top: 2.7rem;
      p {
        padding-top: 0.4rem;
      }
      .main-header {
        flex-direction: row;
        justify-content: space-between;
      }

      .subscriptions-table {
        width: 100%;
        background-color: white;
        box-shadow: 1px 1px 20px 1px rgba(0, 0, 0, 0.05);
        border-radius: 0.6rem;
        margin-top: 2rem;
        display: table;
        td {
          vertical-align: center;
          padding: 1.5rem 0rem;
        }
        th {
          padding: 1rem 0rem 1rem 0rem;
          font-weight: 300;
          font-size: 0.8rem;
          color: rgba(52, 52, 52, 0.5);
        }

        tr {
          border-bottom: 1px solid rgba(52, 52, 52, 0.1);
        }
        p {
          font-size: 0.8rem;
          font-weight: 300;
        }

        .first {
          padding: 0rem 1rem 0rem 1rem;
          max-width: min-content;
          width: 2rem;
          margin: 0;
        }

        .user {
          display: inline-flex;
          align-items: center;
          gap: 0.5rem;

          .title {
            h3 {
              margin: 0;
              font-weight: 300;
              font-size: 0.9rem;
            }
            p {
              font-size: 0.6rem;
              font-weight: 600;
              color: #88a088;
            }
          }
        }
        .tag {
          padding: 0.4rem;
          border-radius: 16px;
          max-width: min-content;
          background: $color-gray-lightest;
          font-size: 0.625rem;
        }
        .active {
          background: rgba(136, 160, 136, 0.4);
          color: $color-green;
        }
        .canceled {
          background: rgba(196, 114, 88, 0.3);
          color: $color-red;
        }
        .subscribed {
          background: rgba(136, 160, 136, 0.4);
        }
        .unsubscribed {
          background: $color-gray-lightest;
        }
        .pending {
          background: #f2f2f2;
          color: #a1a1a1;
        }
      }
    }
  }
}

.mobile-subscribers {
  display: flex;
  flex-direction: column;
  .row {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0rem;
    margin: 0 auto;
    border-bottom: 0.3px solid rgba(196, 196, 196, 0.3);
    .profile-info {
      display: flex;
      justify-content: flex-start;
      gap: 0.5rem;
      .name-container {
        .client-name {
          font-size: $font-size-normal;
        }
        .sub-name {
          font-size: $font-size-small;
          font-weight: 300;
        }
      }
    }
    .price {
      .sub-status {
        font-size: $font-size-smallest;
        font-weight: 300;
        padding: 0.5rem;
        border-radius: 1rem;
        background: rgba(136, 160, 136, 0.4);
        color: $color-green;
        text-align: center;
      }
      .sub-price {
        font-size: $font-size-smaller;
        font-weight: 500;
        text-align: end;
        padding-top: 0.5rem;
        padding-right: 0.5rem;
      }
    }
  }
}
