.rpm-dashboard {
  .health-data-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 1rem;
    .title {
      color: #ffffff;
      font-size: $font-size-normal;
      font-weight: 400;
    }
    @include media-breakpoint-down(md) {
      width: calc(100% - 3rem);
      margin: 0 auto;
      margin-top: 1rem;
      .title {
        color: #515151;
        font-size: $font-size-normal;
        font-weight: 500;
      }
    }
  }
}
