.rpm-provider-dashboard,
.rpm-coach-dashboard {
    .rpm-provider-container,
    .rpm-coach-container {
        .search-container {
            .right-search-container {
                .search-container-wrapper {
                    .search-container-filter-sort-container {
                        width: 21.5625rem;
                        height: 14.25rem;
                        background-color: $white;
                        border: 1px solid #e5e5e5;
                        position: absolute;
                        top: 120%;
                        right: 0;
                        z-index: 50;
                        border-radius: 0.625rem;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        .search-container-filter-sort-container-item {
                            height: 3.5rem;
                            width: 90%;
                            display: flex;
                            align-items: center;
                            justify-content: flex-start;
                            gap: 0.5rem;
                            border-bottom: 1px solid #e5e5e5;
                            cursor: pointer;
                            p {
                                font-size: $font-size-small;
                                font-weight: 400;
                                user-select: none;
                            }
                        }
                    }
                }
            }
        }
    }
}
