.card {
  @extend .mrm-main-box-shadow;
}

.card {
  border-radius: 10px;
  border: none;
}

.card-img {
  border-radius: 10px;
}

.card-title {
  margin-bottom: 0;
}
