@import '../../styles/mixins';

.notifications-default-page {
  min-height: 100vh;
  background-color: $color-white;

  .container {
    @extend .mrm-pt-0_5;
  }
  .notification-header {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    h1 {
      font-family: $serif-font-family;
      font-size: larger;
      padding: 2rem 0 1rem 0;
    }
  }
}

.mark-all-as-read-modal {
  .modal-content {
    background-color: transparent;
    border: none;
  }
}
