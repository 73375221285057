.chat-page {
    .chat-page-wrapper {
        .messages-container {
            .messages {
                padding: 1rem;
                overflow-y: auto;
                display: flex;
                flex-direction: column;
                gap: 1rem;
                height: 100%;
                @include media-breakpoint-up(lg) {
                    max-height: 48vh;
                    height: 48vh;
                }

                .message {
                    display: flex;
                    flex-direction: column;
                    border-radius: 10px;
                    gap: 1rem;
                    .message-header {
                        display: flex;
                        gap: 0.5rem;
                        align-items: flex-start;
                        width: 100%;
                        img {
                            width: 2rem;
                            height: 2rem;
                            object-fit: contain;
                        }
                        .name-and-time {
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            gap: 0.25rem;
                            .name {
                                font-weight: 500;
                                font-size: $font-size-normal;
                            }
                            .time {
                                font-size: $font-size-smallest;
                                opacity: 0.5;
                            }
                        }
                    }
                    .message-body {
                        font-weight: 300;
                        font-size: $font-size-smaller;
                        background-color: $white;
                        width: 100%;
                        white-space: pre-wrap;
                        overflow-wrap: break-word;
                        padding: 1.4rem;
                        a {
                            word-break: break-all;
                        }
                    }
                }
            }
        }
    }
}
