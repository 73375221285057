@import '../../styles/mixins';

.common-desktop-header-container {
  .static-placeholder {
    visibility: hidden;
  }

  .fixed-actual-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
  }
}

.common-desktop-header {
  width: 100%;

  .top-menu-container {
    background: $color-white;
    border-bottom: 1px solid #f0f1f2;
  }

  .dropdown-item {
    margin-bottom: 5px;

    &:active {
      background: none;
    }

    &:hover {
      background: $color-white;
      filter: brightness(0.95);
    }

    svg {
      font-size: $font-size-large;
    }
  }

  .badge {
    position: absolute;
    top: -4px;
    left: 12px;
    cursor: pointer;
  }

  .row {
    margin-left: 0;
    margin-right: 0;
    min-height: get-scaled-size($scale-factor-2);
    align-items: center;
    justify-content: space-between;

    .col {
      flex-basis: content;

      &.left-menu {
        img {
          @extend .mrm-mr-1_5;

          max-height: 4.375rem;
          border-radius: 10px;
          // border: 1px solid $color-assessment-none;
          margin-top: 5px;
          margin-bottom: 5px;
          height: 4.375rem;
          // width: 4.375rem;
          display: block;
        }

        a {
          color: $color-text-disabled;

          &:hover {
            color: $color-text-light;
          }
        }

        a.active {
          color: $color-text-normal;

          &:hover {
            color: $color-text-normal;
            opacity: 1;
          }
        }

        .company-logo {
          &:hover {
            opacity: 1;
          }
        }
      }

      &.right-menu {
        a {
          color: $color-text-disabled;
          font-size: $font-size-normal;

          &:hover {
            color: $color-text-light;
            opacity: 1;
          }
        }

        a.active svg {
          color: $color-text-normal;

          &:hover {
            color: $color-text-normal;
            opacity: 1;
          }
        }

        a.active {
          color: $color-text-normal;

          &:hover {
            color: $color-text-normal;
            opacity: 1;
          }
        }

        .dropdown-item {
          color: $color-text-normal;
        }

        .menu-item {
          position: relative;
          @extend .mrm-ml-1_5;

          svg {
            color: $color-text-lighter;
            vertical-align: middle;
          }

          &:first-child {
            margin-left: 0;
          }

          &.dropdown {
            display: inline-block;
            vertical-align: middle;
            position: relative;

            .menu-item {
              position: relative;
              vertical-align: text-top;
              font-weight: 500;
              color: $color-gray;

              &:hover {
                opacity: 1;
                color: $color-text-disabled;
              }

              svg {
                color: $color-text-disabled;
                margin-left: 2px;
                position: relative;
                bottom: 1px;

                &:hover {
                  opacity: 1;
                  color: $color-text-disabled;
                }
              }

            }

            .dropdown-menu {
              border-radius: 10px;
              border-color: transparent;
              background: $color-white;
              box-shadow: 5px 4px 21px 5px rgba(0, 0, 0, 0.15);

              a {
                font-size: $font-size-small;
              }

              svg {
                @extend .mrm-mr-0_75;
                width: get-scaled-size($scale-factor-1);
                vertical-align: middle;
                font-size: $font-size-large;
                color: $color-text-normal;
              }

              img {
                @extend .mrm-mr-0_75;
                font-size: $font-size-large;
              }
            }

            .dropdown-menu::after {
              border-bottom: 8px solid $color-white;
              border-left: 9px solid rgba(0, 0, 0, 0);
              border-right: 9px solid rgba(0, 0, 0, 0);
              content: '';
              display: inline-block;
              right: 15.5%;
              /* position */
              position: absolute;
              top: -7px;
            }
          }
        }

        .home-icon {
          margin-right: -0.5rem;
        }

        .profile-photo,
        .initials {
          border: 1px solid transparent;
          padding: 1px;
        }

        .show {
          svg {
            color: $color-text-normal;
          }

          .profile-photo,
          .initials {
            border: 1px solid $color-text-normal;
            border-radius: 50%;
          }

          .notification-row .initials {
            border: none;
          }
        }

        .menu-item-container {
          cursor: pointer;

          .btn {
            font-size: $font-size-normal;
            color: $color-text-normal;

            svg {
              @extend .mrm-ml-0_25;
              color: $color-text-normal;
            }
          }
        }

        .notifications-dropdown-menu {
          border-radius: 10px;
          right: -10px !important;
          position: absolute;
          left: auto;
          // inset: auto !important;

          .notifications-list-wrapper {
            @extend .mrm-px-1_5;
            @extend .mrm-pb-1_5;

            min-width: get-scaled-size($scale-factor-24);
            max-height: calc(100vh - 200px);
            overflow-y: auto;

            /* width */
            &::-webkit-scrollbar {
              width: 8px;
            }

            /* Track */
            &::-webkit-scrollbar-track {
              background: $color-white;
            }

            /* Handle */
            &::-webkit-scrollbar-thumb {
              background: $color-gray;
              border-radius: 10px;
            }

            /* Handle on hover */
            &::-webkit-scrollbar-thumb:hover {
              background: $color-dark;
            }
          }

          .notifications-list-header {
            .notification-title {
              margin-top: 0;
              font-weight: 700;
              line-height: 2;
              margin-bottom: 0;
              font-family: $serif-font-family;
            }
          }

          .mark-all-read-btn {
            text-decoration: underline;
            font-weight: 400;
            color: $color-gray;
            position: relative;
          }

          .notification-row {
            flex-wrap: nowrap;
            width: 100%;

            .initials {
              flex-shrink: 0;
              margin-right: 15px;
              margin-left: 5px;
            }
          }
        }
      }
    }
  }
}

.desktop-page-secondary-header-wrapper {
  h1 {
    font-size: 1.25rem;
    line-height: initial;
  }
}


.profile-header {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-left: 1.6rem;

  .texts-header {
    display: grid;
    justify-items: flex-end;
  }

  .name-header {
    margin: 0;
    color: $color-dark;
    font-size: $font-size-small;
  }

  .view-profile-link {
    color: $color-gray;
    font-size: $font-size-smaller;
    font-weight: normal;
    margin: 0;
    text-decoration: underline;
  }
}

.language-switcher {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
  height: 28.98px;

  &::after,
  &::before {
    display: none;
  }

  .btn {
    padding: 0;
  }

  .btn-outline-primary {
    height: 28.98px;
    color: $color-dark;
    border: none;
    width: 100%;
    background-color: transparent;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-weight: 300;
    padding: 0.25rem 1.5rem;
    outline: none;
    box-shadow: none;

    &:hover {
      color: $color-dark;
    }

    &:after {
      background-color: transparent;
    }

    &:active {
      outline: none;
      box-shadow: none;
    }
  }

  .btn-outline-primary:not(:disabled):not(.disabled):active,
  .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show>.btn-outline-primary.dropdown-toggle {
    color: $color-dark;
    background-color: transparent;
    border-color: transparent;
    outline: none;
  }

  .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
  .btn-outline-primary:not(:disabled):not(.disabled):active:focus,
  .show>.btn-outline-primary.dropdown-toggle:focus {
    box-shadow: none;
  }

  .dropdown-toggle::after,
  .dropdown-toggle::before {
    display: none;
    border: none;
    box-shadow: none;
  }
}

.language-switcher {
  .btn-outline-primary.dropdown-toggle {
    color: $color-dark;
    background-color: transparent;
    outline: none;
    border: none;

    &:focus,
    &:active,
    &:hover,
    &:after,
    &:before {
      box-shadow: none;
      outline: none;
      border: none;
    }
  }
}

.language-switcher-title {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .language-switcher-title-text {
    font-weight: 400;
    display: flex;
    align-items: center;
  }
}

.common-desktop-header {
  .profile-dropdown-menu {
    min-width: 200px;
    width: max-content;
    right: 0 !important; // seems to fix the issue with the dropdown menu not being aligned properly
    top: 2.5rem !important;
    border: 0;
    position: absolute;
    left: auto;
    border-radius: 10px;
    border-color: transparent;
    background: $color-white;
    box-shadow: 5px 4px 21px 5px rgba(0, 0, 0, 0.15);
    padding: .5rem 0;
    z-index: 9999999;

    a {
      font-size: $font-size-small;
    }

    svg {
      @extend .mrm-mr-0_75;
      width: get-scaled-size($scale-factor-1);
      vertical-align: middle;
      font-size: $font-size-large;
      color: $color-dark;
    }

    img {
      @extend .mrm-mr-0_75;
      font-size: $font-size-large;
    }
  }
}

// hide arrow on the language switcher
.common-desktop-header {
  .row {
    .col.right-menu {
      .menu-item.dropdown {
        .language-switcher {
          .dropdown-menu::after {
            display: none;
          }
        }
      }
    }
  }
}

.common-desktop-header .row .col.right-menu a {
  font-size: $font-size-small;
}

.common-desktop-header .row .col.right-menu .menu-item.dropdown .dropdown-menu::after {
  right: 12px;
}

.dropdown-menu {
  border-radius: 10px;
  border-color: transparent;
  background: $color-white;
  box-shadow: 5px 4px 21px 5px rgba(0, 0, 0, 0.15);

  a {
    font-size: $font-size-small;
  }

  svg {
    @extend .mrm-mr-0_75;
    width: get-scaled-size($scale-factor-1);
    vertical-align: middle;
    font-size: $font-size-large;
    color: $color-text-normal;
  }

  img {
    @extend .mrm-mr-0_75;
    font-size: $font-size-large;
  }
}

.dropdown-menu::after {
  border-bottom: 8px solid $color-white;
  border-left: 9px solid rgba(0, 0, 0, 0);
  border-right: 9px solid rgba(0, 0, 0, 0);
  content: '';
  display: inline-block;
  right: 15.5%;
  /* position */
  position: absolute;
  top: -7px;
}

.common-desktop-header .row .col.right-menu .menu-item svg {
  color: $color-dark;
}