@import '../../styles/mixins';

.dashboard-admin-page {
  width: 100%;
  padding-bottom: 2.5rem;

  .desktop-page-secondary-header-wrapper {
    height: auto;
  }
  .my-clients-card-wrapper {
    height: auto;
    max-height: 33rem;
    padding: 0.5rem;
    overflow-y: auto;
    /* width */
    &::-webkit-scrollbar {
      width: 8px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: white;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: $color-gray;
      border-radius: 10px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: $color-dark;
    }
  }
  .dashboard-title {
    color: $color-text-normal;
    font-size: 1rem;
    font-weight: 300;
    margin: 0;

    strong {
      font-weight: 500;
    }
  }
  .common-search-bar {
    flex-grow: 1;

    .search {
      height: 40px;
      background: $white;
      padding-left: 35px;
      -webkit-appearance: none;
      border: none;

      border: 1px solid #e3e5e5;
      border-radius: 10px;

      ::placeholder {
        font-size: 16px;
      }
      // @include media-breakpoint-down(md) {
      //   background: #e1e6eb;
      // }
    }
  }

  .sort {
    background: transparent;
    box-shadow: none;
    font-weight: 500;
    font-size: 16px;
    color: $color-text-normal;

    align-items: center;
    display: inline-flex;

    @include media-breakpoint-down(md) {
      background: transparent;
    }

    &.sort-desktop {
      font-size: $font-size-normal;
      font-weight: normal;

      span,
      svg {
        @extend .mrm-ml-0_25;
        color: $color-text-normal;
      }

      span {
        font-weight: bold;
      }

      svg {
        vertical-align: bottom;
      }
    }
  }

  .no-data {
    font-size: $font-size-normal;
    text-align: left;
    width: 100%;
    border-radius: 10px;
    @extend .mrm-p-1;
    margin-top: 1rem;
    color: $color-gray-lightest;
  }

  &.sort-menu {
    .modal-dialog {
      margin: 0;
      left: 0;
      right: 0;
      bottom: 0;
      position: absolute;
      max-width: none;
    }

    form {
      @extend .mrm-pb-1;
    }

    .modal-content {
      border: none;
      border-top-left-radius: 1em;
      border-top-right-radius: 1em;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    .form-check-input {
      width: 20px;
      height: 20px;
      margin-top: 0.1rem;
      border: 1px solid #727272;
      border-radius: 50%;
      -webkit-appearance: none;
      &:focus {
        outline: none;
      }
      &:checked {
        border: none;
        &:before {
          content: '\25C9';
          color: $color-blue;
          font-size: 22px;
          position: relative;
          top: -7px;
          left: -1px;
        }
      }
    }
    .cancel {
      color: $color-text-light;
    }
    .form-check {
      &:not(:last-child) {
        border-bottom: 1px solid #ddd;
      }
    }
  }

  .student-card {
    cursor: pointer;
    box-shadow: 0px 3px 20px 1px rgba(0, 0, 0, 0.05);

    .card-body {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .more-btn-desktop {
      position: absolute;
      right: 0.7em;
      top: 0.7em;
      z-index: 1;
    }

    .name {
      line-height: 1;
      span a {
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        color: #343434;
      }
    }

    &-roadmap-link {
      color: inherit;
      text-decoration: none;
    }

    .student-dot {
      position: absolute;
      left: 4px;
      top: 1.7em;
    }

    .student-card-roadmap-link {
      &:hover {
        opacity: 1;
      }
    }

    .last-login {
      @extend .mrm-mt-1;
      font-size: $font-size-smallest;
      text-align: right;
      margin-bottom: 0;
      color: $color-text-light;
      span {
        font-weight: 500;
      }
    }

    .dropdown {
      position: absolute;
      right: 0.7em;
      top: 0.7em;
    }
  }

  .form-check {
    @extend .mrm-px-2;
    text-align: left;
    padding-left: 40px;
    &:hover {
      cursor: pointer;
    }

    label {
      width: 100%;
      margin-left: 1rem;
      color: $color-text-normal;
      &:hover {
        cursor: pointer;
      }
    }
  }

  .admin-page-header-wrapper {
    .form-check {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  .mobile-page-container {
    @extend .mrm-px-1_5;
    @extend .mrm-pb-1;
  }

  .desktop-page-container {
    padding: 2rem 0rem 1rem 0rem;
    @include media-breakpoint-down(md) {
      padding-top: 0;
    }
    .content-wrapper {
      > div {
        &:first-child {
          @extend .mrm-mt-3;
          @extend .mrm-mb-2;
          padding-left: 0;
          padding-right: 0;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;

          & > div {
            flex-basis: 49.2%;

            &.infinite-scroll-loader {
              flex-basis: 100%;
            }
          }
        }
      }
    }
  }

  .invite-a-client-prompt {
    h1 {
      font-family: $serif-font-family;
      padding-bottom: 1.2rem;
      font-size: 1.7rem;
    }
    .description {
      max-width: 60%;
    }
    button {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      padding: 0.7rem 1.3rem;
      background-color: $color-dark;
      margin-top: 0.5rem;
      border-radius: 0.6rem;
      p {
        color: $white;
      }
    }
  }
}

// TODO: Extract into a common feature component
.item-count {
  @extend .mrm-mr-0_5;
  @extend .mrm-mt-0_5;
  @extend .mrm-py-0_25;
  @extend .mrm-px-0_5;

  display: inline-flex;
  line-height: 1;
  font-size: $font-size-smaller;
  font-weight: normal;
  border-radius: 6px;
  // height: 23px;
  vertical-align: top;

  svg {
    @extend .mrm-mr-0_25;
    height: 12px;
  }

  img {
    @extend .mrm-mr-0_25;
    height: 10px;
  }

  &.red-count {
    background-color: lighten($color-assessment-red, 35%);
    color: $color-assessment-red;
  }

  &.gray-count {
    background-color: $color-background-light-gray;
    color: $color-dark-text-lighter;
  }
}

.toggle-fixed-position {
  position: sticky;
  top: 80px;
  z-index: 10;
}

.upcoming-calls-container {
  background-color: transparent;
  box-shadow: none;
  width: 100%;
}

.roadmap-grid-db {
  display: grid;
  row-gap: 1rem;

  .roadmap-grid-header {
    display: flex;
    justify-content: space-between;
    padding: 0;
    padding-bottom: 0.5rem;
    p {
      color: $color-text-normal;
      font-size: 1rem;
      font-weight: 200;
      margin: 0;

      strong {
        font-weight: 500;
      }
    }
    .add-rm-btn {
      cursor: pointer;
      background-color: $color-text-normal;
      border-radius: 0.4rem;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 1.625rem;
      height: 1.625rem;
    }
  }
}

.clients-container {
  background-color: $color-white;
  box-shadow: none;
  width: 100%;
  padding-bottom: 2.5rem;
}
@include media-breakpoint-up(lg) {
  .upcoming-calls-container {
    margin-top: 0;
    background-color: $color-white;
  }
}

.roadmap-grid-db-parent {
  background-color: $color-background-light;
  box-shadow: none;
}

.roadmaps-carousel-admin-page {
  width: 100%;
  padding: 0 1.5rem;
  p {
    padding-bottom: 1rem;
    font-weight: 300;
  }

  @include media-breakpoint-up(lg) {
    .title {
      margin-bottom: -1.5rem;
    }
  }
}

@include media-breakpoint-up(lg) {
  .upcoming-calls-container {
    margin-top: 0;
    background-color: $white;
  }

  .roadmap-grid-db-parent {
    background-color: $white;
    box-shadow: none;
  }

  .clients-container {
    background-color: $white;
  }

  .roadmaps-carousel-admin-page {
    padding: 2rem 1.5rem 2.5rem 1.5rem;
    background-color: $color-white;
    border-radius: 0.8rem;
    p {
      padding-bottom: 0;
      font-weight: 300;
    }
  }
}

// Assign a Roadmap to _____

.add-exst-rm-body {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-width: 100%;

  button {
    @extend .mrm-px-2;
  }
}

.add-rm-modal {
  display: grid;
  gap: 1rem;
}

.dashboard-title {
  color: $color-dark;
}

.dashboard-admin-page {
  .sort {
    color: $color-dark;
    .roadmap-grid-db {
      .roadmap-grid-header {
        p {
          color: $color-dark;
        }
        .add-rm-btn {
          background-color: $color-dark;
        }
      }
    }
  }
  .custom-btn-assign {
    background-color: transparent;
    color: $color-dark;
    border: 1px solid $color-dark;
    border-radius: 0.6rem;
    &:hover {
      background-color: $color-dark;
      color: $white;
    }
  }
}

.toggle-show-roadmaps {
  background-color: $white;
  box-shadow: 0px -4px 20px 1px rgba(0, 0, 0, 0.05);
  margin-bottom: 1rem;
}
.roadmap-grid-db-parent {
  background-color: $color-bg-white;
  box-shadow: none;
}
.my-clients-card-wrapper {
  /* Track */
  &::-webkit-scrollbar-track {
    background: $color-white;
  }
}

.roadmap-grid-db-parent {
  background-color: $color-white;
}

@include media-breakpoint-up(lg) {
  .clients-container {
    background-color: $color-white;
  }
  .upcoming-calls-container {
    background-color: $color-white;
  }
}

.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -30px; /* gutter size offset */
  width: auto;
}

.my-masonry-grid_column {
  padding-left: 30px; /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div {
  /* change div to reference your elements you put in <Masonry> */
  margin-bottom: 30px;
}
