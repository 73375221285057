.rpm-download-app-mobile {
    min-height: 100vh;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-top: 15vh;
    position: relative;

    .background {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 100%;
        min-height: 100vh;
        height: 100%;
        object-fit: cover;
    }
    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.8);
    }

    &__logout {
        position: absolute;
        top: 1.5rem;
        right: 50%;
        transform: translateX(50%);
        color: $white;
        font-size: 1rem;
        font-weight: 400;
    }

    &__content {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        height: 100%;
        bottom: 0;
        align-items: center;
        gap: 2.5rem;
        height: 100%;

        h1 {
            font-size: 2rem;
            font-weight: 700;
            color: $white;
            text-align: center;
        }

        &__get-it-on {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 1rem;
            img {
                width: 11.84375rem;
                object-fit: contain;
            }
        }
        &__background {
            width: 84vw;
            object-fit: contain;
        }
    }
}
