.rpm-client-profile {
    &__container {
        &__left {
            .rpm-profile-info-container {
                &__info {
                    &__roadmaps {
                        display: flex;
                        flex-direction: column;
                        gap: 0.5rem;
                        width: 100%;
                        &__roadmap-wrapper {
                            position: relative;
                            width: 100%;
                            height: 6.3125rem;

                            &__three-dots {
                                position: absolute;
                                top: 0.5rem;
                                right: 0.5rem;
                                z-index: 2;
                                transform: rotate(90deg);
                                height: 1.5rem;
                                width: 1.5rem;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                outline: none;
                            }

                            &__three-dots-dropdown {
                                position: absolute;
                                top: 0.5rem;
                                right: 0;
                                z-index: 2;
                                min-height: 2.8125rem;
                                width: 9.3125rem;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                outline: none;
                                background-color: $white;
                                box-shadow: 1px 1px 20px 1px rgba(0, 0, 0, 0.05);
                                border-radius: 0.25rem;
                                flex-direction: column;
                                gap: 0.5rem;
                                &__button {
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    gap: 0.5rem;
                                    font-size: 0.75rem;
                                    font-weight: 600;
                                    color: $color-dark;
                                    cursor: pointer;
                                    outline: none;
                                    height: 2.8125rem;
                                    width: 100%;
                                }
                            }

                            &__roadmap {
                                height: 6.3125rem;
                                display: flex;
                                flex-direction: column;
                                align-items: flex-start;
                                justify-content: center;
                                width: 100%;
                                border-radius: 0.625rem;
                                padding: 1rem;
                                background-color: $white;
                                box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
                                position: absolute;
                                z-index: 1;

                                &:hover {
                                    transform: scale(1.01);
                                    transition: all 0.2s ease-in-out;
                                }

                                p {
                                    text-align: left;
                                }
                                &__header {
                                    display: flex;
                                    align-items: flex-start;
                                    gap: 0.75rem;
                                    width: 100%;
                                    &__title {
                                        font-weight: 600;
                                        color: $color-dark;
                                        font-size: 0.875rem;
                                        // three lines then ellipsis
                                        display: -webkit-box;
                                        -webkit-line-clamp: 3;
                                        -webkit-box-orient: vertical;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        width: 75%;
                                        text-align: left;
                                    }
                                    &__image {
                                        height: 2rem;
                                        width: 2rem;
                                        aspect-ratio: 1/1;
                                        border-radius: 0.5rem;
                                    }
                                }
                                &__progress {
                                    display: flex;
                                    flex-direction: column;
                                    gap: 0.5rem;
                                    margin-top: 0.5rem;
                                    width: 100%;
                                    &__bar {
                                        height: 0.375rem;
                                        background-color: #e5e5e5;
                                        border-radius: 0.3125rem;
                                        position: relative;
                                        &__fill {
                                            height: 100%;
                                            background-color: #e8744f;
                                            border-radius: 0.3125rem;
                                            position: absolute;
                                            top: 0;
                                            left: 0;
                                            width: 50%;
                                        }
                                    }
                                    &__text {
                                        font-size: 0.75rem;
                                        font-weight: 600;
                                        color: #adadad;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.rpm-profile-info-container__info__roadmaps__modal {
    .modal-content {
        padding: 3rem;
    }
    .modal-title {
        font-family: $serif-font-family;
        font-size: 1.5rem;
        font-weight: 700;
    }
    .modal-body {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        p {
            text-align: center;
            margin-bottom: 1rem;
        }
        .remove {
            height: 3.125rem;
            background-color: $color-dark;
            color: $white;
            border-radius: 0.3125rem;
            font-size: 1rem;
            font-weight: 500;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            outline: none;
            width: 100%;
            margin-bottom: 1rem;
        }
        .go-back {
            text-decoration: underline;
            color: $color-dark;
            font-size: 1rem;
            cursor: pointer;
            outline: none;
        }
    }
}
