.new-assessment-modal {
    .modal-content {
        background-color: $white;
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: center;
        padding: 3rem 5rem;

        form {
            margin-top: 1rem;
            .form-group {
                display: flex;
                flex-direction: column;
                label {
                    font-size: 0.75rem;
                    font-weight: 300;
                    color: #40413f;
                    margin-bottom: 0.625rem;
                }
                .assessment-wrapper {
                    position: relative;

                    select {
                        height: 2.8125rem;
                        padding-left: 2.8125rem;
                        padding-right: 1.2rem;
                    }

                    .notes-icon {
                        position: absolute;
                        top: 1rem;
                        left: 1rem;
                        transform: translateY(-50%);
                    }
                }
                .date-wrapper {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    justify-content: space-between;
                    gap: 1rem;
                    p {
                        font-weight: 600;
                    }
                    input {
                        height: 2.8125rem;
                        border: 1px solid #f2f1ec;
                        padding: 0;
                        text-align: center;
                    }

                    #month,
                    #day {
                        width: 4.9375rem;
                        font-weight: 300;
                    }

                    #year {
                        width: 6.25rem;
                        font-weight: 300;
                    }
                }

                input {
                    &::placeholder {
                        opacity: 0.5;
                    }
                }

                #score {
                    width: 4rem;
                    height: 2.8125rem;
                    border: 1px solid #f2f1ec;
                    padding: 0;
                    text-align: center;
                    font-size: 0.875rem;
                    font-style: italic;
                    font-weight: 300;
                }

                #assessment_result_link {
                    width: 100%;
                    height: 2.8125rem;
                    border: 1px solid #f2f1ec;
                    text-align: left;
                    padding-left: 1rem;
                    font-size: 0.875rem;
                    font-style: italic;
                    font-weight: 300;
                }
            }
            .error-message {
                color: $color-red;
                font-size: $font-size-smallest;
                margin: 0;
                margin-bottom: 1rem;
                font-weight: 700;
            }
            .submit-button {
                height: 3.125rem;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: $color-dark;
                color: $white;
                border-radius: 0.625rem;
                outline: none;
                font-weight: 500;
            }

            .cancel-button {
                text-decoration: underline;
                font-size: 0.875rem;
                font-weight: 400;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: transparent;
                margin-top: 1.5rem;
            }
        }
    }
}
