.modal-info {
  .modal-content {
    border-radius: 20px;
  }
  .modal-body {
    padding: 45px 20px;
    .modal-info-noble-logo {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    h4 {
      font-family: $serif-font-family;
      font-weight: 700;
      margin-bottom: 10px;
      text-align: center;
    }
    p,
    li {
      font-family: $sans-serif-font-family;
      font-size: 0.8rem;
      font-weight: 400;
    }
    ul {
      padding-left: 20px;
    }
    h6 {
      margin-top: 15px;
      margin-bottom: 10px;
      text-decoration: underline;
      text-decoration-color: $color-item-yellow;
      text-decoration-thickness: 3px;
    }
  }
}
