.theme-dark {
  // Fonts
  --serif-font-family: 'Merriweather', serif;
  --sans-serif-font-family: 'Poppins', sans-serif;

  // Pure Colors
  --pure-white: #ffffff;
  --pure-black: #000000;

  // Custom Colors
  --primary-white: #f8f5f5;
  --primary-dark: #343434;
  --primary-bg-white: #f0ece8;
  --primary-orange: #ca9d29;
  --primary-gray: #6b6b6b;
  --primary-bg-gray: #aeaeae; // used for the step number background
  --primary-light-gray: #e3e5e5;
  --primary-green: #88a088;
  --primary-dark-green: #6d786e;
  --primary-light-green: #9da16b;
  --primary-red: #c47258;
  --primary-alerts: #e8744f;
  --primary-gray-lighter: #f2f1ec;
  --primary-gray-lightest: #c4c4c4;
  --gray-icon: #bfbfbf;

  // Background Color
  .theme-bg {
    background-color: var(--primary-bg-white);
  }
}
