@import '../../../../styles/mixins';

.action-item-dialog {
  button {
    outline: none;
  }
  .modal-body {
    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 1rem;
      h2 {
        font-family: $serif-font-family;
      }
      .save {
        color: $color-orange;
      }
    }
  }
  .form-control {
    border-color: transparent;
    border-radius: 0.4rem;
    box-shadow: 0px -4px 20px 1px rgba(0, 0, 0, 0.05);
    height: 2.81rem;
    &::placeholder {
      color: $color-gray;
      font-size: $font-size-small;
    }
  }

  .modal-content {
    background: $color-background-light;
  }
  .cancel {
    position: absolute;
    cursor: pointer;
    font-weight: 600;
    color: $color-text-light;
    left: 0;

    &:hover {
      opacity: 0.7;
    }
  }

  button {
    height: inherit;
  }
  .react-datepicker-wrapper {
    width: 100%;
    display: flex;
    justify-items: flex-start;
  }
  .btn-due-date {
    height: 40px;
    font-weight: normal;
    margin: 0;
    width: 100%;
    display: flex;
    justify-items: flex-start;
    align-items: center;
    gap: 0.7rem;

    &.is-invalid {
      box-shadow: 0 0 0px 1px $danger;
    }

    svg {
      color: $primary;
    }
  }

  .react-datepicker__navigation {
    height: 10px;
  }
}

.action-item-row {
  display: block;
  border-bottom: 2px solid $color-background-light;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }

  @include media-breakpoint-up(lg) {
    border-bottom: 2px solid #f1f1f1;
  }
  .svg-inline--fa {
    font-size: $font-size-normal;
  }
}

.action-item-row:last-child {
  border-bottom: none;
}

.action-item {
  text-decoration: inherit;
  color: $color-text-normal;
  @extend .mrm-py-0_75;

  a {
    text-decoration: none;
    color: inherit;
  }

  .ai-description {
    font-size: $font-size-normal;
    font-weight: 200;
    line-height: 22px;
    span {
      font-weight: 200;
    }
    p {
      font-size: $font-size-small;
      font-weight: 200;
    }

    .due-over {
      color: $color-assessment-red;
      display: block;
      margin-top: -4px;
    }
    .due-completed {
      color: $color-gray;
      font-weight: 300;
      font-family: $sans-serif-font-family;
    }
    .due-default {
      color: $color-text-disabled;
      display: block;
      margin-top: -4px;
    }
    .file-attachments {
      color: $color-text-disabled;
      font-weight: bold;
    }
  }
  .ai-status {
    background-color: $color-assessment-none;
    border-radius: 8px;
    max-width: 2rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    @extend .mrm-ml-1;
    .svg-inline--fa {
      vertical-align: middle;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .blank {
    a {
      height: 2em;
      width: 2em;
    }
  }
  .delete,
  .edit {
    cursor: pointer;
  }

  .icon-box svg:hover {
    opacity: 0.7;
  }

  .green {
    background-color: $color-assessment-green;
  }
  .text-right {
    position: relative;
    right: 8px;
    color: $color-dark-text-lighter;
  }
}

.roadmap-components-competency-page-action-items {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 0;
    .add-action-item {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $color-dark;
      width: 1.6rem;
      height: 1.6rem;
      border-radius: 0.4rem;
      &::hover {
        background-color: $color-dark;
      }
    }

    h2 {
      font-size: $font-size-large;
      font-weight: 600;
      font-family: $sans-serif-font-family;
    }
    .text {
      display: flex;
      align-items: center;
    }
  }

  span {
    color: $color-dark;
  }
  .action-item-container {
    @extend .mrm-mb-1;
    box-shadow: 0px -4px 20px 1px rgba(0, 0, 0, 0.05);
    background-color: $color-white;
    border-radius: 10px;

    .action-item-link {
      text-decoration: none;
    }
  }

  @media print {
    button.btn {
      display: none;
    }
  }
}
