@import '../../styles/mixins';

.rpm-download-app-desktop {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    height: 100vh;
    background-color: $color-dark;
    overflow-y: hidden;

    &__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding-left: 2.5rem;
        position: relative;
        width: 100%;
        height: 100vh;
        gap: 3rem;
        position: relative;

        &__logout {
            position: absolute;
            top: 2.5rem;
            right: 2.5rem;
            color: $white;
            font-size: 1rem;
            font-weight: 400;
        }

        #logo {
            height: 1.709rem;
            object-fit: contain;
            position: absolute;
            top: 2.5rem;
            left: 2.5rem;
        }

        h1 {
            color: $white;
            font-size: 2.25rem;
            font-weight: 500;
            line-height: 3.5rem;
            width: 75%;
            font-family: $sans-serif-font-family;
        }

        &__description {
            color: $white;
            font-size: 0.875rem;
            font-weight: 400;
        }

        &__qrs {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 1.5rem;

            &__qr-container {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                gap: 1.5rem;

                &__store {
                    font-size: 1.125rem;
                    font-weight: 600;
                    color: $white;
                }

                &__qr {
                    width: 9.875rem;
                    aspect-ratio: 1/1;
                    object-fit: contain;
                }

                &__button {
                    cursor: pointer;
                }
            }
        }
    }

    &__wires {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: relative;
        z-index: 1;
    }
}

