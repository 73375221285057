@import '../../styles/mixins';

.auth-terms-and-conditions-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
}

.auth-terms-and-conditions-container {
  margin: auto;
  background-color: $white;
  width: max-content;
  padding: 1rem 2rem 1rem 2rem;
  border-radius: 0.6rem;
  .header {
    padding-top: 1.5rem;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    align-items: center;
    p {
      text-align: center;
      font-size: $font-size-small;
      font-weight: 300;
      color: $color-text-light;
    }
    .title {
      font-family: $serif-font-family;
      margin: 0 auto;
    }
  }

  .body {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding: 1rem 2rem 2rem 2rem;

    .item {
      display: flex;
      align-items: center;
      color: $color-dark;
      font-weight: 400;
      cursor: pointer;
      user-select: none;
      a {
        margin-left: 1rem;
      }
    }
    .link-icon {
      // font awesome icon width 1rem
      width: 1rem;
      height: 1rem;
      margin-left: -0.5rem;
      color: $color-dark;
    }

    .terms-button {
      background-color: $color-dark;
      color: $white;
      border-radius: 0.6rem;
      margin: 0 auto;
      max-width: fit-content;
      padding: 0.85rem;
      font-size: $font-size-small;

      &:disabled {
        background-color: $color-gray;
        color: $color-white;
      }
    }
  }
}
