.onboarding-invitation-container {
  h1 {
    text-align: left;
  }
  .return-cancel-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    padding: 0 1rem;
    padding-top: 10%;
    p {
      font-weight: 300;
      color: $color-dark;
    }
  }

  .modal-body {
    padding-top: 0;
  }

  .modal-body-container {
    padding: 1.5rem 1.5rem 5rem 1.5rem;
    background-color: $color-white;
    border-radius: 2rem;
    overflow: 'scroll';
    margin-top: 1rem;
  }

  @include media-breakpoint-up(md) {
    .modal-body-container {
      padding: 0;
      background-color: transparent;
      border-radius: 0;
    }
  }

  .steps-container-lines {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    width: 100%;
    padding-top: 4.81rem;
    gap: 1rem;
  }
  .step {
    color: $color-gray;
    font-size: $font-size-small;
  }
  h1 {
    font-weight: 700;
    font-family: $serif-font-family;
    font-size: 2.2rem;
    margin: 1rem 0;
  }
  .description {
    font-weight: 300;
  }

  .step-5 {
    flex-direction: column;
    width: 100%;
    margin-top: 3rem;
    .like-hand {
      width: 20%;
      margin: 0 auto;
      display: flex;
      margin-top: -5rem;
    }
    h1,
    p {
      text-align: center;
    }
    p {
      margin: 0 auto;
      max-width: 80%;
      margin-bottom: 2.5rem;
      font-weight: 300;
    }
    h1 {
      margin-bottom: 1rem;
      font-size: $font-size-largest;
    }
    .invite-another {
      display: flex;
      gap: 1rem;
      width: 100%;
      justify-content: center;
      align-items: center;
      background-color: $color-dark;
      color: $color-white;
      font-weight: 500;
      padding: 1rem;
      margin-top: 1rem;
      border-radius: 0.6rem;
    }
    .done {
      margin-top: 1rem;
      font-weight: 500;
    }
  }
  .modal-profile-container {
    display: flex;
    flex-direction: column;
    background-color: $color-white;
    margin: 0rem auto;
    justify-content: center;
    width: 100%;
    border-radius: 0.5rem;
    padding: 1.5rem 0rem;
    padding-top: 0;

    .dashboard-roadmap-card {
      box-shadow: none;
      padding: 0;
    }

    h1 {
      font-size: 2.25rem;
      font-family: $serif-font-family;
    }

    .add-rm-modal {
      height: 18rem;
      overflow-y: scroll;
      display: flex;
      flex-direction: column;
    }

    .form-control {
      border-radius: 0.5rem;
      border: 1px solid $color-gray-lighter;
      padding: 1.5rem 1rem;
      font-size: $font-size-normal;
      width: 100%;
      margin-bottom: 1rem;
      margin: 0 auto;
    }
    .checkbox-grid {
      display: grid;
      gap: 1rem;
      width: 100%;
      max-width: 90%;
      padding: 0 2rem;

      .form-check {
        display: flex;
        align-items: flex-start;
      }
    }
    .text-area-edit-profile {
      height: 7rem;
      padding: 1rem;
    }
    .save-cancel-grid {
      display: none;
      gap: 0.5rem;
    }
    .btn-cancel-edit {
      display: block;
      background-color: transparent;
      width: 100%;
      padding: 0.5rem;
      color: $color-dark;
      border-radius: 0.5rem;
      justify-items: center;
      min-width: max-content;
      border: 1px solid $color-dark;
      max-width: 85%;
      margin: 0 auto;
      &:hover {
        background-color: $color-dark;
        color: $white;
      }
    }
    .btn-save-edit {
      display: block;
      background-color: $color-dark;
      color: $white;
      width: 100%;
      padding: 0.6rem;
      border-radius: 0.5rem;
      justify-items: center;
      min-width: max-content;
      max-width: 85%;
      margin: 0 auto;
    }

    .resend-delete-grid {
      display: grid;
      gap: 0.5rem;
      justify-items: center;
      margin-top: 1rem;
      grid-template-columns: 1fr 1fr;
      margin: 0 auto;
      width: 85%;
      padding: 1rem 0;
      .action-button {
        width: 100%;
        background-color: $color-gray;
        color: $white;
        padding: 0.5rem;
        border-radius: 0.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: $font-size-normal;
      }
    }
  }

  .invitation-button-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    .invitation-submit {
      background-color: $color-dark;
      display: block;
      color: $white;
      width: 100%;
      padding: 1rem;
      border-radius: 0.8rem;
    }
    .error-from-console {
      color: $color-red;
      font-size: $font-size-small;
      margin-top: 0.5rem;
      display: flex;
      justify-content: center;
      width: 100%;
      padding: 1rem;
      background-color: rgba(196, 114, 88, 0.3);
      border-radius: 0.5rem;
    }
  }

  .invitation-subs-grid {
    display: grid;
    gap: 0.5rem;
    max-height: 15rem;
    overflow-y: scroll;
    margin-bottom: 1rem;
    .invitation-sub {
      cursor: pointer;
      background-color: $white;
      .select {
        display: inline-flex;
        gap: 0.5rem;
        button {
          outline: none;
        }
      }
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 1rem;
      border: 1px solid #d3d3d2;
      border-radius: 0.5rem;
      height: 5.25rem;
      h3 {
        font-size: $font-size-large;
        font-weight: 300;
        margin: 0;
      }

      p {
        font-size: $font-size-large;
        font-weight: 500;
        margin: 0;
      }
    }
  }

  .add-new-subs {
    margin: 0 auto;
    display: inline-flex;
    align-items: center;
    gap: 0.3rem;
    margin-bottom: 0.3rem;
    outline: none;
  }

  .subscription-details-invitation {
    form {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      width: 100%;
      padding-bottom: 2rem;
      position: relative;
      input {
        padding-left: 1rem;
        border-radius: 0.5rem;
        border: 1px solid #d3d3d2;
      }
      .month-label {
        position: absolute;
        left: 0;
        bottom: 0;
        margin-left: 20%;
        margin-bottom: 2.65rem;
        max-width: max-content;
        @include media-breakpoint-up(md) {
          margin-left: 25%;
        }
      }
      .price-label {
        position: absolute;
        left: 0;
        bottom: 0;
        margin-left: 3%;
        margin-bottom: 2.65rem;
      }
      .price-input {
        max-width: 35%;
        background-color: $white;
        padding-left: 2rem;
      }
    }
  }

  .step-4 {
    display: flex;
    flex-direction: column;
    h1 {
      margin: 0;
    }
    .client-info {
      padding-bottom: 1rem;
      h1 {
        margin: 0;
        padding: 0;
        font-family: $sans-serif-font-family;
        font-weight: 300;
      }
      p {
        font-weight: 300;
      }
    }
    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 1rem 0;
      padding-top: 1rem;
    }

    .roadmaps-grid {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      max-height: 13rem;
      overflow-y: scroll;
      margin-bottom: 1rem;
    }

    .invitation-sub {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      max-height: 5.25rem;
      h3 {
        max-width: 50%;
        font-size: $font-size-larger;
      }
      padding-bottom: 1rem;
    }
  }

  .error-active-sub {
    color: $color-red;
    font-size: $font-size-small;
    margin-bottom: 1rem;
  }
}
