.reasons-container {
  button {
    outline: none;
  }
  padding: 0 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .arrow {
    position: absolute;
    transform: rotate(-90deg);
    z-index: 99999;
    right: 0;
    margin-top: 9.75rem;
    margin-right: 4rem;
    @include media-breakpoint-up(md) {
      margin-top: 4.6rem;
      margin-right: 4rem;
    }
  }
  .refund-item {
    display: flex;
    flex-direction: column;
    @include media-breakpoint-up(md) {
      flex-direction: row;
      align-items: center;
    }
    width: 100%;
    gap: 1.1rem;
    input,
    select,
    textarea {
      width: 100%;
      height: 2.8rem;
      padding-left: 1rem;
      border-radius: 0.6rem;
      border: 1px solid $color-gray-lighter;
      font-weight: 300;
      -webkit-appearance: none;
      -moz-appearance: none;
      &:disabled {
        background-color: $white;
        border: 1px solid $color-gray-lighter;
      }
    }
    textarea {
      padding-top: 0.5rem;
      height: 4.5rem;
    }
    p {
      font-weight: 300;
    }
  }
  .explanation {
    p {
      opacity: 0;
    }
  }
  .warning {
    margin: 0 auto;
    display: flex;
    align-items: center;
    gap: 0.7rem;
    width: 80%;
    p {
      font-size: $font-size-smaller;
      color: $color-dark-green;
    }
  }

  .refund {
    background-color: $color-dark;
    color: $white;
    max-width: min-content;
    margin: 0 auto;
    padding: 1rem 2rem;
    border-radius: 0.6rem;
    margin-top: 1rem;
  }
  .cancel {
    p {
      font-size: $font-size-small;
      text-decoration: underline;
      padding-bottom: 0.5rem;
    }
  }
}
