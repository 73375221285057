@import url('https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.modal {
  overflow-y: auto;
}

.confirmation-modal {
  .modal-content .modal-title h1 {
    font-size: 1rem;
  }
  h2 {
    font-size: 1rem;
    color: $color-text-normal;
  }
  small {
    font-size: 0.875rem;
    color: $color-dark-text-lighter;
  }
  .btn {
    font-size: 1rem;
    font-family: $sans-serif-font-family;
  }
  .btn-cancel {
    font-weight: 400;
    color: $color-text-normal;
    &:hover {
      background-color: transparent;
      opacity: 1;
    }
  }
}

.modal-content {
  border-radius: 10px;
  border: none;

  .modal-header {
    border-bottom: none;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    button {
      border: none;
      background-color: transparent;
      color: $color-dark;
      font-family: Poppins;
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
    }
  }

  .modal-title {
    text-align: center;

    h1 {
      width: 100%;
      font-size: 1.125rem;
      font-weight: bold;
      margin-bottom: 0px;
      line-height: 24px;
    }
    p {
      color: #727272;
      font-size: 1rem;
    }
  }

  .btn {
    min-height: 45px;
  }

  @include media-breakpoint-up(lg) {
    .form-control {
      border: 1px solid $color-item-gray;
      &:focus {
        box-shadow: none;
        border: 1px solid #2f80ed;
      }
    }
  }
}

.modal-mobile-slide-from-bottom {
  .modal-content {
    margin-bottom: 1.5rem;
    background-color: $color-white;
  }
  .btn-menu-item {
    height: 50px;
  }
  @include media-breakpoint-down(xs) {
    .modal-dialog {
      display: flex;
      align-items: flex-end;
      min-height: calc(100% - 1rem);
    }

    &.fade .modal-dialog {
      transform: translateY(50px);
    }

    &.show .modal-dialog {
      transform: none;
    }
  }
}
