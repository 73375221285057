.rpm-client-profile {
    &__container {
        &__left {
            height: 100%;
            width: 100%;
            min-width: 19.625rem;
            max-width: 19.625rem;

            background-color: $color-white;
            grid-area: client-profile-left;
            border-radius: 0.625rem;
            display: flex;
            flex-direction: column;
            padding-bottom: 1rem;

            @include media-breakpoint-down(sm) {
                width: calc(100vw - 4rem);
                max-width: calc(100vw - 4rem);
                min-width: calc(100vw - 4rem);
                margin: 0 auto;
            }

            .rpm-profile-info-container {
                display: flex;
                flex-direction: column;
                &__picture-header {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    .background {
                        width: 100%;
                        border-radius: 0.625rem 0.625rem 0 0;
                        object-fit: cover;
                        height: 5.125rem;
                    }
                    &__picture {
                        margin-top: -2.5rem;
                        position: relative;

                        img {
                            width: 6rem;
                            height: 6rem;
                            aspect-ratio: 1/1;
                            border-radius: 0.5rem 0.5rem 0 0.5rem;
                            border: 2px solid rgba(211, 208, 204, 0.46);
                            box-sizing: border-box;
                            z-index: 1;
                        }
                        &__status {
                            height: 1.5rem;
                            padding: 0.5rem;
                            border-radius: 7.8125rem;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            gap: 0.5rem;
                            border: 1px solid #718371;
                            background-color: $white;
                            z-index: 2;
                            position: absolute;
                            bottom: -0.75rem;
                            right: 50%;
                            transform: translateX(50%);

                            &__dot {
                                height: 0.3125rem;
                                width: 0.3125rem;
                                aspect-ratio: 1/1;
                                border-radius: 50%;
                                background-color: #718371;
                            }
                            &__text {
                                font-size: 0.625rem;
                                color: #718371;
                                font-weight: 500;
                            }
                        }

                        .eligible {
                            border: 1px solid #718371;
                            .dot {
                                background-color: #718371;
                            }
                            .text {
                                color: #718371;
                            }
                        }

                        .ineligible {
                            border: 1px solid #e8744f;
                            .dot {
                                background-color: #e8744f;
                            }
                            .text {
                                color: #e8744f;
                            }
                        }
                    }
                }
                &__info {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    width: 90%;
                    margin: 0 auto;
                    margin-top: 1rem;
                    gap: 0.5rem;
                    p {
                        text-align: center;
                        font-size: 0.75rem;
                        font-weight: 300;
                        span {
                            font-weight: 600;
                        }
                    }
                    h1 {
                        text-align: center;
                        font-size: 1.5rem;
                    }

                    &__buttons {
                        display: flex;
                        flex-direction: column;
                        gap: 0.5rem;
                        align-items: center;
                        width: 100%;
                        margin-top: 1rem;
                        &__button {
                            width: 100%;
                            height: 2rem;
                            border-radius: 0.3125rem;
                            font-size: 0.75rem;
                        }
                        .dark {
                            background-color: $color-dark;
                            color: $color-white;
                        }
                    }
                    &__add_header {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                        width: 100%;
                        margin: 1rem 0;
                        p {
                            font-size: 0.875rem;
                            font-weight: 500;
                            text-align: left;
                        }
                        button {
                            height: 1.25rem;
                            width: 1.25rem;
                            aspect-ratio: 1/1;
                            border-radius: 0.3125rem;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            outline: none;
                            background-color: $color-dark;
                        }
                    }

                    &__care-team {
                        display: flex;
                        flex-direction: column;
                        gap: 0.5rem;
                        width: 100%;
                        &__member {
                            height: 5rem;
                            background-color: $white;
                            border-radius: 0.625rem;
                            box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
                            padding: 1rem;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: space-between;
                            &__name-container {
                                display: flex;
                                flex-direction: row;
                                align-items: center;
                                gap: 0.5rem;
                                &__picture {
                                    height: 3rem;
                                    width: 3rem;
                                    aspect-ratio: 1/1;
                                    img {
                                        height: 100%;
                                        width: 100%;
                                        border-radius: 0.5rem 0.5rem 0 0.5rem;
                                    }
                                }
                                &__name {
                                    display: flex;
                                    flex-direction: column;
                                    align-items: flex-start;
                                    p {
                                        font-size: 0.875rem;
                                        font-weight: 600;
                                        // one line then ellipsis
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        white-space: nowrap;
                                    }

                                    .subtitle {
                                        font-size: 0.625rem;
                                        font-weight: 400;
                                        color: $color-dark;
                                        opacity: 0.5;
                                    }
                                }
                            }
                            .chat-btn {
                                outline: none;
                            }
                        }
                    }

                    
                }
            }
        }
    }
}
