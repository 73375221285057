@import '../../styles/mixins';

body {
  .greminders__booking-widget {
    display: none !important;
  }
  #auth-google-calendar-content {
    .greminders__booking-widget {
      display: block !important;
    }
  }
}

.home-app {
  min-height: calc(var(--vh, 1vh) * 100);
  .toast-container {
    position: absolute;
    left: 50%;
    top: 20px;

    .toast {
      position: relative;
      left: -50%;
      z-index: 1;
    }
  }

  .stage-env-banner {
    position: fixed;
    z-index: 1024;
    right: 0;
    left: 0;
    font-size: $font-size-smaller;
    border-bottom: 1px solid #f5c6ca;
    text-align: center;
    color: #731c23;
    background-color: #f8d7d9;
  }
}
.osano-cm-widget {
  display: none;
}

/* .fade:not(.show) {
  opacity: 1;
}
 */
