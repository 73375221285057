.chat-page {
  .chat-page-wrapper {
    .content {
      .content-tabs {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        padding: 0 2rem;
        .tab {
          display: flex;
          align-items: center;
          flex-direction: column;
          gap: 0.3rem;
          cursor: pointer;
        }
      }
    }
  }
}
