.rpm-provider-record-alerts-modal {
    height: 21.75rem;
    width: 90vw;
    display: flex;
    flex-direction: column;
    background-color: $white;
    // center it
    margin-left: 5vw;
    margin-top: calc(50vh - 10.875rem);
    border-radius: 0.625rem;
    .modal-content {
        margin: 0;
        width: 100%;
    }
    .modal-dialog {
        margin: 0;
        width: 100%;
        max-width: 100%;
    }
    &__alert-header {
        height: 2.5rem;
        width: 100%;
        background-color: hsl(15, 77%, 61%);
        padding: 0 1.5rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-top-left-radius: 0.625rem;
        border-top-right-radius: 0.625rem;
        &__left {
            display: flex;
            align-items: center;
            gap: 1rem;
            &__alert-icon-container {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                &__square {
                    width: 1.11125rem;
                    height: 1.11125rem;
                    aspect-ratio: 1/1;
                    background-color: #f9dad0;
                    border-radius: 0.1875rem;
                    transform: rotate(45deg);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                &__icon {
                    color: #e8744f;
                    font-size: $font-size-smallest;
                    font-weight: 800;
                    z-index: 10;
                    position: absolute;
                }
            }
            &__alert-text {
                font-size: 0.875rem;
                font-weight: 600;
                color: $white;
            }
        }
        &__right {
            font-size: 0.75rem;
            font-weight: 600;
            color: $white;
        }

        &__no-alerts {
            color: rgba(89, 89, 89, 0.5);
            font-size: 0.625rem;
            font-weight: 600;
        }
    }
    &__alert-body {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding: 0.5rem 1rem;
        width: 100%;
        gap: 0.5rem;
        background-color: $white;
        border-bottom-left-radius: 0.625rem;
        border-bottom-right-radius: 0.625rem;
        &__header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            padding: 0;
            &__profile-container {
                display: flex;
                align-items: center;
                gap: 1rem;
                width: 100%;

                &__profile-image {
                    width: 3rem;
                    height: 3rem;
                    border-radius: 0.5rem 0.5rem 0 0.5rem;
                    border: 2px solid rgba(240, 236, 232, 0.4);
                    background-color: #d6d6d6;
                    aspect-ratio: 1/1;
                    position: relative;
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        border-radius: 0.5rem 0.5rem 0 0.5rem;
                    }
                    &__initials {
                        width: 100%;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 1rem;
                        font-weight: 500;
                        color: $white;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                    }
                }
                &__details {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: center;
                    width: 100%;
                    &__tag {
                        font-size: 0.625rem;
                        font-weight: 600;
                        color: #e8744f;
                    }
                    &__name {
                        font-size: 1rem;
                        font-weight: 500;
                        color: $color-dark;
                    }
                    &__diagnosis {
                        font-size: 0.625rem;
                        font-weight: 500;
                        color: #595959;
                    }
                }
            }
            &__status-container {
                min-width: 3.8125rem;
                width: max-content;
                border: 1px solid #88a088;
                border-radius: 7.8125rem;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 1.5rem;
                gap: 0.25rem;
                padding: 0 0.75rem;
                &__dot {
                    height: 5px;
                    width: 5px;
                    border-radius: 50%;
                    aspect-ratio: 1/1;
                    background-color: #88a088;
                }
                &__text {
                    font-size: 0.625rem;
                    font-weight: 500;
                    color: #88a088;
                    // do not wrap text
                    white-space: nowrap;
                }
            }
        }
        .rpm-provider-record-header-alert-popup-body {
            height: 14.5rem;
            width: 100%;
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-top: 0.5rem;

            .right-triangle {
                position: absolute;
                top: 6.75rem;
                left: 100%;
                width: 0;
                height: 0;
                border-top: 0.5rem solid transparent;
                border-bottom: 0.5rem solid transparent;
                border-left: 0.5rem solid $white;
            }
            .rpm-provider-record-header-alert-popup-body-item {
                min-height: 3rem;
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                padding: 0 1rem;
                border-bottom: 1px solid #f0ece8;
                .rpm-provider-record-header-alert-popup-body-item-left {
                    display: flex;
                    align-items: center;
                    gap: 0.5rem;
                    justify-content: flex-start;
                    p {
                        font-size: $font-size-small;
                        font-weight: 400;
                        color: $color-dark;
                    }
                }
                .rpm-provider-record-header-alert-popup-body-item-right {
                    display: flex;
                    align-items: center;
                    gap: 0.5rem;
                    justify-content: flex-end;
                    .rpm-provider-record-header-alert-popup-body-item-right-text {
                        padding: 0.5rem;
                        width: 2rem;
                        height: 1.5rem;
                        display: flex;
                        align-items: center;
                        background: rgba(232, 116, 79, 0.27);
                        border-radius: 0.25rem;
                        p {
                            font-size: 0.75rem;
                            font-weight: 600;
                            color: #e8744f;
                        }
                    }
                    .rpm-provider-record-header-alert-popup-body-item-right-date {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        p {
                            color: #908e87;
                            font-weight: 400;
                            font-size: $font-size-smallest;
                        }
                    }
                }
            }
           .rpm-provider-record-header-alert-popup-body-item-clear-all {
                text-decoration: underline;
                font-size: 0.75rem;
                cursor: pointer;
                color: #e8744f;
                font-weight: 600;
                margin-top: 1rem;
            }
        }
    }
}
