.caregiver-modal {
  .client-form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    @include media-breakpoint-down(sm) {
      display: flex;
      flex-direction: column;
    }
    gap: 0.75rem;
    margin: 0 auto;
    margin-top: 1rem;
    width: 85%;
    .form-group {
      display: flex;
      flex-direction: column;
      margin-bottom: 0;
      label {
        font-size: $font-size-smallest;
      }
      input {
        padding: 0.6rem;
        padding-left: 1rem;
        background-color: #fff;
        margin: 0;
        width: 100%;
        border: none;

        &::placeholder {
          font-weight: 400;
          color: $color-dark;
          opacity: 0.7;
          font-size: 14px;
          font-family: $sans-serif-font-family;
        }
        @include media-breakpoint-down(sm) {
          padding: 0.45rem 0.6rem;
          font-size: 12px;
          &::placeholder {
            font-size: 12px;
          }
        }
        &:after {
          content: '*';
        }
      }
    }
    .terms-check {
      display: flex;
      gap: 0.5rem;
      grid-column: span 2 / auto;
      margin-top: 0.5rem;
      .terms-container {
        .agree {
          font-weight: 600;
          cursor: pointer;
          user-select: none;
        }
        p {
          font-size: $font-size-smallest;
        }
        a {
          font-size: $font-size-smallest;
          color: $color-dark;
          text-decoration: underline;
          font-weight: 300;
          cursor: pointer;
        }
      }
    }
    .error-message {
      color: $color-red;
      font-size: $font-size-smallest;
      margin: 0.5rem 0;
      font-weight: 700;
      text-align: start;
    }
    .signup-btn {
      margin-top: 1.5rem;
      width: 100%;

      color: $white;
      padding: 0.5rem 0;
      margin: 0 auto;
      max-height: max-content;
      border-radius: 0.4rem;
      grid-column: span 2 / auto;
      font-weight: 700;
    }
    input[type='checkbox']::after {
      width: 11px;
      height: 11px;
      border: 1px solid #343434;
      border-radius: 2px;
      background-color: $white;
      cursor: pointer;
    }
    input[type='checkbox']:checked::after {
      background-color: #343434;
    }
  }
}
