.stripe-modal {
  .modal-content {
    border-radius: 1.2rem;
  }
}

.modal-header-in {
  display: flex;
  flex-direction: column;
  padding: 0 1.4rem;
  p {
    font-size: $font-size-small;
    color: #918f8f;
  }
  h1 {
    font-family: $serif-font-family;
    padding-bottom: 1.2rem;
    font-size: 2.2rem;
    max-width: 80%;
    line-height: 1.5rem;
  }
  .description {
    font-weight: 300;
    color: $color-dark;
  }
}
.modal-image-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: space-between;
  align-items: flex-start;
  height: max-content;
  padding-bottom: 1rem;

  img {
    grid-column: 2;
    width: 12rem;

    margin: 0 auto;
  }
  button {
    padding-top: 1rem;
    outline: none;
    p {
      text-decoration: underline;
    }
  }
}
.modal-footer-stripe {
  background-color: $color-bg-white;
  display: grid;
  justify-content: flex-start;
  row-gap: 0.5rem;
  min-width: 100%;
  padding: 0.3rem 2rem;
  border-radius: 0 0 1.2rem 1.2rem;
  padding-top: 2rem;
  padding-bottom: 3.5rem;

  .stripe_button {
    min-width: 100%;
    display: flex;
    height: 5rem;
    align-items: center;

    p {
      margin-bottom: 1rem;
    }
  }

  button {
    background-color: $color-dark;
    color: $white;
    display: inline-flex;
    align-items: center;
    border-radius: 0.8rem;
    gap: 1rem;
    height: 3.5rem;
    width: 99%;
    font-size: 0.9rem;
    font-weight: 500;
    padding-right: 16rem;
    white-space: nowrap;
    height: 5rem;
    font-size: $font-size-normal;
    font-weight: 300;
  }

  .button-icon {
    height: 100%;
    border-color: white;
    display: inline-flex;
    align-items: center;
    border-right: 1px solid #595959;
    padding: 0 1.7rem;
  }

  a {
    p {
      text-decoration: underline;
      font-weight: 500;
    }
    padding-bottom: 1rem;
  }
}
