@import '../../../../../styles/mixins';

.support-dashboard-review {
  display: flex;
  flex-direction: column;
  padding-left: 10%;
  width: 100%;
  .title {
    font-weight: 300;
  }
  .info-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 4.75rem;
    row-gap: 1.5rem;
    margin-top: 1.5rem;
    p {
      font-weight: 500;
    }
    .title {
      font-weight: 300;
      min-width: max-content;
    }
    .checkbox-wrapper {
      display: flex;
      align-items: center;
      gap: 2rem;
    }
  }
  .inner-col {
    gap: 1rem;
    display: flex;
    flex-direction: column;
  }

  .subscription {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1rem;
    width: 100%;
    grid-column: span 2;
    padding-left: 5%;
  }
}
.support-divider {
  width: 95%;
  height: 1px;
  background-color: $color-gray-lighter;
  grid-column: span 2;
  margin: 1rem 0 1rem 5%;
}
.span-2 {
  grid-column: span 2;
}
