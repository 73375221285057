.rpm-dashboard {
  .stats-sidebar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    width: 100%;
    .stats-sidebar-header-left {
      .title {
        color: $white;
        font-size: 1.5rem;
        font-weight: 700;
        font-family: $serif-font-family;
        width: 85%;
        margin-bottom: .5rem;
      }
      .days {
        color: #6b6b6b;
        font-size: $font-size-small;
        font-weight: 500;
      }
      .days-left {
        color: #a4a2a1;
        font-size: 0.75rem;
        font-weight: 600;
        span {
          text-decoration: underline;
        }
      }
    }
    .stats-sidebar-header-right {
      .circle-wrap {
        width: 90px;
        height: 90px;
        background: #40413f;
        border-radius: 50%;
      }

      .circle-wrap .circle .mask,
      .circle-wrap .circle .fill {
        width: 90px;
        height: 90px;
        position: absolute;
        border-radius: 50%;
      }

      .circle-wrap .circle .mask {
        clip: rect(0px, 90px, 90px, 45px);
      }

      .circle-wrap .inside-circle {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 70px;
        height: 70px;
        border-radius: 50%;
        background: #343434;
        text-align: center;
        color: #908e87;
        position: absolute;
        z-index: 100;
        font-weight: 600;
        font-size: 1rem;
        margin-top: 10px;
        margin-left: 10px;
      }

      /* color animation */

      /* 3rd progress bar */
      .mask .fill {
        clip: rect(0px, 45px, 90px, 0px);
        background-color: #e8744f;
      }

      .mask.full,
      .circle .fill {
        animation: fill ease-in-out 3s;
        transform: rotate(var(--rotation-rpm));
      }

      @keyframes fill {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(var(--rotation-rpm));
        }
      }
    }
  }
}
