/* Custom Utilities -------------------- */

/*  Custom Spacing ****************************
**
**
**  All spacing sre based on $scale-factor-0_25 ~ $scale-factor-48
**
**  Padding and margin classes are defined like
**  .mrm-p-0_25, .mrm-p_4, .mrm-px_1_5, .mrm-py_2
**  .mrm-m-0_25, .mrm-m_4, .mrm-mx_1_5, .mrm-my_2
**
**  We can get custom spacing using get-scaled-size function defined in this scss file
**/

$base-font-size: 1rem;

$scale-factor-0_25: 0.25;
$scale-factor-0_5: 0.5;
$scale-factor-0_75: 0.75;
$scale-factor-1: 1;
$scale-factor-1_5: 1.5;
$scale-factor-2: 2;
$scale-factor-2_5: 2.5;
$scale-factor-3: 3;
$scale-factor-4: 4;
$scale-factor-6: 6;
$scale-factor-8: 8;
$scale-factor-12: 12;
$scale-factor-16: 16;
$scale-factor-24: 24;
$scale-factor-32: 32;
$scale-factor-40: 40;
$scale-factor-48: 48;

$scale-factors: (
  0_25: $scale-factor-0_25,
  0_5: $scale-factor-0_5,
  0_75: $scale-factor-0_75,
  1: $scale-factor-1,
  1_5: $scale-factor-1_5,
  2: $scale-factor-2,
  2_5: $scale-factor-2_5,
  3: $scale-factor-3,
  4: $scale-factor-4,
  6: $scale-factor-6,
  8: $scale-factor-8,
  12: $scale-factor-12,
  16: $scale-factor-16,
  24: $scale-factor-24,
  32: $scale-factor-32,
  40: $scale-factor-40,
  48: $scale-factor-48,
);

@function get-scaled-size($scale-factor) {
  @return $base-font-size * $scale-factor;
}

$properties: (
  m: margin,
  p: padding,
);

$sides: (
  t: top,
  r: right,
  b: bottom,
  l: left,
);

@each $prop-key, $prop-value in $properties {
  @each $scale-key, $scale in $scale-factors {
    .mrm-#{$prop-key}-#{$scale-key} {
      #{$prop-value}: get-scaled-size($scale);
    }

    .mrm-#{$prop_key}x-#{$scale-key} {
      #{$prop-value}-left: get-scaled-size($scale);
      #{$prop-value}-right: get-scaled-size($scale);
    }

    .mrm-#{$prop_key}y-#{$scale-key} {
      #{$prop-value}-top: get-scaled-size($scale);
      #{$prop-value}-bottom: get-scaled-size($scale);
    }

    @each $side-key, $side-value in $sides {
      .mrm-#{$prop-key}#{$side-key}-#{$scale-key} {
        #{$prop-value}-#{$side-value}: get-scaled-size($scale);
      }
    }
  }
}

// Background Colors

.bg-grey {
  background-color: #f6f8f9;
}

.bg-grey {
  background-color: #f6f8f9;
}

// Containers

.normal-width-container {
  margin-left: auto;
  margin-right: auto;
  @include media-breakpoint-down(xl) {
    max-width: 1095px;
  }
  @include media-breakpoint-down(lg) {
    max-width: 920px;
  }
}

.page-container {
  padding-bottom: 1px;
}

.container {
  width: 100%;
}

.desktop-page-container {
  .form-control {
    border: 1px solid $color-item-gray;
  }
}

// Custom Shadows

.mrm-box-shadow {
  box-shadow: 0 2px 5px 0 #0002;
}

.mrm-main-box-shadow {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.05);
}

@mixin mrm-main-box-shadow {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.05);
}

.mrm-white-on-white-box-shadow {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.09) !important;
  border-radius: 10px;
}

@mixin mrm-white-on-white-box-shadow {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.09) !important;
  border-radius: 10px;
}

.mrm-white-on-white-vivid-box-shadow {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.09);
  border-radius: 10px;
}

@mixin mrm-white-on-white-vivid-box-shadow {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.09);
  border-radius: 10px;
}

// Borders
.mrm-border-bottom {
  box-shadow: 0 1px 0 #0001;
}

.border {
  height: 2px;
  width: 100%;
  background-color: #e5e5e5;
  border: none !important;
}

.border-thin {
  height: 1px;
  width: 100%;
  background-color: #e5e5e5;
  border: none !important;
}

.border-bottom {
  width: 100%;
  border-bottom: 1px solid #e5e5e5;
}

.border-rounded {
  border-radius: 10px;
}

// Icon sizes

// icon tile
.fa-4x {
  font-size: 4em;
}

// bottom nav
.fa-lg {
  font-size: 1.875em;
}

.fa-sm {
  font-size: 1.25em;
}

.fa-xs {
  font-size: 1.125em;
}

.fa-1x {
  font-size: 0.8em;
}

// Cursor

.css-xb97g8:hover {
  cursor: pointer;
}

.hover {
  &:hover {
    cursor: pointer;
  }
}

// Type utilities

$font-size-largest: get-scaled-size(1.5);
$font-size-larger: get-scaled-size(1.25);
$font-size-large: get-scaled-size(1.125);
$font-size-normal: get-scaled-size(1);
$font-size-small: get-scaled-size(0.875);
$font-size-smaller: get-scaled-size(0.8);
$font-size-smallest: get-scaled-size(0.7);

.font-size-small {
  font-size: $font-size-small;
}

.font-size-large {
  font-size: $font-size-large;
}

.font-size-largest {
  font-size: $font-size-largest;
}

.font-size-smaller {
  font-size: $font-size-smaller;
}

.font-size-smallest {
  font-size: $font-size-smallest;
}

.bold {
  font-weight: 800;
}

.semibold {
  font-weight: 600;
}

// Other

.w-fit-content {
  width: fit-content;
}

.svg-inline--fa {
  font-size: 1.5em;
}

.css-g1d714-ValueContainer {
  .css-1rhbuit-multiValue {
    border-radius: 6px;
    .css-12jo7m5 {
      font-size: 1rem;
    }
  }
}

.delete-comment-message-icon {
  @extend .mrm-pl-0_5;
  @extend .mrm-pr-0_25;

  color: $color-text-disabled;
  font-size: 0.75rem;

  &:hover {
    color: lighten($color-text-disabled, 3%);
    cursor: pointer;
  }
}

.rc-slider-disabled {
  background-color: transparent;
}

.desktop-page-secondary-header-wrapper {
  @extend .mrm-main-box-shadow;
  background: #fff;
  border-radius: 0.625rem;
}

.space-between {
  justify-content: space-between;
}

.initials {
  height: 35px;
  width: 35px;
  display: flex;
  background-color: $color-background-dark-gray;
  border-radius: 50%;
  margin-right: 5px;

  span {
    color: #ffffff;
    font-size: 15px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: normal;
  }

  &.md {
    height: 47px;
    width: 47px;

    span {
      font-size: 18px;
    }
  }

  &.lg {
    height: 80px;
    width: 80px;

    span {
      font-size: 32px;
    }
  }
}

.navbar {
  a:hover {
    opacity: 1;
  }
}

.flex {
  display: flex;
}

.space-between {
  justify-content: space-between;
}

.flex-col {
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
}

.items-center {
  align-items: center;
}

.items-start {
  align-items: flex-start;
}

.gap-0-5 {
  gap: 0.5rem;
}

.gap-1 {
  gap: 1rem;
}

.gap-1_5 {
  gap: 1.5rem;
}

.gap-2 {
  gap: 2rem;
}

.w-full {
  width: 100%;
}

.cursor-pointer {
  cursor: pointer;
}

.py-1 {
  padding: 1rem 0;
}
