.rpm-provider-dashboard,
.rpm-coach-dashboard {
    .rpm-provider-container,
    .rpm-coach-container {
        .search-container {
            .right-search-container {
                .search-container-add-client {
                    width: 8.3125rem;
                    height: 2rem;
                    border: 1px solid #d3d0cc;
                    background-color: $color-dark;
                    border-radius: 0.3125rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 0.5rem;
                    outline: none;
                    .search-container-add-client-text {
                        color: $white;
                        font-size: 0.75rem;
                    }
                }
            }
        }
    }
}

#addClientModal {
    .modal-content {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 1rem;
        background-color: $white;
        width: 30.125rem;
        .rpm-provider-add-client-modal-title {
            font-size: 1.5rem;
            font-weight: 700;
            color: $color-dark;
            text-align: center;
        }
        .rpm-provider-add-client-modal-form {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 1rem;
            margin-top: 1rem;
            margin-bottom: 1rem;
            .rpm-provider-add-client-modal-form-input {
                position: relative;
                height: 2.8125rem;
                input {
                    width: 19.6875rem;
                    min-height: 2.8125rem;
                    min-height: 2.8125rem;
                    min-height: 2.8125rem;
                    border: 1px solid #d3d0cc;
                    border-radius: 0.625rem;
                    padding-left: 2.75rem;
                    padding-right: 1rem;
                    font-size: 1rem;
                    &::placeholder {
                        font-weight: 300;
                    }
                }
                svg {
                    position: absolute;
                    top: 50%;
                    left: 1rem;
                    transform: translateY(-50%);
                }
            }
            .rpm-provider-add-client-modal-form-error {
                color: $color-red;
                font-size: $font-size-smallest;
                font-weight: 700;
            }
            .rpm-provider-add-client-modal-form-submit {
                width: 19.6875rem;
                height: 2.8125rem;
                background-color: $color-dark;
                border-radius: 0.625rem;
                display: flex;
                align-items: center;
                justify-content: center;
                color: $white;
                font-size: 1rem;
                font-weight: 500;
            }
        }
    }
}
