@import '../../styles/mixins';

.auth-home {
  display: flex;
  flex-direction: column;
  padding: 0 7.5%;
  width: 100%;
  max-width: 95.375rem;
  margin: 0 auto;
  height: 100vh;
  max-height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: white;
  .login-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.75rem 3.75rem;
    color: $white;
    font-weight: 700;
    font-size: $font-size-normal;
    border-radius: 0.3125rem;
    &:hover,
    &:focus {
      opacity: 1;
    }
  }
  .navbar {
    display: flex;
    align-items: center;
    width: 100%;
    margin: 0 auto;
    padding: 0.95rem 0;
    border-bottom: 0.5px solid $color-gray-lightest;
    .right-container {
      display: flex;
      align-items: center;
      gap: 1.75rem;
      .questions {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        p {
          font-size: $font-size-smallest;
          color: $color-dark;
          opacity: 0.5;
          font-weight: 500;
        }
        .schedule {
          text-decoration: underline;
        }
      }
      @include media-breakpoint-down(sm) {
        flex-direction: column-reverse;
        align-items: flex-end;
        gap: 0.4rem;
        .login-btn {
          padding: 3px;
          width: 6.25rem;
        }
      }
    }
  }
  .content {
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    @include media-breakpoint-up(lg) {
      margin-top: 5vh;
      justify-content: space-between;
      flex-direction: row;
      gap: 0;
    }
    .left-side {
      display: flex;
      flex-direction: column;
      gap: 2rem;
      width: 85%;
      h1 {
        font-size: 3rem;
        line-height: 3.5rem;
        font-weight: 300;
        font-family: $sans-serif-font-family;
      }

      .description {
        font-weight: 300;
        width: 75%;
      }
    }
    img {
      width: 100%;
      height: 80vh;
      object-fit: contain;

      @include media-breakpoint-down(sm) {
        width: 100%;
        object-fit: contain;
        height: max-content;
        padding-bottom: 1rem;
      }
    }
    @include media-breakpoint-down(sm) {
      .left-side {
        align-items: center;
        width: 100%;
        h1 {
          font-size: 1.5rem;
          text-align: center;
          line-height: 30px;
          font-weight: 300;
          width: 80%;
        }
        .description {
          text-align: center;
        }
      }
    }
  }
}
