@import '../../../../styles/mixins';

.support-step-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  grid-column: span 2;
  margin-top: 1rem;
  .step-and-title {
    display: flex;
    align-items: center;
    gap: 1rem;
    .step-number {
      font-size: $font-size-normal;
      height: 1.5rem;
      width: 1.5rem;
      border-radius: 50%;
      background-color: $color-dark;
      display: flex;
      align-items: center;
      justify-content: center;
      p {
        color: $color-white;
      }
    }
  }
  .edit {
    font-size: $font-size-small;
    cursor: pointer;
    outline: none;
  }
}

.support-step-divider {
  width: 95%;
  height: 1px;
  background-color: $color-gray-lighter;
  grid-column: span 2;
  margin: 1rem 0 1rem 5%;
}
