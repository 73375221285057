@import '../../styles/mixins';

.manage-group-edit-page {
  .btn-save {
    color: $primary;
    background-color: transparent;
    box-shadow: none;
    @include media-breakpoint-up(lg) {
      background-color: $primary;
      color: #fff;
    }
  }

  .btn-cancel {
    background-color: transparent;
    box-shadow: none;
  }

  @include media-breakpoint-up(lg) {
    .form-control {
      &:active,
      &:focus {
        border-color: #6e9fe0;
      }
    }
  }
}
