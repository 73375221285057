.rpm-dashboard {
  .stats-sidebar {
    grid-area: stats-sidebar;
    background-color: $color-dark;
    width: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 0.625rem;
    padding: 1.5rem;
    height: 100vh;
    

    .devices-container {
      display: flex;
      align-items: center;
      flex-direction: column;
      background-color: #242424;
      padding: 1rem;
      border-radius: 0.625rem;
    }
  }
  .health-data-records-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    max-height: 100%;
    overflow-y: auto;
    @include media-breakpoint-down(md) {
      width: calc(100% - 3rem);
      margin: 0 auto;
    }
    .end-reached {
      font-size: 0.75rem;
      color: #908e87;
      font-weight: 500;
      text-align: center;
      width: 100%;
      margin-top: 2rem;
      @include media-breakpoint-down(md) {
        padding-top: 1.25rem;
        border-top: 1px solid rgba(160, 158, 156, 0.5);
        margin-top: 2.5rem;
      }
    }
  }
}
