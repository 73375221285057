.rpm-dashboard {
    .roadmaps-container-skeleton {
        height: 26.375rem;
        background-color: $color-white;
        width: 100%;
        grid-area: roadmaps;
        padding: 1.5rem;
        display: flex;
        flex-direction: column;
            min-width: 100%;

        header {
            height: 26px;
            border-radius: 10px;
            width: 25%;
        }

        .roadmap-container-skeleton {
            height: 17.625rem;
            width: 100%;
            border-radius: 10px;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;

            @include media-breakpoint-down(sm) {
                min-width: 266px;
            }

            .roadmap-container-footer-skeleton {
                background-color: $white;
                width: 100%;
                height: 6.875rem;
                border-radius: 0 0 10px 10px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;
                padding: 0 1.5rem;
                gap: 0.5rem;

                .roadmap-container-footer-skeleton-title {
                    height: 1rem;
                    width: 100%;
                    border-radius: 4px;
                }

                .roadmap-container-footer-skeleton-subtitle {
                    height: 1rem;
                    width: 60%;
                    border-radius: 4px;
                }
            }
        }
    }

    .roadmap-skeleton {
        animation: skeleton-loading 1s linear infinite alternate;
    }

    @keyframes skeleton-loading {
        0% {
            background-color: #d8d8d8;
        }

        100% {
            background-color: #EAEAEA;
        }
    }


}