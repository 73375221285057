.audio-message-player-container {
  display: flex;
  align-items: center;
  gap: 1rem;
  border-color: #e5e5e5;
  padding: 0.6rem;
  border-radius: 0.5rem;
  .play-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 auto;
    z-index: 999;
    button {
      outline: none;
      width: 3rem;
      height: 3rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 6px;
    }
  }

  .bars-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 auto;
    z-index: 999;
    .bar-unit {
      width: 2.45px;
      border-radius: 1.4rem;
    }
  }
}
