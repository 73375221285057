.payments-tab-selector {
  display: flex;
  width: 100%;
  flex-direction: column;
  .mobile-title {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-items: start;
    align-items: center;
    margin-bottom: 1.5rem;
    width: 100%;
    h2 {
      font-family: $serif-font-family;
      font-size: $font-size-large;
    }
  }
  .payment-sections {
    display: inline-flex;
    justify-content: space-between;
    gap: 1rem;
    flex-wrap: wrap;
    margin-bottom: 1.5rem;
    width: 100%;
    align-items: flex-start;
    .payment-section {
      display: grid;
      gap: 0.2rem;
      justify-items: center;
      button {
        outline: none;
      }
      p {
        margin: 0;
        font-weight: 500;
      }
    }
  }
}
