input[type='checkbox'] {
  position: relative;

  &.is-invalid::after {
    border: 1px solid $danger;
  }

  &::after {
    content: '';
    width: 1.4rem;
    height: 1.4rem;
    position: absolute;
    border-radius: 30%;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;

    background-color: white;
    border: 1px solid $color-item-gray;
    @include media-breakpoint-down(md) {
      border: 1px solid $color-assessment-none;
    }
  }

  &:checked::after {
    background-color: #2f80ed;
    border: 1px solid #2f80ed;
  }

  &:checked::before {
    content: '';
    background-image: url(https://s.w.org/images/core/emoji/13.0.0/svg/2714.svg);
    width: 0.7rem;
    height: 0.7rem;
    filter: brightness(9);
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    color: white;
    z-index: 4;
    position: absolute;
  }

  ~ label {
    margin-left: 0.75em;
  }
}

input[type='email'],
input[type='number'],
input[type='password'],
input[type='search'],
input[type='tel'],
input[type='text'],
input[type='url'],
input[type='date'],
textarea {
  background-color: $white;
  font-family: poppins, sans-serif;
  @extend .mrm-pl-3;
  border-radius: 0.625rem;
  min-height: 45px;
  border: 1px solid $color-light-gray;
}

.form-group {
  margin-bottom: 1.3rem;
}
