.rpm-profile-info-container {
    &__info {
        &__calls {
            display: flex;
            flex-direction: column;
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 0.5rem;

            &__load-more {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 2rem;
                color: $white;
                background-color: $color-dark;
                font-size: 0.75rem;
                font-weight: 400;
                border-radius: 0.25rem;
                cursor: pointer;
                padding: 0 1rem;
                width: max-content;
                margin: 0 auto;
            }
            &__item {
                width: 100%;
                margin: 0 auto;
                background-color: $color-dark;
                box-shadow: 0px 4px 20px 1px rgba(0, 0, 0, 0.1);
                border-radius: 0.625rem;
                padding: 1rem;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;
                gap: 0.5rem;
                position: relative;

                &__profile-container {
                    display: flex;
                    align-items: center;
                    gap: 0.5rem;
                    cursor: pointer;
                    &__picture {
                        height: 2.5rem;
                        width: 2.5rem;
                        border-radius: 0.25rem;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: flex-start;
                        aspect-ratio: 1/1;
                        &__header {
                            height: 1rem;
                            background-color: #4b4a48;
                            width: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            color: $white;
                            font-weight: 700;
                            font-size: 0.5rem;
                            border-radius: 0.25rem 0.25rem 0 0;
                        }
                        &__body {
                            height: 100%;
                            width: 100%;
                            background-color: $white;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            height: 1.5rem;
                            border-radius: 0 0 0.25rem 0.25rem;
                            &__text {
                                font-family: $serif-font-family;
                                font-size: 1rem;
                                font-weight: 900;
                            }
                        }
                    }
                    &__name {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        &__text {
                            font-weight: 400;
                            font-size: 0.875rem;
                            color: $white;
                        }
                        &__description {
                            font-weight: 400;
                            font-size: 0.625rem;
                            color: #a4a2a1;
                            text-align: left;
                        }
                    }
                }

                &__time {
                    cursor: pointer;
                    height: 2rem;
                    display: flex;
                    align-items: center;
                    width: 100%;
                    background: rgba(98, 97, 93, 0.5);
                    padding: 0 1rem;
                    border-radius: 0.25rem;
                    gap: 0.5rem;

                    svg {
                        margin-right: 0.5rem;
                    }
                    &__text {
                        font-size: 0.75rem;
                        color: $white;
                        font-weight: 400;
                        strong {
                            font-weight: 600;
                            margin-right: 0.5rem;
                        }
                    }
                }

                &__footer {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 100%;
                    &__right {
                        display: flex;
                        align-items: center;
                        gap: 0.5rem;
                        &__text {
                            font-size: 0.75rem;
                            color: $white;
                            font-weight: 500;
                        }
                    }
                }

                &__three-dots {
                    position: absolute;
                    top: 1.25rem;
                    right: 1rem;
                    cursor: pointer;
                    width: 1.5rem;
                    height: 1.5rem;
                    border: none;
                    background-color: transparent;
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    outline: none;
                }

                &__dropdown {
                    height: 5.5625rem;
                    width: 9.3125rem;
                    background-color: $white;
                    border-radius: 0.25rem;
                    position: absolute;
                    top: 1.25rem;
                    right: 1rem;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    z-index: 2;
                    box-shadow: 1px 1px 20px 1px rgba(0, 0, 0, 0.05);
                    padding: 0rem 0.5rem;
                    &__item {
                        height: 2.75rem;
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        cursor: pointer;
                        font-size: 0.75rem;
                        font-weight: 600;
                        padding-left: 0.5rem;
                        color: $color-dark;
                    }
                }
            }
        }
    }
}
