@import '../../styles/mixins';

.manage-edit-user-profile-page {
  .common-header {
    button {
      background-color: transparent;
      box-shadow: none;

      &:hover {
        opacity: 0.7;
      }
    }

    .btn-save {
      color: $primary;
    }
  }
}

.manage-edit-user-profile-modal {
  display: flex;
  flex-direction: column;
  width: 97%;
  margin: 0 auto;
  .modal-header {
    position: sticky;
    width: 100%;
    background: white;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem;
    align-items: center;
    padding: 1rem 1.5rem;
    .chevron {
      justify-self: flex-start;
    }
    h2 {
      width: max-content;
      font-weight: 500;
      font-family: $serif-font-family;
    }
    .save-btn {
      justify-self: end;
    }
  }
}

.manage-edit-user-profile-component {
  @extend .mrm-mb-2;

  label {
    @extend .mrm-ml-0_5;
    margin-bottom: 0;
  }

  .error-message {
    @extend .mrm-ml-0_5;
    color: $danger;
  }

  .action-button {
    width: 17em;
  }

  .account-photo {
    border-radius: 50%;
    width: 4em;
    height: 4em;
  }

  .invalid-avatar {
    font-size: $font-size-normal;

    &.is-invalid {
      color: $danger;
    }
  }
  @include media-breakpoint-up(lg) {
    .form-control {
      border-color: #cccccc;
      &:active,
      &:focus {
        border-color: #6e9fe0;
      }
    }
  }
}

.desktop-page-secondary-header-wrapper {
  .btn-save {
    float: right;
    background: $color-item-blue;
    color: #fff;
  }
}

.edit-profile-container {
  display: flex;
  flex-direction: column;
  background-color: $white;
  margin: 0rem auto;
  justify-content: center;
  width: 100%;
  border-radius: 0.5rem;
  padding: 1.5rem 0rem;
  h1 {
    font-size: $font-size-largest;
    font-family: $serif-font-family;
    margin: 0 auto;
    margin-bottom: 0.5rem;
  }

  .form-group {
    position: relative;
    .icon {
      position: absolute;
      top: 0.7rem;
      left: 2.5rem;
    }
    .email {
      top: 0.9rem;
      left: 2.25rem;
    }
    .phone {
      top: 0.6rem;
    }
  }

  .form-control {
    border-radius: 0.5rem;
    border: 1px solid $color-gray-lighter;
    padding: 1.25rem 1rem;
    padding-left: 3rem;
    font-size: $font-size-normal;
    width: 100%;
    margin-bottom: 1rem;
    max-width: 90%;
    margin: 0 auto;
    font-weight: 300;
  }
  .checkbox-grid {
    display: grid;
    gap: 1rem;
    width: 100%;
    max-width: 90%;
    padding: 0 2rem;

    .form-check {
      display: flex;
      align-items: flex-start;
    }
  }
  .text-area-edit-profile {
    height: 7rem;
    padding: 1rem;
  }
  .save-cancel-grid {
    display: none;
    gap: 0.5rem;
  }
  .btn-cancel-edit {
    display: block;
    background-color: transparent;
    width: 100%;
    padding: 0.5rem;
    color: $color-dark;
    border-radius: 0.5rem;
    justify-items: center;
    min-width: max-content;
    border: 1px solid $color-dark;
    max-width: 85%;
    margin: 0 auto;
    &:hover {
      background-color: $color-dark;
      color: $white;
    }
  }
  .btn-save-edit {
    display: block;
    background-color: $color-dark;
    color: $white;
    width: 100%;
    padding: 0.6rem;
    border-radius: 0.5rem;
    justify-items: center;
    min-width: max-content;
    max-width: 85%;
    margin: 0 auto;
  }

  .resend-delete-grid {
    display: grid;
    gap: 0.5rem;
    justify-items: center;
    margin-top: 1rem;
    grid-template-columns: 1fr 1fr;
    margin: 0 auto;
    width: 85%;
    padding: 1rem 0;
    .action-button {
      width: 100%;
      background-color: $color-gray;
      color: $white;
      padding: 0.5rem;
      border-radius: 0.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: $font-size-normal;
    }
  }
}

@include media-breakpoint-up(lg) {
  .edit-profile-container {
    max-width: 35%;
    margin: 1.5rem auto;
    .save-cancel-grid {
      display: grid;
    }
  }
}

.edit-profile-container {
  background-color: $color-white;
}
