@import '../../styles/mixins';

.analytics-home {
  display: flex;
  flex-direction: column;
  height: 80vh;
  width: 76.5%;
  margin: 0 auto;
  margin-top: 5vh;
  p {
    font-weight: 300;
  }

  .analytics-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 2rem;
    background-color: $color-white;
    margin-top: 1.2rem;
  }
}

.card-wrapper {
  display: flex;
  flex-direction: column;
  justify-items: center;
  padding: 1.5rem;
  background-color: $white;
  border-radius: 0.5rem;
  .h2 {
    font-weight: 300;
  }
}
