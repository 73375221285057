// Buttons primary
#c-bns button:first-child,
#s-bns button:first-child {
  color: #fff;
  color: #fff;
  color: var(--cc-btn-primary-text);
  background: #343434 !important;
  background: var(--cc-btn-primary-bg);
}

// Buttons primary
.cc_div .c-bn {
  color: #40505a;
  color: #2d4156;
  color: var(--cc-btn-secondary-text);
  background: white;
}
#cm .c_link:hover {
  background: white;
}

//titles
#c-ttl {
  font-family: var(--serif-font-family);
  color: #343434;
}

//texts
#c-text {
  font-family: var(--sans-serif-font-family);
  color: #343434;
}

#cm {
  background-color: var(--primary-bg-white);
}
