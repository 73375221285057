.rpm-provider-dashboard {
    .rpm-provider-container {
        .search-container {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 2rem 0;
            min-height: 2rem;

            @include media-breakpoint-down(md) {
                flex-direction: column;
                justify-content: flex-start;
                width: 100%;
                gap: 1rem;
                padding: 2rem 0;
                @include media-breakpoint-down(sm) {
                    padding: 2rem;
                }
            }

            .left-search-container {
                display: flex;
                align-items: center;
                gap: 0.5rem;
                justify-content: flex-start;
                @include media-breakpoint-down(md) {
                    width: 100%;
                }
                .search-container-title {
                    font-size: $font-size-normal;
                    font-weight: 500;
                    color: #515151;
                    width: 100%;
                    flex-wrap: nowrap;
                    white-space: nowrap;
                }
                .search-container-number {
                    min-width: 2.8185rem;
                    height: 1.625rem;
                    background-color: #d5d1cd;
                    border-radius: 1.5rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 0 0.5rem;
                    .search-container-number-text {
                        font-size: $font-size-smaller;
                        font-weight: 800;
                        color: #515151;
                    }
                }
            }
            .right-search-container {
                display: flex;
                align-items: center;
                gap: 0.5rem;
                justify-content: flex-end;
                height: 2rem;
                width: 100%;
                .search-container-input {
                    position: relative;
                    width: 14.375rem;
                    height: 2rem;
                    @include media-breakpoint-down(md) {
                        width: 100%;
                    }
                    svg {
                        position: absolute;
                        top: 50%;
                        left: 0.5rem;
                        transform: translateY(-50%);
                    }
                    input {
                        width: 100%;
                        min-height: 2rem;
                        height: 2rem;
                        max-height: 2rem;
                        border: 1px solid #d3d0cc;
                        border-radius: 0.3125rem;
                        padding-left: 1.75rem;
                        padding-right: 1rem;
                        font-size: 0.75rem;
                        font-weight: 500;
                        color: rgba(52, 52, 52, 0.5);
                        &:focus {
                            outline: none;
                        }
                        &::placeholder {
                            font-size: 0.75rem;
                        }
                    }
                }
            }
        }
    }
}
