.rpm-dashboard {
  .my-team {
    grid-area: my-team;
    background-color: $color-white;
    @include media-breakpoint-down(md) {
      background-color: transparent;
    }
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 1.5rem 0;
    border-radius: 10px;
    h3 {
      font-size: $font-size-normal;
      font-weight: 500;
      width: calc(100% - 3rem);
      margin: 0 auto;
    }
    .my-team-card-container {
      display: flex;
      flex-direction: column;
      margin-top: 1rem;
      width: 100%;
      gap: 0.5rem;
      .my-team-card {
        height: 80px;
        width: calc(100% - 3rem);
        margin: 0 auto;
        box-shadow: 0px 4px 20px 1px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        background-color: $white;
        display: flex;
        align-items: center;
        padding: 1rem;
        justify-content: space-between;
        .my-team-card-left {
          display: flex;
          align-items: center;
          gap: 0.5rem;
          img {
            height: 48px;
            width: 48px;
            border-radius: 10px 10px 0px 10px;
            object-fit: cover;
          }
          .my-team-card-left-text {
            display: flex;
            flex-direction: column;
            h4 {
              font-size: $font-size-small;
              font-weight: 600;
              margin: 0;
              color: $color-dark;
            }
            p {
              font-size: 0.625rem;
              font-weight: 400;
              margin: 0;
              color: rgba(89, 89, 89, 0.7);
            }
          }
        }
      }
    }
  }
}
