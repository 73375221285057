.rpm-dashboard {
  .check-in {
    grid-area: check-in;
    background-color: $color-white;
    @include media-breakpoint-down(md) {
      background-color: transparent;
    }
    width: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 0.625rem;
    padding: 1.5rem 0;
    height: min-content;

    h3 {
      font-size: $font-size-normal;
      font-weight: 500;
      width: calc(100% - 3rem);
      margin: 0 auto;
    }
    .check-in-card-container {
      display: flex;
      flex-direction: column;
      margin-top: 1rem;
      width: 100%;
      gap: 0.5rem;
      .check-in-card {
        min-height: 132px;
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 1rem;
        background-color: $color-dark;
        box-shadow: 0px 4px 20px 1px rgba(0, 0, 0, 0.1);
        width: calc(100% - 3rem);
        margin: 0 auto;
        border-radius: 10px;
        .check-in-card-header {
          display: flex;
          align-items: center;
          gap: 0.5rem;
          img {
            height: 38px;
            width: 38px;
            border-radius: 10px 10px 0px 10px;
            object-fit: cover;
          }
          .check-in-card-header-text {
            display: flex;
            flex-direction: column;
            h4 {
              font-size: $font-size-small;
              font-weight: 400;
              margin: 0;
              color: $white;
            }
            p {
              font-size: 0.75rem;
              font-weight: 400;
              margin: 0;
              color: #a4a2a1;
            }
          }
        }
        .check-in-card-body {
          margin-top: 0.75rem;
          min-height: 32px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          background: rgba(98, 97, 93, 0.5);
          border-radius: 4px;
          gap: 0.5rem;
          .check-in-card-body-date {
            font-size: 0.75rem;
            font-weight: 600;
            margin: 0;
            color: $white;
          }
          .check-in-card-body-time {
            font-size: 0.75rem;
            font-weight: 400;
            margin: 0;
            color: $white;
          }
        }
        .check-in-card-footer {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          margin-top: 0.5rem;
          .check-in-card-footer-right {
            p {
              color: $white;
              font-size: 0.75rem;
              font-weight: 500;
            }
            display: flex;
            align-items: center;
            gap: 0.5rem;
          }
        }
      }
    }
  }
}
