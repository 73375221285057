@import '../../styles/mixins';
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;1,300;1,500;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@400;700&display=swap');

$dark: #343434;
$background-sidebar: #f0ece8;
$background: $color-white;

p {
  font-family: $sans-serif-font-family;
  color: $dark;
}

.home-signup {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  background-color: $color-white;
  width: 100%;
  overflow: hidden;

  .signup-sidebar {
    position: relative;
    background: $color-dark;
    top: 0;
    left: 0;
    min-width: 100%;
    z-index: 1;
    height: 100%;
    padding: 1rem 0rem;

    .logo {
      width: 6.5rem;
      margin-top: 1rem;
      padding-left: 1rem;
    }

    .signup_form {
      width: 100%;
      display: flex;
      flex-direction: column;
      position: absolute;
      bottom: 0;

      .form_c {
        display: flex;
        flex-direction: column;
        min-width: 100%;
        h3 {
          font-family: $serif-font-family;
          font-weight: bold;
          font-size: 2rem;
          color: $white;
          padding: 0 1rem;
          padding-bottom: 1rem;
        }
      }

      p {
        font-size: 1rem;
        font-weight: 300;
        padding: 0 1rem;
        color: $white;
      }

      .ca {
        font-weight: 600;
        font-size: 1.1rem;
        margin-top: 1rem;
      }
      .form-group {
        svg {
          position: absolute;
          margin-top: 0.7rem;
          margin-left: 0.7rem;
          color: $primary;
        }

        .email {
          position: absolute;
          margin-top: 0.85rem;
        }
      }

      .form-control {
        padding-left: 2.5rem;

        &::placeholder {
          font-size: $font-size-normal;
        }
      }

      #disabled-custom-disabled {
        background-color: hsl(0, 0%, 71%);
        color: white;
      }

      form {
        background-color: $color-white;
        width: 100%;
        margin: 0 auto;
        padding: 1.5rem 1rem;
        border-radius: 2rem 2rem 0 0;
        display: grid;
        margin-top: 2rem;
        .error-message {
          color: $color-alerts;
          padding-top: 1rem;
          font-size: $font-size-smaller;
          font-weight: 500;
        }
        .is-invalid-form {
          border-color: red;
          background: #f4e6e1;
        }
        input {
          width: 100%;
          height: 2.7rem;
          border: 1px solid $color-gray-lighter;
          border-radius: 0.5rem;
        }
        ::placeholder {
          font-weight: 300;
          font-size: 1rem;
          color: #363636;
        }
        svg {
          float: left;
          margin-left: 1rem;
          margin-top: -1.85rem;
          position: relative;
          z-index: 2;
          color: red;
        }
        button {
          width: 100%;
          background-color: $color-dark;
          color: white;
          padding: 0.7rem;
          border-radius: 0.5rem;
          font-family: 'Poppins';
          font-weight: 500;
          min-width: 100%;
          justify-content: center;
          margin-top: 2rem;
        }
      }
      .aha {
        text-align: center;
        text-decoration: underline;
        font-weight: 500;
        margin-top: 1rem;
        color: $dark;
      }
    }
  }

  .s-content {
    width: 67%;
    background-color: $color-white;
    padding: 2rem;
    border-radius: 0.8rem;
    min-height: 100vh;
    margin-left: 33%;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    display: none;

    .login_content {
      width: 100%;
      display: grid;
      justify-items: center;
      gap: 1rem;
      .illustration {
        width: 60%;
      }
      h1 {
        text-align: center;
        margin: 0;
        font-weight: 700;
        font-size: 1.75rem;
        font-family: $serif-font-family;
        width: 65%;
      }
      p {
        text-align: center;
        max-width: 26rem;
        font-weight: 300;
        font-size: 1rem;
      }
    }
  }
}
.validation {
  color: $color-alerts;
  padding-top: 1rem;
  font-size: $font-size-smaller;
  font-weight: 500;
}

@include media-breakpoint-up(lg) {
  .home-signup {
    .signup-sidebar {
      position: fixed;
      top: 0;
      left: 0;
      min-width: 33%;
      width: 36%;
      z-index: 1;
      height: 100%;
      padding: 1rem 2.31rem 1rem 2.31rem;
      .signup_form {
        width: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
        bottom: 0;
        min-height: 100%;

        .form_c {
          display: flex;
          flex-direction: column;
          min-width: 100%;
          margin: auto;
          padding: 0 1rem;

          .terms {
            text-align: center;
            font-weight: 300;
            font-size: 0.7rem;
          }
          h3 {
            font-family: $serif-font-family;
            font-weight: bold;
            font-size: 2rem;
            color: $white;
            padding: 0rem;
          }
        }

        p {
          font-size: 1rem;
          font-weight: 300;
          color: $white;
          padding: 0rem;
          padding-top: 0.5rem;
        }

        .ca {
          font-weight: 600;
          font-size: 1.1rem;
          margin-top: 1rem;
        }
        .form-group {
          svg {
            position: absolute;
            margin-top: 0.8rem;
            margin-left: 0.7rem;
            color: $primary;
          }
        }

        .form-control {
          padding-left: 2.5rem;
        }

        form {
          background-color: transparent;
          width: 100%;
          margin: 0 auto;
          padding: 1rem 0;
          border-radius: 2rem 2rem 0 0;
          display: grid;

          .form-title {
            font-weight: 600;
            color: $white;
          }

          input {
            width: 100%;
            height: 2.7rem;
            border: 1px solid $color-gray-lighter;
            border-radius: 0.5rem;
          }
          ::placeholder {
            font-weight: 300;
            font-size: 1rem;
            color: #6d786e;
          }
          svg {
            float: left;
            margin-left: 1rem;
            margin-top: -1.85rem;
            position: relative;
            z-index: 2;
            color: red;
          }
          button {
            width: 100%;
            background-color: transparent;
            color: $color-white;
            padding: 0.7rem;
            border: 1px solid $color-white;
            border-radius: 0.5rem;
            font-family: 'Poppins';
            font-weight: 500;
            min-width: 100%;
            justify-content: center;
            margin-top: 0.8rem;
          }
        }
        .aha {
          text-align: center;
          text-decoration: underline;
          font-weight: 500;
          margin-top: 1rem;
          color: $white;
          font-size: $font-size-smaller;
        }
      }
    }
    .s-content {
      display: grid;
    }
  }
}
