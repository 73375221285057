.chat-default {
    .chat-container {
        .chat-conversations {
            display: flex;
            flex-direction: column;
            height: 100%;
            width: 100%;
            background-color: $color-white;
            border-radius: 0.5rem 0 0 0.5rem;

            .chat-conversations-header {
                display: flex;
                align-items: center;
                justify-content: space-between;
                height: 5rem;
                width: 100%;
                padding: 0 1.5rem;

                .chat-conversations-header-title {
                    font-size: 1.625rem;
                    font-weight: 700;
                    color: $color-dark;
                }
            }

            .chat-conversations-search-container {
                display: flex;
                width: 100%;
                position: relative;
                padding: 0 1.5rem;

                svg {
                    position: absolute;
                    top: 50%;
                    left: 2.5rem;
                    transform: translateY(-50%);
                    color: $color-gray;
                }

                .chat-conversations-search {
                    height: 2.5rem;
                    width: 100%;
                    padding: 0 2.5rem;
                    border: none;
                    border-radius: 0.5rem;
                    border: 1px $color-light-gray solid;
                    background-color: $white;
                    font-size: 1rem;
                    font-weight: 300;
                    color: $color-gray;

                    &::placeholder {
                        color: $color-gray;
                    }

                    &:focus {
                        outline: none;
                    }
                }
            }

            .chat-conversations-list {
                display: flex;
                flex-direction: column;
                align-items: center;
                height: calc(100vh - 15rem);
                overflow-y: auto;
                width: 100%;

                .chat-conversation {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    height: 5.9375rem;
                    min-height: 5.9375rem;
                    width: calc(100% - 3rem);
                    border-bottom: 1px solid rgba(196, 196, 196, 0.3);

                    .chat-conversation-info {
                        display: flex;
                        align-items: center;
                        gap: 1rem;
                        width: 90%;

                        .chat-conversation-avatar {
                            height: 3.125rem;
                            width: 3.125rem;
                            border-radius: 0.625rem 0.625rem 0px 0.625rem;
                            background-color: #718371;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            aspect-ratio: 1/1;
                        }

                        .chat-conversation-name-container {
                            display: flex;
                            flex-direction: column;

                            .chat-conversation-name {
                                font-size: 0.875rem;
                                font-weight: 500;
                                color: $color-dark;
                            }

                            .chat-conversation-last-message {
                                font-size: 0.75rem;
                                font-weight: 300;
                                color: $color-dark-green;
                            }
                        }
                    }

                    .chat-conversation-last-message-time {
                        font-size: 0.75rem;
                        font-weight: 300;
                        color: $color-dark-green;
                        flex-wrap: nowrap;
                        min-width: max-content;
                        height: 3.125rem;
                    }
                }
            }
        }
    }
}
