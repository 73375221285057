.ai-response-input-container {
  display: flex;
  flex-direction: column;
  background-color: $white;
  border-radius: 2rem 2rem 0.6rem 0;
  position: absolute;
  bottom: 0;
  align-items: center;
  padding: 1rem 1.25rem;
  width: 100%;
  gap: 1rem;

  .ai-response-input-description {
    strong {
      color: $color-dark;
      font-weight: 600;
    }
    font-size: $font-size-smallest;
    color: $color-dark;
    font-weight: 300;
    text-align: center;
  }

  .ai-response-mark-complete {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 45px;
    border-radius: 10px;
    background-color: $color-dark;
    color: $white;
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
  }

  .send-action-item-response {
    display: flex;
    flex-direction: column;
    width: 100%;

    @include media-breakpoint-down(sm) {
      padding-top: 0;
    }

    textarea {
      position: relative;
      width: 100%;
      border-radius: 2rem;
      height: 1.5rem;
      max-height: 20vh;
      overflow-y: auto;
      padding-left: 1rem;
      padding-top: 0.8rem;
      padding-right: 10%;
      font-size: $font-size-small;

      @include media-breakpoint-down(sm) {
        font-size: $font-size-normal;
      }

      &::placeholder {
        color: $color-dark;
        opacity: 0.4;
        font-size: $font-size-small;
      }
    }
    button {
      position: absolute;
      right: 0;
      margin-top: 0.45rem;
      margin-right: 2rem;
    }
  }
}
