@import '../../../../styles/mixins';

.profile-grid-container {
  display: grid;
  gap: 1rem;
  width: 100%;
  background-color: transparent;
  border-radius: 0.6rem;
  .profile-grid-container-header {
    display: flex;
    justify-content: space-between;
    display: none;
    h1 {
      font-family: $serif-font-family;
      font-size: $font-size-largest;
    }
    button {
      p {
        text-decoration: underline;
      }
    }
  }
  .profile-grid-container-body {
    display: grid;
    row-gap: 1.2rem;
    padding-top: 1rem;
    .profile-items-container {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));

      gap: 0.5rem;
      justify-items: start;
      .profile-item {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        justify-items: start;
        p {
          margin: 0;
          display: flex;
          width: max-content;
          font-weight: 300;
        }
        .subscription-invitation {
          display: grid;
          gap: 0.3rem;
          button {
            display: flex;
            font-size: $font-size-smallest;
            font-weight: 300;
            text-decoration: underline;
          }
        }
        .subscription-status {
          display: grid;
        }
      }
      .sub-grid-start {
        align-items: flex-start;
      }
      p {
        font-weight: 500;
        font-size: $font-size-small;
        word-wrap: break-word;
        max-width: 100%;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .profile-items-container {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .profile-grid-container {
    background-color: $white;
    max-width: 100%;
    .profile-grid-container-header {
      display: flex;
    }
  }
  .profile-grid-container {
    background-color: $color-white;
    max-width: 100%;
  }
}
