@import '../../../../styles/mixins';

.notifications-components-default-page-notifications-list {
  .no-notifications {
    @extend .mrm-mt-2;
    text-align: center;

    .fa-bell {
      color: $primary !important;
      width: 2.5rem !important;
      font-size: 2.5rem !important;
      @extend .mrm-mb-1_5;
    }
  }

  .notification-row {
    cursor: pointer;
    position: relative;
    font-size: $font-size-small;
    padding: 1rem 1rem 1rem 0;
    border-bottom: 1px solid $color-dark-text-dark;
    display: flex;
    align-items: center;

    .initials {
      height: 3.5rem;
      width: 3.5rem;
    }

    p {
      margin-bottom: 0;
      line-height: 16px;
      font-size: $font-size-smaller;
    }

    .name-and-date-grid {
      display: grid;
      gap: 0.5rem;
    }

    .profile-photo {
      border: none !important;
      img {
        height: 3.5rem;
        width: 3.5rem;
        margin-right: 15px;
        margin-left: 5px;
      }
    }

    .notification-primary {
      color: $color-dark-green;
      strong {
        font-weight: 500;
      }
      span {
        font-weight: 300;
      }
    }

    .notification-secondary {
      justify-self: flex-end;
    }

    .unread-marker {
      min-width: 0.5rem;
      min-height: 0.5rem;
      border-radius: 50%;
      background-color: $color-green;
      @extend .mrm-mr-0_25;
      display: inline-block;
      top: 46px;
      left: 5px;
    }
  }

  hr {
    height: 1px;
    margin: 0 -1.5rem;
  }
}
