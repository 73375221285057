@import '../../../../../styles/mixins';
.support-subscription-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 0.8rem;
  border: 1px solid $color-gray-lighter;
  height: 3.78rem;
  padding: 0 1rem;
  width: 18rem;
  p {
    font-size: $font-size-small;
  }
  .title-container {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    max-width: 65%;
    svg {
      min-height: 1rem;
      min-width: 1rem;
      max-height: 1rem;
      max-width: 1rem;
    }
    p {
      font-weight: 300;
    }
    .end-date {
      font-size: 0.6rem;
    }
  }

  .price {
    font-weight: 600;
    font-size: $font-size-small;
  }
}
