@import '../../../../styles/mixins';

.clients-coaches-container {
  background-color: transparent;
  width: 100%;
  border-radius: 0.6rem;
  max-height: 553px;
  overflow-y: auto;
  .title {
    font-weight: 500;
  }
  .clients-coaches-grid {
    display: grid;
    justify-items: start;
    width: 100%;
    row-gap: 0.5rem;
    .clients-coaches-grid-item {
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: center;
      margin-top: 1.3rem;
      .picture-name {
        display: flex;
        align-items: center;
        gap: 0.5rem;
      }
      .view {
        text-decoration: underline;
        color: $color-dark;
        font-size: $font-size-small;
        font-weight: 300;
        cursor: pointer;
      }
    }
  }
  /* width */
  &::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: $color-white;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: $color-gray;
    border-radius: 10px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: $color-dark;
  }
}

@include media-breakpoint-up(lg) {
  .clients-coaches-container {
    width: 100%;
    background-color: $color-white;
  }
}
