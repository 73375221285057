.rpm-client-profile {
    &__container {
        &__right-body {
            &__schedule-section {
                display: flex;
                flex-direction: column;
                width: 100%;
                align-items: flex-start;
                margin-top: 1.5rem;
                gap: 1.5rem;

                h3 {
                    font-size: 0.875rem;
                    font-weight: 400;
                }

                hr {
                    width: 100%;
                    height: 1px;
                    background-color: #a09e9c;
                    opacity: 0.5;
                }

                &__upcoming-calls {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    gap: 1rem;

                    &__no-upcoming-calls {
                        color: #515151;
                        opacity: 0.5;
                        font-weight: 600;
                        border-bottom: 1px solid rgba(160, 158, 156, 0.5);
                        font-size: 0.75rem;
                        font-weight: 600;
                        padding-bottom: 1.25rem;
                        width: 100%;
                        text-align: center;
                    }
                    &__upcoming-call {
                        display: grid;
                        grid-template-columns: repeat(3, 1fr);
                        grid-template-rows: 1fr;

                        width: 100%;
                        min-height: 4.1875rem;
                        background-color: $white;
                        box-shadow: 0px 4px 20px 1px rgba(0, 0, 0, 0.1);
                        border-radius: 0.625rem;
                        padding: 1rem;
                        position: relative;
                        align-items: center;
                        &__picture {
                            height: 2.5rem;
                            width: 2.5rem;
                            border-radius: 0.25rem;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: flex-start;
                            aspect-ratio: 1/1;
                            &__header {
                                height: 1rem;
                                background-color: #4b4a48;
                                width: 100%;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                color: $white;
                                font-weight: 700;
                                font-size: 0.5rem;
                                border-radius: 0.25rem 0.25rem 0 0;
                            }
                            &__body {
                                height: 100%;
                                width: 100%;
                                background-color: $white;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                height: 1.5rem;
                                border-radius: 0 0 0.25rem 0.25rem;
                                &__text {
                                    font-family: $serif-font-family;
                                    font-size: 1rem;
                                    font-weight: 900;
                                }
                            }
                        }
                        &__name {
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            &__text {
                                font-weight: 400;
                                font-size: 0.875rem;
                                color: $color-dark;
                            }
                            &__description {
                                font-weight: 400;
                                font-size: 0.75rem;
                                color: #a4a2a1;
                            }
                        }

                        &__time {
                            display: flex;
                            align-items: flex-start;
                            gap: 0.5rem;
                            padding-left: 30%;

                            &__text {
                                display: flex;
                                flex-direction: column;
                                align-items: flex-start;
                                margin-top: -0.25rem;

                                &__day {
                                    font-weight: 600;
                                    font-size: 0.75rem;
                                    color: $color-dark;
                                }
                                &__hour {
                                    font-weight: 400;
                                    font-size: 0.75rem;
                                    color: $color-dark;
                                }
                            }
                        }

                        &__buttons {
                            display: flex;
                            align-items: center;
                            justify-content: flex-end;
                            gap: 1rem;
                            &__join-now {
                                font-size: 0.75rem;
                                font-weight: 500;
                                cursor: pointer;
                                svg {
                                    margin-left: 0.25rem;
                                }
                                display: flex;
                                align-items: center;
                                opacity: 1;
                                &:hover {
                                    opacity: 1;
                                }
                            }
                            button {
                                outline: none;
                            }
                            &__edit {
                                padding-right: 0.5rem;
                                height: 1rem;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                            }
                        }

                        &__dropdown {
                            height: 5.5625rem;
                            width: 9.3125rem;
                            background-color: $white;
                            border-radius: 0.25rem;
                            position: absolute;
                            top: 1.25rem;
                            right: 1rem;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;
                            z-index: 2;
                            box-shadow: 1px 1px 20px 1px rgba(0, 0, 0, 0.05);
                            padding: 0rem 0.5rem;
                            &__item {
                                height: 2.75rem;
                                width: 100%;
                                display: flex;
                                align-items: center;
                                justify-content: flex-start;
                                cursor: pointer;
                                font-size: 0.75rem;
                                font-weight: 600;
                                padding-left: 0.5rem;
                                color: $color-dark;
                            }
                        }
                    }
                    &__load-more {
                        background-color: $color-dark;
                        color: $white;
                        height: 2rem;
                        width: 9.56rem;
                        border-radius: 0.3rem;
                        margin: 0 auto;
                        font-size: 0.75rem;
                    }
                }
            }
        }
    }
}
