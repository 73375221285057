@import '../../styles/mixins';

.manage-accounts-page {
  .btn {
    font-size: $font-size-smaller;
  }
  .search {
    background: $white;
    -webkit-appearance: none;
    height: 100%;
    @include media-breakpoint-down(md) {
      background: $white;
    }
  }
  .btn-edit {
    color: $color-text-light;
    background-color: $color-background-light-gray;
    min-height: auto;
    font-weight: bold;
    font-size: $font-size-smaller;
  }
  .common-search-bar {
    flex-grow: 1;
    margin-right: 1rem;
  }
  .common-custom-table {
    @include media-breakpoint-down(md) {
      border-radius: 0px;
    }
  }
}

.desktop-page-secondary-header-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 2rem 1.5rem;
  h1 {
    font-family: $sans-serif-font-family;
    color: $color-dark;
  }
  .add-account {
    background-color: $color-dark;
    color: $color-white;
    padding: 0.8rem 1.2rem;
    border-radius: 0.6rem;
    font-weight: 500;
    font-size: $font-size-small;
  }
}

.pagination-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
  .pagination {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    border-radius: 0.6rem;
    background-color: $color-white;
    li {
      a {
        font-weight: 300;
      }
    }
    .previous,
    .next {
      background-color: $color-dark;
      padding: 0.8rem 1.2rem;
      border-radius: 0.6rem;
      a {
        color: $white;
        font-weight: 500;
      }
    }

    .active {
      a {
        text-decoration: underline;
        font-weight: 500;
      }
    }
  }
}
