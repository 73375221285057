@import '../../../../styles/mixins';

.subscription-container {
  display: grid;
  background-color: transparent;
  border-radius: 0.6rem;
  width: 100%;
  .title {
    font-weight: 500;
  }
  .subscription-details-grid {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 0.8rem;
    .subscription-details-grid-description {
      display: flex;
      align-items: center;
      h2 {
        font-size: $font-size-largest;
        font-weight: 700;
        font-family: $serif-font-family;
      }
    }
    .subscription-details-grid-price {
      display: grid;
      justify-items: end;
      width: 40%;
      max-width: 40%;
      h2 {
        font-size: $font-size-normal;
        font-weight: 600;
      }
      p {
        font-size: $font-size-smallest;
        font-weight: 400;
        text-decoration: underline;
        text-align: right;
        min-width: 100%;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .subscription-container {
    background-color: $color-white;
    .subscription-details-grid {
      .subscription-details-grid-description {
        h2 {
          font-size: $font-size-largest;
        }
      }
      .subscription-details-grid-price {
        h2 {
          font-size: $font-size-largest;
        }
        p {
          font-size: $font-size-smallest;
        }
      }
    }
  }
}
