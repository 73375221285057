@import '../../styles/mixins';

.user-terms-and-conditions-modal {
    .modal-header {
        padding-top: 1.5rem;
        display: flex;
        flex-direction: column;
        margin: 0 auto;
        align-items: center;
        p {
            text-align: center;
            font-size: $font-size-small;
            font-weight: 300;
            color: $color-text-light;
        }
        .modal-title {
            font-family: $serif-font-family;
            margin: 0 auto;
        }
    }
    .modal-body {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        padding: 1rem 2rem 2rem 2rem;
        .item {
            display: flex;
            align-items: center;
            color: $color-dark;
            font-weight: 400;
            cursor: pointer;
            user-select: none;
            a {
                margin-left: 1rem;
            }
        }
        .link-icon {
            // font awesome icon width 1rem
            width: 1rem;
            height: 1rem;
            margin-left: -0.5rem;
            color: $color-dark;
        }

        .terms-button {
            background-color: $color-dark;
            color: $white;
            border-radius: 0.6rem;
            margin: 0 auto;
            max-width: fit-content;
            padding: 0.85rem;
            font-size: $font-size-small;

            &:disabled {
                background-color: $color-gray;
                color: $color-white;
            }
        }
    }
}
