@import '../../styles/mixins';
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;1,300;1,500;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@400;700&display=swap');

$dark: #343434;
$background-sidebar: #f0ece8;
$background: $color-white;

p {
  font-family: $sans-serif-font-family;
  color: $dark;
}

::placeholder {
  font-size: 1rem;
}
.home-onboarding-confirmation {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  background-color: $color-white;
  width: 100%;
  overflow: hidden;
  .login_sidebar {
    position: relative;
    background: $color-dark;
    top: 0;
    left: 0;
    min-width: 100%;
    z-index: 1;
    height: 100%;
    padding: 1rem 0rem;
    .logo {
      width: 6.5rem;
      margin-top: 1rem;
      display: none;
    }
    .login_form {
      width: 100%;
      display: flex;
      flex-direction: column;
      position: absolute;
      bottom: 0;
      .success-block {
        padding-bottom: 1.5rem;
      }

      .form_c {
        display: flex;
        flex-direction: column;
        min-width: 100%;
        background-color: $color-white;
        width: 100%;
        margin: 0 auto;
        padding: 1.5rem 1rem;
        border-radius: 2rem 2rem 0 0;
        padding-bottom: 20%;

        button {
          width: 100%;
          background-color: $color-dark;
          color: white;
          padding: 0.7rem;
          border-radius: 0.5rem;
          font-family: 'Poppins';
          font-weight: 500;
          min-width: 100%;
          justify-content: center;
        }

        .fake_carousel {
          justify-self: center;
          display: inline-flex;
          margin-top: 1.5rem;
          gap: 0.8rem;
        }

        .welcome {
          margin: 0;
          margin-bottom: 0.7rem;
          font-family: $serif-font-family;
          font-weight: bold;
          font-size: 2.625rem;
          color: $white;
          padding: 0 1rem;
        }

        .subscription-details {
          display: flex;
          flex-direction: column;
          p {
            font-weight: 500;
            margin: 0;
            color: $color-dark;
          }
          hr {
            margin-top: 1.5rem;
            margin-bottom: 1.5rem;
          }
          .details-grid {
            display: grid;
            width: 100%;
            gap: 2.5rem;
            .billing-item {
              p {
                font-size: 0.8rem;
              }
              display: flex;
              width: 100%;
              justify-content: space-between;
              .desc {
                font-weight: 300;
              }
            }
          }
          .legals {
            text-decoration: underline;
            font-size: 0.875rem;
            padding-bottom: 1.5rem;
            font-weight: 300;
            color: $white;
          }
        }
      }
      h3 {
        font-family: $serif-font-family;
        font-weight: bold;
        font-size: 2.625rem;
        color: $white;
        padding: 0 1rem;
      }
      p {
        font-size: 1rem;
        font-weight: 300;
        padding: 0 1rem;
        color: $white;
      }

      .ca {
        font-weight: 600;
        font-size: 1.1rem;
        margin-top: 1rem;
      }
      .form-group {
        margin: 0.5rem 0rem;
        svg {
          position: absolute;
          margin-top: 0.7rem;
          margin-left: 0.7rem;
          color: $primary;
        }
      }

      .form-control {
        padding-left: 2.5rem;
      }
      .pre-checkout-price {
        padding-top: 2rem;
        display: flex;
        justify-items: space-between;
        align-items: center;
        .check-description {
          font-size: $font-size-smallest;
          padding-right: 3rem;
          margin: 0;
        }
        .paying-now {
          display: grid;
          justify-items: flex-end;
          gap: 0.5rem;
          p {
            font-size: $font-size-smallest;
            width: max-content;
            margin: 0;
          }
          h3 {
            font-family: $sans-serif-font-family;
            font-weight: 600;
            font-size: 2rem;
          }
        }
      }

      .aha {
        text-align: center;
        text-decoration: underline;
        font-weight: 500;
        margin-top: 1rem;
        color: $dark;
      }

      .subscription {
        display: flex;
        flex-direction: column;
        margin: 0;

        p {
          font-size: 1rem;
          font-weight: 500;
        }
        .subscription-plan {
          .tag {
            background-color: $color-green;
            color: white;
            max-width: min-content;
            padding: 0.2rem;
            margin: 0;
            margin-bottom: 0.5rem;
            border-radius: 0.5rem;
            font-size: $font-size-smallest;
            font-weight: 500;
          }
          .price {
            font-size: $font-size-small;
          }
          padding: 0.2rem 0.5rem 0 0.5rem;
          display: flex;
          align-items: flex-end;
          width: 100%;
          justify-content: space-between;
        }
      }
      hr {
        height: 0.11rem;
        background-color: #dfdede;
        width: 100%;
      }
    }
  }

  .s-content {
    width: 67%;
    background-color: $color-white;
    padding: 2rem;
    border-radius: 0.8rem;
    min-height: 100vh;
    margin-left: 33%;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    display: none;

    .login_content {
      width: 100%;
      display: grid;
      justify-items: center;
      gap: 0.5rem;
      .illustration {
        width: 60%;
      }
      h1 {
        text-align: center;
        margin: 0;
        font-weight: 700;
        font-size: 1.75rem;
        font-family: $serif-font-family;
        width: 65%;
      }
      p {
        text-align: center;
        max-width: 26rem;
        font-weight: 300;
        font-size: 1rem;
      }
    }
  }
}
.validation {
  color: red;
  padding-top: 1rem;
}

#card-element {
  border-radius: 4px 4px 0 0;
  padding: 12px;
  border: 1px solid rgba(50, 50, 93, 0.1);
  max-height: 44px;
  width: 100%;
  background: white;
  box-sizing: border-box;
}

@include media-breakpoint-up(lg) {
  .home-onboarding-confirmation {
    .login_sidebar {
      position: fixed;
      top: 0;
      left: 0;
      min-width: 33%;
      width: 36%;
      z-index: 1;
      height: 100%;
      padding: 1rem 2.31rem 1rem 2.31rem;
      .logo {
        display: block;
      }
      .login_form {
        width: 100%;
        display: grid;
        gap: 1rem;

        position: relative;
        bottom: 0;
        margin-top: 15%;
        .form_c {
          display: flex;
          flex-direction: column;
          min-width: 100%;
          margin: auto;
          padding: 0 1rem;
          background-color: transparent;
          width: 100%;
          margin: 0 auto;
          padding: 1rem 0;
          border-radius: 2rem 2rem 0 0;
          .subscription-details {
            p {
              color: $color-white;
            }
          }
          button {
            background-color: $color-white;
            color: $color-dark;
          }
        }
        h3 {
          font-family: $serif-font-family;
          font-weight: bold;
          font-size: 2.625rem;
          color: $white;
          padding: 0rem;
        }
        p {
          font-size: 1rem;
          font-weight: 300;
          color: $white;
          padding: 0rem;
        }

        .ca {
          font-weight: 600;
          font-size: 1.1rem;
          margin-top: 1rem;
        }
        .form-group {
          margin: 0.8rem 0rem;
          svg {
            position: absolute;
            margin-top: 0.7rem;
            margin-left: 0.7rem;
            color: $primary;
          }
        }

        .form-control {
          padding-left: 2.5rem;
        }

        .aha {
          text-align: center;
          text-decoration: underline;
          font-weight: 500;
          margin-top: 1rem;
          color: $white;
          font-size: $font-size-smaller;
        }
      }
      .check-description {
        font-size: $font-size-smallest;
        padding-right: 8rem;
        margin: 0;
      }
    }
    .s-content {
      display: grid;
      margin-top: -1rem;
      .login_content {
        .welcome-desc {
          max-width: 75%;
        }
        .parent-roadmap-image {
          width: 100%;
          background-color: $white;
          border-radius: 0 0 1rem 1rem;
          .roadmap-image {
            .button-and-text {
              bottom: 0;
              left: 0;
              position: 'relative';

              padding: 0 0 2.2rem 1rem;
              h1 {
                color: $white;
                text-align: left;
                padding-bottom: 1rem;
              }
              button {
                background-color: $white;
                color: $color-dark;
                display: flex;
                width: max-content;
                padding: 0.15rem;
                align-items: center;
                border-radius: 10rem;
                gap: 0.5rem;
                padding-right: 1rem;
                font-weight: 500;
              }
            }
          }
          .description {
            position: relative;
            left: 0;
            text-align: left;
            padding: 1rem;
            font-weight: 300;
          }
        }
      }
    }
  }
}
