.screen-capture {
  .start-recording-btn {
    background-color: $color-dark;
    color: $color-white;
    padding: 0.75rem 1rem;
    border-radius: 0.6rem;
  }
  .cancel-recording {
    background-color: transparent;
    color: $color-dark;
    border: 2px solid $color-dark;
    font-weight: 500;
    border-radius: 0.6rem;
  }
}
