.chat-page {
  .chat-page-wrapper {
    .messages-container {
      .header {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        justify-content: space-between;
        padding: 1.5rem 2rem;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        button {
          outline: none;
        }
        .name-and-status {
          display: flex;
          flex-direction: column;
          align-items: center;
          .name {
            font-weight: 300;
          }
          .status {
            font-size: $font-size-smallest;
            opacity: 0.5;
          }

          .edit-group-info {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 1rem;

            .group-name-container {
              margin-top: 1rem;
              display: flex;
              flex-direction: column;
              align-items: center;
              text-align: center;
              input {
                border: none;
                outline: none;
                border-bottom: 1px solid #e4e4e4;
                background: none;
                font-weight: 300;
                font-size: 1.5rem;
                margin-bottom: 0.5rem;
              }
              h1 {
                font-family: $sans-serif-font-family;
                font-weight: 300;
                font-size: $font-size-largest;
              }
              p {
                font-weight: 600;
                font-size: $font-size-smallest;
                color: #7d7d7d;
              }
            }
          }
        }
      }
    }
  }
}
