@import '../../styles/mixins';

.user-notifications-settings-page {
  .container {
    margin-top: 1rem;
  }
  .header {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    h1 {
      font-family: $serif-font-family;
      font-size: larger;
      padding: 2rem 0 1rem 0;
    }
  }

  .notification-settings-page-row {
    div:first-child {
      text-align: center;
    }
  }
}
