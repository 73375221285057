@import '../../../../styles/mixins';

.payment-method-container {
  display: grid;
  row-gap: 1.2rem;
  border-radius: 0.6rem;
  .title {
    font-weight: 500;
  }
  .card-info {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .card-type {
      display: flex;
      align-items: center;
      gap: 0.6rem;
    }
    .card-number {
      display: grid;
      gap: 0.6rem;
      justify-items: end;
      .buttons-container {
        display: flex;
        gap: 1rem;
        align-items: center;
      }
      .update {
        text-decoration: underline;
        font-weight: 500;
        font-size: $font-size-smaller;
      }
    }
  }
}
