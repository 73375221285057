.chat-page {
  .chat-page-wrapper {
    .content {
      .content-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        justify-content: space-between;
        padding: 2rem 2rem 1rem 2rem;
        h1 {
          font-size: 2rem;
          font-family: $sans-serif-font-family;
          font-weight: 300;
        }
        button {
          outline: none;
        }
      }
    }
  }
}
