/* Legacy start */

$theme-dark-bg: #2d3842;
$theme-light-bg: #f6f8f9;

$theme-color-primary: #3e3e3e;
$theme-color-secondary: #727272;

h1,
h2,
h3 {
  color: #3a3a3b;
}

.blue {
  background-color: #ecf4fa;
}

.theme-text {
  color: #555;
}

.theme-text-blue {
  color: #2f80ed;
}

.color-primary {
  color: $theme-color-primary;
}

.color-secondary {
  color: $theme-color-secondary;
}

.theme-text-primary {
  color: $theme-color-primary;
}

a.theme-text-primary:hover {
  color: #777;
}

.theme-text-secondary {
  color: $theme-color-secondary;
}

.theme-card {
  background-color: white;
}

a.theme-text-secondary:hover {
  color: #999;
}
