.success-modal {
  .modal-content {
    border-radius: 20px;
    padding: 2rem 1.5rem;
  }
  .modal-title {
    font-family: $serif-font-family;
    font-size: 2rem;
    font-weight: 700;
    color: $color-dark;
    margin: 0 auto;
  }
  .modal-header {
    padding: 0;
  }

  .modal-body {
    width: 60%;
    margin: 0 auto;
    text-align: center;
  }
  button {
    margin: 0 auto;
    background-color: $color-dark;
    color: $color-white;
    padding: 0.8rem 1.2rem;
    border-radius: 0.6rem;
    font-size: $font-size-small;
    width: 60%;
    font-weight: 700;
  }
}
