@import '../../styles/mixins';
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;1,300;1,500;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@400;700&display=swap');

$dark: #343434;
$background-sidebar: #f0ece8;
$background: $color-white;

p {
  font-family: $sans-serif-font-family;
  color: $dark;
}

::placeholder {
  font-size: 1rem;
}
.home-subscription-invitation-checkout {
  // display error message

  #payment-message {
    color: $color-alerts;
    padding-top: 1rem;
    font-size: $font-size-smaller;
    font-weight: 500;
  }

  display: flex;
  flex-direction: column;
  min-height: 100%;
  background-color: $background;
  width: 100%;
  overflow: hidden;

  .login_sidebar {
    position: relative;
    background: $color-dark;
    top: 0;
    left: 0;
    min-width: 100%;
    z-index: 1;
    height: 100%;
    padding: 1rem 0rem;

    .logo {
      width: 7rem;
      margin-top: 1rem;
      padding-left: 1rem;
    }

    .login_form {
      width: 100%;
      display: flex;
      flex-direction: column;
      position: absolute;
      bottom: 0;
      .subscription-noble-intro {
        padding: 1.5rem 1rem;

        h3 {
          font-family: $serif-font-family;
          font-weight: bold;
          font-size: 1.626rem;
        }
        p {
          font-size: 1rem;
          font-weight: 300;
          color: $color-white;
        }
        .welcome {
          margin: 0;
          margin-bottom: 0.7rem;
          color: $color-white;
        }
      }

      .form_c {
        display: flex;
        flex-direction: column;
        min-width: 100%;
        background-color: $color-white;
        width: 100%;
        margin: 0 auto;
        padding: 1.5rem 1rem;
        border-radius: 2rem 2rem 0 0;
        padding-bottom: 3rem;
        .terms {
          text-align: center;
          font-weight: 300;
          font-size: 0.7rem;
        }
      }

      .ca {
        font-weight: 600;
        font-size: 1.1rem;
        margin-top: 0.5rem;
      }
      .form-group {
        svg {
          position: absolute;
          margin-top: 0.7rem;
          margin-left: 0.7rem;
          color: $color-green;
        }
      }

      .form-control {
        padding-left: 2.5rem;
      }
      .pre-checkout-price {
        padding-top: 2rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .check-description {
          font-size: $font-size-smallest;
          padding-right: 3rem;
          margin: 0;
        }
        .paying-now {
          display: grid;
          justify-items: flex-end;
          gap: 0.5rem;
          p {
            font-size: $font-size-smallest;
            width: max-content;
            margin: 0;
          }
          h3 {
            font-family: $sans-serif-font-family;
            font-weight: 600;
            font-size: 2rem;
          }
        }
      }

      form {
        background-color: $color-white;
        width: 100%;
        margin: 0 auto;
        padding: 1.5rem 1rem;
        border-radius: 2rem 2rem 0 0;
        .email-checkout {
          background-color: rgba(129, 129, 129, 0.349);
          color: $color-white;
        }
        input {
          width: 100%;
          height: 2.7rem;
          border: 1px solid $color-gray-lighter;
          border-radius: 0.5rem;
        }
        ::placeholder {
          font-weight: 300;
          font-size: 1rem;
          color: #6d786e;
        }
        svg {
          float: left;
          margin-left: 1rem;
          margin-top: 0.875rem;
          position: absolute;
          z-index: 2;
          color: red;
        }
        button {
          width: 100%;
          background-color: $color-dark;
          color: $color-white;
          margin-top: 1rem;
          padding: 0.7rem;
          border-radius: 0.5rem;
          font-family: 'Poppins';
          font-weight: 500;
          min-width: 100%;
          justify-content: center;
        }

        #card-element {
          margin-top: 1rem;
          width: 100%;
          border: 1px solid $color-gray-lighter;
          border-radius: 0.5rem;
          padding: 0.8rem;
          font-size: 1rem;
          font-weight: 300;
          color: #6d786e;
          background-color: $white;
        }
      }
      .aha {
        text-align: center;
        text-decoration: underline;
        font-weight: 500;
        margin-top: 1rem;
        color: $dark;
      }

      .subscription {
        display: flex;
        margin: 0;
        align-items: center;
        justify-content: space-between;
        padding: 1rem 0;

        p {
          font-size: 1rem;
          font-weight: 500;
          font-weight: 300;
          max-width: 50%;
        }

        .price {
          font-weight: 600;
          font-size: $font-size-larger;
        }
      }
      hr {
        height: 0.06rem;
        background-color: #dfdede;
        width: 100%;
      }
      .credit-cards {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 0.5rem;
        p {
          font-size: $font-size-normal;
          font-weight: 500;
        }
        .card-icons {
          display: flex;
          gap: 0.5rem;
          align-items: center;
        }
      }
    }
  }

  .s-content {
    width: 67%;
    background-color: $color-white;
    padding: 2rem;
    border-radius: 0.8rem;
    min-height: 100vh;
    margin-left: 33%;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    display: none;

    .login_content {
      width: 100%;
      display: grid;
      justify-items: center;
      gap: 1rem;

      .illustration {
        width: 60%;
      }
      h1 {
        text-align: center;
        margin: 0;
        font-weight: 700;
        font-size: 1.75rem;
        font-family: $serif-font-family;
        width: 65%;
      }
      p {
        text-align: center;
        max-width: 26rem;
        font-weight: 300;
        font-size: 1rem;
      }
    }
  }
}
.validation {
  color: red;
  padding-top: 1rem;
}

#card-element {
  border-radius: 4px 4px 0 0;
  padding: 12px;
  border: 1px solid rgba(50, 50, 93, 0.1);
  max-height: 44px;
  width: 100%;
  background: white;
  box-sizing: border-box;
}

@include media-breakpoint-up(lg) {
  .home-subscription-invitation-checkout {
    .login_sidebar {
      position: fixed;
      top: 0;
      left: 0;
      min-width: 33%;
      width: 36%;
      z-index: 1;
      height: 100%;
      padding: 1rem 2.31rem 1rem 2.31rem;
      .check-description {
        font-size: $font-size-smallest;
        max-width: 50%;
        margin: 0;
      }
      .login_form {
        width: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
        bottom: 0;
        place-items: center;
        align-items: center;
        min-height: 100%;
        margin-top: 15%;

        .subscription-noble-intro {
          padding: 1.5rem 1rem;
          max-height: max-content;

          h3 {
            font-family: $serif-font-family;
            font-weight: bold;
            font-size: 1.626rem;
            margin: 0;
            margin-bottom: 0.7rem;
            color: $color-white;
          }
          p {
            font-size: 1rem;
            font-weight: 300;
            color: $color-white;
          }
        }

        .form_c {
          display: flex;
          flex-direction: column;
          padding: 0 1rem;
          background-color: transparent;
          .subscription {
            p {
              color: $color-white;
            }
          }
          .credit-cards {
            p {
              color: $color-white;
            }
          }
          form {
            background-color: transparent;
            padding: 0;
            button {
              background-color: $color-white;
              color: $color-dark;
            }
          }
          .pre-checkout-price {
            p,
            h3 {
              color: $color-white;
            }
          }
        }
      }
    }
    .s-content {
      display: grid;
    }
  }
}
