@import '../../../../../styles/mixins';

.support-subscription-radios {
  display: flex;
  align-items: center;
  gap: 2rem;
  grid-template-columns: repeat(2, 1fr);
  grid-column: span 2;
}

.subscriptions-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column: span 2;
  gap: 2rem;
  padding-bottom: 1.5rem;
}

.switch-contract-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: max-content;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  font-size: $font-size-smaller;
  background-color: $color-dark;
  color: $white;
}

.support-subscription-trial-date {
  display: flex;
  flex-direction: column;
  label {
    font-size: $font-size-smaller;
    font-weight: 400;
    color: $color-dark;
    opacity: 0.4;
  }
  input {
    font-size: $font-size-small;
    font-weight: 400;
    border-radius: 0.4rem;
    border: 1px solid $color-light-gray;
    padding: 0.5rem 0.8rem;
    color: $color-gray;
  }
}

.contracts-header {
  grid-column: span 2;
  strong {
    padding-right: 0.5rem;
  }
  width: 100%;
  display: flex;
  padding: 1rem;
  font-size: $font-size-smallest;
  background-color: $color-white;
  border-radius: 0.6rem;
  color: $color-dark;
  opacity: 0.5;
}

.text-message-or-email-wrapper {
  grid-column: span 2;
  display: flex;
  align-items: center;
  gap: 3rem;
}
