.record-measurement-info {
    position: relative;
    cursor: pointer;
    width: 0.675rem;
    height: 0.675rem;
    display: flex;
    align-items: center;
    justify-content: center;
    .record-measurement-info-container {
        position: absolute;
        top: -11rem;
        width: 16.5rem;
        height: 16.625rem;
        display: flex;
        flex-direction: column;
        padding: 1.8125rem;
        background-color: $white;
        box-shadow: 0px 8px 13px rgba(0, 0, 0, 0.2);
        z-index: 999;
        border-radius: 0.25rem;
        h1 {
            font-size: $font-size-normal;
            font-family: $sans-serif-font-family;
            font-weight: 600;
            padding-bottom: 1.5rem;
            border-bottom: 1px solid #d3d0cc;
        }
        .triangle-left {
            position: absolute;
            top: 10.9rem;
            left: -0.5rem;
            width: 0;
            height: 0;
            border-top: 0.5rem solid transparent;
            border-bottom: 0.5rem solid transparent;
            border-right: 0.5rem solid $white;
        }

        .triangle-right {
            position: absolute;
            top: 10.9rem;
            right: -0.5rem;
            width: 0;
            height: 0;
            border-top: 0.5rem solid transparent;
            border-bottom: 0.5rem solid transparent;
            border-left: 0.5rem solid $white;
        }

        .record-measurement-info-container-content {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            justify-content: center;
            gap: 0.5rem;
            margin-top: 1rem;
            .record-measurement-info-container-content-item {
                height: 2rem;
                display: flex;
                width: 100%;
                align-items: center;
                justify-content: space-between;
                .record-measurement-info-container-content-item-title {
                    font-size: $font-size-small;
                    font-weight: 400;
                }
                .record-measurement-info-container-content-item-value {
                    width: 5rem;
                    height: 2rem;
                    border-radius: 0.25rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    p {
                        font-size: 0.75rem;
                        font-weight: 600;
                    }
                }
            }
        }
    }
}
