.audio-player-send {
  .state-label {
    font-size: $font-size-small;
    font-weight: 300;
  }

  .player-container {
    width: 85%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    .bars-and-time-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      .time {
        font-size: $font-size-small;
        margin-right: 0.5rem;
        color: $color-dark;
        opacity: 0.5;
      }
      .right {
        margin-right: 0rem;
        margin-left: 0.5rem;
      }

      .bar {
        width: 3.34px;
        @include media-breakpoint-down(md) {
          width: 2.81px;
        }
      }
    }
  }

  .confirm-delete {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    width: 58%;
    button {
      display: flex;
      width: 100%;
      align-items: center;
      padding: 0.5rem 1rem;
      border-radius: 0.5rem;
      border: 1px solid $color-dark;
      justify-content: center;
    }
    .delete {
      background-color: $color-dark;
      color: $white;
    }
  }
}
