@import '../../styles/mixins';

.common-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9;
  .spinner-border {
    color: $color-dark-text-light;
  }

  &.screen-center {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
  }

  &.local-center {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
  }
}

.dark-loader {
  .spinner-border {
    color: $color-dark;
  }
}

.light-loader {
  .spinner-border {
    color: $white;
  }
}
