@import '../../../../styles/mixins';

.billing-container {
  border-radius: 0.6rem;
  .title {
    font-weight: 500;
  }
  .billing-items {
    display: grid;
    gap: 1.2rem;
    margin-top: 1rem;
    border-radius: 1rem;
    .billing-item {
      display: flex;
      align-content: center;
      justify-content: space-between;
      .billing-item-date {
        p {
          font-weight: 300;
        }
        .date {
          font-size: $font-size-smaller;
        }
      }
      .billing-item-price {
        display: flex;
        align-items: center;
        gap: 0.6rem;
        .price {
          font-weight: 500;
        }
        .status-tag {
          font-size: $font-size-smaller;
          background-color: $color-green;
          color: $white;
          padding: 0.15rem 1rem;
          border-radius: 0.5rem;
        }
        .due {
          background-color: $color-red;
        }
      }
    }
  }
}
