@import '../../styles/mixins';
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;1,300;1,500;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@400;700&display=swap');

$dark: #343434;
$background-sidebar: #f0ece8;
$background: $color-white;

p {
  font-family: $sans-serif-font-family;
  color: $dark;
}

::placeholder {
  font-size: 1rem;
}
.home-subscription-invitation {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  background-color: $background;
  width: 100%;
  overflow: hidden;

  .login_sidebar {
    background-color: $background-sidebar;
    position: absolute;
    top: 0;
    left: 0;
    min-width: 100%;
    z-index: 1;
    height: 100%;
    padding: 1rem 2.31rem 1rem 2.31rem;

    .logo {
      width: 5rem;
      margin-top: 1rem;
    }

    .login_form {
      height: 100%;
      display: grid;
      align-items: center;
      .form_c {
        display: grid;
        justify-content: start;

        .fake_carousel {
          justify-self: center;
          display: inline-flex;
          margin-top: 1.5rem;
          gap: 0.8rem;
        }
        .terms {
          text-align: center;
          font-weight: 300;
          font-size: 0.7rem;
        }
        .welcome {
          margin: 0;
          margin-bottom: 0.7rem;
        }
      }
      h3 {
        font-family: $serif-font-family;
        font-weight: bold;
        font-size: 1.626rem;
      }
      p {
        font-size: 1rem;
        font-weight: 300;
      }

      .ca {
        font-weight: 600;
        font-size: 1.1rem;
        margin-top: 0.5rem;
      }
      .form-group {
        svg {
          position: absolute;
          margin-top: 0.7rem;
          margin-left: 0.7rem;
          color: $primary;
        }
      }

      .form-control {
        padding-left: 2.5rem;
      }

      form {
        margin-top: 0.4rem;
        input {
          width: 100%;
          height: 2.7rem;
          border: 1px solid $color-gray-lighter;
          border-radius: 0.5rem;
        }
        ::placeholder {
          font-weight: 300;
          font-size: 1rem;
          color: #6d786e;
        }
        svg {
          float: left;
          margin-left: 1rem;
          margin-top: -1.85rem;
          position: relative;
          z-index: 2;
          color: red;
        }
        button {
          width: 100%;
          background-color: $dark;
          color: white;
          margin-top: 1rem;
          padding: 0.7rem;
          border-radius: 0.5rem;
          font-family: 'Poppins';
          font-weight: 500;
          min-width: 100%;
          justify-content: center;
        }
      }
      .aha {
        text-align: center;
        text-decoration: underline;
        font-weight: 500;
        margin-top: 1rem;
        color: $dark;
      }

      .subscription {
        display: flex;
        flex-direction: column;
        margin: 0;
        margin-top: 2rem;
        p {
          font-size: 1rem;
          font-weight: 500;
        }
        .subscription-plan {
          .tag {
            background-color: $color-green;
            color: white;
            max-width: min-content;
            padding: 0.2rem;
            margin: 0;
            margin-bottom: 0.5rem;
            border-radius: 0.5rem;
            font-size: $font-size-smallest;
            font-weight: 500;
          }
          .price {
            font-size: $font-size-small;
          }
          padding: 0.2rem 0.5rem 0 0.5rem;
          display: flex;
          align-items: flex-end;
          width: 100%;
          justify-content: space-between;
        }
      }
    }
  }

  .content {
    width: 67%;
    background-color: $color-white;
    padding: 2rem;
    border-radius: 0.8rem;
    min-height: 100vh;
    margin-left: 33%;
    display: grid;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    .login_content {
      width: 100%;
      display: grid;
      justify-items: center;
      gap: 1rem;
      .woman {
        width: 24rem;
      }
      h1 {
        text-align: center;
        margin: 0;
        font-weight: 500;
        font-size: 2rem;
      }
      p {
        text-align: center;
        max-width: 26rem;
        font-weight: 300;
      }
    }
  }
}
.validation {
  color: red;
  padding-top: 1rem;
}

@include media-breakpoint-up(lg) {
  .home-subscription-invitation {
    .login_sidebar {
      background-color: $background-sidebar;
      position: fixed;
      top: 0;
      left: 0;
      min-width: 33%;
      width: 33%;
      z-index: 1;
      height: 100%;
      padding: 1rem 2.31rem 1rem 2.31rem;
    }
  }

  .content {
    visibility: visible;
  }
}
