.rpm-coach-dashboard {
    .rpm-coach-container-layout {
        .rpm-coach-container {
            .rpm-provider-columns {
                display: flex;
                flex-direction: row;
                width: 100%;
                justify-content: space-between;
                padding: 0.55rem;
                position: relative;
                border-bottom: 1px solid #c4c4c4;
                p {
                    font-size: 0.75rem;
                    font-weight: 500;
                    color: #595959;
                }
                .rpm-provider-columns {
                    max-width: 15.2125rem;
                }
                .rpm-provider-columns-statuses {
                    position: absolute;
                    left: 59%;
                    transform: translateX(-59%);
                    display: grid;
                    grid-template-columns: 1fr 1fr 1fr;
                    grid-gap: 2.5rem;
                }
                .rpm-provider-columns-alerts-and-chat {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: flex-end;
                    gap: 1.5rem;

                    .rpm-provider-columns-alerts {
                        padding-right: 1rem;
                    }
                    .rpm-provider-columns-chat {
                        opacity: 0;
                    }
                }
            }
            .rpm-provider-records {
                display: flex;
                flex-direction: column;
                width: 100%;
                align-items: center;
                justify-content: flex-start;
                max-height: 100vh;
                overflow-y: auto;
            }

            .rpm-provider-record {
                display: flex;
                flex-direction: column;
                width: 100%;
                background-color: $white;
                border-radius: 0.625rem;
                margin-top: 1rem;
                box-shadow: 0px -4px 20px 1px rgba(0, 0, 0, 0.05);

                .rpm-provider-record-header {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    height: 4.0625rem;
                    padding: 1rem;
                    position: relative;

                    .rpm-provider-record-header-status-container {
                        position: absolute;
                        left: 60%;
                        transform: translateX(-60%);
                        display: grid;
                        grid-template-columns: 1fr 1fr 1fr;
                        grid-gap: 2rem;
                        .rpm-provider-record-header-status {
                            display: flex;
                            align-items: center;
                            justify-content: flex-start;
                            gap: 0.5rem;
                            min-width: max-content;
                            .rpm-provider-record-header-status-dot {
                                min-width: 0.25rem;
                                width: max-content;
                                height: 0.25rem;
                                border-radius: 50%;
                                aspect-ratio: 1/1;
                                background-color: rgb(164, 162, 161);
                            }

                            .rpm-provider-record-header-status-dot.eligible {
                                background-color: #718371;
                            }

                            .rpm-provider-record-header-status-dot.ineligible {
                                background-color: #e8744f;
                            }

                            .rpm-provider-record-header-status-text {
                                font-size: 0.625rem;
                                font-weight: 500;
                                color: rgb(164, 162, 161);
                            }

                            .rpm-provider-record-header-status-text.eligible {
                                color: #718371;
                            }

                            .rpm-provider-record-header-status-text.ineligible {
                                color: #e8744f;
                            }
                        }
                        .with-border {
                            border: 1px solid rgb(164, 162, 161);
                            border-radius: 7.8125rem;
                            padding: 0.45rem 0.625rem;
                        }

                        .with-border.eligible {
                            border: 1px solid #718371;
                        }

                        .with-border.ineligible {
                            border: 1px solid #e8744f;
                        }
                    }
                    .rpm-provider-record-header-user {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        gap: 0.5rem;
                        max-width: 15.2125rem;
                        cursor: pointer;
                        .rpm-provider-record-header-user-avatar {
                            position: relative;
                            width: 2.5rem;
                            height: 2.5rem;
                            aspect-ratio: 1/1;

                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                                box-sizing: border-box;
                            }

                            .rpm-provider-record-header-user-avatar-text {
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                transform: translate(-50%, -50%);
                                font-size: 0.75rem;
                                font-weight: 500;
                                color: $white;
                            }
                        }
                        .rpm-provider-record-header-user-text {
                            font-size: $font-size-normal;
                            color: $color-dark;
                            font-weight: 500;
                            width: 75%;
                            // 2 lines max, then ellipsis
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;
                        }
                    }

                    .rpm-alerts-wrapper {
                        display: flex;
                        align-items: center;
                        flex-direction: row;
                        gap: 1.5rem;
                        justify-content: flex-end;
                        button {
                            outline: none;
                        }
                        &__resend-invitation {
                            font-size: 0.75rem;
                            font-weight: 400;
                            color: #000000;
                            text-decoration: underline;
                            margin-right: -0.25rem;
                        }
                    }
                }

                .rpm-provider-record-body {
                    height: 6.75rem;
                    border-top: 1px solid #d6d6d6;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .rpm-provider-record-body-grid {
                        width: 90%;
                        display: flex;
                        align-items: flex-start;
                        justify-content: space-between;
                        .rpm-provider-record-body-left,
                        .rpm-provider-record-body-right {
                            display: flex;
                            align-items: center;
                            gap: 2.5rem;
                            .rpm-provider-record-body-left-item {
                                display: flex;
                                flex-direction: column;
                                align-items: flex-start;
                                gap: 0.35rem;
                                .rpm-provider-record-body-left-item-title {
                                    font-size: 0.625rem;
                                    font-weight: 600;
                                    color: $color-dark;
                                }
                                .rpm-provider-record-body-left-item-text {
                                    font-size: 0.625rem;
                                    font-weight: 600;
                                    color: $color-dark;
                                    padding: 0.1rem 0.25rem;
                                    border-radius: 0.25rem;
                                }

                                .tag {
                                    padding: 1px 4px;
                                    border-radius: 4px;
                                    color: #e8744f;
                                    font-weight: 600;
                                    background: #f9dad0;
                                }
                            }
                        }
                    }
                }

                .rpm-provider-record-footer {
                    background-color: #f8f5f5;
                    width: 90%;
                    margin: 0 auto;
                    margin-bottom: 1rem;
                    height: 2rem;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;
                    padding: 0 1rem;
                    border-radius: 0.25rem;

                    p {
                        font-size: 0.625rem;
                        font-weight: 400;
                        color: #595959;
                    }
                    strong {
                        font-size: 0.625rem;
                        font-weight: 600;
                        color: #555555;
                    }

                    .rpm-provider-record-footer-progress {
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                        gap: 0.5rem;
                        .rpm-provider-record-footer-progress-bar {
                            width: 5.5rem;
                            height: 0.375rem;
                            background-color: #e5e5e5;
                            border-radius: 4rem;
                            position: relative;
                            .rpm-provider-record-footer-progress-bar-fill {
                                position: absolute;
                                top: 0;
                                left: 0;
                                height: 100%;
                                background-color: #88a088;
                                border-radius: 4rem;
                            }
                        }
                        p {
                            font-weight: 600;
                            color: #555555;
                        }
                    }
                }
            }
        }
    }
}

.rpm-modal {
    .modal-content {
        display: flex;
        flex-direction: column;
        gap: 0.875rem;
        margin-top: calc(50vh - 6.5rem);
        min-height: 14.375rem;
        width: 30.56rem;

        @include media-breakpoint-down(sm) {
            width: 90vw;
            margin: auto;
            margin-top: 5rem;
        }
        align-items: center;
        justify-content: center;
        padding: 4.5rem 3rem;
        h3 {
            font-size: 1rem;
            font-weight: 600;
            color: $color-dark;
            text-align: center;
        }

        p {
            font-weight: 300;
            font-size: 0.875rem;
            text-align: center;
            color: $color-dark;
        }

        .tip {
            font-size: 0.75rem;
            font-weight: 600;
            text-align: center;
        }

        button {
            position: absolute;
            top: 0.875rem;
            right: 1rem;
        }
    }
}

.container-skeleton {
    animation: container-skeleton-loading 1s linear infinite alternate;
}

@keyframes container-skeleton-loading {
    0% {
        background-color: $color-white;
    }
    100% {
        background-color: $color-white;
    }
}

.skeleton-user-card {
    height: 8.375rem;
    width: 100%;
    border-radius: 0.625rem;
    background-color: $white;
    position: relative;

    &__avatar {
        @extend .container-skeleton;

        width: 40px;
        height: 40px;
        border-radius: 0.25rem;
        border-bottom-right-radius: 0;
        background-color: $color-white;
        position: absolute;
        top: 0.6875rem;
        left: 0.9375rem;
    }

    &__user-info {
        @extend .container-skeleton;
        width: 148px;
        height: 10px;
        left: 4.25rem;
        top: 1.6875rem;
        border-radius: 0.25rem;
        position: absolute;
    }

    &__alerts-circle {
        @extend .container-skeleton;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        position: absolute;
        top: 20px;
        right: 36px;
    }

    &__line {
        @extend .container-skeleton;
        width: 100%;
        height: 1px;
        position: absolute;
        top: 4.21875rem;
    }

    &__info {
        display: flex;
        flex-direction: column;
        gap: 10px;
        top: 5.25rem;
        left: 1.14rem;
        position: absolute;
        &__top {
            @extend .container-skeleton;
            border-radius: 0.25rem;
            width: 74px;
            height: 6px;
        }

        &__bottom {
            @extend .container-skeleton;
            width: 21px;
            height: 10px;
            border-radius: 0.25rem;
        }
    }
}
