.rpm-client-profile {
    &__container {
        &__right-body {
            background-color: $color-white;
            width: 100%;
            height: 100%;
            border-radius: 0.625rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 1rem;
            max-height: 62.675rem;
            overflow-y: auto;
            @include media-breakpoint-down(sm) {
                margin-bottom: 5rem;
                width: calc(100vw - 4rem);
                height: 44.375rem;
                max-height: 44.375rem;
            }

            &__measurements {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 0.5rem;
                width: 100%;
                margin-top: 1rem;
                flex-wrap: wrap;
                &__measurement {
                    cursor: pointer;
                    padding: 0.5rem 1.3rem;
                    border-radius: 7.8125rem;
                    p {
                        font-size: 0.75rem;
                    }
                }
                @include media-breakpoint-down(sm) {
                    &__measurement {
                        padding: 0.5rem 0.725rem;
                    }
                }
            }
           
        }
    }
}
