.cancel-subscription-modal {
  .modal-header {
    margin: 0 auto;
    padding-top: 2.3rem;
    max-width: 80%;
  }
  .modal-body {
    display: flex;
    flex-direction: column;
    padding-bottom: 2.8rem;
    max-width: 80%;
    margin: 0 auto;
    p {
      text-align: center;
      font-weight: 300;
    }
    .are-you-sure {
      display: grid;
      justify-items: center;
      align-items: center;
      padding: 1rem;
      gap: 1rem;
      background-color: rgba(196, 114, 88, 0.3);
      border-radius: 0.5rem;
      margin-top: 2.3rem;
      p {
        color: $color-red;
      }
    }
    button {
      margin: 0 auto;
    }
    .cancel {
      color: $color-white;
      background-color: $color-dark;
      border-radius: 0.4rem;
      padding: 1rem;
      margin-top: 2.3rem;
    }
    .go-back {
      text-decoration: underline;
      margin-top: 1rem;
    }
  }
}
