@import '../../../../../styles/mixins';

.support-assign-coach-form {
  grid-column: span 2;
  .error {
    color: $color-red;
    font-size: $font-size-smallest;
    margin-top: 0.5rem;
  }
}
