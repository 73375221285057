.cancel-client-subscription-modal {
  button {
    outline: none;
  }
  .modal-header {
    padding: 2rem 2rem 0 2rem;
  }
  .modal-title {
    font-size: $font-size-largest;
    font-family: $serif-font-family;
    font-weight: 700;
  }

  .modal-body {
    padding: 2rem 2rem;
    p {
      font-weight: 300;
      text-align: center;
    }
  }
  .cancel {
    background-color: $color-dark;
    color: $color-white;
    border-radius: 0.6rem;
    padding: 0.7rem 1.5rem;
    max-width: max-content;
    margin: 0 auto;
  }
  .go-back {
    margin: 0 auto;
    font-weight: 300;
    font-size: $font-size-small;
    text-decoration: underline;
    margin-top: 1rem;
    padding-bottom: 2rem;
  }
}
