@import '../../styles/mixins';

.roadmap-competency-page {
  background-color: $color-bg-white;
  position: relative;
  padding: 1rem;
  @include media-breakpoint-down(sm) {
    padding: 0rem;
    padding-top: 1rem;
  }
  button {
    outline: none;
  }
  width: 100%;
  overflow-x: hidden;
  .magic-grid {
    display: flex;
    flex-direction: column;
    width: 91%;
    margin: 0 auto;
  }

  .noRightColumnStyles {
    width: 100% !important;
    margin: auto;
    @include media-breakpoint-up(md) {
      width: 100% !important;
    }
  }

  .assessments-and-discuss-wrapper {
    display: grid;
    grid-template-rows: 1fr;
    height: 100%;
    background-color: $color-white;
    border-radius: 0.6rem;
    @include media-breakpoint-down(sm) {
      background-color: transparent;
    }
  }

  .assessment-and-action-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 1rem;
    padding: 1rem;

    @include media-breakpoint-down(sm) {
      padding: 0rem;
      padding-bottom: 4rem;
    }
    .title-header {
      display: flex;
      align-items: center;
      padding-bottom: 1.7rem;
      gap: 1.5rem;
      h1 {
        color: $color-dark;
        font-family: $serif-font-family;
        text-align: left;
      }
    }
  }
  .journal-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 1rem;

    a {
      color: #6e95a9;
    }
  }

  .embed {
    display: flex;
    flex-direction: column;
    figure {
      flex-grow: 1;

      & > div {
        flex-grow: 1;
      }
    }
  }

  .btn-option {
    background-color: transparent;
    box-shadow: none;
    min-height: 27px;
    padding: 0px;
    color: $color-text-normal;
  }

  .common-header {
    h1 {
      font-size: get-scaled-size($scale-factor-1);
    }
    .third-column {
      width: 48px;
      svg {
        position: relative;
        right: 5px;
      }
    }
  }

  h2 {
    display: inline-block;
    @extend .mrm-mr-0_25;
    @extend .mrm-my-1;
    font-weight: 500;
  }

  .competency-comment {
    @extend .mrm-mb-1;

    .dot {
      position: relative;
      top: 21px;
    }

    .initials {
      @extend .mrm-mt-0_5;

      span {
        color: white;
      }
    }

    .user-name {
      @extend .mrm-ml-0_25;

      font-size: $font-size-small;
      font-weight: bold;
      color: $color-text-normal;

      & ~ span {
        @extend .mrm-ml-0_5;

        font-size: $font-size-smaller;
      }
    }

    .card {
      @extend .mrm-p-0_75;
      @extend .mrm-mt-0_25;
      font-size: $font-size-small;
      color: $color-text-normal;
      @include media-breakpoint-up(lg) {
        background: $color-background-light-gray;
      }
    }
    /*  .mine {
      @include media-breakpoint-up(lg) {
        background: $color-item-lightpurple;
        box-shadow: none;
      }
    } */
  }

  .comment-list {
    @extend .mrm-mb-2;
    position: static;

    .comment-form {
      position: fixed;
      bottom: 3.4rem;
      z-index: 1040;
      left: 0;
      right: 0;
      @extend .mrm-p-1;
      border-top: 1px solid #cccccc;

      .form-control {
        &:focus {
          border: 1px solid #eeeeee;
        }
      }

      .form-group {
        margin-bottom: 0;
      }

      &.focused-input {
        position: absolute;
        bottom: 0;
      }
    }

    .competency-comment:last-of-type {
      padding-bottom: 3rem;
    }

    .delete-comment {
      display: flex;
      align-items: center;
      justify-content: center;

      .delete-comment-message-icon {
        margin-top: get-scaled-size($scale-factor-1_5);
      }
    }
  }

  .attachment-button {
    height: auto;
    @extend .mrm-mt-0_5;
  }

  .prev-next-btn-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    padding-top: 1rem;
    @include media-breakpoint-up(lg) {
      flex-direction: row;
      button {
        max-width: max-content;
      }
    }
    justify-content: space-between;
    button {
      background-color: $color-dark;
      color: white;
      font-weight: 500;
      width: 100%;
      svg {
        position: relative;
        top: 2px;
      }
    }
  }

  @media print {
    .tab-selector {
      display: none;
    }

    .action-item-page-attachment-list ~ button.btn {
      display: none;
    }

    .common-header-container {
      .static-placeholder {
        visibility: visible;
      }
      .fixed-actual-header {
        display: none;
      }
    }

    .common-header {
      .col-auto:first-child,
      .col-auto:last-child {
        display: none;
      }
    }
  }

  .desktop-header-container {
    @extend .mrm-box-shadow;
    @extend .mrm-pt-1;
    border-radius: 0;

    .back-button-container {
      float: left;
      height: get-scaled-size($scale-factor_0_25);
    }

    h2 {
      @extend .mrm-mt-0_5;
      @extend .mrm-mb-0_25;
      display: block;

      small {
        @extend .mrm-ml-0_25;
        color: $color-text-light;
      }
    }
    .tab-selector {
      background: transparent;
      min-height: unset;
      margin-bottom: 0;

      &::after {
        border-bottom: none;
      }

      & > .col {
        flex-grow: 1;
        max-width: 50%;
        transform: translate(50%, 0);
      }

      .nav-link {
        @extend .mrm-pb-0_75;
        font-size: $font-size-normal;

        &.active {
          color: $color-item-blue;
        }
      }

      .slider {
        background-color: $color-item-blue;
      }
    }
  }
}

.desktop-page-container {
  .add-another-action-item {
    @extend .mrm-p-1;
    display: block;
    color: $color-text-light;
  }
  .add-attachment,
  .add-action-item {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $color-dark;
    font-weight: 500;
    gap: 0.5rem;
    font-size: $font-size-small;
    border: 1px solid $color-dark;
    border-radius: 0.8rem;
    padding: 1rem;
    max-width: 80%;
    margin: 0 auto;
  }
}
.add-note {
  @extend .mrm-py-1;
  display: block;
  color: $color-text-light;
}

.roadmap-competency-page-competency-more-tab {
  img {
    max-width: calc(100vw - 2rem);
    height: auto !important;
  }

  .print-header {
    text-align: center;
    font-weight: bold;
    display: none;
  }

  @media print {
    .print-header {
      display: block;
    }
  }
}

@include media-breakpoint-up(lg) {
  .roadmap-competency-page {
    padding: 2.5rem 3.5rem;

    .action-item-container,
    .questions-section,
    .btn-action-item,
    .action-item-page-attachment-list ~ button,
    .btn-white,
    .assessment {
      @include mrm-white-on-white-vivid-box-shadow;
    }
    .assessment-and-action-container,
    .journal-container {
      background-color: $color-white;
      padding: 2.5rem;
    }
  }
  .container.primary-content {
    background: #fff;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    @include mrm-main-box-shadow;
  }
  .description-container {
    font-size: $font-size-normal;
    img {
      max-width: 100%;
    }
  }
}

.roadmap-competency-page .prev-next-btn-container button svg {
  font-size: $font-size-normal;
}

.label-p {
  font-weight: 400;
}

.bold-label {
  font-weight: 600;
}

.assessments-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 1rem;
  margin-top: 2rem;
  min-height: 57vh;
  max-height: 57vh;
  p {
    text-align: center;
  }
  .coming-soon {
    font-size: $font-size-smaller;
    font-weight: 600;
    color: $color-text-light;
  }

  .title {
    margin-top: -1rem;
    font-family: $serif-font-family;
    font-weight: 700;
  }

  .description {
    font-weight: 300;
    @include media-breakpoint-up(md) {
      max-width: 50%;
    }
  }
}

.action-item-content {
  width: 100%;
  word-break: break-word;
  .action-item-textarea {
    width: 100%;
    padding: 1rem;
    font-size: $font-size-small;
    @include media-breakpoint-down(sm) {
      font-size: $font-size-normal;
    }
  }
}

.action-item-response {
  display: flex;
  width: 90%;
  margin: 0 auto;
  flex-direction: column;
  gap: 1rem;

  .action-item-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    .image-and-name {
      display: flex;
      align-items: center;
      img {
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 0.6rem 0.6rem 0 0.6rem;
        margin-right: 1rem;
        filter: drop-shadow(2px 3px 5px rgba(0, 0, 0, 0.25));
      }
      .name-and-time {
        display: flex;
        flex-direction: column;
        .name-text {
          font-size: $font-size-small;
        }
        .date-text {
          font-size: $font-size-smallest;
          color: $color-dark;
          opacity: 0.4;
        }
      }
    }
    .edit {
      font-size: $font-size-smallest;
      font-weight: 600;
      cursor: pointer;
      // on hover
      &:hover {
        color: $color-dark;
        opacity: 0.4;
      }
    }
  }
  .action-item-content {
    p {
      white-space: pre-line;
      font-weight: 300;
      font-size: $font-size-smaller;
    }
  }
}

.form-and-action-items {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  max-height: max-content;
  width: 100%;
}
