@import '../../styles/mixins';

.support-form-container {
    width: 48rem;
    background-color: $white;
    border-radius: 0.6rem;
    padding: 0 2.75rem 1.5rem 2.75rem;
    display: flex;
    flex-direction: column;
    overflow-y: auto;

    form {
        padding-top: 2rem;
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 1rem;
        column-gap: 2.5rem;
        align-items: flex-start;
        .support-submit-form-btn {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding: 0.7rem;
            background-color: $color-dark;
            border-radius: 0.6rem;
            max-width: max-content;
            color: $color-white;
            font-size: $font-size-small;
            justify-self: end;
            grid-column: span 2;
        }

        .error {
            color: $color-red;
            font-size: $font-size-smallest;
            margin-top: 0.5rem;
        }
    }

    .subform-header {
        // take 2 columns
        grid-column: span 2;
        display: flex;
        font-size: 0.875rem;
        font-weight: 500;
    }
}
