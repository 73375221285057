.assign-roadmap-modal {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  height: 35rem;
  overflow-y: scroll;
}

.rdmps-header-modal {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 1rem;
  padding-top: 2rem;
}
