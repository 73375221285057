@import '../../styles/mixins';

.dashboard-onboarding-invitation-page {
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 99999;
  width: 100%;
  min-height: 100vh;
  overflow: scroll;
  background-color: $color-bg-white;
}
