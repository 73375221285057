.dashboard-coach-add-calls-page {
  padding-top: 3rem;
  .add-btn {
    font-weight: 300;
    font-family: $sans-serif-font-family;
  }
  .cancel {
    padding-left: 0.5rem;
  }
  .form-label {
    font-size: $font-size-small;
    color: $color-gray;
    padding-bottom: 0.5rem;
  }
  input[type='text'],
  .theme-dark input[type='text'],
  .form-control {
    border-radius: 0.5rem;
    border: 1px solid $color-gray-lighter;
    height: 3.5rem;
    display: flex;
    align-items: center;
    padding: 0;
    padding-left: 1rem;
  }
  .date-picker-cal {
    display: flex;
    gap: 0.5rem;
    align-items: center;
  }
}
