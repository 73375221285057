@import '../../styles/mixins';

.support-sidebar {
  width: 15.75rem;
  border-radius: 0.6rem;
  display: flex;
  flex-direction: column;
  height: 80vh;
  background-color: $color-dark;
  padding: 1.5rem 1.7rem;
  position: sticky;
  top: 10vh;

  .support-sidebar-header {
    p {
      color: $color-white;
      font-size: $font-size-small;
    }
    h1 {
      text-align: left;
      color: $color-white;
      font-size: $font-size-largest;
    }
  }
  .support-sidebar-body {
    padding-top: 1.5rem;
    .support-sidebar-body-steps {
      display: flex;
      flex-direction: column;
      gap: 1.75rem;
    }
  }

  .resend-sub-btn {
    padding: 0.5rem 1rem;
    background-color: $white;
    border-radius: 0.4rem;
    margin-top: 2rem;
    max-width: max-content;
    p {
      color: $color-dark;
      font-size: $font-size-smaller;
    }
  }
}
