@import '../../../../../styles/mixins.scss';

.rpm-signup-mobile {
    position: relative;
    height: 100vh;
    .background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.8);
    }
    .content {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;

        #rpm-logo {
            width: 3rem;
            height: 3rem;
            object-fit: contain;
            margin-left: 1rem;
            margin-bottom: 1rem;
        }

        .title {
            font-size: 2rem;
            font-weight: 700;
            color: $white;
            text-align: left;
            font-family: $serif-font-family;
            width: 100%;
            padding: 0 1rem;
            margin-bottom: 1rem;
        }

        .subtitle {
            color: $white;
            font-weight: 300;
            margin-bottom: 2rem;
            width: 100%;
            text-align: left;
            padding: 0 1rem;
        }

        .form {
            background: $color-white;
            border-top-left-radius: 2rem;
            border-top-right-radius: 2rem;
            height: 55vh;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            padding: 2.5rem 1.125rem 2rem 1.125rem;
            width: 100%;

            .address-container {
                width: 100%;
                background: $white;
                height: 9.9375rem;
                border-radius: 0.625rem;
                position: relative;
                padding: 2rem;
                display: flex;
                flex-direction: column;
                justify-content: center;
                p {
                    color: $color-dark;
                }
                .address-title {
                    font-weight: 600;
                    font-size: 1.125rem;
                    margin-bottom: 0.5rem;
                }

                .address {
                    font-weight: 300;
                    font-size: 1rem;
                    color: #6d786e;
                }

                button {
                    position: absolute;
                    top: 1.5rem;
                    right: 2rem;
                    background: transparent;
                    border: none;
                    cursor: pointer;
                    height: 1.5rem;
                    width: 1.5rem;
                    object-fit: contain;
                    text-decoration: underline;
                    font-size: 500;
                }
            }

            .form-title {
                font-size: 1.125rem;
                font-weight: 500;
                font-family: $sans-serif-font-family;
                color: $color-dark;
                margin-bottom: 1rem;
                width: 100%;
                text-align: left;
            }

            .inputs-wrapper {
                width: 100%;
                display: flex;
                flex-direction: column;
                gap: 0.5rem;
                align-items: center;
                justify-content: flex-start;
                height: 100%;
                overflow-y: auto;
            }

            .input-wrapper {
                position: relative;
                width: 100%;
                margin-bottom: 1rem;
                input {
                    width: 100%;
                    &::placeholder {
                        font-weight: 300;
                        color: $color-dark-green;
                    }
                    border-color: #f2f1ec;
                    border-radius: 10px;
                    outline: none;
                }
                .lock {
                    position: absolute;
                    top: 0.7rem;
                    left: 0.8rem;
                    height: 1.5rem;
                    object-fit: contain;
                }
                .react-datepicker-wrapper {
                    width: 100%;

                    .btn-due-date {
                        display: flex;
                        align-items: center;
                        position: relative;
                        width: 100%;
                        background: $white;
                        border: 1px solid #f2f1ec;
                        border-radius: 0.625rem;
                        height: 2.8125rem;
                        outline: none;
                        p {
                            font-size: 1rem;
                            font-weight: 300;
                            color: #6d786e;
                            margin-left: 1rem;
                        }
                        svg {
                            position: absolute;
                            right: 1rem;
                            top: 0.75rem;
                        }
                    }
                }
            }

            .no-icon {
                margin-bottom: 0;
                .simple-input {
                    padding-left: 1rem;
                }
            }

            .genders-grid {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                grid-template-rows: 1fr 1fr;
                gap: 1rem;
                width: 100%;

                button {
                    border-radius: 0.625rem;
                    border: 1px solid #9c9a9a;
                    width: 100%;
                    background-color: transparent;
                    height: 2.8125rem;
                    color: #656565;
                    font-weight: 300;
                    outline: none;
                }

                .prefer-not-to-say {
                    grid-column: 1 / 3;
                }

                .selected {
                    background-color: $color-dark;
                    color: $white;
                    font-weight: 600;
                }

                .next-button {
                    background-color: $white;
                    color: $color-dark;
                }
            }

            .error-message {
                color: $color-alerts;
                font-size: $font-size-smaller;
                font-weight: 500;
            }
            .submit-button {
                width: 100%;
                margin-top: 1rem;
                font-size: 1rem;
                font-weight: 500;
                font-family: $sans-serif-font-family;
                color: $color-white;
                background: $color-dark;
                border-radius: 10px;
                padding: 0.5rem 0;
                height: 50px;
            }

            .terms-and-conditions {
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                gap: 1rem;
                width: 100%;
                &__text-wrapper {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 0.5rem;
                    margin-top: 0.3rem;
                    &__text {
                        font-size: 0.75rem;
                        font-weight: 600;
                        color: $color-dark;
                        cursor: pointer;
                    }
                    ul {
                        padding-left: 1rem;
                        li {
                            font-size: 0.75rem;
                            font-weight: 400;
                            color: $color-dark;
                        }
                        a {
                            cursor: pointer;
                            text-decoration: underline;
                            color: $color-dark;
                        }
                    }
                }
            }

            .password-requirements {
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-template-rows: auto;
                gap: 0.25rem;
                width: 100%;
                margin: 0;
                margin-bottom: 2rem;
                .title {
                    grid-column: 1 / 3;
                    font-size: 0.75rem;
                    font-weight: 600;
                    margin-bottom: 0.25rem;
                    color: $color-dark;
                    font-family: $sans-serif-font-family;
                    padding: 0;
                }
                &__item {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 0.5rem;
                    width: 100%;
                    &__icon {
                        object-fit: contain;
                        font-size: 0.75rem;
                        color: #e8744f;
                    }
                    &__text {
                        font-size: 0.75rem;
                        font-weight: 400;
                        color: $color-dark;
                        white-space: nowrap;
                    }
                }
            }
        }
    }
    .center {
        align-items: center;
        .title {
            text-align: center;
            margin-bottom: 2.8125rem;
        }
    }
}

.react-tel-input {
    .flag-dropdown {
        background: $white;
        border: none;
        border-radius: 0.525rem;
        .selected-flag {
            background: transparent;
            padding-left: 1rem;
        }
    }
    .country-list {
        box-shadow: 0px 4px 20px 1px rgba(0, 0, 0, 0.1);
        border-radius: 0.525rem;
        li {
            font-weight: 300;
        }
    }
}
