.fixed-bottom {
  overscroll-behavior: contain;
}

.mobile-first-response {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: $white;
  padding: 1.5rem 7.5%;
  border-radius: 40px 40px 0 0;
  height: 100%;

  .mobile-first-response-header {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 45px;
    .response-label {
      width: 100%;
      font-size: 14px;
      font-weight: 600;
      position: absolute;
      left: 0;
    }
  }

  .mobile-all-responses-container {
    height: 75vh;
    width: 100%;
    padding: 1rem 0;
    // hide scrollbar
    &::-webkit-scrollbar {
      display: none;
    }
    .mobile-send-action-item-response-container {
      display: flex;
      flex-direction: row;
      width: 100%;
      align-items: flex-start;
      position: absolute;
      bottom: 0;
      left: 0;
      background-color: $white;
      padding: 1.5rem 1rem;
      .profile-pic-container {
        height: 45px;
        width: 45px;
        border-radius: 50%;
        margin-right: 0.5rem;
        .profile-pic {
          height: 36px;
          width: 36px;
          border-radius: 50%;
          object-fit: cover;
        }
      }
    }
    .mobile-send-action-item-response {
      display: flex;
      flex-direction: column;
      width: 100%;

      @include media-breakpoint-down(sm) {
        padding-top: 0;
      }

      textarea {
        position: relative;
        width: 100%;
        border-radius: 10px;
        height: 36px;
        max-height: 20vh;
        overflow-y: auto;
        padding-left: 1rem;
        padding-top: 0.8rem;
        font-size: $font-size-small;
        background-color: #f1f1f3;
        border: none;
        padding-right: 3rem;
        font-weight: 300;

        @include media-breakpoint-down(sm) {
          font-size: $font-size-normal;
        }

        &::placeholder {
          color: #848389;
          font-size: $font-size-small;
        }

        &:focus-visible {
          outline: none;
        }
      }

      .action-item-response-button {
        position: absolute;
        right: 0;
        margin-top: 0.45rem;
        margin-right: 2rem;
      }
    }
  }

  .height-action-item-start {
    max-width: 100%;
    width: 100%;
    height: max-content;
    max-height: max-content;
    margin: 0 auto;
    .action-item-response {
      margin: 0;
    }
    .action-item-header {
      background-color: $white;
    }
  }

  .comment-btn-action-item-container {
    display: flex;
    align-items: center;
    flex-direction: row;
    width: 100%;
    margin: 0 auto;
    margin-left: -6px;
    p {
      font-size: 12px;
      font-weight: 600;
      color: '#5D5D5D';
    }
  }

  .comments-length {
    font-size: 14px;
    font-weight: 600;
    text-align: left;
    width: 100%;
    margin: 1rem auto;
    color: '#5D5D5D';
  }

  .discuss-container {
    width: 100%;
    .btn-send-message {
      margin-left: 0;
    }
    .first-textarea-message {
      height: 300px;
      font-size: 14px;
    }
  }

  .form-and-action-items {
    height: auto;
    max-height: min-content;
    .action-item-responses {
      height: 100%;
      padding-bottom: 2rem;
      overflow-x: hidden;
      .left-border {
        margin-left: 0;
        margin-bottom: 1rem;
      }
    }
  }
}
