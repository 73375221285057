@import '../../../styles/mixins';

.subscriptions-internal-subscriptions-main-content {
  grid-area: content;
  background-color: var(--primary-white);
  padding: 2rem;
  border-radius: 0.8rem;

  & table {
    width: 100%;
    background-color: white;
    box-shadow: 1px 1px 20px 1px rgba(0, 0, 0, 0.05);
    border-radius: 0.6rem;
    margin-top: 2rem;
    display: table;
    td {
      vertical-align: center;
      padding: 1.5rem 1.5rem;
    }
    th {
      padding: 1rem 0rem 1rem 1.5rem;
      font-weight: 300;
      font-size: 0.8rem;
      color: rgba(52, 52, 52, 0.5);
    }

    tr {
      border-bottom: 1px solid rgba(52, 52, 52, 0.1);
    }
    tbody {
      font-size: 0.87rem;
      font-weight: 300;
    }
  }

  & .main-header {
    display: flex;
    justify-content: space-between;
    button {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.6rem;
      grid-gap: 0.5rem;
      gap: 0.5rem;
      background-color: var(--primary-dark);
      color: var(--pure-white);
      font-size: 0.7rem;
      border-radius: 0.4rem;
    }
  }

  tbody {
    height: calc(90vh - 310px); // Your prefered height here
    table-layout: fixed;
    display: block;
    overflow-y: auto;
    overflow-x: hidden;
  }

  thead,
  tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }
}
