@import '../../../../styles/mixins';

.profile-picture-container {
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
  align-items: flex-end;

  .profile-picture-info {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    .name {
      font-size: $font-size-normal;
    }
  }
  button {
    p {
      text-decoration: underline;
      font-size: $font-size-smallest;
    }
    outline: none;
  }

  .tag {
    font-size: $font-size-smallest;
    margin: 0;
    background-color: $color-light-green;
    display: flex;
    justify-content: center;
    max-width: max-content;
    padding: 0.15rem 1rem;
    color: $color-green;
    border-radius: 1rem;
  }

  .inactive {
    background-color: #e3c7bd;
    color: $color-red;
  }
}
