@import '../../styles/mixins';
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;1,300;1,500;1,600;1,700&display=swap');

// colors
$background: #f0ece8;
$dark: #343434;

// default for all text
p {
  font-family: $sans-serif-font-family;
  color: $dark;
}

.sidebar {
  position: sticky;
  display: flex;
  flex-direction: column;
  top: 5vh;
  left: 0;
  height: 80vh;
  width: 15.75rem;
  justify-items: flex-start;

  .s-header {
    font-weight: 400;
    margin-bottom: 2rem;
    position: relative;
    z-index: 99999;
    max-width: 45%;
    text-align: left;
  }

  .payment-items {
    display: grid;
    gap: 1.8rem;
    .payment-item {
      display: inline-flex;
      align-items: center;
      gap: 0.6rem;
      color: $dark;
      h3,
      a {
        font-weight: normal;
        &:hover {
          color: $dark;
        }
        margin: 0;
        font-size: 0.9rem;
      }
      cursor: pointer;
    }
  }
}
