.rpm-client-profile {
  &__container {
    &__right-body {
      &__records {
        &__record {
          &__measurements {
            .measurement-wrapper {
              display: flex;
              flex-direction: column;
              width: 100%;
              background-color: $white;
              min-height: 3.375rem;
              border-radius: 0.625rem;

              &__mood-text {
                height: auto;
                display: flex;
                flex-direction: column;
                width: calc(100% - 92px);
                padding-top: 13px;
                padding-bottom: 20px;
                border-top: 1px solid #d9d9d9;
                margin: auto;
                margin-top: 15px;
                gap: 10px;

                .hour {
                  color: #a4a2a1;
                  font-size: 0.625rem;
                  font-weight: 400;
                }

                .description {
                  font-weight: 400;
                  font-size: 0.75rem;
                  color: #6b6b6b;
                }

                strong {
                  color: #e8744f;
                  font-weight: 700;
                }
              }
            }
            &__measurement {
              width: 100%;
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
              padding: 0 1rem;
              min-height: 3.375rem;

              &__left {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 0.75rem;
                &__text {
                  font-size: 0.75rem;
                  font-weight: 400;
                  color: #595959;
                  &__title {
                    font-size: 0.875rem;
                    font-weight: 400;
                    color: #595959;
                    white-space: nowrap;
                  }
                }
                &__subtitle {
                  font-size: 0.625rem;
                  font-weight: 400;
                  color: #595959;
                }
              }
              &__right {
                height: 2rem;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                background-color: #ebece1;
                padding: 0 0.75rem;
                border-radius: 0.1875rem;
                &__value {
                  color: #a5a877;
                  font-size: 0.875rem;
                  font-weight: 600;
                }

                &__three-dots-button {
                  &__tooltip {
                    width: 9.3125rem;
                    height: 5.25rem;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    padding: 0 0.5rem;
                    position: absolute;
                    right: 0;
                    top: 0;
                    background-color: $white;
                    border-radius: 0.25rem;
                    box-shadow: 1px 1px 20px 1px rgba(0, 0, 0, 0.05);
                    &__item {
                      display: flex;
                      width: 100%;
                      height: 2.625rem;
                      align-items: center;
                      &__text {
                        font-size: 0.75rem;
                        font-weight: 600;
                        color: $color-dark;
                        margin-left: 0.5rem;
                        width: 100%;
                        text-align: left;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .tooltip-wrapper {
    position: relative;
    padding: 0.5rem;
    outline: none;
    &__tooltip {
      position: absolute;
      top: 0;
      left: 2rem;
      width: 18.5rem;
      min-width: 18.5rem;
      padding: 3rem 2.5rem;
      background: $white;
      box-shadow: 0px 8px 13px rgba(0, 0, 0, 0.2);
      border-radius: 0.3rem;
      display: flex;
      flex-direction: column;
      z-index: 2;
      &__border {
        width: 100%;
        height: 1px;
        background-color: #d3d0cc;
      }

      h3 {
        font-size: 1rem;
        font-weight: 600;
        color: $color-dark;
        margin-bottom: 1.5rem;
        text-align: left;
      }

      &__triangle {
        // it is a triangle at the level of the tooltip-wrapper facing to the left
        position: absolute;
        top: 0.75rem;
        left: -0.5rem;
        width: 0;
        height: 0;
        border-top: 0.5rem solid transparent;
        border-bottom: 0.5rem solid transparent;
        border-right: 0.5rem solid $white;
      }

      &__content {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 0.5rem;
        margin-top: 1.2rem;
        &__item {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          &__tag {
            text-align: left;
            font-weight: 400;
            font-size: 0.875rem;
            color: #595959;
            max-width: 10rem;
            // wrap text
            white-space: normal;
            word-wrap: break-word;
          }
          &__score {
            height: 2rem;
            width: 5rem;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            border-radius: 0.25rem;
            &__value {
              font-size: 0.75rem;
              font-weight: 600;
            }
          }
        }
      }
    }
  }
}

.data-prompt-modal {
  h3 {
    font-family: $serif-font-family;
    font-weight: 700;
    font-size: 1.25rem;
    text-align: center;
    color: $color-dark;
  }
  &__close {
    position: absolute;
    top: -3rem;
    right: -0.5rem;
    cursor: pointer;
  }
  .modal-dialog {
    max-width: 100%;
    margin: auto;
    margin-top: 5rem;
    background: #f8f5f5;
    width: 90vw;
    border-radius: 0.5rem;
    padding: 1.5rem;
    padding-bottom: 2.1875rem;
    padding-top: 4.0625rem;
    border-radius: 1.25rem;
    position: relative;
    .modal-content {
      background: #f8f5f5;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1.8rem;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    width: 100%;
    background-color: $white;
    border-radius: 0.5rem;
    padding: 0.75rem 1.5rem;
    &__item {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      &__tag {
        font-size: 0.875rem;
        font-weight: 400;
        color: #595959;
        max-width: 10rem;
        // wrap text
        white-space: normal;
        word-wrap: break-word;
      }
      &__score {
        width: 6.3125rem;
        height: 2.5rem;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        border-radius: 0.5rem;
        &___value {
          font-size: 0.875rem;
          font-weight: 600;
        }
      }
    }
  }
}

.delete-assessment-modal {
  .modal-content {
    display: flex;
    flex-direction: column;
    gap: 0.875rem;
    margin-top: calc(50vh - 6.5rem);
    min-height: 14.375rem;
    width: 30.56rem;
    @include media-breakpoint-down(sm) {
      width: 90vw;
      margin: auto;
      margin-top: 5rem;
    }
    align-items: center;
    justify-content: center;
    padding: 3rem;
    h3 {
      font-size: 1rem;
      font-weight: 600;
      color: $color-dark;
      font-family: $sans-serif-font-family;
    }

    p {
      font-size: 0.875rem;
      font-weight: 300;
      color: $color-dark;
      text-align: center;
    }

    p.red-text {
      font-size: 0.875rem;
      font-weight: 500;
      color: #e8744f;
      text-align: center;
    }

    position: relative;
  }

  &__warning {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #f9dad0;
    width: 100%;
    padding: 1rem;
    border-left: 4px solid #e8744f;
  }
  &__buttons {
    display: flex;
    align-items: center;
    gap: 1rem;
    @include media-breakpoint-down(sm) {
      width: 100%;
    }
    &__button {
      width: 10.93rem;
      height: 3.125rem;
      border: 1px solid $color-dark;
      font-weight: 500;
      color: $color-dark;
      border-radius: 0.625rem;
      @include media-breakpoint-down(sm) {
        width: 100%;
      }
    }
  }

  &__close {
    position: absolute;
    right: 1rem;
    top: 1rem;
    height: 1.5rem;
    width: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    outline: none;
  }
}
