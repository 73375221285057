.rpm-coach-dashboard {
    .rpm-coach-container-layout {
        .rpm-coach-container {
            .rpm-provider-records-container {
                display: flex;
                flex-direction: column;
                width: 100%;
                gap: 1rem;
            }
        }
    }
}
