@import '../../styles/mixins';

.roadmap-default-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding-top: 0;

  .roadmap-container {
    padding: 0;
  }

  .reviewing-card {
    border-radius: 10px;
    padding: 10px 40px;
    background: #e0ddda;
    color: #5a5a5a;
    font-family: 'Poppins';
    @media (max-width: 1200px) {
      margin-left: 15px;
      margin-right: 5px;
    }
  }

  .magic-grid {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 auto;

    @include media-breakpoint-up(lg) {
      width: 90%;
    }
    @include media-breakpoint-up(xl) {
      width: 80%;
    }
  }

  @include media-breakpoint-up(lg) {
    padding-top: 2.5rem;
  }
  .infinite-scroll-container {
    > div {
      &:first-child {
        width: 100%;
      }
    }
  }

  .common-header {
    h1 {
      font-size: $font-size-normal;
    }
    .third-column {
      width: 48px;
      svg {
        position: relative;
        right: 5px;
      }
    }
  }
  .default-stage-thumbnail {
    background-color: #e1ddda;
    border-radius: 8px;
    height: get-scaled-size($scale-factor-3);
    width: 0.3rem;
    margin: auto;
    margin-right: 0.5rem;

    svg {
      color: white;
      height: get-scaled-size($scale-factor-1);
      width: get-scaled-size($scale-factor-1);
      display: block;
    }
  }

  .table-of-contents-container {
    width: 100%;
    border-radius: 0.5rem;
    position: relative;

    .stage-title {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }
    .stage-title.active {
      margin-left: -1rem;
    }
    .image-container {
      position: relative;
      @include media-breakpoint-up(lg) {
        border-radius: 1rem;
      }
      .title-content {
        display: grid;
        justify-items: start;
        position: absolute;
        bottom: 0;
        padding: 0 2rem;
        @include media-breakpoint-down(sm) {
          padding: 0 1rem;
        }
        padding-bottom: 4rem;
        width: 100%;
        h1 {
          color: $white;
          font-family: $serif-font-family;
          padding-bottom: 0.5rem;
          font-size: $font-size-largest;
        }
        p {
          color: $white;
        }
        .progress {
          height: get-scaled-size($scale-factor-0_5);
          width: 50%;
          .progress-bar {
            background-color: $color-orange;
            border-radius: 4px;
          }
        }
        .continue-btn {
          background-color: $white;
          padding: 0.1rem;
          border-radius: 15rem;
          display: flex;
          align-items: center;
          gap: 0.5rem;
          padding-right: 1rem;
          margin-top: 1.2rem;
          size: $font-size-normal;
        }
      }
    }

    .table-of-contents {
      overflow: auto;
      padding: 2.5rem;
      background-color: $color-white;
      margin-top: -2rem;
      position: sticky;
      width: 100%;
      z-index: 10;
      border-radius: 1rem;
      display: none;

      @include media-breakpoint-up(lg) {
        display: block;
      }

      .card {
        .content {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
        }
        display: flex;
        background-color: $white;
        box-shadow: 0px 4px 20px 1px rgba(0, 0, 0, 0.1);
        margin: 0.6rem 0;
        padding: 1.2rem 2rem;
        h2 {
          margin: 0;
          font-weight: 500;
          font-size: $font-size-small;
        }
        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  .my-story {
    width: 100%;
    margin-top: 0;
    @include media-breakpoint-up(lg) {
      width: 100%;
      margin-top: 0;
    }
    position: relative;
    padding: 1.5rem;
    background-color: $color-white;
    border-radius: 1rem;
  }
  .card {
    box-shadow: none;
    background-color: transparent;
  }
  .stage {
    .card {
      min-height: 4.375rem;
      border: 1px solid transparent;
      box-shadow: 0px 4px 20px 1px rgba(0, 0, 0, 0.1);
      background-color: $color-white;
      &:hover {
        border: 1px solid #eaeaea;
      }
      background-color: $white;
      .row {
        min-height: 55px;
      }
    }

    h2 {
      padding-bottom: 0.5rem;
    }
  }

  h2 {
    @extend .mrm-mb-0_75;
  }
  .roadmap-title {
    font-size: $font-size-larger;
  }

  .hidden-student {
    color: $color-unread-red;
    font-size: $font-size-smallest;
    font-weight: bold;
    @extend .mrm-ml-0_5;
    @extend .py-0;
    @extend .mrm-px-0_5;
    border-radius: 5px;
    background-color: #ffc7c7;
  }

  & > .container {
    @extend .mrm-pt-2;
    @extend .mrm-mb-3;
  }
  .card {
    @extend .mrm-mb-0_75;
    &.recent {
      background-color: $color-item-lightpurple;
      border: 1px solid transparent;
      &:hover {
        border: 1px solid darken($color-item-lightpurple, 3%);
      }
    }
  }
  .card-title {
    font-size: $font-size-small;
  }

  .card-text {
    color: $color-text-disabled;
    font-size: $font-size-smallest;

    .svg-inline--fa {
      @extend .mrm-mr-0_25;
    }

    .badge {
      color: $color-text-light;
      font-size: $font-size-smallest;
    }
  }

  .comments {
    svg {
      position: relative;
      top: 1px;
    }
  }

  .action-items {
    @extend .mrm-mr-0_75;
  }

  .stage {
    @include media-breakpoint-up(lg) {
      margin-bottom: get-scaled-size($scale-factor-1_5);
    }
    @extend .mrm-mb-2_5;
    position: relative;
    h2 {
      font-size: $font-size-normal;
      font-weight: 500;
    }
    .stage-scroll-to {
      height: 0px;
      position: absolute;
      top: -85px;
      left: 0;
      right: 0;
      z-index: -1;

      &.with-student-header {
        top: -100px;
      }

      @media (min-width: 992px) {
        top: -205px;

        &.with-student-header {
          top: -215px;
        }
      }
    }

    .coach-notes {
      color: $color-assessment-red;
    }
  }

  .default-stage-thumbnail.red {
    background-color: $color-red;
  }

  .default-stage-thumbnail.yellow {
    background-color: #b68c31;
  }

  .default-stage-thumbnail.green {
    background-color: #9da16b;
  }

  .dot {
    position: absolute;
    top: 12px;
    left: -12px;
  }

  .desktop-page-secondary-header-wrapper {
    .btn-save {
      background-color: $color-dark;
    }
    .back-button-container {
      float: left;
      height: get-scaled-size($scale-factor_0_25);

      .common-desktop-back-button {
        box-shadow: none;
      }
    }

    .progress-text {
      color: $color-text-light;
      font-weight: 600;
      font-size: $font-size-small;
    }
  }
}
