@import '../../styles/mixins';

.support-dashboard {
  width: 100%;
  display: grid;
  justify-content: flex-center;
  justify-items: center;
  padding-top: 2rem;
  max-height: 100vh;
  overflow-y: scroll;

  .support-dashboard-body {
    display: flex;
    gap: 1.5rem;
  }
}
