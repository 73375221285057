.coach-message-prompt-card {
  background-color: $color-white;
  box-shadow: none;
  width: 100%;
  align-items: center;
  display: grid;
  font-weight: 200;
  padding-top: 6rem;
  margin-top: -2rem;

  .card-body {
    padding: 0;

    .inside-info {
      padding: 1rem;
      h1 {
        text-align: left;
      }
    }
  }

  .message-prompt-title {
    font-family: $serif-font-family;
    font-size: 1.3rem;
    font-weight: bold;
    margin-bottom: 25px;

    span {
      font-family: $serif-font-family;
      text-transform: capitalize;
    }
  }
}

@include media-breakpoint-up(lg) {
  .coach-message-prompt-card {
    position: relative;
    max-height: 100%;
    top: 0;
    padding-top: 2rem;
    margin-top: 0;
    /*     max-height: 19rem; // this seems to fix the issue with the card being too tall on safari
 */
    .card-body {
      padding: 0;

      .inside-info {
        padding: 2rem 1.5rem 2.5rem 1.5rem;
      }
    }
  }
}
