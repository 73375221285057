.send-clinical-record-modal {
  .modal-content {
    background-color: $white;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    padding: 3rem 5rem;

    @include media-breakpoint-down(sm) {
      width: 85vw;
      margin: 0 auto;
      padding: 2rem 1.5rem;
    }

    h3 {
      font-size: 1.5rem;
      font-weight: 300;
      text-align: center;

      @include media-breakpoint-down(sm) {
        font-size: 1.25rem;
      }
    }

    form {
      width: 100%;
      margin-top: 1.875rem;
      display: flex;
      flex-direction: column;

      label {
        width: 100%;
        font-size: 0.75rem;
        font-weight: 400;
        margin-top: 1.25rem;

        &:first-child {
          margin-top: 0;
        }
      }

      input {
        width: 100%;
        font-size: 0.875rem;
        font-weight: 300;
        color: $color-dark;
        padding-left: 1rem;
        height: 2.5rem;

        &:placeholder {
          font-size: 0.875rem;
          color: $color-dark;
          font-weight: 300;
        }

        &:disabled {
          background-color: rgba(211, 208, 204, 0.23);
          color: $color-dark;
        }
      }

      textarea {
        resize: none;
        font-size: 0.875rem;
        font-weight: 300;
        color: $color-dark;
      }

      .reviewing-text {
        font-weight: 300;
        font-size: 0.875rem;
        color: $color-dark;
        margin-top: 5px;
      }

      .error-message {
        color: $color-red;
        font-size: $font-size-smallest;
        margin: 0.5rem 0;
        font-weight: 700;
        text-align: start;
      }

      .submit-btn {
        background-color: $color-dark;
        color: $white;
        font-size: 0.875rem;
        width: 100%;
        height: 2.5rem;
        border-radius: 0.25rem;
      }
    }
  }

  &__buttons {
    display: flex;
    align-items: center;
    width: 100%;
    .back-btn {
        width: 100%;
        font-size: 0.875rem;
        text-align: left;
    }
  }
}
