.rpm-dashboard {
  .records-device {
    height: 54px;
    width: 100%;
    background-color: #40413f;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 9px;
    .device-left {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      .device-icon {
        background-color: #ffffff;
        height: 40px;
        width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px 5px 0px 5px;
        img {
          height: 25px;
          width: 25px;
          object-fit: contain;
        }
      }
      .device-name {
        display: flex;
        flex-direction: column;
        .device-title {
          color: #ffffff;
          font-size: $font-size-small;
          font-weight: 500;
        }
        .device-status {
          color: #908e87;
          font-size: $font-size-smallest;
          font-weight: 500;
        }
      }
    }
    .device-right {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      p {
        font-size: $font-size-smallest;
        color: #908e87;
      }
    }
  }
}
