.assessments-and-discuss-wrapper {
  .lateral-discuss {
    height: 47rem;
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: hidden;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 0.6rem;
    position: relative;
    .title {
      font-size: $font-size-small;
      padding: 2rem 0 0rem 1.5rem;
      font-weight: 600;
    }

    .subtitle {
      font-size: $font-size-small;
      padding: 0 0 1rem 1.5rem;
      font-weight: 300;
      font-style: italic;
    }
    hr {
      margin: 1rem auto;
      padding: 0 1rem;
      border-color: $color-gray;
    }
    .discuss-container {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 1rem;
      height: 28.5rem;

      .responses-length {
        padding-left: 1.5rem;
        font-size: $font-size-smaller;
      }
    }
  }
}

.no-responses-yet {
  font-size: $font-size-smallest;
  color: $color-dark-text-disabled;
  opacity: 0.55;
  font-weight: 600;
}

.mobile-responses {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 1.75rem;
  background-color: $white;
  border-radius: 2rem 2rem 0 0;
  z-index: 2147483637;
  .header {
    width: 100%;
    display: flex;
    p {
      text-align: left;
    }
  }

  .no-response {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .responses-container {
    width: 100%;
    padding-top: 1rem;
    .height-action-item-start {
      max-width: 100%;
      .action-item-header {
        background-color: transparent;
        @include media-breakpoint-down(sm) {
          background-color: $white;
        }
      }
    }
    .action-item-response {
      width: 100%;
    }

    .form-and-action-items {
      height: 52vh;
      width: 100%;
      .left-border {
        margin-left: 0rem;
      }
      .action-item-responses {
        overflow-x: hidden;
      }
    }

    hr {
      height: 0.75px;
      background-color: #dedcdc;
      margin-top: 1rem;
    }
  }
}
