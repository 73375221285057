@import '../../styles/mixins';

.send-instructions {
    background-color: $color-dark;
    color: $white;
    width: 100%;
}

.reset-password-form {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include media-breakpoint-up(md) {
        background-color: $white;
        padding: 3.5rem 5.1875rem;
        border-radius: 0.625rem;
        width: 37rem;
    }

    h2 {
        font-size: 1.5rem;
        font-weight: 600;
        text-align: center;
        margin-top: 2.5rem;
    }

    p {
        margin-top: 0.75rem;
        text-align: center;
    }

    .form-control {
        padding-left: 1.5rem;
        margin-top: 1.5rem;
    }

    button {
        width: 19.68rem;
        height: 2.8125rem;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 400;
        color: $white;
        border-radius: 0.625rem;
    }

    .loading-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.65);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1;
    }
}

.desktop-wrapper {
    display: none;

    @include media-breakpoint-up(md) {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 auto;
        width: 100%;
    }

    .back-button-container {
        width: 37rem;
        button {
            display: flex;
            align-items: center;
            gap: 0.5rem;
        }
        margin-bottom: 2.5rem;
    }
}

.help-instructions {
    width: 426px;
    padding: 1.5rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-left: 47px;
    background-color: #f8f5f5;
    border-left: 1px solid#D5D1CD;
    gap: 29px;
    margin-top: 28px;
    margin-bottom: 44px;
    h3 {
      font-size: 0.875rem;
    }
    ul {
        list-style: none;
        gap: 0.875rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        li {
            display: flex;
            align-items: flex-start;
            gap: 0.5rem;
            font-size: 14px;
            font-weight: 300;
            .icon {
                font-size: 14px;
                color: #ea734f;
                font-weight: 500;
            }
        }
    }
}

.back-button-container-mobile {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    width: 100%;
    height: 60px;
    background-color: $white;
    padding-left: 1.5rem;
    gap: 0.5rem;
}