@import '../../styles/mixins';

.welcome-page {
  height: 100vh;

  .btn {
    max-width: 350px;
    display: block;
    margin: auto;
  }

  .login {
    background-color: $color-bg-white;
    color: $color-dark;
    border: none;
  }

  .welcome-page-logo {
    width: 250px;
    height: auto;
  }
}

@include media-breakpoint-up(sm) {
  .welcome-page {
    .welcome-page-logo {
      width: 325px;
      height: auto;
    }
  }
}

.welcome-page {
  background-image: url(../images/noble-welcome-bg.png);
  background-repeat: no-repeat;
  background-size: 80%;
  background-position: center 20%;
  background-color: $color-dark;
  h1,
  p {
    width: 100%;
    color: $white;
    text-align: center;
  }

  h1 {
    font-family: $serif-font-family;
    font-size: $font-size-largest;
  }

  @include media-breakpoint-up(md) {
    h1,
    p {
      width: 70%;
    }
  }

  .welcome-page-logo {
    width: 150px;
    height: auto;
  }
  .welcome-page-msg {
    @extend .mrm-mt-12;
    color: $color-white;
    line-height: 2.3rem;
  }
}

@include media-breakpoint-up(sm) {
  .welcome-page {
    background-size: 300px;
  }
}
