@import '../../styles/mixins';

.user-user-page {
    min-height: 100vh;
    background-color: $color-white;
    .container {
        @extend .mrm-pt-1;
    }

    .header {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        padding-left: 2rem;
        padding-top: 2rem;
        gap: 0.25rem;
        h1 {
            font-family: $serif-font-family;
            font-size: larger;
            padding: 2rem 0 1rem 0;
        }
        img {
            height: 2.5rem;
        }
    }

    .menu-items-grid {
        display: grid;
        padding-bottom: 2rem;
        .item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 1.3rem 1rem;
            border-bottom: 1px solid rgba(196, 196, 196, 0.3);
            .icon-and-title {
                display: grid;
                grid-template-columns: auto 1fr;
                grid-gap: 1rem;
                place-items: center;
                .title {
                    font-size: 0.875rem;
                    font-weight: 300;
                    color: $color-dark;
                }
            }
        }
    }

    a {
        display: inherit;
        text-decoration: none;
        font-weight: 400;
    }

    .user-name {
        margin-left: -5px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .theme-text-primary {
            color: $color-dark;
            font-weight: 500;
            font-size: 0.875rem;
            font-family: $sans-serif-font-family;
        }
    }
    hr {
        border-color: $color-dark-text-dark;
        margin-left: -15px;
        margin-right: -15px;
    }

    .row-item {
        display: flex;
    }
    .user-page-header {
        margin-bottom: 1rem;
        p:first-child {
            font-weight: 500;
            margin-bottom: -5px;
            font-size: 0.875rem;
        }
        p:last-child {
            a {
                @extend .mrm-mt-0_25;
                text-decoration: none;
                font-weight: 300;
                font-size: 0.75rem;
                color: #6d786e;
            }
        }
        hr {
            width: 90%;
        }
    }
    .user-page-item {
        cursor: pointer;
        display: flex;
        justify-content: space-between;

        svg {
            font-size: $font-size-large;
            width: 1.5rem;
            color: $color-dark;
        }
    }
    .header-down-menu {
        @extend .mrm-pt-0_25;
    }
}

.user-user-page {
    hr {
        border-color: $color-dark;
    }
}
