.refunds-modal {
  .modal-header {
    .modal-title {
      font-size: 1.5rem;
      font-weight: 700;
      font-family: $serif-font-family;
      margin: 0 auto;
      padding-top: 1rem;
    }
  }
}
