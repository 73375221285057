@import '../../../../../styles/mixins';

.support-input-container {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  .support-input {
    min-height: 2.35rem;
    width: 100%;
    padding-left: 2.5rem;
    padding-right: 1rem;
    background-color: $white;
    border: 1px solid $color-light-gray;
    border-radius: 0.4rem;
    align-items: center;
    &::placeholder {
      font-size: $font-size-small;
      font-weight: 300;
    }
  }

  .custom-icon {
    position: absolute;
    left: 1rem;
  }
}

.support-client-info-extra-dropdown {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.5rem;
  background-color: $color-bg-white;
  grid-column: span 2;
  position: relative;
  margin-top: 1rem;

  .triangle-up {
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid $color-bg-white;
    position: absolute;
    right: 0.5rem;
    top: -10px;
  }
  .support-input-container {
    max-width: 18.6rem;
    align-items: center;
    .error {
      margin-left: -15rem;
    }
  }
  .extra-dropdown-select {
    margin: 0 auto;
  }
}
