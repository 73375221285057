.invitation-list-modal {
  button {
    outline: none;
  }
  .modal-header {
    padding-top: 2rem;
    .cancel {
      display: flex;
      justify-content: flex-end;
      width: 100%;
    }
  }
  .modal-body {
    padding: 0 2rem;
    .modal-title {
      font-size: 2.2rem;
      font-weight: 700;
      font-family: $serif-font-family;
      color: $color-dark;
      text-align: left;
    }
    .description {
      text-align: left;
      font-weight: 300;
      padding-top: 0.5rem;
    }
    .choose {
      font-weight: 500;
      margin: 1.5rem 0;
    }
    .invitation-list-modal-body {
      display: grid;
      row-gap: 1rem;
      max-height: 30vh;
      overflow-y: scroll;
      margin-bottom: 1.5rem;
      .invitation {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1rem;
        border: 1px solid #d3d3d2;
        border-radius: 0.6rem;

        .icon-and-title {
          display: flex;
          align-items: center;
          gap: 0.7rem;
          .title {
            h3 {
              font-size: $font-size-large;
              font-weight: 300;
              color: $color-dark;
              max-width: 90%;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2; /* number of lines to show */
              line-clamp: 2;
              -webkit-box-orient: vertical;
            }
            .coach-name {
              font-size: $font-size-smaller;
              font-weight: 300;
              color: $color-dark;
            }
          }
        }
        .price {
          p {
            display: flex;
            flex-wrap: nowrap;
            font-weight: 600;
            // avoid text break
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
    }

    .checkout {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 2rem;
      background-color: $color-dark;
      color: white;
      font-weight: 500;
      padding: 1rem;
      border-radius: 0.6rem;
      border: none;
      width: 100%;
      margin: 0 auto;
      margin-bottom: 1.5rem;

      // if button is disbled background is gray in hex code
      &:disabled {
        background-color: #afafaf;
      }
    }
  }
}
