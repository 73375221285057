.inactive-messaging-banner {
  background-color: #f6f6f6;
  position: relative;
  width: 100%;
  max-width: 1140px;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 10px;
  @include media-breakpoint-down(md) {
    background: transparent;
  }
  h1 {
    position: absolute;
    top: 2rem;
    left: 3rem;
    font-size: 1.5rem;
    text-align: center;
    @include media-breakpoint-down(md) {
      position: relative;
      top: 0;
      left: 0;
      margin-bottom: 2rem;
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 1rem;

    h2 {
      font-family: $serif-font-family;
      font-size: 1.25rem;
      width: 50%;
      margin-top: 1rem;
    }

    p {
      font-size: 0.875rem;
      font-weight: 300;
      width: 50%;
    }

    a {
      text-decoration: none;
      color: $white;
      font-weight: 400;
      font-size: $font-size-normal;
      background-color: $color-dark;
      padding: 0.75rem 3rem;
      border-radius: 10px;
      width: max-content;
      &:hover {
        background-color: $color-dark;
        opacity: 1;
      }
    }
  }
}
