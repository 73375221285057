@import '../../../../styles/mixins';

.roadmap-components-competency-page-competency-learn-tab {
  .print-header {
    text-align: center;
    font-weight: bold;
    display: none;
  }
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 0;
    .add-attachment {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $color-dark;
      padding: 0.4rem;
      border-radius: 0.4rem;
      &::hover {
        background-color: $color-dark;
      }
    }
    h2 {
      font-size: $font-size-large;
      font-weight: 600;
      font-family: $sans-serif-font-family;
    }
    .text {
      display: flex;
      align-items: center;
    }
  }
  .asssessment-title {
    font-weight: 600;
    font-family: $sans-serif-font-family;
    font-size: 1.125rem;
  }
  span {
    font-weight: 400;
    font-size: $font-size-small;
    color: $color-text-light;
  }
  .coach-notes {
    font-size: $font-size-small;
    color: $color-assessment-red;
  }

  .description-container {
    color: $color-text-normal;
    font-size: $font-size-normal;
    overflow-x: hidden;
    @include media-breakpoint-up(lg) {
      font-size: $font-size-normal;
    }

    img {
      max-width: calc(100vw - 30px);
      height: auto !important;
    }
  }

  .embed {
    display: flex;
    flex-direction: column;
    figure {
      flex-grow: 1;
      & > div {
        flex-grow: 1;
      }
    }
  }

  .story-desc {
    font-size: $font-size-small;
    color: $color-dark;
    font-weight: 300;
  }

  .notes-container {
    a {
      text-decoration: none;
    }
    .notes-description a {
      @extend .mrm-main-box-shadow;
      @extend .mrm-p-0_75;
      @extend .mrm-mb-1;

      background-color: #ffffff;
      display: flex;
      flex-flow: column;
      border-radius: 10px;
      &:hover {
        opacity: 1;
      }
      pre {
        @extend .mrm-mb-0_5;

        font-size: $font-size-normal;
        color: $color-text-light;
        font-family: inherit;
        white-space: pre-wrap;
        word-wrap: break-word;
        font-family: 'Open Sans';
      }
    }
    pre {
      @extend .mrm-mb-0_5;

      font-size: $font-size-normal;
      color: $color-text-light;
      font-family: inherit;
      white-space: pre-wrap;
      word-wrap: break-word;
      font-family: 'Open Sans';
    }
  }

  @media print {
    .print-header {
      display: block;
    }
  }

  .self-reflection {
    .assessment-container {
      background-color: $color-white;
      border-radius: 10px;
      color: $color-text-normal;
      min-height: 3em;
      width: 100%;
      margin: 0;
      box-shadow: 0px -4px 20px 1px rgba(0, 0, 0, 0.05);
      margin-bottom: 1rem;
      p {
        font-size: $font-size-normal;
        color: $color-text-normal;
        line-height: 20px;
      }

      .slider-container {
        @extend .mrm-px-0_75;
        .rc-slider {
          &::before {
            position: absolute;
            content: '';
            border: 4px solid rgba(255, 255, 255, 0.55);
            height: 1rem;
            z-index: 10;
            left: 26.5%;
          }

          &::after {
            position: absolute;
            content: '';
            border: 4px solid rgba(255, 255, 255, 0.55);
            height: 1rem;
            right: 26.5%;
          }

          .rc-slider-rail {
            background: linear-gradient(
              to right,
              $color-assessment-red,
              $color-assessment-yellow,
              $color-assessment-green
            );
            height: 1rem;
            padding: 0;
            left: -0.5rem;
            width: calc(100% + 1rem);
            border-radius: 10px;
          }

          .rc-slider-step {
            display: none;
          }

          .rc-slider-mark {
            top: 30px;
            &:first-child {
              display: none;
            }
            .rc-slider-mark-text:first-of-type {
              visibility: hidden;
            }
          }

          .rc-slider-handle {
            margin-top: -10px;
            width: 1.875rem;
            height: 1.875rem;
            border: 1px solid $color-background-light-gray;
            background-color: white;
            border-radius: 10px;
            @extend .mrm-box-shadow;
          }
        }
      }

      .selected {
        font-size: 30px;
        @extend .mrm-ml-0_5;
        vertical-align: inherit;
        display: initial;
      }
      .row {
        flex-wrap: nowrap;
      }
      .assessment {
        cursor: pointer;
        display: flex;
        align-items: center;
        border-radius: 0;
        box-shadow: none;
        padding: 1rem 0;
        .card-title-options {
          font-weight: 300;
          font-size: $font-size-small;
          max-width: max-content;
          display: flex;
        }
        &.red.active {
          background: rgba(238, 234, 230, 0.6);
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
        }
        &.yellow.active {
          background: rgba(238, 234, 230, 0.6);
        }
        &.green.active {
          background: rgba(238, 234, 230, 0.6);
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;
        }

        .circle {
          border-radius: 50%;
          width: 20px;
          height: 20px;
          margin: auto;
          display: block;
          position: relative;
          left: 6px;

          &.selected:before {
            position: absolute;
            content: ' ';
            width: 60%;
            height: 60%;
            border-radius: 50%;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
          }
        }

        .card-body {
          @extend .mrm-py-0_75;
          @extend .mrm-px-0_75;
        }
        .card-title {
          font-size: $font-size-normal;
          line-height: 22px;
        }
      }
      .red {
        .selected:before {
          background-color: $color-alerts;
        }
        .circle {
          border: 1px solid $color-alerts;
        }
      }
      .yellow {
        .selected:before {
          background-color: #ca9d29;
        }
        .circle {
          border: 1px solid #ca9d29;
        }
      }
      .green {
        border-bottom: none;
        .selected:before {
          background-color: #9da16b;
        }
        .circle {
          border: 1px solid #9da16b;
        }
      }
    }
    .assessment-slider-container {
      @extend .mrm-p-0_75;
      min-height: 8em;
      p {
        @extend .mrm-mb-0_75;
        @extend .mrm-mt-0_5;
        margin-left: 4px;
      }
    }
    .btn-bottom-left-rounded {
      @extend .mrm-px-1_5;

      border-radius: 0;
      border-bottom-left-radius: 10px;
      &.active {
        background-color: $primary;
        color: white;
      }
    }
    .btn-bottom-right-rounded {
      @extend .mrm-px-1_5;

      border-radius: 0;
      border-bottom-right-radius: 10px;
      &.active {
        background-color: $primary;
        color: white;
      }
    }
    .btn-bottom-left-rounded,
    .btn-bottom-right-rounded {
      svg {
        vertical-align: middle;
      }
    }
    .border-br-none {
      border-bottom-right-radius: unset !important;
    }
    .assessment-approve {
      display: flex;
      width: 100%;
      justify-content: flex-end;
      margin-top: 1px;
      gap: 1rem;
      button {
        display: flex;
        align-items: center;
        padding: 0.8rem 1rem;
        border-radius: 0.8rem;
        max-width: max-content;
        border: 1.5px solid $color-dark;
      }

      .approved,
      .rejected {
        background-color: $color-dark;
        color: white;
      }
    }
  }

  .question-title {
    font-weight: 600;
  }

  .questions-section {
    @extend .mrm-main-box-shadow;

    background-color: white;
    border-radius: 10px;

    .question-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      line-height: 22px;
      min-height: 58px;
      background-color: $color-white;
      padding: 1.5rem;

      .question {
        font-size: $font-size-small;
        font-weight: 300;
        color: $color-dark;
      }

      .answer {
        @extend .mrm-mt-0_5;
        @extend .mrm-ml-1;
        @extend .mrm-mb-0_25;
        font-size: $font-size-small;
        color: $color-dark-text-lighter;
        font-weight: 300;
      }

      pre {
        max-width: 100%;
        font-size: $font-size-normal;
        font-family: inherit;
        white-space: pre-wrap;
        word-wrap: break-word;
        margin-bottom: 0;
        color: $color-text-normal;
      }

      &:not(:last-child) {
        border-bottom: 2px solid $color-background-light;
        @include media-breakpoint-up(lg) {
          border-bottom: 2px solid #f1f1f1;
        }
      }
      svg {
        margin-left: 0.625rem;
        width: 0.5rem;
      }
      &:hover {
        cursor: pointer;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    .notes-container {
      .notes-description a {
        @include mrm-white-on-white-vivid-box-shadow;
      }
    }
  }
}
