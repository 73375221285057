@import '../../styles/mixins';

.common-header-container {
  .static-placeholder {
    visibility: hidden;
  }
  .fixed-actual-header {
    position: fixed;
    top: 0;
    z-index: 1000;
    width: 100%;
  }
}

.common-header {
  padding: 20px 15px 0;
  background: $color-white;
  overflow-wrap: break-word;

  a,
  .back-link {
    color: $color-text-normal;
  }

  .back-link-container {
    a:hover {
      color: $color-text-normal;
      opacity: 1;
    }
    .svg-inline--fa .fa-secondary {
      opacity: 1;
    }
  }

  h1 {
    font-weight: bold;
    text-align: center;
    font-size: $font-size-large;
    line-height: 20px;
    margin-bottom: 0;
  }

  button {
    font-size: 1rem;
  }

  .full {
    margin: 0;
    width: 100%;
    position: relative;
    left: -15px;
  }

  .row {
    align-items: center;
  }

  .back-link-container {
    padding-left: 0;
    padding-right: 0;
    text-align: center;

    a {
      display: inline-block;
      min-width: get-scaled-size($scale-factor-3);
    }
  }

  .back-link {
    @extend .mrm-px-1;

    cursor: pointer;
  }
}
