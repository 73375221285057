@import '../../styles/mixins';

.common-bottom-nav {
  .navbar {
    height: get-scaled-size($scale-factor-4);
    // border-top: 1px solid $color-item-gray;
    background-color: $white;
    border-top-left-radius: 2.5rem;
    border-top-right-radius: 2.5rem;

    span.badge {
      color: white;
      position: absolute;
      top: 58%;
      transform: translate(12%, -150%);
      font-size: get-scaled-size($scale-factor-0_5);
      line-height: 1.3;

      &.transparent-badge {
        color: transparent;
      }
    }

    .nav-link {
      color: $color-item-gray;
    }

    .nav-item {
      flex-basis: 0;
    }
  }
}
