.new-staff-modal {
    button {
        outline: none;
    }
    .modal-content {
        background-color: $white;
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: center;
        padding: 3rem 5rem;

        @include media-breakpoint-down(sm) {
            padding: 2rem;
        }

        form {
            margin-top: 1rem;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            .form-group {
                display: flex;
                flex-direction: column;
                label {
                    font-size: 0.75rem;
                    font-weight: 300;
                    color: #40413f;
                    margin-bottom: 0.625rem;
                }
                width: 100%;
                .input-wrapper {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 100%;
                    position: relative;
                    height: 2.8125rem;
                    .icon-wrapper {
                        position: absolute;
                        top: 50%;
                        left: 1rem;
                        transform: translateY(-50%);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 1.5rem;
                        height: 1.5rem;
                    }

                    input {
                        width: 100%;
                        height: 100%;
                        border: 1px solid #f2f1ec;
                        border-radius: 0.625rem;
                        padding-left: 3.625rem;
                        &::placeholder {
                            color: #6d786e;
                            font-weight: 300;
                        }
                    }
                }

                .checkbox-wrapper {
                    display: flex;
                    align-items: center;
                    gap: 0.5rem;
                    width: 100%;
                    label {
                        margin: 0;
                    }
                    button {
                        outline: none;
                    }
                }
            }

            .error-message {
                color: $color-red;
                font-size: $font-size-smallest;
                margin-top: 0.5rem;
                font-weight: 700;
            }
            .submit-button {
                height: 3.125rem;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: $color-dark;
                color: $white;
                border-radius: 0.625rem;
                outline: none;
                font-weight: 500;
            }

            .cancel-button {
                text-decoration: underline;
                font-size: 0.875rem;
                font-weight: 400;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: transparent;
                margin-top: 1.5rem;
            }
        }
    }
    .react-tel-input {
        .flag-dropdown {
            background: $white;
            border: none;
            border-radius: 0.525rem;
            .selected-flag {
                background: transparent;
                padding-left: 1rem;
                border: 1px solid #f2f1ec;
                border-right: none;
            }
        }
        .country-list {
            box-shadow: 0px 4px 20px 1px rgba(0, 0, 0, 0.1);
            border-radius: 0.525rem;
            li {
                font-weight: 300;
            }
        }
    }
}
