@import '../../styles/mixins';

.subscriptions-default {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  min-height: 100vh;
  background-color: #fdfdfd;

  .subscriptions-default-nav {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $white;
    padding: 1.25rem 7.5%;
    z-index: 1;
    img {
      width: 7.125rem;
      object-fit: contain;
    }
    position: relative;

    @include media-breakpoint-down(md) {
      flex-direction: column;
      align-items: center;
      gap: 2rem;
    }

    z-index: 1;
    img {
      width: 6.25rem;
    }
    .log-out {
      position: absolute;
      top: 2rem;
      right: 1.5rem;
      font-size: $font-size-small;
      cursor: pointer;
      opacity: 0.6;
      &:hover {
        opacity: 1;
      }
    }
  }
  .content-container {
    display: grid;
    background-size: cover;
    position: relative;
    min-height: 100vh;
    height: 100%;
    padding: 2rem 0;
    @include media-breakpoint-up(lg) {
      background-size: contain;
      grid-template-columns: 1fr 1fr;
    }
    margin: 0 auto;
    place-items: center;
    background-repeat: no-repeat;
    width: 100%;
    background-position-x: right;
    object-fit: cover;
    video,
    .background-image {
      position: absolute;
      top: 0;
      z-index: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      background-position: contain;
      transform: scaleX(-1);
      @include media-breakpoint-down(sm) {
        filter: blur(6px);
      }
    }
    .background-image {
      transform: scaleX(1);
    }
  }

  .payments-container {
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: $white;
    max-width: 31.25rem;
    padding: 2.5rem 5rem;
    min-width: 40vw;
    margin-left: 15vh;
    max-height: max-content;
    height: max-content;
    box-shadow: 1px -5px 18px 5px rgba(0, 0, 0, 0.04);
    border-radius: 1.4rem;
    gap: 1rem;
    .title {
      font-size: 2rem;
      font-weight: 700;
      text-align: center;
    }
    .description {
      text-align: center;
      width: 100%;
      font-weight: 300;
      font-size: $font-size-small;
    }

    .product-name-and-price {
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding-bottom: 1rem;
      border-bottom: 0.5px solid $color-gray-lightest;
      align-items: center;
      .product {
        font-weight: 300;
        font-size: $font-size-small;
        text-align: left;
      }
    }
    .credit-cards {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      .brands {
        display: flex;
        align-items: center;
        gap: 0.4rem;
      }
    }
    form {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      padding: 0;
      width: 100%;
      input {
        width: 100%;
        padding: 0.5rem;
        font-size: $font-size-small;
        border: 1px solid #eaeaee;
        user-select: none;
        border-radius: 0.25rem 0.25rem 0 0;
      }
      .pre-checkout-price {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .check-description {
          font-size: 0.8rem;
          font-weight: 300;
          max-width: 50%;
        }
        .paying-now {
          p {
            font-size: $font-size-smallest;
          }
          h3 {
            font-size: 1.5rem;
            font-weight: 600;
          }
        }
      }
      button {
        padding: 0.5rem;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0.6rem;
        color: $white;
        font-weight: 600;
      }
    }
    .bill-grid {
      border-bottom: 0.5px solid $color-gray-lightest;
      p {
        font-weight: 600;
        font-size: $font-size-small;
      }
      .title {
        font-weight: 300;
      }
    }
    .terms {
      font-weight: 300;
      font-size: $font-size-small;
      text-decoration: underline;
      color: $color-dark;
      text-align: left;
      margin-top: 1rem;
    }
    .go-to-dashboard {
      padding: 0.5rem;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0.35rem;
      color: $white;
      font-weight: 600;
      margin-top: 1rem;
    }
  }
  #payment-message {
    color: $color-red;
    font-size: $font-size-smallest;
    margin: 0.5rem 0;
    font-weight: 700;
  }

  .email-input {
    position: relative;
    svg {
      position: absolute;
      top: 0.95rem;
      left: 0.75rem;
    }
    .form-input {
      padding-left: 2.5rem;
      &:placeholder {
        padding-left: 2rem;
      }
    }
  }
  @include media-breakpoint-down(sm) {
    .payments-container {
      width: 85vw;
      margin-left: 0;
      padding: 1.5rem 2rem;
      height: auto;
      .title {
        font-size: $font-size-large;
      }
      .description {
        font-size: $font-size-small;
      }
      .product-name-and-price {
        .product,
        .price {
          font-size: $font-size-small;
        }
      }
      .credit-cards {
        h3 {
          font-size: $font-size-small;
        }
      }
      form {
        .pre-checkout-price {
          .check-description {
            font-size: 0.5rem;
          }
          .paying-now {
            p {
              font-size: 0.5rem;
            }
            h3 {
              font-size: $font-size-larger;
            }
          }
        }
        button {
          font-size: $font-size-smaller;
        }
      }
      .description,
      .product-name-and-price h3,
      .bill-grid .title,
      .bill-grid p,
      .go-to-dashboard,
      .terms {
        font-size: 0.75rem;
      }
    }
  }
}
