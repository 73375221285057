.rpm-dashboard {
  .health-data-record {
    .date {
      font-size: 12px;
      color: #908e87;
      font-weight: 600;
      margin-top: 0.5rem;
    }
    .health-data-record-content {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      margin-top: 0.5rem;
      .health-data-record-card {
        height: 56px;
        width: 100%;
        background-color: #ffffff;
        border-radius: 10px;
        display: flex;
        align-items: center;
        padding: 1rem;
        justify-content: space-between;
        .health-data-record-card-left {
          display: flex;
          align-items: center;
          gap: 1rem;
          .title-and-text {
            display: flex;
            flex-direction: column;
            .title-container {
              display: flex;
              align-items: center;
              gap: 0.5rem;
              h3 {
                font-size: $font-size-small;
                color: #6b6b6b;
                font-weight: 400;
              }
            }
            p {
              font-size: 12px;
              color: #6b6b6b;
              font-weight: 400;
            }
          }
        }
        .health-data-record-card-right {
          aspect-ratio: 1/1;
          display: flex;
          width: 32px;
          height: 32px;
          align-items: center;
          justify-content: center;
          background: rgba(136, 160, 136, 0.27);
          border-radius: 3px;
          p {
            color: #7b917b;
            font-weight: 600;
            font-size: 0.75rem;
          }
        }
      }
    }
  }
}
