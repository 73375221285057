@import '../../../styles/mixins';

.profile-view {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  justify-items: center;
  min-height: 100vh;
  padding-bottom: 10rem;

  hr {
    width: 100%;
    border: 0;
    height: 0.3px;
    margin: 0;
    color: #c4c4c430;
  }
}

.profile-tab-selector {
  width: 100%;
  max-width: 32rem;
  display: flex;
  flex-direction: column;
  padding: 0 0.5rem;
  margin: 0 auto;

  .profile-header {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    align-items: center;
    max-width: 100%;
    padding-top: 1rem;
    svg {
      justify-self: left;
      margin: 0;
      max-width: fit-content;
    }
    h1 {
      place-self: center;
      margin: 0;
      font-family: $serif-font-family;
      font-size: $font-size-larger;
      margin-left: -1rem;
    }
  }

  .profile-tabs {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 1rem 1.5rem 0 1.5rem;
    .profile-tab {
      display: grid;
      justify-items: center;
      outline: none;
      row-gap: 0.1rem;
    }
  }
}

.profile-container {
  padding: 2rem 0rem;
  width: 100%;
  overflow: hidden;
  display: none;
  justify-items: center;
  align-items: center;

  .active-but-canceled {
    padding: 0.7rem;
    background-color: rgba(196, 114, 88, 0.3);
    max-width: 100%;
    margin: 0 auto;
    border-radius: 0.5rem;
    margin-bottom: 2.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    p {
      color: $color-red;
      font-size: $font-size-smaller;
      span {
        text-decoration: underline;
        font-weight: 500;
        margin-left: 1rem;
        cursor: pointer;
      }
    }
    button {
      color: $color-white;
      background-color: $color-red;
      padding: 0.3rem 0.5rem;
      font-size: $font-size-smaller;
      border-radius: 0.5rem;
    }
  }
}

.right-grid {
  display: grid;
  width: 100%;
  row-gap: 2.2rem;

  .title {
    font-weight: 500;
  }
}

@include media-breakpoint-up(lg) {
  .clients-coaches-container {
    background-color: $color-white;
  }

  .payment-method-container {
    background-color: $color-white;
  }
  .billing-container {
    background-color: $color-white;
  }

  .profile-container {
    display: block;
  }

  .right-grid {
    width: 100%;
  }

  .clients-coaches-container {
    width: 100%;
  }
}

hr {
  width: 100%;
  border: 0;
  height: 0.1px;
  margin: 0;
  color: rgba(196, 196, 196, 0.3);
}
