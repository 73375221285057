// Button global styles

button {
  border: none;
  background: none;
}

.btn {
  font-family: $sans-serif-font-family;
  font-weight: bold;
  border-radius: 5px;
  min-height: 40px;
  // border: none;
  width: auto;
  font-size: 0.875rem;
  &:focus {
    box-shadow: none;
  }
}

.btn-100 {
  width: 100%;
}

.btn-center {
  display: block;
  margin: auto;
}

.btn-orange {
  @include button-variant(#f86630, #f86630, #f86630);
}

.btn-lg {
  font-size: 1rem;
  height: 45px;
  width: 100%;
}

.btn-primary {
  background-color: #2f80ed;
  border-color: #2f80ed;
}

.btn-secondary {
  background: transparent;
  color: #727272;
  font-weight: normal;
  &:hover {
    cursor: pointer;
  }
  &:focus,
  &:active,
  &:hover {
    background: transparent !important;
    color: #727272 !important;
    box-shadow: none !important;
  }
}

.btn-destructive {
  background: transparent;
  color: #ff3b30;
  font-size: 1rem;
  font-weight: 400;
  &:hover {
    color: darken(#ff3b30, 5%);
  }
}

.btn-menu-item {
  color: #3e3e3e;
  font-size: 1rem;
  font-weight: 400;
  &:focus {
    border: none;
  }
  &:hover {
    color: #212529;
  }
}

.more-btn-desktop {
  svg {
    background: transparent;
    border-radius: 8px;
    position: relative;
    color: $color-text-light;
    font-size: 1.5rem;
    width: 40px !important;
    height: 23px;
    // padding: 8px;
    &:hover {
      opacity: 1;
      cursor: pointer;
      background-color: darken($color-background-light-gray, 2%);
    }
  }
  &:hover {
    opacity: 1;
  }
}

.btn-white {
  background-color: white;
  color: $color-text-light;
  @extend .mrm-main-box-shadow;

  &:focus,
  &:active,
  &:hover {
    background-color: white;
    @extend .mrm-main-box-shadow;
    color: $color-text-light;
  }
}

.btn-gray {
  background-color: $color-background-light-gray;
  color: $color-text-light;
  &:focus,
  &:active,
  &:hover {
    color: $color-text-light;
    background-color: darken($color-background-light-gray, 3%);
    opacity: 1;
  }
}

@include media-breakpoint-up(lg) {
  .btn-cancel {
    &:hover {
      background-color: darken($color-background-light-gray, 2%);
      opacity: 1;
    }
  }
}
