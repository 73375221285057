.rpm-provider-records-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1rem;
    height: 100%;
    @include media-breakpoint-down(md) {
        margin-top: 4rem;
    }

    @include media-breakpoint-down(sm) {
        margin-top: 0;
        padding-top: 0;
        overflow-y: auto;
    }
}

.rpm-provider-records {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    max-height: 100vh;
    overflow-y: auto;
}

.rpm-provider-dashboard,
.rpm-coach-dashboard {
    .rpm-alerts-wrapper {
        display: flex;
        align-items: center;
        flex-direction: row;
        gap: 1.5rem;
        justify-content: flex-end;
        button {
            outline: none;
        }
        &__resend-invitation {
            font-size: 0.75rem;
            font-weight: 400;
            color: #000000;
            text-decoration: underline;
            margin-right: -0.25rem;
        }
    }
    .rpm-provider-container,
    .rpm-coach-container {
        .rpm-provider-columns {
            display: flex;
            flex-direction: row;
            width: 100%;
            justify-content: space-between;
            padding: 0.55rem;
            position: relative;
            border-bottom: 1px solid #c4c4c4;
            p {
                font-size: 0.75rem;
                font-weight: 500;
                color: #595959;
            }
            .rpm-provider-columns {
                max-width: 15.2125rem;
            }
            .rpm-provider-columns-statuses {
                position: absolute;
                left: 30%;
                transform: translateX(-30%);
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                grid-gap: 2.5rem;
            }
            .rpm-provider-columns-alerts {
                padding-right: 1rem;
            }
        }

        .rpm-provider-records {
            display: flex;
            flex-direction: column;
            width: 100%;
            gap: 1rem;
            height: 100%;
            overflow-y: auto;
        }
        .rpm-provider-record {
            display: flex;
            flex-direction: column;
            width: 100%;
            background-color: $white;
            border-radius: 0.625rem;
            margin-top: 1rem;
            box-shadow: 0px -4px 20px 1px rgba(0, 0, 0, 0.05);

            .rpm-provider-record-header {
                display: flex;
                align-items: center;
                justify-content: space-between;
                height: 4.0625rem;
                padding: 1rem;
                position: relative;

                .rpm-provider-record-header-status-container {
                    position: absolute;
                    left: 30%;
                    transform: translateX(-30%);
                    display: grid;
                    grid-template-columns: 1fr 1fr 1fr;
                    grid-gap: 2rem;
                    .rpm-provider-record-header-status {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        gap: 0.5rem;
                        .rpm-provider-record-header-status-dot {
                            width: 0.25rem;
                            height: 0.25rem;
                            border-radius: 50%;
                            aspect-ratio: 1/1;
                            background-color: #a4a2a1;
                        }
                        .rpm-provider-record-header-status-text {
                            font-size: 0.625rem;
                            font-weight: 500;
                            color: #a4a2a1;
                        }
                    }
                    .with-border {
                        border: 1px solid #a4a2a1;
                        border-radius: 7.8125rem;
                        padding: 0.4rem 0.625rem;
                    }
                }
                .rpm-provider-record-header-user {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    gap: 0.5rem;
                    max-width: 15.2125rem;
                    .rpm-provider-record-header-user-avatar {
                        position: relative;
                        width: 2.5rem;
                        height: 2.5rem;
                        aspect-ratio: 1/1;
                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                        .rpm-provider-record-header-user-avatar-text {
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            font-size: 0.75rem;
                            font-weight: 500;
                            color: $white;
                        }
                    }
                    .rpm-provider-record-header-user-text {
                        font-size: $font-size-normal;
                        color: $color-dark;
                        font-weight: 500;
                        width: 75%;
                        // 2 lines max, then ellipsis
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                    }
                }
                .rpm-provider-record-header-alert {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    position: relative;
                    cursor: pointer;
                    .rpm-provider-record-header-alert-icon-container {
                        position: relative;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        .rpm-provider-record-header-alert-icon-square {
                            width: 1.25rem;
                            height: 1.25rem;
                            aspect-ratio: 1/1;
                            background-color: #e8744f;
                            border-radius: 0.1875rem;
                            transform: rotate(45deg);
                        }
                        .rpm-provider-record-header-alert-icon {
                            color: $white;
                            font-size: $font-size-smallest;
                            font-weight: 800;
                            z-index: 10;
                            position: absolute;
                        }
                    }
                    .rpm-provider-record-header-alert-text-container {
                        min-width: 5.0625rem;
                        height: 1.5rem;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background: #f9dad0;
                        border-radius: 0.25rem;
                        margin-left: -0.5rem;
                        .rpm-provider-record-header-alert-text {
                            color: #e8744f;
                            font-size: $font-size-smallest;
                            font-weight: 600;
                            text-align: center;
                        }
                    }

                    .rpm-provider-record-header-alert-popup {
                        height: 18rem;
                        width: 16rem;
                        background-color: $white;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        position: absolute;
                        top: -9rem;
                        left: -17rem;
                        border-radius: 0.5rem;
                        box-shadow: 0px 8px 13px rgba(0, 0, 0, 0.2);
                        z-index: 999;
                        .rpm-provider-record-header-alert-popup-header {
                            background-color: #e8744f;
                            height: 2.5rem;
                            width: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            border-radius: 0.25rem 0.25rem 0 0;
                            padding: 0 1rem;
                            .rpm-provider-record-header-alert-popup-header-title-container {
                                display: flex;
                                align-items: center;
                                justify-content: flex-start;
                                gap: 0.5rem;
                                .rpm-provider-record-header-alert-popup-header-title {
                                    font-size: $font-size-small;
                                    font-weight: 600;
                                    color: $white;
                                }
                            }
                            .rpm-provider-record-header-alert-popup-header-clear {
                                font-size: $font-size-smallest;
                                font-weight: 600;
                                color: $white;
                                cursor: pointer;
                                text-decoration: underline;
                            }
                        }
                        .rpm-provider-record-header-alert-popup-body {
                            height: 15.5rem;
                            width: 100%;
                            position: relative;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            padding-top: 0.5rem;

                            .right-triangle {
                                position: absolute;
                                top: 6.75rem;
                                left: 100%;
                                width: 0;
                                height: 0;
                                border-top: 0.5rem solid transparent;
                                border-bottom: 0.5rem solid transparent;
                                border-left: 0.5rem solid $white;
                            }
                            .rpm-provider-record-header-alert-popup-body-item {
                                min-height: 3rem;
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                width: 100%;
                                padding: 0 1rem;
                                border-bottom: 1px solid #f0ece8;
                                .rpm-provider-record-header-alert-popup-body-item-left {
                                    display: flex;
                                    align-items: center;
                                    gap: 0.5rem;
                                    justify-content: flex-start;
                                    p {
                                        font-size: $font-size-small;
                                        font-weight: 400;
                                        color: $color-dark;
                                    }
                                }
                                .rpm-provider-record-header-alert-popup-body-item-right {
                                    display: flex;
                                    align-items: center;
                                    gap: 0.5rem;
                                    justify-content: flex-end;
                                    .rpm-provider-record-header-alert-popup-body-item-right-text {
                                        padding: 0.5rem;
                                        width: 2rem;
                                        height: 1.5rem;
                                        display: flex;
                                        align-items: center;
                                        background: rgba(232, 116, 79, 0.27);
                                        border-radius: 0.25rem;
                                        p {
                                            font-size: 0.75rem;
                                            font-weight: 600;
                                            color: #e8744f;
                                        }
                                    }
                                    .rpm-provider-record-header-alert-popup-body-item-right-date {
                                        display: flex;
                                        flex-direction: column;
                                        align-items: flex-start;
                                        p {
                                            color: #908e87;
                                            font-weight: 400;
                                            font-size: $font-size-smallest;
                                        }
                                    }
                                }
                            }
                            .rpm-provider-record-header-alert-popup-body-item-view-profile {
                                text-decoration: underline;
                                font-size: 0.75rem;
                                cursor: pointer;
                                color: $color-dark;
                                font-weight: 600;
                                margin-top: 1rem;
                            }
                        }
                    }
                }
            }

            .rpm-provider-record-grid {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr 1fr;
                grid-template-rows: 1fr;
                width: 100%;
                gap: 1px;
                background-color: #d7d7d7;
                border-radius: 0 0 1rem 1rem;
                overflow: hidden;
            }
        }

        .rpm-provider-mobile-records {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            width: 100%;
            gap: 1.25rem;
            padding-bottom: 3rem;
            @include media-breakpoint-down(sm) {
                padding: 0;
                overflow-y: auto;
                padding-top: 1rem;
            }
            .rpm-provider-mobile-record {
                width: 85%;
                margin: 0 auto;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;
                border-radius: 0.625rem;
                box-shadow: 0px 4px 20px 1px rgba(0, 0, 0, 0.1);

                &__alert-header {
                    height: 2.5rem;
                    width: 100%;
                    background-color: hsl(15, 77%, 61%);
                    padding: 0 1.5rem;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    border-top-left-radius: 0.625rem;
                    border-top-right-radius: 0.625rem;
                    &__left {
                        display: flex;
                        align-items: center;
                        gap: 1rem;
                        &__alert-icon-container {
                            position: relative;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            &__square {
                                width: 1.11125rem;
                                height: 1.11125rem;
                                aspect-ratio: 1/1;
                                background-color: #f9dad0;
                                border-radius: 0.1875rem;
                                transform: rotate(45deg);
                            }
                            &__icon {
                                color: #e8744f;
                                font-size: $font-size-smallest;
                                font-weight: 800;
                                z-index: 10;
                                position: absolute;
                            }
                        }
                        &__alert-text {
                            font-size: 0.875rem;
                            font-weight: 600;
                            color: $white;
                        }
                    }
                    &__right {
                        font-size: 0.75rem;
                        font-weight: 600;
                        color: $white;
                    }

                    &__no-alerts {
                        color: rgba(89, 89, 89, 0.5);
                        font-size: 0.625rem;
                        font-weight: 600;
                    }
                }
                &__alert-body {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    padding: 0.5rem;
                    width: 100%;
                    gap: 0.5rem;
                    background-color: $white;
                    border-bottom-left-radius: 0.625rem;
                    border-bottom-right-radius: 0.625rem;
                    &__header {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        width: 100%;
                        padding: 0;
                        &__profile-container {
                            display: flex;
                            align-items: center;
                            gap: 1rem;
                            width: 100%;

                            &__profile-image {
                                width: 3rem;
                                height: 3rem;
                                border-radius: 0.5rem 0.5rem 0 0.5rem;
                                border: 2px solid rgba(240, 236, 232, 0.4);
                                background-color: #d6d6d6;
                                aspect-ratio: 1/1;
                                position: relative;
                                img {
                                    width: 100%;
                                    height: 100%;
                                    object-fit: cover;
                                    border-radius: 0.5rem 0.5rem 0 0.5rem;
                                }
                                &__initials {
                                    width: 100%;
                                    height: 100%;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    font-size: 1rem;
                                    font-weight: 500;
                                    color: $white;
                                    position: absolute;
                                    top: 50%;
                                    left: 50%;
                                    transform: translate(-50%, -50%);
                                }
                            }
                            &__details {
                                display: flex;
                                flex-direction: column;
                                align-items: flex-start;
                                justify-content: center;
                                width: 100%;
                                &__tag {
                                    font-size: 0.625rem;
                                    font-weight: 600;
                                    color: #e8744f;
                                }
                                &__name {
                                    font-size: 1rem;
                                    font-weight: 500;
                                    color: $color-dark;
                                }
                                &__diagnosis {
                                    font-size: 0.625rem;
                                    font-weight: 500;
                                    color: #595959;
                                }
                            }
                        }
                        &__status-container {
                            min-width: 3.8125rem;
                            width: max-content;
                            border: 1px solid #88a088;
                            border-radius: 7.8125rem;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            height: 1.5rem;
                            gap: 0.25rem;
                            padding: 0 0.5rem;
                            &__dot {
                                height: 5px;
                                width: 5px;
                                border-radius: 50%;
                                aspect-ratio: 1/1;
                                background-color: #88a088;
                            }
                            &__text {
                                font-size: 0.625rem;
                                font-weight: 500;
                                color: #88a088;
                                // do not wrap text
                                white-space: nowrap;
                            }
                        }
                    }
                    &__coach-container {
                        width: 100%;
                        height: 1.875rem;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        background-color: #f7f5f3;
                        padding: 0 0.5rem;
                        border-radius: 0.25rem;
                        &__title {
                            font-size: 0.75rem;
                            font-weight: 500;
                            color: #595959;
                        }
                        &__button {
                            outline: none;
                        }
                    }
                    &__measurements-container {
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        padding: 0 0.5rem;
                        align-items: center;
                        &__item {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            width: 100%;
                            height: 2.5rem;
                            border-bottom: 1px solid #f0f0f0;
                            &__label {
                                font-size: 0.625rem;
                                font-weight: 600;
                                width: 50%;
                            }
                            &__value {
                                font-size: 0.625rem;
                                font-weight: 400;
                                width: 50%;
                            }

                            &__icon-wrapper {
                                width: 1.5rem;
                                height: 1.5rem;
                                aspect-ratio: 1/1;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                            }
                        }
                    }
                }
            }
        }
    }
}
