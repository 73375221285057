@import '../../styles/mixins';

.rpm-coach-dashboard {
    .rpm-coach-container-layout {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-areas: 'container container calls';
        width: 100%;
        max-width: 1140px;
        margin: 0 auto;
        gap: 1rem;
        margin-top: 1rem;


        .rpm-coach-container {
            margin: 0 auto;
            margin-bottom: 1rem;
            min-height: calc(100vh - 7rem);
            height: min-content;
            width: 100%;
            display: flex;
            flex-direction: column;
            overflow: hidden;
            background-color: $color-white;
            border-radius: 0.625rem;
            padding: 2rem;
            grid-area: container;
        }


        @include media-breakpoint-down(md) {
            grid-template-columns: 1fr;
            grid-template-areas:
                'calls'
                'container';
            margin-top: 0;
            gap: 0;
            .rpm-coach-container {
                background-color: $color-bg-white;
            }
        }
    }
}
