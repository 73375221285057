@import '../../styles/mixins';

.common-user-not-approved {
  @extend .mrm-pt-12;

  h2 {
    line-height: 1.5;
  }

  a {
    text-decoration: none;
    font-weight: normal;
    @extend .mrm-ml-0_5;
  }
}
