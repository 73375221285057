@import '../../styles/mixins';

.notifications-settings {
    .table-container {
        margin-top: 2.875rem;
        width: 95%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 auto;
        padding: 2.8125rem;
        table {
            width: 100%;
            background-color: $color-white;
            border-radius: 0.625rem;

            border-collapse: separate;
            border-spacing: 0 15px;

            th {
                color: $color-dark;
                font-weight: 600;
                font-size: 0.875rem;
                padding: 2.8125rem;
                padding-bottom: 0.5rem;
            }

            .type {
                width: 2.5rem;

                p {
                    color: #908e87;
                    font-size: 0.75rem;
                    text-align: center;
                    width: 100%;
                }
            }

            tbody {
                tr {
                    margin-top: 1rem;
                    font-size: 0.75rem;
                    font-weight: 300;
                }
                .switch {
                    margin-left: 2.85rem;
                }
            }

            tr {
                border-top: 1px solid #e5e5e5;
            }

            // last child padding bottom
            tr:last-child {
                td {
                    padding-bottom: 2.8125rem;
                }
            }
        }
    }
}
