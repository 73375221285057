@import '../../styles/mixins';

.rpm-provider-dashboard {
    &__add-staff-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: $color-white;
        max-width: 1140px;
        margin: 0 auto;
        margin-top: 1rem;
        border-radius: 0.625rem;
        min-height: 5.25rem;
        padding: 1rem 2rem;

        @include media-breakpoint-down(sm) {
            flex-direction: column;
            align-items: flex-start;
            margin-top: 0;
        }

        &__left {
            display: flex;
            align-items: center;
            gap: 1rem;

            &__logo {
                height: 3.5rem;
                width: 3.5rem;
                aspect-ratio: 1/1;
            }

            &__title {
                font-size: 1rem;
                font-weight: 500;
                color: $color-dark;
            }
            @include media-breakpoint-down(sm) {
                margin-bottom: 1rem;
            }
        }

        &__right {
            display: flex;
            align-items: center;
            gap: 1rem;
            justify-content: flex-end;

            @include media-breakpoint-down(sm) {
                flex-direction: column;
                align-items: flex-start;
            }

            &__button {
                background-color: $color-dark;
                min-width: 8.3125rem;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 0.5rem 1rem;
                border-radius: 0.3125rem;
                gap: 0.5rem;
                &__text {
                    color: $white;
                    font-size: 1rem;
                }
            }
        }
    }

    .rpm-provider-container {
        max-width: 1140px;
        margin: 0 auto;
        margin-top: 1rem;
        margin-bottom: 1rem;
        min-height: calc(100vh - 7rem);
        height: 100vh;
        width: 100%;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        background-color: $color-white;
        border-radius: 0.625rem;
        padding: 2rem;
        @include media-breakpoint-down(sm) {
            margin-top: -1rem;
            padding: 0;
            min-height: calc(100vh - 12rem);
            height: calc(100vh - 12rem);
        }
    }
}
