.auth-home-illustration-container {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  padding: 0.25rem;
  padding-right: 1.75rem;
  max-width: max-content;
  border-radius: 4.68rem;
  cursor: pointer;
  &:hover {
    transform: scale(1.01);
  }
  .roadmap-illustration {
    width: 3.56rem;
    height: 3.56rem;
    border-radius: 50%;
    object-fit: cover;
  }
  .text-container {
    display: flex;
    flex-direction: column;
    .main {
      font-size: $font-size-small;
      font-weight: 700;
      font-family: $serif-font-family;
    }
    p {
      color: $white;
      font-size: $font-size-smallest;
    }
  }

  @include media-breakpoint-down(sm) {
    margin: 1rem auto;
  }
}
