.bottom-sheet {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999999;
  background-color: $white;
  border-radius: 10px;
  box-shadow: 0px 20px 20px 1px rgba(0, 0, 0, 0.05);
  padding: 0.5rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    padding: 0 1.5rem;
  }
}
