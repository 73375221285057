.home-signup .signup-sidebar .signup_form .agreements-checkbox-wrapper {
  padding-bottom: 1rem;
  @include media-breakpoint-up(md) {
    grid-column: span 2 / auto;
  }
  ul {
    padding-left: 2.5rem;
    font-size: $font-size-smaller;
    color: $white;
    @include media-breakpoint-down(md) {
      color: $color-dark;
    }
    font-weight: 300;
    margin-top: -1rem;
    opacity: 0.85;

    a {
      color: $white;
      @include media-breakpoint-down(md) {
        color: $color-dark;
      }
      text-decoration: underline;
      font-weight: 300;
      opacity: 0.85;
    }
  }

  .agreements-checkbox {
    display: flex;
    align-items: center;
    gap: 1.5rem;
    width: 100%;
    margin-top: 0.5rem;
    input[type='checkbox'] {
      margin-left: 0.5rem;
      margin-top: 0.5rem;
      &::after {
        width: 1.25rem;
        height: 1.25rem;
      }
      &:checked::after {
        background-color: $color-text-lighter;
        @include media-breakpoint-down(md) {
          background-color: $color-dark;
        }
        border-color: $color-text-lighter;
      }
    }

    .agreement-text {
      width: 100%;
      padding: 0;
      font-size: $font-size-smaller;
      color: $white;
      @include media-breakpoint-down(md) {
        color: $color-dark;
      }
      font-weight: 500;
    }
    a {
      @include media-breakpoint-down(md) {
        color: $color-dark;
      }
      color: $white;
      text-decoration: underline;
      font-weight: 300;
    }
  }
}
