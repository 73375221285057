@import '../../styles/mixins';

.common-search-bar {
  position: relative;

  .form-control {
    background-color: $color-white;
    border-radius: 0.7rem;
    &::placeholder {
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
    }
    &:focus {
      border: 1px solid #eaeef5;
    }
  }

  .search-icon {
    position: absolute;
    left: 0.8rem;
    top: 0.85rem;
    font-size: $font-size-small;
    color: $color-dark;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      vertical-align: middle;
    }
  }
}
