@import '../../styles/mixins';

.rpm-dashboard {
  --rotation-rpm: 0deg;

  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  overflow-x: hidden;
  .rpm-dashboard-grid {
    display: grid;
    grid-template-areas:
      'roadmaps roadmaps stats-sidebar'
      'check-in upcoming-growth-sessions stats-sidebar'
      'my-team upcoming-growth-sessions stats-sidebar';
    gap: 1rem;
    margin: 1rem auto;
    width: 87.5%;

    @include media-breakpoint-down(md) {
      width: 100%;
      grid-template-areas:
        'mobile-records'
        'roadmaps'
        'check-in'
        'my-team'
        'upcoming-growth-sessions'
        'stats-sidebar';
      margin-top: 0;
      padding-bottom: 4rem;
    }
  }
}

html {
  font-size: 14px;
}
