.rpm-coach-dashboard {
    .rpm-coach-container-layout {
        .rpm-coach-container {
            .rpm-provider-mobile-records {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;
                width: 100%;
                gap: 1.25rem;
                padding-bottom: 3rem;
                .rpm-provider-mobile-record {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: flex-start;
                    border-radius: 0.625rem;
                    box-shadow: 0px 4px 20px 1px rgba(0, 0, 0, 0.1);
                    background-color: $white;
                    &__alert-header {
                        height: 2.5rem;
                        width: 100%;
                        background-color: hsl(15, 77%, 61%);
                        padding: 0 1.5rem;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        border-top-left-radius: 0.625rem;
                        border-top-right-radius: 0.625rem;
                        &__left {
                            display: flex;
                            align-items: center;
                            gap: 1rem;
                            &__alert-icon-container {
                                position: relative;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                &__square {
                                    width: 1.11125rem;
                                    height: 1.11125rem;
                                    aspect-ratio: 1/1;
                                    background-color: #f9dad0;
                                    border-radius: 0.1875rem;
                                    transform: rotate(45deg);
                                }
                                &__icon {
                                    color: #e8744f;
                                    font-size: $font-size-smallest;
                                    font-weight: 800;
                                    z-index: 10;
                                    position: absolute;
                                }
                            }
                            &__alert-text {
                                font-size: 0.875rem;
                                font-weight: 600;
                                color: $white;
                            }
                        }
                        &__right {
                            font-size: 0.75rem;
                            font-weight: 600;
                            color: $white;
                        }

                        &__no-alerts {
                            color: rgba(89, 89, 89, 0.5);
                            font-size: 0.625rem;
                            font-weight: 600;
                        }
                    }
                    &__alert-body {
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        padding: 0.5rem;
                        width: 100%;
                        gap: 0.5rem;
                        background-color: $white;
                        border-bottom-left-radius: 0.625rem;
                        border-bottom-right-radius: 0.625rem;
                        &__header {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            width: 100%;
                            padding: 0;
                            &__profile-container {
                                display: flex;
                                align-items: center;
                                gap: 1rem;
                                width: 100%;

                                &__profile-image {
                                    width: 3rem;
                                    height: 3rem;
                                    border-radius: 0.5rem 0.5rem 0 0.5rem;
                                    border: 2px solid rgba(240, 236, 232, 0.4);
                                    background-color: #d6d6d6;
                                    aspect-ratio: 1/1;
                                    position: relative;
                                    img {
                                        width: 100%;
                                        height: 100%;
                                        object-fit: cover;
                                        border-radius: 0.5rem 0.5rem 0 0.5rem;
                                    }
                                    &__initials {
                                        width: 100%;
                                        height: 100%;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        font-size: 1rem;
                                        font-weight: 500;
                                        color: $white;
                                        position: absolute;
                                        top: 50%;
                                        left: 50%;
                                        transform: translate(-50%, -50%);
                                    }
                                }
                                &__details {
                                    display: flex;
                                    flex-direction: column;
                                    align-items: flex-start;
                                    justify-content: center;
                                    width: 100%;
                                    &__tag {
                                        font-size: 0.625rem;
                                        font-weight: 600;
                                        color: #e8744f;
                                    }
                                    &__name {
                                        font-size: 1rem;
                                        font-weight: 500;
                                        color: $color-dark;
                                    }
                                    &__diagnosis {
                                        font-size: 0.625rem;
                                        font-weight: 500;
                                        color: #595959;
                                    }
                                }
                            }
                            &__status-container {
                                min-width: 3.8125rem;
                                width: max-content;
                                border: 1px solid #88a088;
                                border-radius: 7.8125rem;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                height: 1.5rem;
                                gap: 0.25rem;
                                padding: 0 0.5rem;
                                &__dot {
                                    height: 5px;
                                    width: 5px;
                                    border-radius: 50%;
                                    aspect-ratio: 1/1;
                                    background-color: #88a088;
                                }
                                &__text {
                                    font-size: 0.625rem;
                                    font-weight: 500;
                                    color: #88a088;
                                    // do not wrap text
                                    white-space: nowrap;
                                }
                            }
                        }
                        &__coach-container {
                            width: 100%;
                            height: 1.875rem;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            background-color: #f7f5f3;
                            padding: 0 0.5rem;
                            border-radius: 0.25rem;
                            &__title {
                                font-size: 0.75rem;
                                font-weight: 500;
                                color: #595959;
                            }
                        }
                        &__measurements-container {
                            display: flex;
                            flex-direction: column;
                            width: 100%;
                            padding: 0 0.5rem;
                            align-items: center;
                            &__item {
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                width: 100%;
                                height: 2.5rem;
                                border-bottom: 1px solid #f0f0f0;
                                &__label {
                                    font-size: 0.625rem;
                                    font-weight: 600;
                                    width: 50%;
                                }
                                &__value {
                                    font-size: 0.625rem;
                                    font-weight: 400;
                                    width: 50%;
                                }
                            }
                        }
                    }

                    .rpm-provider-mobile-record-footer {
                        background-color: #f8f5f5;
                        width: 90%;
                        margin: 1rem auto;
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        justify-content: center;
                        padding: 1rem;
                        border-radius: 0.25rem;
                        gap: .5rem;

                        p {
                            font-size: 0.625rem;
                            font-weight: 400;
                            color: #595959;
                        }
                        strong {
                            font-size: 0.625rem;
                            font-weight: 600;
                            color: #555555;
                        }

                        .rpm-provider-record-footer-progress {
                            display: flex;
                            align-items: center;
                            justify-content: flex-end;
                            gap: 0.5rem;
                            .rpm-provider-record-footer-progress-bar {
                                width: 5.5rem;
                                height: 0.375rem;
                                background-color: #e5e5e5;
                                border-radius: 4rem;
                                position: relative;
                                .rpm-provider-record-footer-progress-bar-fill {
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    height: 100%;
                                    background-color: #88a088;
                                    border-radius: 4rem;
                                }
                            }
                            p {
                                font-weight: 600;
                                color: #555555;
                            }
                        }
                    }
                }
            }
        }
    }
}
