@import '../../styles/mixins';

.rpm-events {
  &__header {
    display: flex;
    width: 80%;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    margin-top: 2rem;

    select {
      height: 40px;
      border-radius: 0.625rem;
      border: 1px solid $color-dark;
      outline: none;
      padding: 0 1rem;
      font-size: 0.75rem;
     
    }
  }
  .greminders__booking-widget {
    display: block !important;

    height: 100vh !important;

    .greminders__booking-widget-overlay {
      position: relative !important;
      display: flex !important;
      flex-direction: column !important;
      justify-content: flex-start !important;
      padding-top: 3rem !important;
      @include media-breakpoint-down(md) {
        padding-top: 0 !important;
      }
    }
  }
  position: relative;
  button {
    display: flex;
    align-items: center;
    background-color: $color-dark;
    height: 40px;
    padding: 0 1rem;
    border-radius: 0.625rem;
    outline: none;
    @include media-breakpoint-down(md) {
      display: none;
    }
    p {
      margin-left: 0.6rem;
      color: $white;
      font-size: 0.75rem;
    }
  }
}
