@import '../../styles/mixins';

@import '../../styles/mixins';

.auth-forgot-password {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  margin: 0 auto;
  overflow: hidden;
  .auth-forgot-password-navbar {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $white;
    padding: 1.25rem 0;
    img {
      width: 7.125rem;
      object-fit: contain;
    }
    @include media-breakpoint-down(sm) {
      z-index: 1;
    }
  }
  .auth-forgot-password-content {
    position: relative;
    background-size: cover;
    object-fit: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    place-content: center;
    padding-left: 15vw;

    video,
    .background-image {
      position: absolute;
      top: 0;
      z-index: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      background-position: contain;
      transform: scaleX(-1);
      @include media-breakpoint-down(sm) {
        filter: blur(6px);
      }
    }

    .background-image {
      transform: scaleX(1);
    }

    form {
      z-index: 1;
      .auth-forgot-password-form {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        max-width: 31.25rem;

        padding: 2rem 10%;
        background-color: $white;
        border-radius: 1.2rem;
        h1 {
          font-size: 2rem;
          font-weight: 700;
          margin-bottom: 1.5rem;
        }

        .auth-forgot-password-form-header {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-bottom: 1rem;
          h1,
          p {
            text-align: center;
          }
          p {
            margin-top: -0.5rem;
          }
        }
        @include media-breakpoint-down(sm) {
          width: 85vw;
          h1 {
            font-size: $font-size-large;
          }
        }
        .auth-forgot-password-form-body {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;
          gap: 1rem;
          .auth-forgot-password-form-body-input {
            width: 100%;
            .auth-forgot-password-input-wrapper {
              width: 100%;
              display: flex;
              flex-direction: column;
              align-items: center;
              position: relative;
              input {
                width: 100%;
                height: 2.56rem;
                padding: 0.5rem;
                border: none;
                border-radius: 0.5rem;
                font-size: 14px;
                font-weight: 400;
                outline: none;
                background-color: #f8f8f8;
                padding-left: 3.5rem;
                &::placeholder {
                  font-size: 14px;
                  font-weight: 400;
                }
              }
              svg {
                position: absolute;
                top: 0.8rem;
                left: 1rem;
              }
              .lock {
                top: 0.65rem;
              }
            }
          }

          .forgot-password {
            color: $color-dark-green;
            font-weight: 600;
            text-decoration: underline;
            font-size: $font-size-small;
            width: 100%;
            text-align: center;
          }
        }
      }

      .error-message {
        color: $color-red;
        font-size: $font-size-smallest;
        margin: 0.5rem 0;
        font-weight: 700;
      }

      .i-remember {
        text-decoration: underline;
        font-size: $font-size-small;
        font-weight: 700;
        cursor: pointer;
        color: $color-dark-green;
        margin-top: 0.5rem;
      }
    }
    button,
    .success {
      margin-top: 1rem;
      width: 100%;
      color: $white;
      padding: 0.5rem 0;
      margin: 0 auto;
      max-height: max-content;
      border-radius: 0.4rem;
      grid-column: span 2 / auto;
      font-weight: 700;
    }
  }
}

@include media-breakpoint-down(sm) {
  .auth-forgot-password {
    .auth-forgot-password-content {
      grid-template-columns: 1fr;
      place-items: center;
      place-content: start;
      padding-left: 0;
      padding-top: 15vh;
      height: 100vh;
      form {
        .auth-forgot-password-form {
          padding: 1.5rem 2rem;
          .auth-forgot-password-form-header {
            h1 {
              margin-bottom: 0.5rem;
            }
          }
        }
        button {
          font-size: 0.85rem;
        }
      }
    }
  }
}
