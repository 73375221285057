@import '../../styles/mixins';

.auth-caregiver-default {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background-color: #f5f5f5;

  .auth-caregiver-default-navbar {
    display: flex;
    align-items: center;
    width: 85%;
    justify-content: space-between;
    padding: 1.2rem 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    img {
      width: 11.75rem;
      object-fit: contain;
    }
    .login-and-cta {
      display: flex;
      align-items: center;
      gap: 3rem;
      .cta {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        p {
          font-size: $font-size-smaller;
        }
        .schedule {
          text-decoration: underline;
        }
      }
    }
  }

  .auth-caregiver-default-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    .left-side {
      width: 90%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 4rem;
      padding-left: 7.5%;
      .title-content {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        h1 {
          font-size: 2rem;
          font-weight: 700;
          width: 75%;
        }
        p {
          font-size: $font-size-small;
          font-weight: 300;
          width: 75%;
        }
      }

      .cta-content {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
      }
    }
    .right-side {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      position: relative;
      width: 100%;
      height: 100%;
      img {
        width: 120%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: 10;
      }
    }
  }

  .login-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: $font-size-small;
    font-weight: 700;
    width: 11.75rem;
    padding: 0.75rem 0;
    border-radius: 0.5rem;
  }
}

@include media-breakpoint-down(sm) {
  .auth-caregiver-default {
    overflow-y: auto;
    .auth-caregiver-default-navbar {
      align-items: flex-start;
      .login-and-cta {
        flex-direction: column-reverse;
        gap: 0.5rem;
        align-items: flex-end;
        .cta {
          align-items: flex-end;
          p {
            text-align: right;
            font-size: 0.5rem;
          }
        }
      }
    }
    .auth-caregiver-default-content {
      flex-direction: column;
      padding-top: 2rem;
      .left-side {
        padding: 0;
        gap: 2.5rem;
        .title-content {
          align-items: center;
          h1,
          p {
            text-align: center;
          }

          h1 {
            font-size: $font-size-largest;
          }
        }
        .cta-content {
          width: 100%;
          align-items: center;
          .login-btn {
            padding: 1rem;
            width: 50vw;
            max-width: 50vw;
          }
        }
      }
      .right-side {
        align-items: center;
        gap: 2rem;
        padding-bottom: 1rem;
        img {
          position: relative;
          object-fit: contain;
          width: 100%;
        }
      }
    }
    .login-btn {
      padding: 0.5rem 1.5rem;
      max-width: max-content;
      align-items: center;
      p {
        text-align: center;
      }
    }
  }
}
