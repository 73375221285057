@import '../../styles/mixins';

.subscriptions-contracts {
  &.wrapper {
    @extend .mrm-pt-4;
    @extend .mrm-px-1;
    display: grid;
    gap: 1.8rem;
    grid-template-areas:
      'sidebar'
      'content';
  }
}

@include media-breakpoint-up(lg) {
  .subscriptions-contracts {
    &.wrapper {
      grid-template-columns: 1fr 3fr;
      grid-template-areas: 'sidebar content';
    }
  }
}
