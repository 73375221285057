// Google Font Imports

// TODO: Review this later to import only necessary weights !!
// @import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,600;0,700;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

// Fonts

$serif-font-family: var(--serif-font-family);
$sans-serif-font-family: var(--sans-serif-font-family);

// Headings

h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
::placeholder {
  font-family: $sans-serif-font-family;
}

::placeholder {
  font-size: 1rem;
}

h2 {
  font-size: 1.125rem;
  font-weight: bold;
}

h3 {
  font-size: 1.1rem;
}

a {
  font-weight: bold;
  text-decoration: underline;
}

a {
  text-decoration: none;

  &:hover {
    opacity: 0.7;
    text-decoration: none;
  }

  &.no-format {
    color: inherit;
    font-weight: inherit;
    &:hover {
      opacity: 1;
    }
  }
}
