// Colors variables

$color-assessment-yellow: #f5d844;
$color-assessment-red: #f1561e;
$color-assessment-green: #9da16b;
$color-assessment-none: #eeeeee;

$color-unread-red: red;
$color-unread-blue: #2f80ed;

$color-blue: #2f80ed;

$color-item-purple: #a768b7;
$color-item-lightpurple: #d0e0ff;
$color-item-blue: #2f80ed;
$color-item-yellow: #edc342;
$color-item-green: #23d37b;
$color-item-gray: #cccccc;

$color-text-normal: #3e3e3e;
$color-text-light: #727272;
$color-text-lighter: #8d8d8d;
$color-text-disabled: #aaaaaa;
$color-text-danger: #ff3b30;

$color-dark-text-normal: #bdc0c3;
$color-dark-text-dark: #e3e3e3;
$color-dark-text-light: #8d8d8d;
$color-dark-text-lighter: #727272;
$color-dark-text-disabled: #515151;

$color-background-light: #f6f6f6;
$color-background-dark: #343434;
$color-background-light-gray: #f4f4f5;

$color-input-dark-blue: #f1f2f3;

// Pure Colors

// Default MRM Colors

$color-text-black: #343434;
$color-background-dark-gray: #6b6b6b;

$black: var(--pure-black);
$white: var(--pure-white);
$color-white: var(--primary-white);
$color-dark: var(--primary-dark);
$color-bg-white: var(--primary-bg-white);
$color-orange: var(--primary-orange);
$color-gray: var(--primary-gray);
$color-light-gray: var(--primary-light-gray);

$color-green: var(--primary-green);
$color-dark-green: var(--primary-dark-green);
$color-light-green: var(--primary-light-green);

$color-red: var(--primary-red);
$color-alerts: var(--primary-alerts);
$color-gray-lighter: var(--primary-gray-lighter);
$color-gray-lightest: var(--primary-gray-lightest);
$color-bg-gray: var(--primary-bg-gray);
