.filter-card {
  display: flex;
  align-items: center;
  gap: 1rem;
  width: 100%;
  h2 {
    font-weight: 500;
  }

  .input-container {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 1rem;
    .form-group {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      label {
        font-weight: 300;
        margin-bottom: 0.5rem;
        font-size: $font-size-small;
      }
      .react-datepicker-wrapper {
        width: 100%;
        input {
          font-size: $font-size-normal;
          color: $color-dark;
          height: 57px;
          border-radius: 0.5rem;
        }
      }
    }
    input {
      width: 100%;
      padding-left: 1rem;
    }
  }
}
