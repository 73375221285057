.support-sidebar-body-step {
  display: flex;
  align-items: center;
  gap: 0.5rem;

  .step-number {
    border-radius: 50%;
    width: 1.5rem;
    height: 1.5rem;
    background-color: $color-white;
    display: flex;
    justify-content: center;
    align-items: center;
    p {
      color: $color-dark;
      font-size: $font-size-small;
    }
    &.done {
      background-color: $color-light-green;
    }
    &.inactive {
      background-color: $color-bg-gray;
    }
  }
  .step-description {
    color: $color-white;
    font-size: $font-size-smaller;
    position: relative;
    font-weight: 300;

    &.active {
      // this is the custom underline
      // for the active step description
      font-weight: 600;
      &:before {
        content: '';
        position: absolute;
        width: 4.375rem;
        height: 4px;
        bottom: 0;
        top: 1.3rem;
        border-bottom: 1px solid $color-white;
      }
    }
  }
}
