@import '../../styles/mixins';
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;1,300;1,500;1,600;1,700&display=swap');

// colors
$background: #f0ece8;
$dark: #343434;

// default for all text
p {
  font-family: $sans-serif-font-family;
  color: $dark;
  p {
    margin: 0;
  }
}

.dashboard-subscriptions {
  background-color: $background;
  width: 100%;
  margin: 0 auto;
  min-height: 100%;
  padding-bottom: 5rem;
  overflow: hidden;

  .menu-item-container-subs {
    padding: 0 0.5rem;
    margin-right: -0.5rem;
  }

  .tab {
    padding: 1.5rem 2rem 0 2rem;
  }

  .header {
    margin-bottom: 2rem;
    font-weight: normal;
  }

  .main-content {
    display: flex;
    flex-direction: column;
    border-radius: 0.8rem;
    margin: 0 auto;
    padding: 0 2rem;
    .save {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.5rem;
      background-color: $color-dark;
      color: $color-white;
      border-radius: 0.4rem;
      font-weight: 500;
      font-size: $font-size-normal;
    }
    .main-header {
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: center;
      p {
        margin: 0;
      }

      .edit-subscription {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        gap: 0.5rem;
      }
    }

    .add-sub {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.6rem;
      gap: 0.5rem;
      background-color: $color-dark;
      color: $white;
      font-size: $font-size-smallest;
      border-radius: 0.4rem;
      font-weight: 500;
      .icon-text {
        display: flex;
        align-items: center;
        width: 100%;
        justify-items: center;
        gap: 0.5rem;
      }
    }
    .subscription-details {
      .real-labels {
        font-size: $font-size-small;
        color: $color-dark;
      }
      label {
        font-size: $font-size-smaller;
        padding: 0.3rem 0;
      }
      form {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        width: 100%;
        padding-bottom: 2rem;
        .sub-name {
          margin-top: -0.5rem;
        }
        input {
          padding-left: 1rem;
          border-radius: 0.5rem;
          border: 1px solid #d3d3d2;
        }
        .month-label {
          position: relative;
          right: 0;
          margin-bottom: -3.35rem;
          padding-right: 1rem;
          display: flex;
          align-items: center;
          justify-content: flex-end;
        }
        .price-label {
          position: relative;
          left: 0;
          margin-top: -3.4rem;
          padding-left: 1rem;
          display: flex;
          align-items: center;
          justify-content: flex-start;
        }
        .price-input {
          z-index: 10;
          background: transparent;
          padding-left: 2rem;
        }
      }

      max-width: 100%;
      padding-top: 1rem;
      display: grid;
    }

    .subscriptions-table {
      display: inline-block;
      width: 100%;
      background-color: transparent;
      border-radius: 0.6rem;
      margin-top: 1.5rem;
      overflow-x: scroll;

      .name {
        width: 75%;
      }

      td {
        vertical-align: center;
        padding: 1rem 2rem 1rem 0;
        .dots-settings {
          outline: none;
        }
        .dropdown-menu.show {
          border-radius: 0.6rem;
          z-index: 99999;
          button {
            display: block;
            width: 100%;
            padding: 0.25rem 1.5rem;
            clear: both;
            font-weight: 400;
            color: #212529;
            text-align: inherit;
            white-space: nowrap;
            background-color: transparent;
            border: 0;
          }
        }
      }
      th {
        padding: 1rem 0rem;
        font-weight: 300;
        font-size: 0.8rem;
        color: rgba(52, 52, 52, 0.5);
      }

      tr {
        border-bottom: 1px solid rgba(52, 52, 52, 0.1);
        width: 100%;
        vertical-align: center;
      }
      p {
        font-size: 0.8rem;
        font-weight: 300;
      }
      .date {
        white-space: nowrap;
      }
      img {
        width: 2.5rem;
        border-radius: 100rem;
        margin: 0;
      }
      .last {
        padding: 0rem;
        max-width: min-content;
        width: 2rem;
        margin: 0;
      }

      .user {
        display: inline-flex;
        align-items: center;
        gap: 0.5rem;

        .title {
          h3 {
            margin: 0;
            font-weight: 300;
            font-size: 0.9rem;
            min-width: full;
          }
        }
      }
      .tag {
        padding: 0.4rem;
        border-radius: 16px;
        max-width: min-content;
        background: $color-gray-lightest;
        font-size: 0.625rem;
      }
      .subscribed {
        background: rgba(136, 160, 136, 0.4);
      }
      .unsubscribed {
        background: $color-gray-lightest;
      }
      .pending {
        background: #f2f2f2;
        color: #a1a1a1;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .dashboard-subscriptions {
    background-color: $background;
    width: 69rem;
    margin: 0 auto;
    overflow: hidden;
    display: flex;
    gap: 5rem;
    max-height: 100vh;
    overflow-y: scroll;
    align-items: flex-start;
    height: 90vh;

    padding-bottom: 0;

    .main-content {
      width: 100%;
      background-color: $color-white;
      padding: 2rem;
      border-radius: 0.8rem;
      margin-top: 5vh;
      overflow-y: auto;
      height: fit-content;

      .main-content-inside {
        background-color: white;
        padding: 1.5rem;
        border-radius: 0.6rem;
      }

      .main-header {
        flex-direction: row;
        justify-content: space-between;
      }
      .subscriptions-table {
        background-color: $white;
        box-shadow: 1px 1px 20px 1px rgba(0, 0, 0, 0.05);
        th {
          padding: 1rem 2rem;
          font-weight: 300;
          font-size: 0.8rem;
          color: rgba(52, 52, 52, 0.5);
        }
        td {
          padding: 1rem 2rem;
        }
      }

      .subscription-details {
        label {
          font-size: $font-size-smaller;
          padding: 0.3rem 0;
        }
        form {
          display: flex;
          flex-direction: column;
          gap: 1rem;
          width: 100%;
          padding-bottom: 2rem;

          input {
            padding-left: 1rem;
            border-radius: 0.5rem;
            border: 1px solid #d3d3d2;
            max-width: 65%;
          }
          .month-label {
            position: relative;
            right: 0;
            width: 35%;
            margin-bottom: -3.35rem;
            padding-right: 1rem;
            display: flex;
            align-items: center;
            justify-content: flex-end;
          }
          .price-label {
            position: relative;
            left: 0;
            width: 50%;
            margin-top: -3.4rem;
            padding-left: 1rem;
            display: flex;
            align-items: center;
            justify-content: flex-start;
          }
          .price-input {
            max-width: 35%;
            z-index: 10;
            background: transparent;
            padding-left: 2rem;
          }
        }

        max-width: 50%;
        padding-top: 1rem;
        display: grid;
      }
    }
  }
}

.dashboard-create-subscription {
  background-color: $color-white;
  border-radius: 0.8rem;

  .main-content-inside {
    padding: 1.5rem;
    background-color: white;
    border-radius: 1.2rem;
    height: 100vh;

    .main-header {
      display: grid;
      justify-items: flex-start;
      width: 100%;
      align-content: flex-start;
      gap: 0.5rem;
      p {
        margin: 0;
      }

      .create {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;
      }

      .save-sub {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.6rem;
        gap: 0.5rem;
        background-color: $color-dark;
        color: $white;
        font-size: $font-size-smallest;
        border-radius: 0.4rem;
      }
    }
  }

  .subscriptions {
    padding-top: 1.5rem;

    .subscription-details {
      label {
        font-size: $font-size-smaller;
        padding: 0.3rem 0;
      }
      input {
        border: 1px solid $color-gray-lightest;
        border-radius: 0.5rem;
        padding: 0.6rem;
        margin-bottom: 0.65rem;
        font-size: $font-size-small;
        &::placeholder {
          font-size: $font-size-small;
        }
      }
      max-width: 100%;
      padding-top: 1rem;
      display: grid;

      form {
        display: flex;
        flex-direction: column;
      }
    }
    .save-sub {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1rem;
      gap: 0.5rem;
      background-color: $color-dark;
      color: $white;
      font-size: $font-size-normal;
      border-radius: 0.8rem;
      width: 100%;
      margin-top: 1rem;
    }
    .add {
      background-color: transparent;
      color: $color-dark;
      border: 1px solid $color-dark;
    }
  }
}

@include media-breakpoint-up(lg) {
  .dashboard-create-subscription {
    width: 65%;
    margin-top: 2.7rem;
    padding: 2rem;
    .main-content-inside {
      height: auto;
      .main-header {
        display: flex;
        justify-content: space-between;
        align-content: center;
        flex-direction: row;
      }
    }
    .subscriptions {
      .subscription-details {
        max-width: 35%;
        padding-top: 1rem;
        display: grid;
      }
    }
  }
}
