.carousel-container {
  button {
    outline: none;
  }
  width: 100%;
  display: flex;
  flex-direction: column;
  .buttons-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    gap: 1rem;
    padding-bottom: 1rem;
    .right {
      // rotate right arrow
      transform: scaleX(-1);
    }
  }
}
.roadmap-parent-container {
  padding: 0 0.2rem;
  .roadmap-container {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 1rem;
    height: 15.5rem;
    position: relative;
    border-radius: 0.6rem;
    cursor: pointer;
  }
  .roadmap-title {
    position: absolute;
    bottom: 0;
    padding-bottom: 1rem;
    h3 {
      color: $color-white;
      display: flex;
      flex-wrap: wrap;
      max-width: 80%;
      font-family: $serif-font-family;
      font-size: $font-size-normal;
    }
  }
}
.carousel-wrapper {
  display: flex;
  width: 100%;
  position: relative;
}

.carousel-content-wrapper {
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.carousel-content {
  display: flex;
  transition: all 250ms linear;
  -ms-overflow-style: none; /* hide scrollbar in IE and Edge */
  scrollbar-width: none; /* hide scrollbar in Firefox */
}

/* hide scrollbar in webkit browser */
.carousel-content::-webkit-scrollbar,
.carousel-content::-webkit-scrollbar {
  display: none;
}

.carousel-content > * {
  width: 100%;
  flex-shrink: 0;
  flex-grow: 1;
}
.carousel-content.show-2 > * {
  width: 50%;
}

.carousel-content.show-3 > * {
  width: calc(100% / 3);
}

.carousel-content.show-4 > * {
  width: calc(100% / 4);
}

.carousel-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.carousel-wrapper {
  display: flex;
  width: 100%;
  position: relative;
}

.carousel-content-wrapper {
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.carousel-content {
  display: flex;
  transition: all 250ms linear;
  -ms-overflow-style: none; /* hide scrollbar in IE and Edge */
  scrollbar-width: none; /* hide scrollbar in Firefox */
}

/* hide scrollbar in webkit browser */
.carousel-content::-webkit-scrollbar,
.carousel-content::-webkit-scrollbar {
  display: none;
}

.carousel-content > * {
  width: 100%;
  flex-shrink: 0;
  flex-grow: 1;
}

.carousel-content.show-2 > * {
  width: 50%;
}

.carousel-content.show-3 > * {
  width: calc(100% / 3);
}

.carousel-content.show-4 > * {
  width: calc(100% / 4);
}
