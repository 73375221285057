.add-response-modal {
  .modal-header {
    padding: 2rem;
  }

  @include media-breakpoint-down(sm) {
    .modal-content {
      position: fixed;
      bottom: 0;
      margin: 0 auto;
      width: 100%;
      margin-left: -0.5rem;
    }
  }
  .container {
    padding: 2rem;
    padding-top: 0;
    .form-control {
      border-radius: 0.7rem;
      padding: 1rem;
    }
  }
}
