@import '../../styles/mixins';
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;1,300;1,500;1,600;1,700&display=swap');

// colors
$background: #f0ece8;
$dark: #343434;

// default for all text
p {
  font-family: $sans-serif-font-family;
  color: $dark;
}

.dashboard-payouts {
  background-color: $background;
  width: 100%;
  height: 100%;
  overflow: hidden;
  .header {
    margin-bottom: 2.5rem;
    font-weight: normal;
  }

  .tab {
    padding: 1.5rem 2rem 0 2rem;
  }

  .banner {
    display: flex;
    flex-direction: column;
    background-color: #718371;
    padding: 2rem;
    border-radius: 0.8rem;
    margin: 0 auto;
    max-width: 85%;

    h1 {
      color: white;
      font-weight: 700;
      font-size: $font-size-largest;
      font-family: $serif-font-family;
    }

    p {
      color: white;
      font-size: 0.9rem;
      font-weight: 300;
    }

    button {
      margin-top: 2rem;
      background-color: #343434;
      color: white;
      display: inline-flex;
      align-items: center;
      border-radius: 0.8rem;
      gap: 1rem;
      height: 3.5rem;
      padding-right: 2.5rem;
      max-width: max-content;
      font-size: 0.9rem;

      &:hover {
        background-color: black;
      }
    }

    .button-icon {
      height: 100%;
      border-color: white;
      display: inline-flex;
      align-items: center;
      border-right: 1px solid #595959;
      padding: 0 1rem;
    }
    a {
      margin-top: 1rem;
      color: white;
      font-size: 0.9rem;
      font-weight: 300;
      cursor: pointer;
      text-decoration: underline;
      &:hover {
        text-decoration: underline;
        color: black;
      }
    }
  }
  .main-content {
    padding: 2rem;
    border-radius: 0.8rem;
    max-width: 100%;
    // Cards
    .card-grid {
      display: flex;
      flex-direction: column;
      justify-items: center;
      width: 100%;
      justify-content: space-between;
      gap: 1rem;

      .card {
        display: inline-flex;
        justify-items: center;
        align-items: center;
        flex-direction: row;
        flex-wrap: nowrap;
        width: 100%;
        background-color: white;
        padding: 1.2rem 1.8rem 1.2rem 1.8rem;
        border-radius: 0.8rem;
        .icon {
          margin-bottom: 1rem;
          color: #949393;
          width: 20%;
          display: inline-flex;
          justify-content: flex-start;
          align-items: center;
          height: 100%;
        }
        .title {
          width: 60%;
        }
        h2 {
          font-size: $font-size-large;
          font-weight: 500;
          color: #343433;
        }
        p {
          font-size: $font-size-smaller;
          margin: 0;
          font-weight: 300;
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .dashboard-payouts {
    padding: 3.5rem 0rem 3.5rem 5rem;

    .banner {
      margin-left: 25%;
      margin-top: 2.7rem;
      width: 65%;
      p {
        color: white;
        font-size: 0.9rem;
        font-weight: 300;
        padding-right: 16rem;
      }
    }
    .main-content {
      width: 65%;
      margin-left: 25%;
      margin-top: 2rem;
      min-height: 1000px;
      background-color: $color-white;
      .card-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        width: 100%;
        justify-content: space-between;
        gap: 1rem;
        margin-top: 1rem;
        .card {
          display: grid;
          justify-items: center;
          align-items: center;
          flex-direction: row;
          flex-wrap: nowrap;
          width: 100%;
          background-color: white;
          padding: 1.2rem 1.8rem 1.2rem 1.8rem;
          border-radius: 0.8rem;
          .icon {
            margin-bottom: 1rem;
            color: #949393;
            width: 100%;
          }
          .title {
            width: 100%;
          }
          h2 {
            font-size: $font-size-larger;
            font-weight: 500;
            color: #343433;
          }
          p {
            font-size: $font-size-normal;
            margin: 0;
            font-weight: 300;
          }
        }
      }
    }
  }
}
