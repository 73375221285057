.rpm-dashboard {
  .roadmaps-container {
    grid-area: roadmaps;
    background-color: $color-white;
    @include media-breakpoint-down(md) {
      background-color: transparent;
    }
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 1.5rem;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      .roadmaps-container-left {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 0.5rem;
        h3 {
          font-size: $font-size-normal;
          font-weight: 500;
        }
        p {
          font-size: $font-size-small;
          color: #515151;
          opacity: 0.7;
          font-weight: 400;
          @include media-breakpoint-down(sm) {
            font-size: $font-size-smallest;
          }
        }
      }
      .browse-all {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        cursor: pointer;
        p {
          font-size: $font-size-small;
        }
      }
    }

    .roadmaps-container-arrow {
      height: 2.5rem;
      width: 2.5rem;
      background-color: $white;
      border-radius: 10px;
      box-shadow: 0px 4px 20px 1px rgba(0, 0, 0, 0.1);
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      left: 0;
      top: 50%;
      z-index: 9999;
      outline: none;
    }

    .arrow-right {
      right: 0;
      left: unset;
    }

    .roadmaps-container-grid {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 1rem;
      margin-top: 1rem;
      .roadmap-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background-color: $white;
        height: 282px;
        border-radius: 10px;
        // max-width: 266px;
        @include media-breakpoint-down(sm) {
          min-width: 266px;
        }

        .roadmap-container-header {
          width: 100%;
          height: 171px;
          border-radius: 10px 10px 0 0;
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;
          display: flex;
          flex-direction: column;
        }
        .roadmap-container-footer {
          padding: 1rem;
          display: flex;
          flex-direction: column;
          justify-content: center;
          height: 111px;
          gap: 0.5rem;
          position: relative;
          .title {
            font-weight: 700;
            font-size: $font-size-normal;
            font-family: $serif-font-family;
            width: calc(100% - 2rem);
            margin-top: -0.75rem;
            // two lines and ellipsis
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            
          }
          .continue-btn {
            height: 40px;
            width: 40px;
            border-radius: 50%;
            background-color: $color-dark;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            right: 0.5rem;
            top: -20px;
            border: 2px solid rgba(255, 255, 255, 0.3);
          }
        }
      }
    }
    .roadmaps-container-steps {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;
      margin-top: 1rem;
      outline: none;
      .roadmaps-container-step {
        width: 14px;
        height: 3px;
        background-color: #d3d0cc;
        border-radius: 10px;
        outline: none;
      }
      @include media-breakpoint-up(md) {
        display: none;
      }
    }
  }
}
