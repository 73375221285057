.success-addition-modal {
    .modal-content {
        background-color: $white;
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: center;
        padding: 3rem 5rem;
        margin-top: 10vh;
    }
    &__title {
        font-weight: 600;
        font-size: 1rem;
        color: $color-dark;
        margin-top: 1.5rem;
    }

    &__description {
        font-weight: 300;
        font-size: 0.875rem;
        color: $color-dark;
        margin-top: 0.875rem;
    }

    &__close {
        position: absolute;
        top: 1rem;
        right: 1rem;
        cursor: pointer;
        outline: none;
    }
}
