@import '../../styles/mixins';

.manage-edit-roadmap-page {
  .stage-competency {
    @extend .mrm-py-0_5;
    @extend .mrm-px-0_75;
    @extend .mrm-mb-0_75;
    background-color: white;
    border-radius: 10px;
    align-items: flex-start;
    display: flex;
    color: $color-text-normal;
    font-weight: normal;
    flex-direction: column;

    &:hover {
      cursor: pointer;
      opacity: 1;
    }

    .badge {
      color: $color-text-light;
    }

    & ~ button {
      position: absolute;
      top: get-scaled-size($scale-factor-0_5);
      right: get-scaled-size($scale-factor-1);
    }
  }

  & > .container {
    @include media-breakpoint-down(md) {
      padding-left: 0;
      padding-right: 0;
      max-width: none;
    }
    @include media-breakpoint-up(lg) {
      padding-left: get-scaled-size($scale-factor-2);
    }
  }

  .stage-item {
    border-bottom: 1px solid #cccccc;

    &.stage-item__dragging {
      border-bottom: none;
    }

    .stage-header-info {
      position: relative;

      .stage-scroll-to {
        height: 0px;
        position: absolute;
        top: -115px;
        left: 0;
        right: 0;
        z-index: -1;

        @media (min-width: 992px) {
          top: -170px;
        }
      }
    }

    &:hover {
      cursor: pointer;
    }

    @include media-breakpoint-up(lg) {
      border-bottom: none;
      .fa-caret-right,
      .fa-caret-down {
        width: get-scaled-size($scale-factor-1);
      }
      .fa-grip-vertical {
        cursor: grab;
      }
    }

    & > div:first-child {
      justify-content: space-between;

      &:hover {
        cursor: pointer;
      }

      & > div {
        position: relative;

        & > svg:first-child {
          position: absolute;
          left: -(get-scaled-size($scale-factor-1));
          top: 6px;
          font-size: $font-size-smallest;
          vertical-align: baseline;
        }
      }

      & > svg {
        @extend .mrm-ml-0_5;
      }

      @include media-breakpoint-up(lg) {
        justify-content: flex-start;
      }
    }
  }

  .stage-description-notes {
    font-weight: normal;
    color: $color-text-normal;
  }

  .coach-notes {
    color: $color-assessment-red;
  }

  .add-stage-button:disabled {
    opacity: 0.25;
  }
}
