.rpm-client-profile {
    &__container {
        &__right-body {
            &__records {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 100%;
                height: 100%;
                margin-top: 1rem;
                gap: 1rem;
                overflow-y: auto;
                &__record {
                    display: flex;
                    flex-direction: column;
                    gap: 0.875rem;
                    width: 100%;
                    &__header {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        width: 100%;
                        &__date {
                            font-size: 0.75rem;
                            font-weight: 600;
                            color: #515151;
                        }
                    }
                    &__measurements {
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        gap: 0.5rem;
                    }
                }
                &__end {
                    color: #515151;
                    opacity: 0.5;
                    font-weight: 600;
                    border-top: 1px solid rgba(160, 158, 156, 0.5);
                    font-size: 0.75rem;
                    font-weight: 600;
                    padding-top: 1.25rem;
                    width: 100%;
                    text-align: center;
                }
            }
        }
    }
}

.container-skeleton {
    animation: container-skeleton-loading 1s linear infinite alternate;
}

@keyframes container-skeleton-loading {
    0% {
        background-color: $color-white;
    }
    100% {
        background-color: $color-white;
    }
}

.activity-card-skeleton {
    height: 3.375rem;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    background-color: $white;
    position: relative;
    &__profile {
        height: 1.6875rem;
        width: 1.6875rem;
        border-radius: 50%;
        @extend .container-skeleton;
        position: absolute;
        top: 0.875rem;
        left: 0.625rem;
    }
    &__name {
        height: 0.625rem;
        width: 9.25rem;
        top: 1rem;
        left: 2.8125rem;
        @extend .container-skeleton;
        position: absolute;
        border-radius: 0.25rem;
    }
    &__description {
        height: 0.625rem;
        width: 5.875rem;
        top: 1.875rem;
        left: 2.8125rem;
        @extend .container-skeleton;
        position: absolute;
        border-radius: 0.25rem;
    }

    &__score {
        position: absolute;
        @extend .container-skeleton;
        height: 2rem;
        width: 2.875rem;
        top: 0.75rem;
        right: 1rem;
        border-radius: 0.25rem;
    }
}
