@import '../../../../../styles/mixins.scss';

.rpm-connect-your-device-mobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100vh;
  width: 100vw;

  .rpm-connect-progress-bar {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: #595959;
    z-index: 1;
    .rpm-connect-progress-bar-fill {
      height: 100%;
      background-color: #e8744f;
      width: 20%;
      border-radius: 0px 10px 10px 0px;
    }
  }

  @include media-breakpoint-up(md) {
  }

  overflow: hidden;
  .background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 2rem;
    z-index: 2;
    height: 50vh;

    .user-photo {
      width: 5.56rem;
      height: 5.56rem;
      border-radius: 10px 10px 0px 10px;
      border: 2px solid rgba(255, 255, 255, 0.5);
      box-sizing: border-box;
    }

    h1 {
      font-size: 1.5rem;
      font-weight: 700;
      color: $white;
      text-align: center;
      width: 70%;
    }
    button {
      font-size: 0.8rem;
      font-weight: 400;
      color: $white;
      text-decoration: underline;
    }
  }

  .watches-options {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 50vh;
    background-color: $color-white;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 3rem 1.5rem 2rem 1.5rem;
    border-top-left-radius: 2rem;
    border-top-right-radius: 2rem;
    gap: 1rem;

    .watch-option {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      height: 5.0625rem;
      border-radius: 10px;
      background-color: $white;
      box-shadow: 0px -4px 20px 1px rgba(0, 0, 0, 0.05);
      cursor: pointer;
      img {
        height: 4.6rem;
        object-fit: contain;
      }
    }
    .footer-text {
      font-size: 14px;
      color: $color-gray-lightest;
      font-weight: 400;
    }
    .no-device {
      position: absolute;
      bottom: 2rem;
      left: 0;
      width: 100%;
      text-align: center;
      text-decoration: underline;
      font-size: $font-size-normal;
    }
  }
}
