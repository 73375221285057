@import '../../../styles/mixins';
.user-profile-page .common-header button[type='submit'] {
  color: $color-dark;
}
.user-components-profile-form {
  .theme-card {
    max-width: fit-content;
    padding: 3rem 1rem;
    width: 100%;
    min-width: 20rem;
    border-radius: 0.8rem;
    margin-top: 2rem;
  }

  .container-inside {
    padding-bottom: 5rem;
  }

  h2 {
    font-family: $serif-font-family;
    font-size: 1.5rem;
    text-align: center;
  }

  .avatar-editor {
    margin: 0 auto;
  }

  hr {
    @extend .mrm-mb-1;
    width: calc(100% + 2rem);
    height: 1px;
    margin-left: -1rem;
  }

  form {
    padding-top: 1rem;
    .form-label {
      font-size: $font-size-smaller;
    }
    .form-group {
      margin-bottom: 0.875rem;
      border: 1px solid $color-gray-lighter;
      border-radius: 0.6rem;
      min-width: 100%;
      position: relative;
      .icon {
        position: absolute;
        top: 0.8125rem;
        left: 0.6875rem;
        margin-left: 0.25rem;
      }
      .email {
        width: 1.25rem;
        top: 0.875rem;
        left: 0.6rem;
      }
      .phone {
        width: 0.875rem;
        top: 0.6875rem;
        left: 0.8rem;
      }
      .bio {
        width: 1rem;
        top: 0.9rem;
        left: 0.8rem;
      }
      .form-control {
        padding-left: 3rem;
        font-weight: 300;
      }

      textarea {
        padding-top: 0.65rem;
        padding-left: 1rem;
        border: 1px solid $color-gray-lighter;
      }
    }
    .change-password {
      color: $color-dark-green;
      font-weight: 700;
      text-decoration: underline;
      margin: 0;
    }
  }
  .desktop-save-cancel {
    display: none;
    .btn {
      display: flex;
      width: 100%;
      border-radius: 0.6rem;
    }
    .save {
      background-color: $color-dark;
      color: $white;
    }
    .cancel {
      background-color: transparent;
      border: 1px solid $color-dark;
      &:hover {
        background-color: $color-dark;
        color: $white;
      }
    }
  }
  @include media-breakpoint-up(lg) {
    form {
      min-width: 19rem;
      .change-password {
        margin-bottom: 1.5rem;
      }
    }
    .theme-card {
      max-width: fit-content;
      margin: 3rem auto;
      padding: 3rem 5rem;
    }
    .desktop-save-cancel {
      display: grid;
      justify-items: center;
      row-gap: 0.75rem;
    }
  }

  .form-container {
    @include media-breakpoint-up(sm) {
      border-radius: 10px;
    }

    @include media-breakpoint-down(xs) {
      background-color: transparent;

      .btn-delete-account {
        background-color: white;
      }
    }
  }
  .profile-photo {
    @include media-breakpoint-only(sm) {
      margin-top: 1rem;
    }
  }
}

.theme-card {
  background-color: $color-white;
}

.profile-edit-form-container {
  min-width: 350px;
}

.account-delete-modal {
  .confirm {
    max-width: 20em;
  }

  h1.title {
    @include media-breakpoint-down(xs) {
      font-size: $font-size-normal;
      margin: auto;
      line-height: 22px;
    }
  }

  .btn-cancel {
    @extend .mrm-pb-1_5;
    height: 30px;
  }

  .btn-delete-account {
    height: auto;
  }

  .input-sm {
    height: 30px;
    font-size: $font-size-smaller;
  }
}

@include media-breakpoint-up(sm) {
  .user-components-profile-form {
    .form-control {
      border: 1px solid $color-item-gray;
      &:focus {
        border: 1px solid #2f80ed;
      }
    }
    .form-container {
      @include mrm-main-box-shadow;
      margin-bottom: get-scaled-size($scale-factor-2_5);
    }
  }
}

@include media-breakpoint-only(sm) {
  .user-components-user-avatar-editor {
    padding-top: 1.5rem;
  }
}

.account-change-password-modal {
  h1.title {
    @include media-breakpoint-down(xs) {
      font-size: $font-size-normal;
      margin: auto;
      line-height: 22px;
    }
  }

  .form-control {
    border-color: $color-item-gray;
  }

  .cancel-btn {
    @extend .mrm-mt-0_5;

    position: absolute;
    left: 0;
    top: 0;
    font-weight: bold;
  }
  .input-sm {
    height: 30px;
    font-size: $font-size-smaller;
  }

  .btn-primary:disabled {
    border-color: $color-text-disabled;
    background-color: $color-text-disabled;
  }
}

.change-password-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  border-radius: 0.6rem;
  background-color: $color-dark;
  color: $white;
  width: 100%;
}

.password-form {
  .form-control {
    padding-left: 1rem;
  }
}
