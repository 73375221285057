@import '../../styles/mixins';

.auth-download-navbar {
  top: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $white;
  padding: 1.25rem 39px;
  z-index: 1;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.1);;

  img {
    width: 7.125rem;
    object-fit: contain;
  }

  @include media-breakpoint-down(md) {
    flex-direction: column;
    align-items: center;
    padding: 0;
    box-shadow: none;
    position: relative;
    border-bottom: none;
  }

  &__logo-container {
    display: flex;
    align-items: center;
    justify-content: center;
    @include media-breakpoint-down(md) {
      height: 65px;
      box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
      width: 100%;
    }
  }
}

.auth-download {
  display: flex;
  flex-direction: column;
  background-color: $white;
  @include media-breakpoint-up(lg) {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-template-rows: 1fr;
    height: 100vh;
  }
  width: 100vw;

  &__background-image {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    &__secondary-image {
      position: absolute;
      bottom: 0;
      left: -100px;
      height: 558px;
      width: 334.6px;
      background-size: contain;
      background-position: bottom;
      background-repeat: no-repeat;
    }

    @include media-breakpoint-down(md) {
      transform: scaleX(-1);
      height: 348px;

      &__secondary-image {
        left: -50px;
        width: 228.85px;
        height: 351px;
      }
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 39px;

    &__logo {
      width: 47px;
      height: 47px;
      aspect-ratio: 1/1;
      margin-bottom: 21px;
    }
    h1 {
      font-size: 3rem;
      font-family: $sans-serif-font-family;
      font-weight: 700;
      width: 80%;
      line-height: 54px;
    }

    &__subtitle {
      font-size: 1rem;
      font-weight: 400;
      color: $color-dark;
      opacity: 0.58;
      margin-top: 21px;
    }

    &__qrs-wrapper {
      display: flex;
      align-items: flex-start;
      gap: 59px;
      margin-top: 30px;

      &__qr-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        p {
          color: $color-dark;
          opacity: 0.58;
          font-size: 18px;
          font-weight: 600;
          margin-bottom: 27px;
        }
        img {
          margin-bottom: 21px;
        }
        &__qr {
          border-radius: 0.5rem;
          box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
          width: 142px;
          aspect-ratio: 1/1;
          object-fit: contain;
        }
        a {
          &:hover {
            opacity: 1;
            transform: scale(1.01);
            transition: all 0.2s ease-in-out;
          }
        }
      }
    }
    @include media-breakpoint-down(md) {
      padding-top: 33px;
      align-items: center;
      padding-left: 0;
      img {
        margin-bottom: 19px;
      }
      h1 {
        font-size: 2.5rem;
        text-align: center;
        line-height: 44.5px;
      }
      p {
        text-align: center;
      }

      // hide qr title and qr code
      &__qrs-wrapper {
        gap: 9px;
        margin-bottom: 96px;
        &__qr-container {
          p {
            display: none;
          }
          &__qr {
            display: none;
          }
        }
      }
    }
  }
}
