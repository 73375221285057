.calls-modal {
  .modal-header {
    align-items: center;
    padding: 1.5rem;
    padding-bottom: 0;
    p {
      cursor: pointer;
    }
  }
  label {
    font-size: $font-size-small;
    font-weight: 300;
    padding-bottom: 0.5rem;
    color: $color-gray;
  }
  input {
    height: 3rem;
    padding: 1rem;
    font-size: $font-size-small;
  }
  svg {
    margin-right: 1rem;
  }

  .form-control {
    padding-top: 0.7rem;
    height: 3rem;
    font-size: $font-size-small;
  }
  .error-message {
    background-color: var(--primary-red);
    color: white;
    padding: 0.5rem;
    border-radius: 5px;
  }

  .modal-body {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1.5rem;
    padding-bottom: 2rem;
  }

  .delete-call-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: fit-content;
    padding: 1rem;
    background-color: $color-dark;
    color: $color-white;
    font-size: $font-size-smaller;
    border-radius: 0.6rem;
    cursor: pointer;
  }
}

.delete-call-modal {
  .modal-header {
    padding-top: 2rem;
    p {
      cursor: pointer;
    }
  }
  .modal-body {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    text-align: center;
    padding-bottom: 4rem;
    p {
      text-align: center;
      color: $color-alerts;
      font-weight: 500;
    }
  }
}
