@import '../../styles/mixins';

.support-group-calls {
  display: flex;
  flex-direction: column;
  padding: 2rem 1.5rem;
  background-color: $color-white;
  @include media-breakpoint-down(sm) {
    background-color: transparent;
  }
  border-radius: 0.8rem;
  .container-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    h2 {
      font-weight: 200;
      font-size: $font-size-normal;
    }

    .plus-btn {
      background-color: $color-dark;
      border-radius: 0.4rem;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 1.625rem;
      height: 1.625rem;
    }
    span {
      font-weight: bolder;
      color: $color-dark;
    }
  }

  .no-calls {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
    padding: 1rem 0;

    p {
      color: $color-gray-lightest;
      font-weight: 300;
    }

    .icon-container {
      background-color: $color-bg-white;
      max-width: 3rem;
      max-height: 3rem;
      min-width: 3rem;
      min-height: 3rem;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
    }
  }
}

.group-calls-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem 0;
}

.drop-in-card {
  background-color: $white;
  display: flex;
  border-radius: 0.5rem;
  gap: 1rem;
  height: auto;
  align-items: center;
  position: relative;
  width: 100%;
  justify-content: space-between;
  padding-right: 1.3rem;
  box-shadow: 0px 4px 20px 1px rgba(0, 0, 0, 0.1);

  &:hover {
    transform: scale(1.01);
    transition: all 0.2s ease-in-out;
  }

  .icon-wrapper {
    background-color: $color-dark;
    height: 100%;
    display: flex;
    padding: 0 0.8rem;
    border-radius: 0.5rem 0 0 0.5rem;
    position: absolute;
    align-items: center;
  }

  a {
    &:hover {
      // overlay on <a> tag hidden
      opacity: 1;
    }
  }
  .icon-container {
    background-color: #4a4949;
    width: 0.8rem;
    height: 0.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    max-width: 3rem;
    max-height: 3rem;
    min-width: 3rem;
    min-height: 3rem;
  }

  .text-container {
    display: flex;
    flex-direction: column;
    padding: 1rem 0;
    width: 100%;
    padding-left: 5.5rem;
    gap: 0.2rem;

    h3 {
      font-weight: 300;
      font-size: $font-size-normal;
    }
    p {
      font-weight: 200;
      color: $color-dark-text-normal;
      font-size: $font-size-smallest;
    }
  }

  .dropdown-menu {
    border-radius: 0.5rem;
    margin-top: -1rem;
    position: absolute;
    border: 1px solid $color-white;
    box-shadow: 1px 1px 20px 1px rgba(0, 0, 0, 0.05);
    margin-left: -10rem;
    position: relative;
    border-radius: 0.8rem;
    .dropdown-item {
      width: 100%;
      font-size: $font-size-smallest;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      color: $color-dark;
      &:active {
        background-color: transparent;
        color: $color-dark;
      }
    }
    .delete-btn {
      color: $color-red;
      font-weight: 600;
    }
  }
}
