.height-action-item-start {
  max-height: 25vh;
  overflow: auto;
  max-width: 82%;
  .action-item-header {
    position: sticky;
    top: 0;
    background-color: rgb(251, 250, 250);
    padding-bottom: 0.75rem;
  }
}
.responses-length {
  font-size: $font-size-smallest;
  font-weight: 500;
  padding: 0.5rem 0;
}
