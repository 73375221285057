@import '../../styles/mixins';

.edit-call-modal {
  .modal-content {
    background-color: $color-white;
  }
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    h2 {
      font-family: $serif-font-family;
    }
  }
  .form-control {
    border-radius: 0.5rem;
    border: 1px solid $color-light-gray;
    display: flex;
    align-items: center;
    height: 45px;
    font-weight: 300;
    height: 3rem;
    padding: 1rem;
    font-size: $font-size-small;
    box-shadow: none;
  }
  .form-label {
    font-size: $font-size-small;
    font-weight: 300;
    padding-bottom: 0.5rem;
    color: $color-gray;
  }

  .date-picker-cal {
    display: flex;
    gap: 0.5rem;
    align-items: center;
  }
  .error-message {
    color: $color-red;
    font-size: $font-size-smallest;
    margin: 0.5rem 0;
    font-weight: 700;
  }
}
