@import '../../styles/mixins';

.manage-rename-stage-page {
  .btn-save {
    color: $primary;
    background-color: transparent;
    box-shadow: none;
  }

  .btn-cancel {
    background-color: transparent;
    box-shadow: none;
  }
}
