.rpm-client-profile {
    &__container {
        &__right-body {
            .profile-details-section {
                display: flex;
                flex-direction: column;
                width: 100%;
                align-items: flex-start;
                margin-top: 2.25rem;

                input[type='date']::-webkit-inner-spin-button,
                input[type='date']::-webkit-calendar-picker-indicator {
                    padding-right: 0.5rem;
                }

                &__header {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    justify-content: space-between;
                    margin-bottom: 0.8125rem;

                    &__title {
                        font-size: 0.875rem;
                        font-weight: 600;
                    }

                    &__edit {
                        font-size: 0.75rem;
                        font-weight: 500;
                        cursor: pointer;
                        text-decoration: underline;
                    }
                }

                &__body {
                    display: flex;
                    flex-direction: column;
                    width: 100%;

                    &__row {
                        height: 3.375rem;
                        display: grid;
                        grid-template-columns: 1fr 1fr 1fr;
                        width: 100%;
                        gap: 1rem;
                        grid-template-areas: 'label value value';
                        border-bottom: 1px solid #e8e6e6;
                        // center row horizontally
                        align-items: center;

                        &__label {
                            grid-area: label;
                            font-size: 0.75rem;
                            font-weight: 400;
                            color: #4a4a4a;
                            display: flex;
                            align-items: center;
                            justify-content: flex-start;

                            &__icon {
                                margin-right: 0.5rem;
                                width: 1.8125rem;
                                height: 1.8125rem;
                                display: flex;
                                align-items: center;
                                justify-content: flex-start;
                                border-radius: 50%;
                                aspect-ratio: 1/1;
                            }

                            &__title {
                                font-size: 0.875rem;
                                font-weight: 600;
                                color: $color-dark;
                                @include media-breakpoint-down(sm) {
                                    font-size: 0.625rem;
                                }
                            }
                        }

                        &__value {
                            grid-area: value;
                            font-size: 0.75rem;
                            font-weight: 400;
                            color: #4a4a4a;
                            display: flex;
                            align-items: center;
                            justify-content: flex-start;
                            @include media-breakpoint-down(sm) {
                                font-size: 0.625rem;
                            }

                            &__icon {
                                margin-right: 0.5rem;
                            }
                        }
                        &__input-container {
                            position: relative;
                            grid-area: value;
                            display: flex;
                            align-items: center;
                            justify-content: flex-start;
                            width: 14.375rem;
                        }
                        &__input {
                            grid-area: value;
                            height: 2rem;
                            min-height: 2rem;
                            background: $white;
                            border: 1px solid #d3d0cc;
                            border-radius: 0.3125rem;
                            font-size: 0.875rem;
                            text-align: left;
                            width: 14.375rem;
                            font-weight: 400;
                            color: $color-dark;
                            padding-left: 0.625rem;

                            &::placeholder {
                                font-size: 0.875rem;
                            }
                        }

                        &__checkboxes {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            gap: 1rem;
                            position: relative;

                            &__checkbox {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                gap: 0.5rem;
                                outline: none;

                                &__label {
                                    font-size: 0.75rem;
                                    font-weight: 300;
                                    color: #c4c4c4;
                                }

                                .active {
                                    color: $color-dark;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .cancel-subscription-btn {
        width: 35%;
        color: $white;
        background-color: #e8744f;
        height: 2rem;
        border-radius: 0.3125rem;
        font-weight: 500;
        font-size: 0.75rem;
        margin-top: 1rem;
        outline: none;
        @include media-breakpoint-down(sm) {
            width: 100%;
        }
    }
}
.cancel-subscription-modal {
    .modal-content {
        display: flex;
        flex-direction: column;
        gap: 0.875rem;
        margin-top: calc(50vh - 6.5rem);
        min-height: 14.375rem;
        width: 30.56rem;
        @include media-breakpoint-down(sm) {
            width: 90vw;
            margin: auto;
            margin-top: 5rem;
        }
        align-items: center;
        justify-content: center;
        padding: 3rem;
        h3 {
            font-size: 1rem;
            font-weight: 600;
            color: $color-dark;
            font-family: $sans-serif-font-family;
        }

        p {
            font-size: 0.875rem;
            font-weight: 300;
            color: $color-dark;
            text-align: center;
        }

        p.red-text {
            font-size: 0.875rem;
            font-weight: 500;
            color: #e8744f;
            text-align: center;
        }

        position: relative;
    }

    &__warning {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        background-color: #f9dad0;
        width: 100%;
        padding: 1rem;
        border-left: 4px solid #e8744f;
    }
    &__buttons {
        display: flex;
        align-items: center;
        gap: 1rem;
        @include media-breakpoint-down(sm) {
            width: 100%;
        }
        &__button {
            width: 10.93rem;
            height: 3.125rem;
            border: 1px solid $color-dark;
            font-weight: 500;
            color: $color-dark;
            border-radius: 0.625rem;
            @include media-breakpoint-down(sm) {
                width: 100%;
            }
        }
    }

    &__close {
        position: absolute;
        right: 1rem;
        top: 1rem;
        height: 1.5rem;
        width: 1.5rem;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        outline: none;
    }
}
.profile-details-section-validation-alert {
    position: absolute;
    height: 2rem;
    padding: 0.3rem 0.75rem;
    background-color: #e8744f;
    border-radius: 0.25rem;
    top: -3.5rem;
    left: 0;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    min-width: 100%;

    .alert-icon-container {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        .alert-icon-square {
            width: 0.875rem;
            height: 0.875rem;
            aspect-ratio: 1/1;
            background-color: #e8744f;
            border-radius: 0.1875rem;
            transform: rotate(45deg);
        }

        .alert-icon {
            color: $white;
            font-size: 0.5rem;
            font-weight: 800;
            z-index: 10;
            position: absolute;
        }
    }

    &__message {
        color: $white;
        font-size: 0.75rem;
        font-weight: 600;
    }
}
