.rpm-provider-record-grid {
    &__column {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        background-color: $white;
        width: 100%;

        &__header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 1rem;
            width: 100%;
            min-height: 2rem;
            height: 2rem;
            background-color: #f7f5f3;
            &__title {
                font-size: 0.75rem;
                color: #595959;
                text-align: center;
                font-weight: 500;
            }

            &__icon-container {
                width: 1.5rem;
                height: 1.5rem;
            }
        }
        &__body {
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 0.5rem;
            &__title {
                font-size: 1rem;
                font-weight: 600;
                color: #595959;
            }
        }
    }
    .tooltip-wrapper {
        position: relative;
        padding: 0.5rem;
        outline: none;
        &__tooltip {
            position: absolute;
            top: 0;
            left: 2rem;
            width: 18.5rem;
            min-width: 18.5rem;
            padding: 3rem 2.5rem;
            background: $white;
            box-shadow: 0px 8px 13px rgba(0, 0, 0, 0.2);
            border-radius: 0.3rem;
            display: flex;
            flex-direction: column;
            z-index: 2;
            &__border {
                width: 100%;
                height: 1px;
                background-color: #d3d0cc;
            }

            h3 {
                font-size: 1rem;
                font-weight: 600;
                color: $color-dark;
                margin-bottom: 1.5rem;
                text-align: left;
            }

            &__triangle {
                // it is a triangle at the level of the tooltip-wrapper facing to the left
                position: absolute;
                top: 0.75rem;
                left: -0.5rem;
                width: 0;
                height: 0;
                border-top: 0.5rem solid transparent;
                border-bottom: 0.5rem solid transparent;
                border-right: 0.5rem solid $white;
            }

            &__content {
                display: flex;
                flex-direction: column;
                width: 100%;
                gap: 0.5rem;
                margin-top: 1.2rem;
                &__item {
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    &__tag {
                        text-align: left;
                        font-weight: 400;
                        font-size: 0.875rem;
                        color: #595959;
                        max-width: 10rem;
                        // wrap text
                        white-space: normal;
                        word-wrap: break-word;
                    }
                    &__score {
                        height: 2rem;
                        width: 5rem;
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        border-radius: 0.25rem;
                        &__value {
                            font-size: 0.75rem;
                            font-weight: 600;
                        }
                    }
                }
            }
        }
    }
}
