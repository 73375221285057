.rpm-client-profile {
    &__container {
        &__right-body {
            &__billing {
                display: flex;
                flex-direction: column;
                width: 100%;
                align-items: center;
                gap: 1rem;
                margin-top: 1rem;
                &__header {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    &__title {
                        font-size: 0.75rem;
                        font-weight: 400;
                        strong {
                            font-weight: 600;
                        }
                        width: 100%;
                        max-width: max-content;
                    }
                    &__line {
                        width: 100%;
                        height: 1px;
                        background-color: #bebdbd;
                        display: flex;
                        align-items: center;
                        margin-left: 1.1875rem;
                        margin-right: 1rem;
                    }

                    &__tag {
                        width: 3.8125rem;
                        height: 1.5rem;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        gap: 0.1875rem;
                        border: 1px solid #88a088;
                        border-radius: 7.8125rem;
                        padding: 0 0.5rem;
                        &__dot {
                            width: 0.3125rem;
                            height: 0.3125rem;
                            border-radius: 50%;
                            background-color: #88a088;
                        }
                        &__text {
                            font-size: 0.625rem;
                            font-weight: 500;
                            color: #88a088;
                        }
                    }
                }
                &__record {
                    min-height: 14.9375rem;
                    width: 100%;
                    background-color: $white;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    padding: 1rem;
                    border-radius: 0.625rem;
                    position: relative;
                    &__header {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        width: 100%;
                        &__title {
                            font-size: 0.875rem;
                            font-weight: 400;
                            color: #595959;
                            strong {
                                font-weight: 600;
                            }
                            width: 95%;
                        }
                        &__dots {
                            cursor: pointer;
                            color: #c4c4c4;
                        }
                    }
                    &__header-2 {
                        display: flex;
                        align-items: flex-start;
                        justify-content: space-between;
                        width: 100%;
                        margin-top: 0.75rem;
                        padding-bottom: 1.375rem;
                        border-bottom: 1px solid #dbd9d9;
                        &__left {
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-start;
                            gap: 2px;

                            &__title {
                                font-size: 0.75rem;
                                font-weight: 400;
                                strong {
                                    font-weight: 600;
                                }
                                color: #595959;
                            }

                            &__subtitle {
                                font-size: 0.75rem;
                                font-weight: 400;
                                color: #595959;
                            }
                        }
                        &__right {
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-end;
                            gap: 2px;

                            &__text {
                                font-size: 0.75rem;
                                font-weight: 400;
                                color: #595959;
                                text-align: right;
                            }
                        }
                    }

                    &__body {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        gap: 0.5rem;
                        margin-top: 1.5rem;
                        width: 100%;
                        &__title {
                            font-size: 0.75rem;
                            font-weight: 600;
                            color: #595959;
                            width: 100%;
                        }
                        &__text {
                            font-size: 0.75rem;
                            font-weight: 400;
                            color: #595959;
                            width: 100%;
                        }
                    }

                    &__dots {
                        position: absolute;
                        right: 1rem;
                        top: 1rem;
                        height: 1.5rem;
                        width: 1.5rem;
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                        outline: none;
                    }

                    &__tooltip {
                        width: 9.3125rem;
                        height: 5.25rem;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        padding: 0 0.5rem;
                        position: absolute;
                        right: 1rem;
                        top: 1rem;
                        background-color: $white;
                        border-radius: 0.25rem;
                        box-shadow: 1px 1px 20px 1px rgba(0, 0, 0, 0.05);
                        &__item {
                            display: flex;
                            width: 100%;
                            height: 2.625rem;
                            align-items: center;
                            &__text {
                                font-size: 0.75rem;
                                font-weight: 600;
                                color: $color-dark;
                                margin-left: 0.5rem;
                                width: 100%;
                                text-align: left;
                            }
                        }
                    }
                }
            }
        }
    }
}

.delete-cpt-code-modal {
    .modal-content {
        display: flex;
        flex-direction: column;
        gap: 0.875rem;
        margin-top: calc(50vh - 6.5rem);
        height: 14.375rem;
        width: 30.56rem;
        align-items: center;
        justify-content: center;
        @include media-breakpoint-down(sm) {
            width: 90vw;
            margin: auto;
            margin-top: 5rem;
        }
        h3 {
            font-size: 1rem;
            font-weight: 600;
            color: $color-dark;
            font-family: $sans-serif-font-family;
        }

        p {
            font-size: 0.875rem;
            font-weight: 300;
            color: $color-dark;
            text-align: center;
        }
        position: relative;
    }
    &__buttons {
        display: flex;
        align-items: center;
        gap: 1rem;
        @include media-breakpoint-down(sm) {
            width: 100%;
        }
        &__button {
            width: 10.93rem;
            height: 3.125rem;
            border: 1px solid $color-dark;
            font-weight: 500;
            color: $color-dark;
            border-radius: 0.625rem;
            @include media-breakpoint-down(sm) {
                width: 100%;
            }
        }
    }

    &__close {
        position: absolute;
        right: 1rem;
        top: 1rem;
        height: 1.5rem;
        width: 1.5rem;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        outline: none;
    }
}

.container-skeleton {
    animation: container-skeleton-loading 1s linear infinite alternate;
}

@keyframes container-skeleton-loading {
    0% {
        background-color: $color-white;
    }
    100% {
        background-color: $color-white;
    }
}

.billing-card-skeleton {
    height: 14.93rem;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    background-color: $white;
    position: relative;

    &__title {
        @extend .container-skeleton;
        position: absolute;
        height: 0.625rem;
        left: 1rem;
        top: 1.5rem;
        width: 9.25rem;
        border-radius: 0.25rem;
    }

    &__description-1 {
        @extend .container-skeleton;
        position: absolute;
        height: 0.625rem;
        left: 1rem;
        top: 3.5625rem;
        width: 9.875rem;
        border-radius: 0.25rem;
    }

    &__description-2 {
        @extend .container-skeleton;
        position: absolute;
        height: 0.625rem;
        left: 1rem;
        top: 4.5625rem;
        width: 5.875rem;
        border-radius: 0.25rem;
    }

    &__divider {
        height: 1px;
        @extend .container-skeleton;
        width: calc(100% - 2rem);
        position: absolute;
        left: 1rem;
        top: 6.875rem;
    }

    &__footer-1,
    &__footer-2,
    &__footer-3,
    &__footer-4 {
        @extend .container-skeleton;
        position: absolute;
        height: 0.625rem;
        left: 1rem;
        top: 4.5625rem;
        width: 5.875rem;
        border-radius: 0.25rem;
    }

    &__footer-1 {
        top: 8.3125rem;
        width: 3.5625rem;
    }

    &__footer-2 {
        top: 9.75rem;
        width: calc(100% - 2rem);
    }

    &__footer-3 {
        top: 11.125rem;
        width: calc(100% - 8rem);
    }

    &__footer-4 {
        top: 12.5rem;
        width: calc(100% - 5rem);
    }
}
