html {
  font-size: 100%;
  width: 100%;
}
body {
  width: 100%;
}

body,
a,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
li,
dl,
dt,
dd,
blockquote,
pre,
form,
fieldset,
legend,
input,
textarea,
th,
td,
button,
button,
input,
select,
label,
table,
thead,
tbody,
tr,
td,
th,
img {
  margin: 0;
  padding: 0;
  font-family: $sans-serif-font-family;
}

body,
#root,
.home-app,
.page-container {
  min-height: 100vh;
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  height: 2px;
  background: #e5e5e5;
}

figure {
  iframe {
    border-radius: 4px;
  }
}

.modal-content {
  z-index: 9999999;
}
