@import url(https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;1,300;1,500;1,600;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Merriweather:wght@400;700&display=swap);
/*!
 * Bootstrap v4.6.2 (https://getbootstrap.com/)
 * Copyright 2011-2022 The Bootstrap Authors
 * Copyright 2011-2022 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
          text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #0056b3;
    text-decoration: underline; }

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit;
  text-align: -webkit-match-parent; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus:not(:focus-visible) {
  outline: 0; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

[role="button"] {
  cursor: pointer; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2; }

h1, .h1 {
  font-size: 2.5rem; }

h2, .h2 {
  font-size: 2rem; }

h3, .h3 {
  font-size: 1.75rem; }

h4, .h4 {
  font-size: 1.5rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 0.875em;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 0.875em;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%; }

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%; }

.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%; }

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%; }

.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6; }
  .table tbody + tbody {
    border-top: 2px solid #dee2e6; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #dee2e6; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8daff; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #7abaff; }

.table-hover .table-primary:hover {
  background-color: #9fcdff; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #9fcdff; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b3b7bb; }

.table-hover .table-secondary:hover {
  background-color: #c8cbcf; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #c8cbcf; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8fd19e; }

.table-hover .table-success:hover {
  background-color: #b1dfbb; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #b1dfbb; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda; }

.table-hover .table-info:hover {
  background-color: #abdde5; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #abdde5; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e; }

.table-hover .table-warning:hover {
  background-color: #ffe8a1; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffe8a1; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ed969e; }

.table-hover .table-danger:hover {
  background-color: #f1b0b7; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f1b0b7; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc; }

.table-hover .table-light:hover {
  background-color: #ececf6; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #ececf6; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c; }

.table-hover .table-dark:hover {
  background-color: #b9bbbe; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b9bbbe; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6; }

.table-dark {
  color: #fff;
  background-color: #343a40; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #454d55; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .form-control::-webkit-input-placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

input[type="date"].form-control,
input[type="time"].form-control,
input[type="datetime-local"].form-control,
input[type="month"].form-control {
  -webkit-appearance: none;
          appearance: none; }

select.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057; }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input[disabled] ~ .form-check-label,
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #28a745; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem; }
  .form-row > .col > .valid-tooltip,
  .form-row > [class*="col-"] > .valid-tooltip {
    left: 5px; }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated select.form-control:valid, select.form-control.is-valid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center; }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat; }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #28a745; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem; }
  .form-row > .col > .invalid-tooltip,
  .form-row > [class*="col-"] > .invalid-tooltip {
    left: 5px; }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated select.form-control:invalid, select.form-control.is-invalid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center; }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat; }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #dc3545; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
          user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #212529;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }
  .btn-primary:hover {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc; }
  .btn-primary:focus, .btn-primary.focus {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc;
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #0062cc;
    border-color: #005cbf; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5); }

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62; }
  .btn-secondary:focus, .btn-secondary.focus {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62;
    box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #545b62;
    border-color: #4e555b; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745; }
  .btn-success:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34; }
  .btn-success:focus, .btn-success.focus {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34;
    box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #1e7e34;
    border-color: #1c7430; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }
  .btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b; }
  .btn-info:focus, .btn-info.focus {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b;
    box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107; }
  .btn-warning:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00; }
  .btn-warning:focus, .btn-warning.focus {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00;
    box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #d39e00;
    border-color: #c69500; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }
  .btn-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130; }
  .btn-danger:focus, .btn-danger.focus {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130;
    box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #bd2130;
    border-color: #b21f2d; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5; }
  .btn-light:focus, .btn-light.focus {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5;
    box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }
  .btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .btn-dark:focus, .btn-dark.focus {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124;
    box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }

.btn-outline-primary {
  color: #007bff;
  border-color: #007bff; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #007bff;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #6c757d;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.btn-outline-success {
  color: #28a745;
  border-color: #28a745; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #28a745;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #dc3545;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-link {
  font-weight: 400;
  color: #007bff;
  text-decoration: none; }
  .btn-link:hover {
    color: #0056b3;
    text-decoration: underline; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }
  .collapsing.width {
    width: 0;
    height: auto;
    transition: width 0.35s ease; }
    @media (prefers-reduced-motion: reduce) {
      .collapsing.width {
        transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #e9ecef; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #007bff; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #adb5bd;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }
  .input-group:not(.has-validation) > .form-control:not(:last-child),
  .input-group:not(.has-validation) > .custom-select:not(:last-child),
  .input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label,
  .input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label::after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group.has-validation > .form-control:nth-last-child(n + 3),
  .input-group.has-validation > .custom-select:nth-last-child(n + 3),
  .input-group.has-validation > .custom-file:nth-last-child(n + 3) .custom-file-label,
  .input-group.has-validation > .custom-file:nth-last-child(n + 3) .custom-file-label::after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus,
    .input-group-append .btn:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px); }

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px); }

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .input-group-text,
.input-group.has-validation > .input-group-append:nth-last-child(n + 3) > .btn,
.input-group.has-validation > .input-group-append:nth-last-child(n + 3) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  print-color-adjust: exact; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #007bff;
    background-color: #007bff; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #80bdff; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: #b3d7ff;
    border-color: #b3d7ff; }
  .custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: 1px solid #adb5bd; }
  .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: 50% / 50% 50% no-repeat; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #007bff;
  background-color: #007bff; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-switch {
  padding-left: 2.25rem; }
  .custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem; }
  .custom-switch .custom-control-label::after {
    top: calc(0.25rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #adb5bd;
    border-radius: 0.5rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #fff;
    -webkit-transform: translateX(0.75rem);
            transform: translateX(0.75rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(0, 123, 255, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-appearance: none;
          appearance: none; }
  .custom-select:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .custom-select:focus::-ms-value {
      color: #495057;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    display: none; }
  .custom-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057; }

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem; }

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  overflow: hidden;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #80bdff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .custom-file-input[disabled] ~ .custom-file-label,
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #e9ecef; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + 0.75rem);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit;
    border-radius: 0 0.25rem 0.25rem 0; }

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
          appearance: none; }
  .custom-range:focus {
    outline: 0; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    -webkit-appearance: none;
            appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        -webkit-transition: none;
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #b3d7ff; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    -moz-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        -moz-transition: none;
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: #b3d7ff; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    -ms-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        -ms-transition: none;
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: #b3d7ff; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #adb5bd; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none; } }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-link {
    margin-bottom: -1px;
    background-color: transparent;
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      isolation: isolate;
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff; }

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar .container,
  .navbar .container-fluid, .navbar .container-sm, .navbar .container-md, .navbar .container-lg, .navbar .container-xl {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50% / 100% 100% no-repeat; }

.navbar-nav-scroll {
  max-height: 75vh;
  overflow-y: auto; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-nav-scroll {
    overflow: visible; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group {
    border-top: inherit;
    border-bottom: inherit; }
    .card > .list-group:first-child {
      border-top-width: 0;
      border-top-left-radius: calc(0.25rem - 1px);
      border-top-right-radius: calc(0.25rem - 1px); }
    .card > .list-group:last-child {
      border-bottom-width: 0;
      border-bottom-right-radius: calc(0.25rem - 1px);
      border-bottom-left-radius: calc(0.25rem - 1px); }
  .card > .card-header + .list-group,
  .card > .list-group + .card-footer {
    border-top: 0; }

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(0.25rem - 1px); }

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%; }

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

.card-deck .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px; }
    .card-deck .card {
      flex: 1 0;
      margin-right: 15px;
      margin-bottom: 0;
      margin-left: 15px; } }

.card-group > .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap; }
    .card-group > .card {
      flex: 1 0;
      margin-bottom: 0; }
      .card-group > .card + .card {
        margin-left: 0;
        border-left: 0; }
      .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-top,
        .card-group > .card:not(:last-child) .card-header {
          border-top-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-bottom,
        .card-group > .card:not(:last-child) .card-footer {
          border-bottom-right-radius: 0; }
      .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-top,
        .card-group > .card:not(:first-child) .card-header {
          border-top-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-bottom,
        .card-group > .card:not(:first-child) .card-footer {
          border-bottom-left-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    -webkit-column-count: 3;
            column-count: 3;
    grid-column-gap: 1.25rem;
    -webkit-column-gap: 1.25rem;
            column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion {
  overflow-anchor: none; }
  .accordion > .card {
    overflow: hidden; }
    .accordion > .card:not(:last-of-type) {
      border-bottom: 0;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0; }
    .accordion > .card:not(:first-of-type) {
      border-top-left-radius: 0;
      border-top-right-radius: 0; }
    .accordion > .card > .card-header {
      border-radius: 0;
      margin-bottom: -1px; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem; }
  .breadcrumb-item + .breadcrumb-item::before {
    float: left;
    padding-right: 0.5rem;
    color: #6c757d;
    content: "/"; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #6c757d; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6; }
  .page-link:hover {
    z-index: 2;
    color: #0056b3;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 3;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .badge {
      transition: none; } }
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #fff;
  background-color: #007bff; }
  a.badge-primary:hover, a.badge-primary:focus {
    color: #fff;
    background-color: #0062cc; }
  a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

.badge-secondary {
  color: #fff;
  background-color: #6c757d; }
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: #fff;
    background-color: #545b62; }
  a.badge-secondary:focus, a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.badge-success {
  color: #fff;
  background-color: #28a745; }
  a.badge-success:hover, a.badge-success:focus {
    color: #fff;
    background-color: #1e7e34; }
  a.badge-success:focus, a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.badge-info {
  color: #fff;
  background-color: #17a2b8; }
  a.badge-info:hover, a.badge-info:focus {
    color: #fff;
    background-color: #117a8b; }
  a.badge-info:focus, a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.badge-warning {
  color: #212529;
  background-color: #ffc107; }
  a.badge-warning:hover, a.badge-warning:focus {
    color: #212529;
    background-color: #d39e00; }
  a.badge-warning:focus, a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.badge-danger {
  color: #fff;
  background-color: #dc3545; }
  a.badge-danger:hover, a.badge-danger:focus {
    color: #fff;
    background-color: #bd2130; }
  a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.badge-light {
  color: #212529;
  background-color: #f8f9fa; }
  a.badge-light:hover, a.badge-light:focus {
    color: #212529;
    background-color: #dae0e5; }
  a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.badge-dark {
  color: #fff;
  background-color: #343a40; }
  a.badge-dark:hover, a.badge-dark:focus {
    color: #fff;
    background-color: #1d2124; }
  a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff; }
  .alert-primary hr {
    border-top-color: #9fcdff; }
  .alert-primary .alert-link {
    color: #002752; }

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db; }
  .alert-secondary hr {
    border-top-color: #c8cbcf; }
  .alert-secondary .alert-link {
    color: #202326; }

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb; }
  .alert-success hr {
    border-top-color: #b1dfbb; }
  .alert-success .alert-link {
    color: #0b2e13; }

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb; }
  .alert-info hr {
    border-top-color: #abdde5; }
  .alert-info .alert-link {
    color: #062c33; }

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba; }
  .alert-warning hr {
    border-top-color: #ffe8a1; }
  .alert-warning .alert-link {
    color: #533f03; }

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb; }
  .alert-danger hr {
    border-top-color: #f1b0b7; }
  .alert-danger .alert-link {
    color: #491217; }

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe; }
  .alert-light hr {
    border-top-color: #ececf6; }
  .alert-light .alert-link {
    color: #686868; }

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca; }
  .alert-dark hr {
    border-top-color: #b9bbbe; }
  .alert-dark .alert-link {
    color: #040505; }

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  -webkit-animation: 1s linear infinite progress-bar-stripes;
          animation: 1s linear infinite progress-bar-stripes; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      -webkit-animation: none;
              animation: none; } }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #212529;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit; }
  .list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .list-group-item + .list-group-item {
    border-top-width: 0; }
    .list-group-item + .list-group-item.active {
      margin-top: -1px;
      border-top-width: 1px; }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0; }
  .list-group-horizontal > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0; }
  .list-group-horizontal > .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0; }
    .list-group-horizontal > .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px; }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-sm > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-sm > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-sm > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-md > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-md > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-md > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-md > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-lg > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-lg > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-lg > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-xl > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xl > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

.list-group-flush {
  border-radius: 0; }
  .list-group-flush > .list-group-item {
    border-width: 0 0 1px; }
    .list-group-flush > .list-group-item:last-child {
      border-bottom-width: 0; }

.list-group-item-primary {
  color: #004085;
  background-color: #b8daff; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #004085;
    background-color: #9fcdff; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #004085;
    border-color: #004085; }

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #383d41;
    background-color: #c8cbcf; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #383d41;
    border-color: #383d41; }

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #155724;
    background-color: #b1dfbb; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #155724;
    border-color: #155724; }

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #0c5460;
    background-color: #abdde5; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #0c5460;
    border-color: #0c5460; }

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #856404;
    background-color: #ffe8a1; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #856404;
    border-color: #856404; }

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #721c24;
    background-color: #f1b0b7; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #721c24;
    border-color: #721c24; }

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #818182;
    background-color: #ececf6; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #818182;
    border-color: #818182; }

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #1b1e21;
    background-color: #b9bbbe; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #1b1e21;
    border-color: #1b1e21; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:hover {
    color: #000;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0; }

a.close.disabled {
  pointer-events: none; }

.toast {
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  opacity: 0;
  border-radius: 0.25rem; }
  .toast:not(:last-child) {
    margin-bottom: 0.75rem; }
  .toast.showing {
    opacity: 1; }
  .toast.show {
    display: block;
    opacity: 1; }
  .toast.hide {
    display: none; }

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.toast-body {
  padding: 0.75rem; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: -webkit-transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
    transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
    -webkit-transform: translate(0, -50px);
            transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    -webkit-transform: none;
            transform: none; }
  .modal.modal-static .modal-dialog {
    -webkit-transform: scale(1.02);
            transform: scale(1.02); }

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    height: -webkit-min-content;
    height: min-content;
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px); }
  .modal-footer > * {
    margin: 0.25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem);
      height: -webkit-min-content;
      height: min-content; }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
    bottom: calc(-0.5rem - 1px); }
    .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
      bottom: 0;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
      bottom: 1px;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
    left: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
      left: 0;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
      left: 1px;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
    top: calc(-0.5rem - 1px); }
    .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
      top: 0;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      top: 1px;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
    right: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
      right: 0;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
      right: 1px;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  transition: -webkit-transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  -webkit-transform: translateX(100%);
          transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  -webkit-transform: none;
          transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: 50% / 100% 100% no-repeat; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators li {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

@-webkit-keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentcolor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: .75s linear infinite spinner-border;
          animation: .75s linear infinite spinner-border; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@-webkit-keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0); }
  50% {
    opacity: 1;
    -webkit-transform: none;
            transform: none; } }

@keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0); }
  50% {
    opacity: 1;
    -webkit-transform: none;
            transform: none; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentcolor;
  border-radius: 50%;
  opacity: 0;
  -webkit-animation: .75s linear infinite spinner-grow;
          animation: .75s linear infinite spinner-grow; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    -webkit-animation-duration: 1.5s;
            animation-duration: 1.5s; } }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #007bff !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important; }

.bg-secondary {
  background-color: #6c757d !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important; }

.bg-success {
  background-color: #28a745 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #007bff !important; }

.border-secondary {
  border-color: #6c757d !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.user-select-all {
  -webkit-user-select: all !important;
          user-select: all !important; }

.user-select-auto {
  -webkit-user-select: auto !important;
          user-select: auto !important; }

.user-select-none {
  -webkit-user-select: none !important;
          user-select: none !important; }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0,
.roadmap-default-page .hidden-student {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0,
.roadmap-default-page .hidden-student {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #007bff !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #0056b3 !important; }

.text-secondary {
  color: #6c757d !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:hover, a.text-success:focus {
  color: #19692c !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important; }

.text-body {
  color: #212529 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #dee2e6; }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6; } }

#c-bns button:first-child,
#s-bns button:first-child {
  color: #fff;
  color: #fff;
  color: var(--cc-btn-primary-text);
  background: #343434 !important;
  background: var(--cc-btn-primary-bg); }

.cc_div .c-bn {
  color: #40505a;
  color: #2d4156;
  color: var(--cc-btn-secondary-text);
  background: white; }

#cm .c_link:hover {
  background: white; }

#c-ttl {
  font-family: var(--serif-font-family);
  color: #343434; }

#c-text {
  font-family: var(--sans-serif-font-family);
  color: #343434; }

#cm {
  background-color: var(--primary-bg-white); }

::-webkit-input-placeholder {
  font-family: var(--sans-serif-font-family); }

h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
::placeholder {
  font-family: var(--sans-serif-font-family); }

::-webkit-input-placeholder {
  font-size: 1rem; }

::placeholder {
  font-size: 1rem; }

h2 {
  font-size: 1.125rem;
  font-weight: bold; }

h3 {
  font-size: 1.1rem; }

a {
  font-weight: bold;
  text-decoration: underline; }

a {
  text-decoration: none; }
  a:hover {
    opacity: 0.7;
    text-decoration: none; }
  a.no-format {
    color: inherit;
    font-weight: inherit; }
    a.no-format:hover {
      opacity: 1; }

/* Custom Utilities -------------------- */
/*  Custom Spacing ****************************
**
**
**  All spacing sre based on $scale-factor-0_25 ~ $scale-factor-48
**
**  Padding and margin classes are defined like
**  .mrm-p-0_25, .mrm-p_4, .mrm-px_1_5, .mrm-py_2
**  .mrm-m-0_25, .mrm-m_4, .mrm-mx_1_5, .mrm-my_2
**
**  We can get custom spacing using get-scaled-size function defined in this scss file
**/
.mrm-m-0_25 {
  margin: 0.25rem; }

.mrm-mx-0_25 {
  margin-left: 0.25rem;
  margin-right: 0.25rem; }

.mrm-my-0_25 {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem; }

.mrm-mt-0_25, .user-user-page .user-page-header p:last-child a, .roadmap-competency-page .competency-comment .card {
  margin-top: 0.25rem; }

.mrm-mr-0_25, .item-count svg, .item-count img, .roadmap-default-page .card-text .svg-inline--fa, .roadmap-competency-page h2, .notifications-components-default-page-notifications-list .notification-row .unread-marker {
  margin-right: 0.25rem; }

.mrm-mb-0_25, .tab-selector, .roadmap-competency-page .desktop-header-container h2, .roadmap-components-competency-page-competency-learn-tab .questions-section .question-item .answer {
  margin-bottom: 0.25rem; }

.mrm-ml-0_25, .common-desktop-header .row .col.right-menu .menu-item-container .btn svg, .dashboard-admin-page .sort.sort-desktop span,
.dashboard-admin-page .sort.sort-desktop svg, .roadmap-competency-page .competency-comment .user-name, .roadmap-competency-page .desktop-header-container h2 small {
  margin-left: 0.25rem; }

.mrm-m-0_5 {
  margin: 0.5rem; }

.mrm-mx-0_5 {
  margin-left: 0.5rem;
  margin-right: 0.5rem; }

.mrm-my-0_5, .user-components-user-avatar-editor .upload-photo {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem; }

.mrm-mt-0_5, .home-log-in-page .form-group .svg-inline--fa, .home-sign-up-page .form-group .svg-inline--fa, .account-change-password-modal .cancel-btn, .item-count, .roadmap-competency-page .competency-comment .initials, .roadmap-competency-page .attachment-button, .roadmap-competency-page .desktop-header-container h2, .roadmap-components-competency-page-competency-learn-tab .self-reflection .assessment-slider-container p, .roadmap-components-competency-page-competency-learn-tab .questions-section .question-item .answer {
  margin-top: 0.5rem; }

.mrm-mr-0_5, .item-count, .manage-edit-user-roadmap-modal .modal-header .common-user-avatar {
  margin-right: 0.5rem; }

.mrm-mb-0_5, .roadmap-components-competency-page-competency-learn-tab .notes-container .notes-description a pre, .roadmap-components-competency-page-competency-learn-tab .notes-container pre {
  margin-bottom: 0.5rem; }

.mrm-ml-0_5, .home-log-in-page .form-group .svg-inline--fa, .home-sign-up-page .form-group .svg-inline--fa, .common-user-not-approved a, table.common-custom-table thead th svg, .roadmap-default-page .hidden-student, .roadmap-competency-page .competency-comment .user-name ~ span, .roadmap-components-competency-page-competency-learn-tab .self-reflection .assessment-container .selected, .manage-edit-user-profile-component label, .manage-edit-user-profile-component .error-message, .manage-edit-roadmap-page .stage-item > div:first-child > svg, .manage-desktop-inline-adder button:last-child {
  margin-left: 0.5rem; }

.mrm-m-0_75 {
  margin: 0.75rem; }

.mrm-mx-0_75 {
  margin-left: 0.75rem;
  margin-right: 0.75rem; }

.mrm-my-0_75 {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem; }

.mrm-mt-0_75, .roadmap-action-item-page #formNotes, .roadmap-action-item-page button {
  margin-top: 0.75rem; }

.mrm-mr-0_75, .common-desktop-header .row .col.right-menu .menu-item.dropdown .dropdown-menu svg, .common-desktop-header .row .col.right-menu .menu-item.dropdown .dropdown-menu img, .common-desktop-header .profile-dropdown-menu svg, .common-desktop-header .profile-dropdown-menu img, .dropdown-menu svg, .dropdown-menu img, .roadmap-default-page .action-items, .action-item-page-attachment-list .attachment .file-name, .action-item-page-attachment-list .attachment .file-type {
  margin-right: 0.75rem; }

.mrm-mb-0_75, .roadmap-default-page h2, .roadmap-default-page .card, .roadmap-components-competency-page-competency-learn-tab .self-reflection .assessment-slider-container p, .manage-edit-roadmap-page .stage-competency {
  margin-bottom: 0.75rem; }

.mrm-ml-0_75 {
  margin-left: 0.75rem; }

.mrm-m-1, .photo-edit-body .scale-slider {
  margin: 1rem; }

.mrm-mx-1 {
  margin-left: 1rem;
  margin-right: 1rem; }

.mrm-my-1, .roadmap-competency-page h2 {
  margin-top: 1rem;
  margin-bottom: 1rem; }

.mrm-mt-1, .dashboard-admin-page .student-card .last-login, .dashboard-roadmap-card .started-date, .manage-edit-user-roadmap-component .roadmap-title, .subscriptions-internal-subscriptions-modal .modal-body form label, .contracts-modal .modal-body form label {
  margin-top: 1rem; }

.mrm-mr-1 {
  margin-right: 1rem; }

.mrm-mb-1, .photo-edit-body .edit-actions, .user-components-profile-form hr, .roadmap-competency-page .competency-comment, .roadmap-components-competency-page-action-items .action-item-container, .roadmap-components-competency-page-competency-learn-tab .notes-container .notes-description a, .action-item-page-attachment-list .attachment {
  margin-bottom: 1rem; }

.mrm-ml-1, .action-item .ai-status, .roadmap-components-competency-page-competency-learn-tab .questions-section .question-item .answer {
  margin-left: 1rem; }

.mrm-m-1_5 {
  margin: 1.5rem; }

.mrm-mx-1_5 {
  margin-left: 1.5rem;
  margin-right: 1.5rem; }

.mrm-my-1_5 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem; }

.mrm-mt-1_5 {
  margin-top: 1.5rem; }

.mrm-mr-1_5, .common-desktop-header .row .col.left-menu img {
  margin-right: 1.5rem; }

.mrm-mb-1_5, .profile-card-item, .notifications-components-default-page-notifications-list .no-notifications .fa-bell {
  margin-bottom: 1.5rem; }

.mrm-ml-1_5, .common-desktop-header .row .col.right-menu .menu-item {
  margin-left: 1.5rem; }

.mrm-m-2 {
  margin: 2rem; }

.mrm-mx-2 {
  margin-left: 2rem;
  margin-right: 2rem; }

.mrm-my-2, .manage-user-page .profile-card {
  margin-top: 2rem;
  margin-bottom: 2rem; }

.mrm-mt-2, .notifications-components-default-page-notifications-list .no-notifications {
  margin-top: 2rem; }

.mrm-mr-2 {
  margin-right: 2rem; }

.mrm-mb-2, .dashboard-admin-page .desktop-page-container .content-wrapper > div:first-child, .roadmap-competency-page .comment-list, .manage-edit-user-profile-component, .manage-edit-roadmap-details-page {
  margin-bottom: 2rem; }

.mrm-ml-2 {
  margin-left: 2rem; }

.mrm-m-2_5 {
  margin: 2.5rem; }

.mrm-mx-2_5 {
  margin-left: 2.5rem;
  margin-right: 2.5rem; }

.mrm-my-2_5 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem; }

.mrm-mt-2_5 {
  margin-top: 2.5rem; }

.mrm-mr-2_5 {
  margin-right: 2.5rem; }

.mrm-mb-2_5, .roadmap-default-page .stage {
  margin-bottom: 2.5rem; }

.mrm-ml-2_5 {
  margin-left: 2.5rem; }

.mrm-m-3 {
  margin: 3rem; }

.mrm-mx-3 {
  margin-left: 3rem;
  margin-right: 3rem; }

.mrm-my-3 {
  margin-top: 3rem;
  margin-bottom: 3rem; }

.mrm-mt-3, .dashboard-admin-page .desktop-page-container .content-wrapper > div:first-child {
  margin-top: 3rem; }

.mrm-mr-3 {
  margin-right: 3rem; }

.mrm-mb-3, .roadmap-default-page > .container {
  margin-bottom: 3rem; }

.mrm-ml-3 {
  margin-left: 3rem; }

.mrm-m-4 {
  margin: 4rem; }

.mrm-mx-4 {
  margin-left: 4rem;
  margin-right: 4rem; }

.mrm-my-4 {
  margin-top: 4rem;
  margin-bottom: 4rem; }

.mrm-mt-4 {
  margin-top: 4rem; }

.mrm-mr-4 {
  margin-right: 4rem; }

.mrm-mb-4 {
  margin-bottom: 4rem; }

.mrm-ml-4 {
  margin-left: 4rem; }

.mrm-m-6 {
  margin: 6rem; }

.mrm-mx-6 {
  margin-left: 6rem;
  margin-right: 6rem; }

.mrm-my-6 {
  margin-top: 6rem;
  margin-bottom: 6rem; }

.mrm-mt-6, .home-log-in-page .desktop-form-container, .home-sign-up-page .desktop-form-container {
  margin-top: 6rem; }

.mrm-mr-6 {
  margin-right: 6rem; }

.mrm-mb-6 {
  margin-bottom: 6rem; }

.mrm-ml-6 {
  margin-left: 6rem; }

.mrm-m-8 {
  margin: 8rem; }

.mrm-mx-8 {
  margin-left: 8rem;
  margin-right: 8rem; }

.mrm-my-8 {
  margin-top: 8rem;
  margin-bottom: 8rem; }

.mrm-mt-8 {
  margin-top: 8rem; }

.mrm-mr-8 {
  margin-right: 8rem; }

.mrm-mb-8 {
  margin-bottom: 8rem; }

.mrm-ml-8 {
  margin-left: 8rem; }

.mrm-m-12 {
  margin: 12rem; }

.mrm-mx-12 {
  margin-left: 12rem;
  margin-right: 12rem; }

.mrm-my-12 {
  margin-top: 12rem;
  margin-bottom: 12rem; }

.mrm-mt-12, .welcome-page .welcome-page-msg {
  margin-top: 12rem; }

.mrm-mr-12 {
  margin-right: 12rem; }

.mrm-mb-12 {
  margin-bottom: 12rem; }

.mrm-ml-12 {
  margin-left: 12rem; }

.mrm-m-16 {
  margin: 16rem; }

.mrm-mx-16 {
  margin-left: 16rem;
  margin-right: 16rem; }

.mrm-my-16 {
  margin-top: 16rem;
  margin-bottom: 16rem; }

.mrm-mt-16 {
  margin-top: 16rem; }

.mrm-mr-16 {
  margin-right: 16rem; }

.mrm-mb-16 {
  margin-bottom: 16rem; }

.mrm-ml-16 {
  margin-left: 16rem; }

.mrm-m-24 {
  margin: 24rem; }

.mrm-mx-24 {
  margin-left: 24rem;
  margin-right: 24rem; }

.mrm-my-24 {
  margin-top: 24rem;
  margin-bottom: 24rem; }

.mrm-mt-24 {
  margin-top: 24rem; }

.mrm-mr-24 {
  margin-right: 24rem; }

.mrm-mb-24 {
  margin-bottom: 24rem; }

.mrm-ml-24 {
  margin-left: 24rem; }

.mrm-m-32 {
  margin: 32rem; }

.mrm-mx-32 {
  margin-left: 32rem;
  margin-right: 32rem; }

.mrm-my-32 {
  margin-top: 32rem;
  margin-bottom: 32rem; }

.mrm-mt-32 {
  margin-top: 32rem; }

.mrm-mr-32 {
  margin-right: 32rem; }

.mrm-mb-32 {
  margin-bottom: 32rem; }

.mrm-ml-32 {
  margin-left: 32rem; }

.mrm-m-40 {
  margin: 40rem; }

.mrm-mx-40 {
  margin-left: 40rem;
  margin-right: 40rem; }

.mrm-my-40 {
  margin-top: 40rem;
  margin-bottom: 40rem; }

.mrm-mt-40 {
  margin-top: 40rem; }

.mrm-mr-40 {
  margin-right: 40rem; }

.mrm-mb-40 {
  margin-bottom: 40rem; }

.mrm-ml-40 {
  margin-left: 40rem; }

.mrm-m-48 {
  margin: 48rem; }

.mrm-mx-48 {
  margin-left: 48rem;
  margin-right: 48rem; }

.mrm-my-48 {
  margin-top: 48rem;
  margin-bottom: 48rem; }

.mrm-mt-48 {
  margin-top: 48rem; }

.mrm-mr-48 {
  margin-right: 48rem; }

.mrm-mb-48 {
  margin-bottom: 48rem; }

.mrm-ml-48 {
  margin-left: 48rem; }

.mrm-p-0_25, table.common-custom-table tbody tr td, .action-item-page-attachment-list .attachment .delete, .manage-roadmaps-page .status-active {
  padding: 0.25rem; }

.mrm-px-0_25 {
  padding-left: 0.25rem;
  padding-right: 0.25rem; }

.mrm-py-0_25, .item-count {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem; }

.mrm-pt-0_25, .user-user-page .header-down-menu {
  padding-top: 0.25rem; }

.mrm-pr-0_25, .delete-comment-message-icon {
  padding-right: 0.25rem; }

.mrm-pb-0_25 {
  padding-bottom: 0.25rem; }

.mrm-pl-0_25 {
  padding-left: 0.25rem; }

.mrm-p-0_5, .manage-edit-user-roadmap-component .roadmap-title, .manage-competency-page .card.item, .manage-competency-page .card .assessment-input {
  padding: 0.5rem; }

.mrm-px-0_5, .item-count, .roadmap-default-page .hidden-student {
  padding-left: 0.5rem;
  padding-right: 0.5rem; }

.mrm-py-0_5, .desktop-form-container, .dashboard-dashboard-page .dashboard-tab-selector .nav, .manage-edit-roadmap-page .stage-competency {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.mrm-pt-0_5, .notifications-default-page .container {
  padding-top: 0.5rem; }

.mrm-pr-0_5 {
  padding-right: 0.5rem; }

.mrm-pb-0_5 {
  padding-bottom: 0.5rem; }

.mrm-pl-0_5, .delete-comment-message-icon {
  padding-left: 0.5rem; }

.mrm-p-0_75, .photo-change-body a, .roadmap-competency-page .competency-comment .card, .roadmap-components-competency-page-competency-learn-tab .notes-container .notes-description a, .roadmap-components-competency-page-competency-learn-tab .self-reflection .assessment-slider-container {
  padding: 0.75rem; }

.mrm-px-0_75, .roadmap-components-competency-page-competency-learn-tab .self-reflection .assessment-container .slider-container, .roadmap-components-competency-page-competency-learn-tab .self-reflection .assessment-container .assessment .card-body, .manage-edit-roadmap-page .stage-competency {
  padding-left: 0.75rem;
  padding-right: 0.75rem; }

.mrm-py-0_75, .action-item, .roadmap-components-competency-page-competency-learn-tab .self-reflection .assessment-container .assessment .card-body, .manage-competency-page .intro-content .card--embed, .manage-competency-page .coach-notes .card--embed {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem; }

.mrm-pt-0_75 {
  padding-top: 0.75rem; }

.mrm-pr-0_75, .manage-competency-page .intro-content .card--embed, .manage-competency-page .coach-notes .card--embed {
  padding-right: 0.75rem; }

.mrm-pb-0_75, .roadmap-competency-page .desktop-header-container .tab-selector .nav-link {
  padding-bottom: 0.75rem; }

.mrm-pl-0_75, .manage-competency-page .intro-content .card--embed, .manage-competency-page .coach-notes .card--embed {
  padding-left: 0.75rem; }

.mrm-p-1, .dashboard-admin-page .no-data, .roadmap-competency-page .comment-list .comment-form, .desktop-page-container .add-another-action-item, .manage-competency-page .card {
  padding: 1rem; }

.mrm-px-1, .home-campaigns.wrapper, .common-header .back-link, .dashboard-dashboard-page .dashboard-tab-selector, .subscriptions-internal-subscriptions.wrapper, .subscriptions-contracts.wrapper {
  padding-left: 1rem;
  padding-right: 1rem; }

.mrm-py-1, .add-note, .action-item-page-attachment-list .attachment, .roadmap-action-item-page .ai-description {
  padding-top: 1rem;
  padding-bottom: 1rem; }

.mrm-pt-1, .home-campaigns .home-campaigns-sidebar .sidebar-subheader, .user-user-page .container, .roadmap-competency-page .desktop-header-container, .subscriptions-internal-subscriptions-sidebar .sidebar-subheader {
  padding-top: 1rem; }

.mrm-pr-1, .action-item-page-attachment-list .attachment {
  padding-right: 1rem; }

.mrm-pb-1, .dashboard-admin-page.sort-menu form, .dashboard-admin-page .mobile-page-container {
  padding-bottom: 1rem; }

.mrm-pl-1, .action-item-page-attachment-list .attachment {
  padding-left: 1rem; }

.mrm-p-1_5 {
  padding: 1.5rem; }

.mrm-px-1_5, .common-desktop-header .row .col.right-menu .notifications-dropdown-menu .notifications-list-wrapper, .dashboard-admin-page .mobile-page-container, .roadmap-components-competency-page-competency-learn-tab .self-reflection .btn-bottom-left-rounded, .roadmap-components-competency-page-competency-learn-tab .self-reflection .btn-bottom-right-rounded, .subscriptions-internal-subscriptions-modal .modal-body, .contracts-modal .modal-body {
  padding-left: 1.5rem;
  padding-right: 1.5rem; }

.mrm-py-1_5 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem; }

.mrm-pt-1_5 {
  padding-top: 1.5rem; }

.mrm-pr-1_5 {
  padding-right: 1.5rem; }

.mrm-pb-1_5, .common-desktop-header .row .col.right-menu .notifications-dropdown-menu .notifications-list-wrapper, .account-delete-modal .btn-cancel {
  padding-bottom: 1.5rem; }

.mrm-pl-1_5 {
  padding-left: 1.5rem; }

.mrm-p-2, .entry {
  padding: 2rem; }

.mrm-px-2, .dashboard-admin-page .form-check, .add-exst-rm-body button {
  padding-left: 2rem;
  padding-right: 2rem; }

.mrm-py-2 {
  padding-top: 2rem;
  padding-bottom: 2rem; }

.mrm-pt-2, .roadmap-default-page > .container {
  padding-top: 2rem; }

.mrm-pr-2 {
  padding-right: 2rem; }

.mrm-pb-2, .manage-edit-user-roadmap-modal .modal-content, .subscriptions-internal-subscriptions-modal .modal-body, .contracts-modal .modal-body {
  padding-bottom: 2rem; }

.mrm-pl-2, .user-components-invite-coach-modal .form-group input {
  padding-left: 2rem; }

.mrm-p-2_5 {
  padding: 2.5rem; }

.mrm-px-2_5 {
  padding-left: 2.5rem;
  padding-right: 2.5rem; }

.mrm-py-2_5 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem; }

.mrm-pt-2_5 {
  padding-top: 2.5rem; }

.mrm-pr-2_5 {
  padding-right: 2.5rem; }

.mrm-pb-2_5 {
  padding-bottom: 2.5rem; }

.mrm-pl-2_5, .home-sign-up-page .form-control {
  padding-left: 2.5rem; }

.mrm-p-3, .common-network-detector {
  padding: 3rem; }

.mrm-px-3 {
  padding-left: 3rem;
  padding-right: 3rem; }

.mrm-py-3 {
  padding-top: 3rem;
  padding-bottom: 3rem; }

.mrm-pt-3 {
  padding-top: 3rem; }

.mrm-pr-3 {
  padding-right: 3rem; }

.mrm-pb-3 {
  padding-bottom: 3rem; }

.mrm-pl-3, input[type='email'],
input[type='number'],
input[type='password'],
input[type='search'],
input[type='tel'],
input[type='text'],
input[type='url'],
input[type='date'],
textarea, .home-log-in-page .form-control {
  padding-left: 3rem; }

.mrm-p-4 {
  padding: 4rem; }

.mrm-px-4 {
  padding-left: 4rem;
  padding-right: 4rem; }

.mrm-py-4 {
  padding-top: 4rem;
  padding-bottom: 4rem; }

.mrm-pt-4, .home-campaigns.wrapper, .subscriptions-internal-subscriptions.wrapper, .subscriptions-contracts.wrapper {
  padding-top: 4rem; }

.mrm-pr-4 {
  padding-right: 4rem; }

.mrm-pb-4, .roadmap-action-item-page {
  padding-bottom: 4rem; }

.mrm-pl-4 {
  padding-left: 4rem; }

.mrm-p-6 {
  padding: 6rem; }

.mrm-px-6 {
  padding-left: 6rem;
  padding-right: 6rem; }

.mrm-py-6 {
  padding-top: 6rem;
  padding-bottom: 6rem; }

.mrm-pt-6 {
  padding-top: 6rem; }

.mrm-pr-6 {
  padding-right: 6rem; }

.mrm-pb-6 {
  padding-bottom: 6rem; }

.mrm-pl-6 {
  padding-left: 6rem; }

.mrm-p-8 {
  padding: 8rem; }

.mrm-px-8, .desktop-form-container {
  padding-left: 8rem;
  padding-right: 8rem; }

.mrm-py-8 {
  padding-top: 8rem;
  padding-bottom: 8rem; }

.mrm-pt-8 {
  padding-top: 8rem; }

.mrm-pr-8 {
  padding-right: 8rem; }

.mrm-pb-8 {
  padding-bottom: 8rem; }

.mrm-pl-8 {
  padding-left: 8rem; }

.mrm-p-12 {
  padding: 12rem; }

.mrm-px-12 {
  padding-left: 12rem;
  padding-right: 12rem; }

.mrm-py-12 {
  padding-top: 12rem;
  padding-bottom: 12rem; }

.mrm-pt-12, .common-user-not-approved {
  padding-top: 12rem; }

.mrm-pr-12 {
  padding-right: 12rem; }

.mrm-pb-12 {
  padding-bottom: 12rem; }

.mrm-pl-12 {
  padding-left: 12rem; }

.mrm-p-16 {
  padding: 16rem; }

.mrm-px-16 {
  padding-left: 16rem;
  padding-right: 16rem; }

.mrm-py-16 {
  padding-top: 16rem;
  padding-bottom: 16rem; }

.mrm-pt-16 {
  padding-top: 16rem; }

.mrm-pr-16 {
  padding-right: 16rem; }

.mrm-pb-16 {
  padding-bottom: 16rem; }

.mrm-pl-16 {
  padding-left: 16rem; }

.mrm-p-24 {
  padding: 24rem; }

.mrm-px-24 {
  padding-left: 24rem;
  padding-right: 24rem; }

.mrm-py-24 {
  padding-top: 24rem;
  padding-bottom: 24rem; }

.mrm-pt-24 {
  padding-top: 24rem; }

.mrm-pr-24 {
  padding-right: 24rem; }

.mrm-pb-24 {
  padding-bottom: 24rem; }

.mrm-pl-24 {
  padding-left: 24rem; }

.mrm-p-32 {
  padding: 32rem; }

.mrm-px-32 {
  padding-left: 32rem;
  padding-right: 32rem; }

.mrm-py-32 {
  padding-top: 32rem;
  padding-bottom: 32rem; }

.mrm-pt-32 {
  padding-top: 32rem; }

.mrm-pr-32 {
  padding-right: 32rem; }

.mrm-pb-32 {
  padding-bottom: 32rem; }

.mrm-pl-32 {
  padding-left: 32rem; }

.mrm-p-40 {
  padding: 40rem; }

.mrm-px-40 {
  padding-left: 40rem;
  padding-right: 40rem; }

.mrm-py-40 {
  padding-top: 40rem;
  padding-bottom: 40rem; }

.mrm-pt-40 {
  padding-top: 40rem; }

.mrm-pr-40 {
  padding-right: 40rem; }

.mrm-pb-40 {
  padding-bottom: 40rem; }

.mrm-pl-40 {
  padding-left: 40rem; }

.mrm-p-48 {
  padding: 48rem; }

.mrm-px-48 {
  padding-left: 48rem;
  padding-right: 48rem; }

.mrm-py-48 {
  padding-top: 48rem;
  padding-bottom: 48rem; }

.mrm-pt-48 {
  padding-top: 48rem; }

.mrm-pr-48 {
  padding-right: 48rem; }

.mrm-pb-48 {
  padding-bottom: 48rem; }

.mrm-pl-48 {
  padding-left: 48rem; }

.bg-grey {
  background-color: #f6f8f9; }

.bg-grey {
  background-color: #f6f8f9; }

.normal-width-container {
  margin-left: auto;
  margin-right: auto;
  max-width: 1095px; }
  @media (max-width: 1199.98px) {
    .normal-width-container {
      max-width: 920px; } }

.page-container {
  padding-bottom: 1px; }

.container {
  width: 100%; }

.desktop-page-container .form-control {
  border: 1px solid #cccccc; }

.mrm-box-shadow, .photo-edit-body .rc-slider-handle, .roadmap-competency-page .desktop-header-container, .roadmap-components-competency-page-competency-learn-tab .self-reflection .assessment-container .slider-container .rc-slider .rc-slider-handle {
  box-shadow: 0 2px 5px 0 #0002; }

.mrm-main-box-shadow, .desktop-page-secondary-header-wrapper, .btn-white, .btn-white:focus, .btn-white:active, .btn-white:hover, .card, table.common-custom-table, .roadmap-components-competency-page-competency-learn-tab .notes-container .notes-description a, .roadmap-components-competency-page-competency-learn-tab .questions-section, .manage-group-view-page .common-desktop-back-button {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.05); }

.mrm-white-on-white-box-shadow, .action-item-page-attachment-list .attachment, .manage-edit-user-roadmap-component .card-in-card, .manage-group-view-page .card-in-card, .manage-competency-page .card.item, .manage-competency-page .card .assessment-input, .manage-competency-page .intro-content .card--embed, .manage-competency-page .coach-notes .card--embed {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.09) !important;
  border-radius: 10px; }

.mrm-white-on-white-vivid-box-shadow {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.09);
  border-radius: 10px; }

.mrm-border-bottom {
  box-shadow: 0 1px 0 #0001; }

.border {
  height: 2px;
  width: 100%;
  background-color: #e5e5e5;
  border: none !important; }

.border-thin {
  height: 1px;
  width: 100%;
  background-color: #e5e5e5;
  border: none !important; }

.border-bottom {
  width: 100%;
  border-bottom: 1px solid #e5e5e5; }

.border-rounded {
  border-radius: 10px; }

.fa-4x {
  font-size: 4em; }

.fa-lg {
  font-size: 1.875em; }

.fa-sm {
  font-size: 1.25em; }

.fa-xs {
  font-size: 1.125em; }

.fa-1x {
  font-size: 0.8em; }

.css-xb97g8:hover {
  cursor: pointer; }

.hover:hover {
  cursor: pointer; }

.font-size-small {
  font-size: 0.875rem; }

.font-size-large {
  font-size: 1.125rem; }

.font-size-largest {
  font-size: 1.5rem; }

.font-size-smaller {
  font-size: 0.8rem; }

.font-size-smallest {
  font-size: 0.7rem; }

.bold {
  font-weight: 800; }

.semibold {
  font-weight: 600; }

.w-fit-content {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content; }

.svg-inline--fa {
  font-size: 1.5em; }

.css-g1d714-ValueContainer .css-1rhbuit-multiValue {
  border-radius: 6px; }
  .css-g1d714-ValueContainer .css-1rhbuit-multiValue .css-12jo7m5 {
    font-size: 1rem; }

.delete-comment-message-icon {
  color: #aaaaaa;
  font-size: 0.75rem; }
  .delete-comment-message-icon:hover {
    color: #b2b2b2;
    cursor: pointer; }

.rc-slider-disabled {
  background-color: transparent; }

.desktop-page-secondary-header-wrapper {
  background: #fff;
  border-radius: 0.625rem; }

.space-between {
  justify-content: space-between; }

.initials {
  height: 35px;
  width: 35px;
  display: flex;
  background-color: #6b6b6b;
  border-radius: 50%;
  margin-right: 5px; }
  .initials span {
    color: #ffffff;
    font-size: 15px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: normal; }
  .initials.md {
    height: 47px;
    width: 47px; }
    .initials.md span {
      font-size: 18px; }
  .initials.lg {
    height: 80px;
    width: 80px; }
    .initials.lg span {
      font-size: 32px; }

.navbar a:hover {
  opacity: 1; }

.flex {
  display: flex; }

.space-between {
  justify-content: space-between; }

.flex-col {
  flex-direction: column; }

.flex-row {
  flex-direction: row; }

.items-center {
  align-items: center; }

.items-start {
  align-items: flex-start; }

.gap-0-5 {
  grid-gap: 0.5rem;
  gap: 0.5rem; }

.gap-1 {
  grid-gap: 1rem;
  gap: 1rem; }

.gap-1_5 {
  grid-gap: 1.5rem;
  gap: 1.5rem; }

.gap-2 {
  grid-gap: 2rem;
  gap: 2rem; }

.w-full {
  width: 100%; }

.cursor-pointer {
  cursor: pointer; }

.py-1 {
  padding: 1rem 0; }

html {
  font-size: 100%;
  width: 100%; }

body {
  width: 100%; }

body,
a,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
li,
dl,
dt,
dd,
blockquote,
pre,
form,
fieldset,
legend,
input,
textarea,
th,
td,
button,
button,
input,
select,
label,
table,
thead,
tbody,
tr,
td,
th,
img {
  margin: 0;
  padding: 0;
  font-family: var(--sans-serif-font-family); }

body,
#root,
.home-app,
.page-container {
  min-height: 100vh;
  width: 100%;
  max-width: 100%;
  overflow-x: hidden; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  height: 2px;
  background: #e5e5e5; }

figure iframe {
  border-radius: 4px; }

.modal-content {
  z-index: 9999999; }

.fadein {
  animation: fadein 1s;
  -moz-animation: fadein 1s;
  /* Firefox */
  -webkit-animation: fadein 1s;
  /* Safari and Chrome */
  -o-animation: fadein 1s;
  /* Opera */ }

@keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes fadein {
  /* Safari and Chrome */
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

button {
  border: none;
  background: none; }

.btn {
  font-family: var(--sans-serif-font-family);
  font-weight: bold;
  border-radius: 5px;
  min-height: 40px;
  width: auto;
  font-size: 0.875rem; }
  .btn:focus {
    box-shadow: none; }

.btn-100 {
  width: 100%; }

.btn-center {
  display: block;
  margin: auto; }

.btn-orange {
  color: #fff;
  background-color: #f86630;
  border-color: #f86630; }
  .btn-orange:hover {
    color: #fff;
    background-color: #f86630;
    border-color: #ed4608; }
  .btn-orange:focus, .btn-orange.focus {
    color: #fff;
    background-color: #f86630;
    border-color: #ed4608;
    box-shadow: 0 0 0 0.2rem rgba(249, 125, 79, 0.5); }
  .btn-orange.disabled, .btn-orange:disabled {
    color: #fff;
    background-color: #f86630;
    border-color: #f86630; }
  .btn-orange:not(:disabled):not(.disabled):active, .btn-orange:not(:disabled):not(.disabled).active,
  .show > .btn-orange.dropdown-toggle {
    color: #fff;
    background-color: #ed4608;
    border-color: #e14208; }
    .btn-orange:not(:disabled):not(.disabled):active:focus, .btn-orange:not(:disabled):not(.disabled).active:focus,
    .show > .btn-orange.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(249, 125, 79, 0.5); }

.btn-lg, .btn-group-lg > .btn {
  font-size: 1rem;
  height: 45px;
  width: 100%; }

.btn-primary {
  background-color: #2f80ed;
  border-color: #2f80ed; }

.btn-secondary {
  background: transparent;
  color: #727272;
  font-weight: normal; }
  .btn-secondary:hover {
    cursor: pointer; }
  .btn-secondary:focus, .btn-secondary:active, .btn-secondary:hover {
    background: transparent !important;
    color: #727272 !important;
    box-shadow: none !important; }

.btn-destructive {
  background: transparent;
  color: #ff3b30;
  font-size: 1rem;
  font-weight: 400; }
  .btn-destructive:hover {
    color: #ff2317; }

.btn-menu-item {
  color: #3e3e3e;
  font-size: 1rem;
  font-weight: 400; }
  .btn-menu-item:focus {
    border: none; }
  .btn-menu-item:hover {
    color: #212529; }

.more-btn-desktop svg {
  background: transparent;
  border-radius: 8px;
  position: relative;
  color: #727272;
  font-size: 1.5rem;
  width: 40px !important;
  height: 23px; }
  .more-btn-desktop svg:hover {
    opacity: 1;
    cursor: pointer;
    background-color: #efeff0; }

.more-btn-desktop:hover {
  opacity: 1; }

.btn-white {
  background-color: white;
  color: #727272; }
  .btn-white:focus, .btn-white:active, .btn-white:hover {
    background-color: white;
    color: #727272; }

.btn-gray {
  background-color: #f4f4f5;
  color: #727272; }
  .btn-gray:focus, .btn-gray:active, .btn-gray:hover {
    color: #727272;
    background-color: #ececee;
    opacity: 1; }

@media (min-width: 992px) {
  .btn-cancel:hover {
    background-color: #efeff0;
    opacity: 1; } }

.card {
  border-radius: 10px;
  border: none; }

.card-img {
  border-radius: 10px; }

.card-title {
  margin-bottom: 0; }

input[type='checkbox'] {
  position: relative; }
  input[type='checkbox'].is-invalid::after {
    border: 1px solid #dc3545; }
  input[type='checkbox']::after {
    content: '';
    width: 1.4rem;
    height: 1.4rem;
    position: absolute;
    border-radius: 30%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    background-color: white;
    border: 1px solid #cccccc; }
    @media (max-width: 991.98px) {
      input[type='checkbox']::after {
        border: 1px solid #eeeeee; } }
  input[type='checkbox']:checked::after {
    background-color: #2f80ed;
    border: 1px solid #2f80ed; }
  input[type='checkbox']:checked::before {
    content: '';
    background-image: url(https://s.w.org/images/core/emoji/13.0.0/svg/2714.svg);
    width: 0.7rem;
    height: 0.7rem;
    -webkit-filter: brightness(9);
            filter: brightness(9);
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    color: white;
    z-index: 4;
    position: absolute; }
  input[type='checkbox'] ~ label {
    margin-left: 0.75em; }

input[type='email'],
input[type='number'],
input[type='password'],
input[type='search'],
input[type='tel'],
input[type='text'],
input[type='url'],
input[type='date'],
textarea {
  background-color: var(--pure-white);
  font-family: poppins, sans-serif;
  border-radius: 0.625rem;
  min-height: 45px;
  border: 1px solid var(--primary-light-gray); }

.form-group {
  margin-bottom: 1.3rem; }

.modal {
  overflow-y: auto; }

.confirmation-modal .modal-content .modal-title h1 {
  font-size: 1rem; }

.confirmation-modal h2 {
  font-size: 1rem;
  color: #3e3e3e; }

.confirmation-modal small {
  font-size: 0.875rem;
  color: #727272; }

.confirmation-modal .btn {
  font-size: 1rem;
  font-family: var(--sans-serif-font-family); }

.confirmation-modal .btn-cancel {
  font-weight: 400;
  color: #3e3e3e; }
  .confirmation-modal .btn-cancel:hover {
    background-color: transparent;
    opacity: 1; }

.modal-content {
  border-radius: 10px;
  border: none; }
  .modal-content .modal-header {
    border-bottom: none;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px; }
    .modal-content .modal-header button {
      border: none;
      background-color: transparent;
      color: var(--primary-dark);
      font-family: Poppins;
      font-style: normal;
      font-weight: 300;
      font-size: 16px; }
  .modal-content .modal-title {
    text-align: center; }
    .modal-content .modal-title h1 {
      width: 100%;
      font-size: 1.125rem;
      font-weight: bold;
      margin-bottom: 0px;
      line-height: 24px; }
    .modal-content .modal-title p {
      color: #727272;
      font-size: 1rem; }
  .modal-content .btn {
    min-height: 45px; }
  @media (min-width: 992px) {
    .modal-content .form-control {
      border: 1px solid #cccccc; }
      .modal-content .form-control:focus {
        box-shadow: none;
        border: 1px solid #2f80ed; } }

.modal-mobile-slide-from-bottom .modal-content {
  margin-bottom: 1.5rem;
  background-color: var(--primary-white); }

.modal-mobile-slide-from-bottom .btn-menu-item {
  height: 50px; }

@media (max-width: 575.98px) {
  .modal-mobile-slide-from-bottom .modal-dialog {
    display: flex;
    align-items: flex-end;
    min-height: calc(100% - 1rem); }
  .modal-mobile-slide-from-bottom.fade .modal-dialog {
    -webkit-transform: translateY(50px);
            transform: translateY(50px); }
  .modal-mobile-slide-from-bottom.show .modal-dialog {
    -webkit-transform: none;
            transform: none; } }

.common-header {
  padding-top: 32px; }

/* Legacy start */
h1,
h2,
h3 {
  color: #3a3a3b; }

.blue {
  background-color: #ecf4fa; }

.theme-text {
  color: #555; }

.theme-text-blue {
  color: #2f80ed; }

.color-primary {
  color: #3e3e3e; }

.color-secondary {
  color: #727272; }

.theme-text-primary {
  color: #3e3e3e; }

a.theme-text-primary:hover {
  color: #777; }

.theme-text-secondary {
  color: #727272; }

.theme-card {
  background-color: white; }

a.theme-text-secondary:hover {
  color: #999; }

.theme-light {
  --serif-font-family: 'Merriweather', serif;
  --sans-serif-font-family: 'Poppins', sans-serif;
  --primary-dark: orange;
  --pure-white: #ffffff;
  --pure-black: #000000;
  --primary-white: #f8f5f5;
  --primary-dark: #343434;
  --primary-bg-white: #f0ece8;
  --primary-orange: #ca9d29;
  --primary-gray: #6b6b6b;
  --primary-light-gray: #e3e5e5;
  --primary-green: #88a088;
  --primary-dark-green: #6d786e;
  --primary-red: #c47258;
  --primary-alerts: #e8744f;
  --primary-gray-lighter: #f2f1ec;
  --primary-gray-lightest: #c4c4c4;
  --gray-icon: #bfbfbf; }
  .theme-light .theme-bg {
    background-color: var(--primary-white); }

.theme-dark {
  --serif-font-family: 'Merriweather', serif;
  --sans-serif-font-family: 'Poppins', sans-serif;
  --pure-white: #ffffff;
  --pure-black: #000000;
  --primary-white: #f8f5f5;
  --primary-dark: #343434;
  --primary-bg-white: #f0ece8;
  --primary-orange: #ca9d29;
  --primary-gray: #6b6b6b;
  --primary-bg-gray: #aeaeae;
  --primary-light-gray: #e3e5e5;
  --primary-green: #88a088;
  --primary-dark-green: #6d786e;
  --primary-light-green: #9da16b;
  --primary-red: #c47258;
  --primary-alerts: #e8744f;
  --primary-gray-lighter: #f2f1ec;
  --primary-gray-lightest: #c4c4c4;
  --gray-icon: #bfbfbf; }
  .theme-dark .theme-bg {
    background-color: var(--primary-bg-white); }

h1 {
  text-align: left; }

h1 {
  text-align: left; }

body .greminders__booking-widget {
  display: none !important; }

body #auth-google-calendar-content .greminders__booking-widget {
  display: block !important; }

.home-app {
  min-height: calc(1vh * 100);
  min-height: calc(var(--vh, 1vh) * 100); }
  .home-app .toast-container {
    position: absolute;
    left: 50%;
    top: 20px; }
    .home-app .toast-container .toast {
      position: relative;
      left: -50%;
      z-index: 1; }
  .home-app .stage-env-banner {
    position: fixed;
    z-index: 1024;
    right: 0;
    left: 0;
    font-size: 0.8rem;
    border-bottom: 1px solid #f5c6ca;
    text-align: center;
    color: #731c23;
    background-color: #f8d7d9; }

.osano-cm-widget {
  display: none; }

/* .fade:not(.show) {
  opacity: 1;
}
 */
h1 {
  text-align: left; }

.welcome-page {
  height: 100vh; }
  .welcome-page .btn {
    max-width: 350px;
    display: block;
    margin: auto; }
  .welcome-page .login {
    background-color: var(--primary-bg-white);
    color: var(--primary-dark);
    border: none; }
  .welcome-page .welcome-page-logo {
    width: 250px;
    height: auto; }

@media (min-width: 576px) {
  .welcome-page .welcome-page-logo {
    width: 325px;
    height: auto; } }

.welcome-page {
  background-image: url(/static/media/noble-welcome-bg.abb30777.png);
  background-repeat: no-repeat;
  background-size: 80%;
  background-position: center 20%;
  background-color: var(--primary-dark); }
  .welcome-page h1,
  .welcome-page p {
    width: 100%;
    color: var(--pure-white);
    text-align: center; }
  .welcome-page h1 {
    font-family: var(--serif-font-family);
    font-size: 1.5rem; }
  @media (min-width: 768px) {
    .welcome-page h1,
    .welcome-page p {
      width: 70%; } }
  .welcome-page .welcome-page-logo {
    width: 150px;
    height: auto; }
  .welcome-page .welcome-page-msg {
    color: var(--primary-white);
    line-height: 2.3rem; }

@media (min-width: 576px) {
  .welcome-page {
    background-size: 300px; } }

h1 {
  text-align: left; }

.home-log-in-page {
  min-height: 100vh; }
  .home-log-in-page p {
    text-align: center; }
  .home-log-in-page .validation {
    color: var(--primary-alerts);
    padding-top: 1rem;
    font-size: 0.8rem;
    font-weight: 500;
    text-align: left; }
  .home-log-in-page .form-group .svg-inline--fa {
    position: absolute;
    color: var(--primary-gray); }
  .home-log-in-page .legal-info {
    font-size: 0.875rem; }
    .home-log-in-page .legal-info a {
      color: #727272;
      font-weight: normal; }
  .home-log-in-page .desktop-form-container {
    padding-bottom: 2.5rem; }
  .home-log-in-page .common-header {
    padding-top: 32px; }

.home-log-in-page {
  background-color: var(--primary-white); }
  .home-log-in-page .common-header {
    background-color: #f7f5f5 !important; }
    .home-log-in-page .common-header a,
    .home-log-in-page .common-header .common-header .back-link {
      color: var(--primary-dark); }
    .home-log-in-page .common-header svg {
      color: var(--primary-dark) !important; }
  .home-log-in-page .desktop-form-container {
    background-color: var(--primary-gray-lighter);
    padding-bottom: 2.5rem; }
  .home-log-in-page h1 {
    color: var(--primary-dark); }
  .home-log-in-page .btn-primary {
    border: #f6f6f6 1px solid;
    background-color: var(--primary-dark);
    font-weight: normal;
    color: var(--primary-white); }
  .home-log-in-page .legal-info {
    font-size: 0.875rem; }
    .home-log-in-page .legal-info a {
      color: #727272;
      font-weight: bold; }

h1 {
  text-align: left; }

.home-sign-up-page {
  min-height: 100vh; }
  .home-sign-up-page .form-group .svg-inline--fa {
    position: absolute;
    color: var(--primary-gray); }
  .home-sign-up-page .legal-info {
    color: #727272;
    font-size: 0.875rem; }
    .home-sign-up-page .legal-info .legal-link {
      color: #727272; }
      .home-sign-up-page .legal-info .legal-link:hover {
        color: #727272; }

h1 {
  text-align: left; }

.send-instructions {
  background-color: var(--primary-dark);
  color: var(--pure-white);
  width: 100%; }

.reset-password-form {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center; }
  @media (min-width: 768px) {
    .reset-password-form {
      background-color: var(--pure-white);
      padding: 3.5rem 5.1875rem;
      border-radius: 0.625rem;
      width: 37rem; } }
  .reset-password-form h2 {
    font-size: 1.5rem;
    font-weight: 600;
    text-align: center;
    margin-top: 2.5rem; }
  .reset-password-form p {
    margin-top: 0.75rem;
    text-align: center; }
  .reset-password-form .form-control {
    padding-left: 1.5rem;
    margin-top: 1.5rem; }
  .reset-password-form button {
    width: 19.68rem;
    height: 2.8125rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    color: var(--pure-white);
    border-radius: 0.625rem; }
  .reset-password-form .loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.65);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1; }

.desktop-wrapper {
  display: none; }
  @media (min-width: 768px) {
    .desktop-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0 auto;
      width: 100%; } }
  .desktop-wrapper .back-button-container {
    width: 37rem;
    margin-bottom: 2.5rem; }
    .desktop-wrapper .back-button-container button {
      display: flex;
      align-items: center;
      grid-gap: 0.5rem;
      gap: 0.5rem; }

.help-instructions {
  width: 426px;
  padding: 1.5rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-left: 47px;
  background-color: #f8f5f5;
  border-left: 1px solid #D5D1CD;
  grid-gap: 29px;
  gap: 29px;
  margin-top: 28px;
  margin-bottom: 44px; }
  .help-instructions h3 {
    font-size: 0.875rem; }
  .help-instructions ul {
    list-style: none;
    grid-gap: 0.875rem;
    gap: 0.875rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start; }
    .help-instructions ul li {
      display: flex;
      align-items: flex-start;
      grid-gap: 0.5rem;
      gap: 0.5rem;
      font-size: 14px;
      font-weight: 300; }
      .help-instructions ul li .icon {
        font-size: 14px;
        color: #ea734f;
        font-weight: 500; }

.back-button-container-mobile {
  display: flex;
  align-items: center;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  width: 100%;
  height: 60px;
  background-color: var(--pure-white);
  padding-left: 1.5rem;
  grid-gap: 0.5rem;
  gap: 0.5rem; }

h1 {
  text-align: left; }

.home-reset-password-page {
  min-height: 100vh; }
  .home-reset-password-page .common-header {
    background-color: transparent; }
  .home-reset-password-page .avatar {
    width: 2.25rem;
    height: 2.25rem;
    border-radius: 50%;
    background-color: #a768b6;
    color: white;
    text-align: center;
    padding-top: 5px;
    display: inline-block; }

h1 {
  text-align: left; }

.home-unsubscribe-page {
  min-height: 100vh; }

h1 {
  text-align: left; }

h1 {
  text-align: left; }

p {
  font-family: var(--sans-serif-font-family);
  color: #343a40; }

.home-signup {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  background-color: var(--primary-white);
  width: 100%;
  overflow: hidden; }
  .home-signup .signup-sidebar {
    overflow-y: scroll;
    position: relative;
    background: var(--primary-dark);
    top: 0;
    left: 0;
    min-width: 100%;
    z-index: 1;
    height: 100%;
    padding: 1rem 0rem; }
    .home-signup .signup-sidebar .logo {
      width: 5.5rem;
      margin-top: 1rem; }
    .home-signup .signup-sidebar .signup_form {
      width: 100%;
      display: flex;
      flex-direction: column;
      position: absolute;
      bottom: 0; }
      @media (max-width: 991.98px) {
        .home-signup .signup-sidebar .signup_form {
          max-height: 100vh;
          overflow-y: auto;
          padding-top: 1rem; } }
      .home-signup .signup-sidebar .signup_form .form_c {
        display: flex;
        flex-direction: column;
        min-width: 100%; }
        .home-signup .signup-sidebar .signup_form .form_c h3 {
          font-family: var(--serif-font-family);
          font-weight: bold;
          font-size: 2rem;
          color: var(--pure-white);
          padding: 0 1rem; }
        .home-signup .signup-sidebar .signup_form .form_c p {
          font-size: 1rem;
          font-weight: 300;
          padding: 0 1rem;
          color: var(--pure-white); }
        .home-signup .signup-sidebar .signup_form .form_c .ca {
          font-weight: 600;
          font-size: 1.1rem;
          margin-top: 1rem; }
        .home-signup .signup-sidebar .signup_form .form_c .form-group {
          margin: 0.5rem 0rem; }
          .home-signup .signup-sidebar .signup_form .form_c .form-group svg {
            position: absolute;
            margin-top: 0.8rem;
            margin-left: 0.7rem;
            color: #007bff; }
          .home-signup .signup-sidebar .signup_form .form_c .form-group .validation {
            color: var(--primary-alerts);
            padding-top: 1rem;
            font-size: 0.8rem;
            font-weight: 500; }
        .home-signup .signup-sidebar .signup_form .form_c .form-control {
          padding-left: 2.5rem; }
        .home-signup .signup-sidebar .signup_form .form_c .is-invalid-form {
          border-color: red;
          background: #f4e6e1; }
        .home-signup .signup-sidebar .signup_form .form_c form {
          background-color: var(--primary-white);
          width: 100%;
          margin: 0 auto;
          padding: 1.5rem 1rem;
          border-radius: 2rem 2rem 0 0;
          display: flex;
          flex-direction: column; }
          .home-signup .signup-sidebar .signup_form .form_c form .form-title {
            font-weight: 600;
            color: var(--primary-dark); }
          .home-signup .signup-sidebar .signup_form .form_c form input {
            width: 100%;
            height: 2.7rem;
            border: 1px solid var(--primary-gray-lighter);
            border-radius: 0.5rem; }
          .home-signup .signup-sidebar .signup_form .form_c form ::-webkit-input-placeholder {
            font-weight: 300;
            font-size: 1rem;
            color: #6d786e; }
          .home-signup .signup-sidebar .signup_form .form_c form ::placeholder {
            font-weight: 300;
            font-size: 1rem;
            color: #6d786e; }
          .home-signup .signup-sidebar .signup_form .form_c form svg {
            float: left;
            margin-left: 1rem;
            margin-top: -1.85rem;
            position: relative;
            z-index: 2;
            color: red; }
          .home-signup .signup-sidebar .signup_form .form_c form button {
            width: 100%;
            background-color: var(--primary-dark);
            color: white;
            padding: 0.7rem;
            border-radius: 0.5rem;
            font-family: 'Poppins';
            font-weight: 500;
            min-width: 100%;
            justify-content: center;
            margin-top: 0.8rem; }
        .home-signup .signup-sidebar .signup_form .form_c .aha {
          text-align: center;
          text-decoration: underline;
          font-weight: 500;
          margin-top: 1rem;
          color: var(--primary-dark);
          font-size: 0.8rem; }
  .home-signup .s-content {
    width: 67%;
    background-color: var(--primary-white);
    padding: 2rem;
    border-radius: 0.8rem;
    min-height: 100vh;
    margin-left: 33%;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    display: none; }
    .home-signup .s-content .login_content {
      width: 100%;
      display: grid;
      justify-items: center;
      grid-gap: 1rem;
      gap: 1rem; }
      .home-signup .s-content .login_content .illustration {
        width: 50%; }
      .home-signup .s-content .login_content h1 {
        text-align: center;
        margin: 0;
        font-weight: 700;
        font-size: 1.75rem;
        font-family: var(--serif-font-family);
        width: 65%; }
      .home-signup .s-content .login_content p {
        text-align: center;
        max-width: 26rem;
        font-weight: 300;
        font-size: 1rem; }

@media (min-width: 992px) {
  .home-signup .signup-sidebar {
    position: fixed;
    top: 0;
    left: 0;
    min-width: 33%;
    width: 36%;
    z-index: 1;
    height: 100vh;
    max-height: 100vh;
    overflow-y: scroll;
    padding: 1rem 2.31rem 1rem 2.31rem; }
    .home-signup .signup-sidebar .signup_form {
      width: 100%;
      display: flex;
      flex-direction: column;
      position: relative;
      bottom: 0;
      min-height: 100%; }
      .home-signup .signup-sidebar .signup_form .form_c {
        display: flex;
        flex-direction: column;
        min-width: 100%;
        margin: auto;
        padding: 0 1rem; }
        .home-signup .signup-sidebar .signup_form .form_c .terms {
          text-align: center;
          font-weight: 300;
          font-size: 0.7rem; }
        .home-signup .signup-sidebar .signup_form .form_c h3 {
          font-family: var(--serif-font-family);
          font-weight: bold;
          font-size: 2rem;
          color: var(--pure-white);
          padding: 0rem; }
        .home-signup .signup-sidebar .signup_form .form_c p {
          font-size: 1rem;
          font-weight: 300;
          color: var(--pure-white);
          padding: 0rem;
          max-width: 76%;
          padding-top: 1rem; }
        .home-signup .signup-sidebar .signup_form .form_c .ca {
          font-weight: 600;
          font-size: 1.1rem;
          margin-top: 1rem; }
        .home-signup .signup-sidebar .signup_form .form_c .form-group {
          margin-bottom: 0; }
          .home-signup .signup-sidebar .signup_form .form_c .form-group svg {
            position: absolute;
            margin-top: 0.8rem;
            margin-left: 0.7rem;
            color: #007bff; }
          .home-signup .signup-sidebar .signup_form .form_c .form-group .validation {
            color: red;
            padding-top: 1rem; }
        .home-signup .signup-sidebar .signup_form .form_c .form-control {
          padding-left: 2.5rem; }
        .home-signup .signup-sidebar .signup_form .form_c form {
          background-color: transparent;
          width: 100%;
          margin: 0 auto;
          padding: 1rem 0;
          border-radius: 2rem 2rem 0 0;
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-gap: 1rem;
          gap: 1rem; }
          .home-signup .signup-sidebar .signup_form .form_c form .form-title {
            font-weight: 600;
            color: var(--pure-white);
            grid-column: span 2 / auto; }
          .home-signup .signup-sidebar .signup_form .form_c form input {
            width: 100%;
            height: 2.7rem;
            border: 1px solid var(--primary-gray-lighter);
            border-radius: 0.5rem; }
          .home-signup .signup-sidebar .signup_form .form_c form .email-form {
            grid-column: span 2 / auto; }
          .home-signup .signup-sidebar .signup_form .form_c form ::-webkit-input-placeholder {
            font-weight: 300;
            font-size: 0.75rem;
            color: #6d786e; }
          .home-signup .signup-sidebar .signup_form .form_c form ::placeholder {
            font-weight: 300;
            font-size: 0.75rem;
            color: #6d786e; }
          .home-signup .signup-sidebar .signup_form .form_c form svg {
            float: left;
            margin-left: 1rem;
            margin-top: -1.85rem;
            position: relative;
            z-index: 2;
            color: red; }
          .home-signup .signup-sidebar .signup_form .form_c form button {
            width: 100%;
            background-color: var(--pure-white);
            color: var(--primary-dark);
            padding: 0.7rem;
            border-radius: 0.5rem;
            font-family: 'Poppins';
            font-weight: 500;
            min-width: 100%;
            justify-content: center;
            margin-top: 0.8rem; }
        .home-signup .signup-sidebar .signup_form .form_c .aha {
          text-align: center;
          text-decoration: underline;
          font-weight: 500;
          margin-top: 1rem;
          color: var(--pure-white);
          font-size: 0.8rem;
          max-width: 100%; }
  .home-signup .s-content {
    display: grid;
    margin: auto;
    margin-top: -1.5rem; }
    .home-signup .s-content .login_content .illustration-s {
      width: 60%; } }

h1 {
  text-align: left; }

p {
  font-family: var(--sans-serif-font-family);
  color: #343434; }

.signup-stripe {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  background-color: var(--primary-white);
  width: 100%;
  overflow: hidden; }
  .signup-stripe .stripe-sidebar {
    background-color: var(--primary-dark);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;
    height: 100%;
    padding: 1rem 2.31rem 1rem 2.31rem; }
    .signup-stripe .stripe-sidebar .logo-noble {
      width: 5rem;
      margin-top: 1rem; }
    .signup-stripe .stripe-sidebar .stripe-logo {
      margin: 0 auto;
      padding-bottom: 2rem;
      width: 30%; }
    .signup-stripe .stripe-sidebar .login_form {
      height: 100%;
      display: grid;
      align-items: center; }
      .signup-stripe .stripe-sidebar .login_form .form_c {
        display: grid;
        justify-content: start;
        width: 100%; }
        .signup-stripe .stripe-sidebar .login_form .form_c .stripe_button {
          margin: 0 auto;
          margin-top: 1.9rem; }
          .signup-stripe .stripe-sidebar .login_form .form_c .stripe_button p {
            margin-bottom: 1rem; }
        .signup-stripe .stripe-sidebar .login_form .form_c .links {
          margin-top: 2rem;
          margin-bottom: 2rem; }
          .signup-stripe .stripe-sidebar .login_form .form_c .links p {
            text-decoration: underline;
            font-weight: 500; }
            .signup-stripe .stripe-sidebar .login_form .form_c .links p:hover {
              color: var(--primary-dark); }
        .signup-stripe .stripe-sidebar .login_form .form_c .dark_button {
          background-color: #343434;
          color: white;
          margin-top: 1rem;
          border-radius: 0.5rem;
          font-family: 'Poppins';
          font-weight: 500;
          min-width: 100%;
          padding: 1rem 2rem 1rem 2rem;
          text-align: center; }
        .signup-stripe .stripe-sidebar .login_form .form_c h3 {
          font-weight: 500;
          font-size: 1.25rem;
          margin-bottom: 1rem;
          text-align: center;
          font-family: var(--sans-serif-font-family);
          color: var(--pure-white); }
        .signup-stripe .stripe-sidebar .login_form .form_c p {
          font-size: 0.875rem;
          font-weight: 300;
          color: var(--pure-white);
          text-align: center; }
      .signup-stripe .stripe-sidebar .login_form .ca {
        font-weight: 600;
        font-size: 1.1rem;
        margin-top: 1rem; }
      .signup-stripe .stripe-sidebar .login_form .form-group svg {
        position: absolute;
        margin-top: 0.7rem;
        margin-left: 0.7rem;
        color: #007bff; }
      .signup-stripe .stripe-sidebar .login_form .form-control {
        padding-left: 2.5rem; }
      .signup-stripe .stripe-sidebar .login_form form input {
        width: 100%;
        height: 2.7rem;
        margin-top: 1rem;
        border: 1px solid var(--primary-gray-lighter);
        border-radius: 0.5rem; }
      .signup-stripe .stripe-sidebar .login_form form ::-webkit-input-placeholder {
        font-weight: 300;
        font-size: 1rem;
        color: #6d786e; }
      .signup-stripe .stripe-sidebar .login_form form ::placeholder {
        font-weight: 300;
        font-size: 1rem;
        color: #6d786e; }
      .signup-stripe .stripe-sidebar .login_form form svg {
        float: left;
        margin-left: 1rem;
        margin-top: -1.85rem;
        position: relative;
        z-index: 2;
        color: red; }
      .signup-stripe .stripe-sidebar .login_form .aha {
        text-align: center;
        text-decoration: underline;
        font-weight: 500;
        margin-top: 1rem;
        color: #343434; }
    .signup-stripe .stripe-sidebar button {
      background-color: var(--pure-white);
      color: #343434;
      display: inline-flex;
      align-items: center;
      border-radius: 0.8rem;
      grid-gap: 1rem;
      gap: 1rem;
      height: 3.5rem;
      padding-right: 2.5rem;
      max-width: -webkit-max-content;
      max-width: max-content;
      font-size: 0.9rem;
      font-weight: 500; }
    .signup-stripe .stripe-sidebar .button-icon {
      height: 100%;
      border-color: white;
      display: inline-flex;
      align-items: center;
      border-right: 1px solid #595959;
      padding: 0 1rem; }
  .signup-stripe .content {
    width: 67%;
    background-color: var(--primary-white);
    padding: 2rem;
    border-radius: 0.8rem;
    min-height: 100vh;
    margin-left: 33%;
    display: grid;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    display: none; }
    .signup-stripe .content .login_content {
      width: 100%;
      display: grid;
      justify-items: center;
      grid-gap: 1rem;
      gap: 1rem; }
      .signup-stripe .content .login_content .woman {
        width: 24rem; }
      .signup-stripe .content .login_content h1 {
        text-align: center;
        margin: 0;
        font-weight: 500;
        font-size: 2rem; }
      .signup-stripe .content .login_content p {
        text-align: center;
        max-width: 26rem;
        font-weight: 300; }

@media (min-width: 992px) {
  .signup-stripe .stripe-sidebar {
    background-color: var(--primary-dark);
    position: fixed;
    top: 0;
    left: 0;
    min-width: 33%;
    width: 33%;
    z-index: 1;
    height: 100%;
    padding: 1rem 2.31rem 1rem 2.31rem; }
  .signup-stripe .content {
    display: grid; } }

h1 {
  text-align: left; }

p {
  font-family: var(--sans-serif-font-family);
  color: #343434; }

.home-signup {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  background-color: var(--primary-white);
  width: 100%;
  overflow: hidden; }
  .home-signup .signup-sidebar {
    position: relative;
    background: var(--primary-dark);
    top: 0;
    left: 0;
    min-width: 100%;
    z-index: 1;
    height: 100%;
    padding: 1rem 0rem; }
    .home-signup .signup-sidebar .logo {
      width: 6.5rem;
      margin-top: 1rem;
      padding-left: 1rem; }
    .home-signup .signup-sidebar .signup_form {
      width: 100%;
      display: flex;
      flex-direction: column;
      position: absolute;
      bottom: 0; }
      .home-signup .signup-sidebar .signup_form .form_c {
        display: flex;
        flex-direction: column;
        min-width: 100%; }
        .home-signup .signup-sidebar .signup_form .form_c h3 {
          font-family: var(--serif-font-family);
          font-weight: bold;
          font-size: 2rem;
          color: var(--pure-white);
          padding: 0 1rem;
          padding-bottom: 1rem; }
      .home-signup .signup-sidebar .signup_form p {
        font-size: 1rem;
        font-weight: 300;
        padding: 0 1rem;
        color: var(--pure-white); }
      .home-signup .signup-sidebar .signup_form .ca {
        font-weight: 600;
        font-size: 1.1rem;
        margin-top: 1rem; }
      .home-signup .signup-sidebar .signup_form .form-group svg {
        position: absolute;
        margin-top: 0.7rem;
        margin-left: 0.7rem;
        color: #007bff; }
      .home-signup .signup-sidebar .signup_form .form-group .email {
        position: absolute;
        margin-top: 0.85rem; }
      .home-signup .signup-sidebar .signup_form .form-control {
        padding-left: 2.5rem; }
        .home-signup .signup-sidebar .signup_form .form-control::-webkit-input-placeholder {
          font-size: 1rem; }
        .home-signup .signup-sidebar .signup_form .form-control::placeholder {
          font-size: 1rem; }
      .home-signup .signup-sidebar .signup_form #disabled-custom-disabled {
        background-color: #b5b5b5;
        color: white; }
      .home-signup .signup-sidebar .signup_form form {
        background-color: var(--primary-white);
        width: 100%;
        margin: 0 auto;
        padding: 1.5rem 1rem;
        border-radius: 2rem 2rem 0 0;
        display: grid;
        margin-top: 2rem; }
        .home-signup .signup-sidebar .signup_form form .error-message {
          color: var(--primary-alerts);
          padding-top: 1rem;
          font-size: 0.8rem;
          font-weight: 500; }
        .home-signup .signup-sidebar .signup_form form .is-invalid-form {
          border-color: red;
          background: #f4e6e1; }
        .home-signup .signup-sidebar .signup_form form input {
          width: 100%;
          height: 2.7rem;
          border: 1px solid var(--primary-gray-lighter);
          border-radius: 0.5rem; }
        .home-signup .signup-sidebar .signup_form form ::-webkit-input-placeholder {
          font-weight: 300;
          font-size: 1rem;
          color: #363636; }
        .home-signup .signup-sidebar .signup_form form ::placeholder {
          font-weight: 300;
          font-size: 1rem;
          color: #363636; }
        .home-signup .signup-sidebar .signup_form form svg {
          float: left;
          margin-left: 1rem;
          margin-top: -1.85rem;
          position: relative;
          z-index: 2;
          color: red; }
        .home-signup .signup-sidebar .signup_form form button {
          width: 100%;
          background-color: var(--primary-dark);
          color: white;
          padding: 0.7rem;
          border-radius: 0.5rem;
          font-family: 'Poppins';
          font-weight: 500;
          min-width: 100%;
          justify-content: center;
          margin-top: 2rem; }
      .home-signup .signup-sidebar .signup_form .aha {
        text-align: center;
        text-decoration: underline;
        font-weight: 500;
        margin-top: 1rem;
        color: #343434; }
  .home-signup .s-content {
    width: 67%;
    background-color: var(--primary-white);
    padding: 2rem;
    border-radius: 0.8rem;
    min-height: 100vh;
    margin-left: 33%;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    display: none; }
    .home-signup .s-content .login_content {
      width: 100%;
      display: grid;
      justify-items: center;
      grid-gap: 1rem;
      gap: 1rem; }
      .home-signup .s-content .login_content .illustration {
        width: 60%; }
      .home-signup .s-content .login_content h1 {
        text-align: center;
        margin: 0;
        font-weight: 700;
        font-size: 1.75rem;
        font-family: var(--serif-font-family);
        width: 65%; }
      .home-signup .s-content .login_content p {
        text-align: center;
        max-width: 26rem;
        font-weight: 300;
        font-size: 1rem; }

.validation {
  color: var(--primary-alerts);
  padding-top: 1rem;
  font-size: 0.8rem;
  font-weight: 500; }

@media (min-width: 992px) {
  .home-signup .signup-sidebar {
    position: fixed;
    top: 0;
    left: 0;
    min-width: 33%;
    width: 36%;
    z-index: 1;
    height: 100%;
    padding: 1rem 2.31rem 1rem 2.31rem; }
    .home-signup .signup-sidebar .signup_form {
      width: 100%;
      display: flex;
      flex-direction: column;
      position: relative;
      bottom: 0;
      min-height: 100%; }
      .home-signup .signup-sidebar .signup_form .form_c {
        display: flex;
        flex-direction: column;
        min-width: 100%;
        margin: auto;
        padding: 0 1rem; }
        .home-signup .signup-sidebar .signup_form .form_c .terms {
          text-align: center;
          font-weight: 300;
          font-size: 0.7rem; }
        .home-signup .signup-sidebar .signup_form .form_c h3 {
          font-family: var(--serif-font-family);
          font-weight: bold;
          font-size: 2rem;
          color: var(--pure-white);
          padding: 0rem; }
      .home-signup .signup-sidebar .signup_form p {
        font-size: 1rem;
        font-weight: 300;
        color: var(--pure-white);
        padding: 0rem;
        padding-top: 0.5rem; }
      .home-signup .signup-sidebar .signup_form .ca {
        font-weight: 600;
        font-size: 1.1rem;
        margin-top: 1rem; }
      .home-signup .signup-sidebar .signup_form .form-group svg {
        position: absolute;
        margin-top: 0.8rem;
        margin-left: 0.7rem;
        color: #007bff; }
      .home-signup .signup-sidebar .signup_form .form-control {
        padding-left: 2.5rem; }
      .home-signup .signup-sidebar .signup_form form {
        background-color: transparent;
        width: 100%;
        margin: 0 auto;
        padding: 1rem 0;
        border-radius: 2rem 2rem 0 0;
        display: grid; }
        .home-signup .signup-sidebar .signup_form form .form-title {
          font-weight: 600;
          color: var(--pure-white); }
        .home-signup .signup-sidebar .signup_form form input {
          width: 100%;
          height: 2.7rem;
          border: 1px solid var(--primary-gray-lighter);
          border-radius: 0.5rem; }
        .home-signup .signup-sidebar .signup_form form ::-webkit-input-placeholder {
          font-weight: 300;
          font-size: 1rem;
          color: #6d786e; }
        .home-signup .signup-sidebar .signup_form form ::placeholder {
          font-weight: 300;
          font-size: 1rem;
          color: #6d786e; }
        .home-signup .signup-sidebar .signup_form form svg {
          float: left;
          margin-left: 1rem;
          margin-top: -1.85rem;
          position: relative;
          z-index: 2;
          color: red; }
        .home-signup .signup-sidebar .signup_form form button {
          width: 100%;
          background-color: transparent;
          color: var(--primary-white);
          padding: 0.7rem;
          border: 1px solid var(--primary-white);
          border-radius: 0.5rem;
          font-family: 'Poppins';
          font-weight: 500;
          min-width: 100%;
          justify-content: center;
          margin-top: 0.8rem; }
      .home-signup .signup-sidebar .signup_form .aha {
        text-align: center;
        text-decoration: underline;
        font-weight: 500;
        margin-top: 1rem;
        color: var(--pure-white);
        font-size: 0.8rem; }
  .home-signup .s-content {
    display: grid; } }

h1 {
  text-align: left; }

p {
  font-family: var(--sans-serif-font-family);
  color: #343434; }

::-webkit-input-placeholder {
  font-size: 1rem; }

::placeholder {
  font-size: 1rem; }

.home-subscription-invitation {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  background-color: var(--primary-white);
  width: 100%;
  overflow: hidden; }
  .home-subscription-invitation .login_sidebar {
    background-color: #f0ece8;
    position: absolute;
    top: 0;
    left: 0;
    min-width: 100%;
    z-index: 1;
    height: 100%;
    padding: 1rem 2.31rem 1rem 2.31rem; }
    .home-subscription-invitation .login_sidebar .logo {
      width: 5rem;
      margin-top: 1rem; }
    .home-subscription-invitation .login_sidebar .login_form {
      height: 100%;
      display: grid;
      align-items: center; }
      .home-subscription-invitation .login_sidebar .login_form .form_c {
        display: grid;
        justify-content: start; }
        .home-subscription-invitation .login_sidebar .login_form .form_c .fake_carousel {
          justify-self: center;
          display: inline-flex;
          margin-top: 1.5rem;
          grid-gap: 0.8rem;
          gap: 0.8rem; }
        .home-subscription-invitation .login_sidebar .login_form .form_c .terms {
          text-align: center;
          font-weight: 300;
          font-size: 0.7rem; }
        .home-subscription-invitation .login_sidebar .login_form .form_c .welcome {
          margin: 0;
          margin-bottom: 0.7rem; }
      .home-subscription-invitation .login_sidebar .login_form h3 {
        font-family: var(--serif-font-family);
        font-weight: bold;
        font-size: 1.626rem; }
      .home-subscription-invitation .login_sidebar .login_form p {
        font-size: 1rem;
        font-weight: 300; }
      .home-subscription-invitation .login_sidebar .login_form .ca {
        font-weight: 600;
        font-size: 1.1rem;
        margin-top: 0.5rem; }
      .home-subscription-invitation .login_sidebar .login_form .form-group svg {
        position: absolute;
        margin-top: 0.7rem;
        margin-left: 0.7rem;
        color: #007bff; }
      .home-subscription-invitation .login_sidebar .login_form .form-control {
        padding-left: 2.5rem; }
      .home-subscription-invitation .login_sidebar .login_form form {
        margin-top: 0.4rem; }
        .home-subscription-invitation .login_sidebar .login_form form input {
          width: 100%;
          height: 2.7rem;
          border: 1px solid var(--primary-gray-lighter);
          border-radius: 0.5rem; }
        .home-subscription-invitation .login_sidebar .login_form form ::-webkit-input-placeholder {
          font-weight: 300;
          font-size: 1rem;
          color: #6d786e; }
        .home-subscription-invitation .login_sidebar .login_form form ::placeholder {
          font-weight: 300;
          font-size: 1rem;
          color: #6d786e; }
        .home-subscription-invitation .login_sidebar .login_form form svg {
          float: left;
          margin-left: 1rem;
          margin-top: -1.85rem;
          position: relative;
          z-index: 2;
          color: red; }
        .home-subscription-invitation .login_sidebar .login_form form button {
          width: 100%;
          background-color: #343434;
          color: white;
          margin-top: 1rem;
          padding: 0.7rem;
          border-radius: 0.5rem;
          font-family: 'Poppins';
          font-weight: 500;
          min-width: 100%;
          justify-content: center; }
      .home-subscription-invitation .login_sidebar .login_form .aha {
        text-align: center;
        text-decoration: underline;
        font-weight: 500;
        margin-top: 1rem;
        color: #343434; }
      .home-subscription-invitation .login_sidebar .login_form .subscription {
        display: flex;
        flex-direction: column;
        margin: 0;
        margin-top: 2rem; }
        .home-subscription-invitation .login_sidebar .login_form .subscription p {
          font-size: 1rem;
          font-weight: 500; }
        .home-subscription-invitation .login_sidebar .login_form .subscription .subscription-plan {
          padding: 0.2rem 0.5rem 0 0.5rem;
          display: flex;
          align-items: flex-end;
          width: 100%;
          justify-content: space-between; }
          .home-subscription-invitation .login_sidebar .login_form .subscription .subscription-plan .tag {
            background-color: var(--primary-green);
            color: white;
            max-width: -webkit-min-content;
            max-width: min-content;
            padding: 0.2rem;
            margin: 0;
            margin-bottom: 0.5rem;
            border-radius: 0.5rem;
            font-size: 0.7rem;
            font-weight: 500; }
          .home-subscription-invitation .login_sidebar .login_form .subscription .subscription-plan .price {
            font-size: 0.875rem; }
  .home-subscription-invitation .content {
    width: 67%;
    background-color: var(--primary-white);
    padding: 2rem;
    border-radius: 0.8rem;
    min-height: 100vh;
    margin-left: 33%;
    display: grid;
    justify-content: center;
    align-items: center;
    overflow: hidden; }
    .home-subscription-invitation .content .login_content {
      width: 100%;
      display: grid;
      justify-items: center;
      grid-gap: 1rem;
      gap: 1rem; }
      .home-subscription-invitation .content .login_content .woman {
        width: 24rem; }
      .home-subscription-invitation .content .login_content h1 {
        text-align: center;
        margin: 0;
        font-weight: 500;
        font-size: 2rem; }
      .home-subscription-invitation .content .login_content p {
        text-align: center;
        max-width: 26rem;
        font-weight: 300; }

.validation {
  color: red;
  padding-top: 1rem; }

@media (min-width: 992px) {
  .home-subscription-invitation .login_sidebar {
    background-color: #f0ece8;
    position: fixed;
    top: 0;
    left: 0;
    min-width: 33%;
    width: 33%;
    z-index: 1;
    height: 100%;
    padding: 1rem 2.31rem 1rem 2.31rem; }
  .content {
    visibility: visible; } }

h1 {
  text-align: left; }

p {
  font-family: var(--sans-serif-font-family);
  color: #343434; }

::-webkit-input-placeholder {
  font-size: 1rem; }

::placeholder {
  font-size: 1rem; }

.home-subscription-invitation-checkout {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  background-color: var(--primary-white);
  width: 100%;
  overflow: hidden; }
  .home-subscription-invitation-checkout #payment-message {
    color: var(--primary-alerts);
    padding-top: 1rem;
    font-size: 0.8rem;
    font-weight: 500; }
  .home-subscription-invitation-checkout .login_sidebar {
    position: relative;
    background: var(--primary-dark);
    top: 0;
    left: 0;
    min-width: 100%;
    z-index: 1;
    height: 100%;
    padding: 1rem 0rem; }
    .home-subscription-invitation-checkout .login_sidebar .logo {
      width: 7rem;
      margin-top: 1rem;
      padding-left: 1rem; }
    .home-subscription-invitation-checkout .login_sidebar .login_form {
      width: 100%;
      display: flex;
      flex-direction: column;
      position: absolute;
      bottom: 0; }
      .home-subscription-invitation-checkout .login_sidebar .login_form .subscription-noble-intro {
        padding: 1.5rem 1rem; }
        .home-subscription-invitation-checkout .login_sidebar .login_form .subscription-noble-intro h3 {
          font-family: var(--serif-font-family);
          font-weight: bold;
          font-size: 1.626rem; }
        .home-subscription-invitation-checkout .login_sidebar .login_form .subscription-noble-intro p {
          font-size: 1rem;
          font-weight: 300;
          color: var(--primary-white); }
        .home-subscription-invitation-checkout .login_sidebar .login_form .subscription-noble-intro .welcome {
          margin: 0;
          margin-bottom: 0.7rem;
          color: var(--primary-white); }
      .home-subscription-invitation-checkout .login_sidebar .login_form .form_c {
        display: flex;
        flex-direction: column;
        min-width: 100%;
        background-color: var(--primary-white);
        width: 100%;
        margin: 0 auto;
        padding: 1.5rem 1rem;
        border-radius: 2rem 2rem 0 0;
        padding-bottom: 3rem; }
        .home-subscription-invitation-checkout .login_sidebar .login_form .form_c .terms {
          text-align: center;
          font-weight: 300;
          font-size: 0.7rem; }
      .home-subscription-invitation-checkout .login_sidebar .login_form .ca {
        font-weight: 600;
        font-size: 1.1rem;
        margin-top: 0.5rem; }
      .home-subscription-invitation-checkout .login_sidebar .login_form .form-group svg {
        position: absolute;
        margin-top: 0.7rem;
        margin-left: 0.7rem;
        color: var(--primary-green); }
      .home-subscription-invitation-checkout .login_sidebar .login_form .form-control {
        padding-left: 2.5rem; }
      .home-subscription-invitation-checkout .login_sidebar .login_form .pre-checkout-price {
        padding-top: 2rem;
        display: flex;
        justify-content: space-between;
        align-items: center; }
        .home-subscription-invitation-checkout .login_sidebar .login_form .pre-checkout-price .check-description {
          font-size: 0.7rem;
          padding-right: 3rem;
          margin: 0; }
        .home-subscription-invitation-checkout .login_sidebar .login_form .pre-checkout-price .paying-now {
          display: grid;
          justify-items: flex-end;
          grid-gap: 0.5rem;
          gap: 0.5rem; }
          .home-subscription-invitation-checkout .login_sidebar .login_form .pre-checkout-price .paying-now p {
            font-size: 0.7rem;
            width: -webkit-max-content;
            width: max-content;
            margin: 0; }
          .home-subscription-invitation-checkout .login_sidebar .login_form .pre-checkout-price .paying-now h3 {
            font-family: var(--sans-serif-font-family);
            font-weight: 600;
            font-size: 2rem; }
      .home-subscription-invitation-checkout .login_sidebar .login_form form {
        background-color: var(--primary-white);
        width: 100%;
        margin: 0 auto;
        padding: 1.5rem 1rem;
        border-radius: 2rem 2rem 0 0; }
        .home-subscription-invitation-checkout .login_sidebar .login_form form .email-checkout {
          background-color: rgba(129, 129, 129, 0.349);
          color: var(--primary-white); }
        .home-subscription-invitation-checkout .login_sidebar .login_form form input {
          width: 100%;
          height: 2.7rem;
          border: 1px solid var(--primary-gray-lighter);
          border-radius: 0.5rem; }
        .home-subscription-invitation-checkout .login_sidebar .login_form form ::-webkit-input-placeholder {
          font-weight: 300;
          font-size: 1rem;
          color: #6d786e; }
        .home-subscription-invitation-checkout .login_sidebar .login_form form ::placeholder {
          font-weight: 300;
          font-size: 1rem;
          color: #6d786e; }
        .home-subscription-invitation-checkout .login_sidebar .login_form form svg {
          float: left;
          margin-left: 1rem;
          margin-top: 0.875rem;
          position: absolute;
          z-index: 2;
          color: red; }
        .home-subscription-invitation-checkout .login_sidebar .login_form form button {
          width: 100%;
          background-color: var(--primary-dark);
          color: var(--primary-white);
          margin-top: 1rem;
          padding: 0.7rem;
          border-radius: 0.5rem;
          font-family: 'Poppins';
          font-weight: 500;
          min-width: 100%;
          justify-content: center; }
        .home-subscription-invitation-checkout .login_sidebar .login_form form #card-element {
          margin-top: 1rem;
          width: 100%;
          border: 1px solid var(--primary-gray-lighter);
          border-radius: 0.5rem;
          padding: 0.8rem;
          font-size: 1rem;
          font-weight: 300;
          color: #6d786e;
          background-color: var(--pure-white); }
      .home-subscription-invitation-checkout .login_sidebar .login_form .aha {
        text-align: center;
        text-decoration: underline;
        font-weight: 500;
        margin-top: 1rem;
        color: #343434; }
      .home-subscription-invitation-checkout .login_sidebar .login_form .subscription {
        display: flex;
        margin: 0;
        align-items: center;
        justify-content: space-between;
        padding: 1rem 0; }
        .home-subscription-invitation-checkout .login_sidebar .login_form .subscription p {
          font-size: 1rem;
          font-weight: 500;
          font-weight: 300;
          max-width: 50%; }
        .home-subscription-invitation-checkout .login_sidebar .login_form .subscription .price {
          font-weight: 600;
          font-size: 1.25rem; }
      .home-subscription-invitation-checkout .login_sidebar .login_form hr {
        height: 0.06rem;
        background-color: #dfdede;
        width: 100%; }
      .home-subscription-invitation-checkout .login_sidebar .login_form .credit-cards {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 0.5rem; }
        .home-subscription-invitation-checkout .login_sidebar .login_form .credit-cards p {
          font-size: 1rem;
          font-weight: 500; }
        .home-subscription-invitation-checkout .login_sidebar .login_form .credit-cards .card-icons {
          display: flex;
          grid-gap: 0.5rem;
          gap: 0.5rem;
          align-items: center; }
  .home-subscription-invitation-checkout .s-content {
    width: 67%;
    background-color: var(--primary-white);
    padding: 2rem;
    border-radius: 0.8rem;
    min-height: 100vh;
    margin-left: 33%;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    display: none; }
    .home-subscription-invitation-checkout .s-content .login_content {
      width: 100%;
      display: grid;
      justify-items: center;
      grid-gap: 1rem;
      gap: 1rem; }
      .home-subscription-invitation-checkout .s-content .login_content .illustration {
        width: 60%; }
      .home-subscription-invitation-checkout .s-content .login_content h1 {
        text-align: center;
        margin: 0;
        font-weight: 700;
        font-size: 1.75rem;
        font-family: var(--serif-font-family);
        width: 65%; }
      .home-subscription-invitation-checkout .s-content .login_content p {
        text-align: center;
        max-width: 26rem;
        font-weight: 300;
        font-size: 1rem; }

.validation {
  color: red;
  padding-top: 1rem; }

#card-element {
  border-radius: 4px 4px 0 0;
  padding: 12px;
  border: 1px solid rgba(50, 50, 93, 0.1);
  max-height: 44px;
  width: 100%;
  background: white;
  box-sizing: border-box; }

@media (min-width: 992px) {
  .home-subscription-invitation-checkout .login_sidebar {
    position: fixed;
    top: 0;
    left: 0;
    min-width: 33%;
    width: 36%;
    z-index: 1;
    height: 100%;
    padding: 1rem 2.31rem 1rem 2.31rem; }
    .home-subscription-invitation-checkout .login_sidebar .check-description {
      font-size: 0.7rem;
      max-width: 50%;
      margin: 0; }
    .home-subscription-invitation-checkout .login_sidebar .login_form {
      width: 100%;
      display: flex;
      flex-direction: column;
      position: relative;
      bottom: 0;
      place-items: center;
      align-items: center;
      min-height: 100%;
      margin-top: 15%; }
      .home-subscription-invitation-checkout .login_sidebar .login_form .subscription-noble-intro {
        padding: 1.5rem 1rem;
        max-height: -webkit-max-content;
        max-height: max-content; }
        .home-subscription-invitation-checkout .login_sidebar .login_form .subscription-noble-intro h3 {
          font-family: var(--serif-font-family);
          font-weight: bold;
          font-size: 1.626rem;
          margin: 0;
          margin-bottom: 0.7rem;
          color: var(--primary-white); }
        .home-subscription-invitation-checkout .login_sidebar .login_form .subscription-noble-intro p {
          font-size: 1rem;
          font-weight: 300;
          color: var(--primary-white); }
      .home-subscription-invitation-checkout .login_sidebar .login_form .form_c {
        display: flex;
        flex-direction: column;
        padding: 0 1rem;
        background-color: transparent; }
        .home-subscription-invitation-checkout .login_sidebar .login_form .form_c .subscription p {
          color: var(--primary-white); }
        .home-subscription-invitation-checkout .login_sidebar .login_form .form_c .credit-cards p {
          color: var(--primary-white); }
        .home-subscription-invitation-checkout .login_sidebar .login_form .form_c form {
          background-color: transparent;
          padding: 0; }
          .home-subscription-invitation-checkout .login_sidebar .login_form .form_c form button {
            background-color: var(--primary-white);
            color: var(--primary-dark); }
        .home-subscription-invitation-checkout .login_sidebar .login_form .form_c .pre-checkout-price p,
        .home-subscription-invitation-checkout .login_sidebar .login_form .form_c .pre-checkout-price h3 {
          color: var(--primary-white); }
  .home-subscription-invitation-checkout .s-content {
    display: grid; } }

h1 {
  text-align: left; }

p {
  font-family: var(--sans-serif-font-family);
  color: #343434; }

::-webkit-input-placeholder {
  font-size: 1rem; }

::placeholder {
  font-size: 1rem; }

.home-onboarding-confirmation {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  background-color: var(--primary-white);
  width: 100%;
  overflow: hidden; }
  .home-onboarding-confirmation .login_sidebar {
    position: relative;
    background: var(--primary-dark);
    top: 0;
    left: 0;
    min-width: 100%;
    z-index: 1;
    height: 100%;
    padding: 1rem 0rem; }
    .home-onboarding-confirmation .login_sidebar .logo {
      width: 6.5rem;
      margin-top: 1rem;
      display: none; }
    .home-onboarding-confirmation .login_sidebar .login_form {
      width: 100%;
      display: flex;
      flex-direction: column;
      position: absolute;
      bottom: 0; }
      .home-onboarding-confirmation .login_sidebar .login_form .success-block {
        padding-bottom: 1.5rem; }
      .home-onboarding-confirmation .login_sidebar .login_form .form_c {
        display: flex;
        flex-direction: column;
        min-width: 100%;
        background-color: var(--primary-white);
        width: 100%;
        margin: 0 auto;
        padding: 1.5rem 1rem;
        border-radius: 2rem 2rem 0 0;
        padding-bottom: 20%; }
        .home-onboarding-confirmation .login_sidebar .login_form .form_c button {
          width: 100%;
          background-color: var(--primary-dark);
          color: white;
          padding: 0.7rem;
          border-radius: 0.5rem;
          font-family: 'Poppins';
          font-weight: 500;
          min-width: 100%;
          justify-content: center; }
        .home-onboarding-confirmation .login_sidebar .login_form .form_c .fake_carousel {
          justify-self: center;
          display: inline-flex;
          margin-top: 1.5rem;
          grid-gap: 0.8rem;
          gap: 0.8rem; }
        .home-onboarding-confirmation .login_sidebar .login_form .form_c .welcome {
          margin: 0;
          margin-bottom: 0.7rem;
          font-family: var(--serif-font-family);
          font-weight: bold;
          font-size: 2.625rem;
          color: var(--pure-white);
          padding: 0 1rem; }
        .home-onboarding-confirmation .login_sidebar .login_form .form_c .subscription-details {
          display: flex;
          flex-direction: column; }
          .home-onboarding-confirmation .login_sidebar .login_form .form_c .subscription-details p {
            font-weight: 500;
            margin: 0;
            color: var(--primary-dark); }
          .home-onboarding-confirmation .login_sidebar .login_form .form_c .subscription-details hr {
            margin-top: 1.5rem;
            margin-bottom: 1.5rem; }
          .home-onboarding-confirmation .login_sidebar .login_form .form_c .subscription-details .details-grid {
            display: grid;
            width: 100%;
            grid-gap: 2.5rem;
            gap: 2.5rem; }
            .home-onboarding-confirmation .login_sidebar .login_form .form_c .subscription-details .details-grid .billing-item {
              display: flex;
              width: 100%;
              justify-content: space-between; }
              .home-onboarding-confirmation .login_sidebar .login_form .form_c .subscription-details .details-grid .billing-item p {
                font-size: 0.8rem; }
              .home-onboarding-confirmation .login_sidebar .login_form .form_c .subscription-details .details-grid .billing-item .desc {
                font-weight: 300; }
          .home-onboarding-confirmation .login_sidebar .login_form .form_c .subscription-details .legals {
            text-decoration: underline;
            font-size: 0.875rem;
            padding-bottom: 1.5rem;
            font-weight: 300;
            color: var(--pure-white); }
      .home-onboarding-confirmation .login_sidebar .login_form h3 {
        font-family: var(--serif-font-family);
        font-weight: bold;
        font-size: 2.625rem;
        color: var(--pure-white);
        padding: 0 1rem; }
      .home-onboarding-confirmation .login_sidebar .login_form p {
        font-size: 1rem;
        font-weight: 300;
        padding: 0 1rem;
        color: var(--pure-white); }
      .home-onboarding-confirmation .login_sidebar .login_form .ca {
        font-weight: 600;
        font-size: 1.1rem;
        margin-top: 1rem; }
      .home-onboarding-confirmation .login_sidebar .login_form .form-group {
        margin: 0.5rem 0rem; }
        .home-onboarding-confirmation .login_sidebar .login_form .form-group svg {
          position: absolute;
          margin-top: 0.7rem;
          margin-left: 0.7rem;
          color: #007bff; }
      .home-onboarding-confirmation .login_sidebar .login_form .form-control {
        padding-left: 2.5rem; }
      .home-onboarding-confirmation .login_sidebar .login_form .pre-checkout-price {
        padding-top: 2rem;
        display: flex;
        justify-items: space-between;
        align-items: center; }
        .home-onboarding-confirmation .login_sidebar .login_form .pre-checkout-price .check-description {
          font-size: 0.7rem;
          padding-right: 3rem;
          margin: 0; }
        .home-onboarding-confirmation .login_sidebar .login_form .pre-checkout-price .paying-now {
          display: grid;
          justify-items: flex-end;
          grid-gap: 0.5rem;
          gap: 0.5rem; }
          .home-onboarding-confirmation .login_sidebar .login_form .pre-checkout-price .paying-now p {
            font-size: 0.7rem;
            width: -webkit-max-content;
            width: max-content;
            margin: 0; }
          .home-onboarding-confirmation .login_sidebar .login_form .pre-checkout-price .paying-now h3 {
            font-family: var(--sans-serif-font-family);
            font-weight: 600;
            font-size: 2rem; }
      .home-onboarding-confirmation .login_sidebar .login_form .aha {
        text-align: center;
        text-decoration: underline;
        font-weight: 500;
        margin-top: 1rem;
        color: #343434; }
      .home-onboarding-confirmation .login_sidebar .login_form .subscription {
        display: flex;
        flex-direction: column;
        margin: 0; }
        .home-onboarding-confirmation .login_sidebar .login_form .subscription p {
          font-size: 1rem;
          font-weight: 500; }
        .home-onboarding-confirmation .login_sidebar .login_form .subscription .subscription-plan {
          padding: 0.2rem 0.5rem 0 0.5rem;
          display: flex;
          align-items: flex-end;
          width: 100%;
          justify-content: space-between; }
          .home-onboarding-confirmation .login_sidebar .login_form .subscription .subscription-plan .tag {
            background-color: var(--primary-green);
            color: white;
            max-width: -webkit-min-content;
            max-width: min-content;
            padding: 0.2rem;
            margin: 0;
            margin-bottom: 0.5rem;
            border-radius: 0.5rem;
            font-size: 0.7rem;
            font-weight: 500; }
          .home-onboarding-confirmation .login_sidebar .login_form .subscription .subscription-plan .price {
            font-size: 0.875rem; }
      .home-onboarding-confirmation .login_sidebar .login_form hr {
        height: 0.11rem;
        background-color: #dfdede;
        width: 100%; }
  .home-onboarding-confirmation .s-content {
    width: 67%;
    background-color: var(--primary-white);
    padding: 2rem;
    border-radius: 0.8rem;
    min-height: 100vh;
    margin-left: 33%;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    display: none; }
    .home-onboarding-confirmation .s-content .login_content {
      width: 100%;
      display: grid;
      justify-items: center;
      grid-gap: 0.5rem;
      gap: 0.5rem; }
      .home-onboarding-confirmation .s-content .login_content .illustration {
        width: 60%; }
      .home-onboarding-confirmation .s-content .login_content h1 {
        text-align: center;
        margin: 0;
        font-weight: 700;
        font-size: 1.75rem;
        font-family: var(--serif-font-family);
        width: 65%; }
      .home-onboarding-confirmation .s-content .login_content p {
        text-align: center;
        max-width: 26rem;
        font-weight: 300;
        font-size: 1rem; }

.validation {
  color: red;
  padding-top: 1rem; }

#card-element {
  border-radius: 4px 4px 0 0;
  padding: 12px;
  border: 1px solid rgba(50, 50, 93, 0.1);
  max-height: 44px;
  width: 100%;
  background: white;
  box-sizing: border-box; }

@media (min-width: 992px) {
  .home-onboarding-confirmation .login_sidebar {
    position: fixed;
    top: 0;
    left: 0;
    min-width: 33%;
    width: 36%;
    z-index: 1;
    height: 100%;
    padding: 1rem 2.31rem 1rem 2.31rem; }
    .home-onboarding-confirmation .login_sidebar .logo {
      display: block; }
    .home-onboarding-confirmation .login_sidebar .login_form {
      width: 100%;
      display: grid;
      grid-gap: 1rem;
      gap: 1rem;
      position: relative;
      bottom: 0;
      margin-top: 15%; }
      .home-onboarding-confirmation .login_sidebar .login_form .form_c {
        display: flex;
        flex-direction: column;
        min-width: 100%;
        margin: auto;
        padding: 0 1rem;
        background-color: transparent;
        width: 100%;
        margin: 0 auto;
        padding: 1rem 0;
        border-radius: 2rem 2rem 0 0; }
        .home-onboarding-confirmation .login_sidebar .login_form .form_c .subscription-details p {
          color: var(--primary-white); }
        .home-onboarding-confirmation .login_sidebar .login_form .form_c button {
          background-color: var(--primary-white);
          color: var(--primary-dark); }
      .home-onboarding-confirmation .login_sidebar .login_form h3 {
        font-family: var(--serif-font-family);
        font-weight: bold;
        font-size: 2.625rem;
        color: var(--pure-white);
        padding: 0rem; }
      .home-onboarding-confirmation .login_sidebar .login_form p {
        font-size: 1rem;
        font-weight: 300;
        color: var(--pure-white);
        padding: 0rem; }
      .home-onboarding-confirmation .login_sidebar .login_form .ca {
        font-weight: 600;
        font-size: 1.1rem;
        margin-top: 1rem; }
      .home-onboarding-confirmation .login_sidebar .login_form .form-group {
        margin: 0.8rem 0rem; }
        .home-onboarding-confirmation .login_sidebar .login_form .form-group svg {
          position: absolute;
          margin-top: 0.7rem;
          margin-left: 0.7rem;
          color: #007bff; }
      .home-onboarding-confirmation .login_sidebar .login_form .form-control {
        padding-left: 2.5rem; }
      .home-onboarding-confirmation .login_sidebar .login_form .aha {
        text-align: center;
        text-decoration: underline;
        font-weight: 500;
        margin-top: 1rem;
        color: var(--pure-white);
        font-size: 0.8rem; }
    .home-onboarding-confirmation .login_sidebar .check-description {
      font-size: 0.7rem;
      padding-right: 8rem;
      margin: 0; }
  .home-onboarding-confirmation .s-content {
    display: grid;
    margin-top: -1rem; }
    .home-onboarding-confirmation .s-content .login_content .welcome-desc {
      max-width: 75%; }
    .home-onboarding-confirmation .s-content .login_content .parent-roadmap-image {
      width: 100%;
      background-color: var(--pure-white);
      border-radius: 0 0 1rem 1rem; }
      .home-onboarding-confirmation .s-content .login_content .parent-roadmap-image .roadmap-image .button-and-text {
        bottom: 0;
        left: 0;
        position: 'relative';
        padding: 0 0 2.2rem 1rem; }
        .home-onboarding-confirmation .s-content .login_content .parent-roadmap-image .roadmap-image .button-and-text h1 {
          color: var(--pure-white);
          text-align: left;
          padding-bottom: 1rem; }
        .home-onboarding-confirmation .s-content .login_content .parent-roadmap-image .roadmap-image .button-and-text button {
          background-color: var(--pure-white);
          color: var(--primary-dark);
          display: flex;
          width: -webkit-max-content;
          width: max-content;
          padding: 0.15rem;
          align-items: center;
          border-radius: 10rem;
          grid-gap: 0.5rem;
          gap: 0.5rem;
          padding-right: 1rem;
          font-weight: 500; }
      .home-onboarding-confirmation .s-content .login_content .parent-roadmap-image .description {
        position: relative;
        left: 0;
        text-align: left;
        padding: 1rem;
        font-weight: 300; } }

h1 {
  text-align: left; }

p {
  font-family: var(--sans-serif-font-family);
  color: #343434; }

.coach-signup {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  background-color: var(--primary-white);
  width: 100%;
  overflow: hidden; }
  .coach-signup .login_sidebar {
    background-color: #f0ece8;
    position: absolute;
    top: 0;
    left: 0;
    min-width: 100%;
    z-index: 1;
    height: 100%;
    padding: 1rem 2.31rem 1rem 2.31rem; }
    .coach-signup .login_sidebar .logo {
      width: 5rem;
      margin-top: 1rem;
      margin-bottom: 1rem; }
    .coach-signup .login_sidebar .login_form {
      height: 100%;
      display: grid;
      align-items: center; }
      .coach-signup .login_sidebar .login_form .form_c {
        display: grid; }
        .coach-signup .login_sidebar .login_form .form_c .fake_carousel {
          justify-self: center;
          display: inline-flex;
          margin-top: 1.5rem;
          grid-gap: 0.8rem;
          gap: 0.8rem; }
        .coach-signup .login_sidebar .login_form .form_c .terms {
          text-align: center;
          font-weight: 300;
          font-size: 0.7rem; }
      .coach-signup .login_sidebar .login_form h3 {
        font-family: var(--serif-font-family);
        font-weight: bold;
        font-size: 1.626rem;
        margin: 0; }
      .coach-signup .login_sidebar .login_form p {
        font-size: 1rem;
        font-weight: 300; }
      .coach-signup .login_sidebar .login_form .ca {
        font-weight: 600;
        font-size: 1.1rem;
        margin-top: 1rem; }
      .coach-signup .login_sidebar .login_form .form-group svg {
        position: absolute;
        margin-top: 0.7rem;
        margin-left: 0.7rem;
        color: #007bff; }
      .coach-signup .login_sidebar .login_form .form-control {
        padding-left: 2.5rem; }
      .coach-signup .login_sidebar .login_form form input {
        width: 100%;
        height: 2.7rem;
        margin-top: 1rem;
        border: 1px solid var(--primary-gray-lighter);
        border-radius: 0.5rem; }
      .coach-signup .login_sidebar .login_form form ::-webkit-input-placeholder {
        font-weight: 300;
        font-size: 1rem;
        color: #6d786e; }
      .coach-signup .login_sidebar .login_form form ::placeholder {
        font-weight: 300;
        font-size: 1rem;
        color: #6d786e; }
      .coach-signup .login_sidebar .login_form form svg {
        float: left;
        margin-left: 1rem;
        margin-top: -1.85rem;
        position: relative;
        z-index: 2;
        color: red; }
      .coach-signup .login_sidebar .login_form form button {
        width: 100%;
        background-color: #343434;
        color: white;
        margin-top: 1rem;
        padding: 0.7rem;
        border-radius: 0.5rem;
        font-family: 'Poppins';
        font-weight: 500;
        min-width: 100%;
        justify-content: center; }
      .coach-signup .login_sidebar .login_form .aha {
        text-align: center;
        text-decoration: underline;
        font-weight: 500;
        margin-top: 1rem;
        color: #343434; }
  .coach-signup .content {
    width: 67%;
    background-color: var(--primary-white);
    padding: 2rem;
    border-radius: 0.8rem;
    min-height: 100vh;
    margin-left: 33%;
    display: grid;
    justify-content: center;
    align-items: center;
    overflow: hidden; }
    .coach-signup .content .login_content {
      width: 100%;
      display: grid;
      justify-items: center;
      grid-gap: 1rem;
      gap: 1rem; }
      .coach-signup .content .login_content .woman {
        width: 24rem; }
      .coach-signup .content .login_content h1 {
        text-align: center;
        margin: 0;
        font-weight: 500;
        font-size: 2rem; }
      .coach-signup .content .login_content p {
        text-align: center;
        max-width: 26rem;
        font-weight: 300; }

.validation {
  color: red;
  padding-top: 1rem; }

@media (min-width: 992px) {
  .coach-signup .login_sidebar {
    background-color: #f0ece8;
    position: fixed;
    top: 0;
    left: 0;
    min-width: 33%;
    width: 33%;
    z-index: 1;
    height: 100%;
    padding: 1rem 2.31rem 1rem 2.31rem; }
  .content {
    visibility: visible; } }

.home-signup .signup-sidebar .signup_form .agreements-checkbox-wrapper {
  padding-bottom: 1rem; }
  @media (min-width: 768px) {
    .home-signup .signup-sidebar .signup_form .agreements-checkbox-wrapper {
      grid-column: span 2 / auto; } }
  .home-signup .signup-sidebar .signup_form .agreements-checkbox-wrapper ul {
    padding-left: 2.5rem;
    font-size: 0.8rem;
    color: var(--pure-white);
    font-weight: 300;
    margin-top: -1rem;
    opacity: 0.85; }
    @media (max-width: 991.98px) {
      .home-signup .signup-sidebar .signup_form .agreements-checkbox-wrapper ul {
        color: var(--primary-dark); } }
    .home-signup .signup-sidebar .signup_form .agreements-checkbox-wrapper ul a {
      color: var(--pure-white);
      text-decoration: underline;
      font-weight: 300;
      opacity: 0.85; }
      @media (max-width: 991.98px) {
        .home-signup .signup-sidebar .signup_form .agreements-checkbox-wrapper ul a {
          color: var(--primary-dark); } }
  .home-signup .signup-sidebar .signup_form .agreements-checkbox-wrapper .agreements-checkbox {
    display: flex;
    align-items: center;
    grid-gap: 1.5rem;
    gap: 1.5rem;
    width: 100%;
    margin-top: 0.5rem; }
    .home-signup .signup-sidebar .signup_form .agreements-checkbox-wrapper .agreements-checkbox input[type='checkbox'] {
      margin-left: 0.5rem;
      margin-top: 0.5rem; }
      .home-signup .signup-sidebar .signup_form .agreements-checkbox-wrapper .agreements-checkbox input[type='checkbox']::after {
        width: 1.25rem;
        height: 1.25rem; }
      .home-signup .signup-sidebar .signup_form .agreements-checkbox-wrapper .agreements-checkbox input[type='checkbox']:checked::after {
        background-color: #8d8d8d;
        border-color: #8d8d8d; }
        @media (max-width: 991.98px) {
          .home-signup .signup-sidebar .signup_form .agreements-checkbox-wrapper .agreements-checkbox input[type='checkbox']:checked::after {
            background-color: var(--primary-dark); } }
    .home-signup .signup-sidebar .signup_form .agreements-checkbox-wrapper .agreements-checkbox .agreement-text {
      width: 100%;
      padding: 0;
      font-size: 0.8rem;
      color: var(--pure-white);
      font-weight: 500; }
      @media (max-width: 991.98px) {
        .home-signup .signup-sidebar .signup_form .agreements-checkbox-wrapper .agreements-checkbox .agreement-text {
          color: var(--primary-dark); } }
    .home-signup .signup-sidebar .signup_form .agreements-checkbox-wrapper .agreements-checkbox a {
      color: var(--pure-white);
      text-decoration: underline;
      font-weight: 300; }
      @media (max-width: 991.98px) {
        .home-signup .signup-sidebar .signup_form .agreements-checkbox-wrapper .agreements-checkbox a {
          color: var(--primary-dark); } }

h1 {
  text-align: left; }

h1 {
  text-align: left; }

.home-campaigns.wrapper {
  display: grid;
  grid-gap: 1.8rem;
  gap: 1.8rem;
  grid-template-areas: 'sidebar' 'content'; }

.home-campaigns .home-campaigns-sidebar {
  grid-area: sidebar; }

.home-campaigns .home-campaigns-main-content {
  grid-area: content;
  background-color: var(--primary-white);
  padding: 2rem;
  border-radius: 0.8rem; }
  .home-campaigns .home-campaigns-main-content table {
    width: 100%;
    background-color: white;
    box-shadow: 1px 1px 20px 1px rgba(0, 0, 0, 0.05);
    border-radius: 0.6rem;
    margin-top: 2rem;
    display: table; }
    .home-campaigns .home-campaigns-main-content table td {
      vertical-align: center;
      padding: 1.5rem 1.5rem; }
    .home-campaigns .home-campaigns-main-content table th {
      padding: 1rem 0rem 1rem 1.5rem;
      font-weight: 300;
      font-size: 0.8rem;
      color: rgba(52, 52, 52, 0.5); }
    .home-campaigns .home-campaigns-main-content table tr {
      border-bottom: 1px solid rgba(52, 52, 52, 0.1); }
    .home-campaigns .home-campaigns-main-content table tbody {
      font-size: 0.87rem;
      font-weight: 300; }
  .home-campaigns .home-campaigns-main-content .main-header {
    display: flex;
    justify-content: space-between; }
    .home-campaigns .home-campaigns-main-content .main-header button {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.6rem;
      grid-gap: 0.5rem;
      grid-gap: 0.5rem;
      gap: 0.5rem;
      background-color: var(--primary-dark);
      color: var(--pure-white);
      font-size: 0.7rem;
      border-radius: 0.4rem; }
  .home-campaigns .home-campaigns-main-content tbody {
    height: calc(90vh - 310px);
    table-layout: fixed;
    display: block;
    overflow-y: auto;
    overflow-x: hidden; }
  .home-campaigns .home-campaigns-main-content thead,
  .home-campaigns .home-campaigns-main-content tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed; }

@media (min-width: 992px) {
  .home-campaigns.wrapper {
    grid-template-columns: 1fr 3fr;
    grid-template-areas: 'sidebar content'; } }

p.grey {
  color: #727272; }

h1 {
  font-weight: bold;
  font-size: 1.9rem;
  line-height: 41px; }

.desktop-form-container {
  background: white;
  border-radius: 0.5rem;
  min-width: 32rem;
  border-color: #cccccc; }

h1 {
  text-align: left; }

h1 {
  text-align: left; }

.common-header-container .static-placeholder {
  visibility: hidden; }

.common-header-container .fixed-actual-header {
  position: fixed;
  top: 0;
  z-index: 1000;
  width: 100%; }

.common-header {
  padding: 20px 15px 0;
  background: var(--primary-white);
  overflow-wrap: break-word; }
  .common-header a,
  .common-header .back-link {
    color: #3e3e3e; }
  .common-header .back-link-container a:hover {
    color: #3e3e3e;
    opacity: 1; }
  .common-header .back-link-container .svg-inline--fa .fa-secondary {
    opacity: 1; }
  .common-header h1 {
    font-weight: bold;
    text-align: center;
    font-size: 1.125rem;
    line-height: 20px;
    margin-bottom: 0; }
  .common-header button {
    font-size: 1rem; }
  .common-header .full {
    margin: 0;
    width: 100%;
    position: relative;
    left: -15px; }
  .common-header .row {
    align-items: center; }
  .common-header .back-link-container {
    padding-left: 0;
    padding-right: 0;
    text-align: center; }
    .common-header .back-link-container a {
      display: inline-block;
      min-width: 3rem; }
  .common-header .back-link {
    cursor: pointer; }

h1 {
  text-align: left; }

.common-bottom-nav .navbar {
  height: 4rem;
  background-color: var(--pure-white);
  border-top-left-radius: 2.5rem;
  border-top-right-radius: 2.5rem; }
  .common-bottom-nav .navbar span.badge {
    color: white;
    position: absolute;
    top: 58%;
    -webkit-transform: translate(12%, -150%);
            transform: translate(12%, -150%);
    font-size: 0.5rem;
    line-height: 1.3; }
    .common-bottom-nav .navbar span.badge.transparent-badge {
      color: transparent; }
  .common-bottom-nav .navbar .nav-link {
    color: #cccccc; }
  .common-bottom-nav .navbar .nav-item {
    flex-basis: 0; }

h1 {
  text-align: left; }

.common-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9; }
  .common-loader .spinner-border {
    color: #8d8d8d; }
  .common-loader.screen-center {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0; }
  .common-loader.local-center {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0; }

.dark-loader .spinner-border {
  color: var(--primary-dark); }

.light-loader .spinner-border {
  color: var(--pure-white); }

h1 {
  text-align: left; }

.common-network-detector {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80vh; }
  .common-network-detector .offline-description {
    text-align: center;
    color: #727272; }

h1 {
  text-align: left; }

.common-user-not-approved h2 {
  line-height: 1.5; }

.common-user-not-approved a {
  text-decoration: none;
  font-weight: normal; }

h1 {
  text-align: left; }

.common-user-avatar .profile-photo {
  display: flex;
  justify-content: center; }
  .common-user-avatar .profile-photo img {
    width: 3rem;
    height: 3rem;
    border-radius: 50%; }
  .common-user-avatar .profile-photo.xsmall img {
    width: 1.6rem;
    height: 1.6rem; }
  .common-user-avatar .profile-photo.small img {
    width: 2.35rem;
    height: 2.35rem; }
  .common-user-avatar .profile-photo.large img {
    width: 5rem;
    height: 5rem; }
  .common-user-avatar .profile-photo.xlarge img {
    width: 7rem;
    height: 7rem; }

.common-user-avatar .initials {
  background-color: var(--primary-dark-green); }
  .common-user-avatar .initials.small {
    width: 2rem;
    height: 2rem; }
    .common-user-avatar .initials.small span {
      font-size: 0.9rem;
      color: #ffffff; }
  .common-user-avatar .initials.xsmall {
    width: 1.6rem;
    height: 1.6rem; }
    .common-user-avatar .initials.xsmall span {
      font-size: 0.7rem; }
  .common-user-avatar .initials.large {
    width: 5rem;
    height: 5rem; }
    .common-user-avatar .initials.large span {
      font-size: 2rem;
      line-height: 2.75rem; }
  .common-user-avatar .initials.xlarge {
    width: 7rem;
    height: 7rem; }
    .common-user-avatar .initials.xlarge span {
      font-size: 3.25rem; }

h1 {
  text-align: left; }

.tab-selector {
  position: relative;
  z-index: 1; }
  .tab-selector::after {
    content: ' ';
    position: absolute;
    left: 0;
    right: 0;
    bottom: -3px; }
  .tab-selector .nav-link {
    line-height: 1;
    border-radius: 0;
    text-decoration: none;
    font-size: 0.875rem;
    padding-left: 0;
    padding-right: 0; }
  .tab-selector .nav-link {
    color: #aaaaaa; }
    .tab-selector .nav-link.active {
      color: var(--primary-dark);
      background-color: inherit; }
  .tab-selector .slider-container {
    position: relative; }
    @media (max-width: 991.98px) {
      .tab-selector .slider-container {
        top: 3px; } }
    .tab-selector .slider-container .slider {
      z-index: 1;
      position: absolute;
      height: 5px;
      bottom: 0;
      left: 0%;
      transition: left ease 0.2s;
      border-radius: 10px; }

h1 {
  text-align: left; }

.common-custom-dialog .btn-cancel {
  color: #3e3e3e; }
  .common-custom-dialog .btn-cancel:focus {
    border: none; }

h1 {
  text-align: left; }

.common-action-menu {
  z-index: 999999; }
  .common-action-menu .modal-dialog .modal-content {
    background-color: transparent;
    border: none;
    z-index: 9999999; }
    .common-action-menu .modal-dialog .modal-content .btn-menu-item {
      color: #3e3e3e; }
    .common-action-menu .modal-dialog .modal-content a:hover {
      opacity: 1; }
    .common-action-menu .modal-dialog .modal-content .show-rename-form-desktop + .border-thin {
      display: none; }
    @media (min-width: 992px) {
      .common-action-menu .modal-dialog .modal-content .show-rename-form-desktop + .border-thin {
        display: block; }
        .common-action-menu .modal-dialog .modal-content .show-rename-form-desktop + .border-thin + a + .border-thin {
          display: none; } }

.modal-desktop-open-center .modal-dialog {
  width: 400px; }

.modal-desktop-open-center .top-body {
  border-radius: 10px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

.modal-desktop-open-center .cancel {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-top: 1px solid #ddd; }

.modal-backdrop.show {
  opacity: 0.25; }

h1 {
  text-align: left; }

.common-search-bar {
  position: relative; }
  .common-search-bar .form-control {
    background-color: var(--primary-white);
    border-radius: 0.7rem; }
    .common-search-bar .form-control::-webkit-input-placeholder {
      font-style: normal;
      font-weight: 300;
      font-size: 16px; }
    .common-search-bar .form-control::placeholder {
      font-style: normal;
      font-weight: 300;
      font-size: 16px; }
    .common-search-bar .form-control:focus {
      border: 1px solid #eaeef5; }
  .common-search-bar .search-icon {
    position: absolute;
    left: 0.8rem;
    top: 0.85rem;
    font-size: 0.875rem;
    color: var(--primary-dark);
    display: flex;
    align-items: center;
    justify-content: center; }
    .common-search-bar .search-icon svg {
      vertical-align: middle; }

h1 {
  text-align: left; }

table.common-custom-table {
  border-radius: 10px;
  background-color: white;
  width: 100%; }
  table.common-custom-table thead th {
    border-bottom: 1px solid #eeeeee;
    padding: 1rem 0rem 1rem 0rem;
    font-weight: 300;
    font-size: 0.875rem;
    color: var(--primary-dark);
    white-space: nowrap;
    position: relative; }
    table.common-custom-table thead th svg {
      position: absolute;
      color: #727272; }
  table.common-custom-table thead th:not(:last-child) {
    border-right: none; }
  table.common-custom-table tbody tr {
    font-size: 0.875rem; }
    table.common-custom-table tbody tr td:first-child {
      color: #3e3e3e; }
    table.common-custom-table tbody tr td {
      color: #3e3e3e;
      vertical-align: middle;
      padding: 12px 5px;
      font-weight: 300; }
      table.common-custom-table tbody tr td b {
        font-weight: 500; }
    table.common-custom-table tbody tr td:not(:last-child) {
      border-right: none; }
  table.common-custom-table tr:not(:last-child) {
    border-bottom: 1px solid #eeeeee; }

h1 {
  text-align: left; }

.common-desktop-header-container .static-placeholder {
  visibility: hidden; }

.common-desktop-header-container .fixed-actual-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000; }

.common-desktop-header {
  width: 100%; }
  .common-desktop-header .top-menu-container {
    background: var(--primary-white);
    border-bottom: 1px solid #f0f1f2; }
  .common-desktop-header .dropdown-item {
    margin-bottom: 5px; }
    .common-desktop-header .dropdown-item:active {
      background: none; }
    .common-desktop-header .dropdown-item:hover {
      background: var(--primary-white);
      -webkit-filter: brightness(0.95);
              filter: brightness(0.95); }
    .common-desktop-header .dropdown-item svg {
      font-size: 1.125rem; }
  .common-desktop-header .badge {
    position: absolute;
    top: -4px;
    left: 12px;
    cursor: pointer; }
  .common-desktop-header .row {
    margin-left: 0;
    margin-right: 0;
    min-height: 2rem;
    align-items: center;
    justify-content: space-between; }
    .common-desktop-header .row .col {
      flex-basis: content; }
      .common-desktop-header .row .col.left-menu img {
        max-height: 4.375rem;
        border-radius: 10px;
        margin-top: 5px;
        margin-bottom: 5px;
        height: 4.375rem;
        display: block; }
      .common-desktop-header .row .col.left-menu a {
        color: #aaaaaa; }
        .common-desktop-header .row .col.left-menu a:hover {
          color: #727272; }
      .common-desktop-header .row .col.left-menu a.active {
        color: #3e3e3e; }
        .common-desktop-header .row .col.left-menu a.active:hover {
          color: #3e3e3e;
          opacity: 1; }
      .common-desktop-header .row .col.left-menu .company-logo:hover {
        opacity: 1; }
      .common-desktop-header .row .col.right-menu a {
        color: #aaaaaa;
        font-size: 1rem; }
        .common-desktop-header .row .col.right-menu a:hover {
          color: #727272;
          opacity: 1; }
      .common-desktop-header .row .col.right-menu a.active svg {
        color: #3e3e3e; }
        .common-desktop-header .row .col.right-menu a.active svg:hover {
          color: #3e3e3e;
          opacity: 1; }
      .common-desktop-header .row .col.right-menu a.active {
        color: #3e3e3e; }
        .common-desktop-header .row .col.right-menu a.active:hover {
          color: #3e3e3e;
          opacity: 1; }
      .common-desktop-header .row .col.right-menu .dropdown-item {
        color: #3e3e3e; }
      .common-desktop-header .row .col.right-menu .menu-item {
        position: relative; }
        .common-desktop-header .row .col.right-menu .menu-item svg {
          color: #8d8d8d;
          vertical-align: middle; }
        .common-desktop-header .row .col.right-menu .menu-item:first-child {
          margin-left: 0; }
        .common-desktop-header .row .col.right-menu .menu-item.dropdown {
          display: inline-block;
          vertical-align: middle;
          position: relative; }
          .common-desktop-header .row .col.right-menu .menu-item.dropdown .menu-item {
            position: relative;
            vertical-align: text-top;
            font-weight: 500;
            color: var(--primary-gray); }
            .common-desktop-header .row .col.right-menu .menu-item.dropdown .menu-item:hover {
              opacity: 1;
              color: #aaaaaa; }
            .common-desktop-header .row .col.right-menu .menu-item.dropdown .menu-item svg {
              color: #aaaaaa;
              margin-left: 2px;
              position: relative;
              bottom: 1px; }
              .common-desktop-header .row .col.right-menu .menu-item.dropdown .menu-item svg:hover {
                opacity: 1;
                color: #aaaaaa; }
          .common-desktop-header .row .col.right-menu .menu-item.dropdown .dropdown-menu {
            border-radius: 10px;
            border-color: transparent;
            background: var(--primary-white);
            box-shadow: 5px 4px 21px 5px rgba(0, 0, 0, 0.15); }
            .common-desktop-header .row .col.right-menu .menu-item.dropdown .dropdown-menu a {
              font-size: 0.875rem; }
            .common-desktop-header .row .col.right-menu .menu-item.dropdown .dropdown-menu svg {
              width: 1rem;
              vertical-align: middle;
              font-size: 1.125rem;
              color: #3e3e3e; }
            .common-desktop-header .row .col.right-menu .menu-item.dropdown .dropdown-menu img {
              font-size: 1.125rem; }
          .common-desktop-header .row .col.right-menu .menu-item.dropdown .dropdown-menu::after {
            border-bottom: 8px solid var(--primary-white);
            border-left: 9px solid rgba(0, 0, 0, 0);
            border-right: 9px solid rgba(0, 0, 0, 0);
            content: '';
            display: inline-block;
            right: 15.5%;
            /* position */
            position: absolute;
            top: -7px; }
      .common-desktop-header .row .col.right-menu .home-icon {
        margin-right: -0.5rem; }
      .common-desktop-header .row .col.right-menu .profile-photo,
      .common-desktop-header .row .col.right-menu .initials {
        border: 1px solid transparent;
        padding: 1px; }
      .common-desktop-header .row .col.right-menu .show svg {
        color: #3e3e3e; }
      .common-desktop-header .row .col.right-menu .show .profile-photo,
      .common-desktop-header .row .col.right-menu .show .initials {
        border: 1px solid #3e3e3e;
        border-radius: 50%; }
      .common-desktop-header .row .col.right-menu .show .notification-row .initials {
        border: none; }
      .common-desktop-header .row .col.right-menu .menu-item-container {
        cursor: pointer; }
        .common-desktop-header .row .col.right-menu .menu-item-container .btn {
          font-size: 1rem;
          color: #3e3e3e; }
          .common-desktop-header .row .col.right-menu .menu-item-container .btn svg {
            color: #3e3e3e; }
      .common-desktop-header .row .col.right-menu .notifications-dropdown-menu {
        border-radius: 10px;
        right: -10px !important;
        position: absolute;
        left: auto; }
        .common-desktop-header .row .col.right-menu .notifications-dropdown-menu .notifications-list-wrapper {
          min-width: 24rem;
          max-height: calc(100vh - 200px);
          overflow-y: auto;
          /* width */
          /* Track */
          /* Handle */
          /* Handle on hover */ }
          .common-desktop-header .row .col.right-menu .notifications-dropdown-menu .notifications-list-wrapper::-webkit-scrollbar {
            width: 8px; }
          .common-desktop-header .row .col.right-menu .notifications-dropdown-menu .notifications-list-wrapper::-webkit-scrollbar-track {
            background: var(--primary-white); }
          .common-desktop-header .row .col.right-menu .notifications-dropdown-menu .notifications-list-wrapper::-webkit-scrollbar-thumb {
            background: var(--primary-gray);
            border-radius: 10px; }
          .common-desktop-header .row .col.right-menu .notifications-dropdown-menu .notifications-list-wrapper::-webkit-scrollbar-thumb:hover {
            background: var(--primary-dark); }
        .common-desktop-header .row .col.right-menu .notifications-dropdown-menu .notifications-list-header .notification-title {
          margin-top: 0;
          font-weight: 700;
          line-height: 2;
          margin-bottom: 0;
          font-family: var(--serif-font-family); }
        .common-desktop-header .row .col.right-menu .notifications-dropdown-menu .mark-all-read-btn {
          text-decoration: underline;
          font-weight: 400;
          color: var(--primary-gray);
          position: relative; }
        .common-desktop-header .row .col.right-menu .notifications-dropdown-menu .notification-row {
          flex-wrap: nowrap;
          width: 100%; }
          .common-desktop-header .row .col.right-menu .notifications-dropdown-menu .notification-row .initials {
            flex-shrink: 0;
            margin-right: 15px;
            margin-left: 5px; }

.desktop-page-secondary-header-wrapper h1 {
  font-size: 1.25rem;
  line-height: initial; }

.profile-header {
  display: flex;
  align-items: center;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  margin-left: 1.6rem; }
  .profile-header .texts-header {
    display: grid;
    justify-items: flex-end; }
  .profile-header .name-header {
    margin: 0;
    color: var(--primary-dark);
    font-size: 0.875rem; }
  .profile-header .view-profile-link {
    color: var(--primary-gray);
    font-size: 0.8rem;
    font-weight: normal;
    margin: 0;
    text-decoration: underline; }

.language-switcher {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
  height: 28.98px; }
  .language-switcher::after, .language-switcher::before {
    display: none; }
  .language-switcher .btn {
    padding: 0; }
  .language-switcher .btn-outline-primary {
    height: 28.98px;
    color: var(--primary-dark);
    border: none;
    width: 100%;
    background-color: transparent;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-weight: 300;
    padding: 0.25rem 1.5rem;
    outline: none;
    box-shadow: none; }
    .language-switcher .btn-outline-primary:hover {
      color: var(--primary-dark); }
    .language-switcher .btn-outline-primary:after {
      background-color: transparent; }
    .language-switcher .btn-outline-primary:active {
      outline: none;
      box-shadow: none; }
  .language-switcher .btn-outline-primary:not(:disabled):not(.disabled):active,
  .language-switcher .btn-outline-primary:not(:disabled):not(.disabled).active,
  .language-switcher .show > .btn-outline-primary.dropdown-toggle {
    color: var(--primary-dark);
    background-color: transparent;
    border-color: transparent;
    outline: none; }
  .language-switcher .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
  .language-switcher .btn-outline-primary:not(:disabled):not(.disabled):active:focus,
  .language-switcher .show > .btn-outline-primary.dropdown-toggle:focus {
    box-shadow: none; }
  .language-switcher .dropdown-toggle::after,
  .language-switcher .dropdown-toggle::before {
    display: none;
    border: none;
    box-shadow: none; }

.language-switcher .btn-outline-primary.dropdown-toggle {
  color: var(--primary-dark);
  background-color: transparent;
  outline: none;
  border: none; }
  .language-switcher .btn-outline-primary.dropdown-toggle:focus, .language-switcher .btn-outline-primary.dropdown-toggle:active, .language-switcher .btn-outline-primary.dropdown-toggle:hover, .language-switcher .btn-outline-primary.dropdown-toggle:after, .language-switcher .btn-outline-primary.dropdown-toggle:before {
    box-shadow: none;
    outline: none;
    border: none; }

.language-switcher-title {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .language-switcher-title .language-switcher-title-text {
    font-weight: 400;
    display: flex;
    align-items: center; }

.common-desktop-header .profile-dropdown-menu {
  min-width: 200px;
  width: -webkit-max-content;
  width: max-content;
  right: 0 !important;
  top: 2.5rem !important;
  border: 0;
  position: absolute;
  left: auto;
  border-radius: 10px;
  border-color: transparent;
  background: var(--primary-white);
  box-shadow: 5px 4px 21px 5px rgba(0, 0, 0, 0.15);
  padding: .5rem 0;
  z-index: 9999999; }
  .common-desktop-header .profile-dropdown-menu a {
    font-size: 0.875rem; }
  .common-desktop-header .profile-dropdown-menu svg {
    width: 1rem;
    vertical-align: middle;
    font-size: 1.125rem;
    color: var(--primary-dark); }
  .common-desktop-header .profile-dropdown-menu img {
    font-size: 1.125rem; }

.common-desktop-header .row .col.right-menu .menu-item.dropdown .language-switcher .dropdown-menu::after {
  display: none; }

.common-desktop-header .row .col.right-menu a {
  font-size: 0.875rem; }

.common-desktop-header .row .col.right-menu .menu-item.dropdown .dropdown-menu::after {
  right: 12px; }

.dropdown-menu {
  border-radius: 10px;
  border-color: transparent;
  background: var(--primary-white);
  box-shadow: 5px 4px 21px 5px rgba(0, 0, 0, 0.15); }
  .dropdown-menu a {
    font-size: 0.875rem; }
  .dropdown-menu svg {
    width: 1rem;
    vertical-align: middle;
    font-size: 1.125rem;
    color: #3e3e3e; }
  .dropdown-menu img {
    font-size: 1.125rem; }

.dropdown-menu::after {
  border-bottom: 8px solid var(--primary-white);
  border-left: 9px solid rgba(0, 0, 0, 0);
  border-right: 9px solid rgba(0, 0, 0, 0);
  content: '';
  display: inline-block;
  right: 15.5%;
  /* position */
  position: absolute;
  top: -7px; }

.common-desktop-header .row .col.right-menu .menu-item svg {
  color: var(--primary-dark); }

h1 {
  text-align: left; }

.common-switch-active-company-modal img,
.common-switch-active-company-modal span {
  width: 50px;
  border: 1px solid #eeeeee; }

.common-switch-active-company-modal span {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #aaaaaa;
  color: white;
  font-size: 1.25rem; }

.common-switch-active-company-modal h1 {
  font-weight: bold;
  font-size: 1rem;
  margin-bottom: 0;
  line-height: 60px;
  display: inline-block; }

.common-switch-active-company-modal .modal-dialog {
  min-height: 100%; }
  @media (max-width: 575.98px) {
    .common-switch-active-company-modal .modal-dialog {
      margin: 0; } }

@media (max-width: 575.98px) {
  .common-switch-active-company-modal .modal-content {
    border: 0;
    border-radius: 20px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0; } }

.common-switch-active-company-modal .company-item:hover {
  cursor: pointer;
  background: #f7f7f7; }

.common-switch-active-company-modal .company-item .text-primary {
  color: var(--primary-dark) !important; }

.common-switch-active-company-modal .company-item.active:hover {
  cursor: default;
  background: transparent; }

.common-switch-active-company-modal .company-item.active h1:hover {
  cursor: text; }

.common-switch-active-company-modal .company-item:not(:last-child) {
  border-bottom: 1px solid lightgrey; }

h1 {
  text-align: left; }

.desktop-button {
  display: inline-flex;
  grid-gap: 0.8rem;
  gap: 0.8rem;
  align-items: center;
  cursor: pointer; }

h1 {
  text-align: left; }

.modal-info .modal-content {
  border-radius: 20px; }

.modal-info .modal-body {
  padding: 45px 20px; }
  .modal-info .modal-body .modal-info-noble-logo {
    display: flex;
    justify-content: center;
    align-items: center; }
  .modal-info .modal-body h4 {
    font-family: var(--serif-font-family);
    font-weight: 700;
    margin-bottom: 10px;
    text-align: center; }
  .modal-info .modal-body p,
  .modal-info .modal-body li {
    font-family: var(--sans-serif-font-family);
    font-size: 0.8rem;
    font-weight: 400; }
  .modal-info .modal-body ul {
    padding-left: 20px; }
  .modal-info .modal-body h6 {
    margin-top: 15px;
    margin-bottom: 10px;
    text-decoration: underline;
    -webkit-text-decoration-color: #edc342;
            text-decoration-color: #edc342;
    text-decoration-thickness: 3px; }

.success-modal .modal-content {
  border-radius: 20px;
  padding: 2rem 1.5rem; }

.success-modal .modal-title {
  font-family: var(--serif-font-family);
  font-size: 2rem;
  font-weight: 700;
  color: var(--primary-dark);
  margin: 0 auto; }

.success-modal .modal-header {
  padding: 0; }

.success-modal .modal-body {
  width: 60%;
  margin: 0 auto;
  text-align: center; }

.success-modal button {
  margin: 0 auto;
  background-color: var(--primary-dark);
  color: var(--primary-white);
  padding: 0.8rem 1.2rem;
  border-radius: 0.6rem;
  font-size: 0.875rem;
  width: 60%;
  font-weight: 700; }

.bottom-sheet {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999999;
  background-color: var(--pure-white);
  border-radius: 10px;
  box-shadow: 0px 20px 20px 1px rgba(0, 0, 0, 0.05);
  padding: 0.5rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto; }
  .bottom-sheet__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    padding: 0 1.5rem; }

h1 {
  text-align: left; }

h1 {
  text-align: left; }

.user-profile-page {
  min-height: 100vh;
  background-color: var(--primary-bg-white); }
  .user-profile-page button.btn-link {
    text-decoration: none;
    outline: none;
    background: transparent; }
    .user-profile-page button.btn-link:focus {
      box-shadow: none; }
  .user-profile-page .common-header button {
    color: #727272;
    font-weight: bold; }
    .user-profile-page .common-header button[type='submit'] {
      color: #2f80ed;
      font-weight: bold; }
  .user-profile-page .more-btn-desktop {
    position: absolute;
    right: 0.75rem;
    top: 0.75rem; }

.photo-edit-body .rc-slider-rail {
  height: 6px; }

.photo-edit-body .rc-slider-track {
  background: #2f80ed;
  height: 6px; }

.photo-edit-body .rc-slider-handle {
  width: 30px;
  height: 30px;
  margin-top: -13px;
  border-radius: 10px;
  border: solid 2px #2f80ed; }

.photo-edit-body .edit-actions {
  display: flex;
  align-items: center;
  justify-content: space-around; }

.photo-modal-title {
  display: flex;
  justify-content: center; }

.photo-change-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0; }
  .photo-change-body a {
    color: #3e3e3e;
    font-weight: 400;
    height: 50px; }
  .photo-change-body a.remove {
    color: #ff3b30; }
  .photo-change-body button {
    width: 100%; }

.user-profile-page hr {
  border-color: var(--primary-dark); }

.profile-card-item p {
  margin-bottom: -4px;
  color: #727272;
  font-size: 0.875rem; }
  .profile-card-item p:first-child {
    color: #3e3e3e;
    font-weight: 400;
    font-size: 1rem; }
  .profile-card-item p.student-link {
    color: #2f80ed;
    text-decoration: underline; }

.profile-card-item svg.field {
  color: #3e3e3e;
  background: #eeeeee;
  border-radius: 50%;
  width: 2.375rem;
  height: 2.375rem;
  padding: 0.55rem;
  display: block;
  margin: auto; }

h1 {
  text-align: left; }

.user-profile-page .common-header button[type='submit'] {
  color: var(--primary-dark); }

.user-components-profile-form .theme-card {
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  padding: 3rem 1rem;
  width: 100%;
  min-width: 20rem;
  border-radius: 0.8rem;
  margin-top: 2rem; }

.user-components-profile-form .container-inside {
  padding-bottom: 5rem; }

.user-components-profile-form h2 {
  font-family: var(--serif-font-family);
  font-size: 1.5rem;
  text-align: center; }

.user-components-profile-form .avatar-editor {
  margin: 0 auto; }

.user-components-profile-form hr {
  width: calc(100% + 2rem);
  height: 1px;
  margin-left: -1rem; }

.user-components-profile-form form {
  padding-top: 1rem; }
  .user-components-profile-form form .form-label {
    font-size: 0.8rem; }
  .user-components-profile-form form .form-group {
    margin-bottom: 0.875rem;
    border: 1px solid var(--primary-gray-lighter);
    border-radius: 0.6rem;
    min-width: 100%;
    position: relative; }
    .user-components-profile-form form .form-group .icon {
      position: absolute;
      top: 0.8125rem;
      left: 0.6875rem;
      margin-left: 0.25rem; }
    .user-components-profile-form form .form-group .email {
      width: 1.25rem;
      top: 0.875rem;
      left: 0.6rem; }
    .user-components-profile-form form .form-group .phone {
      width: 0.875rem;
      top: 0.6875rem;
      left: 0.8rem; }
    .user-components-profile-form form .form-group .bio {
      width: 1rem;
      top: 0.9rem;
      left: 0.8rem; }
    .user-components-profile-form form .form-group .form-control {
      padding-left: 3rem;
      font-weight: 300; }
    .user-components-profile-form form .form-group textarea {
      padding-top: 0.65rem;
      padding-left: 1rem;
      border: 1px solid var(--primary-gray-lighter); }
  .user-components-profile-form form .change-password {
    color: var(--primary-dark-green);
    font-weight: 700;
    text-decoration: underline;
    margin: 0; }

.user-components-profile-form .desktop-save-cancel {
  display: none; }
  .user-components-profile-form .desktop-save-cancel .btn {
    display: flex;
    width: 100%;
    border-radius: 0.6rem; }
  .user-components-profile-form .desktop-save-cancel .save {
    background-color: var(--primary-dark);
    color: var(--pure-white); }
  .user-components-profile-form .desktop-save-cancel .cancel {
    background-color: transparent;
    border: 1px solid var(--primary-dark); }
    .user-components-profile-form .desktop-save-cancel .cancel:hover {
      background-color: var(--primary-dark);
      color: var(--pure-white); }

@media (min-width: 992px) {
  .user-components-profile-form form {
    min-width: 19rem; }
    .user-components-profile-form form .change-password {
      margin-bottom: 1.5rem; }
  .user-components-profile-form .theme-card {
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;
    margin: 3rem auto;
    padding: 3rem 5rem; }
  .user-components-profile-form .desktop-save-cancel {
    display: grid;
    justify-items: center;
    grid-row-gap: 0.75rem;
    row-gap: 0.75rem; } }

@media (min-width: 576px) {
  .user-components-profile-form .form-container {
    border-radius: 10px; } }

@media (max-width: 575.98px) {
  .user-components-profile-form .form-container {
    background-color: transparent; }
    .user-components-profile-form .form-container .btn-delete-account {
      background-color: white; } }

@media (min-width: 576px) and (max-width: 767.98px) {
  .user-components-profile-form .profile-photo {
    margin-top: 1rem; } }

.theme-card {
  background-color: var(--primary-white); }

.profile-edit-form-container {
  min-width: 350px; }

.account-delete-modal .confirm {
  max-width: 20em; }

@media (max-width: 575.98px) {
  .account-delete-modal h1.title {
    font-size: 1rem;
    margin: auto;
    line-height: 22px; } }

.account-delete-modal .btn-cancel {
  height: 30px; }

.account-delete-modal .btn-delete-account {
  height: auto; }

.account-delete-modal .input-sm {
  height: 30px;
  font-size: 0.8rem; }

@media (min-width: 576px) {
  .user-components-profile-form .form-control {
    border: 1px solid #cccccc; }
    .user-components-profile-form .form-control:focus {
      border: 1px solid #2f80ed; }
  .user-components-profile-form .form-container {
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.05);
    margin-bottom: 2.5rem; } }

@media (min-width: 576px) and (max-width: 767.98px) {
  .user-components-user-avatar-editor {
    padding-top: 1.5rem; } }

@media (max-width: 575.98px) {
  .account-change-password-modal h1.title {
    font-size: 1rem;
    margin: auto;
    line-height: 22px; } }

.account-change-password-modal .form-control {
  border-color: #cccccc; }

.account-change-password-modal .cancel-btn {
  position: absolute;
  left: 0;
  top: 0;
  font-weight: bold; }

.account-change-password-modal .input-sm {
  height: 30px;
  font-size: 0.8rem; }

.account-change-password-modal .btn-primary:disabled {
  border-color: #aaaaaa;
  background-color: #aaaaaa; }

.change-password-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  border-radius: 0.6rem;
  background-color: var(--primary-dark);
  color: var(--pure-white);
  width: 100%; }

.password-form .form-control {
  padding-left: 1rem; }

h1 {
  text-align: left; }

.user-components-user-avatar-editor {
  display: flex;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  align-items: center;
  width: 100%;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  margin: 0 auto; }
  .user-components-user-avatar-editor .upload-photo {
    text-align: start; }
    .user-components-user-avatar-editor .upload-photo .avatar-info {
      display: grid;
      margin-left: 0.375rem; }
      .user-components-user-avatar-editor .upload-photo .avatar-info p {
        margin: 0;
        font-weight: 500;
        margin-bottom: -0.35rem;
        margin-left: 0.1rem; }
      .user-components-user-avatar-editor .upload-photo .avatar-info button {
        font-size: 0.875rem;
        color: var(--primary-dark);
        padding: 0;
        min-height: 0;
        font-weight: 300;
        width: -webkit-max-content;
        width: max-content; }

.photo-edit-body .edit-actions {
  display: flex;
  align-items: center;
  justify-content: space-around; }

h1 {
  text-align: left; }

.user-user-page {
  min-height: 100vh;
  background-color: var(--primary-white); }
  .user-user-page .header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding-left: 2rem;
    padding-top: 2rem;
    grid-gap: 0.25rem;
    gap: 0.25rem; }
    .user-user-page .header h1 {
      font-family: var(--serif-font-family);
      font-size: larger;
      padding: 2rem 0 1rem 0; }
    .user-user-page .header img {
      height: 2.5rem; }
  .user-user-page .menu-items-grid {
    display: grid;
    padding-bottom: 2rem; }
    .user-user-page .menu-items-grid .item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 1.3rem 1rem;
      border-bottom: 1px solid rgba(196, 196, 196, 0.3); }
      .user-user-page .menu-items-grid .item .icon-and-title {
        display: grid;
        grid-template-columns: auto 1fr;
        grid-gap: 1rem;
        place-items: center; }
        .user-user-page .menu-items-grid .item .icon-and-title .title {
          font-size: 0.875rem;
          font-weight: 300;
          color: var(--primary-dark); }
  .user-user-page a {
    display: inherit;
    text-decoration: none;
    font-weight: 400; }
  .user-user-page .user-name {
    margin-left: -5px;
    display: flex;
    flex-direction: column;
    justify-content: center; }
    .user-user-page .user-name .theme-text-primary {
      color: var(--primary-dark);
      font-weight: 500;
      font-size: 0.875rem;
      font-family: var(--sans-serif-font-family); }
  .user-user-page hr {
    border-color: #e3e3e3;
    margin-left: -15px;
    margin-right: -15px; }
  .user-user-page .row-item {
    display: flex; }
  .user-user-page .user-page-header {
    margin-bottom: 1rem; }
    .user-user-page .user-page-header p:first-child {
      font-weight: 500;
      margin-bottom: -5px;
      font-size: 0.875rem; }
    .user-user-page .user-page-header p:last-child a {
      text-decoration: none;
      font-weight: 300;
      font-size: 0.75rem;
      color: #6d786e; }
    .user-user-page .user-page-header hr {
      width: 90%; }
  .user-user-page .user-page-item {
    cursor: pointer;
    display: flex;
    justify-content: space-between; }
    .user-user-page .user-page-item svg {
      font-size: 1.125rem;
      width: 1.5rem;
      color: var(--primary-dark); }

.user-user-page hr {
  border-color: var(--primary-dark); }

h1 {
  text-align: left; }

.user-components-invite-coach-modal .modal-dialog {
  border: 0; }

.user-components-invite-coach-modal .modal-content {
  border: 0; }

.user-components-invite-coach-modal button {
  height: inherit; }

.user-components-invite-coach-modal .form-group {
  position: relative; }
  .user-components-invite-coach-modal .form-group svg {
    color: #727272;
    position: absolute;
    left: 0.5rem;
    top: 0.75rem;
    font-size: 1rem; }

.user-components-invite-coach-modal.mobile-modal .modal-dialog {
  margin: 0;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0; }

.user-components-invite-coach-modal.mobile-modal .modal-content {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

h1 {
  text-align: left; }

.user-notifications-settings-page .container {
  margin-top: 1rem; }

.user-notifications-settings-page .header {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%; }
  .user-notifications-settings-page .header h1 {
    font-family: var(--serif-font-family);
    font-size: larger;
    padding: 2rem 0 1rem 0; }

.user-notifications-settings-page .notification-settings-page-row div:first-child {
  text-align: center; }

h1 {
  text-align: left; }

.user-delivery-type-notifications-settings-page .notification-type-switch {
  padding: 15px; }
  .user-delivery-type-notifications-settings-page .notification-type-switch div:last-child {
    display: flex;
    justify-content: center;
    align-items: center; }

.user-delivery-type-notifications-settings-page .button-container {
  padding: 15px; }
  .user-delivery-type-notifications-settings-page .button-container button {
    background-color: #f4f4f5; }

h1 {
  text-align: left; }

.profile-view {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  justify-items: center;
  min-height: 100vh;
  padding-bottom: 10rem; }
  .profile-view hr {
    width: 100%;
    border: 0;
    height: 0.3px;
    margin: 0;
    color: #c4c4c430; }

.profile-tab-selector {
  width: 100%;
  max-width: 32rem;
  display: flex;
  flex-direction: column;
  padding: 0 0.5rem;
  margin: 0 auto; }
  .profile-tab-selector .profile-header {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    align-items: center;
    max-width: 100%;
    padding-top: 1rem; }
    .profile-tab-selector .profile-header svg {
      justify-self: left;
      margin: 0;
      max-width: -webkit-fit-content;
      max-width: -moz-fit-content;
      max-width: fit-content; }
    .profile-tab-selector .profile-header h1 {
      place-self: center;
      margin: 0;
      font-family: var(--serif-font-family);
      font-size: 1.25rem;
      margin-left: -1rem; }
  .profile-tab-selector .profile-tabs {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 1rem 1.5rem 0 1.5rem; }
    .profile-tab-selector .profile-tabs .profile-tab {
      display: grid;
      justify-items: center;
      outline: none;
      grid-row-gap: 0.1rem;
      row-gap: 0.1rem; }

.profile-container {
  padding: 2rem 0rem;
  width: 100%;
  overflow: hidden;
  display: none;
  justify-items: center;
  align-items: center; }
  .profile-container .active-but-canceled {
    padding: 0.7rem;
    background-color: rgba(196, 114, 88, 0.3);
    max-width: 100%;
    margin: 0 auto;
    border-radius: 0.5rem;
    margin-bottom: 2.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .profile-container .active-but-canceled p {
      color: var(--primary-red);
      font-size: 0.8rem; }
      .profile-container .active-but-canceled p span {
        text-decoration: underline;
        font-weight: 500;
        margin-left: 1rem;
        cursor: pointer; }
    .profile-container .active-but-canceled button {
      color: var(--primary-white);
      background-color: var(--primary-red);
      padding: 0.3rem 0.5rem;
      font-size: 0.8rem;
      border-radius: 0.5rem; }

.right-grid {
  display: grid;
  width: 100%;
  grid-row-gap: 2.2rem;
  row-gap: 2.2rem; }
  .right-grid .title {
    font-weight: 500; }

@media (min-width: 992px) {
  .clients-coaches-container {
    background-color: var(--primary-white); }
  .payment-method-container {
    background-color: var(--primary-white); }
  .billing-container {
    background-color: var(--primary-white); }
  .profile-container {
    display: block; }
  .right-grid {
    width: 100%; }
  .clients-coaches-container {
    width: 100%; } }

hr {
  width: 100%;
  border: 0;
  height: 0.1px;
  margin: 0;
  color: rgba(196, 196, 196, 0.3); }

.cancel-subscription-modal .modal-header {
  margin: 0 auto;
  padding-top: 2.3rem;
  max-width: 80%; }

.cancel-subscription-modal .modal-body {
  display: flex;
  flex-direction: column;
  padding-bottom: 2.8rem;
  max-width: 80%;
  margin: 0 auto; }
  .cancel-subscription-modal .modal-body p {
    text-align: center;
    font-weight: 300; }
  .cancel-subscription-modal .modal-body .are-you-sure {
    display: grid;
    justify-items: center;
    align-items: center;
    padding: 1rem;
    grid-gap: 1rem;
    gap: 1rem;
    background-color: rgba(196, 114, 88, 0.3);
    border-radius: 0.5rem;
    margin-top: 2.3rem; }
    .cancel-subscription-modal .modal-body .are-you-sure p {
      color: var(--primary-red); }
  .cancel-subscription-modal .modal-body button {
    margin: 0 auto; }
  .cancel-subscription-modal .modal-body .cancel {
    color: var(--primary-white);
    background-color: var(--primary-dark);
    border-radius: 0.4rem;
    padding: 1rem;
    margin-top: 2.3rem; }
  .cancel-subscription-modal .modal-body .go-back {
    text-decoration: underline;
    margin-top: 1rem; }

h1 {
  text-align: left; }

.profile-grid-container {
  display: grid;
  grid-gap: 1rem;
  gap: 1rem;
  width: 100%;
  background-color: transparent;
  border-radius: 0.6rem; }
  .profile-grid-container .profile-grid-container-header {
    display: flex;
    justify-content: space-between;
    display: none; }
    .profile-grid-container .profile-grid-container-header h1 {
      font-family: var(--serif-font-family);
      font-size: 1.5rem; }
    .profile-grid-container .profile-grid-container-header button p {
      text-decoration: underline; }
  .profile-grid-container .profile-grid-container-body {
    display: grid;
    grid-row-gap: 1.2rem;
    row-gap: 1.2rem;
    padding-top: 1rem; }
    .profile-grid-container .profile-grid-container-body .profile-items-container {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      grid-gap: 0.5rem;
      gap: 0.5rem;
      justify-items: start; }
      .profile-grid-container .profile-grid-container-body .profile-items-container .profile-item {
        display: flex;
        align-items: center;
        grid-gap: 0.5rem;
        gap: 0.5rem;
        justify-items: start; }
        .profile-grid-container .profile-grid-container-body .profile-items-container .profile-item p {
          margin: 0;
          display: flex;
          width: -webkit-max-content;
          width: max-content;
          font-weight: 300; }
        .profile-grid-container .profile-grid-container-body .profile-items-container .profile-item .subscription-invitation {
          display: grid;
          grid-gap: 0.3rem;
          gap: 0.3rem; }
          .profile-grid-container .profile-grid-container-body .profile-items-container .profile-item .subscription-invitation button {
            display: flex;
            font-size: 0.7rem;
            font-weight: 300;
            text-decoration: underline; }
        .profile-grid-container .profile-grid-container-body .profile-items-container .profile-item .subscription-status {
          display: grid; }
      .profile-grid-container .profile-grid-container-body .profile-items-container .sub-grid-start {
        align-items: flex-start; }
      .profile-grid-container .profile-grid-container-body .profile-items-container p {
        font-weight: 500;
        font-size: 0.875rem;
        word-wrap: break-word;
        max-width: 100%; }

@media (min-width: 992px) {
  .profile-items-container {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr)); }
  .profile-grid-container {
    background-color: var(--pure-white);
    max-width: 100%; }
    .profile-grid-container .profile-grid-container-header {
      display: flex; }
  .profile-grid-container {
    background-color: var(--primary-white);
    max-width: 100%; } }

h1 {
  text-align: left; }

.billing-container {
  border-radius: 0.6rem; }
  .billing-container .title {
    font-weight: 500; }
  .billing-container .billing-items {
    display: grid;
    grid-gap: 1.2rem;
    gap: 1.2rem;
    margin-top: 1rem;
    border-radius: 1rem; }
    .billing-container .billing-items .billing-item {
      display: flex;
      align-content: center;
      justify-content: space-between; }
      .billing-container .billing-items .billing-item .billing-item-date p {
        font-weight: 300; }
      .billing-container .billing-items .billing-item .billing-item-date .date {
        font-size: 0.8rem; }
      .billing-container .billing-items .billing-item .billing-item-price {
        display: flex;
        align-items: center;
        grid-gap: 0.6rem;
        gap: 0.6rem; }
        .billing-container .billing-items .billing-item .billing-item-price .price {
          font-weight: 500; }
        .billing-container .billing-items .billing-item .billing-item-price .status-tag {
          font-size: 0.8rem;
          background-color: var(--primary-green);
          color: var(--pure-white);
          padding: 0.15rem 1rem;
          border-radius: 0.5rem; }
        .billing-container .billing-items .billing-item .billing-item-price .due {
          background-color: var(--primary-red); }

h1 {
  text-align: left; }

.payment-method-container {
  display: grid;
  grid-row-gap: 1.2rem;
  row-gap: 1.2rem;
  border-radius: 0.6rem; }
  .payment-method-container .title {
    font-weight: 500; }
  .payment-method-container .card-info {
    display: flex;
    justify-content: space-between;
    align-items: flex-start; }
    .payment-method-container .card-info .card-type {
      display: flex;
      align-items: center;
      grid-gap: 0.6rem;
      gap: 0.6rem; }
    .payment-method-container .card-info .card-number {
      display: grid;
      grid-gap: 0.6rem;
      gap: 0.6rem;
      justify-items: end; }
      .payment-method-container .card-info .card-number .buttons-container {
        display: flex;
        grid-gap: 1rem;
        gap: 1rem;
        align-items: center; }
      .payment-method-container .card-info .card-number .update {
        text-decoration: underline;
        font-weight: 500;
        font-size: 0.8rem; }

h1 {
  text-align: left; }

.clients-coaches-container {
  background-color: transparent;
  width: 100%;
  border-radius: 0.6rem;
  max-height: 553px;
  overflow-y: auto;
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */ }
  .clients-coaches-container .title {
    font-weight: 500; }
  .clients-coaches-container .clients-coaches-grid {
    display: grid;
    justify-items: start;
    width: 100%;
    grid-row-gap: 0.5rem;
    row-gap: 0.5rem; }
    .clients-coaches-container .clients-coaches-grid .clients-coaches-grid-item {
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: center;
      margin-top: 1.3rem; }
      .clients-coaches-container .clients-coaches-grid .clients-coaches-grid-item .picture-name {
        display: flex;
        align-items: center;
        grid-gap: 0.5rem;
        gap: 0.5rem; }
      .clients-coaches-container .clients-coaches-grid .clients-coaches-grid-item .view {
        text-decoration: underline;
        color: var(--primary-dark);
        font-size: 0.875rem;
        font-weight: 300;
        cursor: pointer; }
  .clients-coaches-container::-webkit-scrollbar {
    width: 8px; }
  .clients-coaches-container::-webkit-scrollbar-track {
    background: var(--primary-white); }
  .clients-coaches-container::-webkit-scrollbar-thumb {
    background: var(--primary-gray);
    border-radius: 10px; }
  .clients-coaches-container::-webkit-scrollbar-thumb:hover {
    background: var(--primary-dark); }

@media (min-width: 992px) {
  .clients-coaches-container {
    width: 100%;
    background-color: var(--primary-white); } }

h1 {
  text-align: left; }

.profile-picture-container {
  display: flex;
  justify-content: space-between;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  align-items: flex-end; }
  .profile-picture-container .profile-picture-info {
    display: flex;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    align-items: center; }
    .profile-picture-container .profile-picture-info .name {
      font-size: 1rem; }
  .profile-picture-container button {
    outline: none; }
    .profile-picture-container button p {
      text-decoration: underline;
      font-size: 0.7rem; }
  .profile-picture-container .tag {
    font-size: 0.7rem;
    margin: 0;
    background-color: var(--primary-light-green);
    display: flex;
    justify-content: center;
    max-width: -webkit-max-content;
    max-width: max-content;
    padding: 0.15rem 1rem;
    color: var(--primary-green);
    border-radius: 1rem; }
  .profile-picture-container .inactive {
    background-color: #e3c7bd;
    color: var(--primary-red); }

h1 {
  text-align: left; }

.subscription-container {
  display: grid;
  background-color: transparent;
  border-radius: 0.6rem;
  width: 100%; }
  .subscription-container .title {
    font-weight: 500; }
  .subscription-container .subscription-details-grid {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 0.8rem; }
    .subscription-container .subscription-details-grid .subscription-details-grid-description {
      display: flex;
      align-items: center; }
      .subscription-container .subscription-details-grid .subscription-details-grid-description h2 {
        font-size: 1.5rem;
        font-weight: 700;
        font-family: var(--serif-font-family); }
    .subscription-container .subscription-details-grid .subscription-details-grid-price {
      display: grid;
      justify-items: end;
      width: 40%;
      max-width: 40%; }
      .subscription-container .subscription-details-grid .subscription-details-grid-price h2 {
        font-size: 1rem;
        font-weight: 600; }
      .subscription-container .subscription-details-grid .subscription-details-grid-price p {
        font-size: 0.7rem;
        font-weight: 400;
        text-decoration: underline;
        text-align: right;
        min-width: 100%; }

@media (min-width: 992px) {
  .subscription-container {
    background-color: var(--primary-white); }
    .subscription-container .subscription-details-grid .subscription-details-grid-description h2 {
      font-size: 1.5rem; }
    .subscription-container .subscription-details-grid .subscription-details-grid-price h2 {
      font-size: 1.5rem; }
    .subscription-container .subscription-details-grid .subscription-details-grid-price p {
      font-size: 0.7rem; } }

.change-payment-method-modal .modal-header {
  padding: 2rem 2rem 0rem 2rem; }
  .change-payment-method-modal .modal-header .modal-title {
    font-size: 1.5rem;
    font-weight: 700;
    margin: 0 auto;
    font-family: var(--serif-font-family); }

.change-payment-method-modal .modal-body {
  padding: 2rem 2rem; }
  .change-payment-method-modal .modal-body .success-body {
    display: grid;
    justify-items: center;
    margin: 0 auto;
    grid-gap: 2rem;
    gap: 2rem; }
    .change-payment-method-modal .modal-body .success-body img {
      width: 20%; }
    .change-payment-method-modal .modal-body .success-body p {
      text-align: center;
      margin-top: -1rem;
      font-weight: 300;
      max-width: 80%; }
    .change-payment-method-modal .modal-body .success-body button {
      background-color: var(--primary-dark);
      color: var(--primary-white);
      border-radius: 0.8rem;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1rem 0;
      font-weight: 500; }
  .change-payment-method-modal .modal-body .credit-card {
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .change-payment-method-modal .modal-body .credit-card p {
      font-weight: 500; }
    .change-payment-method-modal .modal-body .credit-card .companies {
      display: flex;
      align-items: center;
      grid-gap: 0.5rem;
      gap: 0.5rem; }
  .change-payment-method-modal .modal-body form {
    display: flex;
    flex-direction: column; }
    .change-payment-method-modal .modal-body form button {
      background-color: var(--primary-dark);
      color: var(--primary-white);
      font-weight: 500;
      font-size: 1rem;
      text-align: center;
      padding: 1rem;
      border-radius: 0.8rem;
      width: 100%;
      margin: 0rem auto;
      margin-top: 2rem; }
    .change-payment-method-modal .modal-body form .error-message {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      margin: 0rem auto;
      background-color: rgba(196, 114, 88, 0.3);
      color: var(--primary-red);
      padding: 1rem;
      border-radius: 0.8rem;
      margin-top: 2rem; }

h1 {
  text-align: left; }

.user-terms-and-conditions-modal .modal-header {
  padding-top: 1.5rem;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  align-items: center; }
  .user-terms-and-conditions-modal .modal-header p {
    text-align: center;
    font-size: 0.875rem;
    font-weight: 300;
    color: #727272; }
  .user-terms-and-conditions-modal .modal-header .modal-title {
    font-family: var(--serif-font-family);
    margin: 0 auto; }

.user-terms-and-conditions-modal .modal-body {
  display: flex;
  flex-direction: column;
  grid-gap: 1.5rem;
  gap: 1.5rem;
  padding: 1rem 2rem 2rem 2rem; }
  .user-terms-and-conditions-modal .modal-body .item {
    display: flex;
    align-items: center;
    color: var(--primary-dark);
    font-weight: 400;
    cursor: pointer;
    -webkit-user-select: none;
            user-select: none; }
    .user-terms-and-conditions-modal .modal-body .item a {
      margin-left: 1rem; }
  .user-terms-and-conditions-modal .modal-body .link-icon {
    width: 1rem;
    height: 1rem;
    margin-left: -0.5rem;
    color: var(--primary-dark); }
  .user-terms-and-conditions-modal .modal-body .terms-button {
    background-color: var(--primary-dark);
    color: var(--pure-white);
    border-radius: 0.6rem;
    margin: 0 auto;
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;
    padding: 0.85rem;
    font-size: 0.875rem; }
    .user-terms-and-conditions-modal .modal-body .terms-button:disabled {
      background-color: var(--primary-gray);
      color: var(--primary-white); }

h1 {
  text-align: left; }

.user-edit-profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  overflow-y: auto; }
  .user-edit-profile form {
    background-color: var(--primary-white);
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 10vh auto;
    padding: 1.5rem 2rem;
    width: 32rem; }
    @media (max-width: 767.98px) {
      .user-edit-profile form {
        width: 100vw;
        height: 100%;
        z-index: 9999;
        position: relative;
        margin: 0 auto; } }
    .user-edit-profile form .edit-profile-button,
    .user-edit-profile form .cancel-button {
      background-color: var(--primary-dark);
      border-radius: 0.5rem;
      padding: 0.5rem 1rem;
      color: var(--primary-white);
      font-size: 0.875rem;
      width: 100%;
      margin-top: 1rem; }
    .user-edit-profile form .cancel-button {
      background-color: transparent;
      color: var(--primary-dark);
      border: 1px solid var(--primary-dark);
      font-weight: 500; }
    .user-edit-profile form .title {
      font-weight: bold;
      margin-bottom: 0.5rem; }
    .user-edit-profile form .profile-picture {
      display: flex;
      align-items: center;
      margin-bottom: 1rem;
      grid-gap: 1rem;
      gap: 1rem; }
      .user-edit-profile form .profile-picture img {
        height: 3rem;
        width: 3rem;
        border-radius: 50%;
        object-fit: cover; }
      .user-edit-profile form .profile-picture .change-profile-picture-underline {
        text-decoration: underline;
        cursor: pointer;
        font-size: 0.8rem;
        opacity: 0.6; }
      .user-edit-profile form .profile-picture .upload-button {
        background-color: var(--primary-dark);
        border-radius: 0.5rem;
        padding: 0.5rem 1rem;
        color: var(--primary-white);
        font-size: 0.8rem;
        max-width: -webkit-max-content;
        max-width: max-content; }
    .user-edit-profile form .form-group {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-self: flex-start;
      width: 100%;
      margin-bottom: 1rem; }
      .user-edit-profile form .form-group input {
        padding-left: 1rem;
        width: 100%;
        background-color: #fff;
        border: 1px solid #ddd; }
        .user-edit-profile form .form-group input:focus {
          outline-color: var(--primary-dark); }
      .user-edit-profile form .form-group #phone_number {
        padding-left: 3rem;
        border-radius: 0.625rem; }
      .user-edit-profile form .form-group .react-tel-input .flag-dropdown {
        background: transparent;
        border: none;
        border-radius: 0.525rem; }
        .user-edit-profile form .form-group .react-tel-input .flag-dropdown .selected-flag {
          background: transparent;
          padding-left: 1rem; }
      .user-edit-profile form .form-group .react-tel-input .country-list {
        box-shadow: 0px 4px 20px 1px rgba(0, 0, 0, 0.1);
        border-radius: 0.525rem; }
        .user-edit-profile form .form-group .react-tel-input .country-list li {
          font-weight: 300; }
      .user-edit-profile form .form-group .checkbox-group {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        grid-gap: 0.25rem;
        gap: 0.25rem;
        margin-left: 0.5rem; }
      .user-edit-profile form .form-group .error-message {
        color: var(--primary-red);
        font-size: 0.7rem;
        margin: 0.5rem 0;
        font-weight: 700; }

h1 {
  text-align: left; }

h1 {
  text-align: left; }

.dashboard-dashboard-page {
  min-height: 100vh;
  background-color: var(--primary-bg-white); }
  .dashboard-dashboard-page .tab-selector-container {
    height: 2rem; }
  .dashboard-dashboard-page .dashboard-tab-selector {
    background-color: #f6f6f6;
    position: fixed;
    left: 0;
    right: 0; }
    .dashboard-dashboard-page .dashboard-tab-selector .nav-link {
      color: #8d8d8d;
      font-size: 0.8rem;
      font-weight: normal; }
      .dashboard-dashboard-page .dashboard-tab-selector .nav-link.active {
        color: #343434; }
      .dashboard-dashboard-page .dashboard-tab-selector .nav-link:hover {
        color: initial; }
  .dashboard-dashboard-page .dashboard-tab-selector {
    background-color: var(--primary-bg-white); }

h1 {
  text-align: left; }

.dashboard-admin-page {
  width: 100%;
  padding-bottom: 2.5rem; }
  .dashboard-admin-page .desktop-page-secondary-header-wrapper {
    height: auto; }
  .dashboard-admin-page .my-clients-card-wrapper {
    height: auto;
    max-height: 33rem;
    padding: 0.5rem;
    overflow-y: auto;
    /* width */
    /* Track */
    /* Handle */
    /* Handle on hover */ }
    .dashboard-admin-page .my-clients-card-wrapper::-webkit-scrollbar {
      width: 8px; }
    .dashboard-admin-page .my-clients-card-wrapper::-webkit-scrollbar-track {
      background: white; }
    .dashboard-admin-page .my-clients-card-wrapper::-webkit-scrollbar-thumb {
      background: var(--primary-gray);
      border-radius: 10px; }
    .dashboard-admin-page .my-clients-card-wrapper::-webkit-scrollbar-thumb:hover {
      background: var(--primary-dark); }
  .dashboard-admin-page .dashboard-title {
    color: #3e3e3e;
    font-size: 1rem;
    font-weight: 300;
    margin: 0; }
    .dashboard-admin-page .dashboard-title strong {
      font-weight: 500; }
  .dashboard-admin-page .common-search-bar {
    flex-grow: 1; }
    .dashboard-admin-page .common-search-bar .search {
      height: 40px;
      background: var(--pure-white);
      padding-left: 35px;
      -webkit-appearance: none;
      border: none;
      border: 1px solid #e3e5e5;
      border-radius: 10px; }
      .dashboard-admin-page .common-search-bar .search ::-webkit-input-placeholder {
        font-size: 16px; }
      .dashboard-admin-page .common-search-bar .search ::placeholder {
        font-size: 16px; }
  .dashboard-admin-page .sort {
    background: transparent;
    box-shadow: none;
    font-weight: 500;
    font-size: 16px;
    color: #3e3e3e;
    align-items: center;
    display: inline-flex; }
    @media (max-width: 991.98px) {
      .dashboard-admin-page .sort {
        background: transparent; } }
    .dashboard-admin-page .sort.sort-desktop {
      font-size: 1rem;
      font-weight: normal; }
      .dashboard-admin-page .sort.sort-desktop span,
      .dashboard-admin-page .sort.sort-desktop svg {
        color: #3e3e3e; }
      .dashboard-admin-page .sort.sort-desktop span {
        font-weight: bold; }
      .dashboard-admin-page .sort.sort-desktop svg {
        vertical-align: bottom; }
  .dashboard-admin-page .no-data {
    font-size: 1rem;
    text-align: left;
    width: 100%;
    border-radius: 10px;
    margin-top: 1rem;
    color: var(--primary-gray-lightest); }
  .dashboard-admin-page.sort-menu .modal-dialog {
    margin: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    max-width: none; }
  .dashboard-admin-page.sort-menu .modal-content {
    border: none;
    border-top-left-radius: 1em;
    border-top-right-radius: 1em;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0; }
  .dashboard-admin-page.sort-menu .form-check-input {
    width: 20px;
    height: 20px;
    margin-top: 0.1rem;
    border: 1px solid #727272;
    border-radius: 50%;
    -webkit-appearance: none; }
    .dashboard-admin-page.sort-menu .form-check-input:focus {
      outline: none; }
    .dashboard-admin-page.sort-menu .form-check-input:checked {
      border: none; }
      .dashboard-admin-page.sort-menu .form-check-input:checked:before {
        content: '\25C9';
        color: #2f80ed;
        font-size: 22px;
        position: relative;
        top: -7px;
        left: -1px; }
  .dashboard-admin-page.sort-menu .cancel {
    color: #727272; }
  .dashboard-admin-page.sort-menu .form-check:not(:last-child) {
    border-bottom: 1px solid #ddd; }
  .dashboard-admin-page .student-card {
    cursor: pointer;
    box-shadow: 0px 3px 20px 1px rgba(0, 0, 0, 0.05); }
    .dashboard-admin-page .student-card .card-body {
      display: flex;
      flex-direction: column;
      justify-content: space-between; }
    .dashboard-admin-page .student-card .more-btn-desktop {
      position: absolute;
      right: 0.7em;
      top: 0.7em;
      z-index: 1; }
    .dashboard-admin-page .student-card .name {
      line-height: 1; }
      .dashboard-admin-page .student-card .name span a {
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        color: #343434; }
    .dashboard-admin-page .student-card-roadmap-link {
      color: inherit;
      text-decoration: none; }
    .dashboard-admin-page .student-card .student-dot {
      position: absolute;
      left: 4px;
      top: 1.7em; }
    .dashboard-admin-page .student-card .student-card-roadmap-link:hover {
      opacity: 1; }
    .dashboard-admin-page .student-card .last-login {
      font-size: 0.7rem;
      text-align: right;
      margin-bottom: 0;
      color: #727272; }
      .dashboard-admin-page .student-card .last-login span {
        font-weight: 500; }
    .dashboard-admin-page .student-card .dropdown {
      position: absolute;
      right: 0.7em;
      top: 0.7em; }
  .dashboard-admin-page .form-check {
    text-align: left;
    padding-left: 40px; }
    .dashboard-admin-page .form-check:hover {
      cursor: pointer; }
    .dashboard-admin-page .form-check label {
      width: 100%;
      margin-left: 1rem;
      color: #3e3e3e; }
      .dashboard-admin-page .form-check label:hover {
        cursor: pointer; }
  .dashboard-admin-page .admin-page-header-wrapper .form-check {
    padding-top: 0;
    padding-bottom: 0; }
  .dashboard-admin-page .desktop-page-container {
    padding: 2rem 0rem 1rem 0rem; }
    @media (max-width: 991.98px) {
      .dashboard-admin-page .desktop-page-container {
        padding-top: 0; } }
    .dashboard-admin-page .desktop-page-container .content-wrapper > div:first-child {
      padding-left: 0;
      padding-right: 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between; }
      .dashboard-admin-page .desktop-page-container .content-wrapper > div:first-child > div {
        flex-basis: 49.2%; }
        .dashboard-admin-page .desktop-page-container .content-wrapper > div:first-child > div.infinite-scroll-loader {
          flex-basis: 100%; }
  .dashboard-admin-page .invite-a-client-prompt h1 {
    font-family: var(--serif-font-family);
    padding-bottom: 1.2rem;
    font-size: 1.7rem; }
  .dashboard-admin-page .invite-a-client-prompt .description {
    max-width: 60%; }
  .dashboard-admin-page .invite-a-client-prompt button {
    display: flex;
    align-items: center;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    padding: 0.7rem 1.3rem;
    background-color: var(--primary-dark);
    margin-top: 0.5rem;
    border-radius: 0.6rem; }
    .dashboard-admin-page .invite-a-client-prompt button p {
      color: var(--pure-white); }

.item-count {
  display: inline-flex;
  line-height: 1;
  font-size: 0.8rem;
  font-weight: normal;
  border-radius: 6px;
  vertical-align: top; }
  .item-count svg {
    height: 12px; }
  .item-count img {
    height: 10px; }
  .item-count.red-count {
    background-color: #fbd4c6;
    color: #f1561e; }
  .item-count.gray-count {
    background-color: #f4f4f5;
    color: #727272; }

.toggle-fixed-position {
  position: -webkit-sticky;
  position: sticky;
  top: 80px;
  z-index: 10; }

.upcoming-calls-container {
  background-color: transparent;
  box-shadow: none;
  width: 100%; }

.roadmap-grid-db {
  display: grid;
  grid-row-gap: 1rem;
  row-gap: 1rem; }
  .roadmap-grid-db .roadmap-grid-header {
    display: flex;
    justify-content: space-between;
    padding: 0;
    padding-bottom: 0.5rem; }
    .roadmap-grid-db .roadmap-grid-header p {
      color: #3e3e3e;
      font-size: 1rem;
      font-weight: 200;
      margin: 0; }
      .roadmap-grid-db .roadmap-grid-header p strong {
        font-weight: 500; }
    .roadmap-grid-db .roadmap-grid-header .add-rm-btn {
      cursor: pointer;
      background-color: #3e3e3e;
      border-radius: 0.4rem;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 1.625rem;
      height: 1.625rem; }

.clients-container {
  background-color: var(--primary-white);
  box-shadow: none;
  width: 100%;
  padding-bottom: 2.5rem; }

@media (min-width: 992px) {
  .upcoming-calls-container {
    margin-top: 0;
    background-color: var(--primary-white); } }

.roadmap-grid-db-parent {
  background-color: #f6f6f6;
  box-shadow: none; }

.roadmaps-carousel-admin-page {
  width: 100%;
  padding: 0 1.5rem; }
  .roadmaps-carousel-admin-page p {
    padding-bottom: 1rem;
    font-weight: 300; }
  @media (min-width: 992px) {
    .roadmaps-carousel-admin-page .title {
      margin-bottom: -1.5rem; } }

@media (min-width: 992px) {
  .upcoming-calls-container {
    margin-top: 0;
    background-color: var(--pure-white); }
  .roadmap-grid-db-parent {
    background-color: var(--pure-white);
    box-shadow: none; }
  .clients-container {
    background-color: var(--pure-white); }
  .roadmaps-carousel-admin-page {
    padding: 2rem 1.5rem 2.5rem 1.5rem;
    background-color: var(--primary-white);
    border-radius: 0.8rem; }
    .roadmaps-carousel-admin-page p {
      padding-bottom: 0;
      font-weight: 300; } }

.add-exst-rm-body {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-width: 100%; }

.add-rm-modal {
  display: grid;
  grid-gap: 1rem;
  gap: 1rem; }

.dashboard-title {
  color: var(--primary-dark); }

.dashboard-admin-page .sort {
  color: var(--primary-dark); }
  .dashboard-admin-page .sort .roadmap-grid-db .roadmap-grid-header p {
    color: var(--primary-dark); }
  .dashboard-admin-page .sort .roadmap-grid-db .roadmap-grid-header .add-rm-btn {
    background-color: var(--primary-dark); }

.dashboard-admin-page .custom-btn-assign {
  background-color: transparent;
  color: var(--primary-dark);
  border: 1px solid var(--primary-dark);
  border-radius: 0.6rem; }
  .dashboard-admin-page .custom-btn-assign:hover {
    background-color: var(--primary-dark);
    color: var(--pure-white); }

.toggle-show-roadmaps {
  background-color: var(--pure-white);
  box-shadow: 0px -4px 20px 1px rgba(0, 0, 0, 0.05);
  margin-bottom: 1rem; }

.roadmap-grid-db-parent {
  background-color: var(--primary-bg-white);
  box-shadow: none; }

.my-clients-card-wrapper {
  /* Track */ }
  .my-clients-card-wrapper::-webkit-scrollbar-track {
    background: var(--primary-white); }

.roadmap-grid-db-parent {
  background-color: var(--primary-white); }

@media (min-width: 992px) {
  .clients-container {
    background-color: var(--primary-white); }
  .upcoming-calls-container {
    background-color: var(--primary-white); } }

.my-masonry-grid {
  /* Not needed if autoprefixing */
  /* Not needed if autoprefixing */
  display: flex;
  margin-left: -30px;
  /* gutter size offset */
  width: auto; }

.my-masonry-grid_column {
  padding-left: 30px;
  /* gutter size */
  background-clip: padding-box; }

/* Style your items */
.my-masonry-grid_column > div {
  /* change div to reference your elements you put in <Masonry> */
  margin-bottom: 30px; }

.upcomingcalls-card {
  background-color: #343434;
  color: var(--pure-white);
  box-shadow: 0px 4px 20px 1px rgba(0, 0, 0, 0.1) !important; }
  .upcomingcalls-card .card-body {
    padding-left: 70px; }
  .upcomingcalls-card .date-text {
    font-weight: 200;
    font-size: 0.7rem;
    color: #bdc0c3;
    padding-left: 0px; }
  .upcomingcalls-card .upcomingcalls-icon {
    height: 43px;
    width: 43px;
    position: absolute;
    left: 15px;
    top: 20px;
    background-color: #4a4949;
    border-radius: 50%;
    margin-right: 5px; }
    .upcomingcalls-card .upcomingcalls-icon svg {
      margin-top: 10px;
      margin-left: 14px;
      color: #676767; }
  .upcomingcalls-card .edit-call-btn {
    position: absolute;
    right: 0.7em;
    top: 1.7em;
    color: #676767; }

@media (min-width: 992px) {
  .coach-upcomingcalls-card {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.09) !important;
    border-radius: 10px; } }

h1 {
  text-align: left; }

.edit-call-modal .modal-content {
  background-color: var(--primary-white); }

.edit-call-modal .modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem; }
  .edit-call-modal .modal-header h2 {
    font-family: var(--serif-font-family); }

.edit-call-modal .form-control {
  border-radius: 0.5rem;
  border: 1px solid var(--primary-light-gray);
  display: flex;
  align-items: center;
  height: 45px;
  font-weight: 300;
  height: 3rem;
  padding: 1rem;
  font-size: 0.875rem;
  box-shadow: none; }

.edit-call-modal .form-label {
  font-size: 0.875rem;
  font-weight: 300;
  padding-bottom: 0.5rem;
  color: var(--primary-gray); }

.edit-call-modal .date-picker-cal {
  display: flex;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  align-items: center; }

.edit-call-modal .error-message {
  color: var(--primary-red);
  font-size: 0.7rem;
  margin: 0.5rem 0;
  font-weight: 700; }

.dashboard-coach-add-calls .d-contents {
  display: contents; }
  .dashboard-coach-add-calls .d-contents .col {
    padding: 0px; }

.dashboard-coach-add-calls .no-data {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  border: none;
  padding-bottom: 0; }
  .dashboard-coach-add-calls .no-data .phone-container {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f0ece8; }
  .dashboard-coach-add-calls .no-data p {
    color: var(--primary-gray-lightest);
    font-size: 1rem;
    font-weight: 300; }
  @media (max-width: 991.98px) {
    .dashboard-coach-add-calls .no-data {
      background-color: var(--primary-dark);
      padding-bottom: 1rem; }
      .dashboard-coach-add-calls .no-data .phone-container {
        background-color: #4a4949; } }

.dashboard-coach-add-calls .coach-add-calls-btn .card {
  background-color: var(--primary-dark);
  color: var(--pure-white);
  border-radius: 0.5rem;
  padding: 0;
  width: 100%; }
  .dashboard-coach-add-calls .coach-add-calls-btn .card .card-body {
    width: 1.625rem;
    height: 1.625rem;
    padding: 0;
    font-size: 0.875rem;
    display: flex;
    justify-content: center;
    align-items: center; }

.dashboard-coach-add-calls .card-title {
  font-size: 1rem;
  font-weight: 300;
  color: var(--pure-white); }

.fa-icon {
  color: var(--pure-white); }

.coach-upcomingcalls-card {
  min-width: 100%;
  background-color: var(--primary-dark); }

.upcoming-calls-title {
  color: #3e3e3e;
  font-size: 1rem;
  font-weight: 200;
  margin: 0; }
  .upcoming-calls-title span {
    font-weight: bolder;
    color: var(--primary-dark); }

h1 {
  text-align: left; }

.card {
  height: -webkit-max-content;
  height: max-content; }

.dashboard-roadmap-card {
  max-height: -webkit-max-content;
  max-height: max-content;
  box-shadow: 0px -4px 20px 1px rgba(0, 0, 0, 0.05); }
  .dashboard-roadmap-card:hover {
    -webkit-transform: scale(1.01);
            transform: scale(1.01);
    transition: all 0.2s ease-in-out; }
  .dashboard-roadmap-card .roadmap-modal {
    display: flex;
    align-items: center;
    width: 100%;
    padding-right: 1rem; }
    .dashboard-roadmap-card .roadmap-modal button {
      cursor: pointer;
      background-color: var(--primary-dark);
      color: white;
      border-radius: 0.4rem;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      min-width: 1.625rem;
      min-height: 1.625rem; }
  .dashboard-roadmap-card .card-progress-title {
    font-weight: bold;
    font-size: 0.875rem;
    color: #3e3e3e;
    max-width: 80%;
    font-family: var(--serif-font-family); }
  .dashboard-roadmap-card .progress {
    height: 0.25rem;
    background-color: #dedede; }
    .dashboard-roadmap-card .progress .progress-bar {
      background-color: var(--primary-orange);
      transition: width 0.6s ease;
      border-radius: 4px; }
  .dashboard-roadmap-card .progress-description {
    font-size: 0.7rem;
    color: #8d8d8d;
    margin: 0; }
  .dashboard-roadmap-card .roadmap-dot {
    position: absolute;
    left: -0.75rem;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    top: 50%; }
  .dashboard-roadmap-card .started-date {
    font-size: 0.8rem;
    text-align: right;
    margin-bottom: 0; }

.card-progress-title {
  color: var(--primary-dark); }

.dashboard-roadmap-card {
  background-color: var(--pure-white); }

.delete-confirmation-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%; }
  .delete-confirmation-modal .modal-header {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column; }
    .delete-confirmation-modal .modal-header h1 {
      width: 100%;
      font-size: 1.5rem;
      text-align: center;
      font-family: var(--serif-font-family);
      line-height: normal;
      font-weight: 600; }
  .delete-confirmation-modal .modal-body {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem; }
    .delete-confirmation-modal .modal-body .description {
      width: 70%;
      text-align: center;
      font-size: 1rem;
      font-weight: 400; }
    .delete-confirmation-modal .modal-body .remove {
      width: -webkit-max-content;
      width: max-content;
      padding: 1rem;
      color: var(--pure-white);
      background-color: var(--primary-dark);
      border-radius: 0.4rem; }
    .delete-confirmation-modal .modal-body .go-back {
      text-decoration: underline;
      font-weight: 400; }

.col-to-right {
  position: relative; }
  .col-to-right .col-item-to-right {
    position: absolute;
    right: 0; }

.cancel {
  color: var(--primary-dark); }

.plus-btn {
  background-color: var(--primary-dark);
  border-radius: 0.4rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 1.625rem;
  height: 1.625rem; }

.desktop-plus-btn {
  display: none; }

.mobile-plus-btn {
  display: block; }

@media (min-width: 992px) {
  .desktop-plus-btn {
    display: block; }
  .mobile-plus-btn {
    display: none; } }

.btn-secondary-cancel {
  color: #3e3e3e; }

.btn-secondary-cancel {
  color: var(--primary-dark); }

.btn-save {
  color: var(--primary-dark); }

h1 {
  text-align: left; }

p {
  font-family: var(--sans-serif-font-family);
  color: #343434; }

.sidebar {
  position: -webkit-sticky;
  position: sticky;
  display: flex;
  flex-direction: column;
  top: 5vh;
  left: 0;
  height: 80vh;
  width: 15.75rem;
  justify-items: flex-start; }
  .sidebar .s-header {
    font-weight: 400;
    margin-bottom: 2rem;
    position: relative;
    z-index: 99999;
    max-width: 45%;
    text-align: left; }
  .sidebar .payment-items {
    display: grid;
    grid-gap: 1.8rem;
    gap: 1.8rem; }
    .sidebar .payment-items .payment-item {
      display: inline-flex;
      align-items: center;
      grid-gap: 0.6rem;
      gap: 0.6rem;
      color: #343434;
      cursor: pointer; }
      .sidebar .payment-items .payment-item h3,
      .sidebar .payment-items .payment-item a {
        font-weight: normal;
        margin: 0;
        font-size: 0.9rem; }
        .sidebar .payment-items .payment-item h3:hover,
        .sidebar .payment-items .payment-item a:hover {
          color: #343434; }

h1 {
  text-align: left; }

p {
  font-family: var(--sans-serif-font-family);
  color: #343434; }
  p p {
    margin: 0; }

.dashboard-subscriptions {
  background-color: #f0ece8;
  width: 100%;
  margin: 0 auto;
  min-height: 100%;
  padding-bottom: 5rem;
  overflow: hidden; }
  .dashboard-subscriptions .menu-item-container-subs {
    padding: 0 0.5rem;
    margin-right: -0.5rem; }
  .dashboard-subscriptions .tab {
    padding: 1.5rem 2rem 0 2rem; }
  .dashboard-subscriptions .header {
    margin-bottom: 2rem;
    font-weight: normal; }
  .dashboard-subscriptions .main-content {
    display: flex;
    flex-direction: column;
    border-radius: 0.8rem;
    margin: 0 auto;
    padding: 0 2rem; }
    .dashboard-subscriptions .main-content .save {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.5rem;
      background-color: var(--primary-dark);
      color: var(--primary-white);
      border-radius: 0.4rem;
      font-weight: 500;
      font-size: 1rem; }
    .dashboard-subscriptions .main-content .main-header {
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: center; }
      .dashboard-subscriptions .main-content .main-header p {
        margin: 0; }
      .dashboard-subscriptions .main-content .main-header .edit-subscription {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        grid-gap: 0.5rem;
        gap: 0.5rem; }
    .dashboard-subscriptions .main-content .add-sub {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.6rem;
      grid-gap: 0.5rem;
      gap: 0.5rem;
      background-color: var(--primary-dark);
      color: var(--pure-white);
      font-size: 0.7rem;
      border-radius: 0.4rem;
      font-weight: 500; }
      .dashboard-subscriptions .main-content .add-sub .icon-text {
        display: flex;
        align-items: center;
        width: 100%;
        justify-items: center;
        grid-gap: 0.5rem;
        gap: 0.5rem; }
    .dashboard-subscriptions .main-content .subscription-details {
      max-width: 100%;
      padding-top: 1rem;
      display: grid; }
      .dashboard-subscriptions .main-content .subscription-details .real-labels {
        font-size: 0.875rem;
        color: var(--primary-dark); }
      .dashboard-subscriptions .main-content .subscription-details label {
        font-size: 0.8rem;
        padding: 0.3rem 0; }
      .dashboard-subscriptions .main-content .subscription-details form {
        display: flex;
        flex-direction: column;
        grid-gap: 1rem;
        gap: 1rem;
        width: 100%;
        padding-bottom: 2rem; }
        .dashboard-subscriptions .main-content .subscription-details form .sub-name {
          margin-top: -0.5rem; }
        .dashboard-subscriptions .main-content .subscription-details form input {
          padding-left: 1rem;
          border-radius: 0.5rem;
          border: 1px solid #d3d3d2; }
        .dashboard-subscriptions .main-content .subscription-details form .month-label {
          position: relative;
          right: 0;
          margin-bottom: -3.35rem;
          padding-right: 1rem;
          display: flex;
          align-items: center;
          justify-content: flex-end; }
        .dashboard-subscriptions .main-content .subscription-details form .price-label {
          position: relative;
          left: 0;
          margin-top: -3.4rem;
          padding-left: 1rem;
          display: flex;
          align-items: center;
          justify-content: flex-start; }
        .dashboard-subscriptions .main-content .subscription-details form .price-input {
          z-index: 10;
          background: transparent;
          padding-left: 2rem; }
    .dashboard-subscriptions .main-content .subscriptions-table {
      display: inline-block;
      width: 100%;
      background-color: transparent;
      border-radius: 0.6rem;
      margin-top: 1.5rem;
      overflow-x: scroll; }
      .dashboard-subscriptions .main-content .subscriptions-table .name {
        width: 75%; }
      .dashboard-subscriptions .main-content .subscriptions-table td {
        vertical-align: center;
        padding: 1rem 2rem 1rem 0; }
        .dashboard-subscriptions .main-content .subscriptions-table td .dots-settings {
          outline: none; }
        .dashboard-subscriptions .main-content .subscriptions-table td .dropdown-menu.show {
          border-radius: 0.6rem;
          z-index: 99999; }
          .dashboard-subscriptions .main-content .subscriptions-table td .dropdown-menu.show button {
            display: block;
            width: 100%;
            padding: 0.25rem 1.5rem;
            clear: both;
            font-weight: 400;
            color: #212529;
            text-align: inherit;
            white-space: nowrap;
            background-color: transparent;
            border: 0; }
      .dashboard-subscriptions .main-content .subscriptions-table th {
        padding: 1rem 0rem;
        font-weight: 300;
        font-size: 0.8rem;
        color: rgba(52, 52, 52, 0.5); }
      .dashboard-subscriptions .main-content .subscriptions-table tr {
        border-bottom: 1px solid rgba(52, 52, 52, 0.1);
        width: 100%;
        vertical-align: center; }
      .dashboard-subscriptions .main-content .subscriptions-table p {
        font-size: 0.8rem;
        font-weight: 300; }
      .dashboard-subscriptions .main-content .subscriptions-table .date {
        white-space: nowrap; }
      .dashboard-subscriptions .main-content .subscriptions-table img {
        width: 2.5rem;
        border-radius: 100rem;
        margin: 0; }
      .dashboard-subscriptions .main-content .subscriptions-table .last {
        padding: 0rem;
        max-width: -webkit-min-content;
        max-width: min-content;
        width: 2rem;
        margin: 0; }
      .dashboard-subscriptions .main-content .subscriptions-table .user {
        display: inline-flex;
        align-items: center;
        grid-gap: 0.5rem;
        gap: 0.5rem; }
        .dashboard-subscriptions .main-content .subscriptions-table .user .title h3 {
          margin: 0;
          font-weight: 300;
          font-size: 0.9rem;
          min-width: full; }
      .dashboard-subscriptions .main-content .subscriptions-table .tag {
        padding: 0.4rem;
        border-radius: 16px;
        max-width: -webkit-min-content;
        max-width: min-content;
        background: var(--primary-gray-lightest);
        font-size: 0.625rem; }
      .dashboard-subscriptions .main-content .subscriptions-table .subscribed {
        background: rgba(136, 160, 136, 0.4); }
      .dashboard-subscriptions .main-content .subscriptions-table .unsubscribed {
        background: var(--primary-gray-lightest); }
      .dashboard-subscriptions .main-content .subscriptions-table .pending {
        background: #f2f2f2;
        color: #a1a1a1; }

@media (min-width: 992px) {
  .dashboard-subscriptions {
    background-color: #f0ece8;
    width: 69rem;
    margin: 0 auto;
    overflow: hidden;
    display: flex;
    grid-gap: 5rem;
    gap: 5rem;
    max-height: 100vh;
    overflow-y: scroll;
    align-items: flex-start;
    height: 90vh;
    padding-bottom: 0; }
    .dashboard-subscriptions .main-content {
      width: 100%;
      background-color: var(--primary-white);
      padding: 2rem;
      border-radius: 0.8rem;
      margin-top: 5vh;
      overflow-y: auto;
      height: -webkit-fit-content;
      height: -moz-fit-content;
      height: fit-content; }
      .dashboard-subscriptions .main-content .main-content-inside {
        background-color: white;
        padding: 1.5rem;
        border-radius: 0.6rem; }
      .dashboard-subscriptions .main-content .main-header {
        flex-direction: row;
        justify-content: space-between; }
      .dashboard-subscriptions .main-content .subscriptions-table {
        background-color: var(--pure-white);
        box-shadow: 1px 1px 20px 1px rgba(0, 0, 0, 0.05); }
        .dashboard-subscriptions .main-content .subscriptions-table th {
          padding: 1rem 2rem;
          font-weight: 300;
          font-size: 0.8rem;
          color: rgba(52, 52, 52, 0.5); }
        .dashboard-subscriptions .main-content .subscriptions-table td {
          padding: 1rem 2rem; }
      .dashboard-subscriptions .main-content .subscription-details {
        max-width: 50%;
        padding-top: 1rem;
        display: grid; }
        .dashboard-subscriptions .main-content .subscription-details label {
          font-size: 0.8rem;
          padding: 0.3rem 0; }
        .dashboard-subscriptions .main-content .subscription-details form {
          display: flex;
          flex-direction: column;
          grid-gap: 1rem;
          gap: 1rem;
          width: 100%;
          padding-bottom: 2rem; }
          .dashboard-subscriptions .main-content .subscription-details form input {
            padding-left: 1rem;
            border-radius: 0.5rem;
            border: 1px solid #d3d3d2;
            max-width: 65%; }
          .dashboard-subscriptions .main-content .subscription-details form .month-label {
            position: relative;
            right: 0;
            width: 35%;
            margin-bottom: -3.35rem;
            padding-right: 1rem;
            display: flex;
            align-items: center;
            justify-content: flex-end; }
          .dashboard-subscriptions .main-content .subscription-details form .price-label {
            position: relative;
            left: 0;
            width: 50%;
            margin-top: -3.4rem;
            padding-left: 1rem;
            display: flex;
            align-items: center;
            justify-content: flex-start; }
          .dashboard-subscriptions .main-content .subscription-details form .price-input {
            max-width: 35%;
            z-index: 10;
            background: transparent;
            padding-left: 2rem; } }

.dashboard-create-subscription {
  background-color: var(--primary-white);
  border-radius: 0.8rem; }
  .dashboard-create-subscription .main-content-inside {
    padding: 1.5rem;
    background-color: white;
    border-radius: 1.2rem;
    height: 100vh; }
    .dashboard-create-subscription .main-content-inside .main-header {
      display: grid;
      justify-items: flex-start;
      width: 100%;
      align-content: flex-start;
      grid-gap: 0.5rem;
      gap: 0.5rem; }
      .dashboard-create-subscription .main-content-inside .main-header p {
        margin: 0; }
      .dashboard-create-subscription .main-content-inside .main-header .create {
        display: flex;
        justify-content: center;
        align-items: center;
        grid-gap: 0.5rem;
        gap: 0.5rem; }
      .dashboard-create-subscription .main-content-inside .main-header .save-sub {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.6rem;
        grid-gap: 0.5rem;
        gap: 0.5rem;
        background-color: var(--primary-dark);
        color: var(--pure-white);
        font-size: 0.7rem;
        border-radius: 0.4rem; }
  .dashboard-create-subscription .subscriptions {
    padding-top: 1.5rem; }
    .dashboard-create-subscription .subscriptions .subscription-details {
      max-width: 100%;
      padding-top: 1rem;
      display: grid; }
      .dashboard-create-subscription .subscriptions .subscription-details label {
        font-size: 0.8rem;
        padding: 0.3rem 0; }
      .dashboard-create-subscription .subscriptions .subscription-details input {
        border: 1px solid var(--primary-gray-lightest);
        border-radius: 0.5rem;
        padding: 0.6rem;
        margin-bottom: 0.65rem;
        font-size: 0.875rem; }
        .dashboard-create-subscription .subscriptions .subscription-details input::-webkit-input-placeholder {
          font-size: 0.875rem; }
        .dashboard-create-subscription .subscriptions .subscription-details input::placeholder {
          font-size: 0.875rem; }
      .dashboard-create-subscription .subscriptions .subscription-details form {
        display: flex;
        flex-direction: column; }
    .dashboard-create-subscription .subscriptions .save-sub {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1rem;
      grid-gap: 0.5rem;
      gap: 0.5rem;
      background-color: var(--primary-dark);
      color: var(--pure-white);
      font-size: 1rem;
      border-radius: 0.8rem;
      width: 100%;
      margin-top: 1rem; }
    .dashboard-create-subscription .subscriptions .add {
      background-color: transparent;
      color: var(--primary-dark);
      border: 1px solid var(--primary-dark); }

@media (min-width: 992px) {
  .dashboard-create-subscription {
    width: 65%;
    margin-top: 2.7rem;
    padding: 2rem; }
    .dashboard-create-subscription .main-content-inside {
      height: auto; }
      .dashboard-create-subscription .main-content-inside .main-header {
        display: flex;
        justify-content: space-between;
        align-content: center;
        flex-direction: row; }
    .dashboard-create-subscription .subscriptions .subscription-details {
      max-width: 35%;
      padding-top: 1rem;
      display: grid; } }

h1 {
  text-align: left; }

p {
  font-family: var(--sans-serif-font-family);
  color: #343434; }

.dashboard-payouts {
  background-color: #f0ece8;
  width: 100%;
  height: 100%;
  overflow: hidden; }
  .dashboard-payouts .header {
    margin-bottom: 2.5rem;
    font-weight: normal; }
  .dashboard-payouts .tab {
    padding: 1.5rem 2rem 0 2rem; }
  .dashboard-payouts .banner {
    display: flex;
    flex-direction: column;
    background-color: #718371;
    padding: 2rem;
    border-radius: 0.8rem;
    margin: 0 auto;
    max-width: 85%; }
    .dashboard-payouts .banner h1 {
      color: white;
      font-weight: 700;
      font-size: 1.5rem;
      font-family: var(--serif-font-family); }
    .dashboard-payouts .banner p {
      color: white;
      font-size: 0.9rem;
      font-weight: 300; }
    .dashboard-payouts .banner button {
      margin-top: 2rem;
      background-color: #343434;
      color: white;
      display: inline-flex;
      align-items: center;
      border-radius: 0.8rem;
      grid-gap: 1rem;
      gap: 1rem;
      height: 3.5rem;
      padding-right: 2.5rem;
      max-width: -webkit-max-content;
      max-width: max-content;
      font-size: 0.9rem; }
      .dashboard-payouts .banner button:hover {
        background-color: black; }
    .dashboard-payouts .banner .button-icon {
      height: 100%;
      border-color: white;
      display: inline-flex;
      align-items: center;
      border-right: 1px solid #595959;
      padding: 0 1rem; }
    .dashboard-payouts .banner a {
      margin-top: 1rem;
      color: white;
      font-size: 0.9rem;
      font-weight: 300;
      cursor: pointer;
      text-decoration: underline; }
      .dashboard-payouts .banner a:hover {
        text-decoration: underline;
        color: black; }
  .dashboard-payouts .main-content {
    padding: 2rem;
    border-radius: 0.8rem;
    max-width: 100%; }
    .dashboard-payouts .main-content .card-grid {
      display: flex;
      flex-direction: column;
      justify-items: center;
      width: 100%;
      justify-content: space-between;
      grid-gap: 1rem;
      gap: 1rem; }
      .dashboard-payouts .main-content .card-grid .card {
        display: inline-flex;
        justify-items: center;
        align-items: center;
        flex-direction: row;
        flex-wrap: nowrap;
        width: 100%;
        background-color: white;
        padding: 1.2rem 1.8rem 1.2rem 1.8rem;
        border-radius: 0.8rem; }
        .dashboard-payouts .main-content .card-grid .card .icon {
          margin-bottom: 1rem;
          color: #949393;
          width: 20%;
          display: inline-flex;
          justify-content: flex-start;
          align-items: center;
          height: 100%; }
        .dashboard-payouts .main-content .card-grid .card .title {
          width: 60%; }
        .dashboard-payouts .main-content .card-grid .card h2 {
          font-size: 1.125rem;
          font-weight: 500;
          color: #343433; }
        .dashboard-payouts .main-content .card-grid .card p {
          font-size: 0.8rem;
          margin: 0;
          font-weight: 300; }

@media (min-width: 992px) {
  .dashboard-payouts {
    padding: 3.5rem 0rem 3.5rem 5rem; }
    .dashboard-payouts .banner {
      margin-left: 25%;
      margin-top: 2.7rem;
      width: 65%; }
      .dashboard-payouts .banner p {
        color: white;
        font-size: 0.9rem;
        font-weight: 300;
        padding-right: 16rem; }
    .dashboard-payouts .main-content {
      width: 65%;
      margin-left: 25%;
      margin-top: 2rem;
      min-height: 1000px;
      background-color: var(--primary-white); }
      .dashboard-payouts .main-content .card-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        width: 100%;
        justify-content: space-between;
        grid-gap: 1rem;
        gap: 1rem;
        margin-top: 1rem; }
        .dashboard-payouts .main-content .card-grid .card {
          display: grid;
          justify-items: center;
          align-items: center;
          flex-direction: row;
          flex-wrap: nowrap;
          width: 100%;
          background-color: white;
          padding: 1.2rem 1.8rem 1.2rem 1.8rem;
          border-radius: 0.8rem; }
          .dashboard-payouts .main-content .card-grid .card .icon {
            margin-bottom: 1rem;
            color: #949393;
            width: 100%; }
          .dashboard-payouts .main-content .card-grid .card .title {
            width: 100%; }
          .dashboard-payouts .main-content .card-grid .card h2 {
            font-size: 1.25rem;
            font-weight: 500;
            color: #343433; }
          .dashboard-payouts .main-content .card-grid .card p {
            font-size: 1rem;
            margin: 0;
            font-weight: 300; } }

h1 {
  text-align: left; }

p {
  font-family: var(--sans-serif-font-family);
  color: #343434; }
  p p {
    margin: 0; }

.dashboard-clients {
  background-color: #f0ece8;
  width: 100%;
  height: 100%;
  overflow: hidden; }
  .dashboard-clients .header {
    margin-bottom: 2.5rem;
    font-weight: normal; }
  .dashboard-clients .main-content {
    background-color: transparent;
    padding: 2rem;
    border-radius: 0.8rem; }
    .dashboard-clients .main-content .main-header {
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: center;
      flex-shrink: 0; }
      .dashboard-clients .main-content .main-header p {
        margin: 0; }
      .dashboard-clients .main-content .main-header .add-sub {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.6rem;
        grid-gap: 0.5rem;
        gap: 0.5rem;
        background-color: var(--primary-dark);
        color: var(--pure-white);
        font-size: 0.7rem;
        border-radius: 0.4rem;
        font-weight: 500; }
    .dashboard-clients .main-content .subscriptions-table {
      width: 100%;
      background-color: transparent;
      border-radius: 0.6rem;
      margin-top: 2rem;
      overflow: scroll;
      display: none; }
      .dashboard-clients .main-content .subscriptions-table td {
        vertical-align: center;
        padding: 0.8rem 0rem 0.8rem 0rem; }
      .dashboard-clients .main-content .subscriptions-table th {
        padding: 1rem 0rem 1rem 0rem;
        font-weight: 300;
        font-size: 0.8rem;
        color: rgba(52, 52, 52, 0.5); }
      .dashboard-clients .main-content .subscriptions-table tr {
        border-bottom: 1px solid rgba(52, 52, 52, 0.1); }
      .dashboard-clients .main-content .subscriptions-table p {
        font-size: 0.8rem;
        font-weight: 300; }
      .dashboard-clients .main-content .subscriptions-table .first {
        padding: 0rem 1rem 0rem 1rem;
        max-width: -webkit-min-content;
        max-width: min-content;
        width: 2rem;
        margin: 0; }
      .dashboard-clients .main-content .subscriptions-table .user {
        display: inline-flex;
        align-items: center;
        grid-gap: 0.5rem;
        gap: 0.5rem; }
        .dashboard-clients .main-content .subscriptions-table .user .title h3 {
          margin: 0;
          font-weight: 300;
          font-size: 0.9rem; }
        .dashboard-clients .main-content .subscriptions-table .user .title p {
          font-size: 0.6rem;
          font-weight: 600;
          color: #88a088; }
      .dashboard-clients .main-content .subscriptions-table .tag {
        padding: 0.4rem;
        border-radius: 16px;
        max-width: -webkit-min-content;
        max-width: min-content;
        background: var(--primary-gray-lightest);
        font-size: 0.625rem; }
      .dashboard-clients .main-content .subscriptions-table .active {
        background: rgba(136, 160, 136, 0.4);
        color: var(--primary-green); }
      .dashboard-clients .main-content .subscriptions-table .canceled {
        background: rgba(196, 114, 88, 0.3);
        color: var(--primary-red); }
      .dashboard-clients .main-content .subscriptions-table .subscribed {
        background: rgba(136, 160, 136, 0.4); }
      .dashboard-clients .main-content .subscriptions-table .unsubscribed {
        background: var(--primary-gray-lightest); }
      .dashboard-clients .main-content .subscriptions-table .pending {
        background: #f2f2f2;
        color: #a1a1a1; }

@media (min-width: 992px) {
  .dashboard-clients {
    background-color: #f0ece8;
    width: 69rem;
    margin: 0 auto;
    overflow: hidden;
    display: flex;
    grid-gap: 5rem;
    gap: 5rem;
    max-height: 100vh;
    overflow-y: scroll;
    align-items: flex-start;
    height: 90vh; }
    .dashboard-clients .header {
      margin-bottom: 2.5rem;
      font-weight: normal; }
    .dashboard-clients .main-content {
      width: 100%;
      background-color: var(--primary-white);
      padding: 2rem;
      border-radius: 0.8rem;
      margin-top: 5vh;
      overflow-y: auto;
      height: -webkit-fit-content;
      height: -moz-fit-content;
      height: fit-content; }
      .dashboard-clients .main-content p {
        padding-top: 0.4rem; }
      .dashboard-clients .main-content .main-header {
        flex-direction: row;
        justify-content: space-between; }
      .dashboard-clients .main-content .subscriptions-table {
        width: 100%;
        background-color: white;
        box-shadow: 1px 1px 20px 1px rgba(0, 0, 0, 0.05);
        border-radius: 0.6rem;
        margin-top: 2rem;
        display: table; }
        .dashboard-clients .main-content .subscriptions-table td {
          vertical-align: center;
          padding: 1.5rem 0rem; }
        .dashboard-clients .main-content .subscriptions-table th {
          padding: 1rem 0rem 1rem 0rem;
          font-weight: 300;
          font-size: 0.8rem;
          color: rgba(52, 52, 52, 0.5); }
        .dashboard-clients .main-content .subscriptions-table tr {
          border-bottom: 1px solid rgba(52, 52, 52, 0.1); }
        .dashboard-clients .main-content .subscriptions-table p {
          font-size: 0.8rem;
          font-weight: 300; }
        .dashboard-clients .main-content .subscriptions-table .first {
          padding: 0rem 1rem 0rem 1rem;
          max-width: -webkit-min-content;
          max-width: min-content;
          width: 2rem;
          margin: 0; }
        .dashboard-clients .main-content .subscriptions-table .user {
          display: inline-flex;
          align-items: center;
          grid-gap: 0.5rem;
          gap: 0.5rem; }
          .dashboard-clients .main-content .subscriptions-table .user .title h3 {
            margin: 0;
            font-weight: 300;
            font-size: 0.9rem; }
          .dashboard-clients .main-content .subscriptions-table .user .title p {
            font-size: 0.6rem;
            font-weight: 600;
            color: #88a088; }
        .dashboard-clients .main-content .subscriptions-table .tag {
          padding: 0.4rem;
          border-radius: 16px;
          max-width: -webkit-min-content;
          max-width: min-content;
          background: var(--primary-gray-lightest);
          font-size: 0.625rem; }
        .dashboard-clients .main-content .subscriptions-table .active {
          background: rgba(136, 160, 136, 0.4);
          color: var(--primary-green); }
        .dashboard-clients .main-content .subscriptions-table .canceled {
          background: rgba(196, 114, 88, 0.3);
          color: var(--primary-red); }
        .dashboard-clients .main-content .subscriptions-table .subscribed {
          background: rgba(136, 160, 136, 0.4); }
        .dashboard-clients .main-content .subscriptions-table .unsubscribed {
          background: var(--primary-gray-lightest); }
        .dashboard-clients .main-content .subscriptions-table .pending {
          background: #f2f2f2;
          color: #a1a1a1; } }

.mobile-subscribers {
  display: flex;
  flex-direction: column; }
  .mobile-subscribers .row {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0rem;
    margin: 0 auto;
    border-bottom: 0.3px solid rgba(196, 196, 196, 0.3); }
    .mobile-subscribers .row .profile-info {
      display: flex;
      justify-content: flex-start;
      grid-gap: 0.5rem;
      gap: 0.5rem; }
      .mobile-subscribers .row .profile-info .name-container .client-name {
        font-size: 1rem; }
      .mobile-subscribers .row .profile-info .name-container .sub-name {
        font-size: 0.875rem;
        font-weight: 300; }
    .mobile-subscribers .row .price .sub-status {
      font-size: 0.7rem;
      font-weight: 300;
      padding: 0.5rem;
      border-radius: 1rem;
      background: rgba(136, 160, 136, 0.4);
      color: var(--primary-green);
      text-align: center; }
    .mobile-subscribers .row .price .sub-price {
      font-size: 0.8rem;
      font-weight: 500;
      text-align: end;
      padding-top: 0.5rem;
      padding-right: 0.5rem; }

.payments-tab-selector {
  display: flex;
  width: 100%;
  flex-direction: column; }
  .payments-tab-selector .mobile-title {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-items: start;
    align-items: center;
    margin-bottom: 1.5rem;
    width: 100%; }
    .payments-tab-selector .mobile-title h2 {
      font-family: var(--serif-font-family);
      font-size: 1.125rem; }
  .payments-tab-selector .payment-sections {
    display: inline-flex;
    justify-content: space-between;
    grid-gap: 1rem;
    gap: 1rem;
    flex-wrap: wrap;
    margin-bottom: 1.5rem;
    width: 100%;
    align-items: flex-start; }
    .payments-tab-selector .payment-sections .payment-section {
      display: grid;
      grid-gap: 0.2rem;
      gap: 0.2rem;
      justify-items: center; }
      .payments-tab-selector .payment-sections .payment-section button {
        outline: none; }
      .payments-tab-selector .payment-sections .payment-section p {
        margin: 0;
        font-weight: 500; }

.stripe-modal .modal-content {
  border-radius: 1.2rem; }

.modal-header-in {
  display: flex;
  flex-direction: column;
  padding: 0 1.4rem; }
  .modal-header-in p {
    font-size: 0.875rem;
    color: #918f8f; }
  .modal-header-in h1 {
    font-family: var(--serif-font-family);
    padding-bottom: 1.2rem;
    font-size: 2.2rem;
    max-width: 80%;
    line-height: 1.5rem; }
  .modal-header-in .description {
    font-weight: 300;
    color: var(--primary-dark); }

.modal-image-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: space-between;
  align-items: flex-start;
  height: -webkit-max-content;
  height: max-content;
  padding-bottom: 1rem; }
  .modal-image-grid img {
    grid-column: 2;
    width: 12rem;
    margin: 0 auto; }
  .modal-image-grid button {
    padding-top: 1rem;
    outline: none; }
    .modal-image-grid button p {
      text-decoration: underline; }

.modal-footer-stripe {
  background-color: var(--primary-bg-white);
  display: grid;
  justify-content: flex-start;
  grid-row-gap: 0.5rem;
  row-gap: 0.5rem;
  min-width: 100%;
  padding: 0.3rem 2rem;
  border-radius: 0 0 1.2rem 1.2rem;
  padding-top: 2rem;
  padding-bottom: 3.5rem; }
  .modal-footer-stripe .stripe_button {
    min-width: 100%;
    display: flex;
    height: 5rem;
    align-items: center; }
    .modal-footer-stripe .stripe_button p {
      margin-bottom: 1rem; }
  .modal-footer-stripe button {
    background-color: var(--primary-dark);
    color: var(--pure-white);
    display: inline-flex;
    align-items: center;
    border-radius: 0.8rem;
    grid-gap: 1rem;
    gap: 1rem;
    height: 3.5rem;
    width: 99%;
    font-size: 0.9rem;
    font-weight: 500;
    padding-right: 16rem;
    white-space: nowrap;
    height: 5rem;
    font-size: 1rem;
    font-weight: 300; }
  .modal-footer-stripe .button-icon {
    height: 100%;
    border-color: white;
    display: inline-flex;
    align-items: center;
    border-right: 1px solid #595959;
    padding: 0 1.7rem; }
  .modal-footer-stripe a {
    padding-bottom: 1rem; }
    .modal-footer-stripe a p {
      text-decoration: underline;
      font-weight: 500; }

.modal-content {
  background-color: var(--primary-white); }

button {
  outline: none; }

.invitation-back {
  position: absolute;
  top: -3.5rem;
  outline: none;
  z-index: 10; }

.modal-header .invitation-cancel-header {
  display: flex;
  justify-content: flex-end;
  width: 100%; }
  .modal-header .invitation-cancel-header button {
    font-weight: 500;
    outline: none;
    padding: 1rem;
    max-width: -webkit-max-content;
    max-width: max-content; }

.modal-body .invitation-modal-body {
  padding: 1rem;
  padding-top: 0; }

.assign-roadmap-modal {
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem;
  padding: 1rem;
  height: 35rem;
  overflow-y: scroll; }

.rdmps-header-modal {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 1rem;
  padding-top: 2rem; }

.dashboard-coach-add-calls-page {
  padding-top: 3rem; }
  .dashboard-coach-add-calls-page .add-btn {
    font-weight: 300;
    font-family: var(--sans-serif-font-family); }
  .dashboard-coach-add-calls-page .cancel {
    padding-left: 0.5rem; }
  .dashboard-coach-add-calls-page .form-label {
    font-size: 0.875rem;
    color: var(--primary-gray);
    padding-bottom: 0.5rem; }
  .dashboard-coach-add-calls-page input[type='text'],
  .dashboard-coach-add-calls-page .theme-dark input[type='text'],
  .dashboard-coach-add-calls-page .form-control {
    border-radius: 0.5rem;
    border: 1px solid var(--primary-gray-lighter);
    height: 3.5rem;
    display: flex;
    align-items: center;
    padding: 0;
    padding-left: 1rem; }
  .dashboard-coach-add-calls-page .date-picker-cal {
    display: flex;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    align-items: center; }

.coach-message-prompt-card {
  background-color: var(--primary-white);
  box-shadow: none;
  width: 100%;
  align-items: center;
  display: grid;
  font-weight: 200;
  padding-top: 6rem;
  margin-top: -2rem; }
  .coach-message-prompt-card .card-body {
    padding: 0; }
    .coach-message-prompt-card .card-body .inside-info {
      padding: 1rem; }
      .coach-message-prompt-card .card-body .inside-info h1 {
        text-align: left; }
  .coach-message-prompt-card .message-prompt-title {
    font-family: var(--serif-font-family);
    font-size: 1.3rem;
    font-weight: bold;
    margin-bottom: 25px; }
    .coach-message-prompt-card .message-prompt-title span {
      font-family: var(--serif-font-family);
      text-transform: capitalize; }

@media (min-width: 992px) {
  .coach-message-prompt-card {
    position: relative;
    max-height: 100%;
    top: 0;
    padding-top: 2rem;
    margin-top: 0;
    /*     max-height: 19rem; // this seems to fix the issue with the card being too tall on safari
 */ }
    .coach-message-prompt-card .card-body {
      padding: 0; }
      .coach-message-prompt-card .card-body .inside-info {
        padding: 2rem 1.5rem 2.5rem 1.5rem; } }

.client-message-prompt-card {
  background-color: var(--primary-white);
  box-shadow: none;
  width: 100%;
  align-items: center;
  display: grid;
  padding: 1rem;
  font-weight: 200; }
  .client-message-prompt-card h1 {
    font-family: var(--serif-font-family);
    font-size: 1.3rem;
    font-weight: bold;
    text-align: left; }
    .client-message-prompt-card h1 span {
      font-family: var(--serif-font-family);
      text-transform: capitalize; }
  .client-message-prompt-card button {
    background-color: var(--primary-dark);
    border: none;
    border-radius: 0.6rem;
    color: var(--primary-white);
    font-weight: 500; }

@media (min-width: 992px) {
  .client-message-prompt-card {
    position: relative;
    padding: 2rem;
    height: -webkit-min-content;
    height: min-content;
    top: 0;
    /*     max-height: 19rem; // this seems to fix the issue with the card being too tall on safari
 */ } }

h1 {
  text-align: left; }

.dashboard-connect-to-get-paid {
  background-color: var(--primary-white);
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 999999; }
  .dashboard-connect-to-get-paid .header {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: 100%;
    align-items: center;
    justify-items: center; }
    .dashboard-connect-to-get-paid .header img {
      width: 10rem; }
    .dashboard-connect-to-get-paid .header svg {
      margin-top: -2.5rem;
      margin-left: -2.5rem; }
  .dashboard-connect-to-get-paid .content {
    padding: 0 1rem;
    bottom: 0;
    margin-top: 30%; }
    .dashboard-connect-to-get-paid .content p {
      font-size: 0.7rem;
      color: #828181; }
    .dashboard-connect-to-get-paid .content h1 {
      font-family: var(--serif-font-family);
      padding-bottom: 1.2rem;
      font-size: 2rem; }
    .dashboard-connect-to-get-paid .content .description {
      color: var(--primary-dark);
      font-weight: 300;
      font-size: 1rem; }
    .dashboard-connect-to-get-paid .content .button-container {
      margin-top: 1.5rem; }
      .dashboard-connect-to-get-paid .content .button-container p {
        color: var(--primary-dark);
        font-size: 1rem; }
      .dashboard-connect-to-get-paid .content .button-container .stripe_button {
        min-width: 100%;
        display: flex; }
      .dashboard-connect-to-get-paid .content .button-container button {
        background-color: var(--primary-dark);
        color: var(--pure-white);
        display: inline-flex;
        align-items: center;
        border-radius: 0.7rem;
        grid-gap: 1rem;
        gap: 1rem;
        height: 4.5rem;
        min-width: 100%;
        font-size: 1rem;
        font-weight: 500;
        padding-right: 16rem;
        white-space: nowrap;
        margin-top: 1rem; }
      .dashboard-connect-to-get-paid .content .button-container .button-icon {
        height: 100%;
        border-color: white;
        display: inline-flex;
        align-items: center;
        border-right: 1px solid #595959;
        padding: 0 2rem; }
    .dashboard-connect-to-get-paid .content .learn {
      text-decoration: underline;
      font-weight: 500;
      margin-top: 2rem; }

h1 {
  text-align: left; }

.dashboard-onboarding-invitation-page {
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 99999;
  width: 100%;
  min-height: 100vh;
  overflow: scroll;
  background-color: var(--primary-bg-white); }

.onboarding-invitation-container h1 {
  text-align: left; }

.onboarding-invitation-container .return-cancel-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding: 0 1rem;
  padding-top: 10%; }
  .onboarding-invitation-container .return-cancel-header p {
    font-weight: 300;
    color: var(--primary-dark); }

.onboarding-invitation-container .modal-body {
  padding-top: 0; }

.onboarding-invitation-container .modal-body-container {
  padding: 1.5rem 1.5rem 5rem 1.5rem;
  background-color: var(--primary-white);
  border-radius: 2rem;
  overflow: 'scroll';
  margin-top: 1rem; }

@media (min-width: 768px) {
  .onboarding-invitation-container .modal-body-container {
    padding: 0;
    background-color: transparent;
    border-radius: 0; } }

.onboarding-invitation-container .steps-container-lines {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: 100%;
  padding-top: 4.81rem;
  grid-gap: 1rem;
  gap: 1rem; }

.onboarding-invitation-container .step {
  color: var(--primary-gray);
  font-size: 0.875rem; }

.onboarding-invitation-container h1 {
  font-weight: 700;
  font-family: var(--serif-font-family);
  font-size: 2.2rem;
  margin: 1rem 0; }

.onboarding-invitation-container .description {
  font-weight: 300; }

.onboarding-invitation-container .step-5 {
  flex-direction: column;
  width: 100%;
  margin-top: 3rem; }
  .onboarding-invitation-container .step-5 .like-hand {
    width: 20%;
    margin: 0 auto;
    display: flex;
    margin-top: -5rem; }
  .onboarding-invitation-container .step-5 h1,
  .onboarding-invitation-container .step-5 p {
    text-align: center; }
  .onboarding-invitation-container .step-5 p {
    margin: 0 auto;
    max-width: 80%;
    margin-bottom: 2.5rem;
    font-weight: 300; }
  .onboarding-invitation-container .step-5 h1 {
    margin-bottom: 1rem;
    font-size: 1.5rem; }
  .onboarding-invitation-container .step-5 .invite-another {
    display: flex;
    grid-gap: 1rem;
    gap: 1rem;
    width: 100%;
    justify-content: center;
    align-items: center;
    background-color: var(--primary-dark);
    color: var(--primary-white);
    font-weight: 500;
    padding: 1rem;
    margin-top: 1rem;
    border-radius: 0.6rem; }
  .onboarding-invitation-container .step-5 .done {
    margin-top: 1rem;
    font-weight: 500; }

.onboarding-invitation-container .modal-profile-container {
  display: flex;
  flex-direction: column;
  background-color: var(--primary-white);
  margin: 0rem auto;
  justify-content: center;
  width: 100%;
  border-radius: 0.5rem;
  padding: 1.5rem 0rem;
  padding-top: 0; }
  .onboarding-invitation-container .modal-profile-container .dashboard-roadmap-card {
    box-shadow: none;
    padding: 0; }
  .onboarding-invitation-container .modal-profile-container h1 {
    font-size: 2.25rem;
    font-family: var(--serif-font-family); }
  .onboarding-invitation-container .modal-profile-container .add-rm-modal {
    height: 18rem;
    overflow-y: scroll;
    display: flex;
    flex-direction: column; }
  .onboarding-invitation-container .modal-profile-container .form-control {
    border-radius: 0.5rem;
    border: 1px solid var(--primary-gray-lighter);
    padding: 1.5rem 1rem;
    font-size: 1rem;
    width: 100%;
    margin-bottom: 1rem;
    margin: 0 auto; }
  .onboarding-invitation-container .modal-profile-container .checkbox-grid {
    display: grid;
    grid-gap: 1rem;
    gap: 1rem;
    width: 100%;
    max-width: 90%;
    padding: 0 2rem; }
    .onboarding-invitation-container .modal-profile-container .checkbox-grid .form-check {
      display: flex;
      align-items: flex-start; }
  .onboarding-invitation-container .modal-profile-container .text-area-edit-profile {
    height: 7rem;
    padding: 1rem; }
  .onboarding-invitation-container .modal-profile-container .save-cancel-grid {
    display: none;
    grid-gap: 0.5rem;
    gap: 0.5rem; }
  .onboarding-invitation-container .modal-profile-container .btn-cancel-edit {
    display: block;
    background-color: transparent;
    width: 100%;
    padding: 0.5rem;
    color: var(--primary-dark);
    border-radius: 0.5rem;
    justify-items: center;
    min-width: -webkit-max-content;
    min-width: max-content;
    border: 1px solid var(--primary-dark);
    max-width: 85%;
    margin: 0 auto; }
    .onboarding-invitation-container .modal-profile-container .btn-cancel-edit:hover {
      background-color: var(--primary-dark);
      color: var(--pure-white); }
  .onboarding-invitation-container .modal-profile-container .btn-save-edit {
    display: block;
    background-color: var(--primary-dark);
    color: var(--pure-white);
    width: 100%;
    padding: 0.6rem;
    border-radius: 0.5rem;
    justify-items: center;
    min-width: -webkit-max-content;
    min-width: max-content;
    max-width: 85%;
    margin: 0 auto; }
  .onboarding-invitation-container .modal-profile-container .resend-delete-grid {
    display: grid;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    justify-items: center;
    margin-top: 1rem;
    grid-template-columns: 1fr 1fr;
    margin: 0 auto;
    width: 85%;
    padding: 1rem 0; }
    .onboarding-invitation-container .modal-profile-container .resend-delete-grid .action-button {
      width: 100%;
      background-color: var(--primary-gray);
      color: var(--pure-white);
      padding: 0.5rem;
      border-radius: 0.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1rem; }

.onboarding-invitation-container .invitation-button-container {
  display: flex;
  flex-direction: column;
  width: 100%; }
  .onboarding-invitation-container .invitation-button-container .invitation-submit {
    background-color: var(--primary-dark);
    display: block;
    color: var(--pure-white);
    width: 100%;
    padding: 1rem;
    border-radius: 0.8rem; }
  .onboarding-invitation-container .invitation-button-container .error-from-console {
    color: var(--primary-red);
    font-size: 0.875rem;
    margin-top: 0.5rem;
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 1rem;
    background-color: rgba(196, 114, 88, 0.3);
    border-radius: 0.5rem; }

.onboarding-invitation-container .invitation-subs-grid {
  display: grid;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  max-height: 15rem;
  overflow-y: scroll;
  margin-bottom: 1rem; }
  .onboarding-invitation-container .invitation-subs-grid .invitation-sub {
    cursor: pointer;
    background-color: var(--pure-white);
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 1rem;
    border: 1px solid #d3d3d2;
    border-radius: 0.5rem;
    height: 5.25rem; }
    .onboarding-invitation-container .invitation-subs-grid .invitation-sub .select {
      display: inline-flex;
      grid-gap: 0.5rem;
      gap: 0.5rem; }
      .onboarding-invitation-container .invitation-subs-grid .invitation-sub .select button {
        outline: none; }
    .onboarding-invitation-container .invitation-subs-grid .invitation-sub h3 {
      font-size: 1.125rem;
      font-weight: 300;
      margin: 0; }
    .onboarding-invitation-container .invitation-subs-grid .invitation-sub p {
      font-size: 1.125rem;
      font-weight: 500;
      margin: 0; }

.onboarding-invitation-container .add-new-subs {
  margin: 0 auto;
  display: inline-flex;
  align-items: center;
  grid-gap: 0.3rem;
  gap: 0.3rem;
  margin-bottom: 0.3rem;
  outline: none; }

.onboarding-invitation-container .subscription-details-invitation form {
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem;
  width: 100%;
  padding-bottom: 2rem;
  position: relative; }
  .onboarding-invitation-container .subscription-details-invitation form input {
    padding-left: 1rem;
    border-radius: 0.5rem;
    border: 1px solid #d3d3d2; }
  .onboarding-invitation-container .subscription-details-invitation form .month-label {
    position: absolute;
    left: 0;
    bottom: 0;
    margin-left: 20%;
    margin-bottom: 2.65rem;
    max-width: -webkit-max-content;
    max-width: max-content; }
    @media (min-width: 768px) {
      .onboarding-invitation-container .subscription-details-invitation form .month-label {
        margin-left: 25%; } }
  .onboarding-invitation-container .subscription-details-invitation form .price-label {
    position: absolute;
    left: 0;
    bottom: 0;
    margin-left: 3%;
    margin-bottom: 2.65rem; }
  .onboarding-invitation-container .subscription-details-invitation form .price-input {
    max-width: 35%;
    background-color: var(--pure-white);
    padding-left: 2rem; }

.onboarding-invitation-container .step-4 {
  display: flex;
  flex-direction: column; }
  .onboarding-invitation-container .step-4 h1 {
    margin: 0; }
  .onboarding-invitation-container .step-4 .client-info {
    padding-bottom: 1rem; }
    .onboarding-invitation-container .step-4 .client-info h1 {
      margin: 0;
      padding: 0;
      font-family: var(--sans-serif-font-family);
      font-weight: 300; }
    .onboarding-invitation-container .step-4 .client-info p {
      font-weight: 300; }
  .onboarding-invitation-container .step-4 .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 1rem 0;
    padding-top: 1rem; }
  .onboarding-invitation-container .step-4 .roadmaps-grid {
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
    max-height: 13rem;
    overflow-y: scroll;
    margin-bottom: 1rem; }
  .onboarding-invitation-container .step-4 .invitation-sub {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-height: 5.25rem;
    padding-bottom: 1rem; }
    .onboarding-invitation-container .step-4 .invitation-sub h3 {
      max-width: 50%;
      font-size: 1.25rem; }

.onboarding-invitation-container .error-active-sub {
  color: var(--primary-red);
  font-size: 0.875rem;
  margin-bottom: 1rem; }

.carousel-container {
  width: 100%;
  display: flex;
  flex-direction: column; }
  .carousel-container button {
    outline: none; }
  .carousel-container .buttons-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    grid-gap: 1rem;
    gap: 1rem;
    padding-bottom: 1rem; }
    .carousel-container .buttons-container .right {
      -webkit-transform: scaleX(-1);
              transform: scaleX(-1); }

.roadmap-parent-container {
  padding: 0 0.2rem; }
  .roadmap-parent-container .roadmap-container {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 1rem;
    height: 15.5rem;
    position: relative;
    border-radius: 0.6rem;
    cursor: pointer; }
  .roadmap-parent-container .roadmap-title {
    position: absolute;
    bottom: 0;
    padding-bottom: 1rem; }
    .roadmap-parent-container .roadmap-title h3 {
      color: var(--primary-white);
      display: flex;
      flex-wrap: wrap;
      max-width: 80%;
      font-family: var(--serif-font-family);
      font-size: 1rem; }

.carousel-wrapper {
  display: flex;
  width: 100%;
  position: relative; }

.carousel-content-wrapper {
  overflow: hidden;
  width: 100%;
  height: 100%; }

.carousel-content {
  display: flex;
  transition: all 250ms linear;
  -ms-overflow-style: none;
  /* hide scrollbar in IE and Edge */
  scrollbar-width: none;
  /* hide scrollbar in Firefox */ }

/* hide scrollbar in webkit browser */
.carousel-content::-webkit-scrollbar,
.carousel-content::-webkit-scrollbar {
  display: none; }

.carousel-content > * {
  width: 100%;
  flex-shrink: 0;
  flex-grow: 1; }

.carousel-content.show-2 > * {
  width: 50%; }

.carousel-content.show-3 > * {
  width: calc(100% / 3); }

.carousel-content.show-4 > * {
  width: calc(100% / 4); }

.carousel-container {
  width: 100%;
  display: flex;
  flex-direction: column; }

.carousel-wrapper {
  display: flex;
  width: 100%;
  position: relative; }

.carousel-content-wrapper {
  overflow: hidden;
  width: 100%;
  height: 100%; }

.carousel-content {
  display: flex;
  transition: all 250ms linear;
  -ms-overflow-style: none;
  /* hide scrollbar in IE and Edge */
  scrollbar-width: none;
  /* hide scrollbar in Firefox */ }

/* hide scrollbar in webkit browser */
.carousel-content::-webkit-scrollbar,
.carousel-content::-webkit-scrollbar {
  display: none; }

.carousel-content > * {
  width: 100%;
  flex-shrink: 0;
  flex-grow: 1; }

.carousel-content.show-2 > * {
  width: 50%; }

.carousel-content.show-3 > * {
  width: calc(100% / 3); }

.carousel-content.show-4 > * {
  width: calc(100% / 4); }

.invitation-list-modal button {
  outline: none; }

.invitation-list-modal .modal-header {
  padding-top: 2rem; }
  .invitation-list-modal .modal-header .cancel {
    display: flex;
    justify-content: flex-end;
    width: 100%; }

.invitation-list-modal .modal-body {
  padding: 0 2rem; }
  .invitation-list-modal .modal-body .modal-title {
    font-size: 2.2rem;
    font-weight: 700;
    font-family: var(--serif-font-family);
    color: var(--primary-dark);
    text-align: left; }
  .invitation-list-modal .modal-body .description {
    text-align: left;
    font-weight: 300;
    padding-top: 0.5rem; }
  .invitation-list-modal .modal-body .choose {
    font-weight: 500;
    margin: 1.5rem 0; }
  .invitation-list-modal .modal-body .invitation-list-modal-body {
    display: grid;
    grid-row-gap: 1rem;
    row-gap: 1rem;
    max-height: 30vh;
    overflow-y: scroll;
    margin-bottom: 1.5rem; }
    .invitation-list-modal .modal-body .invitation-list-modal-body .invitation {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1rem;
      border: 1px solid #d3d3d2;
      border-radius: 0.6rem; }
      .invitation-list-modal .modal-body .invitation-list-modal-body .invitation .icon-and-title {
        display: flex;
        align-items: center;
        grid-gap: 0.7rem;
        gap: 0.7rem; }
        .invitation-list-modal .modal-body .invitation-list-modal-body .invitation .icon-and-title .title h3 {
          font-size: 1.125rem;
          font-weight: 300;
          color: var(--primary-dark);
          max-width: 90%;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          /* number of lines to show */
          line-clamp: 2;
          -webkit-box-orient: vertical; }
        .invitation-list-modal .modal-body .invitation-list-modal-body .invitation .icon-and-title .title .coach-name {
          font-size: 0.8rem;
          font-weight: 300;
          color: var(--primary-dark); }
      .invitation-list-modal .modal-body .invitation-list-modal-body .invitation .price p {
        display: flex;
        flex-wrap: nowrap;
        font-weight: 600;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap; }
  .invitation-list-modal .modal-body .checkout {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
    background-color: var(--primary-dark);
    color: white;
    font-weight: 500;
    padding: 1rem;
    border-radius: 0.6rem;
    border: none;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 1.5rem; }
    .invitation-list-modal .modal-body .checkout:disabled {
      background-color: #afafaf; }

.refunds-modal .modal-header .modal-title {
  font-size: 1.5rem;
  font-weight: 700;
  font-family: var(--serif-font-family);
  margin: 0 auto;
  padding-top: 1rem; }

.reasons-container {
  padding: 0 2rem;
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem; }
  .reasons-container button {
    outline: none; }
  .reasons-container .arrow {
    position: absolute;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    z-index: 99999;
    right: 0;
    margin-top: 9.75rem;
    margin-right: 4rem; }
    @media (min-width: 768px) {
      .reasons-container .arrow {
        margin-top: 4.6rem;
        margin-right: 4rem; } }
  .reasons-container .refund-item {
    display: flex;
    flex-direction: column;
    width: 100%;
    grid-gap: 1.1rem;
    gap: 1.1rem; }
    @media (min-width: 768px) {
      .reasons-container .refund-item {
        flex-direction: row;
        align-items: center; } }
    .reasons-container .refund-item input,
    .reasons-container .refund-item select,
    .reasons-container .refund-item textarea {
      width: 100%;
      height: 2.8rem;
      padding-left: 1rem;
      border-radius: 0.6rem;
      border: 1px solid var(--primary-gray-lighter);
      font-weight: 300;
      -webkit-appearance: none;
      -moz-appearance: none; }
      .reasons-container .refund-item input:disabled,
      .reasons-container .refund-item select:disabled,
      .reasons-container .refund-item textarea:disabled {
        background-color: var(--pure-white);
        border: 1px solid var(--primary-gray-lighter); }
    .reasons-container .refund-item textarea {
      padding-top: 0.5rem;
      height: 4.5rem; }
    .reasons-container .refund-item p {
      font-weight: 300; }
  .reasons-container .explanation p {
    opacity: 0; }
  .reasons-container .warning {
    margin: 0 auto;
    display: flex;
    align-items: center;
    grid-gap: 0.7rem;
    gap: 0.7rem;
    width: 80%; }
    .reasons-container .warning p {
      font-size: 0.8rem;
      color: var(--primary-dark-green); }
  .reasons-container .refund {
    background-color: var(--primary-dark);
    color: var(--pure-white);
    max-width: -webkit-min-content;
    max-width: min-content;
    margin: 0 auto;
    padding: 1rem 2rem;
    border-radius: 0.6rem;
    margin-top: 1rem; }
  .reasons-container .cancel p {
    font-size: 0.875rem;
    text-decoration: underline;
    padding-bottom: 0.5rem; }

.dashboard-coach-add-calls-page-modal .modal-content {
  background-color: var(--primary-white); }

.dashboard-coach-add-calls-page-modal .modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  margin-top: 1.5rem; }
  .dashboard-coach-add-calls-page-modal .modal-header h2 {
    font-family: var(--sans-serif-font-family);
    font-size: 1.5rem;
    font-weight: 500; }

.dashboard-coach-add-calls-page-modal .form-control {
  border-radius: 0.5rem;
  border: 1px solid var(--primary-gray-lighter);
  display: flex;
  align-items: center;
  height: 45px;
  font-weight: 300;
  height: 3rem;
  padding: 1rem;
  font-size: 0.875rem;
  box-shadow: none; }

.dashboard-coach-add-calls-page-modal label {
  font-size: 0.875rem;
  font-weight: 300;
  padding-bottom: 0.5rem;
  color: var(--primary-gray); }

.dashboard-coach-add-calls-page-modal .date-picker-cal {
  display: flex;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  align-items: center; }

.dashboard-coach-add-calls-page-modal .error-message {
  color: var(--primary-red);
  font-size: 0.7rem;
  margin: 0.5rem 0;
  font-weight: 700; }

.dashboard-coach-add-calls-page-modal .call-types {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  margin: 1rem 0;
  margin-top: 0.5rem; }
  .dashboard-coach-add-calls-page-modal .call-types .call-type {
    width: 100%;
    height: 2.5rem;
    border-radius: 0.25rem;
    background-color: #d3d0cc;
    display: flex;
    align-items: center;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    padding: 1rem;
    cursor: pointer; }
  .dashboard-coach-add-calls-page-modal .call-types .active {
    border: 1px solid #e8744f;
    background-color: var(--pure-white); }

.dashboard-coach-add-calls-page-modal .modal-content {
  background-color: var(--primary-white); }

.dashboard-coach-add-calls-page-modal .modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  margin-top: 1.5rem; }
  .dashboard-coach-add-calls-page-modal .modal-header h2 {
    font-family: var(--sans-serif-font-family);
    font-size: 1.5rem;
    font-weight: 300; }

.dashboard-coach-add-calls-page-modal .form-control {
  border-radius: 0.5rem;
  border: 1px solid var(--primary-gray-lighter);
  display: flex;
  align-items: center;
  height: 45px;
  font-weight: 300;
  height: 3rem;
  padding: 1rem;
  font-size: 0.875rem;
  box-shadow: none; }

.dashboard-coach-add-calls-page-modal .form-label {
  font-size: 0.875rem;
  font-weight: 300;
  padding-bottom: 0.5rem;
  color: var(--primary-gray); }

.dashboard-coach-add-calls-page-modal .date-picker-cal {
  display: flex;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  align-items: center; }

.dashboard-coach-add-calls-page-modal .error-message {
  color: var(--primary-red);
  font-size: 0.7rem;
  margin: 0.5rem 0;
  font-weight: 700; }

.dashboard-coach-add-calls-page-modal .delete-btn {
  width: -webkit-max-content;
  width: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem;
  background-color: var(--primary-dark);
  color: var(--pure-white);
  border-radius: 0.5rem;
  margin: 1.5rem; }

.dashboard-coach-add-calls-page-modal .use-upheal-link {
  display: flex;
  align-items: center;
  width: 100%;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  margin-top: -0.5rem;
  margin-bottom: 1rem; }
  .dashboard-coach-add-calls-page-modal .use-upheal-link button {
    outline: none;
    height: 1rem;
    display: flex;
    align-items: center; }
  .dashboard-coach-add-calls-page-modal .use-upheal-link p {
    font-size: 0.75rem;
    font-weight: 300;
    color: var(--primary-dark); }

.intervals {
  display: flex;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem; }
  .intervals button {
    display: flex;
    align-items: center;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    font-size: 0.75rem; }

.clients-card-container {
  width: 100%;
  padding: 2rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--primary-white);
  border-radius: 10px;
  overscroll-behavior: contain; }
  .clients-card-container .clients-card-header {
    width: calc(100% - 3.75rem);
    margin: 0 auto; }
    .clients-card-container .clients-card-header h3 {
      font-size: 1rem;
      font-weight: 300; }
    .clients-card-container .clients-card-header strong {
      font-size: 1rem;
      font-weight: 700;
      color: #515151; }
    .clients-card-container .clients-card-header .add-client {
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 26px;
      height: 26px;
      background-color: var(--primary-dark);
      border-radius: 5px;
      outline: none; }
  .clients-card-container .filters-wrapper {
    display: flex;
    width: calc(100% - 3.75rem);
    margin: 0 auto;
    grid-gap: 1rem;
    gap: 1rem; }
    .clients-card-container .filters-wrapper .search-wrapper {
      position: relative;
      width: 100%;
      display: flex;
      flex-direction: column;
      margin: 0 auto; }
      .clients-card-container .filters-wrapper .search-wrapper .search-input {
        width: 100%;
        height: 32px;
        font-size: 12px;
        font-weight: 500;
        color: var(--primary-dark);
        padding-left: 2.5rem;
        border-radius: 5px;
        border: none; }
        .clients-card-container .filters-wrapper .search-wrapper .search-input::-webkit-input-placeholder {
          font-size: 12px;
          font-weight: 500;
          color: var(--primary-dark);
          opacity: 0.5; }
        .clients-card-container .filters-wrapper .search-wrapper .search-input::placeholder {
          font-size: 12px;
          font-weight: 500;
          color: var(--primary-dark);
          opacity: 0.5; }
      .clients-card-container .filters-wrapper .search-wrapper .search-icon {
        position: absolute;
        top: 0.85rem;
        left: 1rem; }
    .clients-card-container .filters-wrapper .filter-wrapper,
    .clients-card-container .filters-wrapper .sort-wrapper {
      background-color: var(--pure-white);
      border-radius: 5px;
      display: flex;
      align-items: center;
      width: 30%;
      padding: 0.75rem;
      grid-gap: 0.5rem;
      gap: 0.5rem;
      position: relative;
      cursor: pointer; }
      .clients-card-container .filters-wrapper .filter-wrapper span,
      .clients-card-container .filters-wrapper .sort-wrapper span {
        font-size: 12px;
        font-weight: 500;
        color: var(--primary-dark); }
    .clients-card-container .filters-wrapper .sort-toast {
      position: absolute;
      top: 0;
      right: 0;
      height: auto;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      background-color: var(--pure-white);
      border-radius: 10px;
      width: 345px;
      box-shadow: 0px 20px 20px 1px rgba(0, 0, 0, 0.05);
      z-index: 998;
      padding: 0; }
      .clients-card-container .filters-wrapper .sort-toast .title {
        font-size: 12px;
        font-weight: 600;
        color: #555555;
        margin: 1rem auto;
        width: calc(100% - 3rem);
        text-align: center;
        padding: 0 1.5rem;
        padding-bottom: 1rem;
        border-bottom: 1px solid rgba(229, 229, 229, 0.5); }
      .clients-card-container .filters-wrapper .sort-toast .sort-toast-item {
        display: flex;
        align-items: center;
        grid-gap: 1rem;
        gap: 1rem;
        width: 100%;
        height: 4rem;
        padding: 1rem 1.5rem; }
        .clients-card-container .filters-wrapper .sort-toast .sort-toast-item span {
          font-size: 0.875rem;
          font-weight: 400; }
    .clients-card-container .filters-wrapper .filter-toast {
      display: flex;
      position: absolute;
      top: 0;
      right: 0;
      flex-direction: column;
      align-items: center;
      background-color: var(--pure-white);
      border-radius: 10px;
      width: 345px;
      height: auto;
      max-height: 60vh;
      overflow-y: auto;
      box-shadow: 0px 20px 20px 1px rgba(0, 0, 0, 0.05);
      z-index: 998; }
      .clients-card-container .filters-wrapper .filter-toast .title {
        font-size: 12px;
        font-weight: 600;
        color: #555555;
        padding: 1rem 0;
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        margin: 0 auto;
        background-color: var(--pure-white);
        width: calc(100% - 3rem);
        text-align: center;
        border-bottom: 1px solid rgba(229, 229, 229, 0.5); }
      .clients-card-container .filters-wrapper .filter-toast .filter-toast-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        grid-gap: 1rem;
        gap: 1rem;
        width: 100%;
        min-height: 4rem;
        padding: 1rem 1.5rem;
        overflow: hidden;
        overscroll-behavior: contain; }
        .clients-card-container .filters-wrapper .filter-toast .filter-toast-item p {
          font-size: 0.875rem;
          font-weight: 400;
          color: var(--primary-dark); }
        .clients-card-container .filters-wrapper .filter-toast .filter-toast-item strong {
          font-size: 0.875rem;
          font-weight: 600;
          color: var(--primary-dark); }
      .clients-card-container .filters-wrapper .filter-toast .list-toast {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        width: 100%; }
        .clients-card-container .filters-wrapper .filter-toast .list-toast .list-toast-item {
          padding: 0.5rem 1.5rem;
          display: flex;
          align-items: center;
          grid-gap: 0.5rem;
          gap: 0.5rem;
          height: -webkit-max-content;
          height: max-content;
          background-color: transparent;
          width: 100%; }
          .clients-card-container .filters-wrapper .filter-toast .list-toast .list-toast-item p {
            font-size: 0.875rem;
            font-weight: 400;
            color: var(--primary-dark);
            width: 80%; }
        .clients-card-container .filters-wrapper .filter-toast .list-toast .divider {
          margin: 0.5rem auto;
          width: calc(100% - 3rem); }
      .clients-card-container .filters-wrapper .filter-toast .filter-buttons {
        display: flex;
        grid-gap: 1rem;
        gap: 1rem;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 1rem 1.5rem;
        background-color: var(--pure-white);
        position: -webkit-sticky;
        position: sticky;
        bottom: 0; }
        .clients-card-container .filters-wrapper .filter-toast .filter-buttons button {
          width: 100%;
          padding: 0.65rem;
          font-size: 0.875rem;
          font-weight: 500;
          border-radius: 10px;
          outline: none; }
        .clients-card-container .filters-wrapper .filter-toast .filter-buttons .apply-button {
          background-color: var(--primary-dark);
          color: var(--primary-white);
          border: none; }
        .clients-card-container .filters-wrapper .filter-toast .filter-buttons .clear-button {
          background-color: transparent;
          color: var(--primary-dark);
          border: 1px solid var(--primary-dark); }
  .clients-card-container .user-card-column {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 1rem;
    gap: 1rem;
    max-height: 60vh;
    overflow-y: auto;
    margin-top: 1rem; }
    .clients-card-container .user-card-column .user-card {
      width: calc(100% - 3.75rem);
      margin: 0 auto;
      margin-top: 1rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding-bottom: 1rem;
      background-color: var(--pure-white);
      border-radius: 10px;
      box-shadow: 0px -4px 20px 1px rgba(0, 0, 0, 0.05); }
      .clients-card-container .user-card-column .user-card .clients-card-subscription-and-last-login {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 0.55rem 1rem; }
        .clients-card-container .user-card-column .user-card .clients-card-subscription-and-last-login .clients-card-subscription .subscription-tag {
          font-size: 10px;
          font-weight: 500;
          padding: 7px 8px;
          border: 1px solid #88a088;
          color: #88a088;
          width: -webkit-max-content;
          width: max-content;
          border-radius: 3rem;
          display: flex;
          align-items: center;
          justify-content: center;
          grid-gap: 4px;
          gap: 4px; }
          .clients-card-container .user-card-column .user-card .clients-card-subscription-and-last-login .clients-card-subscription .subscription-tag .circle {
            height: 5px;
            width: 5px;
            border-radius: 50%;
            background-color: #88a088; }
          @media (max-width: 767.98px) {
            .clients-card-container .user-card-column .user-card .clients-card-subscription-and-last-login .clients-card-subscription .subscription-tag {
              font-size: 8px;
              padding: 4px 7px; } }
        .clients-card-container .user-card-column .user-card .clients-card-subscription-and-last-login .clients-card-last-login {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          grid-gap: 0.5rem;
          gap: 0.5rem; }
          .clients-card-container .user-card-column .user-card .clients-card-subscription-and-last-login .clients-card-last-login .title {
            font-size: 10px;
            color: #515151;
            font-weight: 600;
            margin-left: 4px; }
            @media (max-width: 767.98px) {
              .clients-card-container .user-card-column .user-card .clients-card-subscription-and-last-login .clients-card-last-login .title {
                font-size: 8px; } }
          .clients-card-container .user-card-column .user-card .clients-card-subscription-and-last-login .clients-card-last-login .last-seen {
            font-size: 10px;
            color: var(--pure-white);
            font-weight: 600;
            padding: 5px 9px;
            background: #c9a750;
            border-radius: 3rem; }
            @media (max-width: 767.98px) {
              .clients-card-container .user-card-column .user-card .clients-card-subscription-and-last-login .clients-card-last-login .last-seen {
                font-size: 8px; } }
          .clients-card-container .user-card-column .user-card .clients-card-subscription-and-last-login .clients-card-last-login .last-ai {
            font-size: 10px;
            color: #969492;
            font-weight: 600;
            padding: 5px 9px;
            background: #e5e5e5;
            border-radius: 3rem; }
            @media (max-width: 767.98px) {
              .clients-card-container .user-card-column .user-card .clients-card-subscription-and-last-login .clients-card-last-login .last-ai {
                font-size: 8px; } }
      .clients-card-container .user-card-column .user-card .user-card-header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 1rem; }
        .clients-card-container .user-card-column .user-card .user-card-header .avatar-and-name {
          display: flex;
          align-items: center;
          grid-gap: 0.5rem;
          gap: 0.5rem;
          cursor: pointer; }
          .clients-card-container .user-card-column .user-card .user-card-header .avatar-and-name .email {
            font-size: 0.625rem;
            margin-top: -0.45;
            padding-bottom: 0.45rem; }
      .clients-card-container .user-card-column .user-card .user-card-roadmaps-container {
        display: flex;
        flex-direction: column;
        grid-gap: 2rem;
        gap: 2rem;
        width: 100%;
        margin-bottom: 1rem;
        padding: 0 1rem; }
        .clients-card-container .user-card-column .user-card .user-card-roadmaps-container .user-card-roadmaps {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          cursor: pointer; }
          .clients-card-container .user-card-column .user-card .user-card-roadmaps-container .user-card-roadmaps .roadmap-title,
          .clients-card-container .user-card-column .user-card .user-card-roadmaps-container .user-card-roadmaps .roadmap-description {
            font-size: 12px;
            font-weight: 600;
            color: #555555; }
          .clients-card-container .user-card-column .user-card .user-card-roadmaps-container .user-card-roadmaps .roadmap-description {
            font-weight: 300; }

.user-card-skeleton {
  height: 3rem;
  width: calc(100% - 3.75rem);
  margin: 0 auto;
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  border-radius: 10px; }

.skeleton {
  -webkit-animation: skeleton-loading 1s linear infinite alternate;
          animation: skeleton-loading 1s linear infinite alternate; }

.container-skeleton, .billing-card-skeleton__title, .billing-card-skeleton__description-1, .billing-card-skeleton__description-2, .billing-card-skeleton__divider, .billing-card-skeleton__footer-1, .billing-card-skeleton__footer-2, .billing-card-skeleton__footer-3, .billing-card-skeleton__footer-4, .activity-card-skeleton__profile, .activity-card-skeleton__name, .activity-card-skeleton__description, .activity-card-skeleton__score, .skeleton-user-card__avatar, .skeleton-user-card__user-info, .skeleton-user-card__alerts-circle, .skeleton-user-card__line, .skeleton-user-card__info__top, .skeleton-user-card__info__bottom {
  -webkit-animation: container-skeleton-loading 1s linear infinite alternate;
          animation: container-skeleton-loading 1s linear infinite alternate; }

@-webkit-keyframes container-skeleton-loading {
  0% {
    background-color: var(--primary-white); }
  100% {
    background-color: var(--pure-white); } }

@keyframes container-skeleton-loading {
  0% {
    background-color: var(--primary-white); }
  100% {
    background-color: var(--pure-white); } }

@-webkit-keyframes skeleton-loading {
  0% {
    background-color: var(--pure-white); }
  100% {
    background-color: #fafafa; } }

@keyframes skeleton-loading {
  0% {
    background-color: var(--pure-white); }
  100% {
    background-color: #fafafa; } }

.progress-bar-container {
  width: 100%;
  display: flex;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
  margin-bottom: -1rem; }
  .progress-bar-container .progress-bar {
    width: 100%;
    position: relative;
    border-radius: 5px;
    background-color: transparent; }
    .progress-bar-container .progress-bar .progress-wrapper {
      overflow: hidden;
      width: 100%;
      height: 5px;
      font-size: 12px;
      display: flex;
      background-color: #e5e5e5;
      border-radius: 5px; }
      @media (min-width: 768px) {
        .progress-bar-container .progress-bar .progress-wrapper {
          height: 8px; } }
      .progress-bar-container .progress-bar .progress-wrapper .progress {
        position: absolute;
        height: 100%;
        display: flex;
        flex-direction: column;
        text-align: center;
        white-space: nowrap;
        color: #88a088;
        background-color: #88a088;
        justify-content: center; }
  .progress-bar-container .text {
    font-size: 10px;
    font-weight: 600;
    color: #555555; }
    @media (min-width: 768px) {
      .progress-bar-container .text {
        font-size: 12px; } }

h1 {
  text-align: left; }

h1 {
  text-align: left; }

.roadmap-default-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding-top: 0; }
  .roadmap-default-page .roadmap-container {
    padding: 0; }
  .roadmap-default-page .reviewing-card {
    border-radius: 10px;
    padding: 10px 40px;
    background: #e0ddda;
    color: #5a5a5a;
    font-family: 'Poppins'; }
    @media (max-width: 1200px) {
      .roadmap-default-page .reviewing-card {
        margin-left: 15px;
        margin-right: 5px; } }
  .roadmap-default-page .magic-grid {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 auto; }
    @media (min-width: 992px) {
      .roadmap-default-page .magic-grid {
        width: 90%; } }
    @media (min-width: 1200px) {
      .roadmap-default-page .magic-grid {
        width: 80%; } }
  @media (min-width: 992px) {
    .roadmap-default-page {
      padding-top: 2.5rem; } }
  .roadmap-default-page .infinite-scroll-container > div:first-child {
    width: 100%; }
  .roadmap-default-page .common-header h1 {
    font-size: 1rem; }
  .roadmap-default-page .common-header .third-column {
    width: 48px; }
    .roadmap-default-page .common-header .third-column svg {
      position: relative;
      right: 5px; }
  .roadmap-default-page .default-stage-thumbnail {
    background-color: #e1ddda;
    border-radius: 8px;
    height: 3rem;
    width: 0.3rem;
    margin: auto;
    margin-right: 0.5rem; }
    .roadmap-default-page .default-stage-thumbnail svg {
      color: white;
      height: 1rem;
      width: 1rem;
      display: block; }
  .roadmap-default-page .table-of-contents-container {
    width: 100%;
    border-radius: 0.5rem;
    position: relative; }
    .roadmap-default-page .table-of-contents-container .stage-title {
      display: flex;
      align-items: center;
      grid-gap: 0.5rem;
      gap: 0.5rem; }
    .roadmap-default-page .table-of-contents-container .stage-title.active {
      margin-left: -1rem; }
    .roadmap-default-page .table-of-contents-container .image-container {
      position: relative; }
      @media (min-width: 992px) {
        .roadmap-default-page .table-of-contents-container .image-container {
          border-radius: 1rem; } }
      .roadmap-default-page .table-of-contents-container .image-container .title-content {
        display: grid;
        justify-items: start;
        position: absolute;
        bottom: 0;
        padding: 0 2rem;
        padding-bottom: 4rem;
        width: 100%; }
        @media (max-width: 767.98px) {
          .roadmap-default-page .table-of-contents-container .image-container .title-content {
            padding: 0 1rem; } }
        .roadmap-default-page .table-of-contents-container .image-container .title-content h1 {
          color: var(--pure-white);
          font-family: var(--serif-font-family);
          padding-bottom: 0.5rem;
          font-size: 1.5rem; }
        .roadmap-default-page .table-of-contents-container .image-container .title-content p {
          color: var(--pure-white); }
        .roadmap-default-page .table-of-contents-container .image-container .title-content .progress {
          height: 0.5rem;
          width: 50%; }
          .roadmap-default-page .table-of-contents-container .image-container .title-content .progress .progress-bar {
            background-color: var(--primary-orange);
            border-radius: 4px; }
        .roadmap-default-page .table-of-contents-container .image-container .title-content .continue-btn {
          background-color: var(--pure-white);
          padding: 0.1rem;
          border-radius: 15rem;
          display: flex;
          align-items: center;
          grid-gap: 0.5rem;
          gap: 0.5rem;
          padding-right: 1rem;
          margin-top: 1.2rem;
          size: 1rem; }
    .roadmap-default-page .table-of-contents-container .table-of-contents {
      overflow: auto;
      padding: 2.5rem;
      background-color: var(--primary-white);
      margin-top: -2rem;
      position: -webkit-sticky;
      position: sticky;
      width: 100%;
      z-index: 10;
      border-radius: 1rem;
      display: none; }
      @media (min-width: 992px) {
        .roadmap-default-page .table-of-contents-container .table-of-contents {
          display: block; } }
      .roadmap-default-page .table-of-contents-container .table-of-contents .card {
        display: flex;
        background-color: var(--pure-white);
        box-shadow: 0px 4px 20px 1px rgba(0, 0, 0, 0.1);
        margin: 0.6rem 0;
        padding: 1.2rem 2rem; }
        .roadmap-default-page .table-of-contents-container .table-of-contents .card .content {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%; }
        .roadmap-default-page .table-of-contents-container .table-of-contents .card h2 {
          margin: 0;
          font-weight: 500;
          font-size: 0.875rem; }
        .roadmap-default-page .table-of-contents-container .table-of-contents .card:hover {
          cursor: pointer; }
  .roadmap-default-page .my-story {
    width: 100%;
    margin-top: 0;
    position: relative;
    padding: 1.5rem;
    background-color: var(--primary-white);
    border-radius: 1rem; }
    @media (min-width: 992px) {
      .roadmap-default-page .my-story {
        width: 100%;
        margin-top: 0; } }
  .roadmap-default-page .card {
    box-shadow: none;
    background-color: transparent; }
  .roadmap-default-page .stage .card {
    min-height: 4.375rem;
    border: 1px solid transparent;
    box-shadow: 0px 4px 20px 1px rgba(0, 0, 0, 0.1);
    background-color: var(--primary-white);
    background-color: var(--pure-white); }
    .roadmap-default-page .stage .card:hover {
      border: 1px solid #eaeaea; }
    .roadmap-default-page .stage .card .row {
      min-height: 55px; }
  .roadmap-default-page .stage h2 {
    padding-bottom: 0.5rem; }
  .roadmap-default-page .roadmap-title {
    font-size: 1.25rem; }
  .roadmap-default-page .hidden-student {
    color: red;
    font-size: 0.7rem;
    font-weight: bold;
    border-radius: 5px;
    background-color: #ffc7c7; }
  .roadmap-default-page .card.recent {
    background-color: #d0e0ff;
    border: 1px solid transparent; }
    .roadmap-default-page .card.recent:hover {
      border: 1px solid #c1d6ff; }
  .roadmap-default-page .card-title {
    font-size: 0.875rem; }
  .roadmap-default-page .card-text {
    color: #aaaaaa;
    font-size: 0.7rem; }
    .roadmap-default-page .card-text .badge {
      color: #727272;
      font-size: 0.7rem; }
  .roadmap-default-page .comments svg {
    position: relative;
    top: 1px; }
  .roadmap-default-page .stage {
    position: relative; }
    @media (min-width: 992px) {
      .roadmap-default-page .stage {
        margin-bottom: 1.5rem; } }
    .roadmap-default-page .stage h2 {
      font-size: 1rem;
      font-weight: 500; }
    .roadmap-default-page .stage .stage-scroll-to {
      height: 0px;
      position: absolute;
      top: -85px;
      left: 0;
      right: 0;
      z-index: -1; }
      .roadmap-default-page .stage .stage-scroll-to.with-student-header {
        top: -100px; }
      @media (min-width: 992px) {
        .roadmap-default-page .stage .stage-scroll-to {
          top: -205px; }
          .roadmap-default-page .stage .stage-scroll-to.with-student-header {
            top: -215px; } }
    .roadmap-default-page .stage .coach-notes {
      color: #f1561e; }
  .roadmap-default-page .default-stage-thumbnail.red {
    background-color: var(--primary-red); }
  .roadmap-default-page .default-stage-thumbnail.yellow {
    background-color: #b68c31; }
  .roadmap-default-page .default-stage-thumbnail.green {
    background-color: #9da16b; }
  .roadmap-default-page .dot {
    position: absolute;
    top: 12px;
    left: -12px; }
  .roadmap-default-page .desktop-page-secondary-header-wrapper .btn-save {
    background-color: var(--primary-dark); }
  .roadmap-default-page .desktop-page-secondary-header-wrapper .back-button-container {
    float: left;
    height: 0.25rem; }
    .roadmap-default-page .desktop-page-secondary-header-wrapper .back-button-container .common-desktop-back-button {
      box-shadow: none; }
  .roadmap-default-page .desktop-page-secondary-header-wrapper .progress-text {
    color: #727272;
    font-weight: 600;
    font-size: 0.875rem; }

h1 {
  text-align: left; }

.roadmap-competency-page {
  background-color: var(--primary-bg-white);
  position: relative;
  padding: 1rem;
  width: 100%;
  overflow-x: hidden; }
  @media (max-width: 767.98px) {
    .roadmap-competency-page {
      padding: 0rem;
      padding-top: 1rem; } }
  .roadmap-competency-page button {
    outline: none; }
  .roadmap-competency-page .magic-grid {
    display: flex;
    flex-direction: column;
    width: 91%;
    margin: 0 auto; }
  .roadmap-competency-page .noRightColumnStyles {
    width: 100% !important;
    margin: auto; }
    @media (min-width: 768px) {
      .roadmap-competency-page .noRightColumnStyles {
        width: 100% !important; } }
  .roadmap-competency-page .assessments-and-discuss-wrapper {
    display: grid;
    grid-template-rows: 1fr;
    height: 100%;
    background-color: var(--primary-white);
    border-radius: 0.6rem; }
    @media (max-width: 767.98px) {
      .roadmap-competency-page .assessments-and-discuss-wrapper {
        background-color: transparent; } }
  .roadmap-competency-page .assessment-and-action-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 1rem;
    padding: 1rem; }
    @media (max-width: 767.98px) {
      .roadmap-competency-page .assessment-and-action-container {
        padding: 0rem;
        padding-bottom: 4rem; } }
    .roadmap-competency-page .assessment-and-action-container .title-header {
      display: flex;
      align-items: center;
      padding-bottom: 1.7rem;
      grid-gap: 1.5rem;
      gap: 1.5rem; }
      .roadmap-competency-page .assessment-and-action-container .title-header h1 {
        color: var(--primary-dark);
        font-family: var(--serif-font-family);
        text-align: left; }
  .roadmap-competency-page .journal-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 1rem; }
    .roadmap-competency-page .journal-container a {
      color: #6e95a9; }
  .roadmap-competency-page .embed {
    display: flex;
    flex-direction: column; }
    .roadmap-competency-page .embed figure {
      flex-grow: 1; }
      .roadmap-competency-page .embed figure > div {
        flex-grow: 1; }
  .roadmap-competency-page .btn-option {
    background-color: transparent;
    box-shadow: none;
    min-height: 27px;
    padding: 0px;
    color: #3e3e3e; }
  .roadmap-competency-page .common-header h1 {
    font-size: 1rem; }
  .roadmap-competency-page .common-header .third-column {
    width: 48px; }
    .roadmap-competency-page .common-header .third-column svg {
      position: relative;
      right: 5px; }
  .roadmap-competency-page h2 {
    display: inline-block;
    font-weight: 500; }
  .roadmap-competency-page .competency-comment {
    /*  .mine {
      @include media-breakpoint-up(lg) {
        background: $color-item-lightpurple;
        box-shadow: none;
      }
    } */ }
    .roadmap-competency-page .competency-comment .dot {
      position: relative;
      top: 21px; }
    .roadmap-competency-page .competency-comment .initials span {
      color: white; }
    .roadmap-competency-page .competency-comment .user-name {
      font-size: 0.875rem;
      font-weight: bold;
      color: #3e3e3e; }
      .roadmap-competency-page .competency-comment .user-name ~ span {
        font-size: 0.8rem; }
    .roadmap-competency-page .competency-comment .card {
      font-size: 0.875rem;
      color: #3e3e3e; }
      @media (min-width: 992px) {
        .roadmap-competency-page .competency-comment .card {
          background: #f4f4f5; } }
  .roadmap-competency-page .comment-list {
    position: static; }
    .roadmap-competency-page .comment-list .comment-form {
      position: fixed;
      bottom: 3.4rem;
      z-index: 1040;
      left: 0;
      right: 0;
      border-top: 1px solid #cccccc; }
      .roadmap-competency-page .comment-list .comment-form .form-control:focus {
        border: 1px solid #eeeeee; }
      .roadmap-competency-page .comment-list .comment-form .form-group {
        margin-bottom: 0; }
      .roadmap-competency-page .comment-list .comment-form.focused-input {
        position: absolute;
        bottom: 0; }
    .roadmap-competency-page .comment-list .competency-comment:last-of-type {
      padding-bottom: 3rem; }
    .roadmap-competency-page .comment-list .delete-comment {
      display: flex;
      align-items: center;
      justify-content: center; }
      .roadmap-competency-page .comment-list .delete-comment .delete-comment-message-icon {
        margin-top: 1.5rem; }
  .roadmap-competency-page .attachment-button {
    height: auto; }
  .roadmap-competency-page .prev-next-btn-container {
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
    width: 100%;
    padding-top: 1rem;
    justify-content: space-between; }
    @media (min-width: 992px) {
      .roadmap-competency-page .prev-next-btn-container {
        flex-direction: row; }
        .roadmap-competency-page .prev-next-btn-container button {
          max-width: -webkit-max-content;
          max-width: max-content; } }
    .roadmap-competency-page .prev-next-btn-container button {
      background-color: var(--primary-dark);
      color: white;
      font-weight: 500;
      width: 100%; }
      .roadmap-competency-page .prev-next-btn-container button svg {
        position: relative;
        top: 2px; }
  @media print {
    .roadmap-competency-page .tab-selector {
      display: none; }
    .roadmap-competency-page .action-item-page-attachment-list ~ button.btn {
      display: none; }
    .roadmap-competency-page .common-header-container .static-placeholder {
      visibility: visible; }
    .roadmap-competency-page .common-header-container .fixed-actual-header {
      display: none; }
    .roadmap-competency-page .common-header .col-auto:first-child,
    .roadmap-competency-page .common-header .col-auto:last-child {
      display: none; } }
  .roadmap-competency-page .desktop-header-container {
    border-radius: 0; }
    .roadmap-competency-page .desktop-header-container .back-button-container {
      float: left;
      height: 0.25rem; }
    .roadmap-competency-page .desktop-header-container h2 {
      display: block; }
      .roadmap-competency-page .desktop-header-container h2 small {
        color: #727272; }
    .roadmap-competency-page .desktop-header-container .tab-selector {
      background: transparent;
      min-height: unset;
      margin-bottom: 0; }
      .roadmap-competency-page .desktop-header-container .tab-selector::after {
        border-bottom: none; }
      .roadmap-competency-page .desktop-header-container .tab-selector > .col {
        flex-grow: 1;
        max-width: 50%;
        -webkit-transform: translate(50%, 0);
                transform: translate(50%, 0); }
      .roadmap-competency-page .desktop-header-container .tab-selector .nav-link {
        font-size: 1rem; }
        .roadmap-competency-page .desktop-header-container .tab-selector .nav-link.active {
          color: #2f80ed; }
      .roadmap-competency-page .desktop-header-container .tab-selector .slider {
        background-color: #2f80ed; }

.desktop-page-container .add-another-action-item {
  display: block;
  color: #727272; }

.desktop-page-container .add-attachment,
.desktop-page-container .add-action-item {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--primary-dark);
  font-weight: 500;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  font-size: 0.875rem;
  border: 1px solid var(--primary-dark);
  border-radius: 0.8rem;
  padding: 1rem;
  max-width: 80%;
  margin: 0 auto; }

.add-note {
  display: block;
  color: #727272; }

.roadmap-competency-page-competency-more-tab img {
  max-width: calc(100vw - 2rem);
  height: auto !important; }

.roadmap-competency-page-competency-more-tab .print-header {
  text-align: center;
  font-weight: bold;
  display: none; }

@media print {
  .roadmap-competency-page-competency-more-tab .print-header {
    display: block; } }

@media (min-width: 992px) {
  .roadmap-competency-page {
    padding: 2.5rem 3.5rem; }
    .roadmap-competency-page .action-item-container,
    .roadmap-competency-page .questions-section,
    .roadmap-competency-page .btn-action-item,
    .roadmap-competency-page .action-item-page-attachment-list ~ button,
    .roadmap-competency-page .btn-white,
    .roadmap-competency-page .assessment {
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.09);
      border-radius: 10px; }
    .roadmap-competency-page .assessment-and-action-container,
    .roadmap-competency-page .journal-container {
      background-color: var(--primary-white);
      padding: 2.5rem; }
  .container.primary-content {
    background: #fff;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.05); }
  .description-container {
    font-size: 1rem; }
    .description-container img {
      max-width: 100%; } }

.roadmap-competency-page .prev-next-btn-container button svg {
  font-size: 1rem; }

.label-p {
  font-weight: 400; }

.bold-label {
  font-weight: 600; }

.assessments-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  grid-gap: 1rem;
  gap: 1rem;
  margin-top: 2rem;
  min-height: 57vh;
  max-height: 57vh; }
  .assessments-container p {
    text-align: center; }
  .assessments-container .coming-soon {
    font-size: 0.8rem;
    font-weight: 600;
    color: #727272; }
  .assessments-container .title {
    margin-top: -1rem;
    font-family: var(--serif-font-family);
    font-weight: 700; }
  .assessments-container .description {
    font-weight: 300; }
    @media (min-width: 768px) {
      .assessments-container .description {
        max-width: 50%; } }

.action-item-content {
  width: 100%;
  word-break: break-word; }
  .action-item-content .action-item-textarea {
    width: 100%;
    padding: 1rem;
    font-size: 0.875rem; }
    @media (max-width: 767.98px) {
      .action-item-content .action-item-textarea {
        font-size: 1rem; } }

.action-item-response {
  display: flex;
  width: 90%;
  margin: 0 auto;
  flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem; }
  .action-item-response .action-item-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between; }
    .action-item-response .action-item-header .image-and-name {
      display: flex;
      align-items: center; }
      .action-item-response .action-item-header .image-and-name img {
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 0.6rem 0.6rem 0 0.6rem;
        margin-right: 1rem;
        -webkit-filter: drop-shadow(2px 3px 5px rgba(0, 0, 0, 0.25));
                filter: drop-shadow(2px 3px 5px rgba(0, 0, 0, 0.25)); }
      .action-item-response .action-item-header .image-and-name .name-and-time {
        display: flex;
        flex-direction: column; }
        .action-item-response .action-item-header .image-and-name .name-and-time .name-text {
          font-size: 0.875rem; }
        .action-item-response .action-item-header .image-and-name .name-and-time .date-text {
          font-size: 0.7rem;
          color: var(--primary-dark);
          opacity: 0.4; }
    .action-item-response .action-item-header .edit {
      font-size: 0.7rem;
      font-weight: 600;
      cursor: pointer; }
      .action-item-response .action-item-header .edit:hover {
        color: var(--primary-dark);
        opacity: 0.4; }
  .action-item-response .action-item-content p {
    white-space: pre-line;
    font-weight: 300;
    font-size: 0.8rem; }

.form-and-action-items {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  max-height: -webkit-max-content;
  max-height: max-content;
  width: 100%; }

h1 {
  text-align: left; }

.action-item-dialog button {
  outline: none; }

.action-item-dialog .modal-body .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem; }
  .action-item-dialog .modal-body .header h2 {
    font-family: var(--serif-font-family); }
  .action-item-dialog .modal-body .header .save {
    color: var(--primary-orange); }

.action-item-dialog .form-control {
  border-color: transparent;
  border-radius: 0.4rem;
  box-shadow: 0px -4px 20px 1px rgba(0, 0, 0, 0.05);
  height: 2.81rem; }
  .action-item-dialog .form-control::-webkit-input-placeholder {
    color: var(--primary-gray);
    font-size: 0.875rem; }
  .action-item-dialog .form-control::placeholder {
    color: var(--primary-gray);
    font-size: 0.875rem; }

.action-item-dialog .modal-content {
  background: #f6f6f6; }

.action-item-dialog .cancel {
  position: absolute;
  cursor: pointer;
  font-weight: 600;
  color: #727272;
  left: 0; }
  .action-item-dialog .cancel:hover {
    opacity: 0.7; }

.action-item-dialog button {
  height: inherit; }

.action-item-dialog .react-datepicker-wrapper {
  width: 100%;
  display: flex;
  justify-items: flex-start; }

.action-item-dialog .btn-due-date {
  height: 40px;
  font-weight: normal;
  margin: 0;
  width: 100%;
  display: flex;
  justify-items: flex-start;
  align-items: center;
  grid-gap: 0.7rem;
  gap: 0.7rem; }
  .action-item-dialog .btn-due-date.is-invalid {
    box-shadow: 0 0 0px 1px #dc3545; }
  .action-item-dialog .btn-due-date svg {
    color: #007bff; }

.action-item-dialog .react-datepicker__navigation {
  height: 10px; }

.action-item-row {
  display: block;
  border-bottom: 2px solid #f6f6f6;
  cursor: pointer; }
  .action-item-row:hover {
    opacity: 1; }
  @media (min-width: 992px) {
    .action-item-row {
      border-bottom: 2px solid #f1f1f1; } }
  .action-item-row .svg-inline--fa {
    font-size: 1rem; }

.action-item-row:last-child {
  border-bottom: none; }

.action-item {
  text-decoration: inherit;
  color: #3e3e3e; }
  .action-item a {
    text-decoration: none;
    color: inherit; }
  .action-item .ai-description {
    font-size: 1rem;
    font-weight: 200;
    line-height: 22px; }
    .action-item .ai-description span {
      font-weight: 200; }
    .action-item .ai-description p {
      font-size: 0.875rem;
      font-weight: 200; }
    .action-item .ai-description .due-over {
      color: #f1561e;
      display: block;
      margin-top: -4px; }
    .action-item .ai-description .due-completed {
      color: var(--primary-gray);
      font-weight: 300;
      font-family: var(--sans-serif-font-family); }
    .action-item .ai-description .due-default {
      color: #aaaaaa;
      display: block;
      margin-top: -4px; }
    .action-item .ai-description .file-attachments {
      color: #aaaaaa;
      font-weight: bold; }
  .action-item .ai-status {
    background-color: #eeeeee;
    border-radius: 8px;
    max-width: 2rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center; }
    .action-item .ai-status .svg-inline--fa {
      vertical-align: middle;
      display: flex;
      justify-content: center;
      align-items: center; }
  .action-item .blank a {
    height: 2em;
    width: 2em; }
  .action-item .delete,
  .action-item .edit {
    cursor: pointer; }
  .action-item .icon-box svg:hover {
    opacity: 0.7; }
  .action-item .green {
    background-color: #9da16b; }
  .action-item .text-right {
    position: relative;
    right: 8px;
    color: #727272; }

.roadmap-components-competency-page-action-items .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 0; }
  .roadmap-components-competency-page-action-items .header .add-action-item {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--primary-dark);
    width: 1.6rem;
    height: 1.6rem;
    border-radius: 0.4rem; }
    .roadmap-components-competency-page-action-items .header .add-action-item::hover {
      background-color: var(--primary-dark); }
  .roadmap-components-competency-page-action-items .header h2 {
    font-size: 1.125rem;
    font-weight: 600;
    font-family: var(--sans-serif-font-family); }
  .roadmap-components-competency-page-action-items .header .text {
    display: flex;
    align-items: center; }

.roadmap-components-competency-page-action-items span {
  color: var(--primary-dark); }

.roadmap-components-competency-page-action-items .action-item-container {
  box-shadow: 0px -4px 20px 1px rgba(0, 0, 0, 0.05);
  background-color: var(--primary-white);
  border-radius: 10px; }
  .roadmap-components-competency-page-action-items .action-item-container .action-item-link {
    text-decoration: none; }

@media print {
  .roadmap-components-competency-page-action-items button.btn {
    display: none; } }

h1 {
  text-align: left; }

.roadmap-components-competency-page-competency-learn-tab .print-header {
  text-align: center;
  font-weight: bold;
  display: none; }

.roadmap-components-competency-page-competency-learn-tab .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 0; }
  .roadmap-components-competency-page-competency-learn-tab .header .add-attachment {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--primary-dark);
    padding: 0.4rem;
    border-radius: 0.4rem; }
    .roadmap-components-competency-page-competency-learn-tab .header .add-attachment::hover {
      background-color: var(--primary-dark); }
  .roadmap-components-competency-page-competency-learn-tab .header h2 {
    font-size: 1.125rem;
    font-weight: 600;
    font-family: var(--sans-serif-font-family); }
  .roadmap-components-competency-page-competency-learn-tab .header .text {
    display: flex;
    align-items: center; }

.roadmap-components-competency-page-competency-learn-tab .asssessment-title {
  font-weight: 600;
  font-family: var(--sans-serif-font-family);
  font-size: 1.125rem; }

.roadmap-components-competency-page-competency-learn-tab span {
  font-weight: 400;
  font-size: 0.875rem;
  color: #727272; }

.roadmap-components-competency-page-competency-learn-tab .coach-notes {
  font-size: 0.875rem;
  color: #f1561e; }

.roadmap-components-competency-page-competency-learn-tab .description-container {
  color: #3e3e3e;
  font-size: 1rem;
  overflow-x: hidden; }
  @media (min-width: 992px) {
    .roadmap-components-competency-page-competency-learn-tab .description-container {
      font-size: 1rem; } }
  .roadmap-components-competency-page-competency-learn-tab .description-container img {
    max-width: calc(100vw - 30px);
    height: auto !important; }

.roadmap-components-competency-page-competency-learn-tab .embed {
  display: flex;
  flex-direction: column; }
  .roadmap-components-competency-page-competency-learn-tab .embed figure {
    flex-grow: 1; }
    .roadmap-components-competency-page-competency-learn-tab .embed figure > div {
      flex-grow: 1; }

.roadmap-components-competency-page-competency-learn-tab .story-desc {
  font-size: 0.875rem;
  color: var(--primary-dark);
  font-weight: 300; }

.roadmap-components-competency-page-competency-learn-tab .notes-container a {
  text-decoration: none; }

.roadmap-components-competency-page-competency-learn-tab .notes-container .notes-description a {
  background-color: #ffffff;
  display: flex;
  flex-flow: column;
  border-radius: 10px; }
  .roadmap-components-competency-page-competency-learn-tab .notes-container .notes-description a:hover {
    opacity: 1; }
  .roadmap-components-competency-page-competency-learn-tab .notes-container .notes-description a pre {
    font-size: 1rem;
    color: #727272;
    font-family: inherit;
    white-space: pre-wrap;
    word-wrap: break-word;
    font-family: 'Open Sans'; }

.roadmap-components-competency-page-competency-learn-tab .notes-container pre {
  font-size: 1rem;
  color: #727272;
  font-family: inherit;
  white-space: pre-wrap;
  word-wrap: break-word;
  font-family: 'Open Sans'; }

@media print {
  .roadmap-components-competency-page-competency-learn-tab .print-header {
    display: block; } }

.roadmap-components-competency-page-competency-learn-tab .self-reflection .assessment-container {
  background-color: var(--primary-white);
  border-radius: 10px;
  color: #3e3e3e;
  min-height: 3em;
  width: 100%;
  margin: 0;
  box-shadow: 0px -4px 20px 1px rgba(0, 0, 0, 0.05);
  margin-bottom: 1rem; }
  .roadmap-components-competency-page-competency-learn-tab .self-reflection .assessment-container p {
    font-size: 1rem;
    color: #3e3e3e;
    line-height: 20px; }
  .roadmap-components-competency-page-competency-learn-tab .self-reflection .assessment-container .slider-container .rc-slider::before {
    position: absolute;
    content: '';
    border: 4px solid rgba(255, 255, 255, 0.55);
    height: 1rem;
    z-index: 10;
    left: 26.5%; }
  .roadmap-components-competency-page-competency-learn-tab .self-reflection .assessment-container .slider-container .rc-slider::after {
    position: absolute;
    content: '';
    border: 4px solid rgba(255, 255, 255, 0.55);
    height: 1rem;
    right: 26.5%; }
  .roadmap-components-competency-page-competency-learn-tab .self-reflection .assessment-container .slider-container .rc-slider .rc-slider-rail {
    background: linear-gradient(to right, #f1561e, #f5d844, #9da16b);
    height: 1rem;
    padding: 0;
    left: -0.5rem;
    width: calc(100% + 1rem);
    border-radius: 10px; }
  .roadmap-components-competency-page-competency-learn-tab .self-reflection .assessment-container .slider-container .rc-slider .rc-slider-step {
    display: none; }
  .roadmap-components-competency-page-competency-learn-tab .self-reflection .assessment-container .slider-container .rc-slider .rc-slider-mark {
    top: 30px; }
    .roadmap-components-competency-page-competency-learn-tab .self-reflection .assessment-container .slider-container .rc-slider .rc-slider-mark:first-child {
      display: none; }
    .roadmap-components-competency-page-competency-learn-tab .self-reflection .assessment-container .slider-container .rc-slider .rc-slider-mark .rc-slider-mark-text:first-of-type {
      visibility: hidden; }
  .roadmap-components-competency-page-competency-learn-tab .self-reflection .assessment-container .slider-container .rc-slider .rc-slider-handle {
    margin-top: -10px;
    width: 1.875rem;
    height: 1.875rem;
    border: 1px solid #f4f4f5;
    background-color: white;
    border-radius: 10px; }
  .roadmap-components-competency-page-competency-learn-tab .self-reflection .assessment-container .selected {
    font-size: 30px;
    vertical-align: inherit;
    display: initial; }
  .roadmap-components-competency-page-competency-learn-tab .self-reflection .assessment-container .row {
    flex-wrap: nowrap; }
  .roadmap-components-competency-page-competency-learn-tab .self-reflection .assessment-container .assessment {
    cursor: pointer;
    display: flex;
    align-items: center;
    border-radius: 0;
    box-shadow: none;
    padding: 1rem 0; }
    .roadmap-components-competency-page-competency-learn-tab .self-reflection .assessment-container .assessment .card-title-options {
      font-weight: 300;
      font-size: 0.875rem;
      max-width: -webkit-max-content;
      max-width: max-content;
      display: flex; }
    .roadmap-components-competency-page-competency-learn-tab .self-reflection .assessment-container .assessment.red.active {
      background: rgba(238, 234, 230, 0.6);
      border-top-left-radius: 10px;
      border-top-right-radius: 10px; }
    .roadmap-components-competency-page-competency-learn-tab .self-reflection .assessment-container .assessment.yellow.active {
      background: rgba(238, 234, 230, 0.6); }
    .roadmap-components-competency-page-competency-learn-tab .self-reflection .assessment-container .assessment.green.active {
      background: rgba(238, 234, 230, 0.6);
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px; }
    .roadmap-components-competency-page-competency-learn-tab .self-reflection .assessment-container .assessment .circle {
      border-radius: 50%;
      width: 20px;
      height: 20px;
      margin: auto;
      display: block;
      position: relative;
      left: 6px; }
      .roadmap-components-competency-page-competency-learn-tab .self-reflection .assessment-container .assessment .circle.selected:before {
        position: absolute;
        content: ' ';
        width: 60%;
        height: 60%;
        border-radius: 50%;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%); }
    .roadmap-components-competency-page-competency-learn-tab .self-reflection .assessment-container .assessment .card-title {
      font-size: 1rem;
      line-height: 22px; }
  .roadmap-components-competency-page-competency-learn-tab .self-reflection .assessment-container .red .selected:before {
    background-color: var(--primary-alerts); }
  .roadmap-components-competency-page-competency-learn-tab .self-reflection .assessment-container .red .circle {
    border: 1px solid var(--primary-alerts); }
  .roadmap-components-competency-page-competency-learn-tab .self-reflection .assessment-container .yellow .selected:before {
    background-color: #ca9d29; }
  .roadmap-components-competency-page-competency-learn-tab .self-reflection .assessment-container .yellow .circle {
    border: 1px solid #ca9d29; }
  .roadmap-components-competency-page-competency-learn-tab .self-reflection .assessment-container .green {
    border-bottom: none; }
    .roadmap-components-competency-page-competency-learn-tab .self-reflection .assessment-container .green .selected:before {
      background-color: #9da16b; }
    .roadmap-components-competency-page-competency-learn-tab .self-reflection .assessment-container .green .circle {
      border: 1px solid #9da16b; }

.roadmap-components-competency-page-competency-learn-tab .self-reflection .assessment-slider-container {
  min-height: 8em; }
  .roadmap-components-competency-page-competency-learn-tab .self-reflection .assessment-slider-container p {
    margin-left: 4px; }

.roadmap-components-competency-page-competency-learn-tab .self-reflection .btn-bottom-left-rounded {
  border-radius: 0;
  border-bottom-left-radius: 10px; }
  .roadmap-components-competency-page-competency-learn-tab .self-reflection .btn-bottom-left-rounded.active {
    background-color: #007bff;
    color: white; }

.roadmap-components-competency-page-competency-learn-tab .self-reflection .btn-bottom-right-rounded {
  border-radius: 0;
  border-bottom-right-radius: 10px; }
  .roadmap-components-competency-page-competency-learn-tab .self-reflection .btn-bottom-right-rounded.active {
    background-color: #007bff;
    color: white; }

.roadmap-components-competency-page-competency-learn-tab .self-reflection .btn-bottom-left-rounded svg,
.roadmap-components-competency-page-competency-learn-tab .self-reflection .btn-bottom-right-rounded svg {
  vertical-align: middle; }

.roadmap-components-competency-page-competency-learn-tab .self-reflection .border-br-none {
  border-bottom-right-radius: unset !important; }

.roadmap-components-competency-page-competency-learn-tab .self-reflection .assessment-approve {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-top: 1px;
  grid-gap: 1rem;
  gap: 1rem; }
  .roadmap-components-competency-page-competency-learn-tab .self-reflection .assessment-approve button {
    display: flex;
    align-items: center;
    padding: 0.8rem 1rem;
    border-radius: 0.8rem;
    max-width: -webkit-max-content;
    max-width: max-content;
    border: 1.5px solid var(--primary-dark); }
  .roadmap-components-competency-page-competency-learn-tab .self-reflection .assessment-approve .approved,
  .roadmap-components-competency-page-competency-learn-tab .self-reflection .assessment-approve .rejected {
    background-color: var(--primary-dark);
    color: white; }

.roadmap-components-competency-page-competency-learn-tab .question-title {
  font-weight: 600; }

.roadmap-components-competency-page-competency-learn-tab .questions-section {
  background-color: white;
  border-radius: 10px; }
  .roadmap-components-competency-page-competency-learn-tab .questions-section .question-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 22px;
    min-height: 58px;
    background-color: var(--primary-white);
    padding: 1.5rem; }
    .roadmap-components-competency-page-competency-learn-tab .questions-section .question-item .question {
      font-size: 0.875rem;
      font-weight: 300;
      color: var(--primary-dark); }
    .roadmap-components-competency-page-competency-learn-tab .questions-section .question-item .answer {
      font-size: 0.875rem;
      color: #727272;
      font-weight: 300; }
    .roadmap-components-competency-page-competency-learn-tab .questions-section .question-item pre {
      max-width: 100%;
      font-size: 1rem;
      font-family: inherit;
      white-space: pre-wrap;
      word-wrap: break-word;
      margin-bottom: 0;
      color: #3e3e3e; }
    .roadmap-components-competency-page-competency-learn-tab .questions-section .question-item:not(:last-child) {
      border-bottom: 2px solid #f6f6f6; }
      @media (min-width: 992px) {
        .roadmap-components-competency-page-competency-learn-tab .questions-section .question-item:not(:last-child) {
          border-bottom: 2px solid #f1f1f1; } }
    .roadmap-components-competency-page-competency-learn-tab .questions-section .question-item svg {
      margin-left: 0.625rem;
      width: 0.5rem; }
    .roadmap-components-competency-page-competency-learn-tab .questions-section .question-item:hover {
      cursor: pointer; }

@media (min-width: 992px) {
  .roadmap-components-competency-page-competency-learn-tab .notes-container .notes-description a {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.09);
    border-radius: 10px; } }

h1 {
  text-align: left; }

.roadmap-components-action-item-page-attachment button {
  height: inherit;
  max-width: 13em; }

.roadmap-components-action-item-page-attachment .cancel {
  position: absolute;
  left: 0;
  top: 0; }
  .roadmap-components-action-item-page-attachment .cancel:hover {
    opacity: 0.5; }

.roadmap-components-action-item-page-attachment .upload-progress {
  width: 8em; }

.roadmap-components-action-item-page-attachment .failed {
  color: #af0000; }

.roadmap-components-action-item-page-attachment .completed {
  color: darkgreen; }

.action-item-page-attachment-list .attachment {
  background-color: var(--primary-white);
  border-radius: 10px;
  align-items: center;
  display: flex;
  height: 55px; }
  .action-item-page-attachment-list .attachment .attachment-type-icon {
    color: #727272; }
  .action-item-page-attachment-list .attachment .svg-inline--fa {
    font-size: 1.5rem; }
  .action-item-page-attachment-list .attachment .file-name {
    color: #3e3e3e;
    flex-grow: 1;
    text-overflow: ellipsis;
    flex-basis: 1em;
    white-space: nowrap;
    font-size: 0.875rem;
    overflow: hidden;
    text-decoration: underline;
    font-weight: 500;
    padding-right: 1rem; }
    .action-item-page-attachment-list .attachment .file-name:hover {
      opacity: 0.7; }
  .action-item-page-attachment-list .attachment .file-type {
    color: #727272;
    font-size: 0.875rem;
    display: inline-block; }
  .action-item-page-attachment-list .attachment .delete {
    cursor: pointer; }
    .action-item-page-attachment-list .attachment .delete:hover {
      opacity: 0.5; }
    .action-item-page-attachment-list .attachment .delete svg {
      color: #727272; }

h1 {
  text-align: left; }

.roadmap-answer-page .third-column {
  display: flex;
  justify-content: right; }

.roadmap-answer-page .first-column {
  display: flex;
  justify-content: left; }

.roadmap-answer-page .btn-save-question {
  color: white;
  background: var(--primary-dark);
  box-shadow: none;
  padding: 0.8rem;
  border-radius: 0.8rem;
  position: relative;
  right: 0; }

.roadmap-answer-page .btn-cancel-question {
  color: var(--primary-dark);
  background: var(--primary-gray-lighter);
  box-shadow: none;
  padding: 0.8rem;
  border-radius: 0.8rem;
  position: relative;
  left: 0; }

.roadmap-answer-page .btn-cancel {
  background-color: transparent;
  box-shadow: none;
  font-weight: 500; }

.roadmap-answer-page h1 {
  font-size: 1.5rem;
  font-weight: 500; }

.roadmap-answer-page .form-group h2 {
  margin: 2.5rem 0;
  font-family: var(--serif-font-family);
  font-size: 1.5rem; }

.roadmap-answer-page .form-group .form-control {
  padding: 2.5rem; }

h1 {
  text-align: left; }

.roadmap-components-student-info {
  display: flex;
  justify-content: center;
  align-items: center; }
  .roadmap-components-student-info strong {
    color: #3e3e3e; }

.answer-question-modal .modal-content {
  padding: 2rem 2rem; }
  @media (max-width: 767.98px) {
    .answer-question-modal .modal-content {
      padding: 2rem 1rem;
      position: fixed;
      bottom: 0; } }

@media (max-width: 767.98px) {
  .answer-question-modal .answer-modal-header .btn-save-question {
    color: var(--primary-orange); } }

.answer-question-modal .answer-modal-width {
  width: 40%;
  max-width: none !important; }
  @media (max-width: 767.98px) {
    .answer-question-modal .answer-modal-width {
      width: 100%;
      margin: 0 auto; } }

h1 {
  font-size: 1.5rem;
  font-weight: 700;
  font-family: var(--serif-font-family);
  text-align: center; }

.modal-header {
  display: flex;
  align-items: flex-start; }
  .modal-header .cancel-btn {
    font-weight: 500; }
  .modal-header h1 {
    font-size: 0.875rem;
    font-family: var(--sans-serif-font-family);
    font-weight: 300;
    margin-top: 0.2rem;
    line-height: 1.1rem;
    max-width: 50%; }

.modal-body .form-control {
  border: none;
  box-shadow: 0px -4px 20px 1px rgba(0, 0, 0, 0.05);
  border-radius: 0.6rem;
  padding: 1rem;
  font-weight: 300; }

.action-item-modal {
  height: 90vh;
  overflow-y: scroll; }
  @media (max-width: 767.98px) {
    .action-item-modal {
      height: 100%; } }
  .action-item-modal p.ai-notes {
    white-space: pre-line;
    font-weight: 500;
    font-size: 1rem; }
  .action-item-modal .action-item-header {
    padding: 2rem; }
    .action-item-modal .action-item-header .go-back {
      display: flex;
      align-items: center;
      grid-gap: 0.5rem;
      gap: 0.5rem;
      cursor: pointer; }
      .action-item-modal .action-item-header .go-back p {
        font-size: 1.125rem;
        font-weight: 300; }
  .action-item-modal .approve-unapprove-btn {
    background-color: var(--primary-dark);
    color: var(--primary-white);
    border-radius: 0.6rem;
    padding: 0.5rem 1rem;
    font-weight: 500; }
  .action-item-modal .action-item-body {
    height: 100%;
    overflow-y: scroll; }
    @media (max-width: 767.98px) {
      .action-item-modal .action-item-body {
        height: 100%; } }
    .action-item-modal .action-item-body h1 {
      padding-bottom: 2rem; }
    .action-item-modal .action-item-body .title-header-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between; }
      @media (max-width: 767.98px) {
        .action-item-modal .action-item-body .title-header-wrapper {
          flex-direction: column;
          padding-bottom: 1rem;
          align-items: flex-start; } }
      .action-item-modal .action-item-body .title-header-wrapper .title-header {
        display: flex;
        align-items: center; }
        .action-item-modal .action-item-body .title-header-wrapper .title-header .go-back {
          cursor: pointer; }
        .action-item-modal .action-item-body .title-header-wrapper .title-header h1 {
          margin-bottom: 0;
          padding: 0; }
  .action-item-modal .primary-content {
    background-color: transparent;
    box-shadow: none; }
    .action-item-modal .primary-content .ai-description p,
    .action-item-modal .primary-content .ai-description span {
      font-weight: 200; }
    .action-item-modal .primary-content .ai-description figure img {
      width: 100%; }
  .action-item-modal .action-item-modal-width {
    width: 50%;
    max-width: none !important; }
    @media (max-width: 767.98px) {
      .action-item-modal .action-item-modal-width {
        width: 100%;
        margin: 0 auto; } }
  .action-item-modal .buttons button {
    background-color: transparent;
    border: 1.5px solid var(--primary-dark);
    color: var(--primary-dark);
    font-weight: 500;
    border-radius: 0.75rem; }

.roadmap-action-item-page {
  min-height: 100vh; }
  .roadmap-action-item-page .ai-description {
    padding-left: 1rem;
    overflow-wrap: break-word; }
    .roadmap-action-item-page .ai-description span {
      font-weight: 200; }
    .roadmap-action-item-page .ai-description p {
      font-size: 0.875rem;
      font-weight: 200; }
    .roadmap-action-item-page .ai-description img {
      max-width: calc(100% - 30px);
      margin: auto;
      display: block;
      border-radius: 10px; }
    .roadmap-action-item-page .ai-description .media {
      display: block; }
  .roadmap-action-item-page p.ai-notes {
    white-space: pre-line;
    font-weight: 300;
    font-size: 1rem; }
  .roadmap-action-item-page .action-button {
    width: 15em; }
  .roadmap-action-item-page .upload-progress {
    width: 8em; }
  @media (min-width: 992px) {
    .roadmap-action-item-page .form-control {
      border-color: #cccccc; }
      .roadmap-action-item-page .form-control:active, .roadmap-action-item-page .form-control:focus {
        border-color: #6e9fe0; } }
  .roadmap-action-item-page .approve-unapprove-btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    min-width: 9rem;
    background-color: var(--primary-dark);
    color: var(--primary-white); }
    @media (max-width: 1199.98px) {
      .roadmap-action-item-page .approve-unapprove-btn {
        margin-top: 0px !important; } }

.mrm-mt-0_75, .roadmap-action-item-page #formNotes, .roadmap-action-item-page button,
.user-components-profile-view .coach-card,
.roadmap-action-item-page #formNotes,
.roadmap-action-item-page button,
.chat-list-row:nth-of-type(1) {
  margin-top: 2px !important; }

.rh5v-PlayPause_button {
  width: 50px !important;
  height: 50px !important; }

.rh5v-Captions_button {
  width: 50px !important;
  height: 50px !important; }

.rh5v-Volume_button {
  width: 50px !important;
  height: 50px !important; }

.rh5v-Volume_icon {
  padding: 7px;
  width: 40px !important;
  height: 40px;
  margin-top: -20px; }

.rh5v-Fullscreen_icon {
  padding: 5px;
  width: 40px;
  height: 40px;
  margin-top: -5px;
  margin-left: -5px; }

.rh5v-PlayPause_icon {
  padding: 5px;
  width: 40px;
  height: 40px;
  margin-top: -20px;
  margin-left: -5px; }

.add-response-modal .modal-header {
  padding: 2rem; }

@media (max-width: 767.98px) {
  .add-response-modal .modal-content {
    position: fixed;
    bottom: 0;
    margin: 0 auto;
    width: 100%;
    margin-left: -0.5rem; } }

.add-response-modal .container {
  padding: 2rem;
  padding-top: 0; }
  .add-response-modal .container .form-control {
    border-radius: 0.7rem;
    padding: 1rem; }

.screen-capture .start-recording-btn {
  background-color: var(--primary-dark);
  color: var(--primary-white);
  padding: 0.75rem 1rem;
  border-radius: 0.6rem; }

.screen-capture .cancel-recording {
  background-color: transparent;
  color: var(--primary-dark);
  border: 2px solid var(--primary-dark);
  font-weight: 500;
  border-radius: 0.6rem; }

.roadmap-competency-page .reviewing-card {
  border-radius: 10px;
  padding: 10px 40px;
  background: #e0ddda;
  color: #5a5a5a;
  font-family: 'Poppins'; }

.assessments-and-discuss-wrapper .lateral-discuss {
  height: 47rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 0.6rem;
  position: relative; }
  .assessments-and-discuss-wrapper .lateral-discuss .title {
    font-size: 0.875rem;
    padding: 2rem 0 0rem 1.5rem;
    font-weight: 600; }
  .assessments-and-discuss-wrapper .lateral-discuss .subtitle {
    font-size: 0.875rem;
    padding: 0 0 1rem 1.5rem;
    font-weight: 300;
    font-style: italic; }
  .assessments-and-discuss-wrapper .lateral-discuss hr {
    margin: 1rem auto;
    padding: 0 1rem;
    border-color: var(--primary-gray); }
  .assessments-and-discuss-wrapper .lateral-discuss .discuss-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: flex-start;
    align-items: flex-start;
    grid-gap: 1rem;
    gap: 1rem;
    height: 28.5rem; }
    .assessments-and-discuss-wrapper .lateral-discuss .discuss-container .responses-length {
      padding-left: 1.5rem;
      font-size: 0.8rem; }

.no-responses-yet {
  font-size: 0.7rem;
  color: #515151;
  opacity: 0.55;
  font-weight: 600; }

.mobile-responses {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 1.75rem;
  background-color: var(--pure-white);
  border-radius: 2rem 2rem 0 0;
  z-index: 2147483637; }
  .mobile-responses .header {
    width: 100%;
    display: flex; }
    .mobile-responses .header p {
      text-align: left; }
  .mobile-responses .no-response {
    display: flex;
    flex-direction: column;
    align-items: center; }
  .mobile-responses .responses-container {
    width: 100%;
    padding-top: 1rem; }
    .mobile-responses .responses-container .height-action-item-start {
      max-width: 100%; }
      .mobile-responses .responses-container .height-action-item-start .action-item-header {
        background-color: transparent; }
        @media (max-width: 767.98px) {
          .mobile-responses .responses-container .height-action-item-start .action-item-header {
            background-color: var(--pure-white); } }
    .mobile-responses .responses-container .action-item-response {
      width: 100%; }
    .mobile-responses .responses-container .form-and-action-items {
      height: 52vh;
      width: 100%; }
      .mobile-responses .responses-container .form-and-action-items .left-border {
        margin-left: 0rem; }
      .mobile-responses .responses-container .form-and-action-items .action-item-responses {
        overflow-x: hidden; }
    .mobile-responses .responses-container hr {
      height: 0.75px;
      background-color: #dedcdc;
      margin-top: 1rem; }

.ai-response-input-container {
  display: flex;
  flex-direction: column;
  background-color: var(--pure-white);
  border-radius: 2rem 2rem 0.6rem 0;
  position: absolute;
  bottom: 0;
  align-items: center;
  padding: 1rem 1.25rem;
  width: 100%;
  grid-gap: 1rem;
  gap: 1rem; }
  .ai-response-input-container .ai-response-input-description {
    font-size: 0.7rem;
    color: var(--primary-dark);
    font-weight: 300;
    text-align: center; }
    .ai-response-input-container .ai-response-input-description strong {
      color: var(--primary-dark);
      font-weight: 600; }
  .ai-response-input-container .ai-response-mark-complete {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 45px;
    border-radius: 10px;
    background-color: var(--primary-dark);
    color: var(--pure-white);
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer; }
  .ai-response-input-container .send-action-item-response {
    display: flex;
    flex-direction: column;
    width: 100%; }
    @media (max-width: 767.98px) {
      .ai-response-input-container .send-action-item-response {
        padding-top: 0; } }
    .ai-response-input-container .send-action-item-response textarea {
      position: relative;
      width: 100%;
      border-radius: 2rem;
      height: 1.5rem;
      max-height: 20vh;
      overflow-y: auto;
      padding-left: 1rem;
      padding-top: 0.8rem;
      padding-right: 10%;
      font-size: 0.875rem; }
      @media (max-width: 767.98px) {
        .ai-response-input-container .send-action-item-response textarea {
          font-size: 1rem; } }
      .ai-response-input-container .send-action-item-response textarea::-webkit-input-placeholder {
        color: var(--primary-dark);
        opacity: 0.4;
        font-size: 0.875rem; }
      .ai-response-input-container .send-action-item-response textarea::placeholder {
        color: var(--primary-dark);
        opacity: 0.4;
        font-size: 0.875rem; }
    .ai-response-input-container .send-action-item-response button {
      position: absolute;
      right: 0;
      margin-top: 0.45rem;
      margin-right: 2rem; }

.action-item-responses {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: auto;
  grid-gap: 1.5rem;
  gap: 1.5rem;
  padding-bottom: 1rem;
  overflow-x: hidden; }
  @media (max-width: 991.98px) {
    .action-item-responses {
      height: 50vh; } }

.left-border {
  border-left: 3px solid #e5e5e5;
  padding-left: 1rem;
  margin-left: 1.5rem;
  width: calc(100% - 3rem); }

.height-action-item-start {
  max-height: 25vh;
  overflow: auto;
  max-width: 82%; }
  .height-action-item-start .action-item-header {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background-color: #fbfafa;
    padding-bottom: 0.75rem; }

.responses-length {
  font-size: 0.7rem;
  font-weight: 500;
  padding: 0.5rem 0; }

.discuss-container .first-textarea-message {
  height: 30vh;
  padding: 1rem;
  width: 90%;
  margin: 0 auto;
  font-size: 0.875rem; }
  .discuss-container .first-textarea-message::-webkit-input-placeholder {
    color: var(--primary-dark);
    opacity: 0.4;
    font-style: italic;
    font-size: 0.875rem; }
  .discuss-container .first-textarea-message::placeholder {
    color: var(--primary-dark);
    opacity: 0.4;
    font-style: italic;
    font-size: 0.875rem; }
  .discuss-container .first-textarea-message:focus {
    outline: none;
    border: 1.5px solid var(--primary-dark); }

.discuss-container .btn-send-message {
  padding: 0.65rem;
  background-color: var(--primary-dark);
  color: var(--pure-white);
  font-weight: 500;
  border-radius: 10px;
  width: 60%;
  margin-left: 5%;
  margin-bottom: 1rem; }
  .discuss-container .btn-send-message:disabled {
    background-color: transparent;
    color: var(--primary-dark);
    border: 1px solid var(--primary-dark); }

@media (max-width: 991.98px) {
  .discuss-container .first-textarea-message {
    width: 100%;
    height: 60vh;
    font-size: 1rem; }
  .discuss-container .btn-send-message {
    width: 100%; } }

.fixed-bottom {
  overscroll-behavior: contain; }

.mobile-first-response {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: var(--pure-white);
  padding: 1.5rem 7.5%;
  border-radius: 40px 40px 0 0;
  height: 100%; }
  .mobile-first-response .mobile-first-response-header {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 45px; }
    .mobile-first-response .mobile-first-response-header .response-label {
      width: 100%;
      font-size: 14px;
      font-weight: 600;
      position: absolute;
      left: 0; }
  .mobile-first-response .mobile-all-responses-container {
    height: 75vh;
    width: 100%;
    padding: 1rem 0; }
    .mobile-first-response .mobile-all-responses-container::-webkit-scrollbar {
      display: none; }
    .mobile-first-response .mobile-all-responses-container .mobile-send-action-item-response-container {
      display: flex;
      flex-direction: row;
      width: 100%;
      align-items: flex-start;
      position: absolute;
      bottom: 0;
      left: 0;
      background-color: var(--pure-white);
      padding: 1.5rem 1rem; }
      .mobile-first-response .mobile-all-responses-container .mobile-send-action-item-response-container .profile-pic-container {
        height: 45px;
        width: 45px;
        border-radius: 50%;
        margin-right: 0.5rem; }
        .mobile-first-response .mobile-all-responses-container .mobile-send-action-item-response-container .profile-pic-container .profile-pic {
          height: 36px;
          width: 36px;
          border-radius: 50%;
          object-fit: cover; }
    .mobile-first-response .mobile-all-responses-container .mobile-send-action-item-response {
      display: flex;
      flex-direction: column;
      width: 100%; }
      @media (max-width: 767.98px) {
        .mobile-first-response .mobile-all-responses-container .mobile-send-action-item-response {
          padding-top: 0; } }
      .mobile-first-response .mobile-all-responses-container .mobile-send-action-item-response textarea {
        position: relative;
        width: 100%;
        border-radius: 10px;
        height: 36px;
        max-height: 20vh;
        overflow-y: auto;
        padding-left: 1rem;
        padding-top: 0.8rem;
        font-size: 0.875rem;
        background-color: #f1f1f3;
        border: none;
        padding-right: 3rem;
        font-weight: 300; }
        @media (max-width: 767.98px) {
          .mobile-first-response .mobile-all-responses-container .mobile-send-action-item-response textarea {
            font-size: 1rem; } }
        .mobile-first-response .mobile-all-responses-container .mobile-send-action-item-response textarea::-webkit-input-placeholder {
          color: #848389;
          font-size: 0.875rem; }
        .mobile-first-response .mobile-all-responses-container .mobile-send-action-item-response textarea::placeholder {
          color: #848389;
          font-size: 0.875rem; }
        .mobile-first-response .mobile-all-responses-container .mobile-send-action-item-response textarea:focus-visible {
          outline: none; }
      .mobile-first-response .mobile-all-responses-container .mobile-send-action-item-response .action-item-response-button {
        position: absolute;
        right: 0;
        margin-top: 0.45rem;
        margin-right: 2rem; }
  .mobile-first-response .height-action-item-start {
    max-width: 100%;
    width: 100%;
    height: -webkit-max-content;
    height: max-content;
    max-height: -webkit-max-content;
    max-height: max-content;
    margin: 0 auto; }
    .mobile-first-response .height-action-item-start .action-item-response {
      margin: 0; }
    .mobile-first-response .height-action-item-start .action-item-header {
      background-color: var(--pure-white); }
  .mobile-first-response .comment-btn-action-item-container {
    display: flex;
    align-items: center;
    flex-direction: row;
    width: 100%;
    margin: 0 auto;
    margin-left: -6px; }
    .mobile-first-response .comment-btn-action-item-container p {
      font-size: 12px;
      font-weight: 600;
      color: '#5D5D5D'; }
  .mobile-first-response .comments-length {
    font-size: 14px;
    font-weight: 600;
    text-align: left;
    width: 100%;
    margin: 1rem auto;
    color: '#5D5D5D'; }
  .mobile-first-response .discuss-container {
    width: 100%; }
    .mobile-first-response .discuss-container .btn-send-message {
      margin-left: 0; }
    .mobile-first-response .discuss-container .first-textarea-message {
      height: 300px;
      font-size: 14px; }
  .mobile-first-response .form-and-action-items {
    height: auto;
    max-height: -webkit-min-content;
    max-height: min-content; }
    .mobile-first-response .form-and-action-items .action-item-responses {
      height: 100%;
      padding-bottom: 2rem;
      overflow-x: hidden; }
      .mobile-first-response .form-and-action-items .action-item-responses .left-border {
        margin-left: 0;
        margin-bottom: 1rem; }

h1 {
  text-align: left; }

.chat-page .chat-page-wrapper .content .conversations .item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 1rem 2rem;
  position: relative; }
  .chat-page .chat-page-wrapper .content .conversations .item .photo-and-name {
    display: flex;
    flex-direction: row;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    align-items: center; }
    .chat-page .chat-page-wrapper .content .conversations .item .photo-and-name img {
      width: 3.7rem;
      height: 4rem;
      object-fit: contain;
      border-radius: 0.5rem;
      border-end-end-radius: 0; }
    .chat-page .chat-page-wrapper .content .conversations .item .photo-and-name .name-and-time h3 {
      font-weight: 500;
      font-size: 1rem; }
    .chat-page .chat-page-wrapper .content .conversations .item .photo-and-name .name-and-time p {
      font-size: 0.7rem;
      color: var(--primary-gray); }
  .chat-page .chat-page-wrapper .content .conversations .item .number-of-messages {
    display: flex;
    align-items: center;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: var(--primary-alerts); }

.chat-page .chat-page-wrapper .content .content-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding: 2rem 2rem 1rem 2rem; }
  .chat-page .chat-page-wrapper .content .content-header h1 {
    font-size: 2rem;
    font-family: var(--sans-serif-font-family);
    font-weight: 300; }
  .chat-page .chat-page-wrapper .content .content-header button {
    outline: none; }

.chat-page .chat-page-wrapper .content .content-tabs {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0 2rem; }
  .chat-page .chat-page-wrapper .content .content-tabs .tab {
    display: flex;
    align-items: center;
    flex-direction: column;
    grid-gap: 0.3rem;
    gap: 0.3rem;
    cursor: pointer; }

.chat-page .chat-page-wrapper .content .content-search {
  display: flex;
  width: 90%;
  align-items: center;
  position: relative;
  margin: 0 auto; }
  .chat-page .chat-page-wrapper .content .content-search input {
    width: 100%;
    padding-left: 3rem;
    border-radius: 3rem; }
  .chat-page .chat-page-wrapper .content .content-search svg {
    position: absolute;
    left: 1rem; }

.chat-page .chat-page-wrapper .messages-container .messages {
  padding: 1rem;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem;
  height: 100%; }
  @media (min-width: 992px) {
    .chat-page .chat-page-wrapper .messages-container .messages {
      max-height: 48vh;
      height: 48vh; } }
  .chat-page .chat-page-wrapper .messages-container .messages .message {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    grid-gap: 1rem;
    gap: 1rem; }
    .chat-page .chat-page-wrapper .messages-container .messages .message .message-header {
      display: flex;
      grid-gap: 0.5rem;
      gap: 0.5rem;
      align-items: flex-start;
      width: 100%; }
      .chat-page .chat-page-wrapper .messages-container .messages .message .message-header img {
        width: 2rem;
        height: 2rem;
        object-fit: contain; }
      .chat-page .chat-page-wrapper .messages-container .messages .message .message-header .name-and-time {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        grid-gap: 0.25rem;
        gap: 0.25rem; }
        .chat-page .chat-page-wrapper .messages-container .messages .message .message-header .name-and-time .name {
          font-weight: 500;
          font-size: 1rem; }
        .chat-page .chat-page-wrapper .messages-container .messages .message .message-header .name-and-time .time {
          font-size: 0.7rem;
          opacity: 0.5; }
    .chat-page .chat-page-wrapper .messages-container .messages .message .message-body {
      font-weight: 300;
      font-size: 0.8rem;
      background-color: var(--pure-white);
      width: 100%;
      white-space: pre-wrap;
      overflow-wrap: break-word;
      padding: 1.4rem; }
      .chat-page .chat-page-wrapper .messages-container .messages .message .message-body a {
        word-break: break-all; }

.chat-page .chat-page-wrapper .messages-container .sender {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  background-color: var(--pure-white);
  border-radius: 1.8rem 1.8rem 0px 0px;
  grid-gap: 1rem;
  gap: 1rem; }
  .chat-page .chat-page-wrapper .messages-container .sender .new-message {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    height: 100%; }
    .chat-page .chat-page-wrapper .messages-container .sender .new-message .message-header {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 1rem;
      padding-bottom: 0; }
      .chat-page .chat-page-wrapper .messages-container .sender .new-message .message-header p {
        font-weight: 300; }
    .chat-page .chat-page-wrapper .messages-container .sender .new-message .input-container {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-top: 2rem;
      align-items: flex-start;
      padding: 0 1rem; }
      .chat-page .chat-page-wrapper .messages-container .sender .new-message .input-container p {
        font-weight: 500; }
      .chat-page .chat-page-wrapper .messages-container .sender .new-message .input-container input {
        width: 100%;
        border: none;
        outline: none;
        padding: 0;
        margin-top: -1rem;
        margin-bottom: 1rem;
        border-bottom: 1px solid #e6e6e6;
        border-radius: 0;
        padding-bottom: 0.5rem; }
        .chat-page .chat-page-wrapper .messages-container .sender .new-message .input-container input::-webkit-input-placeholder {
          color: rgba(0, 0, 0, 0.25);
          font-weight: 300; }
        .chat-page .chat-page-wrapper .messages-container .sender .new-message .input-container input::placeholder {
          color: rgba(0, 0, 0, 0.25);
          font-weight: 300; }
    .chat-page .chat-page-wrapper .messages-container .sender .new-message .recipients {
      height: 100%;
      overflow-y: auto;
      max-height: 100%;
      height: 100%;
      align-items: flex-start;
      padding: 0 1rem;
      width: 100%; }
      .chat-page .chat-page-wrapper .messages-container .sender .new-message .recipients .recipient {
        display: flex;
        flex-direction: row;
        align-items: center;
        grid-gap: 0.5rem;
        gap: 0.5rem;
        padding: 0.5rem 0; }
        .chat-page .chat-page-wrapper .messages-container .sender .new-message .recipients .recipient img {
          width: 2rem;
          height: 2rem;
          object-fit: contain;
          border-radius: 0.5rem; }
        .chat-page .chat-page-wrapper .messages-container .sender .new-message .recipients .recipient .name, .chat-page .chat-page-wrapper .messages-container .sender .new-message .recipients .recipient .email {
          font-weight: 500;
          font-size: 0.75rem; }
    .chat-page .chat-page-wrapper .messages-container .sender .new-message .name-group-container {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      width: 100%;
      grid-gap: 1rem;
      gap: 1rem; }
      .chat-page .chat-page-wrapper .messages-container .sender .new-message .name-group-container input {
        width: 90%;
        margin: 0 auto;
        margin-top: 5vh;
        border: none;
        border-bottom: 1px solid #e6e6e6;
        outline: none;
        padding: 0;
        border-radius: 0; }
      .chat-page .chat-page-wrapper .messages-container .sender .new-message .name-group-container button {
        width: -webkit-max-content;
        width: max-content;
        padding: 0.5rem 1rem;
        display: flex;
        align-items: center;
        justify-items: center;
        color: var(--pure-white);
        background-color: var(--primary-dark);
        border-radius: 0.4rem;
        margin-right: 5%; }
  .chat-page .chat-page-wrapper .messages-container .sender .record {
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 0.5rem;
    gap: 0.5rem; }
    .chat-page .chat-page-wrapper .messages-container .sender .record .microphone {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 3.07rem;
      width: 3.07rem;
      background-color: var(--primary-dark);
      border-radius: 50%;
      cursor: pointer; }
    .chat-page .chat-page-wrapper .messages-container .sender .record .label {
      font-size: 0.6rem;
      opacity: 0.4; }
  .chat-page .chat-page-wrapper .messages-container .sender .input-container {
    display: flex;
    align-items: center;
    width: 100%;
    grid-gap: 1rem;
    gap: 1rem; }
    .chat-page .chat-page-wrapper .messages-container .sender .input-container .input-wrapper {
      display: flex;
      position: relative;
      align-items: center;
      width: 100%; }
      .chat-page .chat-page-wrapper .messages-container .sender .input-container .input-wrapper textarea {
        width: 100%;
        padding-top: 0.75rem;
        padding-left: 1rem;
        padding-right: 12%;
        max-height: 10rem; }
        .chat-page .chat-page-wrapper .messages-container .sender .input-container .input-wrapper textarea::-webkit-input-placeholder {
          color: rgba(0, 0, 0, 0.25); }
        .chat-page .chat-page-wrapper .messages-container .sender .input-container .input-wrapper textarea::placeholder {
          color: rgba(0, 0, 0, 0.25); }
      .chat-page .chat-page-wrapper .messages-container .sender .input-container .input-wrapper button {
        position: absolute;
        right: 1rem; }
  .chat-page .chat-page-wrapper .messages-container .sender .mic-recording {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 6rem;
    width: 6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer; }

.chat-page .chat-page-wrapper .messages-container .header {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding: 1.5rem 2rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1); }
  .chat-page .chat-page-wrapper .messages-container .header button {
    outline: none; }
  .chat-page .chat-page-wrapper .messages-container .header .name-and-status {
    display: flex;
    flex-direction: column;
    align-items: center; }
    .chat-page .chat-page-wrapper .messages-container .header .name-and-status .name {
      font-weight: 300; }
    .chat-page .chat-page-wrapper .messages-container .header .name-and-status .status {
      font-size: 0.7rem;
      opacity: 0.5; }
    .chat-page .chat-page-wrapper .messages-container .header .name-and-status .edit-group-info {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 1rem; }
      .chat-page .chat-page-wrapper .messages-container .header .name-and-status .edit-group-info .group-name-container {
        margin-top: 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center; }
        .chat-page .chat-page-wrapper .messages-container .header .name-and-status .edit-group-info .group-name-container input {
          border: none;
          outline: none;
          border-bottom: 1px solid #e4e4e4;
          background: none;
          font-weight: 300;
          font-size: 1.5rem;
          margin-bottom: 0.5rem; }
        .chat-page .chat-page-wrapper .messages-container .header .name-and-status .edit-group-info .group-name-container h1 {
          font-family: var(--sans-serif-font-family);
          font-weight: 300;
          font-size: 1.5rem; }
        .chat-page .chat-page-wrapper .messages-container .header .name-and-status .edit-group-info .group-name-container p {
          font-weight: 600;
          font-size: 0.7rem;
          color: #7d7d7d; }

.delete-group-modal {
  z-index: 999999999; }
  .delete-group-modal .modal-body {
    margin: 0 auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 1rem;
    gap: 1rem; }
    .delete-group-modal .modal-body .buttons-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      grid-gap: 1rem;
      gap: 1rem;
      width: 100%;
      justify-content: center; }
      .delete-group-modal .modal-body .buttons-container .delete-btn {
        background-color: var(--primary-red);
        color: var(--pure-white);
        width: -webkit-max-content;
        width: max-content;
        padding: 0.5rem 1rem;
        border-radius: 5px; }

h1 {
  text-align: left; }

.chat-page {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center; }
  .chat-page .chat-page-wrapper {
    display: grid;
    background-color: #fcfbfa;
    margin: 0 auto;
    transition: all 0.5s;
    margin-top: 0;
    align-items: center;
    border-radius: 0.5rem;
    position: relative;
    max-width: 1140px;
    height: 100vh;
    align-items: flex-start; }
    @media (min-width: 992px) {
      .chat-page .chat-page-wrapper {
        margin-top: 1rem;
        height: auto;
        align-items: center; } }
    @media (max-width: 767.98px) {
      .chat-page .chat-page-wrapper {
        z-index: 9999998; } }
    .chat-page .chat-page-wrapper .content {
      display: flex;
      flex-direction: column;
      grid-gap: 1.5rem;
      gap: 1.5rem;
      height: 80vh;
      padding-bottom: 2.5rem;
      width: 100%;
      background-color: var(--primary-white);
      border-radius: 0.625rem 0 0 0.625rem; }
      @media (max-width: 991.98px) {
        .chat-page .chat-page-wrapper .content {
          height: 100vh;
          padding-bottom: 0; } }
      .chat-page .chat-page-wrapper .content .conversations {
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        height: 100%; }
        .chat-page .chat-page-wrapper .content .conversations hr {
          height: 1px; }
    .chat-page .chat-page-wrapper .messages-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 80vh;
      overflow-y: auto; }
      @media (max-width: 991.98px) {
        .chat-page .chat-page-wrapper .messages-container {
          height: 100vh; } }

.audio-player-send .state-label {
  font-size: 0.875rem;
  font-weight: 300; }

.audio-player-send .player-container {
  width: 85%;
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem; }
  .audio-player-send .player-container .bars-and-time-container {
    display: flex;
    flex-direction: row;
    align-items: center; }
    .audio-player-send .player-container .bars-and-time-container .time {
      font-size: 0.875rem;
      margin-right: 0.5rem;
      color: var(--primary-dark);
      opacity: 0.5; }
    .audio-player-send .player-container .bars-and-time-container .right {
      margin-right: 0rem;
      margin-left: 0.5rem; }
    .audio-player-send .player-container .bars-and-time-container .bar {
      width: 3.34px; }
      @media (max-width: 991.98px) {
        .audio-player-send .player-container .bars-and-time-container .bar {
          width: 2.81px; } }

.audio-player-send .confirm-delete {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  grid-gap: 1rem;
  gap: 1rem;
  width: 58%; }
  .audio-player-send .confirm-delete button {
    display: flex;
    width: 100%;
    align-items: center;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    border: 1px solid var(--primary-dark);
    justify-content: center; }
  .audio-player-send .confirm-delete .delete {
    background-color: var(--primary-dark);
    color: var(--pure-white); }

.audio-message-player-container {
  display: flex;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
  border-color: #e5e5e5;
  padding: 0.6rem;
  border-radius: 0.5rem; }
  .audio-message-player-container .play-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 auto;
    z-index: 999; }
    .audio-message-player-container .play-button button {
      outline: none;
      width: 3rem;
      height: 3rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 6px; }
  .audio-message-player-container .bars-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 auto;
    z-index: 999; }
    .audio-message-player-container .bars-container .bar-unit {
      width: 2.45px;
      border-radius: 1.4rem; }

.inactive-messaging-banner {
  background-color: #f6f6f6;
  position: relative;
  width: 100%;
  max-width: 1140px;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 10px; }
  @media (max-width: 991.98px) {
    .inactive-messaging-banner {
      background: transparent; } }
  .inactive-messaging-banner h1 {
    position: absolute;
    top: 2rem;
    left: 3rem;
    font-size: 1.5rem;
    text-align: center; }
    @media (max-width: 991.98px) {
      .inactive-messaging-banner h1 {
        position: relative;
        top: 0;
        left: 0;
        margin-bottom: 2rem; } }
  .inactive-messaging-banner .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    grid-gap: 1rem;
    gap: 1rem; }
    .inactive-messaging-banner .content h2 {
      font-family: var(--serif-font-family);
      font-size: 1.25rem;
      width: 50%;
      margin-top: 1rem; }
    .inactive-messaging-banner .content p {
      font-size: 0.875rem;
      font-weight: 300;
      width: 50%; }
    .inactive-messaging-banner .content a {
      text-decoration: none;
      color: var(--pure-white);
      font-weight: 400;
      font-size: 1rem;
      background-color: var(--primary-dark);
      padding: 0.75rem 3rem;
      border-radius: 10px;
      width: -webkit-max-content;
      width: max-content; }
      .inactive-messaging-banner .content a:hover {
        background-color: var(--primary-dark);
        opacity: 1; }

h1 {
  text-align: left; }

h1 {
  text-align: left; }

.notifications-default-page {
  min-height: 100vh;
  background-color: var(--primary-white); }
  .notifications-default-page .notification-header {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%; }
    .notifications-default-page .notification-header h1 {
      font-family: var(--serif-font-family);
      font-size: larger;
      padding: 2rem 0 1rem 0; }

.mark-all-as-read-modal .modal-content {
  background-color: transparent;
  border: none; }

h1 {
  text-align: left; }

.notifications-components-default-page-notifications-list .no-notifications {
  text-align: center; }
  .notifications-components-default-page-notifications-list .no-notifications .fa-bell {
    color: #007bff !important;
    width: 2.5rem !important;
    font-size: 2.5rem !important; }

.notifications-components-default-page-notifications-list .notification-row {
  cursor: pointer;
  position: relative;
  font-size: 0.875rem;
  padding: 1rem 1rem 1rem 0;
  border-bottom: 1px solid #e3e3e3;
  display: flex;
  align-items: center; }
  .notifications-components-default-page-notifications-list .notification-row .initials {
    height: 3.5rem;
    width: 3.5rem; }
  .notifications-components-default-page-notifications-list .notification-row p {
    margin-bottom: 0;
    line-height: 16px;
    font-size: 0.8rem; }
  .notifications-components-default-page-notifications-list .notification-row .name-and-date-grid {
    display: grid;
    grid-gap: 0.5rem;
    gap: 0.5rem; }
  .notifications-components-default-page-notifications-list .notification-row .profile-photo {
    border: none !important; }
    .notifications-components-default-page-notifications-list .notification-row .profile-photo img {
      height: 3.5rem;
      width: 3.5rem;
      margin-right: 15px;
      margin-left: 5px; }
  .notifications-components-default-page-notifications-list .notification-row .notification-primary {
    color: var(--primary-dark-green); }
    .notifications-components-default-page-notifications-list .notification-row .notification-primary strong {
      font-weight: 500; }
    .notifications-components-default-page-notifications-list .notification-row .notification-primary span {
      font-weight: 300; }
  .notifications-components-default-page-notifications-list .notification-row .notification-secondary {
    justify-self: flex-end; }
  .notifications-components-default-page-notifications-list .notification-row .unread-marker {
    min-width: 0.5rem;
    min-height: 0.5rem;
    border-radius: 50%;
    background-color: var(--primary-green);
    display: inline-block;
    top: 46px;
    left: 5px; }

.notifications-components-default-page-notifications-list hr {
  height: 1px;
  margin: 0 -1.5rem; }

h1 {
  text-align: left; }

.notifications-settings .table-container {
  margin-top: 2.875rem;
  width: 95%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  padding: 2.8125rem; }
  .notifications-settings .table-container table {
    width: 100%;
    background-color: var(--primary-white);
    border-radius: 0.625rem;
    border-collapse: separate;
    border-spacing: 0 15px; }
    .notifications-settings .table-container table th {
      color: var(--primary-dark);
      font-weight: 600;
      font-size: 0.875rem;
      padding: 2.8125rem;
      padding-bottom: 0.5rem; }
    .notifications-settings .table-container table .type {
      width: 2.5rem; }
      .notifications-settings .table-container table .type p {
        color: #908e87;
        font-size: 0.75rem;
        text-align: center;
        width: 100%; }
    .notifications-settings .table-container table tbody tr {
      margin-top: 1rem;
      font-size: 0.75rem;
      font-weight: 300; }
    .notifications-settings .table-container table tbody .switch {
      margin-left: 2.85rem; }
    .notifications-settings .table-container table tr {
      border-top: 1px solid #e5e5e5; }
    .notifications-settings .table-container table tr:last-child td {
      padding-bottom: 2.8125rem; }

h1 {
  text-align: left; }

h1 {
  text-align: left; }

.manage-user-page {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  justify-items: center;
  padding: 0 0 2rem 0;
  background-color: var(--primary-bg-white); }
  .manage-user-page .user-page-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    margin: 1.5rem auto; }
  .manage-user-page .desktop-view-container {
    display: none;
    flex-direction: column;
    width: 80%;
    overflow: hidden;
    justify-items: center;
    margin: 0 auto; }
    .manage-user-page .desktop-view-container .clients-coaches-container {
      width: 100%; }
  .manage-user-page .profile-card {
    padding: 2.2rem;
    border-radius: 1rem;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 1.5rem;
    background-color: var(--pure-white); }
    .manage-user-page .profile-card .profile-picture-container {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      grid-gap: 1rem;
      gap: 1rem;
      position: relative; }
      .manage-user-page .profile-card .profile-picture-container h1 {
        font-family: var(--serif-font-family); }
      .manage-user-page .profile-card .profile-picture-container p {
        padding: 0.2rem 1rem;
        font-size: 0.7rem;
        border-radius: 1rem; }
      .manage-user-page .profile-card .profile-picture-container .message-user {
        position: absolute;
        right: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        grid-gap: 0.2rem;
        gap: 0.2rem;
        border-radius: 5px;
        border: 1px solid var(--primary-dark);
        padding: 0.5rem 0.75rem; }
        .manage-user-page .profile-card .profile-picture-container .message-user svg {
          margin-left: -5px; }
        .manage-user-page .profile-card .profile-picture-container .message-user p {
          text-decoration: none;
          padding: 0; }
  .manage-user-page .bottom-price-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 2rem; }
    .manage-user-page .bottom-price-container .subscription-type {
      display: flex;
      flex-direction: column;
      grid-gap: 0.5rem;
      gap: 0.5rem; }
      .manage-user-page .bottom-price-container .subscription-type p {
        font-size: 0.7rem;
        padding: 0.3rem;
        background-color: var(--primary-green);
        max-width: -webkit-fit-content;
        max-width: -moz-fit-content;
        max-width: fit-content;
        border-radius: 0.5rem;
        color: var(--pure-white);
        font-weight: 500; }
      .manage-user-page .bottom-price-container .subscription-type h2 {
        font-size: 1.25rem;
        color: var(--primary-dark);
        font-weight: 300; }
    .manage-user-page .bottom-price-container .subscription-date p {
      font-weight: 300; }
    .manage-user-page .bottom-price-container .price p {
      font-weight: 600; }
    .manage-user-page .bottom-price-container .payment-buttons {
      display: flex;
      align-items: center;
      grid-gap: 0.5rem;
      gap: 0.5rem; }
    .manage-user-page .bottom-price-container button {
      border: 1px solid var(--primary-dark);
      padding: 0.55rem 1.3rem;
      border-radius: 0.6rem;
      outline: none;
      font-size: 0.8rem; }
      .manage-user-page .bottom-price-container button:hover {
        background-color: var(--primary-dark);
        color: var(--pure-white); }
      .manage-user-page .bottom-price-container button:disabled {
        cursor: not-allowed; }
    .manage-user-page .bottom-price-container .cancel-subscription {
      color: var(--primary-white);
      background-color: var(--primary-dark); }
  .manage-user-page .client-roadmaps {
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
    border-radius: 0.8rem;
    padding: 1.5rem; }
    .manage-user-page .client-roadmaps .roadmap {
      background-color: var(--pure-white); }
    .manage-user-page .client-roadmaps .client-roadmaps-header {
      display: flex;
      align-items: center;
      justify-content: space-between; }
      .manage-user-page .client-roadmaps .client-roadmaps-header p {
        font-size: 1rem;
        font-weight: 500; }
      .manage-user-page .client-roadmaps .client-roadmaps-header .plus-btn {
        padding: 0.4rem;
        display: flex;
        max-width: -webkit-fit-content;
        max-width: -moz-fit-content;
        max-width: fit-content; }

.manage-user-page .profile-card {
  background-color: var(--primary-white); }

@media (min-width: 992px) {
  .manage-user-page .client-roadmaps {
    background-color: var(--primary-white); } }

@media (min-width: 992px) {
  .manage-user-page .desktop-view-container {
    display: flex; }
  .manage-user-page .client-roadmaps {
    width: 100%;
    padding: 2.5rem;
    background-color: var(--primary-white); }
  .profile-tab-content .clients-coaches-container {
    width: 100%; } }

.recent-activity {
  padding: 1.5rem; }
  .recent-activity h3 {
    padding-bottom: 1rem; }
  .recent-activity .recent-activity-description {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center; }
    .recent-activity .recent-activity-description p {
      font-size: 0.875rem;
      font-weight: 300; }
      .recent-activity .recent-activity-description p span {
        font-weight: 600; }
    .recent-activity .recent-activity-description .recent-activity-title {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      grid-gap: 0.5rem;
      gap: 0.5rem; }

h1 {
  text-align: left; }

.manage-edit-user-roadmap-page .avatar {
  position: absolute;
  left: 50%;
  -webkit-transform: translate(-50%, -60%);
          transform: translate(-50%, -60%); }

.manage-edit-user-roadmap-page .card-body {
  border-radius: 10px; }

.manage-edit-user-roadmap-page .common-header button {
  background-color: transparent;
  box-shadow: none; }
  .manage-edit-user-roadmap-page .common-header button:hover {
    opacity: 0.7; }

.manage-edit-user-roadmap-page .common-header .btn-save {
  color: #007bff; }

.manage-edit-user-roadmap-component .no-roadmaps {
  font-style: italic;
  color: #aaaaaa;
  text-align: center; }

.manage-edit-user-roadmap-component .card-in-card {
  background-color: var(--pure-white);
  min-height: 55px;
  line-height: 20px; }

.manage-edit-user-roadmap-component .roadmap-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  font-size: 1rem;
  border-bottom: 1px solid #ebebeb; }
  .manage-edit-user-roadmap-component .roadmap-title button {
    height: inherit;
    font-size: 0.875rem; }
    .manage-edit-user-roadmap-component .roadmap-title button.unassign {
      background-color: inherit;
      box-shadow: none; }
      .manage-edit-user-roadmap-component .roadmap-title button.unassign:hover {
        background-color: white; }

.manage-edit-user-roadmap-modal .modal-header .common-user-avatar {
  display: inline-block;
  vertical-align: middle; }

.manage-edit-user-roadmap-modal .modal-header p {
  margin-bottom: 0;
  text-align: center; }

h1 {
  text-align: left; }

.manage-edit-user-profile-page .common-header button {
  background-color: transparent;
  box-shadow: none; }
  .manage-edit-user-profile-page .common-header button:hover {
    opacity: 0.7; }

.manage-edit-user-profile-page .common-header .btn-save {
  color: #007bff; }

.manage-edit-user-profile-modal {
  display: flex;
  flex-direction: column;
  width: 97%;
  margin: 0 auto; }
  .manage-edit-user-profile-modal .modal-header {
    position: -webkit-sticky;
    position: sticky;
    width: 100%;
    background: white;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 1rem;
    gap: 1rem;
    align-items: center;
    padding: 1rem 1.5rem; }
    .manage-edit-user-profile-modal .modal-header .chevron {
      justify-self: flex-start; }
    .manage-edit-user-profile-modal .modal-header h2 {
      width: -webkit-max-content;
      width: max-content;
      font-weight: 500;
      font-family: var(--serif-font-family); }
    .manage-edit-user-profile-modal .modal-header .save-btn {
      justify-self: end; }

.manage-edit-user-profile-component label {
  margin-bottom: 0; }

.manage-edit-user-profile-component .error-message {
  color: #dc3545; }

.manage-edit-user-profile-component .action-button {
  width: 17em; }

.manage-edit-user-profile-component .account-photo {
  border-radius: 50%;
  width: 4em;
  height: 4em; }

.manage-edit-user-profile-component .invalid-avatar {
  font-size: 1rem; }
  .manage-edit-user-profile-component .invalid-avatar.is-invalid {
    color: #dc3545; }

@media (min-width: 992px) {
  .manage-edit-user-profile-component .form-control {
    border-color: #cccccc; }
    .manage-edit-user-profile-component .form-control:active, .manage-edit-user-profile-component .form-control:focus {
      border-color: #6e9fe0; } }

.desktop-page-secondary-header-wrapper .btn-save {
  float: right;
  background: #2f80ed;
  color: #fff; }

.edit-profile-container {
  display: flex;
  flex-direction: column;
  background-color: var(--pure-white);
  margin: 0rem auto;
  justify-content: center;
  width: 100%;
  border-radius: 0.5rem;
  padding: 1.5rem 0rem; }
  .edit-profile-container h1 {
    font-size: 1.5rem;
    font-family: var(--serif-font-family);
    margin: 0 auto;
    margin-bottom: 0.5rem; }
  .edit-profile-container .form-group {
    position: relative; }
    .edit-profile-container .form-group .icon {
      position: absolute;
      top: 0.7rem;
      left: 2.5rem; }
    .edit-profile-container .form-group .email {
      top: 0.9rem;
      left: 2.25rem; }
    .edit-profile-container .form-group .phone {
      top: 0.6rem; }
  .edit-profile-container .form-control {
    border-radius: 0.5rem;
    border: 1px solid var(--primary-gray-lighter);
    padding: 1.25rem 1rem;
    padding-left: 3rem;
    font-size: 1rem;
    width: 100%;
    margin-bottom: 1rem;
    max-width: 90%;
    margin: 0 auto;
    font-weight: 300; }
  .edit-profile-container .checkbox-grid {
    display: grid;
    grid-gap: 1rem;
    gap: 1rem;
    width: 100%;
    max-width: 90%;
    padding: 0 2rem; }
    .edit-profile-container .checkbox-grid .form-check {
      display: flex;
      align-items: flex-start; }
  .edit-profile-container .text-area-edit-profile {
    height: 7rem;
    padding: 1rem; }
  .edit-profile-container .save-cancel-grid {
    display: none;
    grid-gap: 0.5rem;
    gap: 0.5rem; }
  .edit-profile-container .btn-cancel-edit {
    display: block;
    background-color: transparent;
    width: 100%;
    padding: 0.5rem;
    color: var(--primary-dark);
    border-radius: 0.5rem;
    justify-items: center;
    min-width: -webkit-max-content;
    min-width: max-content;
    border: 1px solid var(--primary-dark);
    max-width: 85%;
    margin: 0 auto; }
    .edit-profile-container .btn-cancel-edit:hover {
      background-color: var(--primary-dark);
      color: var(--pure-white); }
  .edit-profile-container .btn-save-edit {
    display: block;
    background-color: var(--primary-dark);
    color: var(--pure-white);
    width: 100%;
    padding: 0.6rem;
    border-radius: 0.5rem;
    justify-items: center;
    min-width: -webkit-max-content;
    min-width: max-content;
    max-width: 85%;
    margin: 0 auto; }
  .edit-profile-container .resend-delete-grid {
    display: grid;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    justify-items: center;
    margin-top: 1rem;
    grid-template-columns: 1fr 1fr;
    margin: 0 auto;
    width: 85%;
    padding: 1rem 0; }
    .edit-profile-container .resend-delete-grid .action-button {
      width: 100%;
      background-color: var(--primary-gray);
      color: var(--pure-white);
      padding: 0.5rem;
      border-radius: 0.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1rem; }

@media (min-width: 992px) {
  .edit-profile-container {
    max-width: 35%;
    margin: 1.5rem auto; }
    .edit-profile-container .save-cancel-grid {
      display: grid; } }

.edit-profile-container {
  background-color: var(--primary-white); }

h1 {
  text-align: left; }

.manage-groups-page .btn-edit {
  background-color: #f4f4f5; }

@media (max-width: 991.98px) {
  .manage-groups-page .common-custom-table {
    border-radius: 0px; } }

h1 {
  text-align: left; }

.manage-group-view-page .common-header .col-1 {
  padding-left: 0; }

.manage-group-view-page .group-name,
.manage-group-view-page strong {
  font-size: 1.125rem; }

.manage-group-view-page .avatar {
  width: 5em;
  height: 5em;
  color: #007bff;
  border-radius: 50%;
  display: flex;
  background-color: white;
  position: absolute;
  left: 50%;
  -webkit-transform: translate(-50%, -80%);
          transform: translate(-50%, -80%);
  border: 2px solid #eeeeee; }

.manage-group-view-page .table {
  font-size: 0.875rem; }

.manage-group-view-page .btn-edit-group {
  position: absolute;
  right: 0.5em;
  top: 0.5em; }

.manage-group-view-page .card-in-card {
  background-color: var(--pure-white);
  font-weight: bold;
  min-height: 55px;
  line-height: 20px;
  align-items: center; }
  .manage-group-view-page .card-in-card .name {
    color: #3e3e3e; }

.manage-group-view-page .common-desktop-back-button {
  background: #ffffff; }

h1 {
  text-align: left; }

.manage-group-edit-page .btn-save {
  color: #007bff;
  background-color: transparent;
  box-shadow: none; }
  @media (min-width: 992px) {
    .manage-group-edit-page .btn-save {
      background-color: #007bff;
      color: #fff; } }

.manage-group-edit-page .btn-cancel {
  background-color: transparent;
  box-shadow: none; }

@media (min-width: 992px) {
  .manage-group-edit-page .form-control:active, .manage-group-edit-page .form-control:focus {
    border-color: #6e9fe0; } }

h1 {
  text-align: left; }

.manage-accounts-page .btn {
  font-size: 0.8rem; }

.manage-accounts-page .search {
  background: var(--pure-white);
  -webkit-appearance: none;
  height: 100%; }
  @media (max-width: 991.98px) {
    .manage-accounts-page .search {
      background: var(--pure-white); } }

.manage-accounts-page .btn-edit {
  color: #727272;
  background-color: #f4f4f5;
  min-height: auto;
  font-weight: bold;
  font-size: 0.8rem; }

.manage-accounts-page .common-search-bar {
  flex-grow: 1;
  margin-right: 1rem; }

@media (max-width: 991.98px) {
  .manage-accounts-page .common-custom-table {
    border-radius: 0px; } }

.desktop-page-secondary-header-wrapper {
  display: flex;
  flex-direction: column;
  grid-gap: 1.5rem;
  gap: 1.5rem;
  padding: 2rem 1.5rem; }
  .desktop-page-secondary-header-wrapper h1 {
    font-family: var(--sans-serif-font-family);
    color: var(--primary-dark); }
  .desktop-page-secondary-header-wrapper .add-account {
    background-color: var(--primary-dark);
    color: var(--primary-white);
    padding: 0.8rem 1.2rem;
    border-radius: 0.6rem;
    font-weight: 500;
    font-size: 0.875rem; }

.pagination-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 1rem; }
  .pagination-container .pagination {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    border-radius: 0.6rem;
    background-color: var(--primary-white); }
    .pagination-container .pagination li a {
      font-weight: 300; }
    .pagination-container .pagination .previous,
    .pagination-container .pagination .next {
      background-color: var(--primary-dark);
      padding: 0.8rem 1.2rem;
      border-radius: 0.6rem; }
      .pagination-container .pagination .previous a,
      .pagination-container .pagination .next a {
        color: var(--pure-white);
        font-weight: 500; }
    .pagination-container .pagination .active a {
      text-decoration: underline;
      font-weight: 500; }

h1 {
  text-align: left; }

.manage-roadmaps-page .status-active {
  display: block;
  background-color: #23d37b;
  border-radius: 10px;
  color: white;
  max-width: 80px;
  margin: auto;
  font-weight: 600; }

.manage-roadmaps-page .common-search-bar {
  flex-grow: 1;
  margin-right: 1rem; }

.manage-roadmaps-page .search {
  background: #f1f2f3;
  -webkit-appearance: none; }
  @media (max-width: 991.98px) {
    .manage-roadmaps-page .search {
      background: #e1e6eb; } }

.manage-roadmaps-page .btn-action {
  background-color: #f4f4f5;
  min-height: 30px; }
  .manage-roadmaps-page .btn-action svg {
    vertical-align: middle; }

@media (max-width: 991.98px) {
  .manage-roadmaps-page .common-custom-table {
    border-radius: 0px; } }

.desktop-page-container .common-custom-table th,
.desktop-page-container .common-custom-table td {
  padding-left: 15px;
  padding-right: 15px;
  font-size: 1rem; }

.desktop-page-container .common-custom-table a {
  font-weight: 600; }

h1 {
  text-align: left; }

.manage-add-roadmap-page .common-header button {
  background-color: transparent;
  box-shadow: none; }
  .manage-add-roadmap-page .common-header button:hover {
    opacity: 0.7; }

.manage-add-roadmap-page .common-header .btn-save {
  color: #007bff; }

h1 {
  text-align: left; }

.manage-edit-roadmap-page .stage-competency {
  background-color: white;
  border-radius: 10px;
  align-items: flex-start;
  display: flex;
  color: #3e3e3e;
  font-weight: normal;
  flex-direction: column; }
  .manage-edit-roadmap-page .stage-competency:hover {
    cursor: pointer;
    opacity: 1; }
  .manage-edit-roadmap-page .stage-competency .badge {
    color: #727272; }
  .manage-edit-roadmap-page .stage-competency ~ button {
    position: absolute;
    top: 0.5rem;
    right: 1rem; }

@media (max-width: 991.98px) {
  .manage-edit-roadmap-page > .container {
    padding-left: 0;
    padding-right: 0;
    max-width: none; } }

@media (min-width: 992px) {
  .manage-edit-roadmap-page > .container {
    padding-left: 2rem; } }

.manage-edit-roadmap-page .stage-item {
  border-bottom: 1px solid #cccccc; }
  .manage-edit-roadmap-page .stage-item.stage-item__dragging {
    border-bottom: none; }
  .manage-edit-roadmap-page .stage-item .stage-header-info {
    position: relative; }
    .manage-edit-roadmap-page .stage-item .stage-header-info .stage-scroll-to {
      height: 0px;
      position: absolute;
      top: -115px;
      left: 0;
      right: 0;
      z-index: -1; }
      @media (min-width: 992px) {
        .manage-edit-roadmap-page .stage-item .stage-header-info .stage-scroll-to {
          top: -170px; } }
  .manage-edit-roadmap-page .stage-item:hover {
    cursor: pointer; }
  @media (min-width: 992px) {
    .manage-edit-roadmap-page .stage-item {
      border-bottom: none; }
      .manage-edit-roadmap-page .stage-item .fa-caret-right,
      .manage-edit-roadmap-page .stage-item .fa-caret-down {
        width: 1rem; }
      .manage-edit-roadmap-page .stage-item .fa-grip-vertical {
        cursor: grab; } }
  .manage-edit-roadmap-page .stage-item > div:first-child {
    justify-content: space-between; }
    .manage-edit-roadmap-page .stage-item > div:first-child:hover {
      cursor: pointer; }
    .manage-edit-roadmap-page .stage-item > div:first-child > div {
      position: relative; }
      .manage-edit-roadmap-page .stage-item > div:first-child > div > svg:first-child {
        position: absolute;
        left: -1rem;
        top: 6px;
        font-size: 0.7rem;
        vertical-align: baseline; }
    @media (min-width: 992px) {
      .manage-edit-roadmap-page .stage-item > div:first-child {
        justify-content: flex-start; } }

.manage-edit-roadmap-page .stage-description-notes {
  font-weight: normal;
  color: #3e3e3e; }

.manage-edit-roadmap-page .coach-notes {
  color: #f1561e; }

.manage-edit-roadmap-page .add-stage-button:disabled {
  opacity: 0.25; }

h1 {
  text-align: left; }

.manage-add-stage-competency-page .btn-save {
  color: #007bff;
  background-color: transparent;
  box-shadow: none; }

.manage-add-stage-competency-page .btn-cancel {
  background-color: transparent;
  box-shadow: none; }

h1 {
  text-align: left; }

.manage-add-stage-page .btn-save {
  color: #007bff;
  background-color: transparent;
  box-shadow: none; }

.manage-add-stage-page .btn-cancel {
  background-color: transparent;
  box-shadow: none; }

h1 {
  text-align: left; }

.manage-rename-stage-page .btn-save {
  color: #007bff;
  background-color: transparent;
  box-shadow: none; }

.manage-rename-stage-page .btn-cancel {
  background-color: transparent;
  box-shadow: none; }

h1 {
  text-align: left; }

.manage-add-stage-description-page .btn-save {
  color: #007bff;
  background-color: transparent;
  box-shadow: none; }

.manage-add-stage-description-page .btn-cancel {
  background-color: transparent;
  box-shadow: none; }

@media (min-width: 992px) {
  .manage-add-stage-description-page .form-control {
    border-color: #cccccc; }
    .manage-add-stage-description-page .form-control:active, .manage-add-stage-description-page .form-control:focus {
      border-color: #6e9fe0; }
  .manage-add-stage-description-page .btn-cancel {
    background-color: #f4f4f5;
    box-shadow: none; }
  .manage-add-stage-description-page .btn-save {
    float: right;
    background: #2f80ed;
    color: #fff; } }

@media (min-width: 992px) {
  .manage-add-stage-description-page .normal-width-container {
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.05);
    padding-bottom: 1rem;
    padding-left: 4rem;
    padding-right: 4rem;
    background: white;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px; } }

h1 {
  text-align: left; }

.manage-edit-roadmap-details-page .common-header .btn-save {
  color: #007bff;
  background-color: transparent;
  box-shadow: none; }

.manage-edit-roadmap-details-page .common-header .btn-cancel {
  background-color: transparent;
  box-shadow: none; }

.manage-edit-roadmap-details-form .roadmap-icon {
  border-radius: 50%;
  width: 4em;
  height: 4em; }

.manage-edit-roadmap-details-form .invalid-icon {
  font-size: 1rem; }
  .manage-edit-roadmap-details-form .invalid-icon.is-invalid {
    color: #dc3545; }

.manage-edit-roadmap-details-modal .modal-content {
  min-height: 16rem; }

.manage-edit-roadmap-details-modal.loading .modal-content .modal-header {
  border-bottom: none; }

h1 {
  text-align: left; }

.manage-competency-page img {
  max-width: 300px; }

.manage-competency-page .attachment .delete svg {
  color: #3e3e3e;
  font-size: 1.25rem;
  position: relative;
  left: 5px; }

.manage-competency-page .rc-slider-rail {
  background: linear-gradient(to right, #f1561e, #f5d844, #9da16b);
  height: 1rem;
  padding: 0;
  border-radius: 10px; }

.manage-competency-page .rc-slider-handle {
  display: none; }

.manage-competency-page .card--embed {
  display: flex;
  flex-direction: column; }
  .manage-competency-page .card--embed figure {
    flex-grow: 1; }
    .manage-competency-page .card--embed figure > div {
      flex-grow: 1; }

.manage-competency-page .card.item {
  justify-content: space-between;
  flex-direction: initial; }

.manage-competency-page .card .assessment-border {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem; }
  .manage-competency-page .card .assessment-border.red {
    border: 5px solid #f1561e; }
  .manage-competency-page .card .assessment-border.yellow {
    border: 5px solid #f5d844; }
  .manage-competency-page .card .assessment-border.green {
    border: 5px solid #9da16b; }

.manage-competency-page .card .assessment-input {
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border: 0;
  width: 100%;
  outline: none; }
  .manage-competency-page .card .assessment-input--red:focus {
    box-shadow: 0 0 2px #f1561e; }
  .manage-competency-page .card .assessment-input--yellow:focus {
    box-shadow: 0 0 px #f5d844; }
  .manage-competency-page .card .assessment-input--green:focus {
    box-shadow: 0 0 2px #9da16b; }

@media (min-width: 992px) {
  .manage-competency-page .card {
    padding: 0;
    box-shadow: none; } }

.manage-competency-page .assessment-boxes-container {
  display: flex;
  flex-direction: column; }
  @media (min-width: 992px) {
    .manage-competency-page .assessment-boxes-container {
      flex-direction: row; }
      .manage-competency-page .assessment-boxes-container > div {
        flex-grow: 1;
        margin-left: 0.5rem;
        margin-right: 0.5rem; }
        .manage-competency-page .assessment-boxes-container > div:first-child {
          margin-left: 0; }
        .manage-competency-page .assessment-boxes-container > div:last-child {
          margin-right: 0; } }

.manage-competency-page .card.item {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.09);
  border-radius: 10px;
  border-bottom: none !important;
  padding: 0.75rem; }

.manage-competency-page .slider-container {
  margin-bottom: 0.75rem; }

.manage-competency-page .ais-questions-container .ai-question-container {
  border: none !important;
  position: relative; }
  .manage-competency-page .ais-questions-container .ai-question-container span {
    width: calc(100% - 3rem); }
  .manage-competency-page .ais-questions-container .ai-question-container svg {
    position: absolute;
    right: 12px;
    top: 10px;
    border-radius: 10px;
    padding: 5px;
    width: 42px;
    height: 36px;
    font-size: 6px;
    color: #727272; }
    .manage-competency-page .ais-questions-container .ai-question-container svg:hover {
      cursor: pointer;
      background-color: #f4f4f5; }

.manage-competency-page .ais-questions-container .card {
  cursor: pointer;
  font-weight: normal;
  color: #3e3e3e;
  line-height: 31px; }
  .manage-competency-page .ais-questions-container .card:hover {
    opacity: 1; }

.manage-competency-page .supplemental-info-content {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.09);
  border-radius: 10px;
  padding: 0.75rem; }
  .manage-competency-page .supplemental-info-content:hover {
    cursor: pointer; }

.manage-competency-page .intro-content .card--embed {
  background-color: white;
  border-radius: 10px;
  cursor: pointer; }

.manage-competency-page .coach-notes .card--embed {
  background-color: white;
  border-radius: 10px;
  cursor: pointer; }

.manage-competency-page .competency-title-clickable {
  border: 1px solid transparent;
  border-radius: 10px; }
  .manage-competency-page .competency-title-clickable:hover {
    border: 1px solid #ccc; }

@media (min-width: 992px) {
  .manage-competency-page .normal-width-container {
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.05);
    padding-bottom: 1rem;
    padding-left: 4rem;
    padding-right: 4rem;
    background: white;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px; }
  .manage-competency-page .assessment-slider-container {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.09);
    border-radius: 10px;
    margin-top: 1rem;
    padding: 1rem;
    background-color: var(--pure-white);
    border-radius: 10px; }
  .manage-competency-page .card.item .reorder {
    font-size: 1rem;
    margin-right: 4px; } }

.supplemental-dialog .modal-mobile-slide-from-bottom .modal-dialog {
  min-height: 100%; }

.supplemental-dialog .modal-dialog {
  margin: 0;
  margin: auto; }

.supplemental-dialog .modal-body {
  padding: 0; }

.supplemental-dialog .modal-dialog-centered {
  min-height: 100%; }

.supplemental-dialog .modal-content {
  border: none;
  border-radius: 0;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem; }
  @media (min-width: 768px) {
    .supplemental-dialog .modal-content {
      border-radius: 1rem; } }
  .supplemental-dialog .modal-content .icon-header {
    position: relative; }
    .supplemental-dialog .modal-content .icon-header svg {
      position: absolute; }

h1 {
  text-align: left; }

.manage-add-competency-intro-content-page .btn-save {
  color: #007bff;
  background-color: transparent;
  box-shadow: none; }

.manage-add-competency-intro-content-page .btn-cancel {
  background-color: transparent;
  box-shadow: none; }

.manage-add-competency-intro-content-page .ck-editor__editable {
  min-height: 12rem; }

h1 {
  text-align: left; }

.manage-rename-competency-page .btn-save {
  color: #007bff;
  background-color: transparent;
  box-shadow: none; }

.manage-rename-competency-page .btn-cancel {
  background-color: transparent;
  box-shadow: none; }

h1 {
  text-align: left; }

.manage-add-competency-action-item-page .btn-save {
  color: #007bff;
  background-color: transparent;
  box-shadow: none; }

.manage-add-competency-action-item-page .btn-cancel {
  background-color: transparent;
  box-shadow: none; }

.manage-add-competency-action-item-page .ck-editor__editable {
  min-height: 12rem; }

.manage-add-competency-action-item-page .ck-rounded-corners .ck.ck-editor__top .ck-sticky-panel .ck-toolbar {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px; }

.manage-add-competency-action-item-page .ck-rounded-corners .ck.ck-editor__main > .ck-editor__editable {
  min-height: 12rem; }
  .manage-add-competency-action-item-page .ck-rounded-corners .ck.ck-editor__main > .ck-editor__editable.ck-rounded-corners {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px; }

@media (min-width: 992px) {
  .manage-add-competency-action-item-page .form-control {
    border-color: #cccccc; }
    .manage-add-competency-action-item-page .form-control:active, .manage-add-competency-action-item-page .form-control:focus {
      border-color: #6e9fe0; }
  .manage-add-competency-action-item-page .btn-cancel {
    background-color: #f4f4f5;
    box-shadow: none; }
  .manage-add-competency-action-item-page .btn-save {
    float: right;
    background: #2f80ed;
    color: #fff; } }

@media (min-width: 992px) {
  .manage-add-competency-action-item-page .normal-width-container {
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.05);
    padding-bottom: 1rem;
    padding-left: 4rem;
    padding-right: 4rem;
    background: var(--pure-white);
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px; }
  .manage-add-competency-action-item-page .assessment-slider-container {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.09);
    border-radius: 10px;
    margin-top: 1rem;
    padding: 1rem;
    background-color: var(--pure-white);
    border-radius: 10px; }
  .manage-add-competency-action-item-page .card.item .reorder {
    font-size: 1rem;
    margin-right: 4px; } }

h1 {
  text-align: left; }

.manage-add-competency-global-questions-page .btn-save {
  color: #007bff;
  background-color: transparent;
  box-shadow: none; }

.manage-add-competency-global-questions-page .btn-cancel {
  background-color: transparent;
  box-shadow: none; }

@media (min-width: 992px) {
  .manage-add-competency-global-questions-page .form-control {
    border-color: #cccccc; }
    .manage-add-competency-global-questions-page .form-control:active, .manage-add-competency-global-questions-page .form-control:focus {
      border-color: #6e9fe0; }
  .manage-add-competency-global-questions-page .btn-cancel {
    background-color: #f4f4f5;
    box-shadow: none; }
  .manage-add-competency-global-questions-page .btn-save {
    float: right;
    background: #2f80ed;
    color: #fff; } }

@media (min-width: 992px) {
  .manage-add-competency-global-questions-page .normal-width-container {
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.05);
    padding-bottom: 1rem;
    padding-left: 4rem;
    padding-right: 4rem;
    background: white;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px; } }

h1 {
  text-align: left; }

.manage-add-competency-supplemental-content-page .btn-save {
  color: #007bff;
  background-color: transparent;
  box-shadow: none; }

.manage-add-competency-supplemental-content-page .btn-cancel {
  background-color: transparent;
  box-shadow: none; }

.manage-add-competency-supplemental-content-page .ck-editor__editable {
  min-height: 12rem; }

h1 {
  text-align: left; }

h1 {
  text-align: left; }

.manage-add-competency-supplemental-content .ck-rounded-corners .ck.ck-editor__top .ck-sticky-panel .ck-toolbar {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px; }

.manage-add-competency-supplemental-content .ck-rounded-corners .ck.ck-editor__main > .ck-editor__editable {
  min-height: 12rem; }
  .manage-add-competency-supplemental-content .ck-rounded-corners .ck.ck-editor__main > .ck-editor__editable.ck-rounded-corners {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px; }

h1 {
  text-align: left; }

.manage-add-competency-intro-content .ck-rounded-corners .ck.ck-editor__top .ck-sticky-panel .ck-toolbar {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px; }

.manage-add-competency-intro-content .ck-rounded-corners .ck.ck-editor__main > .ck-editor__editable {
  min-height: 12rem; }
  .manage-add-competency-intro-content .ck-rounded-corners .ck.ck-editor__main > .ck-editor__editable.ck-rounded-corners {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px; }

h1 {
  text-align: left; }

.manage-rename-competency {
  padding-top: 0.4rem;
  padding-bottom: 0.4rem; }
  .manage-rename-competency .competency-name-input {
    flex: 1 1 auto;
    width: 1%;
    min-width: 0; }

h1 {
  text-align: left; }

.manage-rename-stage {
  flex: 0.5 1; }
  .manage-rename-stage .stage-name-input {
    flex: 1 1 auto;
    width: 1%;
    min-width: 0; }

.cancel-client-subscription-modal button {
  outline: none; }

.cancel-client-subscription-modal .modal-header {
  padding: 2rem 2rem 0 2rem; }

.cancel-client-subscription-modal .modal-title {
  font-size: 1.5rem;
  font-family: var(--serif-font-family);
  font-weight: 700; }

.cancel-client-subscription-modal .modal-body {
  padding: 2rem 2rem; }
  .cancel-client-subscription-modal .modal-body p {
    font-weight: 300;
    text-align: center; }

.cancel-client-subscription-modal .cancel {
  background-color: var(--primary-dark);
  color: var(--primary-white);
  border-radius: 0.6rem;
  padding: 0.7rem 1.5rem;
  max-width: -webkit-max-content;
  max-width: max-content;
  margin: 0 auto; }

.cancel-client-subscription-modal .go-back {
  margin: 0 auto;
  font-weight: 300;
  font-size: 0.875rem;
  text-decoration: underline;
  margin-top: 1rem;
  padding-bottom: 2rem; }

h1 {
  text-align: left; }

h1 {
  text-align: left; }

.support-sidebar {
  width: 15.75rem;
  border-radius: 0.6rem;
  display: flex;
  flex-direction: column;
  height: 80vh;
  background-color: var(--primary-dark);
  padding: 1.5rem 1.7rem;
  position: -webkit-sticky;
  position: sticky;
  top: 10vh; }
  .support-sidebar .support-sidebar-header p {
    color: var(--primary-white);
    font-size: 0.875rem; }
  .support-sidebar .support-sidebar-header h1 {
    text-align: left;
    color: var(--primary-white);
    font-size: 1.5rem; }
  .support-sidebar .support-sidebar-body {
    padding-top: 1.5rem; }
    .support-sidebar .support-sidebar-body .support-sidebar-body-steps {
      display: flex;
      flex-direction: column;
      grid-gap: 1.75rem;
      gap: 1.75rem; }
  .support-sidebar .resend-sub-btn {
    padding: 0.5rem 1rem;
    background-color: var(--pure-white);
    border-radius: 0.4rem;
    margin-top: 2rem;
    max-width: -webkit-max-content;
    max-width: max-content; }
    .support-sidebar .resend-sub-btn p {
      color: var(--primary-dark);
      font-size: 0.8rem; }

h1 {
  text-align: left; }

.support-dashboard {
  width: 100%;
  display: grid;
  justify-content: flex-center;
  justify-items: center;
  padding-top: 2rem;
  max-height: 100vh;
  overflow-y: scroll; }
  .support-dashboard .support-dashboard-body {
    display: flex;
    grid-gap: 1.5rem;
    gap: 1.5rem; }

.support-sidebar-body-step {
  display: flex;
  align-items: center;
  grid-gap: 0.5rem;
  gap: 0.5rem; }
  .support-sidebar-body-step .step-number {
    border-radius: 50%;
    width: 1.5rem;
    height: 1.5rem;
    background-color: var(--primary-white);
    display: flex;
    justify-content: center;
    align-items: center; }
    .support-sidebar-body-step .step-number p {
      color: var(--primary-dark);
      font-size: 0.875rem; }
    .support-sidebar-body-step .step-number.done {
      background-color: var(--primary-light-green); }
    .support-sidebar-body-step .step-number.inactive {
      background-color: var(--primary-bg-gray); }
  .support-sidebar-body-step .step-description {
    color: var(--primary-white);
    font-size: 0.8rem;
    position: relative;
    font-weight: 300; }
    .support-sidebar-body-step .step-description.active {
      font-weight: 600; }
      .support-sidebar-body-step .step-description.active:before {
        content: '';
        position: absolute;
        width: 4.375rem;
        height: 4px;
        bottom: 0;
        top: 1.3rem;
        border-bottom: 1px solid var(--primary-white); }

h1 {
  text-align: left; }

.support-form-container {
  width: 48rem;
  background-color: var(--pure-white);
  border-radius: 0.6rem;
  padding: 0 2.75rem 1.5rem 2.75rem;
  display: flex;
  flex-direction: column;
  overflow-y: auto; }
  .support-form-container form {
    padding-top: 2rem;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 1rem;
    gap: 1rem;
    grid-column-gap: 2.5rem;
    -webkit-column-gap: 2.5rem;
            column-gap: 2.5rem;
    align-items: flex-start; }
    .support-form-container form .support-submit-form-btn {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 0.7rem;
      background-color: var(--primary-dark);
      border-radius: 0.6rem;
      max-width: -webkit-max-content;
      max-width: max-content;
      color: var(--primary-white);
      font-size: 0.875rem;
      justify-self: end;
      grid-column: span 2; }
    .support-form-container form .error {
      color: var(--primary-red);
      font-size: 0.7rem;
      margin-top: 0.5rem; }
  .support-form-container .subform-header {
    grid-column: span 2;
    display: flex;
    font-size: 0.875rem;
    font-weight: 500; }

h1 {
  text-align: left; }

.support-step-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  grid-column: span 2;
  margin-top: 1rem; }
  .support-step-header-container .step-and-title {
    display: flex;
    align-items: center;
    grid-gap: 1rem;
    gap: 1rem; }
    .support-step-header-container .step-and-title .step-number {
      font-size: 1rem;
      height: 1.5rem;
      width: 1.5rem;
      border-radius: 50%;
      background-color: var(--primary-dark);
      display: flex;
      align-items: center;
      justify-content: center; }
      .support-step-header-container .step-and-title .step-number p {
        color: var(--primary-white); }
  .support-step-header-container .edit {
    font-size: 0.875rem;
    cursor: pointer;
    outline: none; }

.support-step-divider {
  width: 95%;
  height: 1px;
  background-color: var(--primary-gray-lighter);
  grid-column: span 2;
  margin: 1rem 0 1rem 5%; }

h1 {
  text-align: left; }

.support-input-container {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%; }
  .support-input-container .support-input {
    min-height: 2.35rem;
    width: 100%;
    padding-left: 2.5rem;
    padding-right: 1rem;
    background-color: var(--pure-white);
    border: 1px solid var(--primary-light-gray);
    border-radius: 0.4rem;
    align-items: center; }
    .support-input-container .support-input::-webkit-input-placeholder {
      font-size: 0.875rem;
      font-weight: 300; }
    .support-input-container .support-input::placeholder {
      font-size: 0.875rem;
      font-weight: 300; }
  .support-input-container .custom-icon {
    position: absolute;
    left: 1rem; }

.support-client-info-extra-dropdown {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.5rem;
  background-color: var(--primary-bg-white);
  grid-column: span 2;
  position: relative;
  margin-top: 1rem; }
  .support-client-info-extra-dropdown .triangle-up {
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid var(--primary-bg-white);
    position: absolute;
    right: 0.5rem;
    top: -10px; }
  .support-client-info-extra-dropdown .support-input-container {
    max-width: 18.6rem;
    align-items: center; }
    .support-client-info-extra-dropdown .support-input-container .error {
      margin-left: -15rem; }
  .support-client-info-extra-dropdown .extra-dropdown-select {
    margin: 0 auto; }

h1 {
  text-align: left; }

.support-assign-coach-form {
  grid-column: span 2; }
  .support-assign-coach-form .error {
    color: var(--primary-red);
    font-size: 0.7rem;
    margin-top: 0.5rem; }

h1 {
  text-align: left; }

.support-subscription-radios {
  display: flex;
  align-items: center;
  grid-gap: 2rem;
  gap: 2rem;
  grid-template-columns: repeat(2, 1fr);
  grid-column: span 2; }

.subscriptions-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column: span 2;
  grid-gap: 2rem;
  gap: 2rem;
  padding-bottom: 1.5rem; }

.switch-contract-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: -webkit-max-content;
  width: max-content;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  font-size: 0.8rem;
  background-color: var(--primary-dark);
  color: var(--pure-white); }

.support-subscription-trial-date {
  display: flex;
  flex-direction: column; }
  .support-subscription-trial-date label {
    font-size: 0.8rem;
    font-weight: 400;
    color: var(--primary-dark);
    opacity: 0.4; }
  .support-subscription-trial-date input {
    font-size: 0.875rem;
    font-weight: 400;
    border-radius: 0.4rem;
    border: 1px solid var(--primary-light-gray);
    padding: 0.5rem 0.8rem;
    color: var(--primary-gray); }

.contracts-header {
  grid-column: span 2;
  width: 100%;
  display: flex;
  padding: 1rem;
  font-size: 0.7rem;
  background-color: var(--primary-white);
  border-radius: 0.6rem;
  color: var(--primary-dark);
  opacity: 0.5; }
  .contracts-header strong {
    padding-right: 0.5rem; }

.text-message-or-email-wrapper {
  grid-column: span 2;
  display: flex;
  align-items: center;
  grid-gap: 3rem;
  gap: 3rem; }

h1 {
  text-align: left; }

.custom-select-input-container {
  display: flex;
  flex-direction: column;
  width: 100%; }
  .custom-select-input-container .error {
    color: var(--primary-red);
    font-size: 0.7rem;
    margin-top: 0.5rem; }

.custom-select-input-container.coaching-group {
  align-items: center; }

h1 {
  text-align: left; }

.custom-text-input-container {
  display: flex;
  flex-direction: column;
  width: 100%; }
  .custom-text-input-container input {
    font-size: 0.875rem;
    font-weight: 300; }
  .custom-text-input-container .error {
    color: var(--primary-red);
    font-size: 0.7rem;
    margin-top: 0.5rem; }
  .custom-text-input-container .message {
    color: #2f80ed;
    font-size: 0.7rem;
    margin-top: 0.5rem; }

h1 {
  text-align: left; }

.support-radio-container {
  display: inline-flex;
  align-items: center;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  margin-top: 1rem;
  margin-bottom: 1rem; }
  .support-radio-container .radio-icon {
    cursor: pointer; }
  .support-radio-container p {
    font-size: 0.875rem;
    font-weight: 500;
    min-width: 100%; }

h1 {
  text-align: left; }

.support-subscription-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 0.8rem;
  border: 1px solid var(--primary-gray-lighter);
  height: 3.78rem;
  padding: 0 1rem;
  width: 18rem; }
  .support-subscription-container p {
    font-size: 0.875rem; }
  .support-subscription-container .title-container {
    display: flex;
    align-items: center;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    max-width: 65%; }
    .support-subscription-container .title-container svg {
      min-height: 1rem;
      min-width: 1rem;
      max-height: 1rem;
      max-width: 1rem; }
    .support-subscription-container .title-container p {
      font-weight: 300; }
    .support-subscription-container .title-container .end-date {
      font-size: 0.6rem; }
  .support-subscription-container .price {
    font-weight: 600;
    font-size: 0.875rem; }

h1 {
  text-align: left; }

.support-dashboard-review {
  display: flex;
  flex-direction: column;
  padding-left: 10%;
  width: 100%; }
  .support-dashboard-review .title {
    font-weight: 300; }
  .support-dashboard-review .info-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 4.75rem;
    -webkit-column-gap: 4.75rem;
            column-gap: 4.75rem;
    grid-row-gap: 1.5rem;
    row-gap: 1.5rem;
    margin-top: 1.5rem; }
    .support-dashboard-review .info-grid p {
      font-weight: 500; }
    .support-dashboard-review .info-grid .title {
      font-weight: 300;
      min-width: -webkit-max-content;
      min-width: max-content; }
    .support-dashboard-review .info-grid .checkbox-wrapper {
      display: flex;
      align-items: center;
      grid-gap: 2rem;
      gap: 2rem; }
  .support-dashboard-review .inner-col {
    grid-gap: 1rem;
    gap: 1rem;
    display: flex;
    flex-direction: column; }
  .support-dashboard-review .subscription {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1rem;
    width: 100%;
    grid-column: span 2;
    padding-left: 5%; }

.support-divider {
  width: 95%;
  height: 1px;
  background-color: var(--primary-gray-lighter);
  grid-column: span 2;
  margin: 1rem 0 1rem 5%; }

.span-2 {
  grid-column: span 2; }

h1 {
  text-align: left; }

.completed-form-page .completed-status-grid {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem; }
  .completed-form-page .completed-status-grid .completed-item {
    display: flex;
    align-items: center;
    grid-gap: 1rem;
    gap: 1rem; }

.completed-form-page button,
.completed-form-page .create-new-client {
  width: 100%;
  background-color: var(--primary-dark);
  color: var(--primary-white);
  border-radius: 0.6rem;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  padding: 0.5rem 1rem;
  font-size: 0.7rem; }

.completed-form-page .create-new-client {
  margin-top: 2rem;
  font-size: 0.875rem; }

.resend-subscription-modal .modal-body {
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem; }
  .resend-subscription-modal .modal-body .resend-sub-btn {
    padding: 0.5rem 1rem;
    background-color: var(--primary-dark);
    border-radius: 0.4rem;
    max-width: -webkit-max-content;
    max-width: max-content; }
    .resend-subscription-modal .modal-body .resend-sub-btn p {
      color: var(--pure-white);
      font-size: 0.8rem; }

.resend-subscription-modal .calendar-input {
  width: 100%;
  padding: 1rem;
  border: '1px solid $color-gray';
  color: '$color-gray'; }

.resend-subscription-modal .error-message {
  background-color: var(--primary-red);
  color: white;
  padding: 0.5rem;
  border-radius: 5px; }

h1 {
  text-align: left; }

h1 {
  text-align: left; }

.subscriptions-internal-subscriptions.wrapper {
  display: grid;
  grid-gap: 1.8rem;
  gap: 1.8rem;
  grid-template-areas: 'sidebar' 'content'; }

@media (min-width: 992px) {
  .subscriptions-internal-subscriptions.wrapper {
    grid-template-columns: 1fr 3fr;
    grid-template-areas: 'sidebar content'; } }

h1 {
  text-align: left; }

.subscriptions-contracts.wrapper {
  display: grid;
  grid-gap: 1.8rem;
  gap: 1.8rem;
  grid-template-areas: 'sidebar' 'content'; }

@media (min-width: 992px) {
  .subscriptions-contracts.wrapper {
    grid-template-columns: 1fr 3fr;
    grid-template-areas: 'sidebar content'; } }

h1 {
  text-align: left; }

.subscriptions-internal-subscriptions-main-content {
  grid-area: content;
  background-color: var(--primary-white);
  padding: 2rem;
  border-radius: 0.8rem; }
  .subscriptions-internal-subscriptions-main-content table {
    width: 100%;
    background-color: white;
    box-shadow: 1px 1px 20px 1px rgba(0, 0, 0, 0.05);
    border-radius: 0.6rem;
    margin-top: 2rem;
    display: table; }
    .subscriptions-internal-subscriptions-main-content table td {
      vertical-align: center;
      padding: 1.5rem 1.5rem; }
    .subscriptions-internal-subscriptions-main-content table th {
      padding: 1rem 0rem 1rem 1.5rem;
      font-weight: 300;
      font-size: 0.8rem;
      color: rgba(52, 52, 52, 0.5); }
    .subscriptions-internal-subscriptions-main-content table tr {
      border-bottom: 1px solid rgba(52, 52, 52, 0.1); }
    .subscriptions-internal-subscriptions-main-content table tbody {
      font-size: 0.87rem;
      font-weight: 300; }
  .subscriptions-internal-subscriptions-main-content .main-header {
    display: flex;
    justify-content: space-between; }
    .subscriptions-internal-subscriptions-main-content .main-header button {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.6rem;
      grid-gap: 0.5rem;
      grid-gap: 0.5rem;
      gap: 0.5rem;
      background-color: var(--primary-dark);
      color: var(--pure-white);
      font-size: 0.7rem;
      border-radius: 0.4rem; }
  .subscriptions-internal-subscriptions-main-content tbody {
    height: calc(90vh - 310px);
    table-layout: fixed;
    display: block;
    overflow-y: auto;
    overflow-x: hidden; }
  .subscriptions-internal-subscriptions-main-content thead,
  .subscriptions-internal-subscriptions-main-content tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed; }

h1 {
  text-align: left; }

.subscriptions-internal-subscriptions-sidebar {
  grid-area: sidebar; }

.subscriptions-internal-subscriptions-modal .modal-header {
  align-items: baseline; }

.subscriptions-internal-subscriptions-modal .modal-body form {
  display: flex;
  flex-direction: column; }
  .subscriptions-internal-subscriptions-modal .modal-body form label {
    font-size: 0.875rem;
    color: var(--primary-gray);
    padding-bottom: 0.5rem; }
  .subscriptions-internal-subscriptions-modal .modal-body form input {
    padding-left: 1rem; }

.subscriptions-internal-subscriptions-modal .modal-body .error-message {
  background-color: var(--primary-red);
  color: white;
  padding: 0.5rem;
  border-radius: 5px; }

.contracts-modal .modal-header {
  align-items: baseline; }

.contracts-modal .modal-body form {
  display: flex;
  flex-direction: column; }
  .contracts-modal .modal-body form label {
    font-size: 0.875rem;
    color: var(--primary-gray);
    padding-bottom: 0.5rem; }
  .contracts-modal .modal-body form input {
    padding-left: 1rem;
    padding-right: 1rem; }

.contracts-modal .modal-body .error-message {
  background-color: var(--primary-red);
  color: white;
  padding: 0.5rem;
  border-radius: 5px; }

h1 {
  text-align: left; }

.subscriptions-default {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  min-height: 100vh;
  background-color: #fdfdfd; }
  .subscriptions-default .subscriptions-default-nav {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--pure-white);
    padding: 1.25rem 7.5%;
    z-index: 1;
    position: relative;
    z-index: 1; }
    .subscriptions-default .subscriptions-default-nav img {
      width: 7.125rem;
      object-fit: contain; }
    @media (max-width: 991.98px) {
      .subscriptions-default .subscriptions-default-nav {
        flex-direction: column;
        align-items: center;
        grid-gap: 2rem;
        gap: 2rem; } }
    .subscriptions-default .subscriptions-default-nav img {
      width: 6.25rem; }
    .subscriptions-default .subscriptions-default-nav .log-out {
      position: absolute;
      top: 2rem;
      right: 1.5rem;
      font-size: 0.875rem;
      cursor: pointer;
      opacity: 0.6; }
      .subscriptions-default .subscriptions-default-nav .log-out:hover {
        opacity: 1; }
  .subscriptions-default .content-container {
    display: grid;
    background-size: cover;
    position: relative;
    min-height: 100vh;
    height: 100%;
    padding: 2rem 0;
    margin: 0 auto;
    place-items: center;
    background-repeat: no-repeat;
    width: 100%;
    background-position-x: right;
    object-fit: cover; }
    @media (min-width: 992px) {
      .subscriptions-default .content-container {
        background-size: contain;
        grid-template-columns: 1fr 1fr; } }
    .subscriptions-default .content-container video,
    .subscriptions-default .content-container .background-image {
      position: absolute;
      top: 0;
      z-index: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      background-position: contain;
      -webkit-transform: scaleX(-1);
              transform: scaleX(-1); }
      @media (max-width: 767.98px) {
        .subscriptions-default .content-container video,
        .subscriptions-default .content-container .background-image {
          -webkit-filter: blur(6px);
                  filter: blur(6px); } }
    .subscriptions-default .content-container .background-image {
      -webkit-transform: scaleX(1);
              transform: scaleX(1); }
  .subscriptions-default .payments-container {
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--pure-white);
    max-width: 31.25rem;
    padding: 2.5rem 5rem;
    min-width: 40vw;
    margin-left: 15vh;
    max-height: -webkit-max-content;
    max-height: max-content;
    height: -webkit-max-content;
    height: max-content;
    box-shadow: 1px -5px 18px 5px rgba(0, 0, 0, 0.04);
    border-radius: 1.4rem;
    grid-gap: 1rem;
    gap: 1rem; }
    .subscriptions-default .payments-container .title {
      font-size: 2rem;
      font-weight: 700;
      text-align: center; }
    .subscriptions-default .payments-container .description {
      text-align: center;
      width: 100%;
      font-weight: 300;
      font-size: 0.875rem; }
    .subscriptions-default .payments-container .product-name-and-price {
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding-bottom: 1rem;
      border-bottom: 0.5px solid var(--primary-gray-lightest);
      align-items: center; }
      .subscriptions-default .payments-container .product-name-and-price .product {
        font-weight: 300;
        font-size: 0.875rem;
        text-align: left; }
    .subscriptions-default .payments-container .credit-cards {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%; }
      .subscriptions-default .payments-container .credit-cards .brands {
        display: flex;
        align-items: center;
        grid-gap: 0.4rem;
        gap: 0.4rem; }
    .subscriptions-default .payments-container form {
      display: flex;
      flex-direction: column;
      grid-gap: 1rem;
      gap: 1rem;
      padding: 0;
      width: 100%; }
      .subscriptions-default .payments-container form input {
        width: 100%;
        padding: 0.5rem;
        font-size: 0.875rem;
        border: 1px solid #eaeaee;
        -webkit-user-select: none;
                user-select: none;
        border-radius: 0.25rem 0.25rem 0 0; }
      .subscriptions-default .payments-container form .pre-checkout-price {
        display: flex;
        align-items: center;
        justify-content: space-between; }
        .subscriptions-default .payments-container form .pre-checkout-price .check-description {
          font-size: 0.8rem;
          font-weight: 300;
          max-width: 50%; }
        .subscriptions-default .payments-container form .pre-checkout-price .paying-now p {
          font-size: 0.7rem; }
        .subscriptions-default .payments-container form .pre-checkout-price .paying-now h3 {
          font-size: 1.5rem;
          font-weight: 600; }
      .subscriptions-default .payments-container form button {
        padding: 0.5rem;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0.6rem;
        color: var(--pure-white);
        font-weight: 600; }
    .subscriptions-default .payments-container .bill-grid {
      border-bottom: 0.5px solid var(--primary-gray-lightest); }
      .subscriptions-default .payments-container .bill-grid p {
        font-weight: 600;
        font-size: 0.875rem; }
      .subscriptions-default .payments-container .bill-grid .title {
        font-weight: 300; }
    .subscriptions-default .payments-container .terms {
      font-weight: 300;
      font-size: 0.875rem;
      text-decoration: underline;
      color: var(--primary-dark);
      text-align: left;
      margin-top: 1rem; }
    .subscriptions-default .payments-container .go-to-dashboard {
      padding: 0.5rem;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0.35rem;
      color: var(--pure-white);
      font-weight: 600;
      margin-top: 1rem; }
  .subscriptions-default #payment-message {
    color: var(--primary-red);
    font-size: 0.7rem;
    margin: 0.5rem 0;
    font-weight: 700; }
  .subscriptions-default .email-input {
    position: relative; }
    .subscriptions-default .email-input svg {
      position: absolute;
      top: 0.95rem;
      left: 0.75rem; }
    .subscriptions-default .email-input .form-input {
      padding-left: 2.5rem; }
      .subscriptions-default .email-input .form-input:placeholder {
        padding-left: 2rem; }
  @media (max-width: 767.98px) {
    .subscriptions-default .payments-container {
      width: 85vw;
      margin-left: 0;
      padding: 1.5rem 2rem;
      height: auto; }
      .subscriptions-default .payments-container .title {
        font-size: 1.125rem; }
      .subscriptions-default .payments-container .description {
        font-size: 0.875rem; }
      .subscriptions-default .payments-container .product-name-and-price .product,
      .subscriptions-default .payments-container .product-name-and-price .price {
        font-size: 0.875rem; }
      .subscriptions-default .payments-container .credit-cards h3 {
        font-size: 0.875rem; }
      .subscriptions-default .payments-container form .pre-checkout-price .check-description {
        font-size: 0.5rem; }
      .subscriptions-default .payments-container form .pre-checkout-price .paying-now p {
        font-size: 0.5rem; }
      .subscriptions-default .payments-container form .pre-checkout-price .paying-now h3 {
        font-size: 1.25rem; }
      .subscriptions-default .payments-container form button {
        font-size: 0.8rem; }
      .subscriptions-default .payments-container .description,
      .subscriptions-default .payments-container .product-name-and-price h3,
      .subscriptions-default .payments-container .bill-grid .title,
      .subscriptions-default .payments-container .bill-grid p,
      .subscriptions-default .payments-container .go-to-dashboard,
      .subscriptions-default .payments-container .terms {
        font-size: 0.75rem; } }

h1 {
  text-align: left; }

h1 {
  text-align: left; }

.support-group-calls {
  display: flex;
  flex-direction: column;
  padding: 2rem 1.5rem;
  background-color: var(--primary-white);
  border-radius: 0.8rem; }
  @media (max-width: 767.98px) {
    .support-group-calls {
      background-color: transparent; } }
  .support-group-calls .container-header {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .support-group-calls .container-header h2 {
      font-weight: 200;
      font-size: 1rem; }
    .support-group-calls .container-header .plus-btn {
      background-color: var(--primary-dark);
      border-radius: 0.4rem;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 1.625rem;
      height: 1.625rem; }
    .support-group-calls .container-header span {
      font-weight: bolder;
      color: var(--primary-dark); }
  .support-group-calls .no-calls {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    grid-gap: 1rem;
    gap: 1rem;
    padding: 1rem 0; }
    .support-group-calls .no-calls p {
      color: var(--primary-gray-lightest);
      font-weight: 300; }
    .support-group-calls .no-calls .icon-container {
      background-color: var(--primary-bg-white);
      max-width: 3rem;
      max-height: 3rem;
      min-width: 3rem;
      min-height: 3rem;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%; }

.group-calls-list {
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem;
  padding: 1rem 0; }

.drop-in-card {
  background-color: var(--pure-white);
  display: flex;
  border-radius: 0.5rem;
  grid-gap: 1rem;
  gap: 1rem;
  height: auto;
  align-items: center;
  position: relative;
  width: 100%;
  justify-content: space-between;
  padding-right: 1.3rem;
  box-shadow: 0px 4px 20px 1px rgba(0, 0, 0, 0.1); }
  .drop-in-card:hover {
    -webkit-transform: scale(1.01);
            transform: scale(1.01);
    transition: all 0.2s ease-in-out; }
  .drop-in-card .icon-wrapper {
    background-color: var(--primary-dark);
    height: 100%;
    display: flex;
    padding: 0 0.8rem;
    border-radius: 0.5rem 0 0 0.5rem;
    position: absolute;
    align-items: center; }
  .drop-in-card a:hover {
    opacity: 1; }
  .drop-in-card .icon-container {
    background-color: #4a4949;
    width: 0.8rem;
    height: 0.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    max-width: 3rem;
    max-height: 3rem;
    min-width: 3rem;
    min-height: 3rem; }
  .drop-in-card .text-container {
    display: flex;
    flex-direction: column;
    padding: 1rem 0;
    width: 100%;
    padding-left: 5.5rem;
    grid-gap: 0.2rem;
    gap: 0.2rem; }
    .drop-in-card .text-container h3 {
      font-weight: 300;
      font-size: 1rem; }
    .drop-in-card .text-container p {
      font-weight: 200;
      color: #bdc0c3;
      font-size: 0.7rem; }
  .drop-in-card .dropdown-menu {
    border-radius: 0.5rem;
    margin-top: -1rem;
    position: absolute;
    border: 1px solid var(--primary-white);
    box-shadow: 1px 1px 20px 1px rgba(0, 0, 0, 0.05);
    margin-left: -10rem;
    position: relative;
    border-radius: 0.8rem; }
    .drop-in-card .dropdown-menu .dropdown-item {
      width: 100%;
      font-size: 0.7rem;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      color: var(--primary-dark); }
      .drop-in-card .dropdown-menu .dropdown-item:active {
        background-color: transparent;
        color: var(--primary-dark); }
    .drop-in-card .dropdown-menu .delete-btn {
      color: var(--primary-red);
      font-weight: 600; }

.calls-modal .modal-header {
  align-items: center;
  padding: 1.5rem;
  padding-bottom: 0; }
  .calls-modal .modal-header p {
    cursor: pointer; }

.calls-modal label {
  font-size: 0.875rem;
  font-weight: 300;
  padding-bottom: 0.5rem;
  color: var(--primary-gray); }

.calls-modal input {
  height: 3rem;
  padding: 1rem;
  font-size: 0.875rem; }

.calls-modal svg {
  margin-right: 1rem; }

.calls-modal .form-control {
  padding-top: 0.7rem;
  height: 3rem;
  font-size: 0.875rem; }

.calls-modal .error-message {
  background-color: var(--primary-red);
  color: white;
  padding: 0.5rem;
  border-radius: 5px; }

.calls-modal .modal-body {
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem;
  padding: 1.5rem;
  padding-bottom: 2rem; }

.calls-modal .delete-call-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  padding: 1rem;
  background-color: var(--primary-dark);
  color: var(--primary-white);
  font-size: 0.8rem;
  border-radius: 0.6rem;
  cursor: pointer; }

.delete-call-modal .modal-header {
  padding-top: 2rem; }
  .delete-call-modal .modal-header p {
    cursor: pointer; }

.delete-call-modal .modal-body {
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem;
  text-align: center;
  padding-bottom: 4rem; }
  .delete-call-modal .modal-body p {
    text-align: center;
    color: var(--primary-alerts);
    font-weight: 500; }

h1 {
  text-align: left; }

h1 {
  text-align: left; }

.auth-home {
  display: flex;
  flex-direction: column;
  padding: 0 7.5%;
  width: 100%;
  max-width: 95.375rem;
  margin: 0 auto;
  height: 100vh;
  max-height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: white; }
  .auth-home .login-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.75rem 3.75rem;
    color: var(--pure-white);
    font-weight: 700;
    font-size: 1rem;
    border-radius: 0.3125rem; }
    .auth-home .login-btn:hover, .auth-home .login-btn:focus {
      opacity: 1; }
  .auth-home .navbar {
    display: flex;
    align-items: center;
    width: 100%;
    margin: 0 auto;
    padding: 0.95rem 0;
    border-bottom: 0.5px solid var(--primary-gray-lightest); }
    .auth-home .navbar .right-container {
      display: flex;
      align-items: center;
      grid-gap: 1.75rem;
      gap: 1.75rem; }
      .auth-home .navbar .right-container .questions {
        display: flex;
        flex-direction: column;
        align-items: flex-end; }
        .auth-home .navbar .right-container .questions p {
          font-size: 0.7rem;
          color: var(--primary-dark);
          opacity: 0.5;
          font-weight: 500; }
        .auth-home .navbar .right-container .questions .schedule {
          text-decoration: underline; }
      @media (max-width: 767.98px) {
        .auth-home .navbar .right-container {
          flex-direction: column-reverse;
          align-items: flex-end;
          grid-gap: 0.4rem;
          gap: 0.4rem; }
          .auth-home .navbar .right-container .login-btn {
            padding: 3px;
            width: 6.25rem; } }
  .auth-home .content {
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
    grid-gap: 1.5rem;
    gap: 1.5rem; }
    @media (min-width: 992px) {
      .auth-home .content {
        margin-top: 5vh;
        justify-content: space-between;
        flex-direction: row;
        grid-gap: 0;
        gap: 0; } }
    .auth-home .content .left-side {
      display: flex;
      flex-direction: column;
      grid-gap: 2rem;
      gap: 2rem;
      width: 85%; }
      .auth-home .content .left-side h1 {
        font-size: 3rem;
        line-height: 3.5rem;
        font-weight: 300;
        font-family: var(--sans-serif-font-family); }
      .auth-home .content .left-side .description {
        font-weight: 300;
        width: 75%; }
    .auth-home .content img {
      width: 100%;
      height: 80vh;
      object-fit: contain; }
      @media (max-width: 767.98px) {
        .auth-home .content img {
          width: 100%;
          object-fit: contain;
          height: -webkit-max-content;
          height: max-content;
          padding-bottom: 1rem; } }
    @media (max-width: 767.98px) {
      .auth-home .content .left-side {
        align-items: center;
        width: 100%; }
        .auth-home .content .left-side h1 {
          font-size: 1.5rem;
          text-align: center;
          line-height: 30px;
          font-weight: 300;
          width: 80%; }
        .auth-home .content .left-side .description {
          text-align: center; } }

h1 {
  text-align: left; }

.auth-signup {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  margin: 0 auto;
  overflow-y: auto;
  align-items: center; }
  @media (max-width: 767.98px) {
    .auth-signup {
      height: 100%; } }
  .auth-signup .auth-signup-navbar {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--pure-white);
    padding: 1.25rem 7.5%;
    z-index: 1; }
    .auth-signup .auth-signup-navbar img {
      width: 7.125rem;
      object-fit: contain; }
    @media (max-width: 991.98px) {
      .auth-signup .auth-signup-navbar {
        display: grid;
        grid-template-columns: 1fr;
        padding: 0;
        place-content: center; } }
    .auth-signup .auth-signup-navbar__logo-container {
      display: flex;
      align-items: center;
      justify-content: center; }
      @media (max-width: 991.98px) {
        .auth-signup .auth-signup-navbar__logo-container {
          height: 65px;
          box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
          width: 100%; } }
  .auth-signup .content-container {
    position: relative;
    object-fit: cover;
    background-repeat: no-repeat;
    width: 100%;
    display: grid;
    place-items: start;
    padding: 2rem 0;
    min-height: 100vh;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-items: center; }
    @media (min-width: 768px) {
      .auth-signup .content-container {
        padding: 0;
        max-height: 100vh; } }
    .auth-signup .content-container video,
    .auth-signup .content-container .background-image {
      position: absolute;
      top: 0;
      z-index: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      background-position: contain;
      -webkit-transform: scaleX(-1);
              transform: scaleX(-1); }
      @media (max-width: 767.98px) {
        .auth-signup .content-container video,
        .auth-signup .content-container .background-image {
          -webkit-filter: blur(6px);
                  filter: blur(6px); } }
    .auth-signup .content-container .background-image {
      -webkit-transform: scaleX(1);
              transform: scaleX(1); }
    .auth-signup .content-container .signup-container {
      z-index: 1;
      background-color: var(--pure-white);
      width: 90vw;
      padding: 2rem;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      border-radius: 1.2rem; }
      .auth-signup .content-container .signup-container h1 {
        font-size: 1.25rem;
        font-weight: 700;
        text-align: center; }
      .auth-signup .content-container .signup-container p {
        font-size: 0.8rem;
        font-weight: 300;
        text-align: center; }
      .auth-signup .content-container .signup-container form {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 0.75rem;
        gap: 0.75rem;
        margin-top: 1rem;
        width: 100%; }
        @media (max-width: 767.98px) {
          .auth-signup .content-container .signup-container form {
            display: flex;
            flex-direction: column; } }
        .auth-signup .content-container .signup-container form .form-group {
          display: flex;
          flex-direction: column;
          margin-bottom: 0; }
          .auth-signup .content-container .signup-container form .form-group label {
            font-size: 0.7rem; }
          .auth-signup .content-container .signup-container form .form-group .password-container {
            position: relative; }
            .auth-signup .content-container .signup-container form .form-group .password-container button {
              position: absolute;
              top: 50%;
              right: 0.5rem;
              -webkit-transform: translateY(-50%);
                      transform: translateY(-50%);
              cursor: pointer;
              outline: none; }
          .auth-signup .content-container .signup-container form .form-group input {
            padding: 0.6rem;
            padding-left: 1rem;
            background-color: #f8f8f8;
            margin: 0;
            width: 100%;
            border: none; }
            .auth-signup .content-container .signup-container form .form-group input::-webkit-input-placeholder {
              font-weight: 400;
              color: var(--primary-dark);
              opacity: 0.7;
              font-size: 14px;
              font-family: var(--sans-serif-font-family); }
            .auth-signup .content-container .signup-container form .form-group input::placeholder {
              font-weight: 400;
              color: var(--primary-dark);
              opacity: 0.7;
              font-size: 14px;
              font-family: var(--sans-serif-font-family); }
            @media (max-width: 767.98px) {
              .auth-signup .content-container .signup-container form .form-group input {
                padding: 0.45rem 0.6rem;
                font-size: 16px; }
                .auth-signup .content-container .signup-container form .form-group input::-webkit-input-placeholder {
                  font-size: 12px; }
                .auth-signup .content-container .signup-container form .form-group input::placeholder {
                  font-size: 12px; } }
            .auth-signup .content-container .signup-container form .form-group input:after {
              content: '*'; }
          .auth-signup .content-container .signup-container form .form-group #phone_number {
            padding-left: 3rem;
            border-radius: 0.625rem; }
          .auth-signup .content-container .signup-container form .form-group .react-tel-input .flag-dropdown {
            background: #f8f8f8;
            border: none;
            border-radius: 0.525rem; }
            .auth-signup .content-container .signup-container form .form-group .react-tel-input .flag-dropdown .selected-flag {
              background: transparent;
              padding-left: 1rem; }
          .auth-signup .content-container .signup-container form .form-group .react-tel-input .country-list {
            box-shadow: 0px 4px 20px 1px rgba(0, 0, 0, 0.1);
            border-radius: 0.525rem; }
            .auth-signup .content-container .signup-container form .form-group .react-tel-input .country-list li {
              font-weight: 300; }
        .auth-signup .content-container .signup-container form .password-requirements {
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-template-rows: auto;
          grid-column: span 2 / auto;
          grid-gap: 0.25rem;
          gap: 0.25rem;
          width: 100%;
          margin: 0;
          margin-bottom: 2rem; }
          @media (max-width: 767.98px) {
            .auth-signup .content-container .signup-container form .password-requirements {
              grid-template-columns: 1fr; } }
          .auth-signup .content-container .signup-container form .password-requirements .title {
            font-size: 0.75rem;
            font-weight: 600;
            margin-bottom: 0.25rem;
            color: var(--primary-dark);
            font-family: var(--sans-serif-font-family);
            padding: 0;
            text-align: left; }
            @media (min-width: 768px) {
              .auth-signup .content-container .signup-container form .password-requirements .title {
                grid-column: span 2 / auto; } }
          .auth-signup .content-container .signup-container form .password-requirements__item {
            display: flex;
            flex-direction: row;
            align-items: center;
            grid-gap: 0.5rem;
            gap: 0.5rem;
            width: 100%; }
            .auth-signup .content-container .signup-container form .password-requirements__item__icon {
              object-fit: contain;
              font-size: 0.75rem;
              color: #e8744f; }
            .auth-signup .content-container .signup-container form .password-requirements__item__text {
              font-size: 0.75rem;
              font-weight: 400;
              color: var(--primary-dark);
              white-space: nowrap; }
        .auth-signup .content-container .signup-container form .terms-check {
          display: flex;
          grid-gap: 0.5rem;
          gap: 0.5rem;
          grid-column: span 2 / auto;
          margin-top: 0.5rem; }
          .auth-signup .content-container .signup-container form .terms-check .terms-container .agree {
            font-weight: 600;
            cursor: pointer;
            -webkit-user-select: none;
                    user-select: none; }
          .auth-signup .content-container .signup-container form .terms-check .terms-container p {
            font-size: 0.7rem; }
          .auth-signup .content-container .signup-container form .terms-check .terms-container a {
            font-size: 0.7rem;
            color: var(--primary-dark);
            text-decoration: underline;
            font-weight: 300;
            cursor: pointer; }
        .auth-signup .content-container .signup-container form .error-message {
          color: var(--primary-red);
          font-size: 0.7rem;
          margin: 0.5rem 0;
          font-weight: 700;
          text-align: start; }
        .auth-signup .content-container .signup-container form .signup-btn {
          margin-top: 1.5rem;
          width: 100%;
          color: var(--pure-white);
          padding: 0.5rem 0;
          margin: 0 auto;
          max-height: -webkit-max-content;
          max-height: max-content;
          border-radius: 0.4rem;
          grid-column: span 2 / auto;
          font-weight: 700; }
      @media (min-width: 768px) {
        .auth-signup .content-container .signup-container {
          min-height: 593px;
          height: -webkit-max-content;
          height: max-content;
          width: 37.12rem;
          padding: 3.5rem; }
          .auth-signup .content-container .signup-container h1 {
            font-size: 2rem; }
          .auth-signup .content-container .signup-container p {
            font-size: 1rem; } }

h1 {
  text-align: left; }

.auth-login {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  margin: 0 auto;
  overflow: hidden; }
  @media (max-width: 767.98px) {
    .auth-login {
      height: 100%; } }
  .auth-login .auth-login-navbar {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--pure-white);
    padding: 1.25rem 0; }
    .auth-login .auth-login-navbar img {
      width: 7.125rem;
      object-fit: contain; }
    @media (max-width: 767.98px) {
      .auth-login .auth-login-navbar {
        z-index: 1; } }
  .auth-login .auth-login-content {
    position: relative;
    background-size: cover;
    object-fit: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    place-content: center;
    padding-left: 15vw; }
    .auth-login .auth-login-content video,
    .auth-login .auth-login-content .background-image {
      position: absolute;
      top: 0;
      z-index: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      background-position: contain;
      -webkit-transform: scaleX(-1);
              transform: scaleX(-1); }
      @media (max-width: 767.98px) {
        .auth-login .auth-login-content video,
        .auth-login .auth-login-content .background-image {
          -webkit-filter: blur(6px);
                  filter: blur(6px); } }
    .auth-login .auth-login-content .background-image {
      -webkit-transform: scaleX(1);
              transform: scaleX(1); }
    .auth-login .auth-login-content form {
      z-index: 1; }
      .auth-login .auth-login-content form .auth-login-form {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        max-width: 31.25rem;
        padding: 2rem 10%;
        background-color: var(--pure-white);
        border-radius: 1.2rem; }
        .auth-login .auth-login-content form .auth-login-form h1 {
          font-size: 2rem;
          font-weight: 700;
          margin-bottom: 1.5rem; }
        @media (max-width: 767.98px) {
          .auth-login .auth-login-content form .auth-login-form {
            width: 85vw; }
            .auth-login .auth-login-content form .auth-login-form h1 {
              font-size: 1.125rem; } }
        .auth-login .auth-login-content form .auth-login-form .auth-login-form-body {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;
          grid-gap: 1rem;
          gap: 1rem; }
          .auth-login .auth-login-content form .auth-login-form .auth-login-form-body .auth-login-form-body-input {
            width: 100%; }
            .auth-login .auth-login-content form .auth-login-form .auth-login-form-body .auth-login-form-body-input .auth-login-input-wrapper {
              width: 100%;
              display: flex;
              flex-direction: column;
              align-items: center;
              position: relative; }
              .auth-login .auth-login-content form .auth-login-form .auth-login-form-body .auth-login-form-body-input .auth-login-input-wrapper input {
                width: 100%;
                height: 2.56rem;
                padding: 0.5rem;
                border: none;
                border-radius: 0.5rem;
                font-size: 14px;
                font-weight: 400;
                outline: none;
                background-color: #f8f8f8;
                padding-left: 3.5rem; }
                .auth-login .auth-login-content form .auth-login-form .auth-login-form-body .auth-login-form-body-input .auth-login-input-wrapper input::-webkit-input-placeholder {
                  font-size: 14px;
                  font-weight: 400; }
                .auth-login .auth-login-content form .auth-login-form .auth-login-form-body .auth-login-form-body-input .auth-login-input-wrapper input::placeholder {
                  font-size: 14px;
                  font-weight: 400; }
              .auth-login .auth-login-content form .auth-login-form .auth-login-form-body .auth-login-form-body-input .auth-login-input-wrapper svg {
                position: absolute;
                top: 0.8rem;
                left: 1rem; }
              .auth-login .auth-login-content form .auth-login-form .auth-login-form-body .auth-login-form-body-input .auth-login-input-wrapper .lock {
                top: 0.65rem; }
          .auth-login .auth-login-content form .auth-login-form .auth-login-form-body button {
            margin-top: 1rem;
            width: 100%;
            color: var(--pure-white);
            padding: 0.5rem 0;
            margin: 0 auto;
            max-height: -webkit-max-content;
            max-height: max-content;
            border-radius: 0.4rem;
            grid-column: span 2 / auto;
            font-weight: 700; }
          .auth-login .auth-login-content form .auth-login-form .auth-login-form-body .forgot-password {
            color: var(--primary-dark-green);
            font-weight: 600;
            text-decoration: underline;
            font-size: 0.875rem;
            width: 100%;
            text-align: center; }
      .auth-login .auth-login-content form .error-message {
        color: var(--primary-red);
        font-size: 0.7rem;
        margin: 0.5rem 0;
        font-weight: 700; }

@media (max-width: 767.98px) {
  .auth-login .auth-login-content {
    grid-template-columns: 1fr;
    place-items: center;
    place-content: start;
    padding-left: 0;
    padding-top: 15vh;
    height: 100vh; }
    .auth-login .auth-login-content form .auth-login-form {
      padding: 1.5rem 2rem; }
      .auth-login .auth-login-content form .auth-login-form .auth-login-form-header h1 {
        margin-bottom: 0.5rem; }
    .auth-login .auth-login-content form button {
      font-size: 0.85rem; } }

h1 {
  text-align: left; }

.auth-caregiver-default {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background-color: #f5f5f5; }
  .auth-caregiver-default .auth-caregiver-default-navbar {
    display: flex;
    align-items: center;
    width: 85%;
    justify-content: space-between;
    padding: 1.2rem 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1); }
    .auth-caregiver-default .auth-caregiver-default-navbar img {
      width: 11.75rem;
      object-fit: contain; }
    .auth-caregiver-default .auth-caregiver-default-navbar .login-and-cta {
      display: flex;
      align-items: center;
      grid-gap: 3rem;
      gap: 3rem; }
      .auth-caregiver-default .auth-caregiver-default-navbar .login-and-cta .cta {
        display: flex;
        flex-direction: column;
        align-items: flex-end; }
        .auth-caregiver-default .auth-caregiver-default-navbar .login-and-cta .cta p {
          font-size: 0.8rem; }
        .auth-caregiver-default .auth-caregiver-default-navbar .login-and-cta .cta .schedule {
          text-decoration: underline; }
  .auth-caregiver-default .auth-caregiver-default-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%; }
    .auth-caregiver-default .auth-caregiver-default-content .left-side {
      width: 90%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      grid-gap: 4rem;
      gap: 4rem;
      padding-left: 7.5%; }
      .auth-caregiver-default .auth-caregiver-default-content .left-side .title-content {
        display: flex;
        flex-direction: column;
        grid-gap: 2rem;
        gap: 2rem; }
        .auth-caregiver-default .auth-caregiver-default-content .left-side .title-content h1 {
          font-size: 2rem;
          font-weight: 700;
          width: 75%; }
        .auth-caregiver-default .auth-caregiver-default-content .left-side .title-content p {
          font-size: 0.875rem;
          font-weight: 300;
          width: 75%; }
      .auth-caregiver-default .auth-caregiver-default-content .left-side .cta-content {
        display: flex;
        flex-direction: column;
        grid-gap: 1.5rem;
        gap: 1.5rem; }
    .auth-caregiver-default .auth-caregiver-default-content .right-side {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      position: relative;
      width: 100%;
      height: 100%; }
      .auth-caregiver-default .auth-caregiver-default-content .right-side img {
        width: 120%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: 10; }
  .auth-caregiver-default .login-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.875rem;
    font-weight: 700;
    width: 11.75rem;
    padding: 0.75rem 0;
    border-radius: 0.5rem; }

@media (max-width: 767.98px) {
  .auth-caregiver-default {
    overflow-y: auto; }
    .auth-caregiver-default .auth-caregiver-default-navbar {
      align-items: flex-start; }
      .auth-caregiver-default .auth-caregiver-default-navbar .login-and-cta {
        flex-direction: column-reverse;
        grid-gap: 0.5rem;
        gap: 0.5rem;
        align-items: flex-end; }
        .auth-caregiver-default .auth-caregiver-default-navbar .login-and-cta .cta {
          align-items: flex-end; }
          .auth-caregiver-default .auth-caregiver-default-navbar .login-and-cta .cta p {
            text-align: right;
            font-size: 0.5rem; }
    .auth-caregiver-default .auth-caregiver-default-content {
      flex-direction: column;
      padding-top: 2rem; }
      .auth-caregiver-default .auth-caregiver-default-content .left-side {
        padding: 0;
        grid-gap: 2.5rem;
        gap: 2.5rem; }
        .auth-caregiver-default .auth-caregiver-default-content .left-side .title-content {
          align-items: center; }
          .auth-caregiver-default .auth-caregiver-default-content .left-side .title-content h1,
          .auth-caregiver-default .auth-caregiver-default-content .left-side .title-content p {
            text-align: center; }
          .auth-caregiver-default .auth-caregiver-default-content .left-side .title-content h1 {
            font-size: 1.5rem; }
        .auth-caregiver-default .auth-caregiver-default-content .left-side .cta-content {
          width: 100%;
          align-items: center; }
          .auth-caregiver-default .auth-caregiver-default-content .left-side .cta-content .login-btn {
            padding: 1rem;
            width: 50vw;
            max-width: 50vw; }
      .auth-caregiver-default .auth-caregiver-default-content .right-side {
        align-items: center;
        grid-gap: 2rem;
        gap: 2rem;
        padding-bottom: 1rem; }
        .auth-caregiver-default .auth-caregiver-default-content .right-side img {
          position: relative;
          object-fit: contain;
          width: 100%; }
    .auth-caregiver-default .login-btn {
      padding: 0.5rem 1.5rem;
      max-width: -webkit-max-content;
      max-width: max-content;
      align-items: center; }
      .auth-caregiver-default .login-btn p {
        text-align: center; } }

.auth-home-illustration-container {
  display: flex;
  flex-direction: row;
  grid-gap: 1rem;
  gap: 1rem;
  align-items: center;
  padding: 0.25rem;
  padding-right: 1.75rem;
  max-width: -webkit-max-content;
  max-width: max-content;
  border-radius: 4.68rem;
  cursor: pointer; }
  .auth-home-illustration-container:hover {
    -webkit-transform: scale(1.01);
            transform: scale(1.01); }
  .auth-home-illustration-container .roadmap-illustration {
    width: 3.56rem;
    height: 3.56rem;
    border-radius: 50%;
    object-fit: cover; }
  .auth-home-illustration-container .text-container {
    display: flex;
    flex-direction: column; }
    .auth-home-illustration-container .text-container .main {
      font-size: 0.875rem;
      font-weight: 700;
      font-family: var(--serif-font-family); }
    .auth-home-illustration-container .text-container p {
      color: var(--pure-white);
      font-size: 0.7rem; }
  @media (max-width: 767.98px) {
    .auth-home-illustration-container {
      margin: 1rem auto; } }

.success-modal .modal-content {
  border-radius: 20px;
  padding: 2rem 1.5rem; }

.success-modal .modal-title {
  font-family: var(--serif-font-family);
  font-size: 2rem;
  font-weight: 700;
  color: var(--primary-dark);
  margin: 0 auto; }

.success-modal .modal-header {
  padding: 0; }

.success-modal .modal-body {
  width: 60%;
  margin: 0 auto;
  text-align: center; }

.success-modal button {
  margin: 0 auto;
  background-color: var(--primary-dark);
  color: var(--primary-white);
  padding: 0.8rem 1.2rem;
  border-radius: 0.6rem;
  font-size: 0.875rem;
  width: 60%;
  font-weight: 700; }

.caregiver-modal {
  display: flex;
  flex-direction: column;
  align-items: center; }
  .caregiver-modal .modal-content {
    background-color: #f8f5f5;
    padding: 1rem 0; }
  .caregiver-modal .title {
    text-align: center;
    font-size: 2rem;
    font-weight: 700;
    padding-bottom: 0.5rem; }
  .caregiver-modal .description {
    text-align: center;
    font-size: 0.875rem;
    padding-bottom: 0.5rem;
    font-weight: 300;
    margin: 0 auto; }

@media (max-width: 767.98px) {
  .caregiver-modal .title {
    font-size: 1.5rem; }
  .caregiver-modal .description {
    font-size: 0.75rem; } }

.caregiver-modal .tab-selector {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 85%;
  margin: 0 auto;
  padding: 1rem 0; }
  .caregiver-modal .tab-selector .tab {
    display: flex;
    align-items: flex-start;
    width: 100%;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    cursor: pointer;
    padding-left: 0.5rem; }
    .caregiver-modal .tab-selector .tab .title-container h3 {
      font-size: 0.875rem; }
    .caregiver-modal .tab-selector .tab .title-container p {
      font-size: 0.625rem;
      font-weight: 300; }

.caregiver-modal .client-form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.75rem;
  gap: 0.75rem;
  margin: 0 auto;
  margin-top: 1rem;
  width: 85%; }
  @media (max-width: 767.98px) {
    .caregiver-modal .client-form {
      display: flex;
      flex-direction: column; } }
  .caregiver-modal .client-form .form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 0; }
    .caregiver-modal .client-form .form-group label {
      font-size: 0.7rem; }
    .caregiver-modal .client-form .form-group input {
      padding: 0.6rem;
      padding-left: 1rem;
      background-color: #fff;
      margin: 0;
      width: 100%;
      border: none; }
      .caregiver-modal .client-form .form-group input::-webkit-input-placeholder {
        font-weight: 400;
        color: var(--primary-dark);
        opacity: 0.7;
        font-size: 14px;
        font-family: var(--sans-serif-font-family); }
      .caregiver-modal .client-form .form-group input::placeholder {
        font-weight: 400;
        color: var(--primary-dark);
        opacity: 0.7;
        font-size: 14px;
        font-family: var(--sans-serif-font-family); }
      @media (max-width: 767.98px) {
        .caregiver-modal .client-form .form-group input {
          padding: 0.45rem 0.6rem;
          font-size: 12px; }
          .caregiver-modal .client-form .form-group input::-webkit-input-placeholder {
            font-size: 12px; }
          .caregiver-modal .client-form .form-group input::placeholder {
            font-size: 12px; } }
      .caregiver-modal .client-form .form-group input:after {
        content: '*'; }
  .caregiver-modal .client-form .terms-check {
    display: flex;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    grid-column: span 2 / auto;
    margin-top: 0.5rem; }
    .caregiver-modal .client-form .terms-check .terms-container .agree {
      font-weight: 600;
      cursor: pointer;
      -webkit-user-select: none;
              user-select: none; }
    .caregiver-modal .client-form .terms-check .terms-container p {
      font-size: 0.7rem; }
    .caregiver-modal .client-form .terms-check .terms-container a {
      font-size: 0.7rem;
      color: var(--primary-dark);
      text-decoration: underline;
      font-weight: 300;
      cursor: pointer; }
  .caregiver-modal .client-form .error-message {
    color: var(--primary-red);
    font-size: 0.7rem;
    margin: 0.5rem 0;
    font-weight: 700;
    text-align: start; }
  .caregiver-modal .client-form .signup-btn {
    margin-top: 1.5rem;
    width: 100%;
    color: var(--pure-white);
    padding: 0.5rem 0;
    margin: 0 auto;
    max-height: -webkit-max-content;
    max-height: max-content;
    border-radius: 0.4rem;
    grid-column: span 2 / auto;
    font-weight: 700; }
  .caregiver-modal .client-form input[type='checkbox']::after {
    width: 11px;
    height: 11px;
    border: 1px solid #343434;
    border-radius: 2px;
    background-color: var(--pure-white);
    cursor: pointer; }
  .caregiver-modal .client-form input[type='checkbox']:checked::after {
    background-color: #343434; }

.caregiver-form {
  display: flex;
  flex-direction: column;
  grid-gap: 0.75rem;
  gap: 0.75rem;
  margin: 0 auto;
  margin-top: 1rem;
  width: 85%;
  max-height: 50vh;
  overflow-y: auto; }
  .caregiver-form .section-title {
    font-size: 0.7rem; }
  .caregiver-form h2 {
    text-align: center;
    font-family: var(--serif-font-family);
    font-size: 1rem;
    font-weight: 700; }
  .caregiver-form .form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 0; }
    .caregiver-form .form-group label {
      font-size: 0.7rem; }
    .caregiver-form .form-group input {
      padding: 0.6rem;
      padding-left: 1rem;
      background-color: #fff;
      margin: 0;
      width: 100%;
      border: none; }
      .caregiver-form .form-group input::-webkit-input-placeholder {
        font-weight: 400;
        color: var(--primary-dark);
        opacity: 0.7;
        font-size: 14px;
        font-family: var(--sans-serif-font-family); }
      .caregiver-form .form-group input::placeholder {
        font-weight: 400;
        color: var(--primary-dark);
        opacity: 0.7;
        font-size: 14px;
        font-family: var(--sans-serif-font-family); }
      @media (max-width: 767.98px) {
        .caregiver-form .form-group input {
          padding: 0.45rem 0.6rem;
          font-size: 12px; }
          .caregiver-form .form-group input::-webkit-input-placeholder {
            font-size: 12px; }
          .caregiver-form .form-group input::placeholder {
            font-size: 12px; } }
      .caregiver-form .form-group input:after {
        content: '*'; }
  .caregiver-form .terms-check {
    display: flex;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    grid-column: span 2 / auto;
    margin-top: 0.5rem; }
    .caregiver-form .terms-check .terms-container .agree {
      font-weight: 600;
      cursor: pointer;
      -webkit-user-select: none;
              user-select: none; }
    .caregiver-form .terms-check .terms-container p {
      font-size: 0.7rem; }
    .caregiver-form .terms-check .terms-container a {
      font-size: 0.7rem;
      color: var(--primary-dark);
      text-decoration: underline;
      font-weight: 300;
      cursor: pointer; }
  .caregiver-form .error-message {
    color: var(--primary-red);
    font-size: 0.7rem;
    margin: 0.5rem 0;
    font-weight: 700;
    text-align: start; }
  .caregiver-form .done-btn {
    margin-top: 1.5rem;
    width: 100%;
    color: var(--pure-white);
    padding: 0.5rem 0;
    margin: 0 auto;
    max-height: -webkit-max-content;
    max-height: max-content;
    border-radius: 0.4rem;
    grid-column: span 2 / auto;
    font-weight: 700; }
  .caregiver-form .consent-information {
    font-size: 12px;
    font-weight: 400;
    opacity: 0.7;
    padding: 1rem;
    background-color: #fff;
    border-radius: 0.5rem; }

.self-signup-progress {
  display: flex;
  align-items: center;
  grid-gap: 90px;
  gap: 90px;
  width: -webkit-max-content;
  width: max-content; }
  @media (max-width: 991.98px) {
    .self-signup-progress {
      grid-gap: 17px;
      gap: 17px;
      height: 85px;
      justify-content: center;
      width: 100%; } }
  .self-signup-progress .step-number-and-label {
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 9px;
    gap: 9px; }
    .self-signup-progress .step-number-and-label .step-number {
      width: 22px;
      height: 22px;
      border-radius: 50%;
      border: 2px solid #ebb551;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative; }
      @media (max-width: 991.98px) {
        .self-signup-progress .step-number-and-label .step-number {
          border: 1px solid #ebb551; } }
      .self-signup-progress .step-number-and-label .step-number p {
        font-size: 10px;
        font-weight: 600;
        color: #ebb551;
        text-align: center; }
        @media (max-width: 991.98px) {
          .self-signup-progress .step-number-and-label .step-number p {
            font-size: 9px; } }
      .self-signup-progress .step-number-and-label .step-number .step-line {
        width: 63px;
        height: 1px;
        background-color: #dadada;
        position: absolute;
        top: 50%;
        left: 90px;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%); }
        @media (max-width: 991.98px) {
          .self-signup-progress .step-number-and-label .step-number .step-line {
            left: 54px;
            width: 33px; } }
    .self-signup-progress .step-number-and-label .step-label {
      font-size: 11px;
      font-weight: 500;
      color: rgba(52, 52, 52, 0.3);
      text-align: center; }
      @media (max-width: 991.98px) {
        .self-signup-progress .step-number-and-label .step-label {
          font-size: 10px;
          line-height: 12px; } }
  .self-signup-progress .step:last-child .step-line {
    display: none; }
  .self-signup-progress .current .step-label {
    color: rgba(52, 52, 52, 0.6); }

h1 {
  text-align: left; }

h1 {
  text-align: left; }

.auth-forgot-password {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  margin: 0 auto;
  overflow: hidden; }
  .auth-forgot-password .auth-forgot-password-navbar {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--pure-white);
    padding: 1.25rem 0; }
    .auth-forgot-password .auth-forgot-password-navbar img {
      width: 7.125rem;
      object-fit: contain; }
    @media (max-width: 767.98px) {
      .auth-forgot-password .auth-forgot-password-navbar {
        z-index: 1; } }
  .auth-forgot-password .auth-forgot-password-content {
    position: relative;
    background-size: cover;
    object-fit: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    place-content: center;
    padding-left: 15vw; }
    .auth-forgot-password .auth-forgot-password-content video,
    .auth-forgot-password .auth-forgot-password-content .background-image {
      position: absolute;
      top: 0;
      z-index: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      background-position: contain;
      -webkit-transform: scaleX(-1);
              transform: scaleX(-1); }
      @media (max-width: 767.98px) {
        .auth-forgot-password .auth-forgot-password-content video,
        .auth-forgot-password .auth-forgot-password-content .background-image {
          -webkit-filter: blur(6px);
                  filter: blur(6px); } }
    .auth-forgot-password .auth-forgot-password-content .background-image {
      -webkit-transform: scaleX(1);
              transform: scaleX(1); }
    .auth-forgot-password .auth-forgot-password-content form {
      z-index: 1; }
      .auth-forgot-password .auth-forgot-password-content form .auth-forgot-password-form {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        max-width: 31.25rem;
        padding: 2rem 10%;
        background-color: var(--pure-white);
        border-radius: 1.2rem; }
        .auth-forgot-password .auth-forgot-password-content form .auth-forgot-password-form h1 {
          font-size: 2rem;
          font-weight: 700;
          margin-bottom: 1.5rem; }
        .auth-forgot-password .auth-forgot-password-content form .auth-forgot-password-form .auth-forgot-password-form-header {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-bottom: 1rem; }
          .auth-forgot-password .auth-forgot-password-content form .auth-forgot-password-form .auth-forgot-password-form-header h1,
          .auth-forgot-password .auth-forgot-password-content form .auth-forgot-password-form .auth-forgot-password-form-header p {
            text-align: center; }
          .auth-forgot-password .auth-forgot-password-content form .auth-forgot-password-form .auth-forgot-password-form-header p {
            margin-top: -0.5rem; }
        @media (max-width: 767.98px) {
          .auth-forgot-password .auth-forgot-password-content form .auth-forgot-password-form {
            width: 85vw; }
            .auth-forgot-password .auth-forgot-password-content form .auth-forgot-password-form h1 {
              font-size: 1.125rem; } }
        .auth-forgot-password .auth-forgot-password-content form .auth-forgot-password-form .auth-forgot-password-form-body {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;
          grid-gap: 1rem;
          gap: 1rem; }
          .auth-forgot-password .auth-forgot-password-content form .auth-forgot-password-form .auth-forgot-password-form-body .auth-forgot-password-form-body-input {
            width: 100%; }
            .auth-forgot-password .auth-forgot-password-content form .auth-forgot-password-form .auth-forgot-password-form-body .auth-forgot-password-form-body-input .auth-forgot-password-input-wrapper {
              width: 100%;
              display: flex;
              flex-direction: column;
              align-items: center;
              position: relative; }
              .auth-forgot-password .auth-forgot-password-content form .auth-forgot-password-form .auth-forgot-password-form-body .auth-forgot-password-form-body-input .auth-forgot-password-input-wrapper input {
                width: 100%;
                height: 2.56rem;
                padding: 0.5rem;
                border: none;
                border-radius: 0.5rem;
                font-size: 14px;
                font-weight: 400;
                outline: none;
                background-color: #f8f8f8;
                padding-left: 3.5rem; }
                .auth-forgot-password .auth-forgot-password-content form .auth-forgot-password-form .auth-forgot-password-form-body .auth-forgot-password-form-body-input .auth-forgot-password-input-wrapper input::-webkit-input-placeholder {
                  font-size: 14px;
                  font-weight: 400; }
                .auth-forgot-password .auth-forgot-password-content form .auth-forgot-password-form .auth-forgot-password-form-body .auth-forgot-password-form-body-input .auth-forgot-password-input-wrapper input::placeholder {
                  font-size: 14px;
                  font-weight: 400; }
              .auth-forgot-password .auth-forgot-password-content form .auth-forgot-password-form .auth-forgot-password-form-body .auth-forgot-password-form-body-input .auth-forgot-password-input-wrapper svg {
                position: absolute;
                top: 0.8rem;
                left: 1rem; }
              .auth-forgot-password .auth-forgot-password-content form .auth-forgot-password-form .auth-forgot-password-form-body .auth-forgot-password-form-body-input .auth-forgot-password-input-wrapper .lock {
                top: 0.65rem; }
          .auth-forgot-password .auth-forgot-password-content form .auth-forgot-password-form .auth-forgot-password-form-body .forgot-password {
            color: var(--primary-dark-green);
            font-weight: 600;
            text-decoration: underline;
            font-size: 0.875rem;
            width: 100%;
            text-align: center; }
      .auth-forgot-password .auth-forgot-password-content form .error-message {
        color: var(--primary-red);
        font-size: 0.7rem;
        margin: 0.5rem 0;
        font-weight: 700; }
      .auth-forgot-password .auth-forgot-password-content form .i-remember {
        text-decoration: underline;
        font-size: 0.875rem;
        font-weight: 700;
        cursor: pointer;
        color: var(--primary-dark-green);
        margin-top: 0.5rem; }
    .auth-forgot-password .auth-forgot-password-content button,
    .auth-forgot-password .auth-forgot-password-content .success {
      margin-top: 1rem;
      width: 100%;
      color: var(--pure-white);
      padding: 0.5rem 0;
      margin: 0 auto;
      max-height: -webkit-max-content;
      max-height: max-content;
      border-radius: 0.4rem;
      grid-column: span 2 / auto;
      font-weight: 700; }

@media (max-width: 767.98px) {
  .auth-forgot-password .auth-forgot-password-content {
    grid-template-columns: 1fr;
    place-items: center;
    place-content: start;
    padding-left: 0;
    padding-top: 15vh;
    height: 100vh; }
    .auth-forgot-password .auth-forgot-password-content form .auth-forgot-password-form {
      padding: 1.5rem 2rem; }
      .auth-forgot-password .auth-forgot-password-content form .auth-forgot-password-form .auth-forgot-password-form-header h1 {
        margin-bottom: 0.5rem; }
    .auth-forgot-password .auth-forgot-password-content form button {
      font-size: 0.85rem; } }

h1 {
  text-align: left; }

.auth-google-calendar {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  margin: 0 auto;
  overflow: hidden;
  background-color: var(--pure-white); }
  @media (max-width: 767.98px) {
    .auth-google-calendar {
      height: 100%; } }
  .auth-google-calendar .auth-google-calendar-navbar {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--pure-white);
    padding: 1.25rem 7.5%;
    z-index: 1;
    border-bottom: 0.5px solid #c4c4c4;
    margin: 0 auto; }
    .auth-google-calendar .auth-google-calendar-navbar img {
      width: 7.125rem;
      object-fit: contain; }
    .auth-google-calendar .auth-google-calendar-navbar img {
      width: 7.125rem;
      object-fit: contain; }
    @media (max-width: 991.98px) {
      .auth-google-calendar .auth-google-calendar-navbar {
        display: grid;
        grid-template-columns: 1fr;
        padding: 0;
        place-content: center;
        z-index: 1;
        width: 100%; } }
    .auth-google-calendar .auth-google-calendar-navbar__logo-container {
      display: flex;
      align-items: center;
      justify-content: center; }
      @media (max-width: 991.98px) {
        .auth-google-calendar .auth-google-calendar-navbar__logo-container {
          height: 65px;
          box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
          width: 100%; } }
  .auth-google-calendar .auth-google-calendar-content {
    position: relative;
    background-size: cover;
    object-fit: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--pure-white); }
    .auth-google-calendar .auth-google-calendar-content video,
    .auth-google-calendar .auth-google-calendar-content .background-image {
      position: absolute;
      top: 0;
      z-index: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      background-position: contain;
      -webkit-transform: scaleX(-1);
              transform: scaleX(-1); }
      @media (max-width: 767.98px) {
        .auth-google-calendar .auth-google-calendar-content video,
        .auth-google-calendar .auth-google-calendar-content .background-image {
          -webkit-filter: blur(6px);
                  filter: blur(6px); } }
    .auth-google-calendar .auth-google-calendar-content .background-image {
      -webkit-transform: scaleX(1);
              transform: scaleX(1); }
    .auth-google-calendar .auth-google-calendar-content .auth-google-calendar-content-inner {
      z-index: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;
      min-width: 85vw;
      width: 85vw;
      height: 80vh;
      border-radius: 1.25rem;
      background-color: var(--pure-white); }
      .auth-google-calendar .auth-google-calendar-content .auth-google-calendar-content-inner h1 {
        font-family: var(--sans-serif-font-family);
        font-weight: 600;
        font-size: 1.25rem;
        margin: 1rem 0; }
      .auth-google-calendar .auth-google-calendar-content .auth-google-calendar-content-inner .description {
        color: #838383;
        font-size: 0.875rem;
        width: 70%;
        text-align: center;
        padding-bottom: 1rem; }
      .auth-google-calendar .auth-google-calendar-content .auth-google-calendar-content-inner__done {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 2.1rem;
        height: 2.1rem;
        width: 100%;
        max-width: 290px;
        background-color: #ebb551;
        border-radius: 0.3125rem;
        margin: 1.5rem 0; }
        .auth-google-calendar .auth-google-calendar-content .auth-google-calendar-content-inner__done p {
          text-align: center;
          color: var(--pure-white);
          font-weight: 700; }
        .auth-google-calendar .auth-google-calendar-content .auth-google-calendar-content-inner__done:disabled {
          opacity: 0.5;
          cursor: not-allowed; }

.greminders__booking-widget-overlay .greminders__booking-widget-close {
  display: none !important; }

.greminders__booking-widget-overlay.greminders__booking-widget-overlay .greminders__booking-widget-popup {
  box-shadow: none !important; }

.greminders__booking-widget-overlay {
  background: none !important; }

.greminders__booking-widget-overlay.greminders__booking-widget-overlay .greminders__booking-widget-popup {
  width: 80vw !important;
  height: 80vh !important; }
  @media (max-width: 991.98px) {
    .greminders__booking-widget-overlay.greminders__booking-widget-overlay .greminders__booking-widget-popup {
      width: 100vw !important;
      margin-top: 0 !important;
      height: 100vh !important; } }

#auth-google-calendar-content .greminders__booking-widget {
  display: block !important;
  height: 100vh !important; }
  #auth-google-calendar-content .greminders__booking-widget .greminders__booking-widget-overlay {
    position: relative !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: flex-start !important;
    padding-top: 1.5rem !important; }
    @media (max-width: 991.98px) {
      #auth-google-calendar-content .greminders__booking-widget .greminders__booking-widget-overlay {
        padding-top: 0 !important; } }

h1 {
  text-align: left; }

.confirm-coverage {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  margin: 0 auto;
  overflow: hidden;
  align-items: center; }
  @media (max-width: 767.98px) {
    .confirm-coverage {
      height: 100%; } }
  .confirm-coverage .confirm-coverage-navbar {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--pure-white);
    padding: 1.25rem 0;
    z-index: 1; }
    .confirm-coverage .confirm-coverage-navbar img {
      width: 7.125rem;
      object-fit: contain; }
  .confirm-coverage .content-container {
    position: relative;
    object-fit: cover;
    background-repeat: no-repeat;
    width: 100%;
    display: grid;
    place-items: center;
    padding: 2rem 0;
    min-height: 100vh;
    height: auto;
    justify-content: center;
    align-items: center; }
    @media (min-width: 768px) {
      .confirm-coverage .content-container {
        grid-template-columns: 1fr 1fr;
        padding: 0;
        padding-left: 7.5%;
        max-height: 100vh; } }
    .confirm-coverage .content-container video,
    .confirm-coverage .content-container .background-image {
      position: absolute;
      top: 0;
      z-index: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      background-position: contain;
      -webkit-transform: scaleX(-1);
              transform: scaleX(-1); }
      @media (max-width: 767.98px) {
        .confirm-coverage .content-container video,
        .confirm-coverage .content-container .background-image {
          -webkit-filter: blur(6px);
                  filter: blur(6px); } }
    .confirm-coverage .content-container .background-image {
      -webkit-transform: scaleX(1);
              transform: scaleX(1); }
    .confirm-coverage .content-container .signup-container {
      z-index: 1;
      background-color: var(--pure-white);
      width: 90vw;
      padding: 2rem;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      border-radius: 1.2rem; }
      .confirm-coverage .content-container .signup-container h1 {
        font-size: 1.25rem;
        font-weight: 700;
        text-align: center; }
      .confirm-coverage .content-container .signup-container p {
        font-size: 0.8rem;
        font-weight: 300;
        text-align: center; }
      .confirm-coverage .content-container .signup-container form {
        display: flex;
        flex-direction: column;
        grid-gap: 0.75rem;
        gap: 0.75rem;
        margin-top: 1rem;
        width: 100%; }
        .confirm-coverage .content-container .signup-container form .form-group {
          display: flex;
          flex-direction: column;
          margin-bottom: 0; }
          .confirm-coverage .content-container .signup-container form .form-group label {
            font-size: 0.7rem; }
          .confirm-coverage .content-container .signup-container form .form-group input {
            padding: 0.6rem;
            padding-left: 1rem;
            background-color: #f8f8f8;
            margin: 0;
            width: 100%;
            border: none; }
            .confirm-coverage .content-container .signup-container form .form-group input::-webkit-input-placeholder {
              font-weight: 400;
              color: var(--primary-dark);
              opacity: 0.7;
              font-size: 14px;
              font-family: var(--sans-serif-font-family); }
            .confirm-coverage .content-container .signup-container form .form-group input::placeholder {
              font-weight: 400;
              color: var(--primary-dark);
              opacity: 0.7;
              font-size: 14px;
              font-family: var(--sans-serif-font-family); }
            @media (max-width: 767.98px) {
              .confirm-coverage .content-container .signup-container form .form-group input {
                padding: 0.45rem 0.6rem;
                font-size: 12px; }
                .confirm-coverage .content-container .signup-container form .form-group input::-webkit-input-placeholder {
                  font-size: 12px; }
                .confirm-coverage .content-container .signup-container form .form-group input::placeholder {
                  font-size: 12px; } }
            .confirm-coverage .content-container .signup-container form .form-group input:after {
              content: '*'; }
          .confirm-coverage .content-container .signup-container form .form-group #phone_number {
            padding-left: 3rem;
            border-radius: 0.625rem; }
          .confirm-coverage .content-container .signup-container form .form-group .react-tel-input .flag-dropdown {
            background: #f8f8f8;
            border: none;
            border-radius: 0.525rem; }
            .confirm-coverage .content-container .signup-container form .form-group .react-tel-input .flag-dropdown .selected-flag {
              background: transparent;
              padding-left: 1rem; }
          .confirm-coverage .content-container .signup-container form .form-group .react-tel-input .country-list {
            box-shadow: 0px 4px 20px 1px rgba(0, 0, 0, 0.1);
            border-radius: 0.525rem; }
            .confirm-coverage .content-container .signup-container form .form-group .react-tel-input .country-list li {
              font-weight: 300; }
        .confirm-coverage .content-container .signup-container form .terms-check {
          display: flex;
          grid-gap: 0.5rem;
          gap: 0.5rem;
          grid-column: span 2 / auto;
          margin-top: 0.5rem; }
          .confirm-coverage .content-container .signup-container form .terms-check .terms-container .agree {
            font-weight: 600;
            cursor: pointer;
            -webkit-user-select: none;
                    user-select: none; }
          .confirm-coverage .content-container .signup-container form .terms-check .terms-container p {
            font-size: 0.7rem; }
          .confirm-coverage .content-container .signup-container form .terms-check .terms-container a {
            font-size: 0.7rem;
            color: var(--primary-dark);
            text-decoration: underline;
            font-weight: 300;
            cursor: pointer; }
        .confirm-coverage .content-container .signup-container form .error-message {
          color: var(--primary-red);
          font-size: 0.7rem;
          margin: 0.5rem 0;
          font-weight: 700;
          text-align: start; }
        .confirm-coverage .content-container .signup-container form .signup-btn {
          margin-top: 1.5rem;
          width: 100%;
          color: var(--pure-white);
          padding: 0.5rem 0;
          margin: 0 auto;
          max-height: -webkit-max-content;
          max-height: max-content;
          border-radius: 0.4rem;
          grid-column: span 2 / auto;
          font-weight: 700; }
      @media (min-width: 768px) {
        .confirm-coverage .content-container .signup-container {
          max-height: 85vh;
          height: auto;
          width: 37.12rem;
          padding: 3.5rem; }
          .confirm-coverage .content-container .signup-container h1 {
            font-size: 2rem; }
          .confirm-coverage .content-container .signup-container p {
            font-size: 1rem; } }

h1 {
  text-align: left; }

.greminders__booking-widget-iframe .row {
  grid-gap: 1rem !important;
  gap: 1rem !important; }

h1 {
  text-align: left; }

.auth-terms-and-conditions-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw; }

.auth-terms-and-conditions-container {
  margin: auto;
  background-color: var(--pure-white);
  width: -webkit-max-content;
  width: max-content;
  padding: 1rem 2rem 1rem 2rem;
  border-radius: 0.6rem; }
  .auth-terms-and-conditions-container .header {
    padding-top: 1.5rem;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    align-items: center; }
    .auth-terms-and-conditions-container .header p {
      text-align: center;
      font-size: 0.875rem;
      font-weight: 300;
      color: #727272; }
    .auth-terms-and-conditions-container .header .title {
      font-family: var(--serif-font-family);
      margin: 0 auto; }
  .auth-terms-and-conditions-container .body {
    display: flex;
    flex-direction: column;
    grid-gap: 1.5rem;
    gap: 1.5rem;
    padding: 1rem 2rem 2rem 2rem; }
    .auth-terms-and-conditions-container .body .item {
      display: flex;
      align-items: center;
      color: var(--primary-dark);
      font-weight: 400;
      cursor: pointer;
      -webkit-user-select: none;
              user-select: none; }
      .auth-terms-and-conditions-container .body .item a {
        margin-left: 1rem; }
    .auth-terms-and-conditions-container .body .link-icon {
      width: 1rem;
      height: 1rem;
      margin-left: -0.5rem;
      color: var(--primary-dark); }
    .auth-terms-and-conditions-container .body .terms-button {
      background-color: var(--primary-dark);
      color: var(--pure-white);
      border-radius: 0.6rem;
      margin: 0 auto;
      max-width: -webkit-fit-content;
      max-width: -moz-fit-content;
      max-width: fit-content;
      padding: 0.85rem;
      font-size: 0.875rem; }
      .auth-terms-and-conditions-container .body .terms-button:disabled {
        background-color: var(--primary-gray);
        color: var(--primary-white); }

h1 {
  text-align: left; }

.auth-download-navbar {
  top: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--pure-white);
  padding: 1.25rem 39px;
  z-index: 1;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.1); }
  .auth-download-navbar img {
    width: 7.125rem;
    object-fit: contain; }
  @media (max-width: 991.98px) {
    .auth-download-navbar {
      flex-direction: column;
      align-items: center;
      padding: 0;
      box-shadow: none;
      position: relative;
      border-bottom: none; } }
  .auth-download-navbar__logo-container {
    display: flex;
    align-items: center;
    justify-content: center; }
    @media (max-width: 991.98px) {
      .auth-download-navbar__logo-container {
        height: 65px;
        box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
        width: 100%; } }

.auth-download {
  display: flex;
  flex-direction: column;
  background-color: var(--pure-white);
  width: 100vw; }
  @media (min-width: 992px) {
    .auth-download {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      grid-template-rows: 1fr;
      height: 100vh; } }
  .auth-download__background-image {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative; }
    .auth-download__background-image__secondary-image {
      position: absolute;
      bottom: 0;
      left: -100px;
      height: 558px;
      width: 334.6px;
      background-size: contain;
      background-position: bottom;
      background-repeat: no-repeat; }
    @media (max-width: 991.98px) {
      .auth-download__background-image {
        -webkit-transform: scaleX(-1);
                transform: scaleX(-1);
        height: 348px; }
        .auth-download__background-image__secondary-image {
          left: -50px;
          width: 228.85px;
          height: 351px; } }
  .auth-download__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 39px; }
    .auth-download__content__logo {
      width: 47px;
      height: 47px;
      aspect-ratio: 1/1;
      margin-bottom: 21px; }
    .auth-download__content h1 {
      font-size: 3rem;
      font-family: var(--sans-serif-font-family);
      font-weight: 700;
      width: 80%;
      line-height: 54px; }
    .auth-download__content__subtitle {
      font-size: 1rem;
      font-weight: 400;
      color: var(--primary-dark);
      opacity: 0.58;
      margin-top: 21px; }
    .auth-download__content__qrs-wrapper {
      display: flex;
      align-items: flex-start;
      grid-gap: 59px;
      gap: 59px;
      margin-top: 30px; }
      .auth-download__content__qrs-wrapper__qr-container {
        display: flex;
        flex-direction: column;
        align-items: center; }
        .auth-download__content__qrs-wrapper__qr-container p {
          color: var(--primary-dark);
          opacity: 0.58;
          font-size: 18px;
          font-weight: 600;
          margin-bottom: 27px; }
        .auth-download__content__qrs-wrapper__qr-container img {
          margin-bottom: 21px; }
        .auth-download__content__qrs-wrapper__qr-container__qr {
          border-radius: 0.5rem;
          box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
          width: 142px;
          aspect-ratio: 1/1;
          object-fit: contain; }
        .auth-download__content__qrs-wrapper__qr-container a:hover {
          opacity: 1;
          -webkit-transform: scale(1.01);
                  transform: scale(1.01);
          transition: all 0.2s ease-in-out; }
    @media (max-width: 991.98px) {
      .auth-download__content {
        padding-top: 33px;
        align-items: center;
        padding-left: 0; }
        .auth-download__content img {
          margin-bottom: 19px; }
        .auth-download__content h1 {
          font-size: 2.5rem;
          text-align: center;
          line-height: 44.5px; }
        .auth-download__content p {
          text-align: center; }
        .auth-download__content__qrs-wrapper {
          grid-gap: 9px;
          gap: 9px;
          margin-bottom: 96px; }
          .auth-download__content__qrs-wrapper__qr-container p {
            display: none; }
          .auth-download__content__qrs-wrapper__qr-container__qr {
            display: none; } }

h1 {
  text-align: left; }

h1 {
  text-align: left; }

.analytics-home {
  display: flex;
  flex-direction: column;
  height: 80vh;
  width: 76.5%;
  margin: 0 auto;
  margin-top: 5vh; }
  .analytics-home p {
    font-weight: 300; }
  .analytics-home .analytics-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 2rem;
    background-color: var(--primary-white);
    margin-top: 1.2rem; }

.card-wrapper {
  display: flex;
  flex-direction: column;
  justify-items: center;
  padding: 1.5rem;
  background-color: var(--pure-white);
  border-radius: 0.5rem; }
  .card-wrapper .h2 {
    font-weight: 300; }

.filter-card {
  display: flex;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
  width: 100%; }
  .filter-card h2 {
    font-weight: 500; }
  .filter-card .input-container {
    display: flex;
    align-items: center;
    width: 100%;
    grid-gap: 1rem;
    gap: 1rem; }
    .filter-card .input-container .form-group {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%; }
      .filter-card .input-container .form-group label {
        font-weight: 300;
        margin-bottom: 0.5rem;
        font-size: 0.875rem; }
      .filter-card .input-container .form-group .react-datepicker-wrapper {
        width: 100%; }
        .filter-card .input-container .form-group .react-datepicker-wrapper input {
          font-size: 1rem;
          color: var(--primary-dark);
          height: 57px;
          border-radius: 0.5rem; }
    .filter-card .input-container input {
      width: 100%;
      padding-left: 1rem; }

.counter {
  padding: 1.5rem 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-radius: 1rem; }
  .counter h1 {
    font-size: 1.5rem;
    font-family: var(--sans-serif-font-family);
    font-weight: 600; }
  .counter p {
    font-size: 0.8rem;
    margin-top: -0.5rem; }

.icon-wrapper-counter {
  height: 2rem;
  width: 2rem;
  min-height: 2rem;
  min-width: 2rem;
  background-color: var(--primary-dark);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center; }

h1 {
  text-align: left; }

h1 {
  text-align: left; }

h1 {
  text-align: left; }

.rpm-signup-desktop {
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 35vw 65vw;
  min-height: 100vh;
  overflow: hidden; }
  @media (max-width: 991.98px) {
    .rpm-signup-desktop {
      grid-template-columns: 50vw 50vw; } }
  .rpm-signup-desktop .sidebar {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: var(--primary-dark);
    height: 100%;
    max-height: 100%; }
    .rpm-signup-desktop .sidebar #logo {
      position: absolute;
      top: 2rem;
      left: 2rem;
      width: 6.6975rem;
      object-fit: contain; }
    .rpm-signup-desktop .sidebar .sidebar-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      height: 100%;
      padding: 0 2rem;
      width: 100%;
      grid-gap: 1rem;
      gap: 1rem; }
      .rpm-signup-desktop .sidebar .sidebar-content #rpm-logo {
        height: 3rem;
        object-fit: contain; }
      .rpm-signup-desktop .sidebar .sidebar-content #overhead-icon {
        height: 2.5rem;
        object-fit: contain; }
      .rpm-signup-desktop .sidebar .sidebar-content h1 {
        font-size: 2rem;
        font-weight: 700;
        color: var(--pure-white); }
      .rpm-signup-desktop .sidebar .sidebar-content .description {
        font-weight: 300; }
      .rpm-signup-desktop .sidebar .sidebar-content .description-bold {
        font-weight: 600; }
      .rpm-signup-desktop .sidebar .sidebar-content p {
        color: var(--pure-white); }
      .rpm-signup-desktop .sidebar .sidebar-content .label {
        font-weight: 600; }
      .rpm-signup-desktop .sidebar .sidebar-content .form {
        display: flex;
        flex-direction: column;
        grid-gap: 1rem;
        gap: 1rem;
        width: 100%; }
        .rpm-signup-desktop .sidebar .sidebar-content .form .input-wrapper {
          display: flex;
          flex-direction: column;
          grid-gap: 0.5rem;
          gap: 0.5rem;
          width: 100%;
          position: relative; }
          .rpm-signup-desktop .sidebar .sidebar-content .form .input-wrapper input {
            width: 100%;
            height: 3rem;
            border: none;
            border-radius: 0.5rem;
            padding: 0 1rem;
            font-size: 1rem;
            color: var(--primary-dark);
            background-color: var(--pure-white);
            padding-left: 3rem; }
          .rpm-signup-desktop .sidebar .sidebar-content .form .input-wrapper .lock {
            position: absolute;
            top: 0.7rem;
            left: 0.8rem;
            height: 1.5rem;
            object-fit: contain; }
        .rpm-signup-desktop .sidebar .sidebar-content .form .simple-input {
          padding-left: 1rem; }
          .rpm-signup-desktop .sidebar .sidebar-content .form .simple-input::-webkit-input-placeholder {
            font-weight: 300; }
          .rpm-signup-desktop .sidebar .sidebar-content .form .simple-input::placeholder {
            font-weight: 300; }
        .rpm-signup-desktop .sidebar .sidebar-content .form .error-message {
          color: var(--primary-alerts);
          font-size: 0.8rem;
          font-weight: 500; }
        .rpm-signup-desktop .sidebar .sidebar-content .form .terms-and-conditions {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          grid-gap: 1rem;
          gap: 1rem;
          width: 100%; }
          .rpm-signup-desktop .sidebar .sidebar-content .form .terms-and-conditions__text-wrapper {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            grid-gap: 0.5rem;
            gap: 0.5rem;
            margin-top: 0.3rem; }
            .rpm-signup-desktop .sidebar .sidebar-content .form .terms-and-conditions__text-wrapper__text {
              font-size: 0.75rem;
              font-weight: 600;
              color: var(--pure-white);
              cursor: pointer; }
            .rpm-signup-desktop .sidebar .sidebar-content .form .terms-and-conditions__text-wrapper ul {
              padding-left: 1rem; }
              .rpm-signup-desktop .sidebar .sidebar-content .form .terms-and-conditions__text-wrapper ul li {
                font-size: 0.75rem;
                font-weight: 400;
                color: var(--pure-white); }
              .rpm-signup-desktop .sidebar .sidebar-content .form .terms-and-conditions__text-wrapper ul a {
                cursor: pointer;
                text-decoration: underline;
                color: var(--pure-white); }
        .rpm-signup-desktop .sidebar .sidebar-content .form .password-requirements {
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-template-rows: auto;
          grid-gap: .25rem;
          gap: .25rem;
          width: 100%;
          margin-top: 1rem; }
          .rpm-signup-desktop .sidebar .sidebar-content .form .password-requirements .title {
            grid-column: 1 / 3;
            font-size: 0.75rem;
            font-weight: 600;
            margin-bottom: .25rem; }
          .rpm-signup-desktop .sidebar .sidebar-content .form .password-requirements__item {
            display: flex;
            flex-direction: row;
            align-items: center;
            grid-gap: 0.5rem;
            gap: 0.5rem;
            width: 100%; }
            .rpm-signup-desktop .sidebar .sidebar-content .form .password-requirements__item__icon {
              object-fit: contain;
              font-size: .75rem;
              color: #e8744f; }
            .rpm-signup-desktop .sidebar .sidebar-content .form .password-requirements__item__text {
              font-size: 0.75rem;
              font-weight: 400;
              color: var(--pure-white);
              white-space: nowrap; }
        .rpm-signup-desktop .sidebar .sidebar-content .form button {
          width: 100%;
          height: 3rem;
          border: none;
          border-radius: 0.5rem;
          font-size: 1rem;
          font-weight: 600;
          color: var(--primary-dark);
          background-color: var(--pure-white);
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center; }
        .rpm-signup-desktop .sidebar .sidebar-content .form .react-tel-input input {
          width: 100%; }
        .rpm-signup-desktop .sidebar .sidebar-content .form .react-tel-input .flag-dropdown {
          background: var(--pure-white);
          border: none;
          border-radius: 0.525rem; }
          .rpm-signup-desktop .sidebar .sidebar-content .form .react-tel-input .flag-dropdown .selected-flag {
            background: transparent;
            padding-left: 1rem; }
        .rpm-signup-desktop .sidebar .sidebar-content .form .react-tel-input .country-list {
          box-shadow: 0px 4px 20px 1px rgba(0, 0, 0, 0.1);
          border-radius: 0.525rem; }
          .rpm-signup-desktop .sidebar .sidebar-content .form .react-tel-input .country-list li {
            font-weight: 300; }
        .rpm-signup-desktop .sidebar .sidebar-content .form .address-container,
        .rpm-signup-desktop .sidebar .sidebar-content .form .insurance-info-container {
          width: 100%;
          background: var(--pure-white);
          height: 9.9375rem;
          border-radius: 0.625rem;
          position: relative;
          padding: 2rem;
          display: flex;
          flex-direction: column;
          justify-content: center; }
          .rpm-signup-desktop .sidebar .sidebar-content .form .address-container p,
          .rpm-signup-desktop .sidebar .sidebar-content .form .insurance-info-container p {
            color: var(--primary-dark); }
          .rpm-signup-desktop .sidebar .sidebar-content .form .address-container .address-title,
          .rpm-signup-desktop .sidebar .sidebar-content .form .address-container .insurance-info-title,
          .rpm-signup-desktop .sidebar .sidebar-content .form .insurance-info-container .address-title,
          .rpm-signup-desktop .sidebar .sidebar-content .form .insurance-info-container .insurance-info-title {
            font-weight: 600;
            font-size: 1.125rem;
            margin-bottom: 0.5rem; }
          .rpm-signup-desktop .sidebar .sidebar-content .form .address-container .address,
          .rpm-signup-desktop .sidebar .sidebar-content .form .address-container .insurance-info,
          .rpm-signup-desktop .sidebar .sidebar-content .form .insurance-info-container .address,
          .rpm-signup-desktop .sidebar .sidebar-content .form .insurance-info-container .insurance-info {
            font-weight: 300;
            font-size: 1rem;
            color: #6d786e; }
          .rpm-signup-desktop .sidebar .sidebar-content .form .address-container button,
          .rpm-signup-desktop .sidebar .sidebar-content .form .insurance-info-container button {
            position: absolute;
            top: 1.5rem;
            right: 2rem;
            background: transparent;
            border: none;
            cursor: pointer;
            height: 1.5rem;
            width: 1.5rem;
            object-fit: contain;
            text-decoration: underline;
            font-size: 500; }
      .rpm-signup-desktop .sidebar .sidebar-content .date-picker-container {
        display: flex;
        align-items: center;
        flex-direction: row;
        grid-gap: 1rem;
        gap: 1rem; }
        .rpm-signup-desktop .sidebar .sidebar-content .date-picker-container .small-input,
        .rpm-signup-desktop .sidebar .sidebar-content .date-picker-container .medium-input {
          width: 4.4375rem;
          height: 2.8125rem;
          padding-left: 0.5rem;
          outline: none; }
        .rpm-signup-desktop .sidebar .sidebar-content .date-picker-container .medium-input {
          width: 5.625rem; }
      .rpm-signup-desktop .sidebar .sidebar-content .genders-grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 1fr 1fr;
        grid-gap: 1rem;
        gap: 1rem; }
        .rpm-signup-desktop .sidebar .sidebar-content .genders-grid button {
          border-radius: 0.625rem;
          border: 1px solid #f2f1ec;
          width: 100%;
          background-color: transparent;
          height: 2.8125rem;
          color: #f2f1ec;
          font-weight: 300;
          outline: none; }
        .rpm-signup-desktop .sidebar .sidebar-content .genders-grid .prefer-not-to-say {
          grid-column: 1 / 3; }
        .rpm-signup-desktop .sidebar .sidebar-content .genders-grid .selected {
          background-color: var(--pure-white);
          color: var(--primary-dark);
          font-weight: 600; }
        .rpm-signup-desktop .sidebar .sidebar-content .genders-grid .next-button {
          background-color: var(--pure-white);
          color: var(--primary-dark); }
  .rpm-signup-desktop .content {
    background-color: var(--primary-white);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    max-height: 100vh;
    padding: 2rem;
    width: 100%; }
    @media (max-width: 991.98px) {
      .rpm-signup-desktop .content {
        padding: 1rem; } }
    .rpm-signup-desktop .content .carousel {
      max-width: 50rem;
      width: 100%;
      height: 100%;
      display: flex;
      flex-wrap: nowrap;
      justify-content: flex-start;
      overflow: hidden;
      grid-gap: 0;
      gap: 0;
      grid-gap: 0;
      box-shadow: 0px -4px 20px 1px rgba(0, 0, 0, 0.05);
      border-radius: 0.65rem; }
      .rpm-signup-desktop .content .carousel .carousel-item {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; }
        .rpm-signup-desktop .content .carousel .carousel-item .photo-wrapper {
          width: 100%;
          height: 60%;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative; }
          .rpm-signup-desktop .content .carousel .carousel-item .photo-wrapper .photo {
            width: 100%;
            height: 100%;
            object-fit: cover; }
          .rpm-signup-desktop .content .carousel .carousel-item .photo-wrapper .arrow-wrapper {
            position: absolute;
            top: 50%;
            -webkit-transform: translateY(-50%);
                    transform: translateY(-50%);
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center; }
            .rpm-signup-desktop .content .carousel .carousel-item .photo-wrapper .arrow-wrapper .left-arrow {
              width: 3rem;
              height: 3rem;
              border-radius: 0px 8px 8px 0px;
              display: flex;
              align-items: center;
              justify-content: center;
              background: rgba(255, 255, 255, 0.3);
              outline: none; }
            .rpm-signup-desktop .content .carousel .carousel-item .photo-wrapper .arrow-wrapper .right-arrow {
              width: 3rem;
              height: 3rem;
              border-radius: 0px 8px 8px 0px;
              display: flex;
              align-items: center;
              justify-content: center;
              background: rgba(255, 255, 255, 0.3);
              -webkit-transform: rotate(180deg);
                      transform: rotate(180deg);
              outline: none; }
        .rpm-signup-desktop .content .carousel .carousel-item .carousel-item-content {
          background-color: var(--pure-white);
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          grid-gap: 1rem;
          gap: 1rem;
          width: 100%;
          height: 100%; }
          .rpm-signup-desktop .content .carousel .carousel-item .carousel-item-content .icon {
            width: 2rem;
            height: 2rem;
            object-fit: contain;
            display: flex;
            justify-content: center;
            align-items: center; }
          .rpm-signup-desktop .content .carousel .carousel-item .carousel-item-content .title {
            font-size: 1.5rem;
            font-weight: 700;
            color: var(--primary-dark);
            text-align: center; }
          .rpm-signup-desktop .content .carousel .carousel-item .carousel-item-content .description {
            font-size: 14px;
            font-weight: 300;
            color: var(--primary-dark);
            width: 70%;
            text-align: center; }
          .rpm-signup-desktop .content .carousel .carousel-item .carousel-item-content .line-container {
            grid-gap: 3px;
            gap: 3px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 1rem; }
            .rpm-signup-desktop .content .carousel .carousel-item .carousel-item-content .line-container .line {
              width: 14px;
              height: 3px;
              border-radius: 0.5rem;
              cursor: pointer; }
  .rpm-signup-desktop .background-image {
    height: 100vh;
    width: 100%;
    object-fit: cover; }

h1 {
  text-align: left; }

.rpm-signup-mobile {
  position: relative;
  height: 100vh; }
  .rpm-signup-mobile .background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; }
  .rpm-signup-mobile .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8); }
  .rpm-signup-mobile .content {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between; }
    .rpm-signup-mobile .content #rpm-logo {
      width: 3rem;
      height: 3rem;
      object-fit: contain;
      margin-left: 1rem;
      margin-bottom: 1rem; }
    .rpm-signup-mobile .content .title {
      font-size: 2rem;
      font-weight: 700;
      color: var(--pure-white);
      text-align: left;
      font-family: var(--serif-font-family);
      width: 100%;
      padding: 0 1rem;
      margin-bottom: 1rem; }
    .rpm-signup-mobile .content .subtitle {
      color: var(--pure-white);
      font-weight: 300;
      margin-bottom: 2rem;
      width: 100%;
      text-align: left;
      padding: 0 1rem; }
    .rpm-signup-mobile .content .form {
      background: var(--primary-white);
      border-top-left-radius: 2rem;
      border-top-right-radius: 2rem;
      height: 55vh;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      padding: 2.5rem 1.125rem 2rem 1.125rem;
      width: 100%; }
      .rpm-signup-mobile .content .form .address-container {
        width: 100%;
        background: var(--pure-white);
        height: 9.9375rem;
        border-radius: 0.625rem;
        position: relative;
        padding: 2rem;
        display: flex;
        flex-direction: column;
        justify-content: center; }
        .rpm-signup-mobile .content .form .address-container p {
          color: var(--primary-dark); }
        .rpm-signup-mobile .content .form .address-container .address-title {
          font-weight: 600;
          font-size: 1.125rem;
          margin-bottom: 0.5rem; }
        .rpm-signup-mobile .content .form .address-container .address {
          font-weight: 300;
          font-size: 1rem;
          color: #6d786e; }
        .rpm-signup-mobile .content .form .address-container button {
          position: absolute;
          top: 1.5rem;
          right: 2rem;
          background: transparent;
          border: none;
          cursor: pointer;
          height: 1.5rem;
          width: 1.5rem;
          object-fit: contain;
          text-decoration: underline;
          font-size: 500; }
      .rpm-signup-mobile .content .form .form-title {
        font-size: 1.125rem;
        font-weight: 500;
        font-family: var(--sans-serif-font-family);
        color: var(--primary-dark);
        margin-bottom: 1rem;
        width: 100%;
        text-align: left; }
      .rpm-signup-mobile .content .form .inputs-wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        grid-gap: 0.5rem;
        gap: 0.5rem;
        align-items: center;
        justify-content: flex-start;
        height: 100%;
        overflow-y: auto; }
      .rpm-signup-mobile .content .form .input-wrapper {
        position: relative;
        width: 100%;
        margin-bottom: 1rem; }
        .rpm-signup-mobile .content .form .input-wrapper input {
          width: 100%;
          border-color: #f2f1ec;
          border-radius: 10px;
          outline: none; }
          .rpm-signup-mobile .content .form .input-wrapper input::-webkit-input-placeholder {
            font-weight: 300;
            color: var(--primary-dark-green); }
          .rpm-signup-mobile .content .form .input-wrapper input::placeholder {
            font-weight: 300;
            color: var(--primary-dark-green); }
        .rpm-signup-mobile .content .form .input-wrapper .lock {
          position: absolute;
          top: 0.7rem;
          left: 0.8rem;
          height: 1.5rem;
          object-fit: contain; }
        .rpm-signup-mobile .content .form .input-wrapper .react-datepicker-wrapper {
          width: 100%; }
          .rpm-signup-mobile .content .form .input-wrapper .react-datepicker-wrapper .btn-due-date {
            display: flex;
            align-items: center;
            position: relative;
            width: 100%;
            background: var(--pure-white);
            border: 1px solid #f2f1ec;
            border-radius: 0.625rem;
            height: 2.8125rem;
            outline: none; }
            .rpm-signup-mobile .content .form .input-wrapper .react-datepicker-wrapper .btn-due-date p {
              font-size: 1rem;
              font-weight: 300;
              color: #6d786e;
              margin-left: 1rem; }
            .rpm-signup-mobile .content .form .input-wrapper .react-datepicker-wrapper .btn-due-date svg {
              position: absolute;
              right: 1rem;
              top: 0.75rem; }
      .rpm-signup-mobile .content .form .no-icon {
        margin-bottom: 0; }
        .rpm-signup-mobile .content .form .no-icon .simple-input {
          padding-left: 1rem; }
      .rpm-signup-mobile .content .form .genders-grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 1fr 1fr;
        grid-gap: 1rem;
        gap: 1rem;
        width: 100%; }
        .rpm-signup-mobile .content .form .genders-grid button {
          border-radius: 0.625rem;
          border: 1px solid #9c9a9a;
          width: 100%;
          background-color: transparent;
          height: 2.8125rem;
          color: #656565;
          font-weight: 300;
          outline: none; }
        .rpm-signup-mobile .content .form .genders-grid .prefer-not-to-say {
          grid-column: 1 / 3; }
        .rpm-signup-mobile .content .form .genders-grid .selected {
          background-color: var(--primary-dark);
          color: var(--pure-white);
          font-weight: 600; }
        .rpm-signup-mobile .content .form .genders-grid .next-button {
          background-color: var(--pure-white);
          color: var(--primary-dark); }
      .rpm-signup-mobile .content .form .error-message {
        color: var(--primary-alerts);
        font-size: 0.8rem;
        font-weight: 500; }
      .rpm-signup-mobile .content .form .submit-button {
        width: 100%;
        margin-top: 1rem;
        font-size: 1rem;
        font-weight: 500;
        font-family: var(--sans-serif-font-family);
        color: var(--primary-white);
        background: var(--primary-dark);
        border-radius: 10px;
        padding: 0.5rem 0;
        height: 50px; }
      .rpm-signup-mobile .content .form .terms-and-conditions {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        grid-gap: 1rem;
        gap: 1rem;
        width: 100%; }
        .rpm-signup-mobile .content .form .terms-and-conditions__text-wrapper {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          grid-gap: 0.5rem;
          gap: 0.5rem;
          margin-top: 0.3rem; }
          .rpm-signup-mobile .content .form .terms-and-conditions__text-wrapper__text {
            font-size: 0.75rem;
            font-weight: 600;
            color: var(--primary-dark);
            cursor: pointer; }
          .rpm-signup-mobile .content .form .terms-and-conditions__text-wrapper ul {
            padding-left: 1rem; }
            .rpm-signup-mobile .content .form .terms-and-conditions__text-wrapper ul li {
              font-size: 0.75rem;
              font-weight: 400;
              color: var(--primary-dark); }
            .rpm-signup-mobile .content .form .terms-and-conditions__text-wrapper ul a {
              cursor: pointer;
              text-decoration: underline;
              color: var(--primary-dark); }
      .rpm-signup-mobile .content .form .password-requirements {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto;
        grid-gap: 0.25rem;
        gap: 0.25rem;
        width: 100%;
        margin: 0;
        margin-bottom: 2rem; }
        .rpm-signup-mobile .content .form .password-requirements .title {
          grid-column: 1 / 3;
          font-size: 0.75rem;
          font-weight: 600;
          margin-bottom: 0.25rem;
          color: var(--primary-dark);
          font-family: var(--sans-serif-font-family);
          padding: 0; }
        .rpm-signup-mobile .content .form .password-requirements__item {
          display: flex;
          flex-direction: row;
          align-items: center;
          grid-gap: 0.5rem;
          gap: 0.5rem;
          width: 100%; }
          .rpm-signup-mobile .content .form .password-requirements__item__icon {
            object-fit: contain;
            font-size: 0.75rem;
            color: #e8744f; }
          .rpm-signup-mobile .content .form .password-requirements__item__text {
            font-size: 0.75rem;
            font-weight: 400;
            color: var(--primary-dark);
            white-space: nowrap; }
  .rpm-signup-mobile .center {
    align-items: center; }
    .rpm-signup-mobile .center .title {
      text-align: center;
      margin-bottom: 2.8125rem; }

.react-tel-input .flag-dropdown {
  background: var(--pure-white);
  border: none;
  border-radius: 0.525rem; }
  .react-tel-input .flag-dropdown .selected-flag {
    background: transparent;
    padding-left: 1rem; }

.react-tel-input .country-list {
  box-shadow: 0px 4px 20px 1px rgba(0, 0, 0, 0.1);
  border-radius: 0.525rem; }
  .react-tel-input .country-list li {
    font-weight: 300; }

h1 {
  text-align: left; }

.rpm-signup-mobile-carousel {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden; }
  .rpm-signup-mobile-carousel .rpm-signup-mobile-carousel-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    z-index: 1; }
    .rpm-signup-mobile-carousel .rpm-signup-mobile-carousel-wrapper .rpm-signup-mobile-carousel-background-image {
      object-fit: cover;
      width: 100%;
      height: 60vh; }
  .rpm-signup-mobile-carousel .rpm-signup-mobile-carousel-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 55vh;
    padding: 1rem;
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: var(--primary-white);
    z-index: 2;
    border-top-left-radius: 2rem;
    border-top-right-radius: 2rem; }
    .rpm-signup-mobile-carousel .rpm-signup-mobile-carousel-content .rpm-signup-mobile-carousel-content-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      height: 100%;
      flex-wrap: nowrap;
      overflow: hidden;
      position: relative; }
      .rpm-signup-mobile-carousel .rpm-signup-mobile-carousel-content .rpm-signup-mobile-carousel-content-wrapper .rpm-signup-mobile-carousel-content-item {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 1rem;
        grid-gap: 1rem;
        gap: 1rem; }
        .rpm-signup-mobile-carousel .rpm-signup-mobile-carousel-content .rpm-signup-mobile-carousel-content-wrapper .rpm-signup-mobile-carousel-content-item img {
          height: 27px;
          object-fit: contain; }
        .rpm-signup-mobile-carousel .rpm-signup-mobile-carousel-content .rpm-signup-mobile-carousel-content-wrapper .rpm-signup-mobile-carousel-content-item h2 {
          font-size: 1.5rem;
          font-weight: 700;
          color: var(--primary-dark);
          font-family: var(--serif-font-family); }
        .rpm-signup-mobile-carousel .rpm-signup-mobile-carousel-content .rpm-signup-mobile-carousel-content-wrapper .rpm-signup-mobile-carousel-content-item .description {
          font-size: 14px;
          font-weight: 300;
          color: var(--primary-dark); }
        .rpm-signup-mobile-carousel .rpm-signup-mobile-carousel-content .rpm-signup-mobile-carousel-content-wrapper .rpm-signup-mobile-carousel-content-item .reminder {
          font-size: 14px;
          font-weight: 300;
          color: #908e87;
          font-style: italic; }
  .rpm-signup-mobile-carousel .rpm-signup-mobile-carousel-footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 79px;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 3;
    background-color: var(--pure-white);
    border-top-left-radius: 2rem;
    border-top-right-radius: 2rem;
    padding: 0 2rem; }
    .rpm-signup-mobile-carousel .rpm-signup-mobile-carousel-footer .rpm-signup-mobile-carousel-footer-lines {
      display: flex;
      align-items: center;
      justify-content: center;
      grid-gap: 3px;
      gap: 3px;
      width: -webkit-max-content;
      width: max-content; }
      .rpm-signup-mobile-carousel .rpm-signup-mobile-carousel-footer .rpm-signup-mobile-carousel-footer-lines .rpm-signup-mobile-carousel-footer-line {
        width: 14px;
        height: 3px;
        border-radius: 2px; }
    .rpm-signup-mobile-carousel .rpm-signup-mobile-carousel-footer .rpm-signup-mobile-carousel-footer-button {
      height: 3.3rem;
      width: 3.3rem;
      border-radius: 50%;
      background-color: var(--primary-dark);
      display: flex;
      align-items: center;
      justify-content: center; }
      .rpm-signup-mobile-carousel .rpm-signup-mobile-carousel-footer .rpm-signup-mobile-carousel-footer-button svg {
        margin-right: -0.2rem; }

h1 {
  text-align: left; }

h1 {
  text-align: left; }

.rpm-connect-your-device-desktop {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: var(--primary-dark); }
  .rpm-connect-your-device-desktop .rpm-connect-card {
    width: 619px;
    height: 90vh;
    background-color: var(--primary-dark);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    overflow: hidden; }
    .rpm-connect-your-device-desktop .rpm-connect-card .rpm-connect-card-header {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      grid-gap: 1rem;
      gap: 1rem;
      width: 100%;
      min-height: 40%;
      height: 100%;
      position: relative; }
      .rpm-connect-your-device-desktop .rpm-connect-card .rpm-connect-card-header .background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        overflow: hidden;
        background-color: var(--primary-dark); }
      .rpm-connect-your-device-desktop .rpm-connect-card .rpm-connect-card-header .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.8); }
      .rpm-connect-your-device-desktop .rpm-connect-card .rpm-connect-card-header .user-photo {
        width: 5.56rem;
        height: 5.56rem;
        border-radius: 10px 10px 0px 10px;
        border: 2px solid rgba(255, 255, 255, 0.5);
        box-sizing: border-box;
        z-index: 2; }
      .rpm-connect-your-device-desktop .rpm-connect-card .rpm-connect-card-header h1 {
        font-size: 1.5rem;
        font-weight: 700;
        color: var(--pure-white);
        text-align: center;
        width: 70%;
        z-index: 2; }
      .rpm-connect-your-device-desktop .rpm-connect-card .rpm-connect-card-header a {
        font-size: 0.8rem;
        font-weight: 400;
        color: var(--primary-white);
        text-decoration: underline;
        z-index: 2; }
      .rpm-connect-your-device-desktop .rpm-connect-card .rpm-connect-card-header .rpm-progress-bar {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 6px;
        background-color: #595959;
        z-index: 1; }
        .rpm-connect-your-device-desktop .rpm-connect-card .rpm-connect-card-header .rpm-progress-bar .rpm-progress-bar-fill {
          height: 100%;
          background-color: #e8744f;
          width: 20%;
          border-radius: 0px 10px 10px 0px; }
    .rpm-connect-your-device-desktop .rpm-connect-card .rpm-connect-card-body {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      background-color: var(--primary-white);
      padding: 2.5rem 2rem;
      grid-gap: 1rem;
      gap: 1rem; }
      .rpm-connect-your-device-desktop .rpm-connect-card .rpm-connect-card-body .watch-options {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        grid-gap: 1rem;
        gap: 1rem; }
        .rpm-connect-your-device-desktop .rpm-connect-card .rpm-connect-card-body .watch-options .watch-option {
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          height: 5.0625rem;
          border-radius: 10px;
          background-color: var(--pure-white);
          box-shadow: 0px -4px 20px 1px rgba(0, 0, 0, 0.05);
          cursor: pointer;
          outline: none; }
          .rpm-connect-your-device-desktop .rpm-connect-card .rpm-connect-card-body .watch-options .watch-option:hover {
            -webkit-transform: scale(1.01);
                    transform: scale(1.01);
            transition: all 0.2s ease-in-out; }
          .rpm-connect-your-device-desktop .rpm-connect-card .rpm-connect-card-body .watch-options .watch-option img {
            height: 1.6rem;
            object-fit: contain; }
        .rpm-connect-your-device-desktop .rpm-connect-card .rpm-connect-card-body .watch-options .footer-text {
          font-size: 14px;
          color: var(--primary-gray-lightest);
          font-weight: 400; }
        .rpm-connect-your-device-desktop .rpm-connect-card .rpm-connect-card-body .watch-options .no-device {
          position: absolute;
          bottom: 2rem;
          left: 0;
          width: 100%;
          text-align: center;
          text-decoration: underline;
          font-size: 1rem;
          outline: none; }

h1 {
  text-align: left; }

.rpm-connect-your-device-mobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100vh;
  width: 100vw;
  overflow: hidden; }
  .rpm-connect-your-device-mobile .rpm-connect-progress-bar {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: #595959;
    z-index: 1; }
    .rpm-connect-your-device-mobile .rpm-connect-progress-bar .rpm-connect-progress-bar-fill {
      height: 100%;
      background-color: #e8744f;
      width: 20%;
      border-radius: 0px 10px 10px 0px; }
  .rpm-connect-your-device-mobile .background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; }
  .rpm-connect-your-device-mobile .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8); }
  .rpm-connect-your-device-mobile .header {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    grid-gap: 2rem;
    gap: 2rem;
    z-index: 2;
    height: 50vh; }
    .rpm-connect-your-device-mobile .header .user-photo {
      width: 5.56rem;
      height: 5.56rem;
      border-radius: 10px 10px 0px 10px;
      border: 2px solid rgba(255, 255, 255, 0.5);
      box-sizing: border-box; }
    .rpm-connect-your-device-mobile .header h1 {
      font-size: 1.5rem;
      font-weight: 700;
      color: var(--pure-white);
      text-align: center;
      width: 70%; }
    .rpm-connect-your-device-mobile .header button {
      font-size: 0.8rem;
      font-weight: 400;
      color: var(--pure-white);
      text-decoration: underline; }
  .rpm-connect-your-device-mobile .watches-options {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 50vh;
    background-color: var(--primary-white);
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 3rem 1.5rem 2rem 1.5rem;
    border-top-left-radius: 2rem;
    border-top-right-radius: 2rem;
    grid-gap: 1rem;
    gap: 1rem; }
    .rpm-connect-your-device-mobile .watches-options .watch-option {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      height: 5.0625rem;
      border-radius: 10px;
      background-color: var(--pure-white);
      box-shadow: 0px -4px 20px 1px rgba(0, 0, 0, 0.05);
      cursor: pointer; }
      .rpm-connect-your-device-mobile .watches-options .watch-option img {
        height: 4.6rem;
        object-fit: contain; }
    .rpm-connect-your-device-mobile .watches-options .footer-text {
      font-size: 14px;
      color: var(--primary-gray-lightest);
      font-weight: 400; }
    .rpm-connect-your-device-mobile .watches-options .no-device {
      position: absolute;
      bottom: 2rem;
      left: 0;
      width: 100%;
      text-align: center;
      text-decoration: underline;
      font-size: 1rem; }

h1 {
  text-align: left; }

.rpm-fitbit-connection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  background-color: var(--primary-dark); }
  .rpm-fitbit-connection .rpm-wrapper {
    height: 90vh;
    width: 619px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; }
    @media (max-width: 767.98px) {
      .rpm-fitbit-connection .rpm-wrapper {
        width: 100%;
        height: 100vh; } }
    .rpm-fitbit-connection .rpm-wrapper .rpm-fitbit-connection-card {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      height: 100%;
      width: 100%;
      border-radius: 10px;
      overflow: hidden; }
      @media (max-width: 767.98px) {
        .rpm-fitbit-connection .rpm-wrapper .rpm-fitbit-connection-card {
          border-radius: 0; } }
      .rpm-fitbit-connection .rpm-wrapper .rpm-fitbit-connection-card .rpm-fitbit-connection-card-header {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        min-height: 20%;
        width: 100%;
        position: relative; }
        .rpm-fitbit-connection .rpm-wrapper .rpm-fitbit-connection-card .rpm-fitbit-connection-card-header .go-back-button {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 50px;
          width: 50px;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 3;
          outline: none; }
        .rpm-fitbit-connection .rpm-wrapper .rpm-fitbit-connection-card .rpm-fitbit-connection-card-header .background {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          object-fit: cover;
          z-index: 2; }
          @media (max-width: 767.98px) {
            .rpm-fitbit-connection .rpm-wrapper .rpm-fitbit-connection-card .rpm-fitbit-connection-card-header .background {
              height: 120%; } }
        .rpm-fitbit-connection .rpm-wrapper .rpm-fitbit-connection-card .rpm-fitbit-connection-card-header .logo {
          width: 202px;
          object-fit: contain;
          z-index: 3; }
          @media (max-width: 767.98px) {
            .rpm-fitbit-connection .rpm-wrapper .rpm-fitbit-connection-card .rpm-fitbit-connection-card-header .logo {
              width: 144px; } }
        .rpm-fitbit-connection .rpm-wrapper .rpm-fitbit-connection-card .rpm-fitbit-connection-card-header .rpm-fitbit-progress-bar {
          position: absolute;
          bottom: 0;
          left: 0;
          height: 6px;
          width: 100%;
          background-color: #595959;
          z-index: 3; }
          @media (max-width: 767.98px) {
            .rpm-fitbit-connection .rpm-wrapper .rpm-fitbit-connection-card .rpm-fitbit-connection-card-header .rpm-fitbit-progress-bar {
              top: 0;
              bottom: auto;
              height: 3px; } }
          .rpm-fitbit-connection .rpm-wrapper .rpm-fitbit-connection-card .rpm-fitbit-connection-card-header .rpm-fitbit-progress-bar .rpm-fitbit-progress-bar-fill {
            height: 100%;
            width: 30%;
            background-color: #e8744f;
            border-radius: 0 10px 10px 0; }
      .rpm-fitbit-connection .rpm-wrapper .rpm-fitbit-connection-card .rpm-fitbit-connection-card-body {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        padding-top: 1.5rem;
        width: 100%;
        height: 100%;
        grid-gap: 1rem;
        gap: 1rem;
        background-color: var(--primary-white);
        position: relative;
        z-index: 5; }
        @media (max-width: 767.98px) {
          .rpm-fitbit-connection .rpm-wrapper .rpm-fitbit-connection-card .rpm-fitbit-connection-card-body {
            border-radius: 0;
            border-top-left-radius: 30px;
            border-top-right-radius: 30px; } }
        .rpm-fitbit-connection .rpm-wrapper .rpm-fitbit-connection-card .rpm-fitbit-connection-card-body .step {
          display: flex;
          flex-direction: column;
          width: 100%;
          align-items: center;
          justify-content: center;
          position: absolute;
          top: 1.5rem;
          grid-gap: 1rem;
          gap: 1rem; }
        .rpm-fitbit-connection .rpm-wrapper .rpm-fitbit-connection-card .rpm-fitbit-connection-card-body .title {
          font-family: var(--sans-serif-font-family);
          font-size: 18px;
          font-weight: 500; }
        .rpm-fitbit-connection .rpm-wrapper .rpm-fitbit-connection-card .rpm-fitbit-connection-card-body .logo {
          height: 40px;
          width: 40px;
          object-fit: contain; }
        .rpm-fitbit-connection .rpm-wrapper .rpm-fitbit-connection-card .rpm-fitbit-connection-card-body .watch {
          height: 40px;
          object-fit: contain; }
        .rpm-fitbit-connection .rpm-wrapper .rpm-fitbit-connection-card .rpm-fitbit-connection-card-body .noble-and-fitbit {
          height: 62px;
          object-fit: contain; }
        .rpm-fitbit-connection .rpm-wrapper .rpm-fitbit-connection-card .rpm-fitbit-connection-card-body .description {
          width: 75%;
          text-align: center;
          font-weight: 400;
          color: #6b6b6b; }
        .rpm-fitbit-connection .rpm-wrapper .rpm-fitbit-connection-card .rpm-fitbit-connection-card-body .done-button {
          display: flex;
          align-items: center;
          justify-content: center;
          color: var(--pure-white);
          background-color: var(--primary-dark);
          height: 50px;
          width: 50%;
          border-radius: 10px;
          outline: none;
          font-weight: 500;
          cursor: pointer;
          -webkit-user-select: none;
                  user-select: none; }
          @media (max-width: 767.98px) {
            .rpm-fitbit-connection .rpm-wrapper .rpm-fitbit-connection-card .rpm-fitbit-connection-card-body .done-button {
              width: 80%; } }
        .rpm-fitbit-connection .rpm-wrapper .rpm-fitbit-connection-card .rpm-fitbit-connection-card-body .bottom-question {
          bottom: 2rem;
          position: absolute;
          text-align: center;
          width: 100%;
          text-decoration: underline;
          cursor: pointer;
          outline: none; }

h1 {
  text-align: left; }

.rpm-dashboard {
  --rotation-rpm: 0deg;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  overflow-x: hidden; }
  .rpm-dashboard .rpm-dashboard-grid {
    display: grid;
    grid-template-areas: 'roadmaps roadmaps stats-sidebar' 'check-in upcoming-growth-sessions stats-sidebar' 'my-team upcoming-growth-sessions stats-sidebar';
    grid-gap: 1rem;
    gap: 1rem;
    margin: 1rem auto;
    width: 87.5%; }
    @media (max-width: 991.98px) {
      .rpm-dashboard .rpm-dashboard-grid {
        width: 100%;
        grid-template-areas: 'mobile-records' 'roadmaps' 'check-in' 'my-team' 'upcoming-growth-sessions' 'stats-sidebar';
        margin-top: 0;
        padding-bottom: 4rem; } }

html {
  font-size: 14px; }

.rpm-dashboard .roadmaps-container {
  grid-area: roadmaps;
  background-color: var(--primary-white);
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  border-radius: 10px;
  overflow: hidden;
  position: relative; }
  @media (max-width: 991.98px) {
    .rpm-dashboard .roadmaps-container {
      background-color: transparent; } }
  .rpm-dashboard .roadmaps-container header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%; }
    .rpm-dashboard .roadmaps-container header .roadmaps-container-left {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      grid-gap: 0.5rem;
      gap: 0.5rem; }
      .rpm-dashboard .roadmaps-container header .roadmaps-container-left h3 {
        font-size: 1rem;
        font-weight: 500; }
      .rpm-dashboard .roadmaps-container header .roadmaps-container-left p {
        font-size: 0.875rem;
        color: #515151;
        opacity: 0.7;
        font-weight: 400; }
        @media (max-width: 767.98px) {
          .rpm-dashboard .roadmaps-container header .roadmaps-container-left p {
            font-size: 0.7rem; } }
    .rpm-dashboard .roadmaps-container header .browse-all {
      display: flex;
      align-items: center;
      grid-gap: 0.5rem;
      gap: 0.5rem;
      cursor: pointer; }
      .rpm-dashboard .roadmaps-container header .browse-all p {
        font-size: 0.875rem; }
  .rpm-dashboard .roadmaps-container .roadmaps-container-arrow {
    height: 2.5rem;
    width: 2.5rem;
    background-color: var(--pure-white);
    border-radius: 10px;
    box-shadow: 0px 4px 20px 1px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0;
    top: 50%;
    z-index: 9999;
    outline: none; }
  .rpm-dashboard .roadmaps-container .arrow-right {
    right: 0;
    left: unset; }
  .rpm-dashboard .roadmaps-container .roadmaps-container-grid {
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-gap: 1rem;
    gap: 1rem;
    margin-top: 1rem; }
    .rpm-dashboard .roadmaps-container .roadmaps-container-grid .roadmap-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background-color: var(--pure-white);
      height: 282px;
      border-radius: 10px; }
      @media (max-width: 767.98px) {
        .rpm-dashboard .roadmaps-container .roadmaps-container-grid .roadmap-container {
          min-width: 266px; } }
      .rpm-dashboard .roadmaps-container .roadmaps-container-grid .roadmap-container .roadmap-container-header {
        width: 100%;
        height: 171px;
        border-radius: 10px 10px 0 0;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        display: flex;
        flex-direction: column; }
      .rpm-dashboard .roadmaps-container .roadmaps-container-grid .roadmap-container .roadmap-container-footer {
        padding: 1rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 111px;
        grid-gap: 0.5rem;
        gap: 0.5rem;
        position: relative; }
        .rpm-dashboard .roadmaps-container .roadmaps-container-grid .roadmap-container .roadmap-container-footer .title {
          font-weight: 700;
          font-size: 1rem;
          font-family: var(--serif-font-family);
          width: calc(100% - 2rem);
          margin-top: -0.75rem;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis; }
        .rpm-dashboard .roadmaps-container .roadmaps-container-grid .roadmap-container .roadmap-container-footer .continue-btn {
          height: 40px;
          width: 40px;
          border-radius: 50%;
          background-color: var(--primary-dark);
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          right: 0.5rem;
          top: -20px;
          border: 2px solid rgba(255, 255, 255, 0.3); }
  .rpm-dashboard .roadmaps-container .roadmaps-container-steps {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    margin-top: 1rem;
    outline: none; }
    .rpm-dashboard .roadmaps-container .roadmaps-container-steps .roadmaps-container-step {
      width: 14px;
      height: 3px;
      background-color: #d3d0cc;
      border-radius: 10px;
      outline: none; }
    @media (min-width: 768px) {
      .rpm-dashboard .roadmaps-container .roadmaps-container-steps {
        display: none; } }

.rpm-dashboard .stats-sidebar {
  grid-area: stats-sidebar;
  background-color: var(--primary-dark);
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 0.625rem;
  padding: 1.5rem;
  height: 100vh; }
  .rpm-dashboard .stats-sidebar .devices-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: #242424;
    padding: 1rem;
    border-radius: 0.625rem; }

.rpm-dashboard .health-data-records-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  max-height: 100%;
  overflow-y: auto; }
  @media (max-width: 991.98px) {
    .rpm-dashboard .health-data-records-container {
      width: calc(100% - 3rem);
      margin: 0 auto; } }
  .rpm-dashboard .health-data-records-container .end-reached {
    font-size: 0.75rem;
    color: #908e87;
    font-weight: 500;
    text-align: center;
    width: 100%;
    margin-top: 2rem; }
    @media (max-width: 991.98px) {
      .rpm-dashboard .health-data-records-container .end-reached {
        padding-top: 1.25rem;
        border-top: 1px solid rgba(160, 158, 156, 0.5);
        margin-top: 2.5rem; } }

.rpm-dashboard .upcoming-growth-sessions {
  grid-area: upcoming-growth-sessions;
  background-color: var(--primary-white);
  width: 100%;
  min-width: 30vw;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  padding: 1.5rem 0; }
  @media (max-width: 991.98px) {
    .rpm-dashboard .upcoming-growth-sessions {
      background-color: transparent; } }
  .rpm-dashboard .upcoming-growth-sessions h3 {
    font-size: 1rem;
    font-weight: 500;
    width: calc(100% - 3rem);
    margin: 0 auto; }
  .rpm-dashboard .upcoming-growth-sessions .description {
    color: rgba(81, 81, 81, 0.7);
    font-weight: 400;
    font-size: 0.875rem;
    width: calc(100% - 3rem);
    margin: 0 auto; }
  .rpm-dashboard .upcoming-growth-sessions .sessions-container {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    width: 100%;
    grid-gap: 0.5rem;
    gap: 0.5rem; }
    .rpm-dashboard .upcoming-growth-sessions .sessions-container .session {
      height: 114px;
      box-shadow: 0px 4px 20px 1px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      width: calc(100% - 3rem);
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1rem;
      margin: 0 auto; }
      .rpm-dashboard .upcoming-growth-sessions .sessions-container .session .session-left {
        display: flex;
        align-items: center;
        grid-gap: 0.5rem;
        gap: 0.5rem; }
        .rpm-dashboard .upcoming-growth-sessions .sessions-container .session .session-left .date {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding: 0 0.5rem; }
          .rpm-dashboard .upcoming-growth-sessions .sessions-container .session .session-left .date .day {
            font-size: 1.25rem;
            font-weight: 700;
            color: var(--primary-dark); }
          .rpm-dashboard .upcoming-growth-sessions .sessions-container .session .session-left .date .month {
            font-size: 0.875rem;
            font-weight: 600;
            color: var(--primary-dark); }
        .rpm-dashboard .upcoming-growth-sessions .sessions-container .session .session-left img {
          height: 81.6px;
          width: 102px;
          object-fit: cover;
          box-shadow: 0px 4px 20px 1px rgba(0, 0, 0, 0.1);
          border-radius: 8px; }
      .rpm-dashboard .upcoming-growth-sessions .sessions-container .session .session-info {
        display: flex;
        flex-direction: column;
        width: 50%; }
        .rpm-dashboard .upcoming-growth-sessions .sessions-container .session .session-info .session-time {
          color: #838282;
          font-size: 0.8rem;
          font-weight: 600; }
        .rpm-dashboard .upcoming-growth-sessions .sessions-container .session .session-info .session-title {
          font-weight: 400;
          font-size: 1rem;
          color: var(--primary-dark); }

.rpm-dashboard .check-in {
  grid-area: check-in;
  background-color: var(--primary-white);
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 0.625rem;
  padding: 1.5rem 0;
  height: -webkit-min-content;
  height: min-content; }
  @media (max-width: 991.98px) {
    .rpm-dashboard .check-in {
      background-color: transparent; } }
  .rpm-dashboard .check-in h3 {
    font-size: 1rem;
    font-weight: 500;
    width: calc(100% - 3rem);
    margin: 0 auto; }
  .rpm-dashboard .check-in .check-in-card-container {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    width: 100%;
    grid-gap: 0.5rem;
    gap: 0.5rem; }
    .rpm-dashboard .check-in .check-in-card-container .check-in-card {
      min-height: 132px;
      width: 100%;
      display: flex;
      flex-direction: column;
      padding: 1rem;
      background-color: var(--primary-dark);
      box-shadow: 0px 4px 20px 1px rgba(0, 0, 0, 0.1);
      width: calc(100% - 3rem);
      margin: 0 auto;
      border-radius: 10px; }
      .rpm-dashboard .check-in .check-in-card-container .check-in-card .check-in-card-header {
        display: flex;
        align-items: center;
        grid-gap: 0.5rem;
        gap: 0.5rem; }
        .rpm-dashboard .check-in .check-in-card-container .check-in-card .check-in-card-header img {
          height: 38px;
          width: 38px;
          border-radius: 10px 10px 0px 10px;
          object-fit: cover; }
        .rpm-dashboard .check-in .check-in-card-container .check-in-card .check-in-card-header .check-in-card-header-text {
          display: flex;
          flex-direction: column; }
          .rpm-dashboard .check-in .check-in-card-container .check-in-card .check-in-card-header .check-in-card-header-text h4 {
            font-size: 0.875rem;
            font-weight: 400;
            margin: 0;
            color: var(--pure-white); }
          .rpm-dashboard .check-in .check-in-card-container .check-in-card .check-in-card-header .check-in-card-header-text p {
            font-size: 0.75rem;
            font-weight: 400;
            margin: 0;
            color: #a4a2a1; }
      .rpm-dashboard .check-in .check-in-card-container .check-in-card .check-in-card-body {
        margin-top: 0.75rem;
        min-height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        background: rgba(98, 97, 93, 0.5);
        border-radius: 4px;
        grid-gap: 0.5rem;
        gap: 0.5rem; }
        .rpm-dashboard .check-in .check-in-card-container .check-in-card .check-in-card-body .check-in-card-body-date {
          font-size: 0.75rem;
          font-weight: 600;
          margin: 0;
          color: var(--pure-white); }
        .rpm-dashboard .check-in .check-in-card-container .check-in-card .check-in-card-body .check-in-card-body-time {
          font-size: 0.75rem;
          font-weight: 400;
          margin: 0;
          color: var(--pure-white); }
      .rpm-dashboard .check-in .check-in-card-container .check-in-card .check-in-card-footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin-top: 0.5rem; }
        .rpm-dashboard .check-in .check-in-card-container .check-in-card .check-in-card-footer .check-in-card-footer-right {
          display: flex;
          align-items: center;
          grid-gap: 0.5rem;
          gap: 0.5rem; }
          .rpm-dashboard .check-in .check-in-card-container .check-in-card .check-in-card-footer .check-in-card-footer-right p {
            color: var(--pure-white);
            font-size: 0.75rem;
            font-weight: 500; }

.rpm-dashboard .my-team {
  grid-area: my-team;
  background-color: var(--primary-white);
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 1.5rem 0;
  border-radius: 10px; }
  @media (max-width: 991.98px) {
    .rpm-dashboard .my-team {
      background-color: transparent; } }
  .rpm-dashboard .my-team h3 {
    font-size: 1rem;
    font-weight: 500;
    width: calc(100% - 3rem);
    margin: 0 auto; }
  .rpm-dashboard .my-team .my-team-card-container {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    width: 100%;
    grid-gap: 0.5rem;
    gap: 0.5rem; }
    .rpm-dashboard .my-team .my-team-card-container .my-team-card {
      height: 80px;
      width: calc(100% - 3rem);
      margin: 0 auto;
      box-shadow: 0px 4px 20px 1px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      background-color: var(--pure-white);
      display: flex;
      align-items: center;
      padding: 1rem;
      justify-content: space-between; }
      .rpm-dashboard .my-team .my-team-card-container .my-team-card .my-team-card-left {
        display: flex;
        align-items: center;
        grid-gap: 0.5rem;
        gap: 0.5rem; }
        .rpm-dashboard .my-team .my-team-card-container .my-team-card .my-team-card-left img {
          height: 48px;
          width: 48px;
          border-radius: 10px 10px 0px 10px;
          object-fit: cover; }
        .rpm-dashboard .my-team .my-team-card-container .my-team-card .my-team-card-left .my-team-card-left-text {
          display: flex;
          flex-direction: column; }
          .rpm-dashboard .my-team .my-team-card-container .my-team-card .my-team-card-left .my-team-card-left-text h4 {
            font-size: 0.875rem;
            font-weight: 600;
            margin: 0;
            color: var(--primary-dark); }
          .rpm-dashboard .my-team .my-team-card-container .my-team-card .my-team-card-left .my-team-card-left-text p {
            font-size: 0.625rem;
            font-weight: 400;
            margin: 0;
            color: rgba(89, 89, 89, 0.7); }

.rpm-dashboard .mobile-records {
  grid-area: mobile-records; }
  .rpm-dashboard .mobile-records .mobile-records-header {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: var(--primary-dark);
    border-radius: 0 0 35px 35px;
    padding: 1.5rem; }

.rpm-dashboard .stats-sidebar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  width: 100%; }
  .rpm-dashboard .stats-sidebar-header .stats-sidebar-header-left .title {
    color: var(--pure-white);
    font-size: 1.5rem;
    font-weight: 700;
    font-family: var(--serif-font-family);
    width: 85%;
    margin-bottom: .5rem; }
  .rpm-dashboard .stats-sidebar-header .stats-sidebar-header-left .days {
    color: #6b6b6b;
    font-size: 0.875rem;
    font-weight: 500; }
  .rpm-dashboard .stats-sidebar-header .stats-sidebar-header-left .days-left {
    color: #a4a2a1;
    font-size: 0.75rem;
    font-weight: 600; }
    .rpm-dashboard .stats-sidebar-header .stats-sidebar-header-left .days-left span {
      text-decoration: underline; }
  .rpm-dashboard .stats-sidebar-header .stats-sidebar-header-right {
    /* color animation */
    /* 3rd progress bar */ }
    .rpm-dashboard .stats-sidebar-header .stats-sidebar-header-right .circle-wrap {
      width: 90px;
      height: 90px;
      background: #40413f;
      border-radius: 50%; }
    .rpm-dashboard .stats-sidebar-header .stats-sidebar-header-right .circle-wrap .circle .mask,
    .rpm-dashboard .stats-sidebar-header .stats-sidebar-header-right .circle-wrap .circle .fill {
      width: 90px;
      height: 90px;
      position: absolute;
      border-radius: 50%; }
    .rpm-dashboard .stats-sidebar-header .stats-sidebar-header-right .circle-wrap .circle .mask {
      clip: rect(0px, 90px, 90px, 45px); }
    .rpm-dashboard .stats-sidebar-header .stats-sidebar-header-right .circle-wrap .inside-circle {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 70px;
      height: 70px;
      border-radius: 50%;
      background: #343434;
      text-align: center;
      color: #908e87;
      position: absolute;
      z-index: 100;
      font-weight: 600;
      font-size: 1rem;
      margin-top: 10px;
      margin-left: 10px; }
    .rpm-dashboard .stats-sidebar-header .stats-sidebar-header-right .mask .fill {
      clip: rect(0px, 45px, 90px, 0px);
      background-color: #e8744f; }
    .rpm-dashboard .stats-sidebar-header .stats-sidebar-header-right .mask.full,
    .rpm-dashboard .stats-sidebar-header .stats-sidebar-header-right .circle .fill {
      -webkit-animation: fill ease-in-out 3s;
              animation: fill ease-in-out 3s;
      -webkit-transform: rotate(var(--rotation-rpm));
              transform: rotate(var(--rotation-rpm)); }

@-webkit-keyframes fill {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(var(--rotation-rpm));
            transform: rotate(var(--rotation-rpm)); } }

@keyframes fill {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(var(--rotation-rpm));
            transform: rotate(var(--rotation-rpm)); } }

.rpm-dashboard .records-device {
  height: 54px;
  width: 100%;
  background-color: #40413f;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 9px; }
  .rpm-dashboard .records-device .device-left {
    display: flex;
    align-items: center;
    grid-gap: 0.5rem;
    gap: 0.5rem; }
    .rpm-dashboard .records-device .device-left .device-icon {
      background-color: #ffffff;
      height: 40px;
      width: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px 5px 0px 5px; }
      .rpm-dashboard .records-device .device-left .device-icon img {
        height: 25px;
        width: 25px;
        object-fit: contain; }
    .rpm-dashboard .records-device .device-left .device-name {
      display: flex;
      flex-direction: column; }
      .rpm-dashboard .records-device .device-left .device-name .device-title {
        color: #ffffff;
        font-size: 0.875rem;
        font-weight: 500; }
      .rpm-dashboard .records-device .device-left .device-name .device-status {
        color: #908e87;
        font-size: 0.7rem;
        font-weight: 500; }
  .rpm-dashboard .records-device .device-right {
    display: flex;
    align-items: center;
    grid-gap: 0.5rem;
    gap: 0.5rem; }
    .rpm-dashboard .records-device .device-right p {
      font-size: 0.7rem;
      color: #908e87; }

.rpm-dashboard .dashboard-records-footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  margin-top: 1rem; }
  .rpm-dashboard .dashboard-records-footer .last-synced {
    font-size: 0.8rem;
    color: #908e87;
    font-weight: 500; }
  .rpm-dashboard .dashboard-records-footer .sync-now {
    display: flex;
    align-items: center;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    cursor: pointer; }
    .rpm-dashboard .dashboard-records-footer .sync-now p {
      text-decoration: underline;
      font-size: 0.8rem;
      color: #908e87;
      font-weight: 500; }
  @media (max-width: 991.98px) {
    .rpm-dashboard .dashboard-records-footer {
      width: calc(100% - 18px);
      margin: 0 auto;
      margin-top: 1rem; }
      .rpm-dashboard .dashboard-records-footer .last-synced {
        color: var(--pure-white);
        font-size: 0.7rem; }
      .rpm-dashboard .dashboard-records-footer .sync-now p {
        color: var(--pure-white);
        font-size: 0.7rem; } }

.rpm-dashboard .health-data-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 1rem; }
  .rpm-dashboard .health-data-header .title {
    color: #ffffff;
    font-size: 1rem;
    font-weight: 400; }
  @media (max-width: 991.98px) {
    .rpm-dashboard .health-data-header {
      width: calc(100% - 3rem);
      margin: 0 auto;
      margin-top: 1rem; }
      .rpm-dashboard .health-data-header .title {
        color: #515151;
        font-size: 1rem;
        font-weight: 500; } }

.circle-wrap {
  width: 90px;
  height: 90px;
  background: #40413f;
  border-radius: 50%; }

.circle-wrap .circle .mask,
.circle-wrap .circle .fill {
  width: 90px;
  height: 90px;
  position: absolute;
  border-radius: 50%; }

.circle-wrap .circle .mask {
  clip: rect(0px, 90px, 90px, 45px); }

.circle-wrap .inside-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background: #343434;
  text-align: center;
  color: #908e87;
  position: absolute;
  z-index: 100;
  font-weight: 600;
  font-size: 1rem;
  margin-top: 10px;
  margin-left: 10px; }

/* color animation */
/* 3rd progress bar */
.mask .fill {
  clip: rect(0px, 45px, 90px, 0px);
  background-color: #e8744f; }

.mask.full,
.circle .fill {
  -webkit-animation: fill ease-in-out 3s;
          animation: fill ease-in-out 3s;
  -webkit-transform: rotate(var(--rotation-rpm));
          transform: rotate(var(--rotation-rpm)); }

@keyframes fill {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(var(--rotation-rpm));
            transform: rotate(var(--rotation-rpm)); } }

.rpm-dashboard .health-data-record .date {
  font-size: 12px;
  color: #908e87;
  font-weight: 600;
  margin-top: 0.5rem; }

.rpm-dashboard .health-data-record .health-data-record-content {
  display: flex;
  flex-direction: column;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  margin-top: 0.5rem; }
  .rpm-dashboard .health-data-record .health-data-record-content .health-data-record-card {
    height: 56px;
    width: 100%;
    background-color: #ffffff;
    border-radius: 10px;
    display: flex;
    align-items: center;
    padding: 1rem;
    justify-content: space-between; }
    .rpm-dashboard .health-data-record .health-data-record-content .health-data-record-card .health-data-record-card-left {
      display: flex;
      align-items: center;
      grid-gap: 1rem;
      gap: 1rem; }
      .rpm-dashboard .health-data-record .health-data-record-content .health-data-record-card .health-data-record-card-left .title-and-text {
        display: flex;
        flex-direction: column; }
        .rpm-dashboard .health-data-record .health-data-record-content .health-data-record-card .health-data-record-card-left .title-and-text .title-container {
          display: flex;
          align-items: center;
          grid-gap: 0.5rem;
          gap: 0.5rem; }
          .rpm-dashboard .health-data-record .health-data-record-content .health-data-record-card .health-data-record-card-left .title-and-text .title-container h3 {
            font-size: 0.875rem;
            color: #6b6b6b;
            font-weight: 400; }
        .rpm-dashboard .health-data-record .health-data-record-content .health-data-record-card .health-data-record-card-left .title-and-text p {
          font-size: 12px;
          color: #6b6b6b;
          font-weight: 400; }
    .rpm-dashboard .health-data-record .health-data-record-content .health-data-record-card .health-data-record-card-right {
      aspect-ratio: 1/1;
      display: flex;
      width: 32px;
      height: 32px;
      align-items: center;
      justify-content: center;
      background: rgba(136, 160, 136, 0.27);
      border-radius: 3px; }
      .rpm-dashboard .health-data-record .health-data-record-content .health-data-record-card .health-data-record-card-right p {
        color: #7b917b;
        font-weight: 600;
        font-size: 0.75rem; }

.rpm-dashboard .roadmaps-container-skeleton {
  height: 26.375rem;
  background-color: var(--primary-white);
  width: 100%;
  grid-area: roadmaps;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  min-width: 100%; }
  .rpm-dashboard .roadmaps-container-skeleton header {
    height: 26px;
    border-radius: 10px;
    width: 25%; }
  .rpm-dashboard .roadmaps-container-skeleton .roadmap-container-skeleton {
    height: 17.625rem;
    width: 100%;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end; }
    @media (max-width: 767.98px) {
      .rpm-dashboard .roadmaps-container-skeleton .roadmap-container-skeleton {
        min-width: 266px; } }
    .rpm-dashboard .roadmaps-container-skeleton .roadmap-container-skeleton .roadmap-container-footer-skeleton {
      background-color: var(--pure-white);
      width: 100%;
      height: 6.875rem;
      border-radius: 0 0 10px 10px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      padding: 0 1.5rem;
      grid-gap: 0.5rem;
      gap: 0.5rem; }
      .rpm-dashboard .roadmaps-container-skeleton .roadmap-container-skeleton .roadmap-container-footer-skeleton .roadmap-container-footer-skeleton-title {
        height: 1rem;
        width: 100%;
        border-radius: 4px; }
      .rpm-dashboard .roadmaps-container-skeleton .roadmap-container-skeleton .roadmap-container-footer-skeleton .roadmap-container-footer-skeleton-subtitle {
        height: 1rem;
        width: 60%;
        border-radius: 4px; }

.rpm-dashboard .roadmap-skeleton {
  -webkit-animation: skeleton-loading 1s linear infinite alternate;
          animation: skeleton-loading 1s linear infinite alternate; }

@keyframes skeleton-loading {
  0% {
    background-color: #d8d8d8; }
  100% {
    background-color: #EAEAEA; } }

h1 {
  text-align: left; }

.rpm-provider-dashboard__add-staff-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--primary-white);
  max-width: 1140px;
  margin: 0 auto;
  margin-top: 1rem;
  border-radius: 0.625rem;
  min-height: 5.25rem;
  padding: 1rem 2rem; }
  @media (max-width: 767.98px) {
    .rpm-provider-dashboard__add-staff-header {
      flex-direction: column;
      align-items: flex-start;
      margin-top: 0; } }
  .rpm-provider-dashboard__add-staff-header__left {
    display: flex;
    align-items: center;
    grid-gap: 1rem;
    gap: 1rem; }
    .rpm-provider-dashboard__add-staff-header__left__logo {
      height: 3.5rem;
      width: 3.5rem;
      aspect-ratio: 1/1; }
    .rpm-provider-dashboard__add-staff-header__left__title {
      font-size: 1rem;
      font-weight: 500;
      color: var(--primary-dark); }
    @media (max-width: 767.98px) {
      .rpm-provider-dashboard__add-staff-header__left {
        margin-bottom: 1rem; } }
  .rpm-provider-dashboard__add-staff-header__right {
    display: flex;
    align-items: center;
    grid-gap: 1rem;
    gap: 1rem;
    justify-content: flex-end; }
    @media (max-width: 767.98px) {
      .rpm-provider-dashboard__add-staff-header__right {
        flex-direction: column;
        align-items: flex-start; } }
    .rpm-provider-dashboard__add-staff-header__right__button {
      background-color: var(--primary-dark);
      min-width: 8.3125rem;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.5rem 1rem;
      border-radius: 0.3125rem;
      grid-gap: 0.5rem;
      gap: 0.5rem; }
      .rpm-provider-dashboard__add-staff-header__right__button__text {
        color: var(--pure-white);
        font-size: 1rem; }

.rpm-provider-dashboard .rpm-provider-container {
  max-width: 1140px;
  margin: 0 auto;
  margin-top: 1rem;
  margin-bottom: 1rem;
  min-height: calc(100vh - 7rem);
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-color: var(--primary-white);
  border-radius: 0.625rem;
  padding: 2rem; }
  @media (max-width: 767.98px) {
    .rpm-provider-dashboard .rpm-provider-container {
      margin-top: -1rem;
      padding: 0;
      min-height: calc(100vh - 12rem);
      height: calc(100vh - 12rem); } }

.rpm-provider-dashboard .rpm-provider-container .rpm-provider-cards-container,
.rpm-provider-dashboard .rpm-coach-container .rpm-provider-cards-container,
.rpm-coach-dashboard .rpm-provider-container .rpm-provider-cards-container,
.rpm-coach-dashboard .rpm-coach-container .rpm-provider-cards-container {
  height: 9.75rem;
  width: 100%;
  background-color: var(--primary-dark);
  border-radius: 0.625rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  padding: 1.125rem 0; }
  .rpm-provider-dashboard .rpm-provider-container .rpm-provider-cards-container .rpm-provider-card,
  .rpm-provider-dashboard .rpm-coach-container .rpm-provider-cards-container .rpm-provider-card,
  .rpm-coach-dashboard .rpm-provider-container .rpm-provider-cards-container .rpm-provider-card,
  .rpm-coach-dashboard .rpm-coach-container .rpm-provider-cards-container .rpm-provider-card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    padding-left: 2rem;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    border-right: 1px solid #000000; }
    .rpm-provider-dashboard .rpm-provider-container .rpm-provider-cards-container .rpm-provider-card .rpm-provider-card-header,
    .rpm-provider-dashboard .rpm-coach-container .rpm-provider-cards-container .rpm-provider-card .rpm-provider-card-header,
    .rpm-coach-dashboard .rpm-provider-container .rpm-provider-cards-container .rpm-provider-card .rpm-provider-card-header,
    .rpm-coach-dashboard .rpm-coach-container .rpm-provider-cards-container .rpm-provider-card .rpm-provider-card-header {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      grid-gap: 0.5rem;
      gap: 0.5rem; }
      .rpm-provider-dashboard .rpm-provider-container .rpm-provider-cards-container .rpm-provider-card .rpm-provider-card-header .rpm-provider-card-header-icon,
      .rpm-provider-dashboard .rpm-coach-container .rpm-provider-cards-container .rpm-provider-card .rpm-provider-card-header .rpm-provider-card-header-icon,
      .rpm-coach-dashboard .rpm-provider-container .rpm-provider-cards-container .rpm-provider-card .rpm-provider-card-header .rpm-provider-card-header-icon,
      .rpm-coach-dashboard .rpm-coach-container .rpm-provider-cards-container .rpm-provider-card .rpm-provider-card-header .rpm-provider-card-header-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 1.5625rem;
        min-width: 1.5625rem;
        aspect-ratio: 1/1;
        border-radius: 50%;
        background-color: #231f20; }
      .rpm-provider-dashboard .rpm-provider-container .rpm-provider-cards-container .rpm-provider-card .rpm-provider-card-header .rpm-provider-card-header-text,
      .rpm-provider-dashboard .rpm-coach-container .rpm-provider-cards-container .rpm-provider-card .rpm-provider-card-header .rpm-provider-card-header-text,
      .rpm-coach-dashboard .rpm-provider-container .rpm-provider-cards-container .rpm-provider-card .rpm-provider-card-header .rpm-provider-card-header-text,
      .rpm-coach-dashboard .rpm-coach-container .rpm-provider-cards-container .rpm-provider-card .rpm-provider-card-header .rpm-provider-card-header-text {
        color: #908e87;
        font-weight: 500; }
    .rpm-provider-dashboard .rpm-provider-container .rpm-provider-cards-container .rpm-provider-card .rpm-provider-card-number-container,
    .rpm-provider-dashboard .rpm-coach-container .rpm-provider-cards-container .rpm-provider-card .rpm-provider-card-number-container,
    .rpm-coach-dashboard .rpm-provider-container .rpm-provider-cards-container .rpm-provider-card .rpm-provider-card-number-container,
    .rpm-coach-dashboard .rpm-coach-container .rpm-provider-cards-container .rpm-provider-card .rpm-provider-card-number-container {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      grid-gap: 0.5rem;
      gap: 0.5rem; }
      .rpm-provider-dashboard .rpm-provider-container .rpm-provider-cards-container .rpm-provider-card .rpm-provider-card-number-container .rpm-provider-card-number,
      .rpm-provider-dashboard .rpm-coach-container .rpm-provider-cards-container .rpm-provider-card .rpm-provider-card-number-container .rpm-provider-card-number,
      .rpm-coach-dashboard .rpm-provider-container .rpm-provider-cards-container .rpm-provider-card .rpm-provider-card-number-container .rpm-provider-card-number,
      .rpm-coach-dashboard .rpm-coach-container .rpm-provider-cards-container .rpm-provider-card .rpm-provider-card-number-container .rpm-provider-card-number {
        font-family: var(--sans-serif-font-family);
        font-weight: 600;
        font-size: 2.25rem;
        color: var(--pure-white); }
      .rpm-provider-dashboard .rpm-provider-container .rpm-provider-cards-container .rpm-provider-card .rpm-provider-card-number-container .rpm-provider-card-percentage,
      .rpm-provider-dashboard .rpm-coach-container .rpm-provider-cards-container .rpm-provider-card .rpm-provider-card-number-container .rpm-provider-card-percentage,
      .rpm-coach-dashboard .rpm-provider-container .rpm-provider-cards-container .rpm-provider-card .rpm-provider-card-number-container .rpm-provider-card-percentage,
      .rpm-coach-dashboard .rpm-coach-container .rpm-provider-cards-container .rpm-provider-card .rpm-provider-card-number-container .rpm-provider-card-percentage {
        width: 2.93rem;
        height: 1.875rem;
        border-radius: 1.5rem;
        background-color: #231f20;
        display: flex;
        align-items: center;
        justify-content: center; }
        .rpm-provider-dashboard .rpm-provider-container .rpm-provider-cards-container .rpm-provider-card .rpm-provider-card-number-container .rpm-provider-card-percentage .rpm-provider-card-percentage-text,
        .rpm-provider-dashboard .rpm-coach-container .rpm-provider-cards-container .rpm-provider-card .rpm-provider-card-number-container .rpm-provider-card-percentage .rpm-provider-card-percentage-text,
        .rpm-coach-dashboard .rpm-provider-container .rpm-provider-cards-container .rpm-provider-card .rpm-provider-card-number-container .rpm-provider-card-percentage .rpm-provider-card-percentage-text,
        .rpm-coach-dashboard .rpm-coach-container .rpm-provider-cards-container .rpm-provider-card .rpm-provider-card-number-container .rpm-provider-card-percentage .rpm-provider-card-percentage-text {
          color: #908e87;
          font-size: 0.875rem; }
    .rpm-provider-dashboard .rpm-provider-container .rpm-provider-cards-container .rpm-provider-card .rpm-provider-card-footer,
    .rpm-provider-dashboard .rpm-coach-container .rpm-provider-cards-container .rpm-provider-card .rpm-provider-card-footer,
    .rpm-coach-dashboard .rpm-provider-container .rpm-provider-cards-container .rpm-provider-card .rpm-provider-card-footer,
    .rpm-coach-dashboard .rpm-coach-container .rpm-provider-cards-container .rpm-provider-card .rpm-provider-card-footer {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      grid-gap: 0.5rem;
      gap: 0.5rem; }
      .rpm-provider-dashboard .rpm-provider-container .rpm-provider-cards-container .rpm-provider-card .rpm-provider-card-footer .rpm-provider-card-footer-icon,
      .rpm-provider-dashboard .rpm-coach-container .rpm-provider-cards-container .rpm-provider-card .rpm-provider-card-footer .rpm-provider-card-footer-icon,
      .rpm-coach-dashboard .rpm-provider-container .rpm-provider-cards-container .rpm-provider-card .rpm-provider-card-footer .rpm-provider-card-footer-icon,
      .rpm-coach-dashboard .rpm-coach-container .rpm-provider-cards-container .rpm-provider-card .rpm-provider-card-footer .rpm-provider-card-footer-icon {
        -webkit-transform: rotate(45deg);
                transform: rotate(45deg); }
      .rpm-provider-dashboard .rpm-provider-container .rpm-provider-cards-container .rpm-provider-card .rpm-provider-card-footer .rpm-provider-card-footer-text,
      .rpm-provider-dashboard .rpm-coach-container .rpm-provider-cards-container .rpm-provider-card .rpm-provider-card-footer .rpm-provider-card-footer-text,
      .rpm-coach-dashboard .rpm-provider-container .rpm-provider-cards-container .rpm-provider-card .rpm-provider-card-footer .rpm-provider-card-footer-text,
      .rpm-coach-dashboard .rpm-coach-container .rpm-provider-cards-container .rpm-provider-card .rpm-provider-card-footer .rpm-provider-card-footer-text {
        font-size: 0.875rem;
        color: #908e87; }

.rpm-provider-dashboard .rpm-provider-container .rpm-provider-mobile-summary-card,
.rpm-provider-dashboard .rpm-coach-container .rpm-provider-mobile-summary-card,
.rpm-coach-dashboard .rpm-provider-container .rpm-provider-mobile-summary-card,
.rpm-coach-dashboard .rpm-coach-container .rpm-provider-mobile-summary-card {
  height: 15.125rem;
  width: 100%;
  background-color: var(--primary-dark);
  display: flex;
  flex-direction: column;
  border-radius: 0 0 2rem 2rem;
  padding: 2rem 1.5rem; }
  .rpm-provider-dashboard .rpm-provider-container .rpm-provider-mobile-summary-card h1,
  .rpm-provider-dashboard .rpm-coach-container .rpm-provider-mobile-summary-card h1,
  .rpm-coach-dashboard .rpm-provider-container .rpm-provider-mobile-summary-card h1,
  .rpm-coach-dashboard .rpm-coach-container .rpm-provider-mobile-summary-card h1 {
    font-size: 1.5rem;
    color: var(--pure-white);
    font-weight: 700; }
  .rpm-provider-dashboard .rpm-provider-container .rpm-provider-mobile-summary-card__label-and-value,
  .rpm-provider-dashboard .rpm-coach-container .rpm-provider-mobile-summary-card__label-and-value,
  .rpm-coach-dashboard .rpm-provider-container .rpm-provider-mobile-summary-card__label-and-value,
  .rpm-coach-dashboard .rpm-coach-container .rpm-provider-mobile-summary-card__label-and-value {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
    margin-top: 2rem; }
    .rpm-provider-dashboard .rpm-provider-container .rpm-provider-mobile-summary-card__label-and-value__label,
    .rpm-provider-dashboard .rpm-coach-container .rpm-provider-mobile-summary-card__label-and-value__label,
    .rpm-coach-dashboard .rpm-provider-container .rpm-provider-mobile-summary-card__label-and-value__label,
    .rpm-coach-dashboard .rpm-coach-container .rpm-provider-mobile-summary-card__label-and-value__label {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-start;
      grid-gap: 0.5rem;
      gap: 0.5rem; }
      .rpm-provider-dashboard .rpm-provider-container .rpm-provider-mobile-summary-card__label-and-value__label__icon-container,
      .rpm-provider-dashboard .rpm-coach-container .rpm-provider-mobile-summary-card__label-and-value__label__icon-container,
      .rpm-coach-dashboard .rpm-provider-container .rpm-provider-mobile-summary-card__label-and-value__label__icon-container,
      .rpm-coach-dashboard .rpm-coach-container .rpm-provider-mobile-summary-card__label-and-value__label__icon-container {
        height: 2rem;
        width: 2rem;
        aspect-ratio: 1/1;
        border-radius: 50%;
        background-color: #231f20;
        display: flex;
        align-items: center;
        justify-content: center; }
      .rpm-provider-dashboard .rpm-provider-container .rpm-provider-mobile-summary-card__label-and-value__label p,
      .rpm-provider-dashboard .rpm-coach-container .rpm-provider-mobile-summary-card__label-and-value__label p,
      .rpm-coach-dashboard .rpm-provider-container .rpm-provider-mobile-summary-card__label-and-value__label p,
      .rpm-coach-dashboard .rpm-coach-container .rpm-provider-mobile-summary-card__label-and-value__label p {
        color: var(--pure-white);
        font-weight: 500; }
    .rpm-provider-dashboard .rpm-provider-container .rpm-provider-mobile-summary-card__label-and-value__value,
    .rpm-provider-dashboard .rpm-coach-container .rpm-provider-mobile-summary-card__label-and-value__value,
    .rpm-coach-dashboard .rpm-provider-container .rpm-provider-mobile-summary-card__label-and-value__value,
    .rpm-coach-dashboard .rpm-coach-container .rpm-provider-mobile-summary-card__label-and-value__value {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-end; }
      .rpm-provider-dashboard .rpm-provider-container .rpm-provider-mobile-summary-card__label-and-value__value h2,
      .rpm-provider-dashboard .rpm-coach-container .rpm-provider-mobile-summary-card__label-and-value__value h2,
      .rpm-coach-dashboard .rpm-provider-container .rpm-provider-mobile-summary-card__label-and-value__value h2,
      .rpm-coach-dashboard .rpm-coach-container .rpm-provider-mobile-summary-card__label-and-value__value h2 {
        color: var(--pure-white);
        font-weight: 600;
        font-size: 2.5rem; }
      .rpm-provider-dashboard .rpm-provider-container .rpm-provider-mobile-summary-card__label-and-value__value__description,
      .rpm-provider-dashboard .rpm-coach-container .rpm-provider-mobile-summary-card__label-and-value__value__description,
      .rpm-coach-dashboard .rpm-provider-container .rpm-provider-mobile-summary-card__label-and-value__value__description,
      .rpm-coach-dashboard .rpm-coach-container .rpm-provider-mobile-summary-card__label-and-value__value__description {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        grid-gap: 0.5rem;
        gap: 0.5rem; }
        .rpm-provider-dashboard .rpm-provider-container .rpm-provider-mobile-summary-card__label-and-value__value__description p,
        .rpm-provider-dashboard .rpm-coach-container .rpm-provider-mobile-summary-card__label-and-value__value__description p,
        .rpm-coach-dashboard .rpm-provider-container .rpm-provider-mobile-summary-card__label-and-value__value__description p,
        .rpm-coach-dashboard .rpm-coach-container .rpm-provider-mobile-summary-card__label-and-value__value__description p {
          font-size: 0.875rem;
          color: #908e87;
          font-weight: 600; }
  .rpm-provider-dashboard .rpm-provider-container .rpm-provider-mobile-summary-card__steps,
  .rpm-provider-dashboard .rpm-coach-container .rpm-provider-mobile-summary-card__steps,
  .rpm-coach-dashboard .rpm-provider-container .rpm-provider-mobile-summary-card__steps,
  .rpm-coach-dashboard .rpm-coach-container .rpm-provider-mobile-summary-card__steps {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    margin-top: 2rem;
    grid-gap: 0.5rem;
    gap: 0.5rem; }
    .rpm-provider-dashboard .rpm-provider-container .rpm-provider-mobile-summary-card__steps__step,
    .rpm-provider-dashboard .rpm-coach-container .rpm-provider-mobile-summary-card__steps__step,
    .rpm-coach-dashboard .rpm-provider-container .rpm-provider-mobile-summary-card__steps__step,
    .rpm-coach-dashboard .rpm-coach-container .rpm-provider-mobile-summary-card__steps__step {
      width: 0.875rem;
      height: 3px;
      background-color: #231f20; }
    .rpm-provider-dashboard .rpm-provider-container .rpm-provider-mobile-summary-card__steps__step.active,
    .rpm-provider-dashboard .rpm-coach-container .rpm-provider-mobile-summary-card__steps__step.active,
    .rpm-coach-dashboard .rpm-provider-container .rpm-provider-mobile-summary-card__steps__step.active,
    .rpm-coach-dashboard .rpm-coach-container .rpm-provider-mobile-summary-card__steps__step.active {
      background-color: #908e87; }

.rpm-provider-dashboard .rpm-provider-container .search-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2rem 0;
  min-height: 2rem; }
  @media (max-width: 991.98px) {
    .rpm-provider-dashboard .rpm-provider-container .search-container {
      flex-direction: column;
      justify-content: flex-start;
      width: 100%;
      grid-gap: 1rem;
      gap: 1rem;
      padding: 2rem 0; } }
  @media (max-width: 991.98px) and (max-width: 767.98px) {
    .rpm-provider-dashboard .rpm-provider-container .search-container {
      padding: 2rem; } }
  .rpm-provider-dashboard .rpm-provider-container .search-container .left-search-container {
    display: flex;
    align-items: center;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    justify-content: flex-start; }
    @media (max-width: 991.98px) {
      .rpm-provider-dashboard .rpm-provider-container .search-container .left-search-container {
        width: 100%; } }
    .rpm-provider-dashboard .rpm-provider-container .search-container .left-search-container .search-container-title {
      font-size: 1rem;
      font-weight: 500;
      color: #515151;
      width: 100%;
      flex-wrap: nowrap;
      white-space: nowrap; }
    .rpm-provider-dashboard .rpm-provider-container .search-container .left-search-container .search-container-number {
      min-width: 2.8185rem;
      height: 1.625rem;
      background-color: #d5d1cd;
      border-radius: 1.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 0.5rem; }
      .rpm-provider-dashboard .rpm-provider-container .search-container .left-search-container .search-container-number .search-container-number-text {
        font-size: 0.8rem;
        font-weight: 800;
        color: #515151; }
  .rpm-provider-dashboard .rpm-provider-container .search-container .right-search-container {
    display: flex;
    align-items: center;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    justify-content: flex-end;
    height: 2rem;
    width: 100%; }
    .rpm-provider-dashboard .rpm-provider-container .search-container .right-search-container .search-container-input {
      position: relative;
      width: 14.375rem;
      height: 2rem; }
      @media (max-width: 991.98px) {
        .rpm-provider-dashboard .rpm-provider-container .search-container .right-search-container .search-container-input {
          width: 100%; } }
      .rpm-provider-dashboard .rpm-provider-container .search-container .right-search-container .search-container-input svg {
        position: absolute;
        top: 50%;
        left: 0.5rem;
        -webkit-transform: translateY(-50%);
                transform: translateY(-50%); }
      .rpm-provider-dashboard .rpm-provider-container .search-container .right-search-container .search-container-input input {
        width: 100%;
        min-height: 2rem;
        height: 2rem;
        max-height: 2rem;
        border: 1px solid #d3d0cc;
        border-radius: 0.3125rem;
        padding-left: 1.75rem;
        padding-right: 1rem;
        font-size: 0.75rem;
        font-weight: 500;
        color: rgba(52, 52, 52, 0.5); }
        .rpm-provider-dashboard .rpm-provider-container .search-container .right-search-container .search-container-input input:focus {
          outline: none; }
        .rpm-provider-dashboard .rpm-provider-container .search-container .right-search-container .search-container-input input::-webkit-input-placeholder {
          font-size: 0.75rem; }
        .rpm-provider-dashboard .rpm-provider-container .search-container .right-search-container .search-container-input input::placeholder {
          font-size: 0.75rem; }

.rpm-provider-records-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  grid-gap: 1rem;
  gap: 1rem;
  height: 100%; }
  @media (max-width: 991.98px) {
    .rpm-provider-records-container {
      margin-top: 4rem; } }
  @media (max-width: 767.98px) {
    .rpm-provider-records-container {
      margin-top: 0;
      padding-top: 0;
      overflow-y: auto; } }

.rpm-provider-records {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  max-height: 100vh;
  overflow-y: auto; }

.rpm-provider-dashboard .rpm-alerts-wrapper,
.rpm-coach-dashboard .rpm-alerts-wrapper {
  display: flex;
  align-items: center;
  flex-direction: row;
  grid-gap: 1.5rem;
  gap: 1.5rem;
  justify-content: flex-end; }
  .rpm-provider-dashboard .rpm-alerts-wrapper button,
  .rpm-coach-dashboard .rpm-alerts-wrapper button {
    outline: none; }
  .rpm-provider-dashboard .rpm-alerts-wrapper__resend-invitation,
  .rpm-coach-dashboard .rpm-alerts-wrapper__resend-invitation {
    font-size: 0.75rem;
    font-weight: 400;
    color: #000000;
    text-decoration: underline;
    margin-right: -0.25rem; }

.rpm-provider-dashboard .rpm-provider-container .rpm-provider-columns,
.rpm-provider-dashboard .rpm-coach-container .rpm-provider-columns,
.rpm-coach-dashboard .rpm-provider-container .rpm-provider-columns,
.rpm-coach-dashboard .rpm-coach-container .rpm-provider-columns {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  padding: 0.55rem;
  position: relative;
  border-bottom: 1px solid #c4c4c4; }
  .rpm-provider-dashboard .rpm-provider-container .rpm-provider-columns p,
  .rpm-provider-dashboard .rpm-coach-container .rpm-provider-columns p,
  .rpm-coach-dashboard .rpm-provider-container .rpm-provider-columns p,
  .rpm-coach-dashboard .rpm-coach-container .rpm-provider-columns p {
    font-size: 0.75rem;
    font-weight: 500;
    color: #595959; }
  .rpm-provider-dashboard .rpm-provider-container .rpm-provider-columns .rpm-provider-columns,
  .rpm-provider-dashboard .rpm-coach-container .rpm-provider-columns .rpm-provider-columns,
  .rpm-coach-dashboard .rpm-provider-container .rpm-provider-columns .rpm-provider-columns,
  .rpm-coach-dashboard .rpm-coach-container .rpm-provider-columns .rpm-provider-columns {
    max-width: 15.2125rem; }
  .rpm-provider-dashboard .rpm-provider-container .rpm-provider-columns .rpm-provider-columns-statuses,
  .rpm-provider-dashboard .rpm-coach-container .rpm-provider-columns .rpm-provider-columns-statuses,
  .rpm-coach-dashboard .rpm-provider-container .rpm-provider-columns .rpm-provider-columns-statuses,
  .rpm-coach-dashboard .rpm-coach-container .rpm-provider-columns .rpm-provider-columns-statuses {
    position: absolute;
    left: 30%;
    -webkit-transform: translateX(-30%);
            transform: translateX(-30%);
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 2.5rem; }
  .rpm-provider-dashboard .rpm-provider-container .rpm-provider-columns .rpm-provider-columns-alerts,
  .rpm-provider-dashboard .rpm-coach-container .rpm-provider-columns .rpm-provider-columns-alerts,
  .rpm-coach-dashboard .rpm-provider-container .rpm-provider-columns .rpm-provider-columns-alerts,
  .rpm-coach-dashboard .rpm-coach-container .rpm-provider-columns .rpm-provider-columns-alerts {
    padding-right: 1rem; }

.rpm-provider-dashboard .rpm-provider-container .rpm-provider-records,
.rpm-provider-dashboard .rpm-coach-container .rpm-provider-records,
.rpm-coach-dashboard .rpm-provider-container .rpm-provider-records,
.rpm-coach-dashboard .rpm-coach-container .rpm-provider-records {
  display: flex;
  flex-direction: column;
  width: 100%;
  grid-gap: 1rem;
  gap: 1rem;
  height: 100%;
  overflow-y: auto; }

.rpm-provider-dashboard .rpm-provider-container .rpm-provider-record,
.rpm-provider-dashboard .rpm-coach-container .rpm-provider-record,
.rpm-coach-dashboard .rpm-provider-container .rpm-provider-record,
.rpm-coach-dashboard .rpm-coach-container .rpm-provider-record {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: var(--pure-white);
  border-radius: 0.625rem;
  margin-top: 1rem;
  box-shadow: 0px -4px 20px 1px rgba(0, 0, 0, 0.05); }
  .rpm-provider-dashboard .rpm-provider-container .rpm-provider-record .rpm-provider-record-header,
  .rpm-provider-dashboard .rpm-coach-container .rpm-provider-record .rpm-provider-record-header,
  .rpm-coach-dashboard .rpm-provider-container .rpm-provider-record .rpm-provider-record-header,
  .rpm-coach-dashboard .rpm-coach-container .rpm-provider-record .rpm-provider-record-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 4.0625rem;
    padding: 1rem;
    position: relative; }
    .rpm-provider-dashboard .rpm-provider-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-status-container,
    .rpm-provider-dashboard .rpm-coach-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-status-container,
    .rpm-coach-dashboard .rpm-provider-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-status-container,
    .rpm-coach-dashboard .rpm-coach-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-status-container {
      position: absolute;
      left: 30%;
      -webkit-transform: translateX(-30%);
              transform: translateX(-30%);
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 2rem; }
      .rpm-provider-dashboard .rpm-provider-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-status-container .rpm-provider-record-header-status,
      .rpm-provider-dashboard .rpm-coach-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-status-container .rpm-provider-record-header-status,
      .rpm-coach-dashboard .rpm-provider-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-status-container .rpm-provider-record-header-status,
      .rpm-coach-dashboard .rpm-coach-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-status-container .rpm-provider-record-header-status {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        grid-gap: 0.5rem;
        gap: 0.5rem; }
        .rpm-provider-dashboard .rpm-provider-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-status-container .rpm-provider-record-header-status .rpm-provider-record-header-status-dot,
        .rpm-provider-dashboard .rpm-coach-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-status-container .rpm-provider-record-header-status .rpm-provider-record-header-status-dot,
        .rpm-coach-dashboard .rpm-provider-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-status-container .rpm-provider-record-header-status .rpm-provider-record-header-status-dot,
        .rpm-coach-dashboard .rpm-coach-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-status-container .rpm-provider-record-header-status .rpm-provider-record-header-status-dot {
          width: 0.25rem;
          height: 0.25rem;
          border-radius: 50%;
          aspect-ratio: 1/1;
          background-color: #a4a2a1; }
        .rpm-provider-dashboard .rpm-provider-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-status-container .rpm-provider-record-header-status .rpm-provider-record-header-status-text,
        .rpm-provider-dashboard .rpm-coach-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-status-container .rpm-provider-record-header-status .rpm-provider-record-header-status-text,
        .rpm-coach-dashboard .rpm-provider-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-status-container .rpm-provider-record-header-status .rpm-provider-record-header-status-text,
        .rpm-coach-dashboard .rpm-coach-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-status-container .rpm-provider-record-header-status .rpm-provider-record-header-status-text {
          font-size: 0.625rem;
          font-weight: 500;
          color: #a4a2a1; }
      .rpm-provider-dashboard .rpm-provider-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-status-container .with-border,
      .rpm-provider-dashboard .rpm-coach-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-status-container .with-border,
      .rpm-coach-dashboard .rpm-provider-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-status-container .with-border,
      .rpm-coach-dashboard .rpm-coach-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-status-container .with-border {
        border: 1px solid #a4a2a1;
        border-radius: 7.8125rem;
        padding: 0.4rem 0.625rem; }
    .rpm-provider-dashboard .rpm-provider-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-user,
    .rpm-provider-dashboard .rpm-coach-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-user,
    .rpm-coach-dashboard .rpm-provider-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-user,
    .rpm-coach-dashboard .rpm-coach-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-user {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      grid-gap: 0.5rem;
      gap: 0.5rem;
      max-width: 15.2125rem; }
      .rpm-provider-dashboard .rpm-provider-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-user .rpm-provider-record-header-user-avatar,
      .rpm-provider-dashboard .rpm-coach-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-user .rpm-provider-record-header-user-avatar,
      .rpm-coach-dashboard .rpm-provider-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-user .rpm-provider-record-header-user-avatar,
      .rpm-coach-dashboard .rpm-coach-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-user .rpm-provider-record-header-user-avatar {
        position: relative;
        width: 2.5rem;
        height: 2.5rem;
        aspect-ratio: 1/1; }
        .rpm-provider-dashboard .rpm-provider-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-user .rpm-provider-record-header-user-avatar img,
        .rpm-provider-dashboard .rpm-coach-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-user .rpm-provider-record-header-user-avatar img,
        .rpm-coach-dashboard .rpm-provider-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-user .rpm-provider-record-header-user-avatar img,
        .rpm-coach-dashboard .rpm-coach-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-user .rpm-provider-record-header-user-avatar img {
          width: 100%;
          height: 100%;
          object-fit: cover; }
        .rpm-provider-dashboard .rpm-provider-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-user .rpm-provider-record-header-user-avatar .rpm-provider-record-header-user-avatar-text,
        .rpm-provider-dashboard .rpm-coach-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-user .rpm-provider-record-header-user-avatar .rpm-provider-record-header-user-avatar-text,
        .rpm-coach-dashboard .rpm-provider-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-user .rpm-provider-record-header-user-avatar .rpm-provider-record-header-user-avatar-text,
        .rpm-coach-dashboard .rpm-coach-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-user .rpm-provider-record-header-user-avatar .rpm-provider-record-header-user-avatar-text {
          position: absolute;
          top: 50%;
          left: 50%;
          -webkit-transform: translate(-50%, -50%);
                  transform: translate(-50%, -50%);
          font-size: 0.75rem;
          font-weight: 500;
          color: var(--pure-white); }
      .rpm-provider-dashboard .rpm-provider-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-user .rpm-provider-record-header-user-text,
      .rpm-provider-dashboard .rpm-coach-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-user .rpm-provider-record-header-user-text,
      .rpm-coach-dashboard .rpm-provider-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-user .rpm-provider-record-header-user-text,
      .rpm-coach-dashboard .rpm-coach-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-user .rpm-provider-record-header-user-text {
        font-size: 1rem;
        color: var(--primary-dark);
        font-weight: 500;
        width: 75%;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical; }
    .rpm-provider-dashboard .rpm-provider-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-alert,
    .rpm-provider-dashboard .rpm-coach-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-alert,
    .rpm-coach-dashboard .rpm-provider-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-alert,
    .rpm-coach-dashboard .rpm-coach-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-alert {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      position: relative;
      cursor: pointer; }
      .rpm-provider-dashboard .rpm-provider-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-alert .rpm-provider-record-header-alert-icon-container,
      .rpm-provider-dashboard .rpm-coach-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-alert .rpm-provider-record-header-alert-icon-container,
      .rpm-coach-dashboard .rpm-provider-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-alert .rpm-provider-record-header-alert-icon-container,
      .rpm-coach-dashboard .rpm-coach-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-alert .rpm-provider-record-header-alert-icon-container {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center; }
        .rpm-provider-dashboard .rpm-provider-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-alert .rpm-provider-record-header-alert-icon-container .rpm-provider-record-header-alert-icon-square,
        .rpm-provider-dashboard .rpm-coach-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-alert .rpm-provider-record-header-alert-icon-container .rpm-provider-record-header-alert-icon-square,
        .rpm-coach-dashboard .rpm-provider-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-alert .rpm-provider-record-header-alert-icon-container .rpm-provider-record-header-alert-icon-square,
        .rpm-coach-dashboard .rpm-coach-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-alert .rpm-provider-record-header-alert-icon-container .rpm-provider-record-header-alert-icon-square {
          width: 1.25rem;
          height: 1.25rem;
          aspect-ratio: 1/1;
          background-color: #e8744f;
          border-radius: 0.1875rem;
          -webkit-transform: rotate(45deg);
                  transform: rotate(45deg); }
        .rpm-provider-dashboard .rpm-provider-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-alert .rpm-provider-record-header-alert-icon-container .rpm-provider-record-header-alert-icon,
        .rpm-provider-dashboard .rpm-coach-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-alert .rpm-provider-record-header-alert-icon-container .rpm-provider-record-header-alert-icon,
        .rpm-coach-dashboard .rpm-provider-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-alert .rpm-provider-record-header-alert-icon-container .rpm-provider-record-header-alert-icon,
        .rpm-coach-dashboard .rpm-coach-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-alert .rpm-provider-record-header-alert-icon-container .rpm-provider-record-header-alert-icon {
          color: var(--pure-white);
          font-size: 0.7rem;
          font-weight: 800;
          z-index: 10;
          position: absolute; }
      .rpm-provider-dashboard .rpm-provider-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-alert .rpm-provider-record-header-alert-text-container,
      .rpm-provider-dashboard .rpm-coach-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-alert .rpm-provider-record-header-alert-text-container,
      .rpm-coach-dashboard .rpm-provider-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-alert .rpm-provider-record-header-alert-text-container,
      .rpm-coach-dashboard .rpm-coach-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-alert .rpm-provider-record-header-alert-text-container {
        min-width: 5.0625rem;
        height: 1.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #f9dad0;
        border-radius: 0.25rem;
        margin-left: -0.5rem; }
        .rpm-provider-dashboard .rpm-provider-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-alert .rpm-provider-record-header-alert-text-container .rpm-provider-record-header-alert-text,
        .rpm-provider-dashboard .rpm-coach-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-alert .rpm-provider-record-header-alert-text-container .rpm-provider-record-header-alert-text,
        .rpm-coach-dashboard .rpm-provider-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-alert .rpm-provider-record-header-alert-text-container .rpm-provider-record-header-alert-text,
        .rpm-coach-dashboard .rpm-coach-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-alert .rpm-provider-record-header-alert-text-container .rpm-provider-record-header-alert-text {
          color: #e8744f;
          font-size: 0.7rem;
          font-weight: 600;
          text-align: center; }
      .rpm-provider-dashboard .rpm-provider-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-alert .rpm-provider-record-header-alert-popup,
      .rpm-provider-dashboard .rpm-coach-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-alert .rpm-provider-record-header-alert-popup,
      .rpm-coach-dashboard .rpm-provider-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-alert .rpm-provider-record-header-alert-popup,
      .rpm-coach-dashboard .rpm-coach-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-alert .rpm-provider-record-header-alert-popup {
        height: 18rem;
        width: 16rem;
        background-color: var(--pure-white);
        display: flex;
        flex-direction: column;
        align-items: center;
        position: absolute;
        top: -9rem;
        left: -17rem;
        border-radius: 0.5rem;
        box-shadow: 0px 8px 13px rgba(0, 0, 0, 0.2);
        z-index: 999; }
        .rpm-provider-dashboard .rpm-provider-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-alert .rpm-provider-record-header-alert-popup .rpm-provider-record-header-alert-popup-header,
        .rpm-provider-dashboard .rpm-coach-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-alert .rpm-provider-record-header-alert-popup .rpm-provider-record-header-alert-popup-header,
        .rpm-coach-dashboard .rpm-provider-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-alert .rpm-provider-record-header-alert-popup .rpm-provider-record-header-alert-popup-header,
        .rpm-coach-dashboard .rpm-coach-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-alert .rpm-provider-record-header-alert-popup .rpm-provider-record-header-alert-popup-header {
          background-color: #e8744f;
          height: 2.5rem;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-radius: 0.25rem 0.25rem 0 0;
          padding: 0 1rem; }
          .rpm-provider-dashboard .rpm-provider-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-alert .rpm-provider-record-header-alert-popup .rpm-provider-record-header-alert-popup-header .rpm-provider-record-header-alert-popup-header-title-container,
          .rpm-provider-dashboard .rpm-coach-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-alert .rpm-provider-record-header-alert-popup .rpm-provider-record-header-alert-popup-header .rpm-provider-record-header-alert-popup-header-title-container,
          .rpm-coach-dashboard .rpm-provider-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-alert .rpm-provider-record-header-alert-popup .rpm-provider-record-header-alert-popup-header .rpm-provider-record-header-alert-popup-header-title-container,
          .rpm-coach-dashboard .rpm-coach-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-alert .rpm-provider-record-header-alert-popup .rpm-provider-record-header-alert-popup-header .rpm-provider-record-header-alert-popup-header-title-container {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            grid-gap: 0.5rem;
            gap: 0.5rem; }
            .rpm-provider-dashboard .rpm-provider-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-alert .rpm-provider-record-header-alert-popup .rpm-provider-record-header-alert-popup-header .rpm-provider-record-header-alert-popup-header-title-container .rpm-provider-record-header-alert-popup-header-title,
            .rpm-provider-dashboard .rpm-coach-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-alert .rpm-provider-record-header-alert-popup .rpm-provider-record-header-alert-popup-header .rpm-provider-record-header-alert-popup-header-title-container .rpm-provider-record-header-alert-popup-header-title,
            .rpm-coach-dashboard .rpm-provider-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-alert .rpm-provider-record-header-alert-popup .rpm-provider-record-header-alert-popup-header .rpm-provider-record-header-alert-popup-header-title-container .rpm-provider-record-header-alert-popup-header-title,
            .rpm-coach-dashboard .rpm-coach-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-alert .rpm-provider-record-header-alert-popup .rpm-provider-record-header-alert-popup-header .rpm-provider-record-header-alert-popup-header-title-container .rpm-provider-record-header-alert-popup-header-title {
              font-size: 0.875rem;
              font-weight: 600;
              color: var(--pure-white); }
          .rpm-provider-dashboard .rpm-provider-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-alert .rpm-provider-record-header-alert-popup .rpm-provider-record-header-alert-popup-header .rpm-provider-record-header-alert-popup-header-clear,
          .rpm-provider-dashboard .rpm-coach-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-alert .rpm-provider-record-header-alert-popup .rpm-provider-record-header-alert-popup-header .rpm-provider-record-header-alert-popup-header-clear,
          .rpm-coach-dashboard .rpm-provider-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-alert .rpm-provider-record-header-alert-popup .rpm-provider-record-header-alert-popup-header .rpm-provider-record-header-alert-popup-header-clear,
          .rpm-coach-dashboard .rpm-coach-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-alert .rpm-provider-record-header-alert-popup .rpm-provider-record-header-alert-popup-header .rpm-provider-record-header-alert-popup-header-clear {
            font-size: 0.7rem;
            font-weight: 600;
            color: var(--pure-white);
            cursor: pointer;
            text-decoration: underline; }
        .rpm-provider-dashboard .rpm-provider-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-alert .rpm-provider-record-header-alert-popup .rpm-provider-record-header-alert-popup-body,
        .rpm-provider-dashboard .rpm-coach-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-alert .rpm-provider-record-header-alert-popup .rpm-provider-record-header-alert-popup-body,
        .rpm-coach-dashboard .rpm-provider-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-alert .rpm-provider-record-header-alert-popup .rpm-provider-record-header-alert-popup-body,
        .rpm-coach-dashboard .rpm-coach-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-alert .rpm-provider-record-header-alert-popup .rpm-provider-record-header-alert-popup-body {
          height: 15.5rem;
          width: 100%;
          position: relative;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding-top: 0.5rem; }
          .rpm-provider-dashboard .rpm-provider-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-alert .rpm-provider-record-header-alert-popup .rpm-provider-record-header-alert-popup-body .right-triangle,
          .rpm-provider-dashboard .rpm-coach-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-alert .rpm-provider-record-header-alert-popup .rpm-provider-record-header-alert-popup-body .right-triangle,
          .rpm-coach-dashboard .rpm-provider-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-alert .rpm-provider-record-header-alert-popup .rpm-provider-record-header-alert-popup-body .right-triangle,
          .rpm-coach-dashboard .rpm-coach-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-alert .rpm-provider-record-header-alert-popup .rpm-provider-record-header-alert-popup-body .right-triangle {
            position: absolute;
            top: 6.75rem;
            left: 100%;
            width: 0;
            height: 0;
            border-top: 0.5rem solid transparent;
            border-bottom: 0.5rem solid transparent;
            border-left: 0.5rem solid var(--pure-white); }
          .rpm-provider-dashboard .rpm-provider-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-alert .rpm-provider-record-header-alert-popup .rpm-provider-record-header-alert-popup-body .rpm-provider-record-header-alert-popup-body-item,
          .rpm-provider-dashboard .rpm-coach-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-alert .rpm-provider-record-header-alert-popup .rpm-provider-record-header-alert-popup-body .rpm-provider-record-header-alert-popup-body-item,
          .rpm-coach-dashboard .rpm-provider-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-alert .rpm-provider-record-header-alert-popup .rpm-provider-record-header-alert-popup-body .rpm-provider-record-header-alert-popup-body-item,
          .rpm-coach-dashboard .rpm-coach-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-alert .rpm-provider-record-header-alert-popup .rpm-provider-record-header-alert-popup-body .rpm-provider-record-header-alert-popup-body-item {
            min-height: 3rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            padding: 0 1rem;
            border-bottom: 1px solid #f0ece8; }
            .rpm-provider-dashboard .rpm-provider-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-alert .rpm-provider-record-header-alert-popup .rpm-provider-record-header-alert-popup-body .rpm-provider-record-header-alert-popup-body-item .rpm-provider-record-header-alert-popup-body-item-left,
            .rpm-provider-dashboard .rpm-coach-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-alert .rpm-provider-record-header-alert-popup .rpm-provider-record-header-alert-popup-body .rpm-provider-record-header-alert-popup-body-item .rpm-provider-record-header-alert-popup-body-item-left,
            .rpm-coach-dashboard .rpm-provider-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-alert .rpm-provider-record-header-alert-popup .rpm-provider-record-header-alert-popup-body .rpm-provider-record-header-alert-popup-body-item .rpm-provider-record-header-alert-popup-body-item-left,
            .rpm-coach-dashboard .rpm-coach-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-alert .rpm-provider-record-header-alert-popup .rpm-provider-record-header-alert-popup-body .rpm-provider-record-header-alert-popup-body-item .rpm-provider-record-header-alert-popup-body-item-left {
              display: flex;
              align-items: center;
              grid-gap: 0.5rem;
              gap: 0.5rem;
              justify-content: flex-start; }
              .rpm-provider-dashboard .rpm-provider-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-alert .rpm-provider-record-header-alert-popup .rpm-provider-record-header-alert-popup-body .rpm-provider-record-header-alert-popup-body-item .rpm-provider-record-header-alert-popup-body-item-left p,
              .rpm-provider-dashboard .rpm-coach-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-alert .rpm-provider-record-header-alert-popup .rpm-provider-record-header-alert-popup-body .rpm-provider-record-header-alert-popup-body-item .rpm-provider-record-header-alert-popup-body-item-left p,
              .rpm-coach-dashboard .rpm-provider-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-alert .rpm-provider-record-header-alert-popup .rpm-provider-record-header-alert-popup-body .rpm-provider-record-header-alert-popup-body-item .rpm-provider-record-header-alert-popup-body-item-left p,
              .rpm-coach-dashboard .rpm-coach-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-alert .rpm-provider-record-header-alert-popup .rpm-provider-record-header-alert-popup-body .rpm-provider-record-header-alert-popup-body-item .rpm-provider-record-header-alert-popup-body-item-left p {
                font-size: 0.875rem;
                font-weight: 400;
                color: var(--primary-dark); }
            .rpm-provider-dashboard .rpm-provider-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-alert .rpm-provider-record-header-alert-popup .rpm-provider-record-header-alert-popup-body .rpm-provider-record-header-alert-popup-body-item .rpm-provider-record-header-alert-popup-body-item-right,
            .rpm-provider-dashboard .rpm-coach-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-alert .rpm-provider-record-header-alert-popup .rpm-provider-record-header-alert-popup-body .rpm-provider-record-header-alert-popup-body-item .rpm-provider-record-header-alert-popup-body-item-right,
            .rpm-coach-dashboard .rpm-provider-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-alert .rpm-provider-record-header-alert-popup .rpm-provider-record-header-alert-popup-body .rpm-provider-record-header-alert-popup-body-item .rpm-provider-record-header-alert-popup-body-item-right,
            .rpm-coach-dashboard .rpm-coach-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-alert .rpm-provider-record-header-alert-popup .rpm-provider-record-header-alert-popup-body .rpm-provider-record-header-alert-popup-body-item .rpm-provider-record-header-alert-popup-body-item-right {
              display: flex;
              align-items: center;
              grid-gap: 0.5rem;
              gap: 0.5rem;
              justify-content: flex-end; }
              .rpm-provider-dashboard .rpm-provider-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-alert .rpm-provider-record-header-alert-popup .rpm-provider-record-header-alert-popup-body .rpm-provider-record-header-alert-popup-body-item .rpm-provider-record-header-alert-popup-body-item-right .rpm-provider-record-header-alert-popup-body-item-right-text,
              .rpm-provider-dashboard .rpm-coach-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-alert .rpm-provider-record-header-alert-popup .rpm-provider-record-header-alert-popup-body .rpm-provider-record-header-alert-popup-body-item .rpm-provider-record-header-alert-popup-body-item-right .rpm-provider-record-header-alert-popup-body-item-right-text,
              .rpm-coach-dashboard .rpm-provider-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-alert .rpm-provider-record-header-alert-popup .rpm-provider-record-header-alert-popup-body .rpm-provider-record-header-alert-popup-body-item .rpm-provider-record-header-alert-popup-body-item-right .rpm-provider-record-header-alert-popup-body-item-right-text,
              .rpm-coach-dashboard .rpm-coach-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-alert .rpm-provider-record-header-alert-popup .rpm-provider-record-header-alert-popup-body .rpm-provider-record-header-alert-popup-body-item .rpm-provider-record-header-alert-popup-body-item-right .rpm-provider-record-header-alert-popup-body-item-right-text {
                padding: 0.5rem;
                width: 2rem;
                height: 1.5rem;
                display: flex;
                align-items: center;
                background: rgba(232, 116, 79, 0.27);
                border-radius: 0.25rem; }
                .rpm-provider-dashboard .rpm-provider-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-alert .rpm-provider-record-header-alert-popup .rpm-provider-record-header-alert-popup-body .rpm-provider-record-header-alert-popup-body-item .rpm-provider-record-header-alert-popup-body-item-right .rpm-provider-record-header-alert-popup-body-item-right-text p,
                .rpm-provider-dashboard .rpm-coach-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-alert .rpm-provider-record-header-alert-popup .rpm-provider-record-header-alert-popup-body .rpm-provider-record-header-alert-popup-body-item .rpm-provider-record-header-alert-popup-body-item-right .rpm-provider-record-header-alert-popup-body-item-right-text p,
                .rpm-coach-dashboard .rpm-provider-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-alert .rpm-provider-record-header-alert-popup .rpm-provider-record-header-alert-popup-body .rpm-provider-record-header-alert-popup-body-item .rpm-provider-record-header-alert-popup-body-item-right .rpm-provider-record-header-alert-popup-body-item-right-text p,
                .rpm-coach-dashboard .rpm-coach-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-alert .rpm-provider-record-header-alert-popup .rpm-provider-record-header-alert-popup-body .rpm-provider-record-header-alert-popup-body-item .rpm-provider-record-header-alert-popup-body-item-right .rpm-provider-record-header-alert-popup-body-item-right-text p {
                  font-size: 0.75rem;
                  font-weight: 600;
                  color: #e8744f; }
              .rpm-provider-dashboard .rpm-provider-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-alert .rpm-provider-record-header-alert-popup .rpm-provider-record-header-alert-popup-body .rpm-provider-record-header-alert-popup-body-item .rpm-provider-record-header-alert-popup-body-item-right .rpm-provider-record-header-alert-popup-body-item-right-date,
              .rpm-provider-dashboard .rpm-coach-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-alert .rpm-provider-record-header-alert-popup .rpm-provider-record-header-alert-popup-body .rpm-provider-record-header-alert-popup-body-item .rpm-provider-record-header-alert-popup-body-item-right .rpm-provider-record-header-alert-popup-body-item-right-date,
              .rpm-coach-dashboard .rpm-provider-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-alert .rpm-provider-record-header-alert-popup .rpm-provider-record-header-alert-popup-body .rpm-provider-record-header-alert-popup-body-item .rpm-provider-record-header-alert-popup-body-item-right .rpm-provider-record-header-alert-popup-body-item-right-date,
              .rpm-coach-dashboard .rpm-coach-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-alert .rpm-provider-record-header-alert-popup .rpm-provider-record-header-alert-popup-body .rpm-provider-record-header-alert-popup-body-item .rpm-provider-record-header-alert-popup-body-item-right .rpm-provider-record-header-alert-popup-body-item-right-date {
                display: flex;
                flex-direction: column;
                align-items: flex-start; }
                .rpm-provider-dashboard .rpm-provider-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-alert .rpm-provider-record-header-alert-popup .rpm-provider-record-header-alert-popup-body .rpm-provider-record-header-alert-popup-body-item .rpm-provider-record-header-alert-popup-body-item-right .rpm-provider-record-header-alert-popup-body-item-right-date p,
                .rpm-provider-dashboard .rpm-coach-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-alert .rpm-provider-record-header-alert-popup .rpm-provider-record-header-alert-popup-body .rpm-provider-record-header-alert-popup-body-item .rpm-provider-record-header-alert-popup-body-item-right .rpm-provider-record-header-alert-popup-body-item-right-date p,
                .rpm-coach-dashboard .rpm-provider-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-alert .rpm-provider-record-header-alert-popup .rpm-provider-record-header-alert-popup-body .rpm-provider-record-header-alert-popup-body-item .rpm-provider-record-header-alert-popup-body-item-right .rpm-provider-record-header-alert-popup-body-item-right-date p,
                .rpm-coach-dashboard .rpm-coach-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-alert .rpm-provider-record-header-alert-popup .rpm-provider-record-header-alert-popup-body .rpm-provider-record-header-alert-popup-body-item .rpm-provider-record-header-alert-popup-body-item-right .rpm-provider-record-header-alert-popup-body-item-right-date p {
                  color: #908e87;
                  font-weight: 400;
                  font-size: 0.7rem; }
          .rpm-provider-dashboard .rpm-provider-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-alert .rpm-provider-record-header-alert-popup .rpm-provider-record-header-alert-popup-body .rpm-provider-record-header-alert-popup-body-item-view-profile,
          .rpm-provider-dashboard .rpm-coach-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-alert .rpm-provider-record-header-alert-popup .rpm-provider-record-header-alert-popup-body .rpm-provider-record-header-alert-popup-body-item-view-profile,
          .rpm-coach-dashboard .rpm-provider-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-alert .rpm-provider-record-header-alert-popup .rpm-provider-record-header-alert-popup-body .rpm-provider-record-header-alert-popup-body-item-view-profile,
          .rpm-coach-dashboard .rpm-coach-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-alert .rpm-provider-record-header-alert-popup .rpm-provider-record-header-alert-popup-body .rpm-provider-record-header-alert-popup-body-item-view-profile {
            text-decoration: underline;
            font-size: 0.75rem;
            cursor: pointer;
            color: var(--primary-dark);
            font-weight: 600;
            margin-top: 1rem; }
  .rpm-provider-dashboard .rpm-provider-container .rpm-provider-record .rpm-provider-record-grid,
  .rpm-provider-dashboard .rpm-coach-container .rpm-provider-record .rpm-provider-record-grid,
  .rpm-coach-dashboard .rpm-provider-container .rpm-provider-record .rpm-provider-record-grid,
  .rpm-coach-dashboard .rpm-coach-container .rpm-provider-record .rpm-provider-record-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    width: 100%;
    grid-gap: 1px;
    gap: 1px;
    background-color: #d7d7d7;
    border-radius: 0 0 1rem 1rem;
    overflow: hidden; }

.rpm-provider-dashboard .rpm-provider-container .rpm-provider-mobile-records,
.rpm-provider-dashboard .rpm-coach-container .rpm-provider-mobile-records,
.rpm-coach-dashboard .rpm-provider-container .rpm-provider-mobile-records,
.rpm-coach-dashboard .rpm-coach-container .rpm-provider-mobile-records {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  grid-gap: 1.25rem;
  gap: 1.25rem;
  padding-bottom: 3rem; }
  @media (max-width: 767.98px) {
    .rpm-provider-dashboard .rpm-provider-container .rpm-provider-mobile-records,
    .rpm-provider-dashboard .rpm-coach-container .rpm-provider-mobile-records,
    .rpm-coach-dashboard .rpm-provider-container .rpm-provider-mobile-records,
    .rpm-coach-dashboard .rpm-coach-container .rpm-provider-mobile-records {
      padding: 0;
      overflow-y: auto;
      padding-top: 1rem; } }
  .rpm-provider-dashboard .rpm-provider-container .rpm-provider-mobile-records .rpm-provider-mobile-record,
  .rpm-provider-dashboard .rpm-coach-container .rpm-provider-mobile-records .rpm-provider-mobile-record,
  .rpm-coach-dashboard .rpm-provider-container .rpm-provider-mobile-records .rpm-provider-mobile-record,
  .rpm-coach-dashboard .rpm-coach-container .rpm-provider-mobile-records .rpm-provider-mobile-record {
    width: 85%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    border-radius: 0.625rem;
    box-shadow: 0px 4px 20px 1px rgba(0, 0, 0, 0.1); }
    .rpm-provider-dashboard .rpm-provider-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-header,
    .rpm-provider-dashboard .rpm-coach-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-header,
    .rpm-coach-dashboard .rpm-provider-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-header,
    .rpm-coach-dashboard .rpm-coach-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-header {
      height: 2.5rem;
      width: 100%;
      background-color: #e8754f;
      padding: 0 1.5rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-top-left-radius: 0.625rem;
      border-top-right-radius: 0.625rem; }
      .rpm-provider-dashboard .rpm-provider-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-header__left,
      .rpm-provider-dashboard .rpm-coach-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-header__left,
      .rpm-coach-dashboard .rpm-provider-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-header__left,
      .rpm-coach-dashboard .rpm-coach-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-header__left {
        display: flex;
        align-items: center;
        grid-gap: 1rem;
        gap: 1rem; }
        .rpm-provider-dashboard .rpm-provider-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-header__left__alert-icon-container,
        .rpm-provider-dashboard .rpm-coach-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-header__left__alert-icon-container,
        .rpm-coach-dashboard .rpm-provider-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-header__left__alert-icon-container,
        .rpm-coach-dashboard .rpm-coach-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-header__left__alert-icon-container {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center; }
          .rpm-provider-dashboard .rpm-provider-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-header__left__alert-icon-container__square,
          .rpm-provider-dashboard .rpm-coach-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-header__left__alert-icon-container__square,
          .rpm-coach-dashboard .rpm-provider-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-header__left__alert-icon-container__square,
          .rpm-coach-dashboard .rpm-coach-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-header__left__alert-icon-container__square {
            width: 1.11125rem;
            height: 1.11125rem;
            aspect-ratio: 1/1;
            background-color: #f9dad0;
            border-radius: 0.1875rem;
            -webkit-transform: rotate(45deg);
                    transform: rotate(45deg); }
          .rpm-provider-dashboard .rpm-provider-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-header__left__alert-icon-container__icon,
          .rpm-provider-dashboard .rpm-coach-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-header__left__alert-icon-container__icon,
          .rpm-coach-dashboard .rpm-provider-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-header__left__alert-icon-container__icon,
          .rpm-coach-dashboard .rpm-coach-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-header__left__alert-icon-container__icon {
            color: #e8744f;
            font-size: 0.7rem;
            font-weight: 800;
            z-index: 10;
            position: absolute; }
        .rpm-provider-dashboard .rpm-provider-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-header__left__alert-text,
        .rpm-provider-dashboard .rpm-coach-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-header__left__alert-text,
        .rpm-coach-dashboard .rpm-provider-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-header__left__alert-text,
        .rpm-coach-dashboard .rpm-coach-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-header__left__alert-text {
          font-size: 0.875rem;
          font-weight: 600;
          color: var(--pure-white); }
      .rpm-provider-dashboard .rpm-provider-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-header__right,
      .rpm-provider-dashboard .rpm-coach-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-header__right,
      .rpm-coach-dashboard .rpm-provider-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-header__right,
      .rpm-coach-dashboard .rpm-coach-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-header__right {
        font-size: 0.75rem;
        font-weight: 600;
        color: var(--pure-white); }
      .rpm-provider-dashboard .rpm-provider-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-header__no-alerts,
      .rpm-provider-dashboard .rpm-coach-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-header__no-alerts,
      .rpm-coach-dashboard .rpm-provider-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-header__no-alerts,
      .rpm-coach-dashboard .rpm-coach-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-header__no-alerts {
        color: rgba(89, 89, 89, 0.5);
        font-size: 0.625rem;
        font-weight: 600; }
    .rpm-provider-dashboard .rpm-provider-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-body,
    .rpm-provider-dashboard .rpm-coach-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-body,
    .rpm-coach-dashboard .rpm-provider-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-body,
    .rpm-coach-dashboard .rpm-coach-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-body {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      padding: 0.5rem;
      width: 100%;
      grid-gap: 0.5rem;
      gap: 0.5rem;
      background-color: var(--pure-white);
      border-bottom-left-radius: 0.625rem;
      border-bottom-right-radius: 0.625rem; }
      .rpm-provider-dashboard .rpm-provider-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-body__header,
      .rpm-provider-dashboard .rpm-coach-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-body__header,
      .rpm-coach-dashboard .rpm-provider-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-body__header,
      .rpm-coach-dashboard .rpm-coach-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-body__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 0; }
        .rpm-provider-dashboard .rpm-provider-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-body__header__profile-container,
        .rpm-provider-dashboard .rpm-coach-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-body__header__profile-container,
        .rpm-coach-dashboard .rpm-provider-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-body__header__profile-container,
        .rpm-coach-dashboard .rpm-coach-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-body__header__profile-container {
          display: flex;
          align-items: center;
          grid-gap: 1rem;
          gap: 1rem;
          width: 100%; }
          .rpm-provider-dashboard .rpm-provider-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-body__header__profile-container__profile-image,
          .rpm-provider-dashboard .rpm-coach-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-body__header__profile-container__profile-image,
          .rpm-coach-dashboard .rpm-provider-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-body__header__profile-container__profile-image,
          .rpm-coach-dashboard .rpm-coach-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-body__header__profile-container__profile-image {
            width: 3rem;
            height: 3rem;
            border-radius: 0.5rem 0.5rem 0 0.5rem;
            border: 2px solid rgba(240, 236, 232, 0.4);
            background-color: #d6d6d6;
            aspect-ratio: 1/1;
            position: relative; }
            .rpm-provider-dashboard .rpm-provider-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-body__header__profile-container__profile-image img,
            .rpm-provider-dashboard .rpm-coach-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-body__header__profile-container__profile-image img,
            .rpm-coach-dashboard .rpm-provider-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-body__header__profile-container__profile-image img,
            .rpm-coach-dashboard .rpm-coach-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-body__header__profile-container__profile-image img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              border-radius: 0.5rem 0.5rem 0 0.5rem; }
            .rpm-provider-dashboard .rpm-provider-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-body__header__profile-container__profile-image__initials,
            .rpm-provider-dashboard .rpm-coach-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-body__header__profile-container__profile-image__initials,
            .rpm-coach-dashboard .rpm-provider-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-body__header__profile-container__profile-image__initials,
            .rpm-coach-dashboard .rpm-coach-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-body__header__profile-container__profile-image__initials {
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 1rem;
              font-weight: 500;
              color: var(--pure-white);
              position: absolute;
              top: 50%;
              left: 50%;
              -webkit-transform: translate(-50%, -50%);
                      transform: translate(-50%, -50%); }
          .rpm-provider-dashboard .rpm-provider-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-body__header__profile-container__details,
          .rpm-provider-dashboard .rpm-coach-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-body__header__profile-container__details,
          .rpm-coach-dashboard .rpm-provider-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-body__header__profile-container__details,
          .rpm-coach-dashboard .rpm-coach-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-body__header__profile-container__details {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            width: 100%; }
            .rpm-provider-dashboard .rpm-provider-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-body__header__profile-container__details__tag,
            .rpm-provider-dashboard .rpm-coach-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-body__header__profile-container__details__tag,
            .rpm-coach-dashboard .rpm-provider-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-body__header__profile-container__details__tag,
            .rpm-coach-dashboard .rpm-coach-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-body__header__profile-container__details__tag {
              font-size: 0.625rem;
              font-weight: 600;
              color: #e8744f; }
            .rpm-provider-dashboard .rpm-provider-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-body__header__profile-container__details__name,
            .rpm-provider-dashboard .rpm-coach-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-body__header__profile-container__details__name,
            .rpm-coach-dashboard .rpm-provider-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-body__header__profile-container__details__name,
            .rpm-coach-dashboard .rpm-coach-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-body__header__profile-container__details__name {
              font-size: 1rem;
              font-weight: 500;
              color: var(--primary-dark); }
            .rpm-provider-dashboard .rpm-provider-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-body__header__profile-container__details__diagnosis,
            .rpm-provider-dashboard .rpm-coach-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-body__header__profile-container__details__diagnosis,
            .rpm-coach-dashboard .rpm-provider-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-body__header__profile-container__details__diagnosis,
            .rpm-coach-dashboard .rpm-coach-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-body__header__profile-container__details__diagnosis {
              font-size: 0.625rem;
              font-weight: 500;
              color: #595959; }
        .rpm-provider-dashboard .rpm-provider-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-body__header__status-container,
        .rpm-provider-dashboard .rpm-coach-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-body__header__status-container,
        .rpm-coach-dashboard .rpm-provider-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-body__header__status-container,
        .rpm-coach-dashboard .rpm-coach-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-body__header__status-container {
          min-width: 3.8125rem;
          width: -webkit-max-content;
          width: max-content;
          border: 1px solid #88a088;
          border-radius: 7.8125rem;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 1.5rem;
          grid-gap: 0.25rem;
          gap: 0.25rem;
          padding: 0 0.5rem; }
          .rpm-provider-dashboard .rpm-provider-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-body__header__status-container__dot,
          .rpm-provider-dashboard .rpm-coach-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-body__header__status-container__dot,
          .rpm-coach-dashboard .rpm-provider-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-body__header__status-container__dot,
          .rpm-coach-dashboard .rpm-coach-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-body__header__status-container__dot {
            height: 5px;
            width: 5px;
            border-radius: 50%;
            aspect-ratio: 1/1;
            background-color: #88a088; }
          .rpm-provider-dashboard .rpm-provider-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-body__header__status-container__text,
          .rpm-provider-dashboard .rpm-coach-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-body__header__status-container__text,
          .rpm-coach-dashboard .rpm-provider-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-body__header__status-container__text,
          .rpm-coach-dashboard .rpm-coach-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-body__header__status-container__text {
            font-size: 0.625rem;
            font-weight: 500;
            color: #88a088;
            white-space: nowrap; }
      .rpm-provider-dashboard .rpm-provider-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-body__coach-container,
      .rpm-provider-dashboard .rpm-coach-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-body__coach-container,
      .rpm-coach-dashboard .rpm-provider-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-body__coach-container,
      .rpm-coach-dashboard .rpm-coach-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-body__coach-container {
        width: 100%;
        height: 1.875rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #f7f5f3;
        padding: 0 0.5rem;
        border-radius: 0.25rem; }
        .rpm-provider-dashboard .rpm-provider-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-body__coach-container__title,
        .rpm-provider-dashboard .rpm-coach-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-body__coach-container__title,
        .rpm-coach-dashboard .rpm-provider-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-body__coach-container__title,
        .rpm-coach-dashboard .rpm-coach-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-body__coach-container__title {
          font-size: 0.75rem;
          font-weight: 500;
          color: #595959; }
        .rpm-provider-dashboard .rpm-provider-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-body__coach-container__button,
        .rpm-provider-dashboard .rpm-coach-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-body__coach-container__button,
        .rpm-coach-dashboard .rpm-provider-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-body__coach-container__button,
        .rpm-coach-dashboard .rpm-coach-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-body__coach-container__button {
          outline: none; }
      .rpm-provider-dashboard .rpm-provider-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-body__measurements-container,
      .rpm-provider-dashboard .rpm-coach-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-body__measurements-container,
      .rpm-coach-dashboard .rpm-provider-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-body__measurements-container,
      .rpm-coach-dashboard .rpm-coach-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-body__measurements-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 0 0.5rem;
        align-items: center; }
        .rpm-provider-dashboard .rpm-provider-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-body__measurements-container__item,
        .rpm-provider-dashboard .rpm-coach-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-body__measurements-container__item,
        .rpm-coach-dashboard .rpm-provider-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-body__measurements-container__item,
        .rpm-coach-dashboard .rpm-coach-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-body__measurements-container__item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          height: 2.5rem;
          border-bottom: 1px solid #f0f0f0; }
          .rpm-provider-dashboard .rpm-provider-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-body__measurements-container__item__label,
          .rpm-provider-dashboard .rpm-coach-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-body__measurements-container__item__label,
          .rpm-coach-dashboard .rpm-provider-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-body__measurements-container__item__label,
          .rpm-coach-dashboard .rpm-coach-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-body__measurements-container__item__label {
            font-size: 0.625rem;
            font-weight: 600;
            width: 50%; }
          .rpm-provider-dashboard .rpm-provider-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-body__measurements-container__item__value,
          .rpm-provider-dashboard .rpm-coach-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-body__measurements-container__item__value,
          .rpm-coach-dashboard .rpm-provider-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-body__measurements-container__item__value,
          .rpm-coach-dashboard .rpm-coach-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-body__measurements-container__item__value {
            font-size: 0.625rem;
            font-weight: 400;
            width: 50%; }
          .rpm-provider-dashboard .rpm-provider-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-body__measurements-container__item__icon-wrapper,
          .rpm-provider-dashboard .rpm-coach-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-body__measurements-container__item__icon-wrapper,
          .rpm-coach-dashboard .rpm-provider-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-body__measurements-container__item__icon-wrapper,
          .rpm-coach-dashboard .rpm-coach-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-body__measurements-container__item__icon-wrapper {
            width: 1.5rem;
            height: 1.5rem;
            aspect-ratio: 1/1;
            display: flex;
            align-items: center;
            justify-content: center; }

.rpm-provider-dashboard .rpm-provider-container .search-container .right-search-container .search-container-wrapper,
.rpm-provider-dashboard .rpm-coach-container .search-container .right-search-container .search-container-wrapper,
.rpm-coach-dashboard .rpm-provider-container .search-container .right-search-container .search-container-wrapper,
.rpm-coach-dashboard .rpm-coach-container .search-container .right-search-container .search-container-wrapper {
  position: relative;
  width: 6.0625rem;
  height: 2rem; }
  .rpm-provider-dashboard .rpm-provider-container .search-container .right-search-container .search-container-wrapper .search-container-filter,
  .rpm-provider-dashboard .rpm-coach-container .search-container .right-search-container .search-container-wrapper .search-container-filter,
  .rpm-coach-dashboard .rpm-provider-container .search-container .right-search-container .search-container-wrapper .search-container-filter,
  .rpm-coach-dashboard .rpm-coach-container .search-container .right-search-container .search-container-wrapper .search-container-filter {
    width: 6.0625rem;
    height: 2rem;
    border: 1px solid #d3d0cc;
    background-color: var(--pure-white);
    border-radius: 0.3125rem;
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    cursor: pointer; }
    .rpm-provider-dashboard .rpm-provider-container .search-container .right-search-container .search-container-wrapper .search-container-filter .search-container-filter-text,
    .rpm-provider-dashboard .rpm-coach-container .search-container .right-search-container .search-container-wrapper .search-container-filter .search-container-filter-text,
    .rpm-coach-dashboard .rpm-provider-container .search-container .right-search-container .search-container-wrapper .search-container-filter .search-container-filter-text,
    .rpm-coach-dashboard .rpm-coach-container .search-container .right-search-container .search-container-wrapper .search-container-filter .search-container-filter-text {
      font-size: 0.75rem;
      font-weight: 500;
      color: var(--primary-dark);
      white-space: nowrap;
      overflow: hidden; }
  .rpm-provider-dashboard .rpm-provider-container .search-container .right-search-container .search-container-wrapper .search-container-filter-container,
  .rpm-provider-dashboard .rpm-coach-container .search-container .right-search-container .search-container-wrapper .search-container-filter-container,
  .rpm-coach-dashboard .rpm-provider-container .search-container .right-search-container .search-container-wrapper .search-container-filter-container,
  .rpm-coach-dashboard .rpm-coach-container .search-container .right-search-container .search-container-wrapper .search-container-filter-container {
    width: 27.3125rem;
    background-color: var(--pure-white);
    border: 1px solid #d3d0cc;
    position: absolute;
    top: 120%;
    right: 0;
    z-index: 50;
    border-radius: 0.625rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between; }
    @media (max-width: 767.98px) {
      .rpm-provider-dashboard .rpm-provider-container .search-container .right-search-container .search-container-wrapper .search-container-filter-container,
      .rpm-provider-dashboard .rpm-coach-container .search-container .right-search-container .search-container-wrapper .search-container-filter-container,
      .rpm-coach-dashboard .rpm-provider-container .search-container .right-search-container .search-container-wrapper .search-container-filter-container,
      .rpm-coach-dashboard .rpm-coach-container .search-container .right-search-container .search-container-wrapper .search-container-filter-container {
        width: 17.3125rem;
        max-height: 60vh; } }
    .rpm-provider-dashboard .rpm-provider-container .search-container .right-search-container .search-container-wrapper .search-container-filter-container .search-container-filter-container-header,
    .rpm-provider-dashboard .rpm-coach-container .search-container .right-search-container .search-container-wrapper .search-container-filter-container .search-container-filter-container-header,
    .rpm-coach-dashboard .rpm-provider-container .search-container .right-search-container .search-container-wrapper .search-container-filter-container .search-container-filter-container-header,
    .rpm-coach-dashboard .rpm-coach-container .search-container .right-search-container .search-container-wrapper .search-container-filter-container .search-container-filter-container-header {
      min-height: 3.4375rem;
      width: 90%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      border-bottom: 1px solid rgba(229, 229, 229, 0.5); }
      .rpm-provider-dashboard .rpm-provider-container .search-container .right-search-container .search-container-wrapper .search-container-filter-container .search-container-filter-container-header p,
      .rpm-provider-dashboard .rpm-coach-container .search-container .right-search-container .search-container-wrapper .search-container-filter-container .search-container-filter-container-header p,
      .rpm-coach-dashboard .rpm-provider-container .search-container .right-search-container .search-container-wrapper .search-container-filter-container .search-container-filter-container-header p,
      .rpm-coach-dashboard .rpm-coach-container .search-container .right-search-container .search-container-wrapper .search-container-filter-container .search-container-filter-container-header p {
        font-size: 0.75rem;
        font-weight: 600; }
    .rpm-provider-dashboard .rpm-provider-container .search-container .right-search-container .search-container-wrapper .search-container-filter-container .search-container-filter-container-body,
    .rpm-provider-dashboard .rpm-coach-container .search-container .right-search-container .search-container-wrapper .search-container-filter-container .search-container-filter-container-body,
    .rpm-coach-dashboard .rpm-provider-container .search-container .right-search-container .search-container-wrapper .search-container-filter-container .search-container-filter-container-body,
    .rpm-coach-dashboard .rpm-coach-container .search-container .right-search-container .search-container-wrapper .search-container-filter-container .search-container-filter-container-body {
      max-height: 18.0625rem;
      overflow-y: auto;
      width: 90%;
      display: flex;
      flex-direction: column;
      margin: 0 auto; }
      .rpm-provider-dashboard .rpm-provider-container .search-container .right-search-container .search-container-wrapper .search-container-filter-container .search-container-filter-container-body .search-container-filter-container-body-item,
      .rpm-provider-dashboard .rpm-coach-container .search-container .right-search-container .search-container-wrapper .search-container-filter-container .search-container-filter-container-body .search-container-filter-container-body-item,
      .rpm-coach-dashboard .rpm-provider-container .search-container .right-search-container .search-container-wrapper .search-container-filter-container .search-container-filter-container-body .search-container-filter-container-body-item,
      .rpm-coach-dashboard .rpm-coach-container .search-container .right-search-container .search-container-wrapper .search-container-filter-container .search-container-filter-container-body .search-container-filter-container-body-item {
        height: 3.0625rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        border-bottom: 1px solid rgba(229, 229, 229, 0.5);
        overflow: hidden;
        outline: none; }
        .rpm-provider-dashboard .rpm-provider-container .search-container .right-search-container .search-container-wrapper .search-container-filter-container .search-container-filter-container-body .search-container-filter-container-body-item p,
        .rpm-provider-dashboard .rpm-coach-container .search-container .right-search-container .search-container-wrapper .search-container-filter-container .search-container-filter-container-body .search-container-filter-container-body-item p,
        .rpm-coach-dashboard .rpm-provider-container .search-container .right-search-container .search-container-wrapper .search-container-filter-container .search-container-filter-container-body .search-container-filter-container-body-item p,
        .rpm-coach-dashboard .rpm-coach-container .search-container .right-search-container .search-container-wrapper .search-container-filter-container .search-container-filter-container-body .search-container-filter-container-body-item p {
          font-size: 0.875rem;
          font-weight: 400; }
          .rpm-provider-dashboard .rpm-provider-container .search-container .right-search-container .search-container-wrapper .search-container-filter-container .search-container-filter-container-body .search-container-filter-container-body-item p strong,
          .rpm-provider-dashboard .rpm-coach-container .search-container .right-search-container .search-container-wrapper .search-container-filter-container .search-container-filter-container-body .search-container-filter-container-body-item p strong,
          .rpm-coach-dashboard .rpm-provider-container .search-container .right-search-container .search-container-wrapper .search-container-filter-container .search-container-filter-container-body .search-container-filter-container-body-item p strong,
          .rpm-coach-dashboard .rpm-coach-container .search-container .right-search-container .search-container-wrapper .search-container-filter-container .search-container-filter-container-body .search-container-filter-container-body-item p strong {
            font-weight: 600; }
        .rpm-provider-dashboard .rpm-provider-container .search-container .right-search-container .search-container-wrapper .search-container-filter-container .search-container-filter-container-body .search-container-filter-container-body-item svg,
        .rpm-provider-dashboard .rpm-coach-container .search-container .right-search-container .search-container-wrapper .search-container-filter-container .search-container-filter-container-body .search-container-filter-container-body-item svg,
        .rpm-coach-dashboard .rpm-provider-container .search-container .right-search-container .search-container-wrapper .search-container-filter-container .search-container-filter-container-body .search-container-filter-container-body-item svg,
        .rpm-coach-dashboard .rpm-coach-container .search-container .right-search-container .search-container-wrapper .search-container-filter-container .search-container-filter-container-body .search-container-filter-container-body-item svg {
          margin-right: 0.2rem; }
      .rpm-provider-dashboard .rpm-provider-container .search-container .right-search-container .search-container-wrapper .search-container-filter-container .search-container-filter-container-body .search-container-filter-container-body-item-options,
      .rpm-provider-dashboard .rpm-coach-container .search-container .right-search-container .search-container-wrapper .search-container-filter-container .search-container-filter-container-body .search-container-filter-container-body-item-options,
      .rpm-coach-dashboard .rpm-provider-container .search-container .right-search-container .search-container-wrapper .search-container-filter-container .search-container-filter-container-body .search-container-filter-container-body-item-options,
      .rpm-coach-dashboard .rpm-coach-container .search-container .right-search-container .search-container-wrapper .search-container-filter-container .search-container-filter-container-body .search-container-filter-container-body-item-options {
        display: flex;
        flex-direction: column;
        width: 100%; }
        .rpm-provider-dashboard .rpm-provider-container .search-container .right-search-container .search-container-wrapper .search-container-filter-container .search-container-filter-container-body .search-container-filter-container-body-item-options .search-container-filter-container-body-item-options-item,
        .rpm-provider-dashboard .rpm-coach-container .search-container .right-search-container .search-container-wrapper .search-container-filter-container .search-container-filter-container-body .search-container-filter-container-body-item-options .search-container-filter-container-body-item-options-item,
        .rpm-coach-dashboard .rpm-provider-container .search-container .right-search-container .search-container-wrapper .search-container-filter-container .search-container-filter-container-body .search-container-filter-container-body-item-options .search-container-filter-container-body-item-options-item,
        .rpm-coach-dashboard .rpm-coach-container .search-container .right-search-container .search-container-wrapper .search-container-filter-container .search-container-filter-container-body .search-container-filter-container-body-item-options .search-container-filter-container-body-item-options-item {
          height: 3.0625rem;
          border-bottom: 1px solid rgba(229, 229, 229, 0.5);
          overflow: hidden;
          outline: none;
          display: flex;
          align-items: center;
          grid-gap: 0.5rem;
          gap: 0.5rem; }
          .rpm-provider-dashboard .rpm-provider-container .search-container .right-search-container .search-container-wrapper .search-container-filter-container .search-container-filter-container-body .search-container-filter-container-body-item-options .search-container-filter-container-body-item-options-item p,
          .rpm-provider-dashboard .rpm-coach-container .search-container .right-search-container .search-container-wrapper .search-container-filter-container .search-container-filter-container-body .search-container-filter-container-body-item-options .search-container-filter-container-body-item-options-item p,
          .rpm-coach-dashboard .rpm-provider-container .search-container .right-search-container .search-container-wrapper .search-container-filter-container .search-container-filter-container-body .search-container-filter-container-body-item-options .search-container-filter-container-body-item-options-item p,
          .rpm-coach-dashboard .rpm-coach-container .search-container .right-search-container .search-container-wrapper .search-container-filter-container .search-container-filter-container-body .search-container-filter-container-body-item-options .search-container-filter-container-body-item-options-item p {
            font-size: 0.875rem;
            font-weight: 400;
            -webkit-user-select: none;
                    user-select: none; }
          .rpm-provider-dashboard .rpm-provider-container .search-container .right-search-container .search-container-wrapper .search-container-filter-container .search-container-filter-container-body .search-container-filter-container-body-item-options .search-container-filter-container-body-item-options-item button,
          .rpm-provider-dashboard .rpm-coach-container .search-container .right-search-container .search-container-wrapper .search-container-filter-container .search-container-filter-container-body .search-container-filter-container-body-item-options .search-container-filter-container-body-item-options-item button,
          .rpm-coach-dashboard .rpm-provider-container .search-container .right-search-container .search-container-wrapper .search-container-filter-container .search-container-filter-container-body .search-container-filter-container-body-item-options .search-container-filter-container-body-item-options-item button,
          .rpm-coach-dashboard .rpm-coach-container .search-container .right-search-container .search-container-wrapper .search-container-filter-container .search-container-filter-container-body .search-container-filter-container-body-item-options .search-container-filter-container-body-item-options-item button {
            outline: none; }
    .rpm-provider-dashboard .rpm-provider-container .search-container .right-search-container .search-container-wrapper .search-container-filter-container .search-container-filter-container-footer,
    .rpm-provider-dashboard .rpm-coach-container .search-container .right-search-container .search-container-wrapper .search-container-filter-container .search-container-filter-container-footer,
    .rpm-coach-dashboard .rpm-provider-container .search-container .right-search-container .search-container-wrapper .search-container-filter-container .search-container-filter-container-footer,
    .rpm-coach-dashboard .rpm-coach-container .search-container .right-search-container .search-container-wrapper .search-container-filter-container .search-container-filter-container-footer {
      min-height: 8.0625rem;
      width: 100%;
      display: flex;
      align-items: center;
      grid-gap: 1rem;
      gap: 1rem;
      justify-content: center; }
      @media (max-width: 767.98px) {
        .rpm-provider-dashboard .rpm-provider-container .search-container .right-search-container .search-container-wrapper .search-container-filter-container .search-container-filter-container-footer,
        .rpm-provider-dashboard .rpm-coach-container .search-container .right-search-container .search-container-wrapper .search-container-filter-container .search-container-filter-container-footer,
        .rpm-coach-dashboard .rpm-provider-container .search-container .right-search-container .search-container-wrapper .search-container-filter-container .search-container-filter-container-footer,
        .rpm-coach-dashboard .rpm-coach-container .search-container .right-search-container .search-container-wrapper .search-container-filter-container .search-container-filter-container-footer {
          flex-direction: column; } }
      .rpm-provider-dashboard .rpm-provider-container .search-container .right-search-container .search-container-wrapper .search-container-filter-container .search-container-filter-container-footer .search-container-filter-container-footer-button,
      .rpm-provider-dashboard .rpm-coach-container .search-container .right-search-container .search-container-wrapper .search-container-filter-container .search-container-filter-container-footer .search-container-filter-container-footer-button,
      .rpm-coach-dashboard .rpm-provider-container .search-container .right-search-container .search-container-wrapper .search-container-filter-container .search-container-filter-container-footer .search-container-filter-container-footer-button,
      .rpm-coach-dashboard .rpm-coach-container .search-container .right-search-container .search-container-wrapper .search-container-filter-container .search-container-filter-container-footer .search-container-filter-container-footer-button {
        width: 10.25rem;
        height: 2.5rem;
        border-radius: 0.625rem;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        font-size: 0.875rem; }
        @media (max-width: 767.98px) {
          .rpm-provider-dashboard .rpm-provider-container .search-container .right-search-container .search-container-wrapper .search-container-filter-container .search-container-filter-container-footer .search-container-filter-container-footer-button,
          .rpm-provider-dashboard .rpm-coach-container .search-container .right-search-container .search-container-wrapper .search-container-filter-container .search-container-filter-container-footer .search-container-filter-container-footer-button,
          .rpm-coach-dashboard .rpm-provider-container .search-container .right-search-container .search-container-wrapper .search-container-filter-container .search-container-filter-container-footer .search-container-filter-container-footer-button,
          .rpm-coach-dashboard .rpm-coach-container .search-container .right-search-container .search-container-wrapper .search-container-filter-container .search-container-filter-container-footer .search-container-filter-container-footer-button {
            width: 80%; } }
      .rpm-provider-dashboard .rpm-provider-container .search-container .right-search-container .search-container-wrapper .search-container-filter-container .search-container-filter-container-footer .apply,
      .rpm-provider-dashboard .rpm-coach-container .search-container .right-search-container .search-container-wrapper .search-container-filter-container .search-container-filter-container-footer .apply,
      .rpm-coach-dashboard .rpm-provider-container .search-container .right-search-container .search-container-wrapper .search-container-filter-container .search-container-filter-container-footer .apply,
      .rpm-coach-dashboard .rpm-coach-container .search-container .right-search-container .search-container-wrapper .search-container-filter-container .search-container-filter-container-footer .apply {
        background-color: var(--primary-dark);
        color: var(--pure-white); }
      .rpm-provider-dashboard .rpm-provider-container .search-container .right-search-container .search-container-wrapper .search-container-filter-container .search-container-filter-container-footer .reset,
      .rpm-provider-dashboard .rpm-coach-container .search-container .right-search-container .search-container-wrapper .search-container-filter-container .search-container-filter-container-footer .reset,
      .rpm-coach-dashboard .rpm-provider-container .search-container .right-search-container .search-container-wrapper .search-container-filter-container .search-container-filter-container-footer .reset,
      .rpm-coach-dashboard .rpm-coach-container .search-container .right-search-container .search-container-wrapper .search-container-filter-container .search-container-filter-container-footer .reset {
        border: 1px solid var(--primary-dark);
        color: var(--primary-dark);
        background-color: transparent; }

.rpm-provider-dashboard .rpm-provider-container .search-container .right-search-container .search-container-wrapper .search-container-filter-sort-container,
.rpm-provider-dashboard .rpm-coach-container .search-container .right-search-container .search-container-wrapper .search-container-filter-sort-container,
.rpm-coach-dashboard .rpm-provider-container .search-container .right-search-container .search-container-wrapper .search-container-filter-sort-container,
.rpm-coach-dashboard .rpm-coach-container .search-container .right-search-container .search-container-wrapper .search-container-filter-sort-container {
  width: 21.5625rem;
  height: 14.25rem;
  background-color: var(--pure-white);
  border: 1px solid #e5e5e5;
  position: absolute;
  top: 120%;
  right: 0;
  z-index: 50;
  border-radius: 0.625rem;
  display: flex;
  flex-direction: column;
  align-items: center; }
  .rpm-provider-dashboard .rpm-provider-container .search-container .right-search-container .search-container-wrapper .search-container-filter-sort-container .search-container-filter-sort-container-item,
  .rpm-provider-dashboard .rpm-coach-container .search-container .right-search-container .search-container-wrapper .search-container-filter-sort-container .search-container-filter-sort-container-item,
  .rpm-coach-dashboard .rpm-provider-container .search-container .right-search-container .search-container-wrapper .search-container-filter-sort-container .search-container-filter-sort-container-item,
  .rpm-coach-dashboard .rpm-coach-container .search-container .right-search-container .search-container-wrapper .search-container-filter-sort-container .search-container-filter-sort-container-item {
    height: 3.5rem;
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    border-bottom: 1px solid #e5e5e5;
    cursor: pointer; }
    .rpm-provider-dashboard .rpm-provider-container .search-container .right-search-container .search-container-wrapper .search-container-filter-sort-container .search-container-filter-sort-container-item p,
    .rpm-provider-dashboard .rpm-coach-container .search-container .right-search-container .search-container-wrapper .search-container-filter-sort-container .search-container-filter-sort-container-item p,
    .rpm-coach-dashboard .rpm-provider-container .search-container .right-search-container .search-container-wrapper .search-container-filter-sort-container .search-container-filter-sort-container-item p,
    .rpm-coach-dashboard .rpm-coach-container .search-container .right-search-container .search-container-wrapper .search-container-filter-sort-container .search-container-filter-sort-container-item p {
      font-size: 0.875rem;
      font-weight: 400;
      -webkit-user-select: none;
              user-select: none; }

.record-measurement-info {
  position: relative;
  cursor: pointer;
  width: 0.675rem;
  height: 0.675rem;
  display: flex;
  align-items: center;
  justify-content: center; }
  .record-measurement-info .record-measurement-info-container {
    position: absolute;
    top: -11rem;
    width: 16.5rem;
    height: 16.625rem;
    display: flex;
    flex-direction: column;
    padding: 1.8125rem;
    background-color: var(--pure-white);
    box-shadow: 0px 8px 13px rgba(0, 0, 0, 0.2);
    z-index: 999;
    border-radius: 0.25rem; }
    .record-measurement-info .record-measurement-info-container h1 {
      font-size: 1rem;
      font-family: var(--sans-serif-font-family);
      font-weight: 600;
      padding-bottom: 1.5rem;
      border-bottom: 1px solid #d3d0cc; }
    .record-measurement-info .record-measurement-info-container .triangle-left {
      position: absolute;
      top: 10.9rem;
      left: -0.5rem;
      width: 0;
      height: 0;
      border-top: 0.5rem solid transparent;
      border-bottom: 0.5rem solid transparent;
      border-right: 0.5rem solid var(--pure-white); }
    .record-measurement-info .record-measurement-info-container .triangle-right {
      position: absolute;
      top: 10.9rem;
      right: -0.5rem;
      width: 0;
      height: 0;
      border-top: 0.5rem solid transparent;
      border-bottom: 0.5rem solid transparent;
      border-left: 0.5rem solid var(--pure-white); }
    .record-measurement-info .record-measurement-info-container .record-measurement-info-container-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      justify-content: center;
      grid-gap: 0.5rem;
      gap: 0.5rem;
      margin-top: 1rem; }
      .record-measurement-info .record-measurement-info-container .record-measurement-info-container-content .record-measurement-info-container-content-item {
        height: 2rem;
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between; }
        .record-measurement-info .record-measurement-info-container .record-measurement-info-container-content .record-measurement-info-container-content-item .record-measurement-info-container-content-item-title {
          font-size: 0.875rem;
          font-weight: 400; }
        .record-measurement-info .record-measurement-info-container .record-measurement-info-container-content .record-measurement-info-container-content-item .record-measurement-info-container-content-item-value {
          width: 5rem;
          height: 2rem;
          border-radius: 0.25rem;
          display: flex;
          align-items: center;
          justify-content: center; }
          .record-measurement-info .record-measurement-info-container .record-measurement-info-container-content .record-measurement-info-container-content-item .record-measurement-info-container-content-item-value p {
            font-size: 0.75rem;
            font-weight: 600; }

.rpm-provider-dashboard .rpm-provider-container .search-container .right-search-container .search-container-add-client,
.rpm-provider-dashboard .rpm-coach-container .search-container .right-search-container .search-container-add-client,
.rpm-coach-dashboard .rpm-provider-container .search-container .right-search-container .search-container-add-client,
.rpm-coach-dashboard .rpm-coach-container .search-container .right-search-container .search-container-add-client {
  width: 8.3125rem;
  height: 2rem;
  border: 1px solid #d3d0cc;
  background-color: var(--primary-dark);
  border-radius: 0.3125rem;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  outline: none; }
  .rpm-provider-dashboard .rpm-provider-container .search-container .right-search-container .search-container-add-client .search-container-add-client-text,
  .rpm-provider-dashboard .rpm-coach-container .search-container .right-search-container .search-container-add-client .search-container-add-client-text,
  .rpm-coach-dashboard .rpm-provider-container .search-container .right-search-container .search-container-add-client .search-container-add-client-text,
  .rpm-coach-dashboard .rpm-coach-container .search-container .right-search-container .search-container-add-client .search-container-add-client-text {
    color: var(--pure-white);
    font-size: 0.75rem; }

#addClientModal .modal-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 1rem;
  background-color: var(--pure-white);
  width: 30.125rem; }
  #addClientModal .modal-content .rpm-provider-add-client-modal-title {
    font-size: 1.5rem;
    font-weight: 700;
    color: var(--primary-dark);
    text-align: center; }
  #addClientModal .modal-content .rpm-provider-add-client-modal-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    grid-gap: 1rem;
    gap: 1rem;
    margin-top: 1rem;
    margin-bottom: 1rem; }
    #addClientModal .modal-content .rpm-provider-add-client-modal-form .rpm-provider-add-client-modal-form-input {
      position: relative;
      height: 2.8125rem; }
      #addClientModal .modal-content .rpm-provider-add-client-modal-form .rpm-provider-add-client-modal-form-input input {
        width: 19.6875rem;
        min-height: 2.8125rem;
        min-height: 2.8125rem;
        min-height: 2.8125rem;
        border: 1px solid #d3d0cc;
        border-radius: 0.625rem;
        padding-left: 2.75rem;
        padding-right: 1rem;
        font-size: 1rem; }
        #addClientModal .modal-content .rpm-provider-add-client-modal-form .rpm-provider-add-client-modal-form-input input::-webkit-input-placeholder {
          font-weight: 300; }
        #addClientModal .modal-content .rpm-provider-add-client-modal-form .rpm-provider-add-client-modal-form-input input::placeholder {
          font-weight: 300; }
      #addClientModal .modal-content .rpm-provider-add-client-modal-form .rpm-provider-add-client-modal-form-input svg {
        position: absolute;
        top: 50%;
        left: 1rem;
        -webkit-transform: translateY(-50%);
                transform: translateY(-50%); }
    #addClientModal .modal-content .rpm-provider-add-client-modal-form .rpm-provider-add-client-modal-form-error {
      color: var(--primary-red);
      font-size: 0.7rem;
      font-weight: 700; }
    #addClientModal .modal-content .rpm-provider-add-client-modal-form .rpm-provider-add-client-modal-form-submit {
      width: 19.6875rem;
      height: 2.8125rem;
      background-color: var(--primary-dark);
      border-radius: 0.625rem;
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--pure-white);
      font-size: 1rem;
      font-weight: 500; }

.new-staff-modal button {
  outline: none; }

.new-staff-modal .modal-content {
  background-color: var(--pure-white);
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding: 3rem 5rem; }
  @media (max-width: 767.98px) {
    .new-staff-modal .modal-content {
      padding: 2rem; } }
  .new-staff-modal .modal-content form {
    margin-top: 1rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center; }
    .new-staff-modal .modal-content form .form-group {
      display: flex;
      flex-direction: column;
      width: 100%; }
      .new-staff-modal .modal-content form .form-group label {
        font-size: 0.75rem;
        font-weight: 300;
        color: #40413f;
        margin-bottom: 0.625rem; }
      .new-staff-modal .modal-content form .form-group .input-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        position: relative;
        height: 2.8125rem; }
        .new-staff-modal .modal-content form .form-group .input-wrapper .icon-wrapper {
          position: absolute;
          top: 50%;
          left: 1rem;
          -webkit-transform: translateY(-50%);
                  transform: translateY(-50%);
          display: flex;
          align-items: center;
          justify-content: center;
          width: 1.5rem;
          height: 1.5rem; }
        .new-staff-modal .modal-content form .form-group .input-wrapper input {
          width: 100%;
          height: 100%;
          border: 1px solid #f2f1ec;
          border-radius: 0.625rem;
          padding-left: 3.625rem; }
          .new-staff-modal .modal-content form .form-group .input-wrapper input::-webkit-input-placeholder {
            color: #6d786e;
            font-weight: 300; }
          .new-staff-modal .modal-content form .form-group .input-wrapper input::placeholder {
            color: #6d786e;
            font-weight: 300; }
      .new-staff-modal .modal-content form .form-group .checkbox-wrapper {
        display: flex;
        align-items: center;
        grid-gap: 0.5rem;
        gap: 0.5rem;
        width: 100%; }
        .new-staff-modal .modal-content form .form-group .checkbox-wrapper label {
          margin: 0; }
        .new-staff-modal .modal-content form .form-group .checkbox-wrapper button {
          outline: none; }
    .new-staff-modal .modal-content form .error-message {
      color: var(--primary-red);
      font-size: 0.7rem;
      margin-top: 0.5rem;
      font-weight: 700; }
    .new-staff-modal .modal-content form .submit-button {
      height: 3.125rem;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--primary-dark);
      color: var(--pure-white);
      border-radius: 0.625rem;
      outline: none;
      font-weight: 500; }
    .new-staff-modal .modal-content form .cancel-button {
      text-decoration: underline;
      font-size: 0.875rem;
      font-weight: 400;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      margin-top: 1.5rem; }

.new-staff-modal .react-tel-input .flag-dropdown {
  background: var(--pure-white);
  border: none;
  border-radius: 0.525rem; }
  .new-staff-modal .react-tel-input .flag-dropdown .selected-flag {
    background: transparent;
    padding-left: 1rem;
    border: 1px solid #f2f1ec;
    border-right: none; }

.new-staff-modal .react-tel-input .country-list {
  box-shadow: 0px 4px 20px 1px rgba(0, 0, 0, 0.1);
  border-radius: 0.525rem; }
  .new-staff-modal .react-tel-input .country-list li {
    font-weight: 300; }

.rpm-provider-record-grid__column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: var(--pure-white);
  width: 100%; }
  .rpm-provider-record-grid__column__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;
    width: 100%;
    min-height: 2rem;
    height: 2rem;
    background-color: #f7f5f3; }
    .rpm-provider-record-grid__column__header__title {
      font-size: 0.75rem;
      color: #595959;
      text-align: center;
      font-weight: 500; }
    .rpm-provider-record-grid__column__header__icon-container {
      width: 1.5rem;
      height: 1.5rem; }
  .rpm-provider-record-grid__column__body {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    grid-gap: 0.5rem;
    gap: 0.5rem; }
    .rpm-provider-record-grid__column__body__title {
      font-size: 1rem;
      font-weight: 600;
      color: #595959; }

.rpm-provider-record-grid .tooltip-wrapper {
  position: relative;
  padding: 0.5rem;
  outline: none; }
  .rpm-provider-record-grid .tooltip-wrapper__tooltip {
    position: absolute;
    top: 0;
    left: 2rem;
    width: 18.5rem;
    min-width: 18.5rem;
    padding: 3rem 2.5rem;
    background: var(--pure-white);
    box-shadow: 0px 8px 13px rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem;
    display: flex;
    flex-direction: column;
    z-index: 2; }
    .rpm-provider-record-grid .tooltip-wrapper__tooltip__border {
      width: 100%;
      height: 1px;
      background-color: #d3d0cc; }
    .rpm-provider-record-grid .tooltip-wrapper__tooltip h3 {
      font-size: 1rem;
      font-weight: 600;
      color: var(--primary-dark);
      margin-bottom: 1.5rem;
      text-align: left; }
    .rpm-provider-record-grid .tooltip-wrapper__tooltip__triangle {
      position: absolute;
      top: 0.75rem;
      left: -0.5rem;
      width: 0;
      height: 0;
      border-top: 0.5rem solid transparent;
      border-bottom: 0.5rem solid transparent;
      border-right: 0.5rem solid var(--pure-white); }
    .rpm-provider-record-grid .tooltip-wrapper__tooltip__content {
      display: flex;
      flex-direction: column;
      width: 100%;
      grid-gap: 0.5rem;
      gap: 0.5rem;
      margin-top: 1.2rem; }
      .rpm-provider-record-grid .tooltip-wrapper__tooltip__content__item {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center; }
        .rpm-provider-record-grid .tooltip-wrapper__tooltip__content__item__tag {
          text-align: left;
          font-weight: 400;
          font-size: 0.875rem;
          color: #595959;
          max-width: 10rem;
          white-space: normal;
          word-wrap: break-word; }
        .rpm-provider-record-grid .tooltip-wrapper__tooltip__content__item__score {
          height: 2rem;
          width: 5rem;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          border-radius: 0.25rem; }
          .rpm-provider-record-grid .tooltip-wrapper__tooltip__content__item__score__value {
            font-size: 0.75rem;
            font-weight: 600; }

h1 {
  text-align: left; }

.rpm-download-app-desktop {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  height: 100vh;
  background-color: var(--primary-dark);
  overflow-y: hidden; }
  .rpm-download-app-desktop__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-left: 2.5rem;
    position: relative;
    width: 100%;
    height: 100vh;
    grid-gap: 3rem;
    gap: 3rem;
    position: relative; }
    .rpm-download-app-desktop__content__logout {
      position: absolute;
      top: 2.5rem;
      right: 2.5rem;
      color: var(--pure-white);
      font-size: 1rem;
      font-weight: 400; }
    .rpm-download-app-desktop__content #logo {
      height: 1.709rem;
      object-fit: contain;
      position: absolute;
      top: 2.5rem;
      left: 2.5rem; }
    .rpm-download-app-desktop__content h1 {
      color: var(--pure-white);
      font-size: 2.25rem;
      font-weight: 500;
      line-height: 3.5rem;
      width: 75%;
      font-family: var(--sans-serif-font-family); }
    .rpm-download-app-desktop__content__description {
      color: var(--pure-white);
      font-size: 0.875rem;
      font-weight: 400; }
    .rpm-download-app-desktop__content__qrs {
      display: flex;
      align-items: center;
      justify-content: center;
      grid-gap: 1.5rem;
      gap: 1.5rem; }
      .rpm-download-app-desktop__content__qrs__qr-container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        grid-gap: 1.5rem;
        gap: 1.5rem; }
        .rpm-download-app-desktop__content__qrs__qr-container__store {
          font-size: 1.125rem;
          font-weight: 600;
          color: var(--pure-white); }
        .rpm-download-app-desktop__content__qrs__qr-container__qr {
          width: 9.875rem;
          aspect-ratio: 1/1;
          object-fit: contain; }
        .rpm-download-app-desktop__content__qrs__qr-container__button {
          cursor: pointer; }
  .rpm-download-app-desktop__wires {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: relative;
    z-index: 1; }

.rpm-download-app-mobile {
  min-height: 100vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-top: 15vh;
  position: relative; }
  .rpm-download-app-mobile .background {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    min-height: 100vh;
    height: 100%;
    object-fit: cover; }
  .rpm-download-app-mobile .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8); }
  .rpm-download-app-mobile__logout {
    position: absolute;
    top: 1.5rem;
    right: 50%;
    -webkit-transform: translateX(50%);
            transform: translateX(50%);
    color: var(--pure-white);
    font-size: 1rem;
    font-weight: 400; }
  .rpm-download-app-mobile__content {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
    bottom: 0;
    align-items: center;
    grid-gap: 2.5rem;
    gap: 2.5rem;
    height: 100%; }
    .rpm-download-app-mobile__content h1 {
      font-size: 2rem;
      font-weight: 700;
      color: var(--pure-white);
      text-align: center; }
    .rpm-download-app-mobile__content__get-it-on {
      display: flex;
      flex-direction: column;
      align-items: center;
      grid-gap: 1rem;
      gap: 1rem; }
      .rpm-download-app-mobile__content__get-it-on img {
        width: 11.84375rem;
        object-fit: contain; }
    .rpm-download-app-mobile__content__background {
      width: 84vw;
      object-fit: contain; }

h1 {
  text-align: left; }

.rpm-client-profile__container {
  max-width: 1140px;
  margin: 0 auto;
  margin-top: 1rem;
  margin-bottom: 1rem;
  min-height: calc(100vh - 7rem);
  width: 100%;
  overflow: hidden;
  border-radius: 0.625rem;
  padding: 2rem;
  display: grid;
  grid-template-areas: 'client-profile-left client-profile-right client-profile-right client-profile-right' 'client-profile-left client-profile-right client-profile-right client-profile-right' 'client-profile-left client-profile-right client-profile-right client-profile-right' 'client-profile-left client-profile-right client-profile-right client-profile-right';
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 1rem;
  gap: 1rem; }
  @media (max-width: 767.98px) {
    .rpm-client-profile__container {
      grid-template-areas: 'header' 'client-profile-left' 'client-profile-right' 'client-profile-right' 'client-profile-right';
      grid-template-columns: 1fr;
      margin-top: 0;
      padding: 0;
      place-content: center;
      width: 100%;
      grid-gap: 1.5rem;
      gap: 1.5rem; } }
  .rpm-client-profile__container__right {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-area: client-profile-right;
    grid-gap: 1rem;
    gap: 1rem; }

.new-assessment-modal .modal-content {
  background-color: var(--pure-white);
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding: 3rem 5rem; }
  .new-assessment-modal .modal-content form {
    margin-top: 1rem; }
    .new-assessment-modal .modal-content form .form-group {
      display: flex;
      flex-direction: column; }
      .new-assessment-modal .modal-content form .form-group label {
        font-size: 0.75rem;
        font-weight: 300;
        color: #40413f;
        margin-bottom: 0.625rem; }
      .new-assessment-modal .modal-content form .form-group .assessment-wrapper {
        position: relative; }
        .new-assessment-modal .modal-content form .form-group .assessment-wrapper select {
          height: 2.8125rem;
          padding-left: 2.8125rem;
          padding-right: 1.2rem; }
        .new-assessment-modal .modal-content form .form-group .assessment-wrapper .notes-icon {
          position: absolute;
          top: 1rem;
          left: 1rem;
          -webkit-transform: translateY(-50%);
                  transform: translateY(-50%); }
      .new-assessment-modal .modal-content form .form-group .date-wrapper {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
        grid-gap: 1rem;
        gap: 1rem; }
        .new-assessment-modal .modal-content form .form-group .date-wrapper p {
          font-weight: 600; }
        .new-assessment-modal .modal-content form .form-group .date-wrapper input {
          height: 2.8125rem;
          border: 1px solid #f2f1ec;
          padding: 0;
          text-align: center; }
        .new-assessment-modal .modal-content form .form-group .date-wrapper #month,
        .new-assessment-modal .modal-content form .form-group .date-wrapper #day {
          width: 4.9375rem;
          font-weight: 300; }
        .new-assessment-modal .modal-content form .form-group .date-wrapper #year {
          width: 6.25rem;
          font-weight: 300; }
      .new-assessment-modal .modal-content form .form-group input::-webkit-input-placeholder {
        opacity: 0.5; }
      .new-assessment-modal .modal-content form .form-group input::placeholder {
        opacity: 0.5; }
      .new-assessment-modal .modal-content form .form-group #score {
        width: 4rem;
        height: 2.8125rem;
        border: 1px solid #f2f1ec;
        padding: 0;
        text-align: center;
        font-size: 0.875rem;
        font-style: italic;
        font-weight: 300; }
      .new-assessment-modal .modal-content form .form-group #assessment_result_link {
        width: 100%;
        height: 2.8125rem;
        border: 1px solid #f2f1ec;
        text-align: left;
        padding-left: 1rem;
        font-size: 0.875rem;
        font-style: italic;
        font-weight: 300; }
    .new-assessment-modal .modal-content form .error-message {
      color: var(--primary-red);
      font-size: 0.7rem;
      margin: 0;
      margin-bottom: 1rem;
      font-weight: 700; }
    .new-assessment-modal .modal-content form .submit-button {
      height: 3.125rem;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--primary-dark);
      color: var(--pure-white);
      border-radius: 0.625rem;
      outline: none;
      font-weight: 500; }
    .new-assessment-modal .modal-content form .cancel-button {
      text-decoration: underline;
      font-size: 0.875rem;
      font-weight: 400;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      margin-top: 1.5rem; }

.add-cpt-modal .modal-content {
  background-color: var(--pure-white);
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding: 3rem 5rem; }
  .add-cpt-modal .modal-content form {
    margin-top: 1rem; }
    .add-cpt-modal .modal-content form .form-group {
      display: flex;
      flex-direction: column; }
      .add-cpt-modal .modal-content form .form-group label {
        font-size: 0.75rem;
        font-weight: 300;
        color: #40413f;
        margin-bottom: 0.625rem; }
      .add-cpt-modal .modal-content form .form-group .select-cpt-dropdown {
        position: relative;
        height: 2.8125rem;
        background: var(--pure-white);
        border: 1px solid #f2f1ec;
        border-radius: 0.625rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 1.5625rem;
        padding-right: 1.1875rem;
        width: 100%; }
        .add-cpt-modal .modal-content form .form-group .select-cpt-dropdown p {
          font-size: 0.875rem;
          font-weight: 600; }
        .add-cpt-modal .modal-content form .form-group .select-cpt-dropdown__options {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          background: var(--pure-white);
          border: 1px solid #f2f1ec;
          border-radius: 0.625rem;
          z-index: 100;
          height: 21rem;
          max-height: 21rem;
          overflow-y: auto;
          display: flex;
          flex-direction: column;
          box-shadow: 1px 1px 20px 1px rgba(0, 0, 0, 0.05); }
          .add-cpt-modal .modal-content form .form-group .select-cpt-dropdown__options__option {
            min-height: 5rem;
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 100%;
            border-bottom: 1px solid #f2f1ec;
            cursor: pointer;
            padding: 0 1rem; }
            .add-cpt-modal .modal-content form .form-group .select-cpt-dropdown__options__option p {
              font-weight: 300;
              font-size: 0.875rem; }
              .add-cpt-modal .modal-content form .form-group .select-cpt-dropdown__options__option p strong {
                font-weight: 600; }
            .add-cpt-modal .modal-content form .form-group .select-cpt-dropdown__options__option .description {
              font-size: 0.7rem;
              font-weight: 300; }
            .add-cpt-modal .modal-content form .form-group .select-cpt-dropdown__options__option:hover {
              background-color: #f2f1ec; }
      .add-cpt-modal .modal-content form .form-group .date-wrapper {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
        grid-gap: 1rem;
        gap: 1rem; }
        .add-cpt-modal .modal-content form .form-group .date-wrapper p {
          font-weight: 600; }
        .add-cpt-modal .modal-content form .form-group .date-wrapper input {
          height: 2.8125rem;
          border: 1px solid #f2f1ec;
          padding: 0;
          text-align: center; }
        .add-cpt-modal .modal-content form .form-group .date-wrapper #month,
        .add-cpt-modal .modal-content form .form-group .date-wrapper #day {
          width: 4.9375rem;
          font-weight: 300; }
        .add-cpt-modal .modal-content form .form-group .date-wrapper #year {
          width: 6.25rem;
          font-weight: 300; }
      .add-cpt-modal .modal-content form .form-group input::-webkit-input-placeholder, .add-cpt-modal .modal-content form .form-group textarea::-webkit-input-placeholder {
        opacity: 0.5; }
      .add-cpt-modal .modal-content form .form-group input::placeholder,
      .add-cpt-modal .modal-content form .form-group textarea::placeholder {
        opacity: 0.5; }
      .add-cpt-modal .modal-content form .form-group .simple-text-input,
      .add-cpt-modal .modal-content form .form-group textarea {
        height: 2.8125rem;
        border: 1px solid #f2f1ec;
        border-radius: 0.625rem;
        padding: 0 1rem;
        font-size: 0.875rem; }
        .add-cpt-modal .modal-content form .form-group .simple-text-input::-webkit-input-placeholder, .add-cpt-modal .modal-content form .form-group textarea::-webkit-input-placeholder {
          font-weight: 300;
          font-style: italic; }
        .add-cpt-modal .modal-content form .form-group .simple-text-input::placeholder,
        .add-cpt-modal .modal-content form .form-group textarea::placeholder {
          font-weight: 300;
          font-style: italic; }
      .add-cpt-modal .modal-content form .form-group textarea {
        height: 6rem;
        padding-top: 1rem;
        resize: none; }
      .add-cpt-modal .modal-content form .form-group #score {
        width: 4rem;
        height: 2.8125rem;
        border: 1px solid #f2f1ec;
        padding: 0;
        text-align: center;
        font-size: 0.875rem;
        font-style: italic;
        font-weight: 300; }
      .add-cpt-modal .modal-content form .form-group #assessment_result_link {
        width: 100%;
        height: 2.8125rem;
        border: 1px solid #f2f1ec;
        text-align: left;
        padding-left: 1rem;
        font-size: 0.875rem;
        font-style: italic;
        font-weight: 300; }
    .add-cpt-modal .modal-content form .error-message {
      color: var(--primary-red);
      font-size: 0.7rem;
      margin: 0;
      margin-bottom: 1rem;
      font-weight: 700; }
    .add-cpt-modal .modal-content form .submit-button {
      height: 3.125rem;
      width: 100%;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--primary-dark);
      color: var(--pure-white);
      border-radius: 0.625rem;
      outline: none; }
    .add-cpt-modal .modal-content form .cancel-button {
      text-decoration: underline;
      font-size: 0.875rem;
      font-weight: 400;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      margin-top: 1.5rem; }

.rpm-client-profile__container__right-body .profile-details-section {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  margin-top: 2.25rem; }
  .rpm-client-profile__container__right-body .profile-details-section input[type='date']::-webkit-inner-spin-button,
  .rpm-client-profile__container__right-body .profile-details-section input[type='date']::-webkit-calendar-picker-indicator {
    padding-right: 0.5rem; }
  .rpm-client-profile__container__right-body .profile-details-section__header {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 0.8125rem; }
    .rpm-client-profile__container__right-body .profile-details-section__header__title {
      font-size: 0.875rem;
      font-weight: 600; }
    .rpm-client-profile__container__right-body .profile-details-section__header__edit {
      font-size: 0.75rem;
      font-weight: 500;
      cursor: pointer;
      text-decoration: underline; }
  .rpm-client-profile__container__right-body .profile-details-section__body {
    display: flex;
    flex-direction: column;
    width: 100%; }
    .rpm-client-profile__container__right-body .profile-details-section__body__row {
      height: 3.375rem;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      width: 100%;
      grid-gap: 1rem;
      gap: 1rem;
      grid-template-areas: 'label value value';
      border-bottom: 1px solid #e8e6e6;
      align-items: center; }
      .rpm-client-profile__container__right-body .profile-details-section__body__row__label {
        grid-area: label;
        font-size: 0.75rem;
        font-weight: 400;
        color: #4a4a4a;
        display: flex;
        align-items: center;
        justify-content: flex-start; }
        .rpm-client-profile__container__right-body .profile-details-section__body__row__label__icon {
          margin-right: 0.5rem;
          width: 1.8125rem;
          height: 1.8125rem;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          border-radius: 50%;
          aspect-ratio: 1/1; }
        .rpm-client-profile__container__right-body .profile-details-section__body__row__label__title {
          font-size: 0.875rem;
          font-weight: 600;
          color: var(--primary-dark); }
          @media (max-width: 767.98px) {
            .rpm-client-profile__container__right-body .profile-details-section__body__row__label__title {
              font-size: 0.625rem; } }
      .rpm-client-profile__container__right-body .profile-details-section__body__row__value {
        grid-area: value;
        font-size: 0.75rem;
        font-weight: 400;
        color: #4a4a4a;
        display: flex;
        align-items: center;
        justify-content: flex-start; }
        @media (max-width: 767.98px) {
          .rpm-client-profile__container__right-body .profile-details-section__body__row__value {
            font-size: 0.625rem; } }
        .rpm-client-profile__container__right-body .profile-details-section__body__row__value__icon {
          margin-right: 0.5rem; }
      .rpm-client-profile__container__right-body .profile-details-section__body__row__input-container {
        position: relative;
        grid-area: value;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 14.375rem; }
      .rpm-client-profile__container__right-body .profile-details-section__body__row__input {
        grid-area: value;
        height: 2rem;
        min-height: 2rem;
        background: var(--pure-white);
        border: 1px solid #d3d0cc;
        border-radius: 0.3125rem;
        font-size: 0.875rem;
        text-align: left;
        width: 14.375rem;
        font-weight: 400;
        color: var(--primary-dark);
        padding-left: 0.625rem; }
        .rpm-client-profile__container__right-body .profile-details-section__body__row__input::-webkit-input-placeholder {
          font-size: 0.875rem; }
        .rpm-client-profile__container__right-body .profile-details-section__body__row__input::placeholder {
          font-size: 0.875rem; }
      .rpm-client-profile__container__right-body .profile-details-section__body__row__checkboxes {
        display: flex;
        align-items: center;
        justify-content: center;
        grid-gap: 1rem;
        gap: 1rem;
        position: relative; }
        .rpm-client-profile__container__right-body .profile-details-section__body__row__checkboxes__checkbox {
          display: flex;
          align-items: center;
          justify-content: center;
          grid-gap: 0.5rem;
          gap: 0.5rem;
          outline: none; }
          .rpm-client-profile__container__right-body .profile-details-section__body__row__checkboxes__checkbox__label {
            font-size: 0.75rem;
            font-weight: 300;
            color: #c4c4c4; }
          .rpm-client-profile__container__right-body .profile-details-section__body__row__checkboxes__checkbox .active {
            color: var(--primary-dark); }

.rpm-client-profile .cancel-subscription-btn {
  width: 35%;
  color: var(--pure-white);
  background-color: #e8744f;
  height: 2rem;
  border-radius: 0.3125rem;
  font-weight: 500;
  font-size: 0.75rem;
  margin-top: 1rem;
  outline: none; }
  @media (max-width: 767.98px) {
    .rpm-client-profile .cancel-subscription-btn {
      width: 100%; } }

.cancel-subscription-modal .modal-content {
  display: flex;
  flex-direction: column;
  grid-gap: 0.875rem;
  gap: 0.875rem;
  margin-top: calc(50vh - 6.5rem);
  min-height: 14.375rem;
  width: 30.56rem;
  align-items: center;
  justify-content: center;
  padding: 3rem;
  position: relative; }
  @media (max-width: 767.98px) {
    .cancel-subscription-modal .modal-content {
      width: 90vw;
      margin: auto;
      margin-top: 5rem; } }
  .cancel-subscription-modal .modal-content h3 {
    font-size: 1rem;
    font-weight: 600;
    color: var(--primary-dark);
    font-family: var(--sans-serif-font-family); }
  .cancel-subscription-modal .modal-content p {
    font-size: 0.875rem;
    font-weight: 300;
    color: var(--primary-dark);
    text-align: center; }
  .cancel-subscription-modal .modal-content p.red-text {
    font-size: 0.875rem;
    font-weight: 500;
    color: #e8744f;
    text-align: center; }

.cancel-subscription-modal__warning {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #f9dad0;
  width: 100%;
  padding: 1rem;
  border-left: 4px solid #e8744f; }

.cancel-subscription-modal__buttons {
  display: flex;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem; }
  @media (max-width: 767.98px) {
    .cancel-subscription-modal__buttons {
      width: 100%; } }
  .cancel-subscription-modal__buttons__button {
    width: 10.93rem;
    height: 3.125rem;
    border: 1px solid var(--primary-dark);
    font-weight: 500;
    color: var(--primary-dark);
    border-radius: 0.625rem; }
    @media (max-width: 767.98px) {
      .cancel-subscription-modal__buttons__button {
        width: 100%; } }

.cancel-subscription-modal__close {
  position: absolute;
  right: 1rem;
  top: 1rem;
  height: 1.5rem;
  width: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  outline: none; }

.profile-details-section-validation-alert {
  position: absolute;
  height: 2rem;
  padding: 0.3rem 0.75rem;
  background-color: #e8744f;
  border-radius: 0.25rem;
  top: -3.5rem;
  left: 0;
  display: flex;
  align-items: center;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  min-width: 100%; }
  .profile-details-section-validation-alert .alert-icon-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center; }
    .profile-details-section-validation-alert .alert-icon-container .alert-icon-square {
      width: 0.875rem;
      height: 0.875rem;
      aspect-ratio: 1/1;
      background-color: #e8744f;
      border-radius: 0.1875rem;
      -webkit-transform: rotate(45deg);
              transform: rotate(45deg); }
    .profile-details-section-validation-alert .alert-icon-container .alert-icon {
      color: var(--pure-white);
      font-size: 0.5rem;
      font-weight: 800;
      z-index: 10;
      position: absolute; }
  .profile-details-section-validation-alert__message {
    color: var(--pure-white);
    font-size: 0.75rem;
    font-weight: 600; }

.rpm-client-profile__container__right-body__billing {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
  margin-top: 1rem; }
  .rpm-client-profile__container__right-body__billing__header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .rpm-client-profile__container__right-body__billing__header__title {
      font-size: 0.75rem;
      font-weight: 400;
      width: 100%;
      max-width: -webkit-max-content;
      max-width: max-content; }
      .rpm-client-profile__container__right-body__billing__header__title strong {
        font-weight: 600; }
    .rpm-client-profile__container__right-body__billing__header__line {
      width: 100%;
      height: 1px;
      background-color: #bebdbd;
      display: flex;
      align-items: center;
      margin-left: 1.1875rem;
      margin-right: 1rem; }
    .rpm-client-profile__container__right-body__billing__header__tag {
      width: 3.8125rem;
      height: 1.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      grid-gap: 0.1875rem;
      gap: 0.1875rem;
      border: 1px solid #88a088;
      border-radius: 7.8125rem;
      padding: 0 0.5rem; }
      .rpm-client-profile__container__right-body__billing__header__tag__dot {
        width: 0.3125rem;
        height: 0.3125rem;
        border-radius: 50%;
        background-color: #88a088; }
      .rpm-client-profile__container__right-body__billing__header__tag__text {
        font-size: 0.625rem;
        font-weight: 500;
        color: #88a088; }
  .rpm-client-profile__container__right-body__billing__record {
    min-height: 14.9375rem;
    width: 100%;
    background-color: var(--pure-white);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    border-radius: 0.625rem;
    position: relative; }
    .rpm-client-profile__container__right-body__billing__record__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%; }
      .rpm-client-profile__container__right-body__billing__record__header__title {
        font-size: 0.875rem;
        font-weight: 400;
        color: #595959;
        width: 95%; }
        .rpm-client-profile__container__right-body__billing__record__header__title strong {
          font-weight: 600; }
      .rpm-client-profile__container__right-body__billing__record__header__dots {
        cursor: pointer;
        color: #c4c4c4; }
    .rpm-client-profile__container__right-body__billing__record__header-2 {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      width: 100%;
      margin-top: 0.75rem;
      padding-bottom: 1.375rem;
      border-bottom: 1px solid #dbd9d9; }
      .rpm-client-profile__container__right-body__billing__record__header-2__left {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        grid-gap: 2px;
        gap: 2px; }
        .rpm-client-profile__container__right-body__billing__record__header-2__left__title {
          font-size: 0.75rem;
          font-weight: 400;
          color: #595959; }
          .rpm-client-profile__container__right-body__billing__record__header-2__left__title strong {
            font-weight: 600; }
        .rpm-client-profile__container__right-body__billing__record__header-2__left__subtitle {
          font-size: 0.75rem;
          font-weight: 400;
          color: #595959; }
      .rpm-client-profile__container__right-body__billing__record__header-2__right {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        grid-gap: 2px;
        gap: 2px; }
        .rpm-client-profile__container__right-body__billing__record__header-2__right__text {
          font-size: 0.75rem;
          font-weight: 400;
          color: #595959;
          text-align: right; }
    .rpm-client-profile__container__right-body__billing__record__body {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      grid-gap: 0.5rem;
      gap: 0.5rem;
      margin-top: 1.5rem;
      width: 100%; }
      .rpm-client-profile__container__right-body__billing__record__body__title {
        font-size: 0.75rem;
        font-weight: 600;
        color: #595959;
        width: 100%; }
      .rpm-client-profile__container__right-body__billing__record__body__text {
        font-size: 0.75rem;
        font-weight: 400;
        color: #595959;
        width: 100%; }
    .rpm-client-profile__container__right-body__billing__record__dots {
      position: absolute;
      right: 1rem;
      top: 1rem;
      height: 1.5rem;
      width: 1.5rem;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      outline: none; }
    .rpm-client-profile__container__right-body__billing__record__tooltip {
      width: 9.3125rem;
      height: 5.25rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0 0.5rem;
      position: absolute;
      right: 1rem;
      top: 1rem;
      background-color: var(--pure-white);
      border-radius: 0.25rem;
      box-shadow: 1px 1px 20px 1px rgba(0, 0, 0, 0.05); }
      .rpm-client-profile__container__right-body__billing__record__tooltip__item {
        display: flex;
        width: 100%;
        height: 2.625rem;
        align-items: center; }
        .rpm-client-profile__container__right-body__billing__record__tooltip__item__text {
          font-size: 0.75rem;
          font-weight: 600;
          color: var(--primary-dark);
          margin-left: 0.5rem;
          width: 100%;
          text-align: left; }

.delete-cpt-code-modal .modal-content {
  display: flex;
  flex-direction: column;
  grid-gap: 0.875rem;
  gap: 0.875rem;
  margin-top: calc(50vh - 6.5rem);
  height: 14.375rem;
  width: 30.56rem;
  align-items: center;
  justify-content: center;
  position: relative; }
  @media (max-width: 767.98px) {
    .delete-cpt-code-modal .modal-content {
      width: 90vw;
      margin: auto;
      margin-top: 5rem; } }
  .delete-cpt-code-modal .modal-content h3 {
    font-size: 1rem;
    font-weight: 600;
    color: var(--primary-dark);
    font-family: var(--sans-serif-font-family); }
  .delete-cpt-code-modal .modal-content p {
    font-size: 0.875rem;
    font-weight: 300;
    color: var(--primary-dark);
    text-align: center; }

.delete-cpt-code-modal__buttons {
  display: flex;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem; }
  @media (max-width: 767.98px) {
    .delete-cpt-code-modal__buttons {
      width: 100%; } }
  .delete-cpt-code-modal__buttons__button {
    width: 10.93rem;
    height: 3.125rem;
    border: 1px solid var(--primary-dark);
    font-weight: 500;
    color: var(--primary-dark);
    border-radius: 0.625rem; }
    @media (max-width: 767.98px) {
      .delete-cpt-code-modal__buttons__button {
        width: 100%; } }

.delete-cpt-code-modal__close {
  position: absolute;
  right: 1rem;
  top: 1rem;
  height: 1.5rem;
  width: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  outline: none; }

.container-skeleton, .billing-card-skeleton__title, .billing-card-skeleton__description-1, .billing-card-skeleton__description-2, .billing-card-skeleton__divider, .billing-card-skeleton__footer-1, .billing-card-skeleton__footer-2, .billing-card-skeleton__footer-3, .billing-card-skeleton__footer-4, .activity-card-skeleton__profile, .activity-card-skeleton__name, .activity-card-skeleton__description, .activity-card-skeleton__score, .skeleton-user-card__avatar, .skeleton-user-card__user-info, .skeleton-user-card__alerts-circle, .skeleton-user-card__line, .skeleton-user-card__info__top, .skeleton-user-card__info__bottom {
  -webkit-animation: container-skeleton-loading 1s linear infinite alternate;
          animation: container-skeleton-loading 1s linear infinite alternate; }

@keyframes container-skeleton-loading {
  0% {
    background-color: var(--primary-white); }
  100% {
    background-color: var(--primary-white); } }

.billing-card-skeleton {
  height: 14.93rem;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  background-color: var(--pure-white);
  position: relative; }
  .billing-card-skeleton__title {
    position: absolute;
    height: 0.625rem;
    left: 1rem;
    top: 1.5rem;
    width: 9.25rem;
    border-radius: 0.25rem; }
  .billing-card-skeleton__description-1 {
    position: absolute;
    height: 0.625rem;
    left: 1rem;
    top: 3.5625rem;
    width: 9.875rem;
    border-radius: 0.25rem; }
  .billing-card-skeleton__description-2 {
    position: absolute;
    height: 0.625rem;
    left: 1rem;
    top: 4.5625rem;
    width: 5.875rem;
    border-radius: 0.25rem; }
  .billing-card-skeleton__divider {
    height: 1px;
    width: calc(100% - 2rem);
    position: absolute;
    left: 1rem;
    top: 6.875rem; }
  .billing-card-skeleton__footer-1, .billing-card-skeleton__footer-2, .billing-card-skeleton__footer-3, .billing-card-skeleton__footer-4 {
    position: absolute;
    height: 0.625rem;
    left: 1rem;
    top: 4.5625rem;
    width: 5.875rem;
    border-radius: 0.25rem; }
  .billing-card-skeleton__footer-1 {
    top: 8.3125rem;
    width: 3.5625rem; }
  .billing-card-skeleton__footer-2 {
    top: 9.75rem;
    width: calc(100% - 2rem); }
  .billing-card-skeleton__footer-3 {
    top: 11.125rem;
    width: calc(100% - 8rem); }
  .billing-card-skeleton__footer-4 {
    top: 12.5rem;
    width: calc(100% - 5rem); }

.rpm-client-profile__container__left {
  height: 100%;
  width: 100%;
  min-width: 19.625rem;
  max-width: 19.625rem;
  background-color: var(--primary-white);
  grid-area: client-profile-left;
  border-radius: 0.625rem;
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem; }
  @media (max-width: 767.98px) {
    .rpm-client-profile__container__left {
      width: calc(100vw - 4rem);
      max-width: calc(100vw - 4rem);
      min-width: calc(100vw - 4rem);
      margin: 0 auto; } }
  .rpm-client-profile__container__left .rpm-profile-info-container {
    display: flex;
    flex-direction: column; }
    .rpm-client-profile__container__left .rpm-profile-info-container__picture-header {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%; }
      .rpm-client-profile__container__left .rpm-profile-info-container__picture-header .background {
        width: 100%;
        border-radius: 0.625rem 0.625rem 0 0;
        object-fit: cover;
        height: 5.125rem; }
      .rpm-client-profile__container__left .rpm-profile-info-container__picture-header__picture {
        margin-top: -2.5rem;
        position: relative; }
        .rpm-client-profile__container__left .rpm-profile-info-container__picture-header__picture img {
          width: 6rem;
          height: 6rem;
          aspect-ratio: 1/1;
          border-radius: 0.5rem 0.5rem 0 0.5rem;
          border: 2px solid rgba(211, 208, 204, 0.46);
          box-sizing: border-box;
          z-index: 1; }
        .rpm-client-profile__container__left .rpm-profile-info-container__picture-header__picture__status {
          height: 1.5rem;
          padding: 0.5rem;
          border-radius: 7.8125rem;
          display: flex;
          align-items: center;
          justify-content: center;
          grid-gap: 0.5rem;
          gap: 0.5rem;
          border: 1px solid #718371;
          background-color: var(--pure-white);
          z-index: 2;
          position: absolute;
          bottom: -0.75rem;
          right: 50%;
          -webkit-transform: translateX(50%);
                  transform: translateX(50%); }
          .rpm-client-profile__container__left .rpm-profile-info-container__picture-header__picture__status__dot {
            height: 0.3125rem;
            width: 0.3125rem;
            aspect-ratio: 1/1;
            border-radius: 50%;
            background-color: #718371; }
          .rpm-client-profile__container__left .rpm-profile-info-container__picture-header__picture__status__text {
            font-size: 0.625rem;
            color: #718371;
            font-weight: 500; }
        .rpm-client-profile__container__left .rpm-profile-info-container__picture-header__picture .eligible {
          border: 1px solid #718371; }
          .rpm-client-profile__container__left .rpm-profile-info-container__picture-header__picture .eligible .dot {
            background-color: #718371; }
          .rpm-client-profile__container__left .rpm-profile-info-container__picture-header__picture .eligible .text {
            color: #718371; }
        .rpm-client-profile__container__left .rpm-profile-info-container__picture-header__picture .ineligible {
          border: 1px solid #e8744f; }
          .rpm-client-profile__container__left .rpm-profile-info-container__picture-header__picture .ineligible .dot {
            background-color: #e8744f; }
          .rpm-client-profile__container__left .rpm-profile-info-container__picture-header__picture .ineligible .text {
            color: #e8744f; }
    .rpm-client-profile__container__left .rpm-profile-info-container__info {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 90%;
      margin: 0 auto;
      margin-top: 1rem;
      grid-gap: 0.5rem;
      gap: 0.5rem; }
      .rpm-client-profile__container__left .rpm-profile-info-container__info p {
        text-align: center;
        font-size: 0.75rem;
        font-weight: 300; }
        .rpm-client-profile__container__left .rpm-profile-info-container__info p span {
          font-weight: 600; }
      .rpm-client-profile__container__left .rpm-profile-info-container__info h1 {
        text-align: center;
        font-size: 1.5rem; }
      .rpm-client-profile__container__left .rpm-profile-info-container__info__buttons {
        display: flex;
        flex-direction: column;
        grid-gap: 0.5rem;
        gap: 0.5rem;
        align-items: center;
        width: 100%;
        margin-top: 1rem; }
        .rpm-client-profile__container__left .rpm-profile-info-container__info__buttons__button {
          width: 100%;
          height: 2rem;
          border-radius: 0.3125rem;
          font-size: 0.75rem; }
        .rpm-client-profile__container__left .rpm-profile-info-container__info__buttons .dark {
          background-color: var(--primary-dark);
          color: var(--primary-white); }
      .rpm-client-profile__container__left .rpm-profile-info-container__info__add_header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin: 1rem 0; }
        .rpm-client-profile__container__left .rpm-profile-info-container__info__add_header p {
          font-size: 0.875rem;
          font-weight: 500;
          text-align: left; }
        .rpm-client-profile__container__left .rpm-profile-info-container__info__add_header button {
          height: 1.25rem;
          width: 1.25rem;
          aspect-ratio: 1/1;
          border-radius: 0.3125rem;
          display: flex;
          align-items: center;
          justify-content: center;
          outline: none;
          background-color: var(--primary-dark); }
      .rpm-client-profile__container__left .rpm-profile-info-container__info__care-team {
        display: flex;
        flex-direction: column;
        grid-gap: 0.5rem;
        gap: 0.5rem;
        width: 100%; }
        .rpm-client-profile__container__left .rpm-profile-info-container__info__care-team__member {
          height: 5rem;
          background-color: var(--pure-white);
          border-radius: 0.625rem;
          box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
          padding: 1rem;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between; }
          .rpm-client-profile__container__left .rpm-profile-info-container__info__care-team__member__name-container {
            display: flex;
            flex-direction: row;
            align-items: center;
            grid-gap: 0.5rem;
            gap: 0.5rem; }
            .rpm-client-profile__container__left .rpm-profile-info-container__info__care-team__member__name-container__picture {
              height: 3rem;
              width: 3rem;
              aspect-ratio: 1/1; }
              .rpm-client-profile__container__left .rpm-profile-info-container__info__care-team__member__name-container__picture img {
                height: 100%;
                width: 100%;
                border-radius: 0.5rem 0.5rem 0 0.5rem; }
            .rpm-client-profile__container__left .rpm-profile-info-container__info__care-team__member__name-container__name {
              display: flex;
              flex-direction: column;
              align-items: flex-start; }
              .rpm-client-profile__container__left .rpm-profile-info-container__info__care-team__member__name-container__name p {
                font-size: 0.875rem;
                font-weight: 600;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap; }
              .rpm-client-profile__container__left .rpm-profile-info-container__info__care-team__member__name-container__name .subtitle {
                font-size: 0.625rem;
                font-weight: 400;
                color: var(--primary-dark);
                opacity: 0.5; }
          .rpm-client-profile__container__left .rpm-profile-info-container__info__care-team__member .chat-btn {
            outline: none; }

.rpm-client-profile__container__right-body__header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: relative; }
  .rpm-client-profile__container__right-body__header__options-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%; }
    .rpm-client-profile__container__right-body__header__options-wrapper__options {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 0.5rem;
      gap: 0.5rem;
      max-width: 36rem; }
      .rpm-client-profile__container__right-body__header__options-wrapper__options__option {
        padding: 0.875rem 0;
        width: 6.875rem;
        max-width: 6.875rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer; }
        .rpm-client-profile__container__right-body__header__options-wrapper__options__option__text {
          font-size: 0.875rem;
          font-weight: 400;
          color: #686868;
          white-space: nowrap; }
      @media (max-width: 767.98px) {
        .rpm-client-profile__container__right-body__header__options-wrapper__options {
          max-width: 100vw; }
          .rpm-client-profile__container__right-body__header__options-wrapper__options__option {
            width: 3.375rem;
            max-width: 3.375rem; } }
    @media (max-width: 767.98px) and (max-width: 767.98px) {
      .rpm-client-profile__container__right-body__header__options-wrapper__options__option__text {
        font-size: 0.75rem; } }
    .rpm-client-profile__container__right-body__header__options-wrapper__selected-bar {
      width: 100%;
      position: relative;
      height: 1px;
      background-color: #d3d0cc;
      display: flex;
      flex-direction: row; }
      .rpm-client-profile__container__right-body__header__options-wrapper__selected-bar__bar {
        position: absolute;
        width: 6.875rem;
        height: 2px;
        background-color: #e8744f;
        bottom: 0;
        left: 0;
        transition: all 0.3s ease-in-out; }
        @media (max-width: 767.98px) {
          .rpm-client-profile__container__right-body__header__options-wrapper__selected-bar__bar {
            width: 3.375rem; } }
  .rpm-client-profile__container__right-body__header__add {
    background-color: #343434;
    height: 1.25rem;
    width: 1.25rem;
    aspect-ratio: 1/1;
    border-radius: 0.3125rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0;
    outline: none;
    position: absolute;
    cursor: pointer; }
    .rpm-client-profile__container__right-body__header__add__options {
      position: absolute;
      top: 1.75rem;
      right: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 9.3125rem;
      height: -webkit-max-content;
      height: max-content;
      background-color: var(--pure-white);
      border: 1px solid #f8f5f5;
      box-shadow: 1px 1px 20px 1px rgba(0, 0, 0, 0.05);
      border-radius: 4px;
      z-index: 1; }
      .rpm-client-profile__container__right-body__header__add__options__option {
        height: 2.625rem;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        min-height: 43px;
        border-bottom: 1px solid rgba(196, 196, 196, 0.3); }
        .rpm-client-profile__container__right-body__header__add__options__option:last-child {
          border-bottom: none; }
        .rpm-client-profile__container__right-body__header__add__options__option__text {
          font-size: 0.75rem;
          font-weight: 600; }

.rpm-client-profile__container__right-body__header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: relative; }
  .rpm-client-profile__container__right-body__header__options-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%; }
    .rpm-client-profile__container__right-body__header__options-wrapper__options {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 0.5rem;
      gap: 0.5rem;
      max-width: 36rem; }
      .rpm-client-profile__container__right-body__header__options-wrapper__options__option {
        padding: 0.875rem 0;
        width: 6.875rem;
        max-width: 6.875rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer; }
        .rpm-client-profile__container__right-body__header__options-wrapper__options__option__text {
          font-size: 0.875rem;
          font-weight: 400;
          color: #686868;
          white-space: nowrap; }
      @media (max-width: 767.98px) {
        .rpm-client-profile__container__right-body__header__options-wrapper__options {
          max-width: 100vw; }
          .rpm-client-profile__container__right-body__header__options-wrapper__options__option {
            width: 3.375rem;
            max-width: 3.375rem; } }
    @media (max-width: 767.98px) and (max-width: 767.98px) {
      .rpm-client-profile__container__right-body__header__options-wrapper__options__option__text {
        font-size: 0.75rem; } }
    .rpm-client-profile__container__right-body__header__options-wrapper__selected-bar {
      width: 100%;
      position: relative;
      height: 1px;
      background-color: #d3d0cc;
      display: flex;
      flex-direction: row; }
      .rpm-client-profile__container__right-body__header__options-wrapper__selected-bar__bar {
        position: absolute;
        width: 6.875rem;
        height: 2px;
        background-color: #e8744f;
        bottom: 0;
        left: 0;
        transition: all 0.3s ease-in-out; }
        @media (max-width: 767.98px) {
          .rpm-client-profile__container__right-body__header__options-wrapper__selected-bar__bar {
            width: 3.375rem; } }
  .rpm-client-profile__container__right-body__header__add {
    background-color: #343434;
    height: 1.25rem;
    width: 1.25rem;
    aspect-ratio: 1/1;
    border-radius: 0.3125rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0;
    outline: none;
    position: absolute;
    cursor: pointer; }
    .rpm-client-profile__container__right-body__header__add__options {
      position: absolute;
      top: 1.75rem;
      right: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 9.3125rem;
      height: -webkit-max-content;
      height: max-content;
      background-color: var(--pure-white);
      border: 1px solid #f8f5f5;
      box-shadow: 1px 1px 20px 1px rgba(0, 0, 0, 0.05);
      border-radius: 4px;
      z-index: 1; }
      .rpm-client-profile__container__right-body__header__add__options__option {
        height: 2.625rem;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        min-height: 43px;
        border-bottom: 1px solid rgba(196, 196, 196, 0.3); }
        .rpm-client-profile__container__right-body__header__add__options__option:last-child {
          border-bottom: none; }
        .rpm-client-profile__container__right-body__header__add__options__option__text {
          font-size: 0.75rem;
          font-weight: 600; }

.rpm-client-profile-right-header {
  background-color: var(--primary-dark);
  width: 100%;
  height: 10.25rem;
  min-height: 10.25rem;
  border-radius: 0.625rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 2rem;
  grid-area: header; }
  .rpm-client-profile-right-header .wheel-container {
    display: flex;
    grid-gap: 1.5rem;
    gap: 1.5rem; }
  .rpm-client-profile-right-header__left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    grid-gap: 0.5rem;
    gap: 0.5rem; }
    .rpm-client-profile-right-header__left__status {
      display: flex;
      flex-direction: row;
      align-items: center;
      grid-gap: 0.5rem;
      gap: 0.5rem;
      border-radius: 7.8125rem;
      padding: 0.5rem;
      border: 1px solid #718371;
      margin-bottom: 0.5rem; }
      .rpm-client-profile-right-header__left__status .dot {
        height: 0.3125rem;
        width: 0.3125rem;
        aspect-ratio: 1/1;
        border-radius: 50%;
        background-color: #718371; }
      .rpm-client-profile-right-header__left__status .text {
        font-size: 0.625rem;
        color: #718371;
        font-weight: 500; }
    .rpm-client-profile-right-header__left .eligible {
      border: 1px solid #718371; }
      .rpm-client-profile-right-header__left .eligible .dot {
        background-color: #718371; }
      .rpm-client-profile-right-header__left .eligible .text {
        color: #718371; }
    .rpm-client-profile-right-header__left .ineligible {
      border: 1px solid #e8744f; }
      .rpm-client-profile-right-header__left .ineligible .dot {
        background-color: #e8744f; }
      .rpm-client-profile-right-header__left .ineligible .text {
        color: #e8744f; }
    .rpm-client-profile-right-header__left h3 {
      font-size: 1rem;
      color: var(--pure-white);
      font-weight: 600; }
    .rpm-client-profile-right-header__left__text {
      font-size: 0.75rem;
      font-weight: 600;
      color: #a4a2a1; }
  .rpm-client-profile-right-header__right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    grid-gap: 0.5rem;
    gap: 0.5rem; }
    .rpm-client-profile-right-header__right__title {
      font-size: 0.625rem;
      font-weight: 600;
      color: #a4a2a1; }
    .rpm-client-profile-right-header__right__text {
      font-size: 0.75rem;
      font-weight: 400;
      color: var(--pure-white);
      text-align: center; }

@media (max-width: 767.98px) {
  .rpm-client-profile-right-header {
    flex-direction: column;
    align-items: center;
    height: auto;
    min-height: auto;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    max-width: 100%;
    grid-gap: 2rem;
    gap: 2rem; }
    .rpm-client-profile-right-header .wheel-container {
      justify-content: space-between;
      width: 100%; }
    .rpm-client-profile-right-header__right {
      flex-direction: row;
      grid-gap: 2rem;
      gap: 2rem;
      align-items: center; }
      .rpm-client-profile-right-header__right__text {
        font-size: 0.635rem; } }

.rpm-client-profile__container__right-body {
  background-color: var(--primary-white);
  width: 100%;
  height: 100%;
  border-radius: 0.625rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  max-height: 62.675rem;
  overflow-y: auto; }
  @media (max-width: 767.98px) {
    .rpm-client-profile__container__right-body {
      margin-bottom: 5rem;
      width: calc(100vw - 4rem);
      height: 44.375rem;
      max-height: 44.375rem; } }
  .rpm-client-profile__container__right-body__measurements {
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    width: 100%;
    margin-top: 1rem;
    flex-wrap: wrap; }
    .rpm-client-profile__container__right-body__measurements__measurement {
      cursor: pointer;
      padding: 0.5rem 1.3rem;
      border-radius: 7.8125rem; }
      .rpm-client-profile__container__right-body__measurements__measurement p {
        font-size: 0.75rem; }
    @media (max-width: 767.98px) {
      .rpm-client-profile__container__right-body__measurements__measurement {
        padding: 0.5rem 0.725rem; } }

.rpm-client-profile__container__left .rpm-profile-info-container__info__roadmaps {
  display: flex;
  flex-direction: column;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  width: 100%; }
  .rpm-client-profile__container__left .rpm-profile-info-container__info__roadmaps__roadmap-wrapper {
    position: relative;
    width: 100%;
    height: 6.3125rem; }
    .rpm-client-profile__container__left .rpm-profile-info-container__info__roadmaps__roadmap-wrapper__three-dots {
      position: absolute;
      top: 0.5rem;
      right: 0.5rem;
      z-index: 2;
      -webkit-transform: rotate(90deg);
              transform: rotate(90deg);
      height: 1.5rem;
      width: 1.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      outline: none; }
    .rpm-client-profile__container__left .rpm-profile-info-container__info__roadmaps__roadmap-wrapper__three-dots-dropdown {
      position: absolute;
      top: 0.5rem;
      right: 0;
      z-index: 2;
      min-height: 2.8125rem;
      width: 9.3125rem;
      display: flex;
      align-items: center;
      justify-content: center;
      outline: none;
      background-color: var(--pure-white);
      box-shadow: 1px 1px 20px 1px rgba(0, 0, 0, 0.05);
      border-radius: 0.25rem;
      flex-direction: column;
      grid-gap: 0.5rem;
      gap: 0.5rem; }
      .rpm-client-profile__container__left .rpm-profile-info-container__info__roadmaps__roadmap-wrapper__three-dots-dropdown__button {
        display: flex;
        align-items: center;
        justify-content: center;
        grid-gap: 0.5rem;
        gap: 0.5rem;
        font-size: 0.75rem;
        font-weight: 600;
        color: var(--primary-dark);
        cursor: pointer;
        outline: none;
        height: 2.8125rem;
        width: 100%; }
    .rpm-client-profile__container__left .rpm-profile-info-container__info__roadmaps__roadmap-wrapper__roadmap {
      height: 6.3125rem;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      width: 100%;
      border-radius: 0.625rem;
      padding: 1rem;
      background-color: var(--pure-white);
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
      position: absolute;
      z-index: 1; }
      .rpm-client-profile__container__left .rpm-profile-info-container__info__roadmaps__roadmap-wrapper__roadmap:hover {
        -webkit-transform: scale(1.01);
                transform: scale(1.01);
        transition: all 0.2s ease-in-out; }
      .rpm-client-profile__container__left .rpm-profile-info-container__info__roadmaps__roadmap-wrapper__roadmap p {
        text-align: left; }
      .rpm-client-profile__container__left .rpm-profile-info-container__info__roadmaps__roadmap-wrapper__roadmap__header {
        display: flex;
        align-items: flex-start;
        grid-gap: 0.75rem;
        gap: 0.75rem;
        width: 100%; }
        .rpm-client-profile__container__left .rpm-profile-info-container__info__roadmaps__roadmap-wrapper__roadmap__header__title {
          font-weight: 600;
          color: var(--primary-dark);
          font-size: 0.875rem;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 75%;
          text-align: left; }
        .rpm-client-profile__container__left .rpm-profile-info-container__info__roadmaps__roadmap-wrapper__roadmap__header__image {
          height: 2rem;
          width: 2rem;
          aspect-ratio: 1/1;
          border-radius: 0.5rem; }
      .rpm-client-profile__container__left .rpm-profile-info-container__info__roadmaps__roadmap-wrapper__roadmap__progress {
        display: flex;
        flex-direction: column;
        grid-gap: 0.5rem;
        gap: 0.5rem;
        margin-top: 0.5rem;
        width: 100%; }
        .rpm-client-profile__container__left .rpm-profile-info-container__info__roadmaps__roadmap-wrapper__roadmap__progress__bar {
          height: 0.375rem;
          background-color: #e5e5e5;
          border-radius: 0.3125rem;
          position: relative; }
          .rpm-client-profile__container__left .rpm-profile-info-container__info__roadmaps__roadmap-wrapper__roadmap__progress__bar__fill {
            height: 100%;
            background-color: #e8744f;
            border-radius: 0.3125rem;
            position: absolute;
            top: 0;
            left: 0;
            width: 50%; }
        .rpm-client-profile__container__left .rpm-profile-info-container__info__roadmaps__roadmap-wrapper__roadmap__progress__text {
          font-size: 0.75rem;
          font-weight: 600;
          color: #adadad; }

.rpm-profile-info-container__info__roadmaps__modal .modal-content {
  padding: 3rem; }

.rpm-profile-info-container__info__roadmaps__modal .modal-title {
  font-family: var(--serif-font-family);
  font-size: 1.5rem;
  font-weight: 700; }

.rpm-profile-info-container__info__roadmaps__modal .modal-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%; }
  .rpm-profile-info-container__info__roadmaps__modal .modal-body p {
    text-align: center;
    margin-bottom: 1rem; }
  .rpm-profile-info-container__info__roadmaps__modal .modal-body .remove {
    height: 3.125rem;
    background-color: var(--primary-dark);
    color: var(--pure-white);
    border-radius: 0.3125rem;
    font-size: 1rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    outline: none;
    width: 100%;
    margin-bottom: 1rem; }
  .rpm-profile-info-container__info__roadmaps__modal .modal-body .go-back {
    text-decoration: underline;
    color: var(--primary-dark);
    font-size: 1rem;
    cursor: pointer;
    outline: none; }

.rpm-client-profile__container__right-body__records {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-top: 1rem;
  grid-gap: 1rem;
  gap: 1rem;
  overflow-y: auto; }
  .rpm-client-profile__container__right-body__records__record {
    display: flex;
    flex-direction: column;
    grid-gap: 0.875rem;
    gap: 0.875rem;
    width: 100%; }
    .rpm-client-profile__container__right-body__records__record__header {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%; }
      .rpm-client-profile__container__right-body__records__record__header__date {
        font-size: 0.75rem;
        font-weight: 600;
        color: #515151; }
    .rpm-client-profile__container__right-body__records__record__measurements {
      width: 100%;
      display: flex;
      flex-direction: column;
      grid-gap: 0.5rem;
      gap: 0.5rem; }
  .rpm-client-profile__container__right-body__records__end {
    color: #515151;
    opacity: 0.5;
    font-weight: 600;
    border-top: 1px solid rgba(160, 158, 156, 0.5);
    font-size: 0.75rem;
    font-weight: 600;
    padding-top: 1.25rem;
    width: 100%;
    text-align: center; }

.container-skeleton, .billing-card-skeleton__title, .billing-card-skeleton__description-1, .billing-card-skeleton__description-2, .billing-card-skeleton__divider, .billing-card-skeleton__footer-1, .billing-card-skeleton__footer-2, .billing-card-skeleton__footer-3, .billing-card-skeleton__footer-4, .activity-card-skeleton__profile, .activity-card-skeleton__name, .activity-card-skeleton__description, .activity-card-skeleton__score, .skeleton-user-card__avatar, .skeleton-user-card__user-info, .skeleton-user-card__alerts-circle, .skeleton-user-card__line, .skeleton-user-card__info__top, .skeleton-user-card__info__bottom {
  -webkit-animation: container-skeleton-loading 1s linear infinite alternate;
          animation: container-skeleton-loading 1s linear infinite alternate; }

@keyframes container-skeleton-loading {
  0% {
    background-color: var(--primary-white); }
  100% {
    background-color: var(--primary-white); } }

.activity-card-skeleton {
  height: 3.375rem;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  background-color: var(--pure-white);
  position: relative; }
  .activity-card-skeleton__profile {
    height: 1.6875rem;
    width: 1.6875rem;
    border-radius: 50%;
    position: absolute;
    top: 0.875rem;
    left: 0.625rem; }
  .activity-card-skeleton__name {
    height: 0.625rem;
    width: 9.25rem;
    top: 1rem;
    left: 2.8125rem;
    position: absolute;
    border-radius: 0.25rem; }
  .activity-card-skeleton__description {
    height: 0.625rem;
    width: 5.875rem;
    top: 1.875rem;
    left: 2.8125rem;
    position: absolute;
    border-radius: 0.25rem; }
  .activity-card-skeleton__score {
    position: absolute;
    height: 2rem;
    width: 2.875rem;
    top: 0.75rem;
    right: 1rem;
    border-radius: 0.25rem; }

.rpm-client-profile__container__right-body__records__record__measurements .measurement-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: var(--pure-white);
  min-height: 3.375rem;
  border-radius: 0.625rem; }
  .rpm-client-profile__container__right-body__records__record__measurements .measurement-wrapper__mood-text {
    height: auto;
    display: flex;
    flex-direction: column;
    width: calc(100% - 92px);
    padding-top: 13px;
    padding-bottom: 20px;
    border-top: 1px solid #d9d9d9;
    margin: auto;
    margin-top: 15px;
    grid-gap: 10px;
    gap: 10px; }
    .rpm-client-profile__container__right-body__records__record__measurements .measurement-wrapper__mood-text .hour {
      color: #a4a2a1;
      font-size: 0.625rem;
      font-weight: 400; }
    .rpm-client-profile__container__right-body__records__record__measurements .measurement-wrapper__mood-text .description {
      font-weight: 400;
      font-size: 0.75rem;
      color: #6b6b6b; }
    .rpm-client-profile__container__right-body__records__record__measurements .measurement-wrapper__mood-text strong {
      color: #e8744f;
      font-weight: 700; }

.rpm-client-profile__container__right-body__records__record__measurements__measurement {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  min-height: 3.375rem; }
  .rpm-client-profile__container__right-body__records__record__measurements__measurement__left {
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-gap: 0.75rem;
    gap: 0.75rem; }
    .rpm-client-profile__container__right-body__records__record__measurements__measurement__left__text {
      font-size: 0.75rem;
      font-weight: 400;
      color: #595959; }
      .rpm-client-profile__container__right-body__records__record__measurements__measurement__left__text__title {
        font-size: 0.875rem;
        font-weight: 400;
        color: #595959;
        white-space: nowrap; }
    .rpm-client-profile__container__right-body__records__record__measurements__measurement__left__subtitle {
      font-size: 0.625rem;
      font-weight: 400;
      color: #595959; }
  .rpm-client-profile__container__right-body__records__record__measurements__measurement__right {
    height: 2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #ebece1;
    padding: 0 0.75rem;
    border-radius: 0.1875rem; }
    .rpm-client-profile__container__right-body__records__record__measurements__measurement__right__value {
      color: #a5a877;
      font-size: 0.875rem;
      font-weight: 600; }
    .rpm-client-profile__container__right-body__records__record__measurements__measurement__right__three-dots-button__tooltip {
      width: 9.3125rem;
      height: 5.25rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0 0.5rem;
      position: absolute;
      right: 0;
      top: 0;
      background-color: var(--pure-white);
      border-radius: 0.25rem;
      box-shadow: 1px 1px 20px 1px rgba(0, 0, 0, 0.05); }
      .rpm-client-profile__container__right-body__records__record__measurements__measurement__right__three-dots-button__tooltip__item {
        display: flex;
        width: 100%;
        height: 2.625rem;
        align-items: center; }
        .rpm-client-profile__container__right-body__records__record__measurements__measurement__right__three-dots-button__tooltip__item__text {
          font-size: 0.75rem;
          font-weight: 600;
          color: var(--primary-dark);
          margin-left: 0.5rem;
          width: 100%;
          text-align: left; }

.rpm-client-profile .tooltip-wrapper {
  position: relative;
  padding: 0.5rem;
  outline: none; }
  .rpm-client-profile .tooltip-wrapper__tooltip {
    position: absolute;
    top: 0;
    left: 2rem;
    width: 18.5rem;
    min-width: 18.5rem;
    padding: 3rem 2.5rem;
    background: var(--pure-white);
    box-shadow: 0px 8px 13px rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem;
    display: flex;
    flex-direction: column;
    z-index: 2; }
    .rpm-client-profile .tooltip-wrapper__tooltip__border {
      width: 100%;
      height: 1px;
      background-color: #d3d0cc; }
    .rpm-client-profile .tooltip-wrapper__tooltip h3 {
      font-size: 1rem;
      font-weight: 600;
      color: var(--primary-dark);
      margin-bottom: 1.5rem;
      text-align: left; }
    .rpm-client-profile .tooltip-wrapper__tooltip__triangle {
      position: absolute;
      top: 0.75rem;
      left: -0.5rem;
      width: 0;
      height: 0;
      border-top: 0.5rem solid transparent;
      border-bottom: 0.5rem solid transparent;
      border-right: 0.5rem solid var(--pure-white); }
    .rpm-client-profile .tooltip-wrapper__tooltip__content {
      display: flex;
      flex-direction: column;
      width: 100%;
      grid-gap: 0.5rem;
      gap: 0.5rem;
      margin-top: 1.2rem; }
      .rpm-client-profile .tooltip-wrapper__tooltip__content__item {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center; }
        .rpm-client-profile .tooltip-wrapper__tooltip__content__item__tag {
          text-align: left;
          font-weight: 400;
          font-size: 0.875rem;
          color: #595959;
          max-width: 10rem;
          white-space: normal;
          word-wrap: break-word; }
        .rpm-client-profile .tooltip-wrapper__tooltip__content__item__score {
          height: 2rem;
          width: 5rem;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          border-radius: 0.25rem; }
          .rpm-client-profile .tooltip-wrapper__tooltip__content__item__score__value {
            font-size: 0.75rem;
            font-weight: 600; }

.data-prompt-modal h3 {
  font-family: var(--serif-font-family);
  font-weight: 700;
  font-size: 1.25rem;
  text-align: center;
  color: var(--primary-dark); }

.data-prompt-modal__close {
  position: absolute;
  top: -3rem;
  right: -0.5rem;
  cursor: pointer; }

.data-prompt-modal .modal-dialog {
  max-width: 100%;
  margin: auto;
  margin-top: 5rem;
  background: #f8f5f5;
  width: 90vw;
  border-radius: 0.5rem;
  padding: 1.5rem;
  padding-bottom: 2.1875rem;
  padding-top: 4.0625rem;
  border-radius: 1.25rem;
  position: relative; }
  .data-prompt-modal .modal-dialog .modal-content {
    background: #f8f5f5;
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 1.8rem;
    gap: 1.8rem; }

.data-prompt-modal__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
  width: 100%;
  background-color: var(--pure-white);
  border-radius: 0.5rem;
  padding: 0.75rem 1.5rem; }
  .data-prompt-modal__content__item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%; }
    .data-prompt-modal__content__item__tag {
      font-size: 0.875rem;
      font-weight: 400;
      color: #595959;
      max-width: 10rem;
      white-space: normal;
      word-wrap: break-word; }
    .data-prompt-modal__content__item__score {
      width: 6.3125rem;
      height: 2.5rem;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      border-radius: 0.5rem; }
      .data-prompt-modal__content__item__score___value {
        font-size: 0.875rem;
        font-weight: 600; }

.delete-assessment-modal .modal-content {
  display: flex;
  flex-direction: column;
  grid-gap: 0.875rem;
  gap: 0.875rem;
  margin-top: calc(50vh - 6.5rem);
  min-height: 14.375rem;
  width: 30.56rem;
  align-items: center;
  justify-content: center;
  padding: 3rem;
  position: relative; }
  @media (max-width: 767.98px) {
    .delete-assessment-modal .modal-content {
      width: 90vw;
      margin: auto;
      margin-top: 5rem; } }
  .delete-assessment-modal .modal-content h3 {
    font-size: 1rem;
    font-weight: 600;
    color: var(--primary-dark);
    font-family: var(--sans-serif-font-family); }
  .delete-assessment-modal .modal-content p {
    font-size: 0.875rem;
    font-weight: 300;
    color: var(--primary-dark);
    text-align: center; }
  .delete-assessment-modal .modal-content p.red-text {
    font-size: 0.875rem;
    font-weight: 500;
    color: #e8744f;
    text-align: center; }

.delete-assessment-modal__warning {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #f9dad0;
  width: 100%;
  padding: 1rem;
  border-left: 4px solid #e8744f; }

.delete-assessment-modal__buttons {
  display: flex;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem; }
  @media (max-width: 767.98px) {
    .delete-assessment-modal__buttons {
      width: 100%; } }
  .delete-assessment-modal__buttons__button {
    width: 10.93rem;
    height: 3.125rem;
    border: 1px solid var(--primary-dark);
    font-weight: 500;
    color: var(--primary-dark);
    border-radius: 0.625rem; }
    @media (max-width: 767.98px) {
      .delete-assessment-modal__buttons__button {
        width: 100%; } }

.delete-assessment-modal__close {
  position: absolute;
  right: 1rem;
  top: 1rem;
  height: 1.5rem;
  width: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  outline: none; }

.success-addition-modal .modal-content {
  background-color: var(--pure-white);
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding: 3rem 5rem;
  margin-top: 10vh; }

.success-addition-modal__title {
  font-weight: 600;
  font-size: 1rem;
  color: var(--primary-dark);
  margin-top: 1.5rem; }

.success-addition-modal__description {
  font-weight: 300;
  font-size: 0.875rem;
  color: var(--primary-dark);
  margin-top: 0.875rem; }

.success-addition-modal__close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
  outline: none; }

.rpm-profile-info-container__info__calls {
  display: flex;
  flex-direction: column;
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-gap: 0.5rem;
  gap: 0.5rem; }
  .rpm-profile-info-container__info__calls__load-more {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2rem;
    color: var(--pure-white);
    background-color: var(--primary-dark);
    font-size: 0.75rem;
    font-weight: 400;
    border-radius: 0.25rem;
    cursor: pointer;
    padding: 0 1rem;
    width: -webkit-max-content;
    width: max-content;
    margin: 0 auto; }
  .rpm-profile-info-container__info__calls__item {
    width: 100%;
    margin: 0 auto;
    background-color: var(--primary-dark);
    box-shadow: 0px 4px 20px 1px rgba(0, 0, 0, 0.1);
    border-radius: 0.625rem;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    position: relative; }
    .rpm-profile-info-container__info__calls__item__profile-container {
      display: flex;
      align-items: center;
      grid-gap: 0.5rem;
      gap: 0.5rem;
      cursor: pointer; }
      .rpm-profile-info-container__info__calls__item__profile-container__picture {
        height: 2.5rem;
        width: 2.5rem;
        border-radius: 0.25rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        aspect-ratio: 1/1; }
        .rpm-profile-info-container__info__calls__item__profile-container__picture__header {
          height: 1rem;
          background-color: #4b4a48;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          color: var(--pure-white);
          font-weight: 700;
          font-size: 0.5rem;
          border-radius: 0.25rem 0.25rem 0 0; }
        .rpm-profile-info-container__info__calls__item__profile-container__picture__body {
          height: 100%;
          width: 100%;
          background-color: var(--pure-white);
          display: flex;
          align-items: center;
          justify-content: center;
          height: 1.5rem;
          border-radius: 0 0 0.25rem 0.25rem; }
          .rpm-profile-info-container__info__calls__item__profile-container__picture__body__text {
            font-family: var(--serif-font-family);
            font-size: 1rem;
            font-weight: 900; }
      .rpm-profile-info-container__info__calls__item__profile-container__name {
        display: flex;
        flex-direction: column;
        align-items: flex-start; }
        .rpm-profile-info-container__info__calls__item__profile-container__name__text {
          font-weight: 400;
          font-size: 0.875rem;
          color: var(--pure-white); }
        .rpm-profile-info-container__info__calls__item__profile-container__name__description {
          font-weight: 400;
          font-size: 0.625rem;
          color: #a4a2a1;
          text-align: left; }
    .rpm-profile-info-container__info__calls__item__time {
      cursor: pointer;
      height: 2rem;
      display: flex;
      align-items: center;
      width: 100%;
      background: rgba(98, 97, 93, 0.5);
      padding: 0 1rem;
      border-radius: 0.25rem;
      grid-gap: 0.5rem;
      gap: 0.5rem; }
      .rpm-profile-info-container__info__calls__item__time svg {
        margin-right: 0.5rem; }
      .rpm-profile-info-container__info__calls__item__time__text {
        font-size: 0.75rem;
        color: var(--pure-white);
        font-weight: 400; }
        .rpm-profile-info-container__info__calls__item__time__text strong {
          font-weight: 600;
          margin-right: 0.5rem; }
    .rpm-profile-info-container__info__calls__item__footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%; }
      .rpm-profile-info-container__info__calls__item__footer__right {
        display: flex;
        align-items: center;
        grid-gap: 0.5rem;
        gap: 0.5rem; }
        .rpm-profile-info-container__info__calls__item__footer__right__text {
          font-size: 0.75rem;
          color: var(--pure-white);
          font-weight: 500; }
    .rpm-profile-info-container__info__calls__item__three-dots {
      position: absolute;
      top: 1.25rem;
      right: 1rem;
      cursor: pointer;
      width: 1.5rem;
      height: 1.5rem;
      border: none;
      background-color: transparent;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      outline: none; }
    .rpm-profile-info-container__info__calls__item__dropdown {
      height: 5.5625rem;
      width: 9.3125rem;
      background-color: var(--pure-white);
      border-radius: 0.25rem;
      position: absolute;
      top: 1.25rem;
      right: 1rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      z-index: 2;
      box-shadow: 1px 1px 20px 1px rgba(0, 0, 0, 0.05);
      padding: 0rem 0.5rem; }
      .rpm-profile-info-container__info__calls__item__dropdown__item {
        height: 2.75rem;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        cursor: pointer;
        font-size: 0.75rem;
        font-weight: 600;
        padding-left: 0.5rem;
        color: var(--primary-dark); }

.rpm-client-profile__container__right-body__schedule-section {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  margin-top: 1.5rem;
  grid-gap: 1.5rem;
  gap: 1.5rem; }
  .rpm-client-profile__container__right-body__schedule-section h3 {
    font-size: 0.875rem;
    font-weight: 400; }
  .rpm-client-profile__container__right-body__schedule-section hr {
    width: 100%;
    height: 1px;
    background-color: #a09e9c;
    opacity: 0.5; }
  .rpm-client-profile__container__right-body__schedule-section__upcoming-calls {
    display: flex;
    flex-direction: column;
    width: 100%;
    grid-gap: 1rem;
    gap: 1rem; }
    .rpm-client-profile__container__right-body__schedule-section__upcoming-calls__no-upcoming-calls {
      color: #515151;
      opacity: 0.5;
      font-weight: 600;
      border-bottom: 1px solid rgba(160, 158, 156, 0.5);
      font-size: 0.75rem;
      font-weight: 600;
      padding-bottom: 1.25rem;
      width: 100%;
      text-align: center; }
    .rpm-client-profile__container__right-body__schedule-section__upcoming-calls__upcoming-call {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: 1fr;
      width: 100%;
      min-height: 4.1875rem;
      background-color: var(--pure-white);
      box-shadow: 0px 4px 20px 1px rgba(0, 0, 0, 0.1);
      border-radius: 0.625rem;
      padding: 1rem;
      position: relative;
      align-items: center; }
      .rpm-client-profile__container__right-body__schedule-section__upcoming-calls__upcoming-call__picture {
        height: 2.5rem;
        width: 2.5rem;
        border-radius: 0.25rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        aspect-ratio: 1/1; }
        .rpm-client-profile__container__right-body__schedule-section__upcoming-calls__upcoming-call__picture__header {
          height: 1rem;
          background-color: #4b4a48;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          color: var(--pure-white);
          font-weight: 700;
          font-size: 0.5rem;
          border-radius: 0.25rem 0.25rem 0 0; }
        .rpm-client-profile__container__right-body__schedule-section__upcoming-calls__upcoming-call__picture__body {
          height: 100%;
          width: 100%;
          background-color: var(--pure-white);
          display: flex;
          align-items: center;
          justify-content: center;
          height: 1.5rem;
          border-radius: 0 0 0.25rem 0.25rem; }
          .rpm-client-profile__container__right-body__schedule-section__upcoming-calls__upcoming-call__picture__body__text {
            font-family: var(--serif-font-family);
            font-size: 1rem;
            font-weight: 900; }
      .rpm-client-profile__container__right-body__schedule-section__upcoming-calls__upcoming-call__name {
        display: flex;
        flex-direction: column;
        align-items: flex-start; }
        .rpm-client-profile__container__right-body__schedule-section__upcoming-calls__upcoming-call__name__text {
          font-weight: 400;
          font-size: 0.875rem;
          color: var(--primary-dark); }
        .rpm-client-profile__container__right-body__schedule-section__upcoming-calls__upcoming-call__name__description {
          font-weight: 400;
          font-size: 0.75rem;
          color: #a4a2a1; }
      .rpm-client-profile__container__right-body__schedule-section__upcoming-calls__upcoming-call__time {
        display: flex;
        align-items: flex-start;
        grid-gap: 0.5rem;
        gap: 0.5rem;
        padding-left: 30%; }
        .rpm-client-profile__container__right-body__schedule-section__upcoming-calls__upcoming-call__time__text {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          margin-top: -0.25rem; }
          .rpm-client-profile__container__right-body__schedule-section__upcoming-calls__upcoming-call__time__text__day {
            font-weight: 600;
            font-size: 0.75rem;
            color: var(--primary-dark); }
          .rpm-client-profile__container__right-body__schedule-section__upcoming-calls__upcoming-call__time__text__hour {
            font-weight: 400;
            font-size: 0.75rem;
            color: var(--primary-dark); }
      .rpm-client-profile__container__right-body__schedule-section__upcoming-calls__upcoming-call__buttons {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        grid-gap: 1rem;
        gap: 1rem; }
        .rpm-client-profile__container__right-body__schedule-section__upcoming-calls__upcoming-call__buttons__join-now {
          font-size: 0.75rem;
          font-weight: 500;
          cursor: pointer;
          display: flex;
          align-items: center;
          opacity: 1; }
          .rpm-client-profile__container__right-body__schedule-section__upcoming-calls__upcoming-call__buttons__join-now svg {
            margin-left: 0.25rem; }
          .rpm-client-profile__container__right-body__schedule-section__upcoming-calls__upcoming-call__buttons__join-now:hover {
            opacity: 1; }
        .rpm-client-profile__container__right-body__schedule-section__upcoming-calls__upcoming-call__buttons button {
          outline: none; }
        .rpm-client-profile__container__right-body__schedule-section__upcoming-calls__upcoming-call__buttons__edit {
          padding-right: 0.5rem;
          height: 1rem;
          display: flex;
          align-items: center;
          justify-content: center; }
      .rpm-client-profile__container__right-body__schedule-section__upcoming-calls__upcoming-call__dropdown {
        height: 5.5625rem;
        width: 9.3125rem;
        background-color: var(--pure-white);
        border-radius: 0.25rem;
        position: absolute;
        top: 1.25rem;
        right: 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        z-index: 2;
        box-shadow: 1px 1px 20px 1px rgba(0, 0, 0, 0.05);
        padding: 0rem 0.5rem; }
        .rpm-client-profile__container__right-body__schedule-section__upcoming-calls__upcoming-call__dropdown__item {
          height: 2.75rem;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          cursor: pointer;
          font-size: 0.75rem;
          font-weight: 600;
          padding-left: 0.5rem;
          color: var(--primary-dark); }
    .rpm-client-profile__container__right-body__schedule-section__upcoming-calls__load-more {
      background-color: var(--primary-dark);
      color: var(--pure-white);
      height: 2rem;
      width: 9.56rem;
      border-radius: 0.3rem;
      margin: 0 auto;
      font-size: 0.75rem; }

h1 {
  text-align: left; }

.rpm-coach-dashboard .rpm-coach-container-layout {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-areas: 'container container calls';
  width: 100%;
  max-width: 1140px;
  margin: 0 auto;
  grid-gap: 1rem;
  gap: 1rem;
  margin-top: 1rem; }
  .rpm-coach-dashboard .rpm-coach-container-layout .rpm-coach-container {
    margin: 0 auto;
    margin-bottom: 1rem;
    min-height: calc(100vh - 7rem);
    height: -webkit-min-content;
    height: min-content;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    background-color: var(--primary-white);
    border-radius: 0.625rem;
    padding: 2rem;
    grid-area: container; }
  @media (max-width: 991.98px) {
    .rpm-coach-dashboard .rpm-coach-container-layout {
      grid-template-columns: 1fr;
      grid-template-areas: 'calls' 'container';
      margin-top: 0;
      grid-gap: 0;
      gap: 0; }
      .rpm-coach-dashboard .rpm-coach-container-layout .rpm-coach-container {
        background-color: var(--primary-bg-white); } }

.rpm-coach-dashboard .rpm-coach-container .search-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2rem 0;
  padding-top: 1rem;
  min-height: 2rem; }
  @media (max-width: 991.98px) {
    .rpm-coach-dashboard .rpm-coach-container .search-container {
      flex-direction: column;
      justify-content: flex-start;
      width: 100%;
      grid-gap: 1rem;
      gap: 1rem; } }
  .rpm-coach-dashboard .rpm-coach-container .search-container .left-search-container {
    display: flex;
    align-items: center;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    justify-content: flex-start; }
    @media (max-width: 991.98px) {
      .rpm-coach-dashboard .rpm-coach-container .search-container .left-search-container {
        width: 100%; } }
    .rpm-coach-dashboard .rpm-coach-container .search-container .left-search-container .search-container-title {
      font-size: 1rem;
      font-weight: 500;
      color: #515151;
      width: 100%;
      flex-wrap: nowrap;
      white-space: nowrap; }
    .rpm-coach-dashboard .rpm-coach-container .search-container .left-search-container .search-container-number {
      min-width: 2.8185rem;
      height: 1.625rem;
      background-color: #d5d1cd;
      border-radius: 1.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 0.5rem; }
      .rpm-coach-dashboard .rpm-coach-container .search-container .left-search-container .search-container-number .search-container-number-text {
        font-size: 0.8rem;
        font-weight: 800;
        color: #515151; }
  .rpm-coach-dashboard .rpm-coach-container .search-container .right-search-container {
    display: flex;
    align-items: center;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    justify-content: flex-end;
    height: 2rem; }
    .rpm-coach-dashboard .rpm-coach-container .search-container .right-search-container .search-container-input {
      position: relative;
      width: 14.375rem;
      height: 2rem; }
      @media (max-width: 991.98px) {
        .rpm-coach-dashboard .rpm-coach-container .search-container .right-search-container .search-container-input {
          width: 100%; } }
      .rpm-coach-dashboard .rpm-coach-container .search-container .right-search-container .search-container-input svg {
        position: absolute;
        top: 50%;
        left: 0.5rem;
        -webkit-transform: translateY(-50%);
                transform: translateY(-50%); }
      .rpm-coach-dashboard .rpm-coach-container .search-container .right-search-container .search-container-input input {
        width: 100%;
        min-height: 2rem;
        height: 2rem;
        max-height: 2rem;
        border: 1px solid #d3d0cc;
        border-radius: 0.3125rem;
        padding-left: 1.75rem;
        padding-right: 1rem;
        font-size: 0.75rem;
        font-weight: 500;
        color: rgba(52, 52, 52, 0.5); }
        .rpm-coach-dashboard .rpm-coach-container .search-container .right-search-container .search-container-input input:focus {
          outline: none; }
        .rpm-coach-dashboard .rpm-coach-container .search-container .right-search-container .search-container-input input::-webkit-input-placeholder {
          font-size: 0.75rem; }
        .rpm-coach-dashboard .rpm-coach-container .search-container .right-search-container .search-container-input input::placeholder {
          font-size: 0.75rem; }

.rpm-coach-dashboard .rpm-coach-container .search-container .right-search-container .search-container-wrapper .search-container-filter-sort-container {
  width: 21.5625rem;
  height: 14.25rem;
  background-color: var(--pure-white);
  border: 1px solid #e5e5e5;
  position: absolute;
  top: 120%;
  right: 0;
  z-index: 50;
  border-radius: 0.625rem;
  display: flex;
  flex-direction: column;
  align-items: center; }
  @media (max-width: 991.98px) {
    .rpm-coach-dashboard .rpm-coach-container .search-container .right-search-container .search-container-wrapper .search-container-filter-sort-container {
      width: 14.25rem;
      aspect-ratio: 1/1; } }
  .rpm-coach-dashboard .rpm-coach-container .search-container .right-search-container .search-container-wrapper .search-container-filter-sort-container .search-container-filter-sort-container-item {
    height: 3.5rem;
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    border-bottom: 1px solid #e5e5e5;
    cursor: pointer; }
    .rpm-coach-dashboard .rpm-coach-container .search-container .right-search-container .search-container-wrapper .search-container-filter-sort-container .search-container-filter-sort-container-item p {
      font-size: 0.875rem;
      font-weight: 400;
      -webkit-user-select: none;
              user-select: none; }

.rpm-coach-dashboard .rpm-coach-container .search-container .right-search-container .search-container-wrapper {
  position: relative;
  width: 100%;
  height: 2rem; }
  @media (min-width: 992px) {
    .rpm-coach-dashboard .rpm-coach-container .search-container .right-search-container .search-container-wrapper {
      width: 6.0625rem; } }
  .rpm-coach-dashboard .rpm-coach-container .search-container .right-search-container .search-container-wrapper .search-container-filter {
    width: 6.0625rem;
    height: 2rem;
    border: 1px solid #d3d0cc;
    background-color: var(--pure-white);
    padding: 0 0.5rem;
    border-radius: 0.3125rem;
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    cursor: pointer; }
    .rpm-coach-dashboard .rpm-coach-container .search-container .right-search-container .search-container-wrapper .search-container-filter .search-container-filter-text {
      font-size: 0.75rem;
      font-weight: 500;
      color: var(--primary-dark);
      white-space: nowrap;
      overflow: hidden; }
  .rpm-coach-dashboard .rpm-coach-container .search-container .right-search-container .search-container-wrapper .search-container-filter-container {
    width: 27.3125rem;
    background-color: var(--pure-white);
    border: 1px solid #d3d0cc;
    position: absolute;
    top: 120%;
    right: 0;
    z-index: 50;
    border-radius: 0.625rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between; }
    @media (max-width: 767.98px) {
      .rpm-coach-dashboard .rpm-coach-container .search-container .right-search-container .search-container-wrapper .search-container-filter-container {
        width: 17.3125rem; } }
    .rpm-coach-dashboard .rpm-coach-container .search-container .right-search-container .search-container-wrapper .search-container-filter-container .search-container-filter-container-header {
      min-height: 3.4375rem;
      width: 90%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      border-bottom: 1px solid rgba(229, 229, 229, 0.5); }
      .rpm-coach-dashboard .rpm-coach-container .search-container .right-search-container .search-container-wrapper .search-container-filter-container .search-container-filter-container-header p {
        font-size: 0.75rem;
        font-weight: 600; }
    .rpm-coach-dashboard .rpm-coach-container .search-container .right-search-container .search-container-wrapper .search-container-filter-container .search-container-filter-container-body {
      max-height: 18.0625rem;
      overflow-y: auto;
      width: 90%;
      display: flex;
      flex-direction: column;
      margin: 0 auto; }
      .rpm-coach-dashboard .rpm-coach-container .search-container .right-search-container .search-container-wrapper .search-container-filter-container .search-container-filter-container-body .search-container-filter-container-body-item {
        height: 3.0625rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        border-bottom: 1px solid rgba(229, 229, 229, 0.5);
        overflow: hidden;
        outline: none; }
        .rpm-coach-dashboard .rpm-coach-container .search-container .right-search-container .search-container-wrapper .search-container-filter-container .search-container-filter-container-body .search-container-filter-container-body-item p {
          font-size: 0.875rem;
          font-weight: 400; }
          .rpm-coach-dashboard .rpm-coach-container .search-container .right-search-container .search-container-wrapper .search-container-filter-container .search-container-filter-container-body .search-container-filter-container-body-item p strong {
            font-weight: 600; }
        .rpm-coach-dashboard .rpm-coach-container .search-container .right-search-container .search-container-wrapper .search-container-filter-container .search-container-filter-container-body .search-container-filter-container-body-item svg {
          margin-right: 0.2rem; }
      .rpm-coach-dashboard .rpm-coach-container .search-container .right-search-container .search-container-wrapper .search-container-filter-container .search-container-filter-container-body .search-container-filter-container-body-item-options {
        display: flex;
        flex-direction: column;
        width: 100%; }
        .rpm-coach-dashboard .rpm-coach-container .search-container .right-search-container .search-container-wrapper .search-container-filter-container .search-container-filter-container-body .search-container-filter-container-body-item-options .search-container-filter-container-body-item-options-item {
          height: 3.0625rem;
          border-bottom: 1px solid rgba(229, 229, 229, 0.5);
          overflow: hidden;
          outline: none;
          display: flex;
          align-items: center;
          grid-gap: 0.5rem;
          gap: 0.5rem; }
          .rpm-coach-dashboard .rpm-coach-container .search-container .right-search-container .search-container-wrapper .search-container-filter-container .search-container-filter-container-body .search-container-filter-container-body-item-options .search-container-filter-container-body-item-options-item p {
            font-size: 0.875rem;
            font-weight: 400;
            -webkit-user-select: none;
                    user-select: none; }
          .rpm-coach-dashboard .rpm-coach-container .search-container .right-search-container .search-container-wrapper .search-container-filter-container .search-container-filter-container-body .search-container-filter-container-body-item-options .search-container-filter-container-body-item-options-item button {
            outline: none; }
    .rpm-coach-dashboard .rpm-coach-container .search-container .right-search-container .search-container-wrapper .search-container-filter-container .search-container-filter-container-footer {
      min-height: 8.0625rem;
      width: 100%;
      display: flex;
      align-items: center;
      grid-gap: 1rem;
      gap: 1rem;
      justify-content: center; }
      @media (max-width: 767.98px) {
        .rpm-coach-dashboard .rpm-coach-container .search-container .right-search-container .search-container-wrapper .search-container-filter-container .search-container-filter-container-footer {
          flex-direction: column; } }
      .rpm-coach-dashboard .rpm-coach-container .search-container .right-search-container .search-container-wrapper .search-container-filter-container .search-container-filter-container-footer .search-container-filter-container-footer-button {
        width: 10.25rem;
        height: 2.5rem;
        border-radius: 0.625rem;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        font-size: 0.875rem; }
      .rpm-coach-dashboard .rpm-coach-container .search-container .right-search-container .search-container-wrapper .search-container-filter-container .search-container-filter-container-footer .apply {
        background-color: var(--primary-dark);
        color: var(--pure-white); }
      .rpm-coach-dashboard .rpm-coach-container .search-container .right-search-container .search-container-wrapper .search-container-filter-container .search-container-filter-container-footer .reset {
        border: 1px solid var(--primary-dark);
        color: var(--primary-dark);
        background-color: transparent; }

.rpm-coach-dashboard .rpm-coach-container-layout .rpm-coach-container .rpm-provider-records-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  grid-gap: 1rem;
  gap: 1rem; }

.rpm-alerts-wrapper {
  display: flex;
  align-items: center;
  flex-direction: row;
  grid-gap: 1.5rem;
  gap: 1.5rem;
  justify-content: flex-end; }
  .rpm-alerts-wrapper button {
    outline: none; }
  .rpm-alerts-wrapper__resend-invitation {
    font-size: 0.75rem;
    font-weight: 400;
    color: #000000;
    text-decoration: underline;
    margin-right: -0.25rem; }
  .rpm-alerts-wrapper .rpm-alerts-wrapper-alert {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative; }
    .rpm-alerts-wrapper .rpm-alerts-wrapper-alert .rpm-alerts-wrapper-alert-icon-container {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer; }
      .rpm-alerts-wrapper .rpm-alerts-wrapper-alert .rpm-alerts-wrapper-alert-icon-container .rpm-alerts-wrapper-alert-icon-square {
        width: 1.25rem;
        height: 1.25rem;
        aspect-ratio: 1/1;
        background-color: #e8744f;
        border-radius: 0.1875rem;
        -webkit-transform: rotate(45deg);
                transform: rotate(45deg); }
      .rpm-alerts-wrapper .rpm-alerts-wrapper-alert .rpm-alerts-wrapper-alert-icon-container .rpm-alerts-wrapper-alert-icon {
        color: var(--pure-white);
        font-size: 0.7rem;
        font-weight: 800;
        z-index: 10;
        position: absolute; }
    .rpm-alerts-wrapper .rpm-alerts-wrapper-alert .rpm-alerts-wrapper-alert-text-container {
      min-width: 5.0625rem;
      height: 1.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #f9dad0;
      border-radius: 0.25rem;
      margin-left: -0.5rem;
      cursor: pointer; }
      .rpm-alerts-wrapper .rpm-alerts-wrapper-alert .rpm-alerts-wrapper-alert-text-container .rpm-alerts-wrapper-alert-text {
        color: #e8744f;
        font-size: 0.7rem;
        font-weight: 600;
        text-align: center; }
    .rpm-alerts-wrapper .rpm-alerts-wrapper-alert .rpm-alerts-wrapper-alert-popup {
      height: 18rem;
      width: 16rem;
      background-color: var(--pure-white);
      display: flex;
      flex-direction: column;
      align-items: center;
      position: absolute;
      top: -9rem;
      left: -17rem;
      border-radius: 0.5rem;
      box-shadow: 0px 8px 13px rgba(0, 0, 0, 0.2);
      z-index: 999; }
      .rpm-alerts-wrapper .rpm-alerts-wrapper-alert .rpm-alerts-wrapper-alert-popup .rpm-alerts-wrapper-alert-popup-header {
        background-color: #e8744f;
        height: 2.5rem;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 0.25rem 0.25rem 0 0;
        padding: 0 1rem; }
        .rpm-alerts-wrapper .rpm-alerts-wrapper-alert .rpm-alerts-wrapper-alert-popup .rpm-alerts-wrapper-alert-popup-header .rpm-alerts-wrapper-alert-popup-header-title-container {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          grid-gap: 0.5rem;
          gap: 0.5rem; }
          .rpm-alerts-wrapper .rpm-alerts-wrapper-alert .rpm-alerts-wrapper-alert-popup .rpm-alerts-wrapper-alert-popup-header .rpm-alerts-wrapper-alert-popup-header-title-container .rpm-alerts-wrapper-alert-popup-header-title {
            font-size: 0.875rem;
            font-weight: 600;
            color: var(--pure-white); }
        .rpm-alerts-wrapper .rpm-alerts-wrapper-alert .rpm-alerts-wrapper-alert-popup .rpm-alerts-wrapper-alert-popup-header .rpm-alerts-wrapper-alert-popup-header-clear {
          font-size: 0.7rem;
          font-weight: 600;
          color: var(--pure-white);
          cursor: pointer;
          text-decoration: underline; }
      .rpm-alerts-wrapper .rpm-alerts-wrapper-alert .rpm-alerts-wrapper-alert-popup .rpm-alerts-wrapper-alert-popup-body {
        height: 15.5rem;
        max-height: 15.5rem;
        width: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 0.5rem; }
        .rpm-alerts-wrapper .rpm-alerts-wrapper-alert .rpm-alerts-wrapper-alert-popup .rpm-alerts-wrapper-alert-popup-body .right-triangle {
          position: absolute;
          top: 6.75rem;
          left: 100%;
          width: 0;
          height: 0;
          border-top: 0.5rem solid transparent;
          border-bottom: 0.5rem solid transparent;
          border-left: 0.5rem solid var(--pure-white); }
        .rpm-alerts-wrapper .rpm-alerts-wrapper-alert .rpm-alerts-wrapper-alert-popup .rpm-alerts-wrapper-alert-popup-body .rpm-alerts-wrapper-alert-popup-body-item {
          min-height: 3rem;
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          padding: 0 1rem;
          border-bottom: 1px solid #f0ece8; }
          .rpm-alerts-wrapper .rpm-alerts-wrapper-alert .rpm-alerts-wrapper-alert-popup .rpm-alerts-wrapper-alert-popup-body .rpm-alerts-wrapper-alert-popup-body-item .rpm-alerts-wrapper-alert-popup-body-item-left {
            display: flex;
            align-items: center;
            grid-gap: 0.5rem;
            gap: 0.5rem;
            justify-content: flex-start; }
            .rpm-alerts-wrapper .rpm-alerts-wrapper-alert .rpm-alerts-wrapper-alert-popup .rpm-alerts-wrapper-alert-popup-body .rpm-alerts-wrapper-alert-popup-body-item .rpm-alerts-wrapper-alert-popup-body-item-left p {
              font-size: 0.875rem;
              font-weight: 400;
              color: var(--primary-dark); }
          .rpm-alerts-wrapper .rpm-alerts-wrapper-alert .rpm-alerts-wrapper-alert-popup .rpm-alerts-wrapper-alert-popup-body .rpm-alerts-wrapper-alert-popup-body-item .rpm-alerts-wrapper-alert-popup-body-item-right {
            display: flex;
            align-items: center;
            grid-gap: 0.5rem;
            gap: 0.5rem;
            justify-content: flex-end; }
            .rpm-alerts-wrapper .rpm-alerts-wrapper-alert .rpm-alerts-wrapper-alert-popup .rpm-alerts-wrapper-alert-popup-body .rpm-alerts-wrapper-alert-popup-body-item .rpm-alerts-wrapper-alert-popup-body-item-right .rpm-alerts-wrapper-alert-popup-body-item-right-text {
              padding: 0.5rem;
              width: 2rem;
              height: 1.5rem;
              display: flex;
              align-items: center;
              justify-content: center;
              background: rgba(232, 116, 79, 0.27);
              border-radius: 0.25rem; }
              .rpm-alerts-wrapper .rpm-alerts-wrapper-alert .rpm-alerts-wrapper-alert-popup .rpm-alerts-wrapper-alert-popup-body .rpm-alerts-wrapper-alert-popup-body-item .rpm-alerts-wrapper-alert-popup-body-item-right .rpm-alerts-wrapper-alert-popup-body-item-right-text p {
                font-size: 0.75rem;
                font-weight: 600;
                color: #e8744f; }
            .rpm-alerts-wrapper .rpm-alerts-wrapper-alert .rpm-alerts-wrapper-alert-popup .rpm-alerts-wrapper-alert-popup-body .rpm-alerts-wrapper-alert-popup-body-item .rpm-alerts-wrapper-alert-popup-body-item-right .rpm-alerts-wrapper-alert-popup-body-item-right-date {
              display: flex;
              flex-direction: column;
              align-items: flex-start; }
              .rpm-alerts-wrapper .rpm-alerts-wrapper-alert .rpm-alerts-wrapper-alert-popup .rpm-alerts-wrapper-alert-popup-body .rpm-alerts-wrapper-alert-popup-body-item .rpm-alerts-wrapper-alert-popup-body-item-right .rpm-alerts-wrapper-alert-popup-body-item-right-date p {
                color: #908e87;
                font-weight: 400;
                font-size: 0.7rem; }
        .rpm-alerts-wrapper .rpm-alerts-wrapper-alert .rpm-alerts-wrapper-alert-popup .rpm-alerts-wrapper-alert-popup-body .rpm-alerts-wrapper-alert-popup-body-item-view-profile {
          text-decoration: underline;
          font-size: 0.75rem;
          cursor: pointer;
          color: var(--primary-dark);
          font-weight: 600;
          margin-top: 1rem; }
  .rpm-alerts-wrapper__no-alerts {
    font-size: 0.625rem;
    font-weight: 600;
    color: rgba(89, 89, 89, 0.5); }

.rpm-provider-record-alerts-modal {
  height: 21.75rem;
  width: 90vw;
  display: flex;
  flex-direction: column;
  background-color: var(--pure-white);
  margin-left: 5vw;
  margin-top: calc(50vh - 10.875rem);
  border-radius: 0.625rem; }
  .rpm-provider-record-alerts-modal .modal-content {
    margin: 0;
    width: 100%; }
  .rpm-provider-record-alerts-modal .modal-dialog {
    margin: 0;
    width: 100%;
    max-width: 100%; }
  .rpm-provider-record-alerts-modal__alert-header {
    height: 2.5rem;
    width: 100%;
    background-color: #e8754f;
    padding: 0 1.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top-left-radius: 0.625rem;
    border-top-right-radius: 0.625rem; }
    .rpm-provider-record-alerts-modal__alert-header__left {
      display: flex;
      align-items: center;
      grid-gap: 1rem;
      gap: 1rem; }
      .rpm-provider-record-alerts-modal__alert-header__left__alert-icon-container {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center; }
        .rpm-provider-record-alerts-modal__alert-header__left__alert-icon-container__square {
          width: 1.11125rem;
          height: 1.11125rem;
          aspect-ratio: 1/1;
          background-color: #f9dad0;
          border-radius: 0.1875rem;
          -webkit-transform: rotate(45deg);
                  transform: rotate(45deg);
          display: flex;
          align-items: center;
          justify-content: center; }
        .rpm-provider-record-alerts-modal__alert-header__left__alert-icon-container__icon {
          color: #e8744f;
          font-size: 0.7rem;
          font-weight: 800;
          z-index: 10;
          position: absolute; }
      .rpm-provider-record-alerts-modal__alert-header__left__alert-text {
        font-size: 0.875rem;
        font-weight: 600;
        color: var(--pure-white); }
    .rpm-provider-record-alerts-modal__alert-header__right {
      font-size: 0.75rem;
      font-weight: 600;
      color: var(--pure-white); }
    .rpm-provider-record-alerts-modal__alert-header__no-alerts {
      color: rgba(89, 89, 89, 0.5);
      font-size: 0.625rem;
      font-weight: 600; }
  .rpm-provider-record-alerts-modal__alert-body {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 0.5rem 1rem;
    width: 100%;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    background-color: var(--pure-white);
    border-bottom-left-radius: 0.625rem;
    border-bottom-right-radius: 0.625rem; }
    .rpm-provider-record-alerts-modal__alert-body__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 0; }
      .rpm-provider-record-alerts-modal__alert-body__header__profile-container {
        display: flex;
        align-items: center;
        grid-gap: 1rem;
        gap: 1rem;
        width: 100%; }
        .rpm-provider-record-alerts-modal__alert-body__header__profile-container__profile-image {
          width: 3rem;
          height: 3rem;
          border-radius: 0.5rem 0.5rem 0 0.5rem;
          border: 2px solid rgba(240, 236, 232, 0.4);
          background-color: #d6d6d6;
          aspect-ratio: 1/1;
          position: relative; }
          .rpm-provider-record-alerts-modal__alert-body__header__profile-container__profile-image img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 0.5rem 0.5rem 0 0.5rem; }
          .rpm-provider-record-alerts-modal__alert-body__header__profile-container__profile-image__initials {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 1rem;
            font-weight: 500;
            color: var(--pure-white);
            position: absolute;
            top: 50%;
            left: 50%;
            -webkit-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%); }
        .rpm-provider-record-alerts-modal__alert-body__header__profile-container__details {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          width: 100%; }
          .rpm-provider-record-alerts-modal__alert-body__header__profile-container__details__tag {
            font-size: 0.625rem;
            font-weight: 600;
            color: #e8744f; }
          .rpm-provider-record-alerts-modal__alert-body__header__profile-container__details__name {
            font-size: 1rem;
            font-weight: 500;
            color: var(--primary-dark); }
          .rpm-provider-record-alerts-modal__alert-body__header__profile-container__details__diagnosis {
            font-size: 0.625rem;
            font-weight: 500;
            color: #595959; }
      .rpm-provider-record-alerts-modal__alert-body__header__status-container {
        min-width: 3.8125rem;
        width: -webkit-max-content;
        width: max-content;
        border: 1px solid #88a088;
        border-radius: 7.8125rem;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 1.5rem;
        grid-gap: 0.25rem;
        gap: 0.25rem;
        padding: 0 0.75rem; }
        .rpm-provider-record-alerts-modal__alert-body__header__status-container__dot {
          height: 5px;
          width: 5px;
          border-radius: 50%;
          aspect-ratio: 1/1;
          background-color: #88a088; }
        .rpm-provider-record-alerts-modal__alert-body__header__status-container__text {
          font-size: 0.625rem;
          font-weight: 500;
          color: #88a088;
          white-space: nowrap; }
    .rpm-provider-record-alerts-modal__alert-body .rpm-provider-record-header-alert-popup-body {
      height: 14.5rem;
      width: 100%;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 0.5rem; }
      .rpm-provider-record-alerts-modal__alert-body .rpm-provider-record-header-alert-popup-body .right-triangle {
        position: absolute;
        top: 6.75rem;
        left: 100%;
        width: 0;
        height: 0;
        border-top: 0.5rem solid transparent;
        border-bottom: 0.5rem solid transparent;
        border-left: 0.5rem solid var(--pure-white); }
      .rpm-provider-record-alerts-modal__alert-body .rpm-provider-record-header-alert-popup-body .rpm-provider-record-header-alert-popup-body-item {
        min-height: 3rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 0 1rem;
        border-bottom: 1px solid #f0ece8; }
        .rpm-provider-record-alerts-modal__alert-body .rpm-provider-record-header-alert-popup-body .rpm-provider-record-header-alert-popup-body-item .rpm-provider-record-header-alert-popup-body-item-left {
          display: flex;
          align-items: center;
          grid-gap: 0.5rem;
          gap: 0.5rem;
          justify-content: flex-start; }
          .rpm-provider-record-alerts-modal__alert-body .rpm-provider-record-header-alert-popup-body .rpm-provider-record-header-alert-popup-body-item .rpm-provider-record-header-alert-popup-body-item-left p {
            font-size: 0.875rem;
            font-weight: 400;
            color: var(--primary-dark); }
        .rpm-provider-record-alerts-modal__alert-body .rpm-provider-record-header-alert-popup-body .rpm-provider-record-header-alert-popup-body-item .rpm-provider-record-header-alert-popup-body-item-right {
          display: flex;
          align-items: center;
          grid-gap: 0.5rem;
          gap: 0.5rem;
          justify-content: flex-end; }
          .rpm-provider-record-alerts-modal__alert-body .rpm-provider-record-header-alert-popup-body .rpm-provider-record-header-alert-popup-body-item .rpm-provider-record-header-alert-popup-body-item-right .rpm-provider-record-header-alert-popup-body-item-right-text {
            padding: 0.5rem;
            width: 2rem;
            height: 1.5rem;
            display: flex;
            align-items: center;
            background: rgba(232, 116, 79, 0.27);
            border-radius: 0.25rem; }
            .rpm-provider-record-alerts-modal__alert-body .rpm-provider-record-header-alert-popup-body .rpm-provider-record-header-alert-popup-body-item .rpm-provider-record-header-alert-popup-body-item-right .rpm-provider-record-header-alert-popup-body-item-right-text p {
              font-size: 0.75rem;
              font-weight: 600;
              color: #e8744f; }
          .rpm-provider-record-alerts-modal__alert-body .rpm-provider-record-header-alert-popup-body .rpm-provider-record-header-alert-popup-body-item .rpm-provider-record-header-alert-popup-body-item-right .rpm-provider-record-header-alert-popup-body-item-right-date {
            display: flex;
            flex-direction: column;
            align-items: flex-start; }
            .rpm-provider-record-alerts-modal__alert-body .rpm-provider-record-header-alert-popup-body .rpm-provider-record-header-alert-popup-body-item .rpm-provider-record-header-alert-popup-body-item-right .rpm-provider-record-header-alert-popup-body-item-right-date p {
              color: #908e87;
              font-weight: 400;
              font-size: 0.7rem; }
      .rpm-provider-record-alerts-modal__alert-body .rpm-provider-record-header-alert-popup-body .rpm-provider-record-header-alert-popup-body-item-clear-all {
        text-decoration: underline;
        font-size: 0.75rem;
        cursor: pointer;
        color: #e8744f;
        font-weight: 600;
        margin-top: 1rem; }

.rpm-coach-calls {
  grid-area: calls;
  background-color: #f8f5f5;
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;
  padding-bottom: 2.5rem;
  height: -webkit-min-content;
  height: min-content; }
  .rpm-coach-calls__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2.5rem 1.5rem; }
    .rpm-coach-calls__header__title {
      font-weight: 400; }
    .rpm-coach-calls__header__button {
      height: 2rem;
      width: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--primary-dark);
      border-radius: 0.25rem; }
  .rpm-coach-calls__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%; }
    .rpm-coach-calls__content__title {
      width: 87%;
      margin: 0 auto;
      font-weight: 700;
      font-size: 0.625rem;
      color: #555555; }
    .rpm-coach-calls__content__list {
      display: flex;
      flex-direction: column;
      align-items: center;
      grid-gap: 0.5rem;
      gap: 0.5rem;
      margin-top: 1rem;
      width: 100%; }
      .rpm-coach-calls__content__list__item {
        width: 87%;
        margin: 0 auto;
        background-color: var(--primary-dark);
        box-shadow: 0px 4px 20px 1px rgba(0, 0, 0, 0.1);
        border-radius: 0.625rem;
        padding: 1rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        grid-gap: 0.5rem;
        gap: 0.5rem;
        position: relative; }
        .rpm-coach-calls__content__list__item__profile-container {
          display: flex;
          align-items: center;
          grid-gap: 0.5rem;
          gap: 0.5rem;
          cursor: pointer; }
          .rpm-coach-calls__content__list__item__profile-container__picture {
            height: 2.5rem;
            width: 2.5rem;
            border-radius: 0.25rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            aspect-ratio: 1/1; }
            .rpm-coach-calls__content__list__item__profile-container__picture__header {
              height: 1rem;
              background-color: #4b4a48;
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              color: var(--pure-white);
              font-weight: 700;
              font-size: 0.5rem;
              border-radius: 0.25rem 0.25rem 0 0; }
            .rpm-coach-calls__content__list__item__profile-container__picture__body {
              height: 100%;
              width: 100%;
              background-color: var(--pure-white);
              display: flex;
              align-items: center;
              justify-content: center;
              height: 1.5rem;
              border-radius: 0 0 0.25rem 0.25rem; }
              .rpm-coach-calls__content__list__item__profile-container__picture__body p {
                font-family: var(--serif-font-family);
                font-size: 1rem;
                font-weight: 900; }
          .rpm-coach-calls__content__list__item__profile-container__name {
            display: flex;
            flex-direction: column;
            align-items: flex-start; }
            .rpm-coach-calls__content__list__item__profile-container__name__text {
              font-weight: 400;
              font-size: 0.875rem;
              color: var(--pure-white); }
            .rpm-coach-calls__content__list__item__profile-container__name__description {
              font-weight: 400;
              font-size: 0.625rem;
              color: #a4a2a1; }
        .rpm-coach-calls__content__list__item__time {
          cursor: pointer;
          height: 2rem;
          display: flex;
          align-items: center;
          width: 100%;
          background: rgba(98, 97, 93, 0.5);
          padding: 0 1rem;
          border-radius: 0.25rem;
          grid-gap: 0.5rem;
          gap: 0.5rem; }
          .rpm-coach-calls__content__list__item__time p {
            font-size: 0.75rem;
            color: var(--pure-white);
            font-weight: 400; }
            .rpm-coach-calls__content__list__item__time p strong {
              font-weight: 600;
              margin-right: 0.5rem; }
        .rpm-coach-calls__content__list__item__footer {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%; }
          .rpm-coach-calls__content__list__item__footer__right {
            display: flex;
            align-items: center;
            grid-gap: 0.5rem;
            gap: 0.5rem; }
            .rpm-coach-calls__content__list__item__footer__right__text {
              font-size: 0.75rem;
              color: var(--pure-white);
              font-weight: 500; }
        .rpm-coach-calls__content__list__item__three-dots {
          position: absolute;
          top: 1.25rem;
          right: 1rem;
          cursor: pointer;
          width: 1.5rem;
          height: 1.5rem;
          border: none;
          background-color: transparent;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          outline: none; }
        .rpm-coach-calls__content__list__item__dropdown {
          height: 5.5625rem;
          width: 9.3125rem;
          background-color: var(--pure-white);
          border-radius: 0.25rem;
          position: absolute;
          top: 1.25rem;
          right: 1rem;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          z-index: 2;
          box-shadow: 1px 1px 20px 1px rgba(0, 0, 0, 0.05);
          padding: 0rem 0.5rem; }
          .rpm-coach-calls__content__list__item__dropdown__item {
            height: 2.75rem;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            cursor: pointer;
            font-size: 0.75rem;
            font-weight: 600;
            padding-left: 0.5rem;
            color: var(--primary-dark); }
      .rpm-coach-calls__content__list__loading-card {
        width: 327px;
        height: 110px;
        box-shadow: 0px 4px 20px 1px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        background-color: var(--primary-dark);
        position: relative; }
        .rpm-coach-calls__content__list__loading-card__avatar {
          width: 38px;
          height: 38px;
          border-radius: 0.25rem;
          background-color: #4b4a48;
          position: absolute;
          top: 1rem;
          left: 1rem; }
        .rpm-coach-calls__content__list__loading-card__info {
          top: 2rem;
          left: 65px;
          position: absolute;
          width: 135px;
          height: 10px;
          background-color: #4b4a48;
          border-radius: 0.25rem; }
        .rpm-coach-calls__content__list__loading-card__body {
          left: 1rem;
          top: 62px;
          background-color: #4b4a48;
          position: absolute;
          width: 295px;
          height: 32px;
          border-radius: 0.25rem; }

.rpm-coach-dashboard .rpm-coach-container-layout .rpm-coach-container .rpm-provider-columns {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  padding: 0.55rem;
  position: relative;
  border-bottom: 1px solid #c4c4c4; }
  .rpm-coach-dashboard .rpm-coach-container-layout .rpm-coach-container .rpm-provider-columns p {
    font-size: 0.75rem;
    font-weight: 500;
    color: #595959; }
  .rpm-coach-dashboard .rpm-coach-container-layout .rpm-coach-container .rpm-provider-columns .rpm-provider-columns {
    max-width: 15.2125rem; }
  .rpm-coach-dashboard .rpm-coach-container-layout .rpm-coach-container .rpm-provider-columns .rpm-provider-columns-statuses {
    position: absolute;
    left: 59%;
    -webkit-transform: translateX(-59%);
            transform: translateX(-59%);
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 2.5rem; }
  .rpm-coach-dashboard .rpm-coach-container-layout .rpm-coach-container .rpm-provider-columns .rpm-provider-columns-alerts-and-chat {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    grid-gap: 1.5rem;
    gap: 1.5rem; }
    .rpm-coach-dashboard .rpm-coach-container-layout .rpm-coach-container .rpm-provider-columns .rpm-provider-columns-alerts-and-chat .rpm-provider-columns-alerts {
      padding-right: 1rem; }
    .rpm-coach-dashboard .rpm-coach-container-layout .rpm-coach-container .rpm-provider-columns .rpm-provider-columns-alerts-and-chat .rpm-provider-columns-chat {
      opacity: 0; }

.rpm-coach-dashboard .rpm-coach-container-layout .rpm-coach-container .rpm-provider-records {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  max-height: 100vh;
  overflow-y: auto; }

.rpm-coach-dashboard .rpm-coach-container-layout .rpm-coach-container .rpm-provider-record {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: var(--pure-white);
  border-radius: 0.625rem;
  margin-top: 1rem;
  box-shadow: 0px -4px 20px 1px rgba(0, 0, 0, 0.05); }
  .rpm-coach-dashboard .rpm-coach-container-layout .rpm-coach-container .rpm-provider-record .rpm-provider-record-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 4.0625rem;
    padding: 1rem;
    position: relative; }
    .rpm-coach-dashboard .rpm-coach-container-layout .rpm-coach-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-status-container {
      position: absolute;
      left: 60%;
      -webkit-transform: translateX(-60%);
              transform: translateX(-60%);
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 2rem; }
      .rpm-coach-dashboard .rpm-coach-container-layout .rpm-coach-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-status-container .rpm-provider-record-header-status {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        grid-gap: 0.5rem;
        gap: 0.5rem;
        min-width: -webkit-max-content;
        min-width: max-content; }
        .rpm-coach-dashboard .rpm-coach-container-layout .rpm-coach-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-status-container .rpm-provider-record-header-status .rpm-provider-record-header-status-dot {
          min-width: 0.25rem;
          width: -webkit-max-content;
          width: max-content;
          height: 0.25rem;
          border-radius: 50%;
          aspect-ratio: 1/1;
          background-color: #a4a2a1; }
        .rpm-coach-dashboard .rpm-coach-container-layout .rpm-coach-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-status-container .rpm-provider-record-header-status .rpm-provider-record-header-status-dot.eligible {
          background-color: #718371; }
        .rpm-coach-dashboard .rpm-coach-container-layout .rpm-coach-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-status-container .rpm-provider-record-header-status .rpm-provider-record-header-status-dot.ineligible {
          background-color: #e8744f; }
        .rpm-coach-dashboard .rpm-coach-container-layout .rpm-coach-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-status-container .rpm-provider-record-header-status .rpm-provider-record-header-status-text {
          font-size: 0.625rem;
          font-weight: 500;
          color: #a4a2a1; }
        .rpm-coach-dashboard .rpm-coach-container-layout .rpm-coach-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-status-container .rpm-provider-record-header-status .rpm-provider-record-header-status-text.eligible {
          color: #718371; }
        .rpm-coach-dashboard .rpm-coach-container-layout .rpm-coach-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-status-container .rpm-provider-record-header-status .rpm-provider-record-header-status-text.ineligible {
          color: #e8744f; }
      .rpm-coach-dashboard .rpm-coach-container-layout .rpm-coach-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-status-container .with-border {
        border: 1px solid #a4a2a1;
        border-radius: 7.8125rem;
        padding: 0.45rem 0.625rem; }
      .rpm-coach-dashboard .rpm-coach-container-layout .rpm-coach-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-status-container .with-border.eligible {
        border: 1px solid #718371; }
      .rpm-coach-dashboard .rpm-coach-container-layout .rpm-coach-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-status-container .with-border.ineligible {
        border: 1px solid #e8744f; }
    .rpm-coach-dashboard .rpm-coach-container-layout .rpm-coach-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-user {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      grid-gap: 0.5rem;
      gap: 0.5rem;
      max-width: 15.2125rem;
      cursor: pointer; }
      .rpm-coach-dashboard .rpm-coach-container-layout .rpm-coach-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-user .rpm-provider-record-header-user-avatar {
        position: relative;
        width: 2.5rem;
        height: 2.5rem;
        aspect-ratio: 1/1; }
        .rpm-coach-dashboard .rpm-coach-container-layout .rpm-coach-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-user .rpm-provider-record-header-user-avatar img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          box-sizing: border-box; }
        .rpm-coach-dashboard .rpm-coach-container-layout .rpm-coach-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-user .rpm-provider-record-header-user-avatar .rpm-provider-record-header-user-avatar-text {
          position: absolute;
          top: 50%;
          left: 50%;
          -webkit-transform: translate(-50%, -50%);
                  transform: translate(-50%, -50%);
          font-size: 0.75rem;
          font-weight: 500;
          color: var(--pure-white); }
      .rpm-coach-dashboard .rpm-coach-container-layout .rpm-coach-container .rpm-provider-record .rpm-provider-record-header .rpm-provider-record-header-user .rpm-provider-record-header-user-text {
        font-size: 1rem;
        color: var(--primary-dark);
        font-weight: 500;
        width: 75%;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical; }
    .rpm-coach-dashboard .rpm-coach-container-layout .rpm-coach-container .rpm-provider-record .rpm-provider-record-header .rpm-alerts-wrapper {
      display: flex;
      align-items: center;
      flex-direction: row;
      grid-gap: 1.5rem;
      gap: 1.5rem;
      justify-content: flex-end; }
      .rpm-coach-dashboard .rpm-coach-container-layout .rpm-coach-container .rpm-provider-record .rpm-provider-record-header .rpm-alerts-wrapper button {
        outline: none; }
      .rpm-coach-dashboard .rpm-coach-container-layout .rpm-coach-container .rpm-provider-record .rpm-provider-record-header .rpm-alerts-wrapper__resend-invitation {
        font-size: 0.75rem;
        font-weight: 400;
        color: #000000;
        text-decoration: underline;
        margin-right: -0.25rem; }
  .rpm-coach-dashboard .rpm-coach-container-layout .rpm-coach-container .rpm-provider-record .rpm-provider-record-body {
    height: 6.75rem;
    border-top: 1px solid #d6d6d6;
    display: flex;
    align-items: center;
    justify-content: center; }
    .rpm-coach-dashboard .rpm-coach-container-layout .rpm-coach-container .rpm-provider-record .rpm-provider-record-body .rpm-provider-record-body-grid {
      width: 90%;
      display: flex;
      align-items: flex-start;
      justify-content: space-between; }
      .rpm-coach-dashboard .rpm-coach-container-layout .rpm-coach-container .rpm-provider-record .rpm-provider-record-body .rpm-provider-record-body-grid .rpm-provider-record-body-left,
      .rpm-coach-dashboard .rpm-coach-container-layout .rpm-coach-container .rpm-provider-record .rpm-provider-record-body .rpm-provider-record-body-grid .rpm-provider-record-body-right {
        display: flex;
        align-items: center;
        grid-gap: 2.5rem;
        gap: 2.5rem; }
        .rpm-coach-dashboard .rpm-coach-container-layout .rpm-coach-container .rpm-provider-record .rpm-provider-record-body .rpm-provider-record-body-grid .rpm-provider-record-body-left .rpm-provider-record-body-left-item,
        .rpm-coach-dashboard .rpm-coach-container-layout .rpm-coach-container .rpm-provider-record .rpm-provider-record-body .rpm-provider-record-body-grid .rpm-provider-record-body-right .rpm-provider-record-body-left-item {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          grid-gap: 0.35rem;
          gap: 0.35rem; }
          .rpm-coach-dashboard .rpm-coach-container-layout .rpm-coach-container .rpm-provider-record .rpm-provider-record-body .rpm-provider-record-body-grid .rpm-provider-record-body-left .rpm-provider-record-body-left-item .rpm-provider-record-body-left-item-title,
          .rpm-coach-dashboard .rpm-coach-container-layout .rpm-coach-container .rpm-provider-record .rpm-provider-record-body .rpm-provider-record-body-grid .rpm-provider-record-body-right .rpm-provider-record-body-left-item .rpm-provider-record-body-left-item-title {
            font-size: 0.625rem;
            font-weight: 600;
            color: var(--primary-dark); }
          .rpm-coach-dashboard .rpm-coach-container-layout .rpm-coach-container .rpm-provider-record .rpm-provider-record-body .rpm-provider-record-body-grid .rpm-provider-record-body-left .rpm-provider-record-body-left-item .rpm-provider-record-body-left-item-text,
          .rpm-coach-dashboard .rpm-coach-container-layout .rpm-coach-container .rpm-provider-record .rpm-provider-record-body .rpm-provider-record-body-grid .rpm-provider-record-body-right .rpm-provider-record-body-left-item .rpm-provider-record-body-left-item-text {
            font-size: 0.625rem;
            font-weight: 600;
            color: var(--primary-dark);
            padding: 0.1rem 0.25rem;
            border-radius: 0.25rem; }
          .rpm-coach-dashboard .rpm-coach-container-layout .rpm-coach-container .rpm-provider-record .rpm-provider-record-body .rpm-provider-record-body-grid .rpm-provider-record-body-left .rpm-provider-record-body-left-item .tag,
          .rpm-coach-dashboard .rpm-coach-container-layout .rpm-coach-container .rpm-provider-record .rpm-provider-record-body .rpm-provider-record-body-grid .rpm-provider-record-body-right .rpm-provider-record-body-left-item .tag {
            padding: 1px 4px;
            border-radius: 4px;
            color: #e8744f;
            font-weight: 600;
            background: #f9dad0; }
  .rpm-coach-dashboard .rpm-coach-container-layout .rpm-coach-container .rpm-provider-record .rpm-provider-record-footer {
    background-color: #f8f5f5;
    width: 90%;
    margin: 0 auto;
    margin-bottom: 1rem;
    height: 2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;
    border-radius: 0.25rem; }
    .rpm-coach-dashboard .rpm-coach-container-layout .rpm-coach-container .rpm-provider-record .rpm-provider-record-footer p {
      font-size: 0.625rem;
      font-weight: 400;
      color: #595959; }
    .rpm-coach-dashboard .rpm-coach-container-layout .rpm-coach-container .rpm-provider-record .rpm-provider-record-footer strong {
      font-size: 0.625rem;
      font-weight: 600;
      color: #555555; }
    .rpm-coach-dashboard .rpm-coach-container-layout .rpm-coach-container .rpm-provider-record .rpm-provider-record-footer .rpm-provider-record-footer-progress {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      grid-gap: 0.5rem;
      gap: 0.5rem; }
      .rpm-coach-dashboard .rpm-coach-container-layout .rpm-coach-container .rpm-provider-record .rpm-provider-record-footer .rpm-provider-record-footer-progress .rpm-provider-record-footer-progress-bar {
        width: 5.5rem;
        height: 0.375rem;
        background-color: #e5e5e5;
        border-radius: 4rem;
        position: relative; }
        .rpm-coach-dashboard .rpm-coach-container-layout .rpm-coach-container .rpm-provider-record .rpm-provider-record-footer .rpm-provider-record-footer-progress .rpm-provider-record-footer-progress-bar .rpm-provider-record-footer-progress-bar-fill {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          background-color: #88a088;
          border-radius: 4rem; }
      .rpm-coach-dashboard .rpm-coach-container-layout .rpm-coach-container .rpm-provider-record .rpm-provider-record-footer .rpm-provider-record-footer-progress p {
        font-weight: 600;
        color: #555555; }

.rpm-modal .modal-content {
  display: flex;
  flex-direction: column;
  grid-gap: 0.875rem;
  gap: 0.875rem;
  margin-top: calc(50vh - 6.5rem);
  min-height: 14.375rem;
  width: 30.56rem;
  align-items: center;
  justify-content: center;
  padding: 4.5rem 3rem; }
  @media (max-width: 767.98px) {
    .rpm-modal .modal-content {
      width: 90vw;
      margin: auto;
      margin-top: 5rem; } }
  .rpm-modal .modal-content h3 {
    font-size: 1rem;
    font-weight: 600;
    color: var(--primary-dark);
    text-align: center; }
  .rpm-modal .modal-content p {
    font-weight: 300;
    font-size: 0.875rem;
    text-align: center;
    color: var(--primary-dark); }
  .rpm-modal .modal-content .tip {
    font-size: 0.75rem;
    font-weight: 600;
    text-align: center; }
  .rpm-modal .modal-content button {
    position: absolute;
    top: 0.875rem;
    right: 1rem; }

.container-skeleton, .billing-card-skeleton__title, .billing-card-skeleton__description-1, .billing-card-skeleton__description-2, .billing-card-skeleton__divider, .billing-card-skeleton__footer-1, .billing-card-skeleton__footer-2, .billing-card-skeleton__footer-3, .billing-card-skeleton__footer-4, .activity-card-skeleton__profile, .activity-card-skeleton__name, .activity-card-skeleton__description, .activity-card-skeleton__score, .skeleton-user-card__avatar, .skeleton-user-card__user-info, .skeleton-user-card__alerts-circle, .skeleton-user-card__line, .skeleton-user-card__info__top, .skeleton-user-card__info__bottom {
  -webkit-animation: container-skeleton-loading 1s linear infinite alternate;
          animation: container-skeleton-loading 1s linear infinite alternate; }

@keyframes container-skeleton-loading {
  0% {
    background-color: var(--primary-white); }
  100% {
    background-color: var(--primary-white); } }

.skeleton-user-card {
  height: 8.375rem;
  width: 100%;
  border-radius: 0.625rem;
  background-color: var(--pure-white);
  position: relative; }
  .skeleton-user-card__avatar {
    width: 40px;
    height: 40px;
    border-radius: 0.25rem;
    border-bottom-right-radius: 0;
    background-color: var(--primary-white);
    position: absolute;
    top: 0.6875rem;
    left: 0.9375rem; }
  .skeleton-user-card__user-info {
    width: 148px;
    height: 10px;
    left: 4.25rem;
    top: 1.6875rem;
    border-radius: 0.25rem;
    position: absolute; }
  .skeleton-user-card__alerts-circle {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    position: absolute;
    top: 20px;
    right: 36px; }
  .skeleton-user-card__line {
    width: 100%;
    height: 1px;
    position: absolute;
    top: 4.21875rem; }
  .skeleton-user-card__info {
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px;
    top: 5.25rem;
    left: 1.14rem;
    position: absolute; }
    .skeleton-user-card__info__top {
      border-radius: 0.25rem;
      width: 74px;
      height: 6px; }
    .skeleton-user-card__info__bottom {
      width: 21px;
      height: 10px;
      border-radius: 0.25rem; }

.rpm-coach-dashboard .rpm-coach-container-layout .rpm-coach-container .rpm-provider-mobile-records {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  grid-gap: 1.25rem;
  gap: 1.25rem;
  padding-bottom: 3rem; }
  .rpm-coach-dashboard .rpm-coach-container-layout .rpm-coach-container .rpm-provider-mobile-records .rpm-provider-mobile-record {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    border-radius: 0.625rem;
    box-shadow: 0px 4px 20px 1px rgba(0, 0, 0, 0.1);
    background-color: var(--pure-white); }
    .rpm-coach-dashboard .rpm-coach-container-layout .rpm-coach-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-header {
      height: 2.5rem;
      width: 100%;
      background-color: #e8754f;
      padding: 0 1.5rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-top-left-radius: 0.625rem;
      border-top-right-radius: 0.625rem; }
      .rpm-coach-dashboard .rpm-coach-container-layout .rpm-coach-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-header__left {
        display: flex;
        align-items: center;
        grid-gap: 1rem;
        gap: 1rem; }
        .rpm-coach-dashboard .rpm-coach-container-layout .rpm-coach-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-header__left__alert-icon-container {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center; }
          .rpm-coach-dashboard .rpm-coach-container-layout .rpm-coach-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-header__left__alert-icon-container__square {
            width: 1.11125rem;
            height: 1.11125rem;
            aspect-ratio: 1/1;
            background-color: #f9dad0;
            border-radius: 0.1875rem;
            -webkit-transform: rotate(45deg);
                    transform: rotate(45deg); }
          .rpm-coach-dashboard .rpm-coach-container-layout .rpm-coach-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-header__left__alert-icon-container__icon {
            color: #e8744f;
            font-size: 0.7rem;
            font-weight: 800;
            z-index: 10;
            position: absolute; }
        .rpm-coach-dashboard .rpm-coach-container-layout .rpm-coach-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-header__left__alert-text {
          font-size: 0.875rem;
          font-weight: 600;
          color: var(--pure-white); }
      .rpm-coach-dashboard .rpm-coach-container-layout .rpm-coach-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-header__right {
        font-size: 0.75rem;
        font-weight: 600;
        color: var(--pure-white); }
      .rpm-coach-dashboard .rpm-coach-container-layout .rpm-coach-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-header__no-alerts {
        color: rgba(89, 89, 89, 0.5);
        font-size: 0.625rem;
        font-weight: 600; }
    .rpm-coach-dashboard .rpm-coach-container-layout .rpm-coach-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-body {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      padding: 0.5rem;
      width: 100%;
      grid-gap: 0.5rem;
      gap: 0.5rem;
      background-color: var(--pure-white);
      border-bottom-left-radius: 0.625rem;
      border-bottom-right-radius: 0.625rem; }
      .rpm-coach-dashboard .rpm-coach-container-layout .rpm-coach-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-body__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 0; }
        .rpm-coach-dashboard .rpm-coach-container-layout .rpm-coach-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-body__header__profile-container {
          display: flex;
          align-items: center;
          grid-gap: 1rem;
          gap: 1rem;
          width: 100%; }
          .rpm-coach-dashboard .rpm-coach-container-layout .rpm-coach-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-body__header__profile-container__profile-image {
            width: 3rem;
            height: 3rem;
            border-radius: 0.5rem 0.5rem 0 0.5rem;
            border: 2px solid rgba(240, 236, 232, 0.4);
            background-color: #d6d6d6;
            aspect-ratio: 1/1;
            position: relative; }
            .rpm-coach-dashboard .rpm-coach-container-layout .rpm-coach-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-body__header__profile-container__profile-image img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              border-radius: 0.5rem 0.5rem 0 0.5rem; }
            .rpm-coach-dashboard .rpm-coach-container-layout .rpm-coach-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-body__header__profile-container__profile-image__initials {
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 1rem;
              font-weight: 500;
              color: var(--pure-white);
              position: absolute;
              top: 50%;
              left: 50%;
              -webkit-transform: translate(-50%, -50%);
                      transform: translate(-50%, -50%); }
          .rpm-coach-dashboard .rpm-coach-container-layout .rpm-coach-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-body__header__profile-container__details {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            width: 100%; }
            .rpm-coach-dashboard .rpm-coach-container-layout .rpm-coach-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-body__header__profile-container__details__tag {
              font-size: 0.625rem;
              font-weight: 600;
              color: #e8744f; }
            .rpm-coach-dashboard .rpm-coach-container-layout .rpm-coach-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-body__header__profile-container__details__name {
              font-size: 1rem;
              font-weight: 500;
              color: var(--primary-dark); }
            .rpm-coach-dashboard .rpm-coach-container-layout .rpm-coach-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-body__header__profile-container__details__diagnosis {
              font-size: 0.625rem;
              font-weight: 500;
              color: #595959; }
        .rpm-coach-dashboard .rpm-coach-container-layout .rpm-coach-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-body__header__status-container {
          min-width: 3.8125rem;
          width: -webkit-max-content;
          width: max-content;
          border: 1px solid #88a088;
          border-radius: 7.8125rem;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 1.5rem;
          grid-gap: 0.25rem;
          gap: 0.25rem;
          padding: 0 0.5rem; }
          .rpm-coach-dashboard .rpm-coach-container-layout .rpm-coach-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-body__header__status-container__dot {
            height: 5px;
            width: 5px;
            border-radius: 50%;
            aspect-ratio: 1/1;
            background-color: #88a088; }
          .rpm-coach-dashboard .rpm-coach-container-layout .rpm-coach-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-body__header__status-container__text {
            font-size: 0.625rem;
            font-weight: 500;
            color: #88a088;
            white-space: nowrap; }
      .rpm-coach-dashboard .rpm-coach-container-layout .rpm-coach-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-body__coach-container {
        width: 100%;
        height: 1.875rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #f7f5f3;
        padding: 0 0.5rem;
        border-radius: 0.25rem; }
        .rpm-coach-dashboard .rpm-coach-container-layout .rpm-coach-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-body__coach-container__title {
          font-size: 0.75rem;
          font-weight: 500;
          color: #595959; }
      .rpm-coach-dashboard .rpm-coach-container-layout .rpm-coach-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-body__measurements-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 0 0.5rem;
        align-items: center; }
        .rpm-coach-dashboard .rpm-coach-container-layout .rpm-coach-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-body__measurements-container__item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          height: 2.5rem;
          border-bottom: 1px solid #f0f0f0; }
          .rpm-coach-dashboard .rpm-coach-container-layout .rpm-coach-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-body__measurements-container__item__label {
            font-size: 0.625rem;
            font-weight: 600;
            width: 50%; }
          .rpm-coach-dashboard .rpm-coach-container-layout .rpm-coach-container .rpm-provider-mobile-records .rpm-provider-mobile-record__alert-body__measurements-container__item__value {
            font-size: 0.625rem;
            font-weight: 400;
            width: 50%; }
    .rpm-coach-dashboard .rpm-coach-container-layout .rpm-coach-container .rpm-provider-mobile-records .rpm-provider-mobile-record .rpm-provider-mobile-record-footer {
      background-color: #f8f5f5;
      width: 90%;
      margin: 1rem auto;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      padding: 1rem;
      border-radius: 0.25rem;
      grid-gap: .5rem;
      gap: .5rem; }
      .rpm-coach-dashboard .rpm-coach-container-layout .rpm-coach-container .rpm-provider-mobile-records .rpm-provider-mobile-record .rpm-provider-mobile-record-footer p {
        font-size: 0.625rem;
        font-weight: 400;
        color: #595959; }
      .rpm-coach-dashboard .rpm-coach-container-layout .rpm-coach-container .rpm-provider-mobile-records .rpm-provider-mobile-record .rpm-provider-mobile-record-footer strong {
        font-size: 0.625rem;
        font-weight: 600;
        color: #555555; }
      .rpm-coach-dashboard .rpm-coach-container-layout .rpm-coach-container .rpm-provider-mobile-records .rpm-provider-mobile-record .rpm-provider-mobile-record-footer .rpm-provider-record-footer-progress {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        grid-gap: 0.5rem;
        gap: 0.5rem; }
        .rpm-coach-dashboard .rpm-coach-container-layout .rpm-coach-container .rpm-provider-mobile-records .rpm-provider-mobile-record .rpm-provider-mobile-record-footer .rpm-provider-record-footer-progress .rpm-provider-record-footer-progress-bar {
          width: 5.5rem;
          height: 0.375rem;
          background-color: #e5e5e5;
          border-radius: 4rem;
          position: relative; }
          .rpm-coach-dashboard .rpm-coach-container-layout .rpm-coach-container .rpm-provider-mobile-records .rpm-provider-mobile-record .rpm-provider-mobile-record-footer .rpm-provider-record-footer-progress .rpm-provider-record-footer-progress-bar .rpm-provider-record-footer-progress-bar-fill {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            background-color: #88a088;
            border-radius: 4rem; }
        .rpm-coach-dashboard .rpm-coach-container-layout .rpm-coach-container .rpm-provider-mobile-records .rpm-provider-mobile-record .rpm-provider-mobile-record-footer .rpm-provider-record-footer-progress p {
          font-weight: 600;
          color: #555555; }

.send-clinical-record-modal .modal-content {
  background-color: var(--pure-white);
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding: 3rem 5rem; }
  @media (max-width: 767.98px) {
    .send-clinical-record-modal .modal-content {
      width: 85vw;
      margin: 0 auto;
      padding: 2rem 1.5rem; } }
  .send-clinical-record-modal .modal-content h3 {
    font-size: 1.5rem;
    font-weight: 300;
    text-align: center; }
    @media (max-width: 767.98px) {
      .send-clinical-record-modal .modal-content h3 {
        font-size: 1.25rem; } }
  .send-clinical-record-modal .modal-content form {
    width: 100%;
    margin-top: 1.875rem;
    display: flex;
    flex-direction: column; }
    .send-clinical-record-modal .modal-content form label {
      width: 100%;
      font-size: 0.75rem;
      font-weight: 400;
      margin-top: 1.25rem; }
      .send-clinical-record-modal .modal-content form label:first-child {
        margin-top: 0; }
    .send-clinical-record-modal .modal-content form input {
      width: 100%;
      font-size: 0.875rem;
      font-weight: 300;
      color: var(--primary-dark);
      padding-left: 1rem;
      height: 2.5rem; }
      .send-clinical-record-modal .modal-content form input:placeholder {
        font-size: 0.875rem;
        color: var(--primary-dark);
        font-weight: 300; }
      .send-clinical-record-modal .modal-content form input:disabled {
        background-color: rgba(211, 208, 204, 0.23);
        color: var(--primary-dark); }
    .send-clinical-record-modal .modal-content form textarea {
      resize: none;
      font-size: 0.875rem;
      font-weight: 300;
      color: var(--primary-dark); }
    .send-clinical-record-modal .modal-content form .reviewing-text {
      font-weight: 300;
      font-size: 0.875rem;
      color: var(--primary-dark);
      margin-top: 5px; }
    .send-clinical-record-modal .modal-content form .error-message {
      color: var(--primary-red);
      font-size: 0.7rem;
      margin: 0.5rem 0;
      font-weight: 700;
      text-align: start; }
    .send-clinical-record-modal .modal-content form .submit-btn {
      background-color: var(--primary-dark);
      color: var(--pure-white);
      font-size: 0.875rem;
      width: 100%;
      height: 2.5rem;
      border-radius: 0.25rem; }

.send-clinical-record-modal__buttons {
  display: flex;
  align-items: center;
  width: 100%; }
  .send-clinical-record-modal__buttons .back-btn {
    width: 100%;
    font-size: 0.875rem;
    text-align: left; }

.clinical-record-submitted-modal .modal-content {
  background-color: var(--pure-white);
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding: 3rem 5rem;
  margin-top: 10vh; }

.clinical-record-submitted-modal__title {
  font-weight: 600;
  font-size: 1rem;
  color: var(--primary-dark);
  margin-top: 1.5rem; }

.clinical-record-submitted-modal__description {
  font-weight: 300;
  font-size: 0.875rem;
  color: var(--primary-dark);
  margin-top: 0.875rem;
  text-align: center; }

.clinical-record-submitted-modal__close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
  outline: none; }

.add-coach-modal .modal-content {
  background-color: var(--pure-white);
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding: 3rem 5rem; }
  .add-coach-modal .modal-content form {
    min-width: 100%; }
  @media (max-width: 767.98px) {
    .add-coach-modal .modal-content {
      width: 85vw;
      margin: 0 auto;
      padding: 2rem 1.5rem; } }
  .add-coach-modal .modal-content h3 {
    font-size: 1.5rem;
    font-weight: 300;
    text-align: center; }
    @media (max-width: 767.98px) {
      .add-coach-modal .modal-content h3 {
        font-size: 1.25rem; } }
  .add-coach-modal .modal-content .submit-btn {
    background-color: var(--primary-dark);
    color: var(--pure-white);
    font-size: 0.875rem;
    width: 100%;
    height: 2.5rem;
    border-radius: 0.25rem; }

h1 {
  text-align: left; }

.rpm-events {
  position: relative; }
  .rpm-events__header {
    display: flex;
    width: 80%;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    margin-top: 2rem; }
    .rpm-events__header select {
      height: 40px;
      border-radius: 0.625rem;
      border: 1px solid var(--primary-dark);
      outline: none;
      padding: 0 1rem;
      font-size: 0.75rem; }
  .rpm-events .greminders__booking-widget {
    display: block !important;
    height: 100vh !important; }
    .rpm-events .greminders__booking-widget .greminders__booking-widget-overlay {
      position: relative !important;
      display: flex !important;
      flex-direction: column !important;
      justify-content: flex-start !important;
      padding-top: 3rem !important; }
      @media (max-width: 991.98px) {
        .rpm-events .greminders__booking-widget .greminders__booking-widget-overlay {
          padding-top: 0 !important; } }
  .rpm-events button {
    display: flex;
    align-items: center;
    background-color: var(--primary-dark);
    height: 40px;
    padding: 0 1rem;
    border-radius: 0.625rem;
    outline: none; }
    @media (max-width: 991.98px) {
      .rpm-events button {
        display: none; } }
    .rpm-events button p {
      margin-left: 0.6rem;
      color: var(--pure-white);
      font-size: 0.75rem; }

h1 {
  text-align: left; }

h1 {
  text-align: left; }

h1 {
  text-align: left; }

.chat-default {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  background-color: var(--primary-bg-white);
  align-items: center;
  padding-top: 1rem; }
  .chat-default .chat-container {
    max-width: 1140px;
    margin: 0 auto;
    height: calc(100vh - 7rem);
    max-height: calc(100vh - 7rem);
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    overflow: hidden; }

.chat-default .chat-container .chat-conversations {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-color: var(--primary-white);
  border-radius: 0.5rem 0 0 0.5rem; }
  .chat-default .chat-container .chat-conversations .chat-conversations-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 5rem;
    width: 100%;
    padding: 0 1.5rem; }
    .chat-default .chat-container .chat-conversations .chat-conversations-header .chat-conversations-header-title {
      font-size: 1.625rem;
      font-weight: 700;
      color: var(--primary-dark); }
  .chat-default .chat-container .chat-conversations .chat-conversations-search-container {
    display: flex;
    width: 100%;
    position: relative;
    padding: 0 1.5rem; }
    .chat-default .chat-container .chat-conversations .chat-conversations-search-container svg {
      position: absolute;
      top: 50%;
      left: 2.5rem;
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%);
      color: var(--primary-gray); }
    .chat-default .chat-container .chat-conversations .chat-conversations-search-container .chat-conversations-search {
      height: 2.5rem;
      width: 100%;
      padding: 0 2.5rem;
      border: none;
      border-radius: 0.5rem;
      border: 1px var(--primary-light-gray) solid;
      background-color: var(--pure-white);
      font-size: 1rem;
      font-weight: 300;
      color: var(--primary-gray); }
      .chat-default .chat-container .chat-conversations .chat-conversations-search-container .chat-conversations-search::-webkit-input-placeholder {
        color: var(--primary-gray); }
      .chat-default .chat-container .chat-conversations .chat-conversations-search-container .chat-conversations-search::placeholder {
        color: var(--primary-gray); }
      .chat-default .chat-container .chat-conversations .chat-conversations-search-container .chat-conversations-search:focus {
        outline: none; }
  .chat-default .chat-container .chat-conversations .chat-conversations-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: calc(100vh - 15rem);
    overflow-y: auto;
    width: 100%; }
    .chat-default .chat-container .chat-conversations .chat-conversations-list .chat-conversation {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 5.9375rem;
      min-height: 5.9375rem;
      width: calc(100% - 3rem);
      border-bottom: 1px solid rgba(196, 196, 196, 0.3); }
      .chat-default .chat-container .chat-conversations .chat-conversations-list .chat-conversation .chat-conversation-info {
        display: flex;
        align-items: center;
        grid-gap: 1rem;
        gap: 1rem;
        width: 90%; }
        .chat-default .chat-container .chat-conversations .chat-conversations-list .chat-conversation .chat-conversation-info .chat-conversation-avatar {
          height: 3.125rem;
          width: 3.125rem;
          border-radius: 0.625rem 0.625rem 0px 0.625rem;
          background-color: #718371;
          display: flex;
          align-items: center;
          justify-content: center;
          aspect-ratio: 1/1; }
        .chat-default .chat-container .chat-conversations .chat-conversations-list .chat-conversation .chat-conversation-info .chat-conversation-name-container {
          display: flex;
          flex-direction: column; }
          .chat-default .chat-container .chat-conversations .chat-conversations-list .chat-conversation .chat-conversation-info .chat-conversation-name-container .chat-conversation-name {
            font-size: 0.875rem;
            font-weight: 500;
            color: var(--primary-dark); }
          .chat-default .chat-container .chat-conversations .chat-conversations-list .chat-conversation .chat-conversation-info .chat-conversation-name-container .chat-conversation-last-message {
            font-size: 0.75rem;
            font-weight: 300;
            color: var(--primary-dark-green); }
      .chat-default .chat-container .chat-conversations .chat-conversations-list .chat-conversation .chat-conversation-last-message-time {
        font-size: 0.75rem;
        font-weight: 300;
        color: var(--primary-dark-green);
        flex-wrap: nowrap;
        min-width: -webkit-max-content;
        min-width: max-content;
        height: 3.125rem; }

.chat-default .chat-container .chat-messages {
  display: flex;
  flex-direction: column;
  background-color: var(--pure-white);
  border-radius: 0 0.5rem 0.5rem 0;
  position: relative; }
  .chat-default .chat-container .chat-messages .chat-messages-header {
    width: calc(100% - 3rem);
    margin: 0 auto;
    height: 5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e5e5e5; }
    .chat-default .chat-container .chat-messages .chat-messages-header .chat-messages-header-title {
      font-family: var(--serif-font-family);
      font-size: 1.25rem;
      font-weight: 700; }
  .chat-default .chat-container .chat-messages .chat-messages-list {
    width: calc(100% - 3rem);
    margin: 0 auto;
    height: calc(100vh - 19rem);
    margin-top: 1rem;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem; }
    .chat-default .chat-container .chat-messages .chat-messages-list .chat-message {
      display: flex;
      align-items: flex-start;
      grid-gap: 0.5rem;
      gap: 0.5rem;
      width: 100%; }
      .chat-default .chat-container .chat-messages .chat-messages-list .chat-message .chat-message-avatar {
        width: 2rem;
        height: 2rem;
        aspect-ratio: 1/1;
        border-radius: 0.3125rem 0 0.3125rem 0.3125rem;
        object-fit: cover; }
      .chat-default .chat-container .chat-messages .chat-messages-list .chat-message .chat-message-avatar.mine {
        border-radius: 0 0.3125rem 0.3125rem 0.3125rem;
        background-color: #88a088; }
      .chat-default .chat-container .chat-messages .chat-messages-list .chat-message .chat-message-avatar.not-mine {
        border-radius: 0.3125rem 0 0.3125rem 0.3125rem;
        background-color: #f8f5f5; }
      .chat-default .chat-container .chat-messages .chat-messages-list .chat-message .chat-message-content {
        font-size: 0.75rem;
        font-weight: 300;
        padding: 1rem 1.5rem;
        color: #6d786e;
        background: #f8f5f5;
        border-radius: 0 0.625rem 0.625rem 0.625rem;
        max-width: 100%; }
      .chat-default .chat-container .chat-messages .chat-messages-list .chat-message .chat-message-content.mine {
        background: #88a088;
        color: var(--pure-white);
        border-radius: 0.625rem 0 0.625rem 0.625rem;
        margin-left: 2.5rem; }
      .chat-default .chat-container .chat-messages .chat-messages-list .chat-message .chat-message-content.not-mine {
        background: #f8f5f5;
        border-radius: 0 0.625rem 0.625rem 0.625rem;
        margin-right: 2.5rem; }
  .chat-default .chat-container .chat-messages .chat-messages-input-container {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 4.75rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1.5rem;
    border-radius: 1.875rem 1.875rem 0 0;
    border: 1px solid #f0ece8;
    background-color: var(--pure-white); }
    .chat-default .chat-container .chat-messages .chat-messages-input-container .chat-messages-input-wrapper {
      position: relative;
      width: 100%; }
      .chat-default .chat-container .chat-messages .chat-messages-input-container .chat-messages-input-wrapper .chat-messages-input {
        height: 2.375rem;
        width: 100%;
        border-radius: 2rem;
        border: 1px solid #e3e5e5;
        background-color: var(--pure-white);
        padding: 0 1rem;
        font-size: 0.875rem; }
      .chat-default .chat-container .chat-messages .chat-messages-input-container .chat-messages-input-wrapper svg {
        position: absolute;
        top: 50%;
        right: 1rem;
        -webkit-transform: translateY(-50%);
                transform: translateY(-50%);
        color: var(--primary-gray); }

