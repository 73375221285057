.rpm-alerts-wrapper {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 1.5rem;
    justify-content: flex-end;
    button {
        outline: none;
    }
    &__resend-invitation {
        font-size: 0.75rem;
        font-weight: 400;
        color: #000000;
        text-decoration: underline;
        margin-right: -0.25rem;
    }
    .rpm-alerts-wrapper-alert {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        position: relative;
        .rpm-alerts-wrapper-alert-icon-container {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            .rpm-alerts-wrapper-alert-icon-square {
                width: 1.25rem;
                height: 1.25rem;
                aspect-ratio: 1/1;
                background-color: #e8744f;
                border-radius: 0.1875rem;
                transform: rotate(45deg);
            }
            .rpm-alerts-wrapper-alert-icon {
                color: $white;
                font-size: $font-size-smallest;
                font-weight: 800;
                z-index: 10;
                position: absolute;
            }
        }
        .rpm-alerts-wrapper-alert-text-container {
            min-width: 5.0625rem;
            height: 1.5rem;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #f9dad0;
            border-radius: 0.25rem;
            margin-left: -0.5rem;
            cursor: pointer;
            .rpm-alerts-wrapper-alert-text {
                color: #e8744f;
                font-size: $font-size-smallest;
                font-weight: 600;
                text-align: center;
            }
        }

        .rpm-alerts-wrapper-alert-popup {
            height: 18rem;
            width: 16rem;
            background-color: $white;
            display: flex;
            flex-direction: column;
            align-items: center;
            position: absolute;
            top: -9rem;
            left: -17rem;
            border-radius: 0.5rem;
            box-shadow: 0px 8px 13px rgba(0, 0, 0, 0.2);
            z-index: 999;
            .rpm-alerts-wrapper-alert-popup-header {
                background-color: #e8744f;
                height: 2.5rem;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                border-radius: 0.25rem 0.25rem 0 0;
                padding: 0 1rem;
                .rpm-alerts-wrapper-alert-popup-header-title-container {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    gap: 0.5rem;
                    .rpm-alerts-wrapper-alert-popup-header-title {
                        font-size: $font-size-small;
                        font-weight: 600;
                        color: $white;
                    }
                }
                .rpm-alerts-wrapper-alert-popup-header-clear {
                    font-size: $font-size-smallest;
                    font-weight: 600;
                    color: $white;
                    cursor: pointer;
                    text-decoration: underline;
                }
            }
            .rpm-alerts-wrapper-alert-popup-body {
                height: 15.5rem;
                max-height: 15.5rem;
                width: 100%;
                position: relative;
                display: flex;
                flex-direction: column;
                align-items: center;
                padding-top: 0.5rem;

                .right-triangle {
                    position: absolute;
                    top: 6.75rem;
                    left: 100%;
                    width: 0;
                    height: 0;
                    border-top: 0.5rem solid transparent;
                    border-bottom: 0.5rem solid transparent;
                    border-left: 0.5rem solid $white;
                }
                .rpm-alerts-wrapper-alert-popup-body-item {
                    min-height: 3rem;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 100%;
                    padding: 0 1rem;
                    border-bottom: 1px solid #f0ece8;
                    .rpm-alerts-wrapper-alert-popup-body-item-left {
                        display: flex;
                        align-items: center;
                        gap: 0.5rem;
                        justify-content: flex-start;
                        p {
                            font-size: $font-size-small;
                            font-weight: 400;
                            color: $color-dark;
                        }
                    }
                    .rpm-alerts-wrapper-alert-popup-body-item-right {
                        display: flex;
                        align-items: center;
                        gap: 0.5rem;
                        justify-content: flex-end;
                        .rpm-alerts-wrapper-alert-popup-body-item-right-text {
                            padding: 0.5rem;
                            width: 2rem;
                            height: 1.5rem;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            background: rgba(232, 116, 79, 0.27);
                            border-radius: 0.25rem;
                            p {
                                font-size: 0.75rem;
                                font-weight: 600;
                                color: #e8744f;
                            }
                        }
                        .rpm-alerts-wrapper-alert-popup-body-item-right-date {
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            p {
                                color: #908e87;
                                font-weight: 400;
                                font-size: $font-size-smallest;
                            }
                        }
                    }
                }
                .rpm-alerts-wrapper-alert-popup-body-item-view-profile {
                    text-decoration: underline;
                    font-size: 0.75rem;
                    cursor: pointer;
                    color: $color-dark;
                    font-weight: 600;
                    margin-top: 1rem;
                }
            }
        }
    }
    &__no-alerts {
        font-size: 0.625rem;
        font-weight: 600;
        color: rgba(89, 89, 89, 0.5);
    }
}
