@import '../../../styles/mixins';
.card {
  height: max-content;
}

.dashboard-roadmap-card {
  // @extend .mrm-white-on-white-box-shadow;
  &:hover {
    transform: scale(1.01);
    transition: all 0.2s ease-in-out;
  }

  max-height: max-content;
  box-shadow: 0px -4px 20px 1px rgba(0, 0, 0, 0.05);
  .roadmap-modal {
    display: flex;
    align-items: center;
    width: 100%;
    button {
      cursor: pointer;
      background-color: $color-dark;
      color: white;
      border-radius: 0.4rem;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      min-width: 1.625rem;
      min-height: 1.625rem;
    }
    padding-right: 1rem;
  }
  .card-progress-title {
    font-weight: bold;
    font-size: $font-size-small;
    color: $color-text-normal;
    max-width: 80%;
    font-family: $serif-font-family;
  }

  .progress {
    height: get-scaled-size($scale-factor-0_25);
    background-color: #dedede;
    .progress-bar {
      background-color: $color-orange;
      transition: width 0.6s ease;
      border-radius: 4px;
    }
  }

  .progress-description {
    font-size: $font-size-smallest;
    color: $color-text-lighter;
    margin: 0;
  }

  .roadmap-dot {
    position: absolute;
    left: -#{get-scaled-size($scale-factor-0_75)};
    transform: translateY(-50%);
    top: 50%;
  }

  .started-date {
    @extend .mrm-mt-1;
    font-size: $font-size-smaller;
    text-align: right;
    margin-bottom: 0;
  }
}

.card-progress-title {
  color: $color-dark;
}
.dashboard-roadmap-card {
  background-color: $white;
}

.delete-confirmation-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  .modal-header {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    h1 {
      width: 100%;
      font-size: $font-size-largest;
      text-align: center;
      font-family: $serif-font-family;
      line-height: normal;
      font-weight: 600;
    }
  }
  .modal-body {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
    .description {
      width: 70%;
      text-align: center;
      font-size: $font-size-normal;
      font-weight: 400;
    }
    .remove {
      width: max-content;
      padding: 1rem;
      color: $white;
      background-color: $color-dark;
      border-radius: 0.4rem;
    }
    .go-back {
      text-decoration: underline;
      font-weight: 400;
    }
  }
}
