.chat-page {
  .chat-page-wrapper {
    .content {
      .conversations {
        .item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          cursor: pointer;
          padding: 1rem 2rem;
          position: relative;

          .photo-and-name {
            display: flex;
            flex-direction: row;
            gap: 0.5rem;
            align-items: center;
            img {
              width: 3.7rem;
              height: 4rem;
              object-fit: contain;
              border-radius: 0.5rem;
              border-end-end-radius: 0;
            }
            .name-and-time {
              h3 {
                font-weight: 500;
                font-size: $font-size-normal;
              }
              p {
                font-size: $font-size-smallest;
                color: $color-gray;
              }
            }
          }
          .number-of-messages {
            display: flex;
            align-items: center;
            gap: 0.5rem;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: $color-alerts;
          }
        }
      }
    }
  }
}
