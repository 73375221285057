.dashboard-coach-add-calls-page-modal {
  .modal-content {
    background-color: $color-white;
  }
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    margin-top: 1.5rem;
    h2 {
      font-family: $sans-serif-font-family;
      font-size: 1.5rem;
      font-weight: 500;
    }
  }
  .form-control {
    border-radius: 0.5rem;
    border: 1px solid $color-gray-lighter;
    display: flex;
    align-items: center;
    height: 45px;
    font-weight: 300;
    height: 3rem;
    padding: 1rem;
    font-size: $font-size-small;
    box-shadow: none;
  }
  label {
    font-size: $font-size-small;
    font-weight: 300;
    padding-bottom: 0.5rem;
    color: $color-gray;
  }
  .date-picker-cal {
    display: flex;
    gap: 0.5rem;
    align-items: center;
  }
  .error-message {
    color: $color-red;
    font-size: $font-size-smallest;
    margin: 0.5rem 0;
    font-weight: 700;
  }
  .call-types {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 0.5rem;
    margin: 1rem 0;
    margin-top: 0.5rem;
    .call-type {
      width: 100%;
      height: 2.5rem;
      border-radius: 0.25rem;
      background-color: #d3d0cc;
      display: flex;
      align-items: center;
      gap: 0.5rem;
      padding: 1rem;
      cursor: pointer;
    }

    .active {
      border: 1px solid #e8744f;
      background-color: $white;
    }
  }

  .modal-content {
    background-color: $color-white;
  }
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    margin-top: 1.5rem;
    h2 {
      font-family: $sans-serif-font-family;
      font-size: 1.5rem;
      font-weight: 300;
    }
  }
  .form-control {
    border-radius: 0.5rem;
    border: 1px solid $color-gray-lighter;
    display: flex;
    align-items: center;
    height: 45px;
    font-weight: 300;
    height: 3rem;
    padding: 1rem;
    font-size: $font-size-small;
    box-shadow: none;
  }
  .form-label {
    font-size: $font-size-small;
    font-weight: 300;
    padding-bottom: 0.5rem;
    color: $color-gray;
  }

  .date-picker-cal {
    display: flex;
    gap: 0.5rem;
    align-items: center;
  }
  .error-message {
    color: $color-red;
    font-size: $font-size-smallest;
    margin: 0.5rem 0;
    font-weight: 700;
  }

  .delete-btn {
    width: max-content;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 1rem;
    background-color: $color-dark;
    color: $white;
    border-radius: 0.5rem;
    margin: 1.5rem;
  }

  .use-upheal-link {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 0.5rem;
    margin-top: -0.5rem;
    margin-bottom: 1rem;
    button {
      outline: none;
      height: 1rem;
      display: flex;

      align-items: center;
    }
    p {
      font-size: 0.75rem;
      font-weight: 300;
      color: $color-dark;
    }
  }
}
.intervals {
  display: flex;
  align-items: center;
  gap: 1rem;
  button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 0.75rem;
  }
}
