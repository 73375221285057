@import '../../styles/mixins';

.home-log-in-page {
  min-height: 100vh;
  p {
    text-align: center;
  }
  .validation {
    color: $color-alerts;
    padding-top: 1rem;
    font-size: $font-size-smaller;
    font-weight: 500;
    text-align: left;
  }

  .form-group {
    .svg-inline--fa {
      position: absolute;
      @extend .mrm-ml-0_5;
      @extend .mrm-mt-0_5;
      color: $color-gray;
    }
  }

  .legal-info {
    a {
      color: $color-text-light;
      font-weight: normal;
    }
    font-size: $font-size-small;
  }

  .form-control {
    @extend .mrm-pl-3;
  }

  .desktop-form-container {
    @extend .mrm-mt-6;
    padding-bottom: 2.5rem;
  }
  .common-header {
    padding-top: 32px;
  }
}

.home-log-in-page {
  background-color: $color-white;
  .common-header {
    background-color: #f7f5f5 !important;

    a,
    .common-header .back-link {
      color: $color-dark;
    }
    svg {
      color: $color-dark !important;
    }
  }

  .desktop-form-container {
    background-color: $color-gray-lighter;
    padding-bottom: 2.5rem;
  }

  h1 {
    color: $color-dark;
  }
  .btn-primary {
    border: #f6f6f6 1px solid;
    background-color: $color-dark;
    font-weight: normal;
    color: $color-white;
  }
  .legal-info {
    a {
      color: $color-text-light;
      font-weight: bold;
    }
    font-size: $font-size-small;
  }
}
