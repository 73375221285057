.rpm-provider-dashboard,
.rpm-coach-dashboard {
    .rpm-provider-container,
    .rpm-coach-container {
        .rpm-provider-cards-container {
            height: 9.75rem;
            width: 100%;
            background-color: $color-dark;
            border-radius: 0.625rem;
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: 1fr;
            padding: 1.125rem 0;
            .rpm-provider-card {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;
                width: 100%;
                padding-left: 2rem;
                gap: 0.5rem;
                border-right: 1px solid #000000;
                .rpm-provider-card-header {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    gap: 0.5rem;
                    .rpm-provider-card-header-icon {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 1.5625rem;
                        min-width: 1.5625rem;
                        aspect-ratio: 1/1;
                        border-radius: 50%;
                        background-color: #231f20;
                    }
                    .rpm-provider-card-header-text {
                        color: #908e87;
                        font-weight: 500;
                    }
                }
                .rpm-provider-card-number-container {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    gap: 0.5rem;
                    .rpm-provider-card-number {
                        font-family: $sans-serif-font-family;
                        font-weight: 600;
                        font-size: 2.25rem;
                        color: $white;
                    }
                    .rpm-provider-card-percentage {
                        width: 2.93rem;
                        height: 1.875rem;
                        border-radius: 1.5rem;
                        background-color: #231f20;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        .rpm-provider-card-percentage-text {
                            color: #908e87;
                            font-size: $font-size-small;
                        }
                    }
                }
                .rpm-provider-card-footer {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    gap: 0.5rem;
                    .rpm-provider-card-footer-icon {
                        transform: rotate(45deg);
                    }
                    .rpm-provider-card-footer-text {
                        font-size: $font-size-small;
                        color: #908e87;
                    }
                }
            }
        }
        .rpm-provider-mobile-summary-card {
            height: 15.125rem;
            width: 100%;
            background-color: $color-dark;
            display: flex;
            flex-direction: column;
            border-radius: 0 0 2rem 2rem;
            padding: 2rem 1.5rem;
            h1 {
                font-size: 1.5rem;
                color: $white;
                font-weight: 700;
            }
            &__label-and-value {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: flex-end;
                width: 100%;
                margin-top: 2rem;
                &__label {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    align-items: flex-start;
                    gap: 0.5rem;
                    &__icon-container {
                        height: 2rem;
                        width: 2rem;
                        aspect-ratio: 1/1;
                        border-radius: 50%;
                        background-color: #231f20;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                    p {
                        color: $white;
                        font-weight: 500;
                    }
                }
                &__value {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    align-items: flex-end;
                    h2 {
                        color: $white;
                        font-weight: 600;
                        font-size: 2.5rem;
                    }
                    &__description {
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-end;
                        align-items: center;
                        gap: 0.5rem;
                        p {
                            font-size: 0.875rem;
                            color: #908e87;
                            font-weight: 600;
                        }
                    }
                }
            }
            &__steps {
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0 auto;
                margin-top: 2rem;
                gap: 0.5rem;

                &__step {
                    width: 0.875rem;
                    height: 3px;
                    background-color: #231f20;
                }

                &__step.active {
                    background-color: #908e87;
                }
            }
        }
    }
}
