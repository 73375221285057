@import '../../../../../styles/mixins.scss';

$sidebar-width: 35vw;
$content-width: 65vw;

$sidebar-width-tablet: 50vw;
$content-width-tablet: 50vw;

.rpm-signup-desktop {
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: $sidebar-width $content-width;
    @include media-breakpoint-down(md) {
        grid-template-columns: $sidebar-width-tablet $content-width-tablet;
    }
    min-height: 100vh;
    overflow: hidden;

    .sidebar {
        width: 100%;
        display: flex;
        flex-direction: column;
        background-color: $color-dark;
        height: 100%;
        max-height: 100%;

        #logo {
            position: absolute;
            top: 2rem;
            left: 2rem;
            width: 6.6975rem;
            object-fit: contain;
        }

        .sidebar-content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            height: 100%;
            padding: 0 2rem;
            width: 100%;
            gap: 1rem;

            #rpm-logo {
                height: 3rem;
                object-fit: contain;
            }
            #overhead-icon {
                height: 2.5rem;
                object-fit: contain;
            }

            h1 {
                font-size: 2rem;
                font-weight: 700;
                color: $white;
            }

            .description {
                font-weight: 300;
            }

            .description-bold {
                font-weight: 600;
            }

            p {
                color: $white;
            }

            .label {
                font-weight: 600;
            }

            .form {
                display: flex;
                flex-direction: column;
                gap: 1rem;
                width: 100%;
                .input-wrapper {
                    display: flex;
                    flex-direction: column;
                    gap: 0.5rem;
                    width: 100%;
                    position: relative;

                    input {
                        width: 100%;
                        height: 3rem;
                        border: none;
                        border-radius: 0.5rem;
                        padding: 0 1rem;
                        font-size: 1rem;
                        color: $color-dark;
                        background-color: $white;
                        padding-left: 3rem;
                    }
                    .lock {
                        position: absolute;
                        top: 0.7rem;
                        left: 0.8rem;
                        height: 1.5rem;
                        object-fit: contain;
                    }
                }

                .simple-input {
                    padding-left: 1rem;
                    &::placeholder {
                        font-weight: 300;
                    }
                }

                .error-message {
                    color: $color-alerts;
                    font-size: $font-size-smaller;
                    font-weight: 500;
                }

                .terms-and-conditions {
                    display: flex;
                    flex-direction: row;
                    align-items: flex-start;
                    gap: 1rem;
                    width: 100%;
                    &__text-wrapper {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        gap: 0.5rem;
                        margin-top: 0.3rem;
                        &__text {
                            font-size: 0.75rem;
                            font-weight: 600;
                            color: $white;
                            cursor: pointer;
                        }
                        ul {
                            padding-left: 1rem;
                            li {
                                font-size: 0.75rem;
                                font-weight: 400;
                                color: $white;
                            }
                            a {
                                cursor: pointer;
                                text-decoration: underline;
                                color: $white;
                            }
                        }
                    }
                }

                .password-requirements {
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    grid-template-rows: auto;
                    gap: .25rem;
                    width: 100%;
                    margin-top: 1rem;
                    .title {
                        grid-column: 1 / 3;
                        font-size: 0.75rem;
                        font-weight: 600;
                        margin-bottom: .25rem;
                    }
                    &__item {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        gap: 0.5rem;
                        width: 100%;
                        &__icon {
                            object-fit: contain;
                            font-size: .75rem;
                            color: #e8744f;
                        }
                        &__text {
                            font-size: 0.75rem;
                            font-weight: 400;
                            color: $white;
                            white-space: nowrap;
                        }
                    }
                }

                button {
                    width: 100%;
                    height: 3rem;
                    border: none;
                    border-radius: 0.5rem;
                    font-size: 1rem;
                    font-weight: 600;
                    color: $color-dark;
                    background-color: $white;
                    cursor: pointer;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                .react-tel-input {
                    input {
                        width: 100%;
                    }
                    .flag-dropdown {
                        background: $white;
                        border: none;
                        border-radius: 0.525rem;
                        .selected-flag {
                            background: transparent;
                            padding-left: 1rem;
                        }
                    }
                    .country-list {
                        box-shadow: 0px 4px 20px 1px rgba(0, 0, 0, 0.1);
                        border-radius: 0.525rem;
                        li {
                            font-weight: 300;
                        }
                    }
                }

                .address-container,
                .insurance-info-container {
                    width: 100%;
                    background: $white;
                    height: 9.9375rem;
                    border-radius: 0.625rem;
                    position: relative;
                    padding: 2rem;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    p {
                        color: $color-dark;
                    }
                    .address-title,
                    .insurance-info-title {
                        font-weight: 600;
                        font-size: 1.125rem;
                        margin-bottom: 0.5rem;
                    }

                    .address,
                    .insurance-info {
                        font-weight: 300;
                        font-size: 1rem;
                        color: #6d786e;
                    }

                    button {
                        position: absolute;
                        top: 1.5rem;
                        right: 2rem;
                        background: transparent;
                        border: none;
                        cursor: pointer;
                        height: 1.5rem;
                        width: 1.5rem;
                        object-fit: contain;
                        text-decoration: underline;
                        font-size: 500;
                    }
                }
            }
            .date-picker-container {
                display: flex;
                align-items: center;
                flex-direction: row;
                gap: 1rem;
                .small-input,
                .medium-input {
                    width: 4.4375rem;
                    height: 2.8125rem;
                    padding-left: 0.5rem;
                    outline: none;
                }
                .medium-input {
                    width: 5.625rem;
                }
            }
            .genders-grid {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                grid-template-rows: 1fr 1fr;
                gap: 1rem;

                button {
                    border-radius: 0.625rem;
                    border: 1px solid #f2f1ec;
                    width: 100%;
                    background-color: transparent;
                    height: 2.8125rem;
                    color: #f2f1ec;
                    font-weight: 300;
                    outline: none;
                }

                .prefer-not-to-say {
                    grid-column: 1 / 3;
                }

                .selected {
                    background-color: $white;
                    color: $color-dark;
                    font-weight: 600;
                }

                .next-button {
                    background-color: $white;
                    color: $color-dark;
                }
            }
        }
    }

    .content {
        background-color: $color-white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100vh;
        max-height: 100vh;
        padding: 2rem;

        @include media-breakpoint-down(md) {
            padding: 1rem;
        }

        width: 100%;
        .carousel {
            max-width: 50rem;
            width: 100%;
            height: 100%;
            display: flex;
            flex-wrap: nowrap;
            justify-content: flex-start;
            overflow: hidden;
            gap: 0;
            grid-gap: 0;
            box-shadow: 0px -4px 20px 1px rgba(0, 0, 0, 0.05);
            border-radius: 0.65rem;

            .carousel-item {
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                .photo-wrapper {
                    width: 100%;
                    height: 60%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: relative;
                    .photo {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                    .arrow-wrapper {
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        width: 100%;
                        height: 100%;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        .left-arrow {
                            width: 3rem;
                            height: 3rem;
                            border-radius: 0px 8px 8px 0px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            background: rgba(255, 255, 255, 0.3);
                            outline: none;
                        }
                        .right-arrow {
                            width: 3rem;
                            height: 3rem;
                            border-radius: 0px 8px 8px 0px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            background: rgba(255, 255, 255, 0.3);
                            transform: rotate(180deg);
                            outline: none;
                        }
                    }
                }

                .carousel-item-content {
                    background-color: $white;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    gap: 1rem;
                    width: 100%;
                    height: 100%;
                    .icon {
                        width: 2rem;
                        height: 2rem;
                        object-fit: contain;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                    .title {
                        font-size: 1.5rem;
                        font-weight: 700;
                        color: $color-dark;
                        text-align: center;
                    }
                    .description {
                        font-size: 14px;
                        font-weight: 300;
                        color: $color-dark;
                        width: 70%;
                        text-align: center;
                    }
                    .line-container {
                        gap: 3px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-top: 1rem;

                        .line {
                            width: 14px;
                            height: 3px;
                            border-radius: 0.5rem;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }

    .background-image {
        height: 100vh;
        width: 100%;
        object-fit: cover;
    }
}
