.action-item-responses {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: auto;
  gap: 1.5rem;
  padding-bottom: 1rem;
  overflow-x: hidden;
  @include media-breakpoint-down(md) {
    height: 50vh;
  }
}

.left-border {
  border-left: 3px solid #e5e5e5;
  padding-left: 1rem;
  margin-left: 1.5rem;
  width: calc(100% - 3rem);
}
