@import '../../../../../styles/mixins';

.custom-select-input-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  .error {
    color: $color-red;
    font-size: $font-size-smallest;
    margin-top: 0.5rem;
  }
}

.custom-select-input-container.coaching-group {
  align-items: center;
}
