.change-payment-method-modal {
  .modal-header {
    padding: 2rem 2rem 0rem 2rem;
    .modal-title {
      font-size: $font-size-largest;
      font-weight: 700;
      margin: 0 auto;
      font-family: $serif-font-family;
    }
  }
  .modal-body {
    padding: 2rem 2rem;
    .success-body {
      display: grid;
      justify-items: center;
      margin: 0 auto;
      gap: 2rem;
      img {
        width: 20%;
      }
      p {
        text-align: center;
        margin-top: -1rem;
        font-weight: 300;
        max-width: 80%;
      }
      button {
        background-color: $color-dark;
        color: $color-white;
        border-radius: 0.8rem;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1rem 0;
        font-weight: 500;
      }
    }
    .credit-card {
      display: flex;
      justify-content: space-between;
      align-items: center;
      p {
        font-weight: 500;
      }
      .companies {
        display: flex;
        align-items: center;
        gap: 0.5rem;
      }
    }
    form {
      display: flex;
      flex-direction: column;
      button {
        background-color: $color-dark;
        color: $color-white;
        font-weight: 500;
        font-size: $font-size-normal;
        text-align: center;
        padding: 1rem;
        border-radius: 0.8rem;
        width: 100%;
        margin: 0rem auto;
        margin-top: 2rem;
      }
      .error-message {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin: 0rem auto;
        background-color: rgba(196, 114, 88, 0.3);
        color: $color-red;
        padding: 1rem;
        border-radius: 0.8rem;
        margin-top: 2rem;
      }
    }
  }
}
