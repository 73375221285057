.clients-card-container {
  width: 100%;
  padding: 2rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: $color-white;
  border-radius: 10px;
  overscroll-behavior: contain;

  .clients-card-header {
    width: calc(100% - 3.75rem);
    margin: 0 auto;
    h3 {
      font-size: $font-size-normal;
      font-weight: 300;
    }
    strong {
      font-size: $font-size-normal;
      font-weight: 700;
      color: #515151;
    }
    .add-client {
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 26px;
      height: 26px;
      background-color: $color-dark;
      border-radius: 5px;
      outline: none;
    }
  }
  .filters-wrapper {
    display: flex;
    width: calc(100% - 3.75rem);
    margin: 0 auto;
    gap: 1rem;
    .search-wrapper {
      position: relative;
      width: 100%;
      display: flex;
      flex-direction: column;
      margin: 0 auto;
      .search-input {
        width: 100%;
        height: 32px;
        font-size: 12px;
        font-weight: 500;
        color: $color-dark;
        padding-left: 2.5rem;
        border-radius: 5px;
        border: none;
        &::placeholder {
          font-size: 12px;
          font-weight: 500;
          color: $color-dark;
          opacity: 0.5;
        }
      }
      .search-icon {
        position: absolute;
        top: 0.85rem;
        left: 1rem;
      }
    }
    .filter-wrapper,
    .sort-wrapper {
      background-color: $white;
      border-radius: 5px;
      display: flex;
      align-items: center;
      width: 30%;
      padding: 0.75rem;
      gap: 0.5rem;
      position: relative;
      cursor: pointer;
      span {
        font-size: 12px;
        font-weight: 500;
        color: $color-dark;
      }
    }
    .sort-toast {
      position: absolute;
      top: 0;
      right: 0;
      height: auto;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      background-color: $white;
      border-radius: 10px;
      width: 345px;
      box-shadow: 0px 20px 20px 1px rgba(0, 0, 0, 0.05);
      z-index: 998;
      padding: 0;
      .title {
        font-size: 12px;
        font-weight: 600;
        color: #555555;
        margin: 1rem auto;
        width: calc(100% - 3rem);
        text-align: center;
        padding: 0 1.5rem;
        padding-bottom: 1rem;
        border-bottom: 1px solid rgba(229, 229, 229, 0.5);
      }

      .sort-toast-item {
        display: flex;
        align-items: center;
        gap: 1rem;
        width: 100%;
        height: 4rem;
        padding: 1rem 1.5rem;
        span {
          font-size: $font-size-small;
          font-weight: 400;
        }
      }
    }

    .filter-toast {
      display: flex;
      position: absolute;
      top: 0;
      right: 0;
      flex-direction: column;
      align-items: center;
      background-color: $white;
      border-radius: 10px;
      width: 345px;
      height: auto;
      max-height: 60vh;
      overflow-y: auto;
      box-shadow: 0px 20px 20px 1px rgba(0, 0, 0, 0.05);
      z-index: 998;

      .title {
        font-size: 12px;
        font-weight: 600;
        color: #555555;
        padding: 1rem 0;
        position: sticky;
        top: 0;
        margin: 0 auto;
        background-color: $white;
        width: calc(100% - 3rem);
        text-align: center;
        border-bottom: 1px solid rgba(229, 229, 229, 0.5);
      }
      .filter-toast-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 1rem;
        width: 100%;
        min-height: 4rem;
        padding: 1rem 1.5rem;
        overflow: hidden;
        overscroll-behavior: contain;
        p {
          font-size: $font-size-small;
          font-weight: 400;
          color: $color-dark;
        }
        strong {
          font-size: $font-size-small;
          font-weight: 600;
          color: $color-dark;
        }
      }
      .list-toast {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        width: 100%;
        .list-toast-item {
          padding: 0.5rem 1.5rem;
          display: flex;
          align-items: center;
          gap: 0.5rem;
          height: max-content;
          background-color: transparent;
          width: 100%;
          p {
            font-size: $font-size-small;
            font-weight: 400;
            color: $color-dark;
            width: 80%;
          }
        }
        .divider {
          margin: 0.5rem auto;
          width: calc(100% - 3rem);
        }
      }
      .filter-buttons {
        display: flex;
        gap: 1rem;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 1rem 1.5rem;
        background-color: $white;
        position: sticky;
        bottom: 0;
        button {
          width: 100%;
          padding: 0.65rem;
          font-size: $font-size-small;
          font-weight: 500;
          border-radius: 10px;
          outline: none;
        }
        .apply-button {
          background-color: $color-dark;
          color: $color-white;
          border: none;
        }
        .clear-button {
          background-color: transparent;
          color: $color-dark;
          border: 1px solid $color-dark;
        }
      }
    }
  }
  .user-card-column {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    max-height: 60vh;
    overflow-y: auto;
    margin-top: 1rem;

    .user-card {
      width: calc(100% - 3.75rem);
      margin: 0 auto;
      margin-top: 1rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding-bottom: 1rem;
      background-color: $white;
      border-radius: 10px;
      box-shadow: 0px -4px 20px 1px rgba(0, 0, 0, 0.05);

      .clients-card-subscription-and-last-login {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 0.55rem 1rem;
        .clients-card-subscription {
          .subscription-tag {
            .circle {
              height: 5px;
              width: 5px;
              border-radius: 50%;
              background-color: #88a088;
            }
            font-size: 10px;
            font-weight: 500;
            padding: 7px 8px;
            border: 1px solid #88a088;
            color: #88a088;
            width: max-content;
            border-radius: 3rem;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 4px;
            @include media-breakpoint-down(sm) {
              font-size: 8px;
              padding: 4px 7px;
            }
          }
        }
        .clients-card-last-login {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          gap: 0.5rem;

          .title {
            font-size: 10px;
            color: #515151;
            font-weight: 600;
            margin-left: 4px;
            @include media-breakpoint-down(sm) {
              font-size: 8px;
            }
          }
          .last-seen {
            font-size: 10px;
            color: $white;
            font-weight: 600;
            padding: 5px 9px;
            background: #c9a750;
            border-radius: 3rem;
            @include media-breakpoint-down(sm) {
              font-size: 8px;
            }
          }
          .last-ai {
            font-size: 10px;
            color: #969492;
            font-weight: 600;
            padding: 5px 9px;
            background: #e5e5e5;
            border-radius: 3rem;
            @include media-breakpoint-down(sm) {
              font-size: 8px;
            }
          }
        }
      }

      .user-card-header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 1rem;

        .avatar-and-name {
          display: flex;
          align-items: center;
          gap: 0.5rem;
          cursor: pointer;
          .email {
            font-size: 0.625rem;
            margin-top: -0.45;
            padding-bottom: 0.45rem;
          }
        }
      }

      .user-card-roadmaps-container {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        width: 100%;
        margin-bottom: 1rem;
        padding: 0 1rem;
        .user-card-roadmaps {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          cursor: pointer;
          .roadmap-title,
          .roadmap-description {
            font-size: 12px;
            font-weight: 600;
            color: #555555;
          }
          .roadmap-description {
            font-weight: 300;
          }
        }
      }
    }
  }
}

.user-card-skeleton {
  height: 3rem;
  width: calc(100% - 3.75rem);
  margin: 0 auto;
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
}

.skeleton {
  animation: skeleton-loading 1s linear infinite alternate;
}

.container-skeleton {
  animation: container-skeleton-loading 1s linear infinite alternate;
}

@keyframes container-skeleton-loading {
  0% {
    background-color: $color-white;
  }
  100% {
    background-color: $white;
  }
}

@keyframes skeleton-loading {
  0% {
    background-color: $white;
  }
  100% {
    background-color: #fafafa;
  }
}
