@import '../../styles/mixins';

.home-reset-password-page {
  min-height: 100vh;
  .common-header {
    background-color: transparent;
  }
  .avatar {
    width: 2.25rem;
    height: 2.25rem;
    border-radius: 50%;
    background-color: #a768b6;
    color: white;
    text-align: center;
    padding-top: 5px;
    display: inline-block;
  }
}
