@import '../../styles/mixins';

.auth-google-calendar {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  @include media-breakpoint-down(sm) {
    height: 100%;
  }
  margin: 0 auto;
  overflow: hidden;
  background-color: $white;
  .auth-google-calendar-navbar {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $white;
    padding: 1.25rem 7.5%;
    z-index: 1;
    img {
      width: 7.125rem;
      object-fit: contain;
    }
    border-bottom: 0.5px solid #c4c4c4;
    margin: 0 auto;
    img {
      width: 7.125rem;
      object-fit: contain;
    }

    @include media-breakpoint-down(md) {
      display: grid;
      grid-template-columns: 1fr;
      padding: 0;
      place-content: center;
      z-index: 1;
      width: 100%;
    }

    &__logo-container {
      display: flex;
      align-items: center;
      justify-content: center;
      @include media-breakpoint-down(md) {
        height: 65px;
        box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
        width: 100%;
      }
    }
  }

  .auth-google-calendar-content {
    position: relative;
    background-size: cover;
    object-fit: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    background-color: $white;
    video,
    .background-image {
      position: absolute;
      top: 0;
      z-index: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      background-position: contain;
      transform: scaleX(-1);
      @include media-breakpoint-down(sm) {
        filter: blur(6px);
      }
    }

    .background-image {
      transform: scaleX(1);
    }

    .auth-google-calendar-content-inner {
      z-index: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;
      min-width: 85vw;
      width: 85vw;
      height: 80vh;
      border-radius: 1.25rem;
      background-color: $white;

      h1 {
        font-family: $sans-serif-font-family;
        font-weight: 600;
        font-size: 1.25rem;
        margin: 1rem 0;
      }

      .description {
        color: #838383;
        font-size: 0.875rem;
        width: 70%;
        text-align: center;
        padding-bottom: 1rem;
      }
      &__done {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 2.1rem;
        height: 2.1rem;
        width: 100%;
        max-width: 290px;
        background-color: #ebb551;
        border-radius: 0.3125rem;
        margin: 1.5rem 0;

        p {
          text-align: center;
          color: $white;
          font-weight: 700;
        }

        &:disabled {
          opacity: 0.5;
          cursor: not-allowed;
        }
      }
    }
  }
}

.greminders__booking-widget-overlay .greminders__booking-widget-close {
  display: none !important;
}

.greminders__booking-widget-overlay.greminders__booking-widget-overlay
  .greminders__booking-widget-popup {
  box-shadow: none !important;
}
.greminders__booking-widget-overlay {
  background: none !important;
}

.greminders__booking-widget-overlay.greminders__booking-widget-overlay
  .greminders__booking-widget-popup {
  width: 80vw !important;
  height: 80vh !important;

  @include media-breakpoint-down(md) {
    width: 100vw !important;
    margin-top: 0 !important;
    height: 100vh !important;
  }
}

#auth-google-calendar-content {
  .greminders__booking-widget {
    display: block !important;

    height: 100vh !important;

    .greminders__booking-widget-overlay {
      position: relative !important;
      display: flex !important;
      flex-direction: column !important;
      justify-content: flex-start !important;
      padding-top: 1.5rem !important;
      @include media-breakpoint-down(md) {
        padding-top: 0 !important;
      }
    }
  }
}
