@import '../../styles/mixins';

.rpm-client-profile {
    &__container {
        max-width: 1140px;
        margin: 0 auto;
        margin-top: 1rem;
        margin-bottom: 1rem;
        min-height: calc(100vh - 7rem);
        width: 100%;
        overflow: hidden;
        border-radius: 0.625rem;
        padding: 2rem;
        display: grid;
        grid-template-areas:
            'client-profile-left client-profile-right client-profile-right client-profile-right'
            'client-profile-left client-profile-right client-profile-right client-profile-right'
            'client-profile-left client-profile-right client-profile-right client-profile-right'
            'client-profile-left client-profile-right client-profile-right client-profile-right';
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap: 1rem;

        @include media-breakpoint-down(sm) {
            grid-template-areas:
                'header'
                'client-profile-left'
                'client-profile-right'
                'client-profile-right'
                'client-profile-right';
            grid-template-columns: 1fr;
            margin-top: 0;
            padding: 0;
            // align vertical center grid
            place-content: center;
            width: 100%;
            gap: 1.5rem;
        }

        &__right {
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            grid-area: client-profile-right;
            gap: 1rem;
        }
    }
}
