@import '../../styles/mixins';

.rpm-fitbit-connection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  background-color: $color-dark;

  .rpm-wrapper {
    height: 90vh;
    width: 619px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @include media-breakpoint-down(sm) {
      width: 100%;
      height: 100vh;
    }

    .rpm-fitbit-connection-card {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      height: 100%;
      width: 100%;
      border-radius: 10px;
      overflow: hidden;
      @include media-breakpoint-down(sm) {
        border-radius: 0;
      }

      .rpm-fitbit-connection-card-header {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        min-height: 20%;
        width: 100%;
        position: relative;

        .go-back-button {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 50px;
          width: 50px;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 3;
          outline: none;
        }
        .background {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          @include media-breakpoint-down(sm) {
            height: 120%;
          }
          width: 100%;
          object-fit: cover;
          z-index: 2;
        }
        .logo {
          width: 202px;
          object-fit: contain;
          z-index: 3;
          @include media-breakpoint-down(sm) {
            width: 144px;
          }
        }
        .rpm-fitbit-progress-bar {
          position: absolute;
          bottom: 0;
          left: 0;
          height: 6px;
          @include media-breakpoint-down(sm) {
            top: 0;
            bottom: auto;
            height: 3px;
          }
          width: 100%;
          background-color: #595959;
          z-index: 3;
          .rpm-fitbit-progress-bar-fill {
            height: 100%;
            width: 30%;
            background-color: #e8744f;
            border-radius: 0 10px 10px 0;
          }
        }
      }

      .rpm-fitbit-connection-card-body {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        padding-top: 1.5rem;
        width: 100%;
        height: 100%;
        gap: 1rem;
        background-color: $color-white;
        position: relative;
        z-index: 5;
        @include media-breakpoint-down(sm) {
          border-radius: 0;
          border-top-left-radius: 30px;
          border-top-right-radius: 30px;
        }

        .step {
          display: flex;
          flex-direction: column;
          width: 100%;
          align-items: center;
          justify-content: center;
          position: absolute;
          top: 1.5rem;
          gap: 1rem;
        }

        .title {
          font-family: $sans-serif-font-family;
          font-size: 18px;
          font-weight: 500;
        }

        .logo {
          height: 40px;
          width: 40px;
          object-fit: contain;
        }
        .watch {
          height: 40px;
          object-fit: contain;
        }
        .noble-and-fitbit {
          height: 62px;
          object-fit: contain;
        }
        .description {
          width: 75%;
          text-align: center;
          font-weight: 400;
          color: #6b6b6b;
        }
        .done-button {
          display: flex;
          align-items: center;
          justify-content: center;
          color: $white;
          background-color: $color-dark;
          height: 50px;
          width: 50%;
          border-radius: 10px;
          outline: none;
          font-weight: 500;
          cursor: pointer;
          user-select: none;
          @include media-breakpoint-down(sm) {
            width: 80%;
          }
        }
        .bottom-question {
          bottom: 2rem;
          position: absolute;
          text-align: center;
          width: 100%;
          text-decoration: underline;
          cursor: pointer;
          outline: none;
        }
      }
    }
  }
}
