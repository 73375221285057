.add-coach-modal {
  .modal-content {
    background-color: $white;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    padding: 3rem 5rem;

    form {
        min-width: 100%;
        
    }

    @include media-breakpoint-down(sm) {
      width: 85vw;
      margin: 0 auto;
      padding: 2rem 1.5rem;
    }

    h3 {
      font-size: 1.5rem;
      font-weight: 300;
      text-align: center;

      @include media-breakpoint-down(sm) {
        font-size: 1.25rem;
      }
    }

    .submit-btn {
        background-color: $color-dark;
        color: $white;
        font-size: 0.875rem;
        width: 100%;
        height: 2.5rem;
        border-radius: 0.25rem;
    }
  }
}
