.self-signup-progress {
  display: flex;
  align-items: center;
  gap: 90px;
    width: max-content;

  @include media-breakpoint-down(md) {
    gap: 17px;
    height: 85px;
    justify-content: center;
    width: 100%;
  }

  .step-number-and-label {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 9px;

    .step-number {
      width: 22px;
      height: 22px;
      border-radius: 50%;
      border: 2px solid #ebb551;
      @include media-breakpoint-down(md) {
        border: 1px solid #ebb551;
      }
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      p {
        font-size: 10px;
        font-weight: 600;
        color: #ebb551;
        text-align: center;
        @include media-breakpoint-down(md) {
          font-size: 9px;
        }
      }
      .step-line {
        width: 63px;
        height: 1px;
        background-color: #dadada;
        position: absolute;
        top: 50%;
        left: 90px;
        transform: translate(-50%, -50%);
        @include media-breakpoint-down(md) {
          left: 54px;
          width: 33px;
        }
      }
    }

    .step-label {
      font-size: 11px;
      font-weight: 500;
      color: rgba(52, 52, 52, 0.3);
      text-align: center;
      @include media-breakpoint-down(md) {
        font-size: 10px;
        line-height: 12px;
      }
    }
  }

  // last step line should be hidden
  .step:last-child .step-line {
    display: none;
  }

  .current {
    .step-label {
      color: rgba(52, 52, 52, 0.6);
    }
  }
}
