.discuss-container {
  .first-textarea-message {
    height: 30vh;
    padding: 1rem;
    width: 90%;
    margin: 0 auto;
    font-size: $font-size-small;
    // placeholder
    &::placeholder {
      color: $color-dark;
      opacity: 0.4;
      font-style: italic;
      font-size: $font-size-small;
    }

    // outline color
    &:focus {
      outline: none;
      border: 1.5px solid $color-dark;
    }
  }

  .btn-send-message {
    padding: 0.65rem;
    background-color: $color-dark;
    color: $white;
    font-weight: 500;
    border-radius: 10px;
    width: 60%;
    margin-left: 5%;
    margin-bottom: 1rem;

    &:disabled {
      background-color: transparent;
      color: $color-dark;
      border: 1px solid $color-dark;
    }
  }
}

@include media-breakpoint-down(md) {
  .discuss-container {
    .first-textarea-message {
      width: 100%;
      height: 60vh;
      font-size: 1rem;
    }
    .btn-send-message {
      width: 100%;
    }
  }
}
