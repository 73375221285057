.resend-subscription-modal {
  .modal-body {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    .resend-sub-btn {
      padding: 0.5rem 1rem;
      background-color: $color-dark;
      border-radius: 0.4rem;
      max-width: max-content;
      p {
        color: $white;
        font-size: $font-size-smaller;
      }
    }
  }
  .calendar-input {
    width: 100%;
    padding: 1rem;
    border: '1px solid $color-gray';
    color: '$color-gray';
  }

  .error-message {
    background-color: var(--primary-red);
    color: white;
    padding: 0.5rem;
    border-radius: 5px;
  }
}
