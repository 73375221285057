@import '../../../../../styles/mixins';

.completed-form-page {
  .completed-status-grid {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    .completed-item {
      display: flex;
      align-items: center;
      gap: 1rem;
    }
  }
  button,
  .create-new-client {
    width: 100%;
    background-color: $color-dark;
    color: $color-white;
    border-radius: 0.6rem;
    max-width: fit-content;
    padding: 0.5rem 1rem;
    font-size: $font-size-smallest;
  }

  .create-new-client {
    margin-top: 2rem;
    font-size: $font-size-small;
  }
}
