@import '../../styles/mixins';

.chat-default {
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    background-color: $color-bg-white;
    align-items: center;
    padding-top: 1rem;

    .chat-container {
        max-width: 1140px;
        margin: 0 auto;
        height: calc(100vh - 7rem);
        max-height: calc(100vh - 7rem);
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr;
        overflow: hidden;

        

       
    }
}