.action-item-modal {
  height: 90vh;
  @include media-breakpoint-down(sm) {
    height: 100%;
  }

  overflow-y: scroll;

  p.ai-notes {
    white-space: pre-line;
    font-weight: 500;
    font-size: $font-size-normal;
  }

  .action-item-header {
    padding: 2rem;
    .go-back {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      cursor: pointer;
      p {
        font-size: $font-size-large;
        font-weight: 300;
      }
    }
  }

  .approve-unapprove-btn {
    background-color: $color-dark;
    color: $color-white;
    border-radius: 0.6rem;
    padding: 0.5rem 1rem;
    font-weight: 500;
  }

  .action-item-body {
    height: 100%;
    @include media-breakpoint-down(sm) {
      height: 100%;
    }
    overflow-y: scroll;
    h1 {
      padding-bottom: 2rem;
    }
    .title-header-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include media-breakpoint-down(sm) {
        flex-direction: column;
        padding-bottom: 1rem;
        align-items: flex-start;
      }
      .title-header {
        display: flex;
        align-items: center;
        .go-back {
          cursor: pointer;
        }
        h1 {
          margin-bottom: 0;
          padding: 0;
        }
      }
    }
  }

  .primary-content {
    background-color: transparent;
    box-shadow: none;
    .ai-description {
      p,
      span {
        font-weight: 200;
      }
      figure {
        img {
          width: 100%;
        }
      }
    }
  }
  .action-item-modal-width {
    width: 50%;
    @include media-breakpoint-down(sm) {
      width: 100%;
      margin: 0 auto;
    }
    max-width: none !important;
  }
  .buttons {
    button {
      background-color: transparent;
      border: 1.5px solid $color-dark;
      color: $color-dark;
      font-weight: 500;
      border-radius: 0.75rem;
    }
  }
}

.roadmap-action-item-page {
  min-height: 100vh;
  @extend .mrm-pb-4;

  .ai-description {
    span {
      font-weight: 200;
    }
    padding-left: 1rem;
    p {
      font-size: $font-size-small;
      font-weight: 200;
    }
    overflow-wrap: break-word;
    @extend .mrm-py-1;

    img {
      max-width: calc(100% - 30px);
      margin: auto;
      display: block;
      border-radius: 10px;
    }
    .media {
      display: block;
    }
  }

  p.ai-notes {
    white-space: pre-line;
    font-weight: 300;
    font-size: $font-size-normal;
  }

  #formNotes {
    @extend .mrm-mt-0_75;
  }

  .action-button {
    width: 15em;
  }

  button {
    @extend .mrm-mt-0_75;
  }

  .upload-progress {
    width: 8em;
  }

  @include media-breakpoint-up(lg) {
    .form-control {
      border-color: #cccccc;
      &:active,
      &:focus {
        border-color: #6e9fe0;
      }
    }
  }

  .approve-unapprove-btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    min-width: 9rem;
    background-color: $color-dark;
    color: $color-white;
    @include media-breakpoint-down(lg) {
      margin-top: 0px !important;
    }
  }
}

.mrm-mt-0_75,
.user-components-profile-view .coach-card,
.roadmap-action-item-page #formNotes,
.roadmap-action-item-page button,
.chat-list-row:nth-of-type(1) {
  margin-top: 2px !important;
}

.rh5v-PlayPause_button {
  width: 50px !important;
  height: 50px !important;
}

.rh5v-Captions_button {
  width: 50px !important;
  height: 50px !important;
}

.rh5v-Volume_button {
  width: 50px !important;
  height: 50px !important;
}

.rh5v-Volume_icon {
  padding: 7px;
  width: 40px !important;
  height: 40px;
  margin-top: -20px;
}

.rh5v-Fullscreen_icon {
  padding: 5px;
  width: 40px;
  height: 40px;
  margin-top: -5px;
  margin-left: -5px;
}

.rh5v-PlayPause_icon {
  padding: 5px;
  width: 40px;
  height: 40px;
  margin-top: -20px;
  margin-left: -5px;
}
