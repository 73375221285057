.counter {
  padding: 1.5rem 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-radius: 1rem;

  h1 {
    font-size: 1.5rem;
    font-family: $sans-serif-font-family;
    font-weight: 600;
  }
  p {
    font-size: $font-size-smaller;
    margin-top: -0.5rem;
  }
}
.icon-wrapper-counter {
  height: 2rem;
  width: 2rem;
  min-height: 2rem;
  min-width: 2rem;
  background-color: $color-dark;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
