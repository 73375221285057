.chat-default {
    .chat-container {
        .chat-messages {
            display: flex;
            flex-direction: column;
            background-color: $white;
            border-radius: 0 0.5rem 0.5rem 0;
            position: relative;

            .chat-messages-header {
                width: calc(100% - 3rem);
                margin: 0 auto;
                height: 5rem;
                display: flex;
                align-items: center;
                justify-content: space-between;
                border-bottom: 1px solid #e5e5e5;

                .chat-messages-header-title {
                    font-family: $serif-font-family;
                    font-size: 1.25rem;
                    font-weight: 700;
                }
            }

            .chat-messages-list {
                width: calc(100% - 3rem);
                margin: 0 auto;
                height: calc(100vh - 19rem);
                margin-top: 1rem;
                overflow-y: auto;
                display: flex;
                flex-direction: column;
                gap: 1rem;

                .chat-message {
                    display: flex;
                    align-items: flex-start;
                    gap: 0.5rem;
                    width: 100%;

                    .chat-message-avatar {
                        width: 2rem;
                        height: 2rem;
                        aspect-ratio: 1/1;
                        border-radius: 0.3125rem 0 0.3125rem 0.3125rem;
                        object-fit: cover;
                    }

                    .chat-message-avatar.mine {
                        border-radius: 0 0.3125rem 0.3125rem 0.3125rem;
                        background-color: #88a088;
                    }

                    .chat-message-avatar.not-mine {
                        border-radius: 0.3125rem 0 0.3125rem 0.3125rem;
                        background-color: #f8f5f5;
                    }

                    .chat-message-content {
                        font-size: 0.75rem;
                        font-weight: 300;
                        padding: 1rem 1.5rem;
                        color: #6d786e;
                        background: #f8f5f5;
                        border-radius: 0 0.625rem 0.625rem 0.625rem;
                        max-width: 100%;
                    }

                    .chat-message-content.mine {
                        background: #88a088;
                        color: $white;
                        border-radius: 0.625rem 0 0.625rem 0.625rem;
                        margin-left: 2.5rem;
                    }

                    .chat-message-content.not-mine {
                        background: #f8f5f5;
                        border-radius: 0 0.625rem 0.625rem 0.625rem;
                        margin-right: 2.5rem;
                    }
                }
            }

            .chat-messages-input-container {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 4.75rem;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0 1.5rem;
                border-radius: 1.875rem 1.875rem 0 0;
                border: 1px solid #f0ece8;
                background-color: $white;

                .chat-messages-input-wrapper {
                    position: relative;
                    width: 100%;

                    .chat-messages-input {
                        height: 2.375rem;
                        width: 100%;
                        border-radius: 2rem;
                        border: 1px solid #e3e5e5;
                        background-color: $white;
                        padding: 0 1rem;
                        font-size: 0.875rem;
                    }

                    svg {
                        position: absolute;
                        top: 50%;
                        right: 1rem;
                        transform: translateY(-50%);
                        color: $color-gray;
                    }
                }
            }
        }
    }
}
