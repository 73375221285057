@import '../../styles/mixins';

.manage-user-page {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  justify-items: center;
  padding: 0 0 2rem 0;
  background-color: $color-bg-white;
  .user-page-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    margin: 1.5rem auto;
  }
  .desktop-view-container {
    display: none;
    flex-direction: column;
    width: 80%;
    overflow: hidden;
    justify-items: center;
    margin: 0 auto;
    .clients-coaches-container {
      width: 100%;
    }
  }

  .profile-card {
    @extend .mrm-my-2;
    padding: 2.2rem;
    border-radius: 1rem;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 1.5rem;
    background-color: $white;
    .profile-picture-container {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 1rem;
      position: relative;

      h1 {
        font-family: $serif-font-family;
      }

      p {
        padding: 0.2rem 1rem;
        font-size: $font-size-smallest;
        border-radius: 1rem;
      }

      .message-user {
        position: absolute;
        right: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          margin-left: -5px;
        }
        p {
          text-decoration: none;
          padding: 0;
        }
        gap: 0.2rem;
        border-radius: 5px;
        border: 1px solid $color-dark;
        padding: 0.5rem 0.75rem;
      }
    }
  }

  .bottom-price-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 2rem;

    .subscription-type {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      p {
        font-size: $font-size-smallest;
        padding: 0.3rem;
        background-color: $color-green;
        max-width: fit-content;
        border-radius: 0.5rem;
        color: $white;
        font-weight: 500;
      }
      h2 {
        font-size: $font-size-larger;
        color: $color-dark;
        font-weight: 300;
      }
    }

    .subscription-date {
      p {
        font-weight: 300;
      }
    }

    .price {
      p {
        font-weight: 600;
      }
    }

    .payment-buttons {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }

    button {
      border: 1px solid $color-dark;
      padding: 0.55rem 1.3rem;
      border-radius: 0.6rem;
      outline: none;
      font-size: $font-size-smaller;
      &:hover {
        background-color: $color-dark;
        color: $white;
      }

      &:disabled {
        cursor: not-allowed;
      }
    }
    .cancel-subscription {
      color: $color-white;
      background-color: $color-dark;
    }
  }

  .client-roadmaps {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    border-radius: 0.8rem;
    padding: 1.5rem;
    .roadmap {
      background-color: $white;
    }
    .client-roadmaps-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      p {
        font-size: $font-size-normal;
        font-weight: 500;
      }
      .plus-btn {
        padding: 0.4rem;
        display: flex;
        max-width: fit-content;
      }
    }
  }
}

.manage-user-page {
  .profile-card {
    background-color: $color-white;
  }
}

@include media-breakpoint-up(lg) {
  .manage-user-page {
    .client-roadmaps {
      background-color: $color-white;
    }
  }
}

@include media-breakpoint-up(lg) {
  .manage-user-page {
    .desktop-view-container {
      display: flex;
    }
    .client-roadmaps {
      width: 100%;
      padding: 2.5rem;
      background-color: $color-white;
    }
  }
  .profile-tab-content {
    .clients-coaches-container {
      width: 100%;
    }
  }
}

.recent-activity {
  padding: 1.5rem;
  h3 {
    padding-bottom: 1rem;
  }
  .recent-activity-description {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    p {
      font-size: $font-size-small;
      font-weight: 300;
      span {
        font-weight: 600;
      }
    }
    .recent-activity-title {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 0.5rem;
    }
  }
}
