@import '../../../../../styles/mixins';
.custom-text-input-container {
    display: flex;
    flex-direction: column;
    width: 100%;

    input {
        font-size: $font-size-small;
        font-weight: 300;
    }

    .error {
        color: $color-red;
        font-size: $font-size-smallest;
        margin-top: 0.5rem;
    }

    .message {
        color: $color-item-blue;
        font-size: $font-size-smallest;
        margin-top: 0.5rem;
    }
}
