.rpm-dashboard {
  .upcoming-growth-sessions {
    grid-area: upcoming-growth-sessions;
    background-color: $color-white;
    @include media-breakpoint-down(md) {
      background-color: transparent;
    }
    width: 100%;
    min-width: 30vw;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    padding: 1.5rem 0;
    h3 {
      font-size: $font-size-normal;
      font-weight: 500;
      width: calc(100% - 3rem);
      margin: 0 auto;
    }
    .description {
      color: rgba(81, 81, 81, 0.7);
      font-weight: 400;
      font-size: $font-size-small;
      width: calc(100% - 3rem);
      margin: 0 auto;
    }
    .sessions-container {
      margin-top: 1rem;
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 0.5rem;
      .session {
        height: 114px;
        box-shadow: 0px 4px 20px 1px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        width: calc(100% - 3rem);
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1rem;
        margin: 0 auto;
        .session-left {
          display: flex;
          align-items: center;
          gap: 0.5rem;
          .date {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 0 0.5rem;
            .day {
              font-size: 1.25rem;
              font-weight: 700;
              color: $color-dark;
            }
            .month {
              font-size: $font-size-small;
              font-weight: 600;
              color: $color-dark;
            }
          }
          img {
            height: 81.6px;
            width: 102px;
            object-fit: cover;
            box-shadow: 0px 4px 20px 1px rgba(0, 0, 0, 0.1);
            border-radius: 8px;
          }
        }
        .session-info {
          display: flex;
          flex-direction: column;
          width: 50%;
          .session-time {
            color: #838282;
            font-size: $font-size-smaller;
            font-weight: 600;
          }
          .session-title {
            font-weight: 400;
            font-size: $font-size-normal;
            color: $color-dark;
          }
        }
      }
    }
  }
}
