@import '../../styles/mixins';

.chat-page {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .chat-page-wrapper {
    display: grid;
    // background-color: rgba(255, 255, 255, 0.5);
    background-color: #fcfbfa;
    margin: 0 auto;
    transition: all 0.5s;
    margin-top: 0;
    align-items: center;
    border-radius: 0.5rem;
    position: relative;
    max-width: 1140px;
    height: 100vh;
    align-items: flex-start;

    @include media-breakpoint-up(lg) {
      margin-top: 1rem;
      height: auto;
      align-items: center;
    }

    @include media-breakpoint-down(sm) {
      z-index: 9999998;
    }

    .content {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      height: 80vh;
      // height: 100%;
      padding-bottom: 2.5rem;

      @include media-breakpoint-down(md) {
        height: 100vh;
        padding-bottom: 0;
      }
      width: 100%;
      background-color: $color-white;
      border-radius: 0.625rem 0 0 0.625rem;

      .conversations {
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        height: 100%;
        hr {
          height: 1px;
        }
      }
    }

    .messages-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 80vh;
      overflow-y: auto;
      @include media-breakpoint-down(md) {
        height: 100vh;
      }
    }
  }
}
