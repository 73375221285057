.chat-page {
  .chat-page-wrapper {
    .content {
      .content-search {
        display: flex;
        width: 90%;
        align-items: center;
        position: relative;
        margin: 0 auto;
        input {
          width: 100%;
          padding-left: 3rem;
          border-radius: 3rem;
        }

        svg {
          position: absolute;
          left: 1rem;
        }
      }
    }
  }
}
