.delete-group-modal {
  z-index: 999999999;
  .modal-body {
    margin: 0 auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    .buttons-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 1rem;
      width: 100%;
      justify-content: center;

      .delete-btn {
        background-color: $color-red;
        color: $white;
        width: max-content;
        padding: 0.5rem 1rem;
        border-radius: 5px;
      }
    }
  }
}
