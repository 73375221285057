.caregiver-modal {
  .tab-selector {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 85%;
    margin: 0 auto;
    padding: 1rem 0;
    .tab {
      display: flex;
      align-items: flex-start;
      width: 100%;
      gap: 0.5rem;
      cursor: pointer;
      padding-left: 0.5rem;
      .title-container {
        h3 {
          font-size: 0.875rem;
        }
        p {
          font-size: 0.625rem;
          font-weight: 300;
        }
      }
    }
  }
}
