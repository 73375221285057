.modal-content {
  background-color: $color-white;
}

button {
  outline: none;
}

.invitation-back {
  position: absolute;
  top: -3.5rem;
  outline: none;
  z-index: 10;
}
.modal-header {
  .invitation-cancel-header {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    button {
      font-weight: 500;
      outline: none;
      padding: 1rem;
      max-width: max-content;
    }
  }
}

.modal-body {
  .invitation-modal-body {
    padding: 1rem;
    padding-top: 0;
  }
}
