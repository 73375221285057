.rpm-provider-dashboard,
.rpm-coach-dashboard {
    .rpm-provider-container,
    .rpm-coach-container {
        .search-container {
            .right-search-container {
                .search-container-wrapper {
                    position: relative;
                    width: 6.0625rem;
                    height: 2rem;

                    .search-container-filter {
                        width: 6.0625rem;
                        height: 2rem;
                        border: 1px solid #d3d0cc;
                        background-color: $white;
                        border-radius: 0.3125rem;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        gap: 0.5rem;
                        cursor: pointer;
                        .search-container-filter-text {
                            font-size: 0.75rem;
                            font-weight: 500;
                            color: $color-dark;
                            white-space: nowrap;
                            overflow: hidden;
                        }
                    }
                    .search-container-filter-container {
                        width: 27.3125rem;
                        background-color: $white;
                        border: 1px solid #d3d0cc;
                        position: absolute;
                        top: 120%;
                        right: 0;
                        z-index: 50;
                        border-radius: 0.625rem;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: space-between;
                        @include media-breakpoint-down(sm) {
                            width: 17.3125rem;
                            max-height: 60vh;
                        }
                        .search-container-filter-container-header {
                            min-height: 3.4375rem;
                            width: 90%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            margin: 0 auto;
                            p {
                                font-size: 0.75rem;
                                font-weight: 600;
                            }
                            border-bottom: 1px solid rgba(229, 229, 229, 0.5);
                        }

                        .search-container-filter-container-body {
                            max-height: 18.0625rem;
                            overflow-y: auto;
                            width: 90%;
                            display: flex;
                            flex-direction: column;
                            margin: 0 auto;
                            .search-container-filter-container-body-item {
                                height: 3.0625rem;
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                width: 100%;
                                border-bottom: 1px solid
                                    rgba(229, 229, 229, 0.5);
                                overflow: hidden;
                                outline: none;
                                p {
                                    font-size: $font-size-small;
                                    font-weight: 400;
                                    strong {
                                        font-weight: 600;
                                    }
                                }
                                svg {
                                    margin-right: 0.2rem;
                                }
                            }
                            .search-container-filter-container-body-item-options {
                                display: flex;
                                flex-direction: column;
                                width: 100%;
                                .search-container-filter-container-body-item-options-item {
                                    height: 3.0625rem;
                                    border-bottom: 1px solid
                                        rgba(229, 229, 229, 0.5);
                                    overflow: hidden;
                                    outline: none;
                                    display: flex;
                                    align-items: center;
                                    gap: 0.5rem;
                                    p {
                                        font-size: $font-size-small;
                                        font-weight: 400;
                                        user-select: none;
                                    }
                                    button {
                                        outline: none;
                                    }
                                }
                            }
                        }

                        .search-container-filter-container-footer {
                            min-height: 8.0625rem;
                            width: 100%;
                            display: flex;
                            align-items: center;
                            gap: 1rem;
                            justify-content: center;

                            @include media-breakpoint-down(sm) {
                                flex-direction: column;
                            }

                            .search-container-filter-container-footer-button {
                                width: 10.25rem;
                                height: 2.5rem;
                                border-radius: 0.625rem;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                cursor: pointer;
                                font-size: $font-size-small;

                                @include media-breakpoint-down(sm) {
                                    width: 80%;
                                }
                            }

                            .apply {
                                background-color: $color-dark;
                                color: $white;
                            }

                            .reset {
                                border: 1px solid $color-dark;
                                color: $color-dark;
                                background-color: transparent;
                            }
                        }
                    }
                }
            }
        }
    }
}
