@import '../../styles/mixins';

.roadmap-answer-page {
  .third-column {
    display: flex;
    justify-content: right;
  }
  .first-column {
    display: flex;
    justify-content: left;
  }
  .btn-save-question {
    color: white;
    background: $color-dark;
    box-shadow: none;
    padding: 0.8rem;
    border-radius: 0.8rem;
    position: relative;
    right: 0;
  }
  .btn-cancel-question {
    color: $color-dark;
    background: $color-gray-lighter;
    box-shadow: none;
    padding: 0.8rem;
    border-radius: 0.8rem;
    position: relative;
    left: 0;
  }

  .btn-cancel {
    background-color: transparent;
    box-shadow: none;
    font-weight: 500;
  }
  h1 {
    font-size: $font-size-largest;
    font-weight: 500;
  }

  .form-group {
    h2 {
      margin: 2.5rem 0;
      font-family: $serif-font-family;
      font-size: $font-size-largest;
    }

    .form-control {
      padding: 2.5rem;
    }
  }
}
