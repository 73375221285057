.add-cpt-modal {
    .modal-content {
        background-color: $white;
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: center;
        padding: 3rem 5rem;

        form {
            margin-top: 1rem;
            .form-group {
                display: flex;
                flex-direction: column;
                label {
                    font-size: 0.75rem;
                    font-weight: 300;
                    color: #40413f;
                    margin-bottom: 0.625rem;
                }
                .select-cpt-dropdown {
                    position: relative;
                    height: 2.8125rem;
                    background: $white;
                    border: 1px solid #f2f1ec;
                    border-radius: 0.625rem;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding-left: 1.5625rem;
                    padding-right: 1.1875rem;
                    width: 100%;
                    p {
                        font-size: 0.875rem;
                        font-weight: 600;
                    }
                    &__options {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        background: $white;
                        border: 1px solid #f2f1ec;
                        border-radius: 0.625rem;
                        z-index: 100;
                        height: 21rem;
                        max-height: 21rem;
                        overflow-y: auto;
                        display: flex;
                        flex-direction: column;
                        box-shadow: 1px 1px 20px 1px rgba(0, 0, 0, 0.05);
                        &__option {
                            min-height: 5rem;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            width: 100%;
                            border-bottom: 1px solid #f2f1ec;
                            cursor: pointer;
                            padding: 0 1rem;
                            p {
                                font-weight: 300;
                                font-size: 0.875rem;
                                strong {
                                    font-weight: 600;
                                }
                            }
                            .description {
                                font-size: $font-size-smallest;
                                font-weight: 300;
                            }

                            &:hover {
                                background-color: #f2f1ec;
                            }
                        }
                    }
                }
                .date-wrapper {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    justify-content: space-between;
                    gap: 1rem;
                    p {
                        font-weight: 600;
                    }
                    input {
                        height: 2.8125rem;
                        border: 1px solid #f2f1ec;
                        padding: 0;
                        text-align: center;
                    }

                    #month,
                    #day {
                        width: 4.9375rem;
                        font-weight: 300;
                    }

                    #year {
                        width: 6.25rem;
                        font-weight: 300;
                    }
                }

                input,
                textarea {
                    &::placeholder {
                        opacity: 0.5;
                    }
                }

                .simple-text-input,
                textarea {
                    height: 2.8125rem;
                    border: 1px solid #f2f1ec;
                    border-radius: 0.625rem;
                    padding: 0 1rem;
                    font-size: 0.875rem;
                    &::placeholder {
                        font-weight: 300;
                        font-style: italic;
                    }
                }

                textarea {
                    height: 6rem;
                    padding-top: 1rem;
                    resize: none;
                }

                #score {
                    width: 4rem;
                    height: 2.8125rem;
                    border: 1px solid #f2f1ec;
                    padding: 0;
                    text-align: center;
                    font-size: 0.875rem;
                    font-style: italic;
                    font-weight: 300;
                }

                #assessment_result_link {
                    width: 100%;
                    height: 2.8125rem;
                    border: 1px solid #f2f1ec;
                    text-align: left;
                    padding-left: 1rem;
                    font-size: 0.875rem;
                    font-style: italic;
                    font-weight: 300;
                }
            }
            .error-message {
                color: $color-red;
                font-size: $font-size-smallest;
                margin: 0;
                margin-bottom: 1rem;
                font-weight: 700;
            }
            .submit-button {
                height: 3.125rem;
                width: 100%;
                font-weight: 500;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: $color-dark;
                color: $white;
                border-radius: 0.625rem;
                outline: none;
            }

            .cancel-button {
                text-decoration: underline;
                font-size: 0.875rem;
                font-weight: 400;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: transparent;
                margin-top: 1.5rem;
            }
        }
    }
}
