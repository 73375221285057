@import '../../styles/mixins';

.user-edit-profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  overflow-y: auto;
  form {
    background-color: $color-white;
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 10vh auto;
    padding: 1.5rem 2rem;
    width: 32rem;

    @include media-breakpoint-down(sm) {
      width: 100vw;
      height: 100%;
      z-index: 9999;
      position: relative;
      margin: 0 auto;
    }
    .edit-profile-button,
    .cancel-button {
      background-color: $color-dark;
      border-radius: 0.5rem;
      padding: 0.5rem 1rem;
      color: $color-white;
      font-size: $font-size-small;
      width: 100%;
      margin-top: 1rem;
    }
    .cancel-button {
      background-color: transparent;
      color: $color-dark;
      border: 1px solid $color-dark;
      font-weight: 500;
    }
    .title {
      font-weight: bold;
      margin-bottom: 0.5rem;
    }
    .profile-picture {
      display: flex;
      align-items: center;
      margin-bottom: 1rem;
      gap: 1rem;
      img {
        height: 3rem;
        width: 3rem;
        border-radius: 50%;
        object-fit: cover;
      }
      .change-profile-picture-underline {
        text-decoration: underline;
        cursor: pointer;
        font-size: $font-size-smaller;
        opacity: 0.6;
      }
      .upload-button {
        background-color: $color-dark;
        border-radius: 0.5rem;
        padding: 0.5rem 1rem;
        color: $color-white;
        font-size: $font-size-smaller;
        max-width: max-content;
      }
    }
    .form-group {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-self: flex-start;
      width: 100%;
      margin-bottom: 1rem;
      input {
        padding-left: 1rem;
        width: 100%;
        background-color: #fff;
        border: 1px solid #ddd;
        &:focus {
          outline-color: $color-dark;
        }
      }
      #phone_number {
        padding-left: 3rem;
        border-radius: 0.625rem;
      }
      .react-tel-input {
        .flag-dropdown {
          background: transparent;
          border: none;
          border-radius: 0.525rem;
          .selected-flag {
            background: transparent;
            padding-left: 1rem;
          }
        }
        .country-list {
          box-shadow: 0px 4px 20px 1px rgba(0, 0, 0, 0.1);
          border-radius: 0.525rem;
          li {
            font-weight: 300;
          }
        }
      }
      .checkbox-group {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 0.25rem;
        margin-left: 0.5rem;
      }
      .error-message {
        color: $color-red;
        font-size: $font-size-smallest;
        margin: 0.5rem 0;
        font-weight: 700;
      }
    }
  }
}
